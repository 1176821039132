import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalWindow from '../../../../../../components/ModalWindow';
import { PharmacyTag } from '../../../../../SourcePage/types';
import * as Styled from './AddSourceTagModal.styles';
import { TagsTypeOptions } from '../../../../types';
import { baseTheme } from '../../../../../../global/styles/theme';

export interface AddSourceTagModalProps {
  tagsList: PharmacyTag[];
  code: string;
  onCloseAddTagModal: () => void;
  handleSetTags: (values: number[], metaText: string) => void;
  tagsType: string;
}

export const AddSourceTagModal = ({
  handleSetTags,
  tagsList,
  code,
  onCloseAddTagModal,
  tagsType,
}: AddSourceTagModalProps) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const [filteredTags, setFilteredTags] = useState(tagsList);
  const [addedTags, setAddedTags] = useState<number[]>([]);
  const [isNextStep, setIsNextStep] = useState<boolean>(false);
  const [metaValue, setMetaValue] = useState<string>('');

  const handleSearchTags = () => {
    if (searchValue) {
      setFilteredTags(
        (tagsList &&
          tagsList?.filter((el) =>
            el.name.toLowerCase().includes(searchValue.toLowerCase())
          )) ||
          []
      );
    } else {
      setFilteredTags(tagsList);
    }
  };

  useEffect(() => {
    handleSearchTags();
  }, [searchValue]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const onAddTagHandler = (tagId: number) => {
    if (addedTags.includes(tagId)) {
      setAddedTags(addedTags.filter((item) => item !== tagId));
    } else {
      setAddedTags([...addedTags, tagId]);
    }
  };

  return (
    <ModalWindow onClose={onCloseAddTagModal}>
      {isNextStep ? (
        <Styled.TagsContainer>
          <p className="title">
            {t(
              'PharmacyTagsPage.SET_CREATED_PHARMACY_TAG_MODAL.TITLE_SET_EXISTING'
            )}
          </p>
          <div className="meta-input-container">
            <input name="meta" onChange={(e) => setMetaValue(e.target.value)} />
          </div>
          <div style={{ display: 'flex', marginTop: '50px' }}>
            <Styled.AddTagsBtn
              onClick={() => handleSetTags(addedTags, metaValue)}
            >
              {t('PharmacyTagsPage.SET_CREATED_PHARMACY_TAG_MODAL.BIND')}
            </Styled.AddTagsBtn>
          </div>
        </Styled.TagsContainer>
      ) : (
        <Styled.TagsContainer>
          <div className="title">
            {tagsType === TagsTypeOptions.network
              ? t('PharmacyTagsPage.ADD_EXISTING_TAG_MODAL.NETWORK_TAGS')
              : t('PharmacyTagsPage.ADD_EXISTING_TAG_MODAL.SOURCE_TAGS')}
          </div>
          <Styled.SearchBarInput>
            <input
              placeholder={t('ProductsPage.NETWORKS_MODAL.PLACEHOLDER')}
              value={searchValue}
              onChange={handleInputChange}
              type="text"
            />
          </Styled.SearchBarInput>
          <Styled.SourceTagsBlock>
            {filteredTags &&
              filteredTags.map((tag) => (
                <div
                  className={`tags-container ${
                    addedTags[0] === tag.id ? 'added-tag' : ''
                  }`}
                  key={tag.name}
                  onClick={() => setAddedTags([tag.id])}
                >
                  <div className="checkbox-container" />
                  <div className="tag">{tag.name}</div>
                  <div
                    className="tag-color-block"
                    style={{ backgroundColor: tag.color }}
                  />
                </div>
              ))}
          </Styled.SourceTagsBlock>
          <Styled.AddTagsBtn
            style={{
              backgroundColor: !addedTags[0]
                ? baseTheme.colors.disabled
                : baseTheme.colors.focus,
              pointerEvents: !addedTags[0] ? 'none' : 'auto',
            }}
            onClick={() => {
              if (addedTags.length > 0) {
                setIsNextStep(true);
              }
            }}
          >
            {t('PharmacyTagsPage.ADD_EXISTING_TAG_MODAL.ADD_SELECTED_TAGS')}
          </Styled.AddTagsBtn>
        </Styled.TagsContainer>
      )}
    </ModalWindow>
  );
};
