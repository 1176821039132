import React, { useEffect, useRef } from 'react';
import DG from '2gis-maps';
import iconA from '../../global/media/mapA.png';
import iconB from '../../global/media/mapB.png';

interface Props {
  sourceLat: string;
  sourceLon: string;
  setLatB: React.Dispatch<React.SetStateAction<number>>;
  setLonB: React.Dispatch<React.SetStateAction<number>>;
  latB: number;
  lonB: number;
}

const Map2Gis = ({
  sourceLat,
  sourceLon,
  setLatB,
  setLonB,
  lonB,
  latB,
}: Props) => {
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const mapInitializedRef = useRef<boolean>(false);
  const mapRef = useRef<any>(null);

  useEffect(() => {
    if (!DG || latB === 0 || lonB === 0 || mapInitializedRef.current) {
      return;
    }

    const apiKey = process.env.REACT_APP_KEY_MAP;
    const center = [sourceLat, sourceLon];
    const customIconB = DG.icon({
      iconUrl: iconB,
      iconSize: [32, 32],
      iconAnchor: [16, 16],
    });
    const customIconA = DG.icon({
      iconUrl: iconA,
      iconSize: [32, 32],
      iconAnchor: [16, 16],
    });

    if (mapContainerRef.current) {
      const map = DG.map(mapContainerRef.current, {
        center,
        zoom: 13,
        key: apiKey,
      });

      const markerA = DG.marker(center);
      markerA.setIcon(customIconA);
      map.addLayer(markerA);

      const markerB = DG.marker([latB, lonB]);
      markerB.setIcon(customIconB);
      map.addLayer(markerB);

      markerB.dragging.enable();

      markerB.on('drag', (event: { target: { getLatLng: () => any } }) => {
        const newCoordinates = event.target.getLatLng();
        const newLat = Number(newCoordinates.lat);
        const newLon = Number(newCoordinates.lng);

        setLatB(newLat);
        setLonB(newLon);
      });

      mapRef.current = map;
      mapInitializedRef.current = true;
    }
  }, [latB, lonB, sourceLat, sourceLon, setLatB, setLonB]);

  return (
    <div ref={mapContainerRef} style={{ height: '850px', width: '600px' }} />
  );
};

export default Map2Gis;
