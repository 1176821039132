import axios from 'axios';
import { NetworkResponse } from './types';
import { getFromLocalStorage } from '../../global/helpers';
import { UserRole } from '../OrdersPage/types';

export class NetworkService {
  static API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/api/v1/`;

  static role = getFromLocalStorage('role');

  static getAllNetworks = async () => {
    const url =
      NetworkService.role === UserRole.ADMIN
        ? `${NetworkService.API_BASE_URL}admin/networks`
        : `${NetworkService.API_BASE_URL}networks`;
    const response = await axios({
      method: 'get',
      url,
    });
    return response.data;
  };

  static getNetwork = async (networkCode: string) => {
    const response = await axios({
      method: 'get',
      url: `${NetworkService.API_BASE_URL}admin/networks/${networkCode}`,
    });
    return response.data;
  };

  static getSourcesByNetwork = async (networkCode: string) => {
    const response = await axios({
      method: 'get',
      url: `${NetworkService.API_BASE_URL}admin/sources?network_code=${networkCode}`,
    });
    return response.data;
  };

  static editNetwork = async (networkInfo: NetworkResponse) => {
    const response = await axios({
      method: 'post',
      url: `${NetworkService.API_BASE_URL}admin/networks`,
      data: networkInfo,
    });
    return response.data;
  };

  static getUsers = async (role: string, networkCode: string) => {
    const response = await axios({
      method: 'get',
      url: `${NetworkService.API_BASE_URL}users/admin/users/list?role=${role}&network_code=${networkCode}`,
    });
    return response.data;
  };

  static updateUser = async (userInfo: any) => {
    const response = await axios({
      method: 'post',
      url: `${NetworkService.API_BASE_URL}users/admin/users`,
      data: userInfo,
    });
    return response.data;
  };

  static activateSource = async (sourceCode: string) => {
    const response = await axios({
      method: 'put',
      url: `${NetworkService.API_BASE_URL}admin/sources/${sourceCode}/visible`,
    });
    return response.data;
  };

  static deactivateSource = async (sourceCode: string) => {
    const response = await axios({
      method: 'put',
      url: `${NetworkService.API_BASE_URL}admin/sources/${sourceCode}/invisible`,
    });
    return response.data;
  };
}
