import React, { ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Formik,
  FormikProps,
  FormikErrors,
  Field,
  ErrorMessage,
} from 'formik';
import * as actions from '../../actions';
import * as Styled from './CreateTemplates.styles';
import { GenerateTemplatesErrors, GenerateTemplatesRequest } from '../../types';
import { formatIikCodeToBeValid } from '../../../../global/helpers';

const INITIAL_STATE = {
  code: '',
  description: '',
  discount: { target: 'Delivery', type: 'Percent', value: null },
  limits: {
    minOrderPrice: null,
    maxUsagesTotal: null,
    maxUsagesUser: null,
    maxUserOrders: null,
  },
  duration: null,
};

const CreateTemplates = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSubmitForm = async (values: GenerateTemplatesRequest) => {
    await dispatch(
      actions.generateTemplates.request({
        ...values,
        postEffect: () => {
          dispatch(actions.getTemplatesData.request());
        },
      })
    );
  };

  const validateForm = (values: GenerateTemplatesRequest) => {
    const errors: FormikErrors<GenerateTemplatesErrors> = {};
    if (!values.code) {
      errors.code = t('PROMO_CODE_PAGE.ERROR_CODE');
    }
    if (!formatIikCodeToBeValid(values.code) && values.code) {
      errors.code = t('PROMO_CODE_PAGE.WRONG_FORMATE_CODE');
    }
    if (!values.description) {
      errors.description = t('PROMO_CODE_PAGE.ERROR_DESCRIPTION');
    }
    if (!values.discount.value) {
      errors.discountValue = t('PROMO_CODE_PAGE.ERROR_VALUE');
    }

    return errors;
  };

  const handleChange = (
    props: FormikProps<GenerateTemplatesRequest>,
    event: ChangeEvent<HTMLInputElement>,
    inputName: string
  ) => {
    if (inputName === 'discountTarget') {
      props.setFieldValue('discount', {
        ...props.values.discount,
        target: event.target.value,
      });
    } else if (inputName === 'discountValue') {
      props.setFieldValue('discount', {
        ...props.values.discount,
        value: Number(event.target.value),
      });
    } else if (inputName === 'discountType') {
      props.setFieldValue('discount', {
        ...props.values.discount,
        type: event.target.value,
      });
    } else if (inputName === 'minOrderPrice') {
      props.setFieldValue('limits', {
        ...props.values.limits,
        minOrderPrice: Number(event.target.value),
      });
    } else if (inputName === 'maxUsagesTotal') {
      props.setFieldValue('limits', {
        ...props.values.limits,
        maxUsagesTotal: Number(event.target.value),
      });
    } else if (inputName === 'maxUsagesUser') {
      props.setFieldValue('limits', {
        ...props.values.limits,
        maxUsagesUser: Number(event.target.value),
      });
    } else if (inputName === 'maxUserOrders') {
      props.setFieldValue('limits', {
        ...props.values.limits,
        maxUserOrders: Number(event.target.value),
      });
    } else if (inputName === 'duration') {
      props.setFieldValue('duration', Number(event.target.value));
    } else if (inputName === 'code') {
      props.setFieldValue('code', event.target.value.toUpperCase());
    } else {
      props.setFieldValue(inputName, event.target.value);
    }
  };

  return (
    <Styled.CreateTemplates>
      <Formik
        onSubmit={handleSubmitForm}
        validate={validateForm}
        initialValues={INITIAL_STATE}
      >
        {(props: FormikProps<GenerateTemplatesRequest>) => (
          <Form className="form-container">
            <div className="container-header">
              <div className="cell-header">
                {t('PROMO_CODE_PAGE.CODE_TEMPLATE')}
              </div>

              <div className="cell-header">
                {t('PROMO_CODE_PAGE.DESCRIPTION_TEMPLATE')}
              </div>
              <div className="cell-header">
                {t('PROMO_CODE_PAGE.TARGET_TEMPLATE')}
              </div>
              <div className="cell-header">
                {t('PROMO_CODE_PAGE.TYPE_TEMPLATE')}
              </div>
              <div className="cell-header">
                {t('PROMO_CODE_PAGE.VALUE_TEMPLATE')}
              </div>
              <div className="cell-header">
                {t('PROMO_CODE_PAGE.DURATION_TEMPLATE')}
              </div>
              <div className="cell-header">
                {' '}
                {t('PROMO_CODE_PAGE.MAX_USAGES_TOTAL_TEMPLATE')}
              </div>
              <div className="cell-header">
                {t('PROMO_CODE_PAGE.MAX_USAGES_USERS_TEMPLATE')}
              </div>
              <div className="cell-header">
                {t('PROMO_CODE_PAGE.MAX_USER_ORDERS_TEMPLATE')}
              </div>
              <div className="cell-header">
                {t('PROMO_CODE_PAGE.MIN_ORDER_PRICE_TEMPLATE')}
              </div>
            </div>

            <div className="container-table">
              <div className="cell">
                <Field
                  placeHolder="CODE_{12}"
                  id="code"
                  name="code"
                  type="text"
                  disabled={false}
                  className="input"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    handleChange(props, e, 'code');
                  }}
                />
                <ErrorMessage
                  name="code"
                  render={(msg: string) => (
                    <div className="error-message-label">{msg}</div>
                  )}
                />
              </div>

              <div className="cell">
                <Field
                  id="description"
                  name="description"
                  type="text"
                  disabled={false}
                  className="input"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    handleChange(props, e, 'description');
                  }}
                />
                <ErrorMessage
                  name="description"
                  render={(msg: string) => (
                    <div className="error-message-label">{msg}</div>
                  )}
                />
              </div>
              <div className="cell">
                <Field
                  id="discountTarget"
                  name="discountTarget"
                  className="input"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    handleChange(props, e, 'discountTarget');
                  }}
                  as="select"
                >
                  <option value="Delivery">Delivery</option>
                  <option value="Products">Products</option>
                </Field>
              </div>
              <div className="cell">
                <Field
                  id="discountType"
                  name="discountType"
                  className="input"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    handleChange(props, e, 'discountType');
                  }}
                  as="select"
                >
                  <option value="Percent">Percent</option>
                  <option value="Money">Money</option>
                </Field>
              </div>
              <div className="cell">
                <Field
                  id="discountValue"
                  name="discountValue"
                  type="number"
                  disabled={false}
                  className="input"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    handleChange(props, e, 'discountValue');
                  }}
                />
                <ErrorMessage
                  name="discountValue"
                  render={(msg: string) => (
                    <div className="error-message-label">{msg}</div>
                  )}
                />
              </div>
              <div className="cell">
                <Field
                  id="duration"
                  name="duration"
                  type="number"
                  disabled={false}
                  className="input"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    handleChange(props, e, 'duration');
                  }}
                />
              </div>
              <div className="cell">
                <Field
                  id="maxUsagesTotal"
                  name="maxUsagesTotal"
                  type="number"
                  disabled={false}
                  className="input"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    handleChange(props, e, 'maxUsagesTotal');
                  }}
                />
              </div>
              <div className="cell">
                <Field
                  id="maxUsagesUser"
                  name="maxUsagesUser"
                  type="number"
                  disabled={false}
                  className="input"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    handleChange(props, e, 'maxUsagesUser');
                  }}
                />
              </div>
              <div className="cell">
                <Field
                  id="maxUserOrders"
                  name="maxUserOrders"
                  type="number"
                  disabled={false}
                  className="input"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    handleChange(props, e, 'maxUserOrders');
                  }}
                />
              </div>
              <div className="cell">
                <Field
                  id="minOrderPrice"
                  name="minOrderPrice"
                  type="number"
                  disabled={false}
                  className="input"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    handleChange(props, e, 'minOrderPrice');
                  }}
                />
              </div>
            </div>

            <button type="submit" className="create-templates">
              {t('PROMO_CODE_PAGE.CREATE_TEMPLATES')}
            </button>
          </Form>
        )}
      </Formik>
    </Styled.CreateTemplates>
  );
};

export default CreateTemplates;
