import { createReducer } from '@reduxjs/toolkit';
import * as actions from './actions';
import { AppState } from '../../global/types';
import { SettingsPageState } from './types';

const initialState: SettingsPageState = {
  getConfig: {
    data: null,
    loading: false,
    error: null,
  },
  getCardPayment: {
    data: null,
    loading: false,
    error: null,
  },
};

const reducer = createReducer(initialState, {
  [actions.getConfig.REQUEST]: (state) => {
    state.getConfig.loading = true;
  },

  [actions.getConfig.SUCCESS]: (state, action) => {
    state.getConfig.loading = false;
    state.getConfig.data = action.payload;
  },

  [actions.getConfig.ERROR]: (state, action) => {
    state.getConfig.loading = false;
    state.getConfig.error = action.payload;
  },
  [actions.getCardPayment.REQUEST]: (state) => {
    state.getCardPayment.loading = true;
  },

  [actions.getCardPayment.SUCCESS]: (state, action) => {
    state.getCardPayment.loading = false;
    state.getCardPayment.data = action.payload;
  },

  [actions.getCardPayment.ERROR]: (state, action) => {
    state.getCardPayment.loading = false;
    state.getCardPayment.error = action.payload;
  },
});

const config = (state: AppState) => state.settingsPageReducer.getConfig.data;
const cardPayment = (state: AppState) =>
  state.settingsPageReducer.getCardPayment.data;

const selectors = {
  config,
  cardPayment,
};

export { selectors };
export default reducer;
