import React, { useEffect, useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as actions from '../../../OrdersPage/actions';
import * as Styled from './StatusOperator.styles';
import bottomArrow from '../../../../global/media/bottom-arrow.svg';
import copyIcon from '../../../../global/media/copy-icon.png';
import SuggestionStatus from './components/SuggestionStatus';
import {
  getFromLocalStorage,
  formatTime,
  formatPaymentMethod,
} from '../../../../global/helpers';
import {
  OrderTableData,
  UserRole,
  PaymentResponseItemCache,
  PaymentType,
} from '../../../OrdersPage/types';
import { selectors } from '../../reducer';
import Notification from '../../../../components/NotificationsList/components/Notification';
import { selectors as ordersSelectors } from '../../../OrdersPage/reducer';
import { selectors as notiSelectors } from '../../../InitComponent/reducer';
import { ErrorType } from '../../types';
import { NotificationProps } from '../../../InitComponent/types';
import { StatusType } from '../../../../global/types';
import success from '../../../../global/media/Vector (2).svg';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

type UniversalType = {
  [key: string]: {
    automation_state: string;
  };
};

interface StatusOperatorProps {
  onCancelClick: () => void;
  data: OrderTableData;
  setIsAutoDeliveryModalOpen: (params: boolean) => void;
  setLoader: React.Dispatch<React.SetStateAction<boolean>>;
  dataNetwork: UniversalType | null;
}

const NotificationWrapper = styled('div')`
  max-height: 60px;
  position: fixed;
  top: 56px;
  left: calc(50vw - 425px / 2);
  width: 425px;
  z-index: 600;
`;

export const PaymentItems = ({
  id,
  data,
  defaultPaymentStatus,
  setLoader,
  user,
}: {
  id: string;
  data: PaymentResponseItemCache[];
  defaultPaymentStatus: string;
  setLoader: any;
  user?: string;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const orderData = useSelector(ordersSelectors.selectedOrderData);
  const token = getFromLocalStorage('accessToken');
  const role =
    getFromLocalStorage('role') === 'manager' ? 'network' : 'pharmacy';

  const [paymentStatus, setPaymentStatus] = useState(defaultPaymentStatus);
  const [paymentUuid, setPaymentUuid] = useState('');
  const [paymentComment, setPaymentComment] = useState('');
  const [paymentAmount, setPaymentAmount] = useState('');
  const [customError, setCustomError] = useState('');
  const [showAll, setShowAll] = useState(false);

  const filtered = data?.filter((f) => {
    if (data?.length === 1) {
      return true;
    }
    if (!showAll) {
      return f.status !== 'paid' && f.status !== 'refund_ready';
    }
    return true;
  });

  const getAmount = (row: PaymentResponseItemCache) => {
    if (
      orderData?.paymentType === 'interpay' ||
      orderData?.paymentType === PaymentType.MB
    ) {
      if (row?.payment_type) {
        if (user === UserRole.PHARMACIST || user === UserRole.MANAGER) {
          return row.items_amount;
        }
        return row.amount;
      }
      return row.amount;
    }
    return row.amount;
  };

  const handleSetItemsPayment = () => {
    if (paymentStatus === 'repayment') {
      if (!paymentAmount || !paymentComment) {
        return setCustomError('Заполните все поля!');
      }
      dispatch(
        actions.postRepayment.request({
          id,
          data: {
            amount: Number(paymentAmount),
            comment: paymentComment,
            payment_type: 'items',
          },
          postEffect: () => {
            dispatch(
              actions.setSelectedOrder.request({
                role,
                token,
                id,
              })
            );
            setLoader(false);
          },
        })
      );
      setPaymentStatus('');
      setPaymentAmount('');
      setPaymentComment('');
      return;
    }
    if (
      !paymentUuid &&
      ['ready_to_refund', 'ready_to_refund_payment'].includes(paymentStatus)
    ) {
      return setCustomError('Выберите ID платежа!');
    }
    setCustomError('');
    if (paymentStatus !== 'ready_to_refund') {
      dispatch(
        actions.putPaymentStatus.request({
          uuid: paymentUuid,
          data: {
            type: ['refund_ready', 'refund_failed'].includes(
              paymentStatus as string
            )
              ? 'refund'
              : 'payment',
            status: paymentStatus,
          },
          postEffect: () => {
            dispatch(
              actions.setSelectedOrder.request({
                role,
                token,
                id,
              })
            );
            setLoader(false);
          },
        })
      );
      setPaymentUuid('');
      setPaymentStatus('');
      setPaymentAmount('');
      setPaymentComment('');
    }
    if (paymentStatus === 'ready_to_refund') {
      if (!paymentAmount || !paymentComment) {
        return setCustomError('Заполните все поля!');
      }
      dispatch(
        actions.postRefund.request({
          id: paymentUuid,
          data: {
            amount: Number(paymentAmount),
            comment: paymentComment,
            payment_type: 'items',
          },
          postEffect: () => {
            dispatch(
              actions.setSelectedOrder.request({
                role,
                token,
                id,
              })
            );
            setLoader(false);
          },
        })
      );
      setPaymentUuid('');
      setPaymentStatus('');
      setPaymentAmount('');
      setPaymentComment('');
    }
  };

  if (!data.length || data[0].payment_method === 'in_place') {
    return null;
  }
  return (
    <div style={{ overflow: 'hidden' }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>Тип</StyledTableCell>
              <StyledTableCell>Способ оплаты</StyledTableCell>
              <StyledTableCell>Текущий статус</StyledTableCell>
              <StyledTableCell>Дата оплаты</StyledTableCell>
              <StyledTableCell>Сумма</StyledTableCell>
              <StyledTableCell>Комментарий</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell>Сумма заказа</TableCell>
              <TableCell style={{ color: 'green', fontWeight: '700' }}>
                {data.reduce((acc: number, item: any) => {
                  if (
                    item.type === 'refund' &&
                    item.status !== 'refund_failed'
                  ) {
                    return acc - Number(item.amount);
                  }
                  if (
                    item.type === 'payment' &&
                    ![
                      'kaspi_not_found',
                      'canceled',
                      'failed',
                      'cancel_invoice',
                      'invoice_canceled',
                    ].includes(item.status)
                  ) {
                    return acc + Number(item.amount);
                  }
                  return acc;
                }, 0)}
              </TableCell>
              <TableCell />
            </TableRow>
            {filtered.map((row) => (
              <TableRow
                key={row.uuid}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell>{row.label}</TableCell>
                <TableCell component="th" scope="row">
                  Товары
                </TableCell>
                <TableCell>
                  {formatPaymentMethod(
                    row.payment_method,
                    user === UserRole.MANAGER || user === UserRole.PHARMACIST
                  )}
                </TableCell>
                <TableCell>
                  {row.payment_method === 'interpay' &&
                  row.status === 'wait_capture'
                    ? t(`OrdersPage.STATUSES.interpay_hold`)
                    : t(`OrdersPage.STATUSES.${row.status}`)}
                </TableCell>
                <TableCell>{formatTime(row.updated_at)}</TableCell>
                <TableCell>{getAmount(row)}</TableCell>
                <TableCell>{row.comment}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="bottom-block" style={{ alignItems: 'center' }}>
        {data.length > 1 && data.some((f) => f.status === 'paid') && (
          <div
            className="btn"
            style={{ borderRadius: 0 }}
            onClick={() => setShowAll((prev) => !prev)}
          >
            {showAll ? 'Скрыть' : 'Все операции'}
          </div>
        )}
      </div>
      {user !== UserRole.PHARMACIST && user !== UserRole.MANAGER && (
        <>
          <div className="bottom-block" style={{ alignItems: 'center' }}>
            <div
              className="status-wrapper"
              style={{ marginLeft: 0, marginRight: 6 }}
            >
              <div className="status-title" style={{ minWidth: 98 }}>
                Статус оплаты за заказ:
              </div>
              <select
                className="field-status"
                value={paymentStatus}
                onChange={(e) => {
                  const allPayments = data?.filter((f) => f.type === 'payment');
                  const allRefunds = data?.filter((f) => f.type === 'refund');
                  setPaymentStatus(e.target.value);
                  if (
                    ['refund_ready', 'refund_failed'].includes(
                      e.target.value
                    ) &&
                    allRefunds?.length === 1
                  ) {
                    return setPaymentUuid(allRefunds[0].uuid);
                  }
                  if (allPayments?.length === 1) {
                    setPaymentUuid(allPayments[0].uuid);
                  }
                }}
              >
                <option value="pending">Ожидание</option>
                <option value="ready_to_invoice">Выставить счет</option>
                <option value="invoice_ready">Счет выставлен</option>
                <option value="check_invoice">Проверить оплату счета</option>
                <option value="paid">Оплачено</option>
                <option value="failed">Ошибка</option>
                <option value="canceled">Отменен</option>
                <option value="kaspi_not_found">Каспи не найден</option>
                {data.some((f) => f.status === 'paid') && (
                  <>
                    <option value="ready_to_refund">Сделать возврат</option>
                    <option value="refund_ready">Возврат выполнен</option>
                    <option value="refund_failed">
                      Не удалось сделать возврат
                    </option>
                    <option value="repayment">Довыставить счет</option>
                  </>
                )}
                {data.some((f) =>
                  ['invoice_ready', 'check_invoice'].includes(f.status)
                ) && <option value="cancel_invoice">Отмена инвойса</option>}
                {data.some((f) => f.status === 'cancel_invoice') && (
                  <option value="invoice_canceled">Отмена произведена</option>
                )}
              </select>
            </div>
            <button
              type="button"
              className="confirm-btn"
              onClick={handleSetItemsPayment}
              disabled={
                (orderData?.status === 'completed' &&
                  orderData?.statusPharmacy === 'completed' &&
                  paymentStatus !== 'ready_to_refund') ||
                (orderData?.status === 'canceled' &&
                  orderData?.statusPharmacy === 'canceled' &&
                  paymentStatus !== 'ready_to_refund')
              }
            >
              {t('OrderPage.StatusOperator.SAVE')}
            </button>
          </div>
          {!['repayment'].includes(paymentStatus as string) && (
            <div className="bottom-block" style={{ alignItems: 'center' }}>
              <div className="status-wrapper" style={{ marginLeft: 0 }}>
                <div className="status-title" style={{ minWidth: 98 }}>
                  Выберите ID платежа:
                </div>
                <select
                  className="field-status"
                  value={paymentUuid}
                  onChange={(e) => setPaymentUuid(e.target.value)}
                >
                  <option value="">Не выбрано</option>
                  {!['refund_ready', 'refund_failed'].includes(
                    paymentStatus as string
                  ) &&
                    data.map(
                      (row) =>
                        row.type === 'payment' && (
                          <option value={row.uuid}>{row.label}</option>
                        )
                    )}
                  {['refund_ready', 'refund_failed'].includes(
                    paymentStatus as string
                  ) &&
                    data.map(
                      (row) =>
                        row.type === 'refund' && (
                          <option value={row.uuid}>{row.label}</option>
                        )
                    )}
                </select>
              </div>
            </div>
          )}
          {['repayment', 'ready_to_refund'].includes(
            paymentStatus as string
          ) && (
            <>
              <div className="bottom-block" style={{ alignItems: 'center' }}>
                <div className="status-wrapper" style={{ marginLeft: 0 }}>
                  <div className="status-title" style={{ minWidth: 98 }}>
                    Введите сумму:
                  </div>
                  <input
                    className="field-status"
                    value={paymentAmount}
                    onChange={(e) => {
                      if (
                        paymentStatus === 'ready_to_refund' &&
                        Number(e.target.value) >
                          (data?.find((f) => f.uuid === paymentUuid)?.amount ||
                            0) -
                            (data
                              ?.find((f) => f.uuid === paymentUuid)
                              ?.refunds?.reduce((acc, item) => {
                                if (item.status !== 'refund_failed') {
                                  return acc + Number(item.amount);
                                }
                                return acc;
                              }, 0) || 0)
                      ) {
                        return;
                      }
                      setPaymentAmount(e.target.value);
                    }}
                  />
                  {paymentStatus === 'ready_to_refund' && (
                    <span style={{ color: 'red' }}>{`< ${
                      (data?.find((f) => f.uuid === paymentUuid)?.amount || 0) -
                      (data
                        ?.find((f) => f.uuid === paymentUuid)
                        ?.refunds?.reduce((acc, item) => {
                          if (item.status !== 'refund_failed') {
                            return acc + Number(item.amount);
                          }
                          return acc;
                        }, 0) || 0)
                    }`}</span>
                  )}
                </div>
              </div>
              <div className="bottom-block" style={{ alignItems: 'center' }}>
                <div className="status-wrapper" style={{ marginLeft: 0 }}>
                  <div className="status-title" style={{ minWidth: 98 }}>
                    Комментарий:
                  </div>
                  <div className="status-combined">
                    <input
                      className="field-status"
                      value={paymentComment}
                      onChange={(e) => setPaymentComment(e.target.value)}
                    />
                    {paymentStatus === 'repayment' && (
                      <select
                        className="field-status"
                        value={paymentComment}
                        onChange={(e) => setPaymentComment(e.target.value)}
                      >
                        <option value="">свободный комментарий</option>
                        <option value="в аптеке дороже">в аптеке дороже</option>
                        <option value="некорректная отработка аптеки">
                          некорректная отработка аптеки
                        </option>
                        <option value="добавили в заказ">
                          добавили в заказ
                        </option>
                        <option value="перепутал цифру">перепутал цифру</option>
                      </select>
                    )}
                    {paymentStatus === 'ready_to_refund' && (
                      <select
                        className="field-status"
                        value={paymentComment}
                        onChange={(e) => setPaymentComment(e.target.value)}
                      >
                        <option value="">свободный комментарий</option>
                        <option value="в аптеке дешевле">
                          в аптеке дешевле
                        </option>
                        <option value="в аптеке скидка">в аптеке скидка</option>
                        <option value="переоформленный заказ в другую аптеку">
                          переоформленный заказ в другую аптеку
                        </option>
                        <option value="некорректная отработка аптеки">
                          некорректная отработка аптеки
                        </option>
                        <option value="аптека закрылась">
                          аптека закрылась
                        </option>
                        <option value="убрали с заказа">убрали с заказа</option>
                        <option value="нет в наличии">нет в наличии</option>
                        <option value="сроковый препарат">
                          сроковый препарат
                        </option>
                      </select>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
      <div style={{ color: 'red', fontSize: 14 }}>{customError}</div>
    </div>
  );
};

const PaymentDelivery = ({
  id,
  data,
  defaultPaymentStatus,
  defaultPrice,
  setLoader,
  DeliveryComponent,
  user,
}: {
  id: string;
  data: PaymentResponseItemCache[];
  defaultPaymentStatus: string;
  defaultPrice: string | number;
  setLoader: any;
  DeliveryComponent: any;
  user: string;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const orderData = useSelector(ordersSelectors.selectedOrderData);
  const token = getFromLocalStorage('accessToken');
  const role =
    getFromLocalStorage('role') === 'manager' ? 'network' : 'pharmacy';

  const [paymentDeliveryStatus, setPaymentDeliveryStatus] =
    useState(defaultPaymentStatus);
  const [paymentUuid, setPaymentUuid] = useState('');
  const [paymentComment, setPaymentComment] = useState('');
  const [paymentAmount, setPaymentAmount] = useState('');
  const [customError, setCustomError] = useState('');
  const [showAll, setShowAll] = useState(false);

  const filtered = data?.filter((f) => {
    if (data?.length === 1) {
      return true;
    }
    if (!showAll) {
      return f.status !== 'paid' && f.status !== 'refund_ready';
    }
    return true;
  });

  const getAmount = (row: PaymentResponseItemCache) => {
    if (
      orderData?.paymentType === 'interpay' ||
      orderData?.paymentType === PaymentType.MB
    ) {
      if (row?.payment_type) {
        if (user === UserRole.PHARMACIST || user === UserRole.MANAGER) {
          return row.items_amount;
        }
        return row.amount;
      }
      return row.amount;
    }
    return row.amount;
  };

  const handleSetDeliveryPayment = () => {
    if (paymentDeliveryStatus === 'repayment') {
      dispatch(
        actions.postRepayment.request({
          id,
          data: {
            amount: Number(paymentAmount),
            comment: paymentComment,
            payment_type: 'delivery',
          },
          postEffect: () => {
            dispatch(
              actions.setSelectedOrder.request({
                role,
                token,
                id,
              })
            );
            setLoader(false);
          },
        })
      );
      setPaymentDeliveryStatus('');
      setPaymentAmount('');
      setPaymentComment('');
      return;
    }
    if (!data?.length) {
      dispatch(
        actions.postRepayment.request({
          id,
          data: {
            amount: defaultPrice,
            comment: 'вручную',
            payment_type: 'delivery',
          },
          postEffect: () => {
            dispatch(
              actions.setSelectedOrder.request({
                role,
                token,
                id,
              })
            );
            setLoader(false);
          },
        })
      );
      return;
    }
    if (
      !paymentUuid &&
      ['ready_to_refund', 'ready_to_refund_payment'].includes(
        paymentDeliveryStatus
      )
    ) {
      return setCustomError('Выберите ID платежа!');
    }
    setCustomError('');
    if (paymentDeliveryStatus !== 'ready_to_refund') {
      dispatch(
        actions.putPaymentStatus.request({
          uuid: paymentUuid,
          data: {
            type: ['refund_ready', 'refund_failed'].includes(
              paymentDeliveryStatus as string
            )
              ? 'refund'
              : 'payment',
            status: paymentDeliveryStatus,
          },
          postEffect: () => {
            dispatch(
              actions.setSelectedOrder.request({
                role,
                token,
                id,
              })
            );
            setLoader(false);
          },
        })
      );
      setPaymentUuid('');
      setPaymentDeliveryStatus('');
      setPaymentAmount('');
      setPaymentComment('');
    }
    if (paymentDeliveryStatus === 'ready_to_refund') {
      if (!paymentAmount || !paymentComment) {
        return setCustomError('Заполните все поля!');
      }
      dispatch(
        actions.postRefund.request({
          id: paymentUuid,
          data: {
            amount: Number(paymentAmount),
            comment: paymentComment,
            payment_type: 'delivery',
          },
          postEffect: () => {
            dispatch(
              actions.setSelectedOrder.request({
                role,
                token,
                id,
              })
            );
            setLoader(false);
          },
        })
      );
      setPaymentUuid('');
      setPaymentDeliveryStatus('');
      setPaymentAmount('');
      setPaymentComment('');
    }
  };
  return (
    <div style={{ overflow: 'hidden', marginTop: 12 }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>Тип</StyledTableCell>
              <StyledTableCell>Способ оплаты</StyledTableCell>
              <StyledTableCell>Текущий статус</StyledTableCell>
              <StyledTableCell>Дата оплаты</StyledTableCell>
              <StyledTableCell>Сумма</StyledTableCell>
              <StyledTableCell>Комментарий</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell>Сумма заказа</TableCell>
              <TableCell style={{ color: 'green', fontWeight: '700' }}>
                {data.reduce((acc: number, item: any) => {
                  if (
                    item.type === 'refund' &&
                    item.status !== 'refund_failed'
                  ) {
                    return acc - Number(item.amount);
                  }
                  if (
                    item.type === 'payment' &&
                    ![
                      'kaspi_not_found',
                      'canceled',
                      'failed',
                      'cancel_invoice',
                      'invoice_canceled',
                    ].includes(item.status)
                  ) {
                    return acc + Number(item.amount);
                  }
                  return acc;
                }, 0)}
              </TableCell>
              <TableCell />
            </TableRow>
            {filtered.map((row) => (
              <TableRow
                key={row.uuid}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell>{row.label}</TableCell>
                <TableCell component="th" scope="row">
                  Доставка
                </TableCell>
                <TableCell>
                  {formatPaymentMethod(
                    row.payment_method,
                    user === UserRole.MANAGER || user === UserRole.PHARMACIST
                  )}
                </TableCell>
                <TableCell>
                  {row.payment_method === 'interpay' &&
                  row.status === 'wait_capture'
                    ? t(`OrdersPage.STATUSES.interpay_hold`)
                    : t(`OrdersPage.STATUSES.${row.status}`)}
                </TableCell>
                <TableCell>{formatTime(row.updated_at)}</TableCell>
                <TableCell>{getAmount(row)}</TableCell>
                <TableCell>{row.comment}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        className="bottom-block"
        style={{
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {data.length > 1 && data.some((f) => f.status === 'paid') && (
          <div
            className="btn"
            style={{ borderRadius: 0 }}
            onClick={() => setShowAll((prev) => !prev)}
          >
            {showAll ? 'Скрыть' : 'Все операции'}
          </div>
        )}
        <DeliveryComponent />
      </div>
      <div className="bottom-block" style={{ alignItems: 'center' }}>
        <div className="status-wrapper" style={{ marginLeft: 0 }}>
          <div className="status-title">Статуc оплаты за доставку:</div>
          <select
            className="field-status"
            value={paymentDeliveryStatus}
            onChange={(e) => {
              const allPayments = data?.filter((f) => f.type === 'payment');
              const allRefunds = data?.filter((f) => f.type === 'refund');
              setPaymentDeliveryStatus(e.target.value);
              if (
                ['refund_ready', 'refund_failed'].includes(e.target.value) &&
                allRefunds?.length === 1
              ) {
                return setPaymentUuid(allRefunds[0].uuid);
              }
              if (allPayments?.length === 1) {
                setPaymentUuid(allPayments[0].uuid);
              }
            }}
          >
            <option value="pending">Ожидание</option>
            <option value="ready_to_invoice">Выставить счет</option>
            <option value="invoice_ready">Счет выставлен</option>
            <option value="check_invoice">Проверить оплату счета</option>
            <option value="paid">Оплачено</option>
            <option value="failed">Ошибка</option>
            <option value="canceled">Отменен</option>
            <option value="kaspi_not_found">Каспи не найден</option>
            {data.some((f) => f.status === 'paid') && (
              <>
                <option value="ready_to_refund">Сделать возврат</option>
                <option value="refund_ready">Возврат выполнен</option>
                <option value="refund_failed">
                  Не удалось сделать возврат
                </option>
                <option value="repayment">Довыставить счет</option>
              </>
            )}
            {data.some((f) =>
              ['invoice_ready', 'check_invoice'].includes(f.status)
            ) && <option value="cancel_invoice">Отмена инвойса</option>}
            {data.some((f) => f.status === 'cancel_invoice') && (
              <option value="invoice_canceled">Отмена произведена</option>
            )}
          </select>
        </div>
        <button
          type="button"
          className="confirm-btn"
          onClick={handleSetDeliveryPayment}
          disabled={
            (orderData?.status === 'completed' &&
              orderData?.statusPharmacy === 'completed' &&
              paymentDeliveryStatus !== 'ready_to_refund') ||
            (orderData?.status === 'canceled' &&
              orderData?.statusPharmacy === 'canceled' &&
              paymentDeliveryStatus !== 'ready_to_refund')
          }
        >
          {t('OrderPage.StatusOperator.SAVE')}
        </button>
      </div>
      {!['repayment'].includes(paymentDeliveryStatus as string) && (
        <div className="bottom-block" style={{ alignItems: 'center' }}>
          <div className="status-wrapper" style={{ marginLeft: 0 }}>
            <div className="status-title" style={{ minWidth: 98 }}>
              Выберите ID платежа:
            </div>
            <select
              className="field-status"
              value={paymentUuid}
              onChange={(e) => setPaymentUuid(e.target.value)}
            >
              <option value="">Не выбрано</option>
              {!['refund_ready', 'refund_failed'].includes(
                paymentDeliveryStatus as string
              ) &&
                data.map(
                  (row) =>
                    row.type === 'payment' && (
                      <option value={row.uuid}>{row.label}</option>
                    )
                )}
              {['refund_ready', 'refund_failed'].includes(
                paymentDeliveryStatus as string
              ) &&
                data.map(
                  (row) =>
                    row.type === 'refund' && (
                      <option value={row.uuid}>{row.label}</option>
                    )
                )}
            </select>
          </div>
        </div>
      )}
      {['repayment', 'ready_to_refund'].includes(
        paymentDeliveryStatus as string
      ) && (
        <>
          <div className="bottom-block" style={{ alignItems: 'center' }}>
            <div className="status-wrapper" style={{ marginLeft: 0 }}>
              <div className="status-title" style={{ minWidth: 98 }}>
                Введите сумму:
              </div>
              <input
                className="field-status"
                value={paymentAmount}
                onChange={(e) => {
                  if (
                    paymentDeliveryStatus === 'ready_to_refund' &&
                    Number(e.target.value) >
                      (data?.find((f) => f.uuid === paymentUuid)?.amount || 0) -
                        (data
                          ?.find((f) => f.uuid === paymentUuid)
                          ?.refunds?.reduce((acc, item) => {
                            if (item.status !== 'refund_failed') {
                              return acc + Number(item.amount);
                            }
                            return acc;
                          }, 0) || 0)
                  ) {
                    return;
                  }
                  setPaymentAmount(e.target.value);
                }}
              />
              {paymentDeliveryStatus === 'ready_to_refund' && (
                <span style={{ color: 'red' }}>{`< ${
                  (data?.find((f) => f.uuid === paymentUuid)?.amount || 0) -
                  (data
                    ?.find((f) => f.uuid === paymentUuid)
                    ?.refunds?.reduce((acc, item) => {
                      if (item.status !== 'refund_failed') {
                        return acc + Number(item.amount);
                      }
                      return acc;
                    }, 0) || 0)
                }`}</span>
              )}
            </div>
          </div>
          <div className="bottom-block" style={{ alignItems: 'center' }}>
            <div className="status-wrapper" style={{ marginLeft: 0 }}>
              <div className="status-title" style={{ minWidth: 98 }}>
                Комментарий:
              </div>
              <div className="status-combined">
                <input
                  className="field-status"
                  value={paymentComment}
                  onChange={(e) => setPaymentComment(e.target.value)}
                />
                {paymentDeliveryStatus === 'repayment' && (
                  <select
                    className="field-status"
                    value={paymentComment}
                    onChange={(e) => setPaymentComment(e.target.value)}
                  >
                    <option value="">свободный комментарий</option>
                    <option value="межгород">межгород</option>
                    <option value="мультидоставка">мультидоставка</option>
                    <option value="клиент поменял адрес">
                      клиент поменял адрес
                    </option>
                    <option value="долгое ожидание оплаты клиентом">
                      долгое ожидание оплаты клиентом
                    </option>
                  </select>
                )}
                {paymentDeliveryStatus === 'ready_to_refund' && (
                  <select
                    className="field-status"
                    value={paymentComment}
                    onChange={(e) => setPaymentComment(e.target.value)}
                  >
                    <option value="">свободный комментарий</option>
                    <option value="дорогая доставка">дорогая доставка</option>
                    <option value="отмена от клиента">отмена от клиента</option>
                    <option value="аптека закрылась">аптека закрылась</option>
                    <option value="перепутал цифру">перепутал цифру</option>
                  </select>
                )}
              </div>
            </div>
          </div>
        </>
      )}
      <div style={{ color: 'red', fontSize: 14 }}>{customError}</div>
    </div>
  );
};

export const PaymentOrder = ({
  id,
  data,
  itemsData,
  deliveryData,
  DeliveryComponent,
  setLoader,
  noDelivery,
  user,
}: {
  id: string;
  data: PaymentResponseItemCache[];
  itemsData: PaymentResponseItemCache[] | null;
  deliveryData: PaymentResponseItemCache[] | null;
  DeliveryComponent?: any;
  setLoader: any;
  noDelivery: boolean;
  user?: string;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const orderData = useSelector(ordersSelectors.selectedOrderData);
  const token = getFromLocalStorage('accessToken');
  const role =
    getFromLocalStorage('role') === 'manager' ? 'network' : 'pharmacy';

  const [paymentStatus, setPaymentStatus] = useState('');
  const [paymentType, setPaymentType] = useState('');
  const [paymentUuid, setPaymentUuid] = useState('');
  const [paymentComment, setPaymentComment] = useState('');
  const [paymentAmount, setPaymentAmount] = useState('');
  const [itemsAmount, setItemsAmount] = useState('');
  const [deliveryAmount, setDeliveryAmount] = useState('');
  const [customError, setCustomError] = useState('');
  const [showAll, setShowAll] = useState(false);
  const [isBannerVisible, setIsBannerVisible] = useState<boolean>(false);
  const [currentData, setCurrentData] = useState<PaymentResponseItemCache[]>(
    []
  );

  const filtered = currentData?.filter((f) => {
    if (data?.length === 1) {
      return true;
    }
    if (!showAll) {
      return f.status !== 'paid' && f.status !== 'refund_ready';
    }
    return true;
  });

  const filteredRefund = () => {
    if (
      orderData?.paymentType !== 'interpay' &&
      orderData?.paymentType !== PaymentType.MB
    ) {
      return currentData;
    }
    if (
      paymentStatus === 'ready_to_refund' ||
      paymentStatus === 'ready_to_refund_payment'
    ) {
      return currentData.filter((el) => el.status === 'paid') || [];
    }
    if (paymentStatus === 'cancel_payment') {
      return (
        currentData.filter(
          (el) =>
            el.status === 'wait_capture' ||
            el.status === 'ready_to_invoice' ||
            el.status === 'pending'
        ) || []
      );
    }
    return [];
  };

  const handleSetOrderPayment = () => {
    if (paymentStatus === 'repayment') {
      if (!paymentAmount || !paymentComment) {
        return setCustomError('Заполните все поля!');
      }
      dispatch(
        actions.postRepayment.request({
          id,
          data: {
            amount: Number(paymentAmount),
            comment: paymentComment,
            payment_type: paymentType,
          },
          postEffect: () => {
            dispatch(
              actions.setSelectedOrder.request({
                role,
                token,
                id,
              })
            );
            setLoader(false);
          },
        })
      );
      setPaymentStatus('');
      setPaymentAmount('');
      setPaymentComment('');
      return;
    }
    if (
      !paymentUuid &&
      ['ready_to_refund', 'ready_to_refund_payment', 'cancel_payment'].includes(
        paymentStatus
      )
    ) {
      return setCustomError('Выберите ID платежа!');
    }
    setCustomError('');
    if (paymentStatus === 'ready_to_refund') {
      if (!paymentAmount || !paymentComment) {
        return setCustomError('Заполните все поля!');
      }
      dispatch(
        actions.postRefund.request({
          id: paymentUuid,
          data: {
            payment_type: paymentType,
            amount: Number(paymentAmount),
            comment: paymentComment,
          },
          postEffect: () => {
            dispatch(
              actions.setSelectedOrder.request({
                role,
                token,
                id,
              })
            );
            setLoader(false);
          },
        })
      );
      setPaymentUuid('');
      setPaymentStatus('');
      setPaymentAmount('');
      setPaymentComment('');
    }
    if (paymentStatus === 'ready_to_refund_payment') {
      if (!paymentComment) {
        return setCustomError('Заполните все поля!');
      }
      dispatch(
        actions.postRefund.request({
          id: paymentUuid,
          data: {
            payment_type: 'order',
            amount: filteredRefund()?.find((el) => el.uuid === paymentUuid)
              ?.amount,
            comment: paymentComment,
          },
          postEffect: () => {
            dispatch(
              actions.setSelectedOrder.request({
                role,
                token,
                id,
              })
            );
            setLoader(false);
          },
        })
      );
      setPaymentUuid('');
      setPaymentStatus('');
      setPaymentAmount('');
      setPaymentComment('');
    }
    if (paymentStatus === 'cancel_payment') {
      if (!paymentComment) {
        return setCustomError('Заполните комментарий!');
      }
      dispatch(
        actions.postCancelPayment.request({
          id: paymentUuid,
          data: {
            payment_uuid: paymentUuid,
            comment: paymentComment,
          },
          postEffect: () => {
            dispatch(
              actions.setSelectedOrder.request({
                role,
                token,
                id,
              })
            );
            setLoader(false);
          },
        })
      );
      setPaymentUuid('');
      setPaymentStatus('');
      setPaymentAmount('');
      setPaymentComment('');
    }
    if (paymentStatus === 'repayment_order') {
      if ((!itemsAmount && !deliveryAmount) || !paymentComment) {
        return setCustomError('Заполните все поля!');
      }
      dispatch(
        actions.postRepayment.request({
          id,
          data: {
            amount: 0,
            comment: paymentComment,
            payment_type: 'order',
            items_amount: Number(itemsAmount),
            delivery_amount: Number(deliveryAmount),
          },
          postEffect: () => {
            dispatch(
              actions.setSelectedOrder.request({
                role,
                token,
                id,
              })
            );
            setLoader(false);
          },
        })
      );
      setPaymentStatus('');
      setPaymentAmount('');
      setItemsAmount('');
      setDeliveryAmount('');
      setPaymentComment('');
    }
  };

  const getTypeName = (row: PaymentResponseItemCache) => {
    if (row.payment_type) {
      if (row.payment_type === 'order') {
        if (user === UserRole.PHARMACIST || user === UserRole.MANAGER) {
          return 'Товары';
        }
        return noDelivery ? 'Товары' : 'Товары+Доставка';
      }
      return t(`OrderPage.PAYMENT_TYPES.${row.payment_type}`);
    }
    if (row.refund_type === 'order') {
      return 'Платеж';
    }
    return row.refund_type === 'items' ? 'Товары' : 'Доставка';
  };

  const getAmount = (row: PaymentResponseItemCache) => {
    if (
      orderData?.paymentType === 'interpay' ||
      orderData?.paymentType === PaymentType.MB
    ) {
      if (row?.payment_type) {
        if (user === UserRole.PHARMACIST || user === UserRole.MANAGER) {
          return row.items_amount;
        }
        return row.amount;
      }
      return row.amount;
    }
    return row.amount;
  };

  const handleCopy = (url: string) => {
    setIsBannerVisible(true);
    navigator.clipboard.writeText(url);
  };

  useEffect(() => {
    if (isBannerVisible) {
      const timeOut = setTimeout(() => {
        setIsBannerVisible(false);
      }, 500);

      return () => clearTimeout(timeOut);
    }
  }, [isBannerVisible]);

  useEffect(() => {
    if (data && data.length > 0) {
      const concatArrItems = data.concat(itemsData || []);
      const concatArrDelivery = concatArrItems.concat(deliveryData || []);
      const isInterpay =
        orderData?.paymentType === PaymentType.INTERPAY ||
        orderData?.paymentType === PaymentType.MB;

      if (isInterpay) {
        let payments = 1;
        let refunds = 1;
        const interpayLabels = concatArrDelivery.map((el) => {
          if (el.type === 'payment') {
            /* eslint-disable no-plusplus */
            return { ...el, label: `Платеж-${payments++}` };
          }
          /* eslint-disable no-plusplus */
          return { ...el, label: `Возврат-${refunds++}` };
        });
        const filteredLabels = interpayLabels.filter((el) =>
          user === UserRole.PHARMACIST || user === UserRole.MANAGER
            ? el.payment_type !== 'delivery' && el.refund_type !== 'delivery'
            : el
        );

        setCurrentData(filteredLabels);
        return;
      }

      setCurrentData(concatArrDelivery);
    }
  }, [data, itemsData, deliveryData]);

  if (!currentData.length) {
    return null;
  }

  return (
    <div style={{ overflow: 'hidden' }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>Тип</StyledTableCell>
              <StyledTableCell>Способ оплаты</StyledTableCell>
              <StyledTableCell>Текущий статус</StyledTableCell>
              <StyledTableCell>Дата оплаты</StyledTableCell>
              <StyledTableCell>Сумма</StyledTableCell>
              <StyledTableCell>Комментарий</StyledTableCell>
              <StyledTableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell>Сумма заказа</TableCell>
              <TableCell style={{ color: 'green', fontWeight: '700' }}>
                {currentData.reduce((acc: number, item: any) => {
                  const amount =
                    user === UserRole.PHARMACIST || user === UserRole.MANAGER
                      ? item.items_amount
                      : item.amount;
                  if (
                    item.type === 'refund' &&
                    item.status !== 'refund_failed'
                  ) {
                    return acc - Number(item.amount);
                  }
                  if (
                    item.type === 'payment' &&
                    ![
                      'kaspi_not_found',
                      'canceled',
                      'failed',
                      'cancel_invoice',
                      'invoice_canceled',
                    ].includes(item.status)
                  ) {
                    return acc + Number(amount);
                  }
                  return acc;
                }, 0)}
              </TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
            {filtered.map((row) => (
              <TableRow
                key={row.uuid}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell>{row.label}</TableCell>
                <TableCell component="th" scope="row">
                  {/* {noDelivery ? 'Товары' : 'Товары+Доставка'} */}
                  {getTypeName(row)}
                </TableCell>
                <TableCell>
                  {formatPaymentMethod(
                    row.payment_method,
                    user === UserRole.MANAGER || user === UserRole.PHARMACIST
                  )}
                </TableCell>
                <TableCell>
                  {row.payment_method === 'interpay' &&
                  row.status === 'wait_capture'
                    ? t(`OrdersPage.STATUSES.interpay_hold`)
                    : t(`OrdersPage.STATUSES.${row.status}`)}
                </TableCell>
                <TableCell>{formatTime(row.updated_at)}</TableCell>
                <TableCell>{getAmount(row)}</TableCell>
                <TableCell>{row.comment}</TableCell>
                <TableCell
                  onClick={() => handleCopy(row.payment_url)}
                  style={{ cursor: 'pointer' }}
                >
                  <img src={copyIcon} alt="copy icon" width={24} height={24} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        className="bottom-block"
        style={{
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {currentData.length > 1 &&
          currentData.some(
            (f) =>
              f.status === 'paid' ||
              f.payment_method === PaymentType.INTERPAY ||
              f.payment_method.includes(PaymentType.MB)
          ) && (
            <div
              className="btn"
              style={{ borderRadius: 0 }}
              onClick={() => setShowAll((prev) => !prev)}
            >
              {showAll ? 'Скрыть' : 'Все операции'}
            </div>
          )}
        {!noDelivery &&
          user !== UserRole.MANAGER &&
          user !== UserRole.PHARMACIST && <DeliveryComponent />}
      </div>
      {(currentData.some((f) => f.status === 'paid') ||
        currentData.some(
          (f) =>
            f.payment_method === 'interpay' ||
            f.payment_method === PaymentType.MB
        )) &&
        user !== UserRole.MANAGER &&
        user !== UserRole.PHARMACIST && (
          <>
            <div className="bottom-block" style={{ alignItems: 'center' }}>
              <div className="status-wrapper" style={{ marginLeft: 0 }}>
                <div className="status-title">За товары или за доставку:</div>
                <select
                  className="field-status"
                  value={paymentType}
                  onChange={(e) => setPaymentType(e.target.value)}
                >
                  <option value="">Не выбрано</option>
                  <option value="items">За товары</option>
                  {orderData?.deliveryMethod !== 'self' && (
                    <option value="delivery">За доставку</option>
                  )}
                  {(orderData?.paymentType === 'interpay' ||
                    orderData?.paymentType === 'mb') && (
                    <option value="payment">За весь платеж</option>
                  )}
                </select>
              </div>
            </div>
            {paymentType && (
              <>
                <div className="bottom-block" style={{ alignItems: 'center' }}>
                  <div className="status-wrapper" style={{ marginLeft: 0 }}>
                    <div className="status-title">Статуc оплаты:</div>
                    <select
                      className="field-status"
                      value={paymentStatus}
                      onChange={(e) => {
                        const allPayments = currentData?.filter(
                          (f) => f.type === 'payment'
                        );
                        setPaymentStatus(e.target.value);
                        if (allPayments?.length === 1) {
                          setPaymentUuid(allPayments[0].uuid);
                        }
                      }}
                    >
                      <option value="">Не выбрано</option>
                      {((orderData?.paymentType === 'interpay' &&
                        currentData?.some((el) => el.status === 'paid')) ||
                        orderData?.paymentType !== 'interpay') &&
                        paymentType !== 'payment' && (
                          <option value="ready_to_refund">
                            Сделать возврат
                          </option>
                        )}
                      {(orderData?.paymentType !== 'interpay' ||
                        !currentData
                          ?.filter((f) => f.type === 'payment')
                          .some(
                            (el) =>
                              el.status === 'ready_to_invoice' ||
                              el.status === 'invoice_ready' ||
                              el.status === 'pending'
                          )) &&
                        paymentType !== 'payment' && (
                          <option value="repayment">Довыставить счет</option>
                        )}
                      {(((orderData?.paymentType === 'interpay' ||
                        orderData?.paymentType === PaymentType.MB) &&
                        currentData?.some((el) => el.status === 'paid')) ||
                        orderData?.paymentType !== 'interpay') &&
                        paymentType === 'payment' && (
                          <option value="ready_to_refund_payment">
                            Возврат всего платежа
                          </option>
                        )}
                      {(orderData?.paymentType === 'interpay' ||
                        orderData?.paymentType === PaymentType.MB) &&
                        paymentType === 'payment' &&
                        orderData?.deliveryType === 'delivery' &&
                        !currentData
                          ?.filter((f) => f.type === 'payment')
                          .some(
                            (el) =>
                              el.status === 'ready_to_invoice' ||
                              el.status === 'invoice_ready' ||
                              el.status === 'pending'
                          ) && (
                          <option value="repayment_order">
                            Довыставить платеж
                          </option>
                        )}
                      {orderData?.paymentType === 'interpay' &&
                        paymentType === 'payment' &&
                        currentData
                          ?.filter((f) => f.type === 'payment')
                          .some(
                            (el) =>
                              el.status === 'ready_to_invoice' ||
                              el.status === 'wait_capture' ||
                              el.status === 'pending'
                          ) && (
                          <option value="cancel_payment">
                            Отменить платеж
                          </option>
                        )}
                    </select>
                  </div>
                  <button
                    type="button"
                    className="confirm-btn"
                    onClick={handleSetOrderPayment}
                    disabled={
                      (orderData?.status === 'completed' &&
                        orderData?.statusPharmacy === 'completed' &&
                        paymentStatus !== 'ready_to_refund' &&
                        orderData?.paymentType !== 'interpay' &&
                        orderData?.paymentType !== PaymentType.MB) ||
                      (orderData?.status === 'canceled' &&
                        orderData?.statusPharmacy === 'canceled' &&
                        paymentStatus !== 'ready_to_refund')
                    }
                  >
                    {t('OrderPage.StatusOperator.SAVE')}
                  </button>
                </div>
                {!['repayment', 'repayment_order'].includes(
                  paymentStatus as string
                ) && (
                  <div
                    className="bottom-block"
                    style={{ alignItems: 'center' }}
                  >
                    <div className="status-wrapper" style={{ marginLeft: 0 }}>
                      <div className="status-title" style={{ minWidth: 98 }}>
                        Выберите ID платежа:
                      </div>
                      <select
                        className="field-status"
                        value={paymentUuid}
                        onChange={(e) => setPaymentUuid(e.target.value)}
                      >
                        <option value="">Не выбрано</option>
                        {filteredRefund().map(
                          (row) =>
                            row.type === 'payment' && (
                              <option value={row.uuid}>{row.label}</option>
                            )
                        )}
                      </select>
                    </div>
                  </div>
                )}
                {[
                  'repayment',
                  'ready_to_refund',
                  'ready_to_refund_payment',
                  'cancel_payment',
                ].includes(paymentStatus as string) && (
                  <>
                    {paymentStatus !== 'cancel_payment' &&
                      paymentType !== 'payment' && (
                        <div
                          className="bottom-block"
                          style={{ alignItems: 'center' }}
                        >
                          <div
                            className="status-wrapper"
                            style={{ marginLeft: 0 }}
                          >
                            <div
                              className="status-title"
                              style={{ minWidth: 98 }}
                            >
                              Введите сумму:
                            </div>
                            <input
                              className="field-status"
                              type="number"
                              value={paymentAmount}
                              onChange={(e) => {
                                if (
                                  paymentStatus === 'ready_to_refund' &&
                                  Number(e.target.value) >
                                    ((orderData?.paymentType === 'interpay' ||
                                    orderData?.paymentType === PaymentType.MB
                                      ? currentData
                                      : data
                                    )?.find((f) => f.uuid === paymentUuid)
                                      ?.amount || 0) -
                                      ((orderData?.paymentType === 'interpay' ||
                                      orderData?.paymentType === PaymentType.MB
                                        ? currentData
                                        : data
                                      )
                                        ?.find((f) => f.uuid === paymentUuid)
                                        ?.refunds?.reduce(
                                          (acc, item) =>
                                            acc + Number(item.amount),
                                          0
                                        ) || 0)
                                ) {
                                  return;
                                }
                                setPaymentAmount(e.target.value);
                              }}
                            />
                            {paymentStatus === 'ready_to_refund' && (
                              <span style={{ color: 'red' }}>{`< ${
                                (currentData?.find(
                                  (f) => f.uuid === paymentUuid
                                )?.amount || 0) -
                                (currentData
                                  ?.find((f) => f.uuid === paymentUuid)
                                  ?.refunds?.reduce(
                                    (acc, item) => acc + Number(item.amount),
                                    0
                                  ) || 0)
                              }`}</span>
                            )}
                          </div>
                        </div>
                      )}
                    <div
                      className="bottom-block"
                      style={{ alignItems: 'center' }}
                    >
                      <div className="status-wrapper" style={{ marginLeft: 0 }}>
                        <div className="status-title" style={{ minWidth: 98 }}>
                          Комментарий:
                        </div>
                        <div className="status-combined">
                          <input
                            className="field-status"
                            value={paymentComment}
                            onChange={(e) => setPaymentComment(e.target.value)}
                          />
                          {paymentStatus === 'repayment' && (
                            <select
                              className="field-status"
                              value={paymentComment}
                              onChange={(e) =>
                                setPaymentComment(e.target.value)
                              }
                            >
                              {paymentType === 'items' && (
                                <>
                                  <option value="Товары: ">
                                    Товары: свободный комментарий
                                  </option>
                                  <option value="Товары: в аптеке дороже">
                                    Товары: в аптеке дороже
                                  </option>
                                  <option value="Товары: некорректная отработка аптеки">
                                    Товары: некорректная отработка аптеки
                                  </option>
                                  <option value="Товары: добавили в заказ">
                                    Товары: добавили в заказ
                                  </option>
                                  <option value="Товары: перепутал цифру">
                                    Товары: перепутал цифру
                                  </option>
                                  <option value="Товары: долгое ожидание оплаты клиентом">
                                    Товары: долгое ожидание оплаты клиентом
                                  </option>
                                </>
                              )}
                              {paymentType === 'delivery' && (
                                <>
                                  <option value="Доставка: ">
                                    Доставка: свободный комментарий
                                  </option>
                                  <option value="Доставка: межгород">
                                    Доставка: межгород
                                  </option>
                                  <option value="Доставка: мультидоставка">
                                    Доставка: мультидоставка
                                  </option>
                                  <option value="Доставка: клиент поменял адрес">
                                    Доставка: клиент поменял адрес
                                  </option>
                                  <option value="Доставка: перепутал цифру">
                                    Доставка: перепутал цифру
                                  </option>
                                  <option value="Доставка: долгое ожидание оплаты клиентом">
                                    Доставка: долгое ожидание оплаты клиентом
                                  </option>
                                </>
                              )}
                            </select>
                          )}
                          {paymentStatus === 'ready_to_refund' && (
                            <select
                              className="field-status"
                              value={paymentComment}
                              onChange={(e) =>
                                setPaymentComment(e.target.value)
                              }
                            >
                              {paymentType === 'items' && (
                                <>
                                  <option value="Товары: ">
                                    Товары: свободный комментарий
                                  </option>
                                  <option value="Товары: в аптеке дешевле">
                                    Товары: в аптеке дешевле
                                  </option>
                                  <option value="Товары: в аптеке скидка">
                                    Товары: в аптеке скидка
                                  </option>
                                  <option value="Товары: переоформленный заказ в другую аптеку">
                                    Товары: переоформленный заказ в другую
                                    аптеку
                                  </option>
                                  <option value="Товары: некорректная отработка аптеки">
                                    Товары: некорректная отработка аптеки
                                  </option>
                                  <option value="Товары: перепутал цифру">
                                    Товары: перепутал цифру
                                  </option>
                                  <option value="Товары: убрали с заказа">
                                    убрали с заказа
                                  </option>
                                  <option value="Товары: аптека закрылась">
                                    Товары: аптека закрылась
                                  </option>
                                  <option value="Товары: нет в наличии">
                                    Товары: нет в наличии
                                  </option>
                                  <option value="Товары: сроковый препарат">
                                    Товары: сроковый препарат
                                  </option>
                                </>
                              )}
                              {paymentType === 'delivery' && (
                                <>
                                  <option value="Доставка: ">
                                    Доставка: свободный комментарий
                                  </option>
                                  <option value="Доставка: перепутал цифру">
                                    Доставка: перепутал цифру
                                  </option>
                                  <option value="Доставка: дорогая доставка">
                                    Доставка: дорогая доставка
                                  </option>
                                  <option value="Доставка: отмена от клиента">
                                    Доставка: отмена от клиента
                                  </option>
                                  <option value="Доставка: переоформленный заказ в другую аптеку">
                                    Доставка: переоформленный заказ в другую
                                    аптеку
                                  </option>
                                </>
                              )}
                            </select>
                          )}
                          {paymentStatus === 'ready_to_refund_payment' && (
                            <select
                              className="field-status"
                              value={paymentComment}
                              onChange={(e) =>
                                setPaymentComment(e.target.value)
                              }
                            >
                              <option value="Возврат всего платежа: ">
                                Возврат всего платежа: свободный комментарий
                              </option>
                            </select>
                          )}
                          {paymentStatus === 'cancel_payment' && (
                            <select
                              className="field-status"
                              value={paymentComment}
                              onChange={(e) =>
                                setPaymentComment(e.target.value)
                              }
                            >
                              <option value="Отмена платежа: ">
                                Отмена платежа: свободный комментарий
                              </option>
                            </select>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {['repayment_order'].includes(paymentStatus as string) && (
                  <>
                    <div className="bottom-block-order-repayment">
                      <div className="status-wrapper" style={{ marginLeft: 0 }}>
                        <div className="status-title" style={{ minWidth: 98 }}>
                          Сумма за товары:
                        </div>
                        <input
                          className="field-status"
                          type="number"
                          value={itemsAmount}
                          min="0"
                          onChange={(e) => {
                            const { value } = e.target;
                            !value.startsWith('-') &&
                              Number(value) >= 0 &&
                              setItemsAmount(value);
                          }}
                        />
                      </div>
                      <div className="status-wrapper" style={{ marginLeft: 0 }}>
                        <div className="status-title" style={{ minWidth: 98 }}>
                          Сумма за доставку:
                        </div>
                        <input
                          className="field-status"
                          type="number"
                          value={deliveryAmount}
                          min="0"
                          onChange={(e) => {
                            const { value } = e.target;
                            !value.startsWith('-') &&
                              Number(value) >= 0 &&
                              setDeliveryAmount(value);
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className="bottom-block"
                      style={{ alignItems: 'center' }}
                    >
                      <div className="status-wrapper" style={{ marginLeft: 0 }}>
                        <div className="status-title" style={{ minWidth: 98 }}>
                          Комментарий:
                        </div>
                        <div className="status-combined">
                          <input
                            className="field-status"
                            value={paymentComment}
                            onChange={(e) => setPaymentComment(e.target.value)}
                          />
                          <select
                            className="field-status"
                            value={paymentComment}
                            onChange={(e) => setPaymentComment(e.target.value)}
                          >
                            <option value="Довыставление платежа: ">
                              Довыставление платежа: свободный комментарий
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div style={{ color: 'red', fontSize: 14 }}>{customError}</div>
              </>
            )}
          </>
        )}
      {isBannerVisible && (
        <NotificationWrapper>
          <Notification
            onClose={() => setIsBannerVisible(false)}
            id={0}
            type="success"
            content={t('OrdersPage.COPY_SUCCESS')}
            canBeClosed
          />
        </NotificationWrapper>
      )}
    </div>
  );
};

const StatusOperator = ({
  onCancelClick,
  data,
  setIsAutoDeliveryModalOpen,
  setLoader,
  dataNetwork,
}: StatusOperatorProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const token = getFromLocalStorage('accessToken');
  const userRole = getFromLocalStorage('role');
  const schedule = useSelector(ordersSelectors.scheduleData);
  const [contacts, setContacts] = useState(schedule);
  const allStatuses = useSelector(selectors.allStatuses).filter(
    (el: any) => el.code !== StatusType.CANCELED
  );
  const [disableStatusButton, setDisableStatusButton] =
    useState<boolean>(false);

  const errorEditOrder = useSelector(selectors.editError);
  const errorPutStatus = useSelector(ordersSelectors.ordersPageError);
  const noti = useSelector(notiSelectors.notifications);
  const [activeTab, setActiveTab] = useState<string>('contacts');
  const hasTag1044 = data.sourceTags?.some((tag) => tag.id === 1044);

  const filteredStatusNext = data.statusNext?.filter((el) => {
    if (hasTag1044) {
      return (
        el !== 'in_pharmacy_collecting' &&
        el !== 'in_pharmacy_issued' &&
        el !== 'in_pharmacy_collected' &&
        el !== 'completed'
      );
    }
    return data.statusNext;
  });
  const nextSt = data.statusNext.length !== 0 ? filteredStatusNext : [''];

  const getTitle = (title: string) => {
    switch (title) {
      case 'enabled':
        return 'включен';
      case 'disabled':
        return 'отключен';
      case 'manual_mode':
        return 'на ручнике';
      case 'automator_is_disabled':
        return 'отключен';
      default:
        return '';
    }
  };

  const deliveryNetworkCode = 'daribar';

  useEffect(() => {
    setContacts(schedule);
  }, [schedule]);

  useEffect(() => {
    setContacts(null);
  }, []);

  const setStatusNameFn = (code: string) => {
    return allStatuses.find((el: any) => el.code === code)?.name;
  };
  const [stateStatus, setStateStatus] = useState<string>(
    (nextSt && nextSt[0]) || ''
  );

  const [isOpenSuggestion, setOpenSuggestion] = useState<boolean>(false);
  const role =
    getFromLocalStorage('role') === 'manager' ? 'network' : 'pharmacy';

  const [statusName, setStatusName] = useState<string>(
    nextSt ? setStatusNameFn(nextSt[0]) : ''
  );

  const handleSuggestionCLick = (status: string, statusName: string) => {
    setStateStatus(status);
    setStatusName(statusName);
    setOpenSuggestion(false);
  };

  useEffect(() => {
    nextSt && setStateStatus(nextSt[0] || '');
    nextSt && setStatusName(setStatusNameFn(nextSt[0]) || '');
    setDisableStatusButton(false);
  }, [data.status]);

  const handleChangeStatus = () => {
    if (disableStatusButton) {
      return;
    }

    setDisableStatusButton(true);
    stateStatus.length !== 0 &&
      dispatch(
        actions.putStatus.request({
          id: data?.id,
          token,
          status: stateStatus,
          postEffect: () => {
            dispatch(
              actions.setSelectedOrder.request({
                role,
                id: data.id,
                postEffect: () => {
                  setDisableStatusButton(false);
                },
                errorEffect: () => {
                  setDisableStatusButton(false);
                },
              })
            );
            if (
              data?.deliveryType === 'delivery' &&
              data?.deliveryMethod === 'delivery_yandex' &&
              stateStatus === 'ready'
            ) {
              setIsAutoDeliveryModalOpen(true);
            }
          },
          errorEffect: () => {
            setDisableStatusButton(false);
          },
        })
      );
  };

  const handleHistoryClick = () => {
    history.push(
      `/orders/${data.orderNumber.slice(1)}/history?from=${
        data.originalCreatedAt
      }`
    );
  };

  const errorTextStrategy = {
    [ErrorType.EXPECTED_PAID]: t('OrderPage.StatusOperator.EXPECTED_PAID'),
    [ErrorType.EXPECTED_PAID_DELIVERY]: t(
      'OrderPage.StatusOperator.EXPECTED_PAID'
    ),
    [ErrorType.EXPECTED_WAITING_FOR_DELIVERY_PAYMENT]: t(
      'OrderPage.StatusOperator.EXPECTED_WAITING_FOR_DELIVERY_PAYMENT'
    ),
    [ErrorType.EXPECTED_WAITING_FOR_PAYMENT]: t(
      'OrderPage.StatusOperator.EXPECTED_WAITING_FOR_PAYMENT'
    ),
  };
  const disableStatus =
    (data.status === StatusType.CANCELED ||
      data.status === StatusType.COMPLETED) &&
    data.soft === 'STANDART';
  return (
    <Styled.StatusContainer>
      <Styled.CancelAndHistoryBlock className="cancel-and-history-container">
        <div className="status-history" onClick={handleHistoryClick}>
          {' '}
          {t('OrderPage.StatusOperator.HISTORY_STATUS')}
        </div>
        {(userRole === UserRole.ADMIN ||
          userRole === UserRole.OPERATOR ||
          userRole === UserRole.HEAD_OPERATOR) &&
          data.status !== StatusType.CANCELED && (
            <div className="cancel-btn" onClick={onCancelClick}>
              {t('OrdersPage.OrderDetailedModal.controls.CANCEL_OPERATOR')}
            </div>
          )}
      </Styled.CancelAndHistoryBlock>
      <Styled.OperatorContainer>
        <div className="active-set-status-block">
          <Styled.ActiveStatus>
            <div className="title">{t('OrderPage.StatusOperator.STATUS')}</div>
            <div className="status" style={{ color: `#${data.statusColor}` }}>
              {data.statusName}
            </div>
          </Styled.ActiveStatus>
          {((data.status !== StatusType.CANCELED &&
            data.status !== StatusType.COMPLETED) ||
            userRole === UserRole.ADMIN) &&
            !disableStatus && (
              <Styled.SetStatus>
                {(userRole === UserRole.ADMIN ||
                  userRole === UserRole.HEAD_OPERATOR ||
                  userRole === UserRole.OPERATOR) &&
                data.soft === 'INFO_APTEKA' &&
                data.status === StatusType.ON_CLARIFICATION ? (
                  <div />
                ) : (
                  <div className="status-block">
                    <div className="title">
                      {t('OrderPage.StatusOperator.NEW_STATUS')}
                    </div>
                    <div
                      className="set-status"
                      onClick={() => setOpenSuggestion(!isOpenSuggestion)}
                    >
                      <div className="text">{statusName}</div>
                      <img src={bottomArrow} alt="bottom arrow" />
                    </div>
                  </div>
                )}
                {(userRole === UserRole.ADMIN ||
                  userRole === UserRole.HEAD_OPERATOR ||
                  userRole === UserRole.OPERATOR) &&
                data.soft === 'INFO_APTEKA' &&
                data.status === StatusType.ON_CLARIFICATION ? (
                  <div />
                ) : (
                  <Styled.ConfirmButton>
                    {data.status !== StatusType.NEW && (
                      <div className="btn-text" onClick={handleChangeStatus}>
                        {t('OrderPage.StatusOperator.BTN_TEXT')}
                      </div>
                    )}
                    {data.status === StatusType.NEW && (
                      <div className="btn-text" onClick={handleChangeStatus}>
                        {t('OrderPage.StatusOperator.BTN_TEXT')}
                      </div>
                    )}
                  </Styled.ConfirmButton>
                )}
                {isOpenSuggestion && (
                  <SuggestionStatus
                    onClick={handleSuggestionCLick}
                    onClose={() => setOpenSuggestion(false)}
                    next={nextSt}
                    soft={data.soft}
                    data={data}
                    role={userRole}
                  />
                )}
              </Styled.SetStatus>
            )}
        </div>
        <div className="active-set-status-block" style={{ marginTop: 24 }}>
          <Styled.ActiveStatus>
            <div className="title">
              {t('OrderPage.StatusOperator.STATUS_PHARMACY')}
            </div>
            <div
              className="status"
              style={{ color: `#${data.statusColorPharmacy}` }}
            >
              {data.statusNamePharmacy}
            </div>
          </Styled.ActiveStatus>
          {dataNetwork && dataNetwork[data.networkCode] && (
            <div className="wrapper-">
              <div
                className={`network-button-${
                  dataNetwork[data.networkCode]?.automation_state
                }`}
              >
                {dataNetwork[data.networkCode]?.automation_state ===
                'automator_is_disabled'
                  ? 'Автоматор'
                  : data.networkCode}
                :{' '}
                {dataNetwork[data.networkCode]?.automation_state && (
                  <span
                    className={`text-accent-${
                      dataNetwork[data.networkCode]?.automation_state
                    }`}
                  >
                    {getTitle(dataNetwork[data.networkCode]?.automation_state)}
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          <PaymentOrder
            id={data?.id}
            data={data.payments?.order}
            itemsData={data.payments?.items}
            deliveryData={data.payments?.delivery}
            setLoader={setLoader}
            noDelivery={data.deliveryType === 'self'}
            DeliveryComponent={() => (
              <div
                className="btn"
                onClick={() => setIsAutoDeliveryModalOpen(true)}
              >
                Вызов курьера
              </div>
            )}
          />
          {data?.paymentType !== PaymentType.INTERPAY &&
            data?.paymentType !== PaymentType.MB && (
              <PaymentItems
                id={data?.id}
                data={data.payments?.items}
                defaultPaymentStatus={data.paymentStatus || ''}
                setLoader={setLoader}
                user={userRole}
              />
            )}
          {data?.deliveryType === 'delivery' &&
            !data.payments?.order.length && (
              <div style={{ marginTop: 24 }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {dataNetwork && dataNetwork[deliveryNetworkCode] && (
                    <div
                      className={`network-button-${dataNetwork[deliveryNetworkCode]?.automation_state}`}
                    >
                      {dataNetwork[deliveryNetworkCode]?.automation_state ===
                      'automator_is_disabled'
                        ? 'Автоматор'
                        : deliveryNetworkCode}
                      :{' '}
                      {dataNetwork[deliveryNetworkCode]?.automation_state && (
                        <span
                          className={`text-accent-${dataNetwork[deliveryNetworkCode]?.automation_state}`}
                        >
                          {getTitle(
                            dataNetwork[deliveryNetworkCode]?.automation_state
                          )}
                        </span>
                      )}
                    </div>
                  )}
                </div>
                {data?.paymentType !== PaymentType.INTERPAY &&
                  data?.paymentType !== PaymentType.MB && (
                    <PaymentDelivery
                      id={data?.id}
                      data={data.payments?.delivery}
                      defaultPaymentStatus={data.deliveryPaymentStatus || ''}
                      defaultPrice={data.delivery.priceFact || '0'}
                      setLoader={setLoader}
                      user={userRole}
                      // eslint-disable-next-line react/no-unstable-nested-components
                      DeliveryComponent={() => (
                        <div
                          className="btn"
                          onClick={() => setIsAutoDeliveryModalOpen(true)}
                        >
                          Вызов курьера
                        </div>
                      )}
                    />
                  )}
              </div>
            )}
        </div>
        {(errorPutStatus || errorEditOrder) &&
          noti.find((el: NotificationProps) => el.type === 'error') && (
            <>
              <div
                className={`error-text ${
                  data.statusNamePharmacy && 'error-text-margin'
                }`}
              >
                {/* @ts-ignore */}
                {errorTextStrategy[errorPutStatus?.error]}
              </div>
              <div
                className={`error-text ${
                  data.statusNamePharmacy && 'error-text-margin'
                }`}
              >
                {/* @ts-ignore */}
                {errorTextStrategy[errorEditOrder?.error]}
              </div>
            </>
          )}
        {(userRole === UserRole.ADMIN ||
          userRole === UserRole.OPERATOR ||
          userRole === UserRole.HEAD_OPERATOR) && (
          <>
            <div className="flex-container">
              <button
                className="button-contacts"
                type="button"
                onClick={() => {
                  setActiveTab('contacts');
                  !contacts &&
                    dispatch(
                      actions.getSchedule.request({
                        sourceCode: data.source,
                      })
                    );
                }}
              >
                {t('ProductsPage.CONTACTS')}
              </button>
              <button
                className="button-contacts"
                type="button"
                onClick={() => {
                  setActiveTab('schedule');
                }}
              >
                {t('ProductsPage.SCHEDULE')}
              </button>
            </div>
            {activeTab === 'contacts' ? (
              contacts?.map((item) => (
                <tr className="contacts-list item" key={item.phone}>
                  <td className="text-contacts">{item.name}</td>
                  <td className="textAccent">{item.phone}</td>
                  <td className="text">
                    {item.role === UserRole.MANAGER &&
                      t('OrderHistoryPage.UserRole.MANAGER')}
                    {item.role === UserRole.OPERATOR &&
                      t('OrderHistoryPage.UserRole.OPERATOR')}
                    {item.role === UserRole.HEAD_OPERATOR &&
                      t('OrderHistoryPage.UserRole.HEAD_OPERATOR')}
                    {item.role === UserRole.PHARMACIST &&
                      t('OrderHistoryPage.UserRole.PHARMACIST')}
                  </td>
                  <td className="text-contacts">
                    <div className={item.active ? 'online' : 'offline'} />
                  </td>
                </tr>
              ))
            ) : (
              <div className="schedule">{data?.schedule}</div>
            )}
          </>
        )}
      </Styled.OperatorContainer>
    </Styled.StatusContainer>
  );
};

export default StatusOperator;
