import styled from 'styled-components';
import { baseTheme } from '../../global/styles/theme';

export const ErrorAlertModalContainer = styled('div')`
  /*     position: absolute; */
  background-color: #cc9933;
  width: 100%;
  left: 0;
  z-index: 99999;
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  border: 1px solid #e0e5eb;
  border-radius: 8px;
  margin: 34px 0 65px 0;
  display: flex;
  flex-direction: column;

  .content-container {
    display: flex;
  }

  .copy-button {
    font-weight: 500;
    font-size: 18px;
    cursor: pointer;
    color: #ffffff;
    background-color: #0a77c7;
    border-radius: 8px;
    padding: 12px 24px;
    text-align: center;
    max-width: 320px;
    margin: 24px 0 34px 0;
  }

  .title-text-container {
    max-width: 550px;
  }

  .error-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 550px;
  }

  .error-title {
    margin: 0px;
    margin: 0 auto;
    font-size: 20px;
    font-weight: 500;
    color: ${baseTheme.colors.white};
    margin: 2px;
  }

  .call-center-title {
    font-size: 20px;
    font-weight: 500;
    color: ${baseTheme.colors.white};
    margin: 0px;

    span {
      color: ${baseTheme.colors.mainLightBlue};
    }
  }

  .error-text {
    font-size: 20px;
    font-weight: 500;
    color: ${baseTheme.colors.white};
    margin: 2px;
  }

  .timer-text {
    font-size: 26px;
    font-weight: 700;
    color: ${baseTheme.colors.white};
  }

  .buttons-container {
    display: flex;
    align-items: center;
  }

  .close-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    background-color: ${baseTheme.colors.focusLight};
    width: 30px;
    height: 30px;
    border-radius: 2px;
    color: ${baseTheme.colors.white};
    border: 1px solid ${baseTheme.colors.stroking};
  }
`;
