import React, { useState, useEffect } from 'react';
import Pagination from '@mui/material/Pagination';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as Styled from './PromoCodes.styles';
import { selectors } from '../../reducer';
import { PromoCodesProps } from '../../types';
import CustomToggle from '../../../../components/CustomToggle';

import * as actions from '../../actions';
import { formatTime } from '../../../../global/helpers';

interface Props {
  filter: string;
}

const PromoCodes = ({ filter }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const itemsPerPage = 5;

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);

  const promoCodes = useSelector(selectors.promoCodes);

  useEffect(() => {
    if (promoCodes) {
      const totalPages = Math.ceil(promoCodes.length / itemsPerPage);
      setCount(totalPages);
    }
  }, [promoCodes]);

  const onClick = async (code: string, enabled: boolean) => {
    await dispatch(
      actions.activatePromoCodes.request({
        code,
        enabled: !enabled,
        postEffect: () => {
          dispatch(actions.getPromoCodesData.request());
        },
      })
    );
  };
  useEffect(() => {
    if (promoCodes) {
      const filteredPromoCodes = promoCodes.filter((template: any) => {
        if (filter === 'active') {
          return template.enabled;
        }
        return !template.enabled;
      });

      const totalPages = Math.ceil(filteredPromoCodes.length / itemsPerPage);
      setCount(totalPages);
      setPage(1);
    }
  }, [promoCodes, filter, itemsPerPage]);

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(Number(event.target.value));
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const renderPromoCodes = () => {
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const filteredPromoCodes = promoCodes?.filter(
      (template: PromoCodesProps) => {
        if (filter === 'active') {
          return template.enabled;
        }
        return !template.enabled;
      }
    );

    return filteredPromoCodes
      ?.slice(startIndex, endIndex)
      .map((template: PromoCodesProps) => (
        <div key={template.id} className="container-list">
          <div className="cell-accent">{template.id}</div>
          <div className="cell">{template.user}</div>
          <div className="cell-promo cell--ulg">
            <CustomToggle
              onChange={(e) => {
                onClick(template.code, template.enabled);
              }}
              id="activate-promo-code"
              checked={template.enabled}
              variant="standart"
            />
            <p className="promo-code-value">{template.code}</p>
          </div>
          <div className="cell">{template.description}</div>
          <div className="cell-accent">{template.discount.target}</div>
          <div className="cell-accent">{template.discount.type}</div>
          <div className="cell-accent">{template.discount.value}</div>
          <div className="cell-accent">{template.limits.max_usages_total}</div>
          <div className="cell-accent">{template.limits.max_usages_user}</div>
          <div className="cell-accent">{template.limits.max_user_orders}</div>
          <div className="cell-accent">{template.limits.min_order_price}</div>
          <div className="cell">
            {!template.valid_from
              ? 'бессрочный'
              : formatTime(template.valid_from as string)}
          </div>
          <div className="cell">
            {!template.valid_until
              ? 'бессрочный'
              : formatTime(template.valid_until as string)}
          </div>
        </div>
      ));
  };

  return (
    <Styled.Templates>
      <div className="container-header">
        <div className="cell-header-accent">
          {t('PROMO_CODE_PAGE.ID_TEMPLATE')}
        </div>
        <div className="cell-header">{t('PROMO_CODE_PAGE.USER_TEMPLATE')}</div>
        <div className="cell-promo-header cell-header--ulg">
          {t('PROMO_CODE_PAGE.CODE_TEMPLATE')}
        </div>
        <div className="cell-header">
          {t('PROMO_CODE_PAGE.DESCRIPTION_TEMPLATE')}
        </div>
        <div className="cell-header-accent">
          {t('PROMO_CODE_PAGE.TARGET_TEMPLATE')}
        </div>
        <div className="cell-header-accent">
          {t('PROMO_CODE_PAGE.TYPE_TEMPLATE')}
        </div>
        <div className="cell-header-accent">
          {t('PROMO_CODE_PAGE.VALUE_TEMPLATE')}
        </div>
        <div className="cell-header-accent">
          {t('PROMO_CODE_PAGE.MAX_USAGES_TOTAL_TEMPLATE')}
        </div>
        <div className="cell-header-accent">
          {t('PROMO_CODE_PAGE.MAX_USAGES_USERS_TEMPLATE')}
        </div>
        <div className="cell-header-accent">
          {t('PROMO_CODE_PAGE.MAX_USER_ORDERS_TEMPLATE')}
        </div>
        <div className="cell-header-accent">
          {t('PROMO_CODE_PAGE.MIN_ORDER_PRICE_TEMPLATE')}
        </div>
        <div className="cell-header">{t('PROMO_CODE_PAGE.FROM_TEMPLATE')}</div>
        <div className="cell-header">{t('PROMO_CODE_PAGE.TO_TEMPLATE')}</div>
      </div>

      <div className="container-table">{renderPromoCodes()}</div>

      <Styled.PaginationContainer>
        <input
          type="tel"
          max={count}
          value={page}
          onChange={handleChangeInput}
        />
        <Pagination count={count} onChange={handleChange} page={page} />
      </Styled.PaginationContainer>
    </Styled.Templates>
  );
};

export default PromoCodes;
