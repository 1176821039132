import styled from 'styled-components';
import { baseTheme } from '../../../../../../global/styles/theme';

export const OrdersTableRow = styled('tr')`
  position: relative;
  background-color: ${baseTheme.colors.white};
  width: 100%;

  .table-cell-canceled {
    padding: 16px 10px 120px 0 !important;
  }

  .table-cell {
    padding: 16px 10px 16px 0;
    border-top: 1px solid ${baseTheme.colors.stroking};
    font-size: 16px;
    font-weight: 400;
    color: ${baseTheme.colors.primary};
    cursor: pointer;
    width: 8%;

    .order-timer {
      width: 110px;
      font-size: 24px;
      font-weight: 700;
      padding-right: 0;
    }

    .timer-green {
      color: ${baseTheme.colors.focus};
    }

    .timer-orange {
      color: ${baseTheme.colors.warning};
    }

    .timer-red {
      color: ${baseTheme.colors.error};
    }

    .timer-grey {
      color: ${baseTheme.colors.disabled};
    }

    @media only screen and (max-width: 550px) {
      .order-timer {
        width: 45px;
        font-size: 16px;
      }
    }
  }

  .vertical-align-top {
    vertical-align: top;
  }
  .table-cell__order-timer {
    width: 8.5%;
  }
  .table-cell__order-timer-pharmacist {
    width: 12.5%;
  }
  .table-cell__order-timer-manager {
    width: 9.8%;
  }
  .table-cell-source {
    width: 7%;
  }
  .phoneNumber-cell {
    width: 9.4%;
  }
  .table-cell-pharmacy {
    width: 9%;
  }
  .table-cell-collector {
    width: 8.3%;
  }

  .table-cell-delivery-status {
    width: 12.7%;
  }

  .table-cell-delivery {
    width: 6.7%;
  }

  .table-cell-delivery-manager {
    width: 7.7%;
  }

  .table-cell-delivery-pharmacist {
    width: 9.7%;
  }

  .table-cell-order-status {
    width: 12.7%;
  }

  .table-cell-order-status-manager {
    width: 14.5%;
  }

  .table-cell-order-status-pharmacist {
    width: 18.5%;
  }

  .table-cell__created {
    color: ${baseTheme.colors.secondary};
    width: 6.4%;
  }

  .table-cell__created-manager {
    color: ${baseTheme.colors.secondary};
    width: 13.7%;
  }

  .table-cell__created-pharmacist {
    color: ${baseTheme.colors.secondary};
    width: 9.4%;
  }

  .table-cell__order-number {
    font-size: 18px;
    font-weight: 500;
    color: ${baseTheme.colors.primary};
    width: 7.5%;

    @media only screen and (max-width: 550px) {
      font-size: 16px;
    }
  }
  .table-cell__order-number-manager {
    font-size: 18px;
    font-weight: 500;
    color: ${baseTheme.colors.primary};
    width: 13.8%;

    @media only screen and (max-width: 550px) {
      font-size: 16px;
    }
  }

  .table-cell__order-number-pharmacist {
    font-size: 18px;
    font-weight: 500;
    color: ${baseTheme.colors.primary};
    width: 10.8%;

    @media only screen and (max-width: 550px) {
      font-size: 16px;
    }
  }

  .table-cell__amount {
    width: 5.8%;
    padding: 16px 0 20px 0 !important;
  }

  .table-cell__amount-manager {
    width: 6.5%;
    padding: 16px 0 20px 0 !important;
  }

  .table-cell__amount-pharmacist {
    width: 8.5%;
    padding: 16px 0 20px 0 !important;
  }

  .table-cell__amount-discount {
    text-decoration: line-through;
  }

  .table-cell__amount-accent {
    font-weight: 700;
    color: #eb5757;
  }

  .table-cell__payment-status {
    width: 6%;

    .operator-payment-type {
      color: grey;
    }

    img {
      margin: 0 10px -4px 0;
    }

    .payment-true-block {
      display: flex;
      flex-direction: column;

      .was_paid_at {
        padding-top: 5px;
        font-size: 14px;
        color: ${baseTheme.colors.secondary};
      }
    }
  }

  .table-cell__payment-status-manager {
    width: 7%;

    img {
      margin: 0 10px -4px 0;
    }

    .payment-true-block {
      display: flex;
      flex-direction: column;

      .was_paid_at {
        padding-top: 5px;
        font-size: 14px;
        color: ${baseTheme.colors.secondary};
      }
    }
  }

  .table-cell__payment-status-pharmacist {
    width: 8.6%;

    img {
      margin: 0 10px -4px 0;
    }

    .payment-true-block {
      display: flex;
      flex-direction: column;

      .was_paid_at {
        padding-top: 5px;
        font-size: 14px;
        color: ${baseTheme.colors.secondary};
      }
    }
  }
  .item-yellow {
    animation-duration: 1s;
    animation-name: slidein-red;
    animation-iteration-count: infinite;

    @keyframes slidein-red {
      0% {
        background-color: ${baseTheme.colors.error};
        color: ${baseTheme.colors};
      }

      50% {
        background-color: ${baseTheme.colors.white};
        color: ${baseTheme.colors.secondary};
      }

      100% {
        background-color: ${baseTheme.colors.error};
        color: ${baseTheme.colors.white};
      }
    }
  }
  .table-cell__payment {
    padding: 16px 40px 20px 5px !important;
  }

  .table-cell__payment-manager {
    padding: 16px 40px 20px 5px !important;
    width: 17%;
  }

  .table-cell__payment-pharmacist {
    padding: 16px 40px 20px 5px !important;
    width: 22%;
  }

  .table-cell__source {
    padding: 16px 40px 20px 25px !important;
    width: 10%;
  }

  .in-pharmacy {
    font-weight: 400;
    font-size: 14px;
    padding: 8px 0;
  }

  .orderNumber-block,
  .phoneNumber-block {
    display: flex;

    img {
      margin: 3px 0 0 4px;
      width: 16px !important;
      height: auto;
    }
  }
`;

export const IconsContainer = styled('div')`
  cursor: pointer;
  margin: 0 12px;
  display: flex;
  align-items: center;

  div {
    width: 18px;
    height: 19px;
  }

  img {
    margin-top: -4px;
  }
`;

export const OrderCancelTableRow = styled('div')<{ education?: boolean }>`
  max-width: 1380px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1.05fr;
  position: absolute;
  z-index: 200;
  margin-top: ${({ education }) => (education ? '0px' : '-90px')};
  @media only screen and (max-width: 550px) {
    width: 95%;
  }
  .comment-area {
    display: flex;

    &:first-child {
      width: 98%;
    }

    .disable {
      pointer-events: none;
      background: ${baseTheme.colors.disabled};
    }

    .error-tooltip {
      background-color: #f6f9ff;
      filter: drop-shadow(0px 1px 8px rgba(0, 58, 136, 0.15));
      border-radius: 4px;
      color: ${baseTheme.colors.secondary};
      font-size: 16px;
      font-weight: 400;
      padding: 8px 16px;
      position: absolute;
      top: -43px;
      white-space: nowrap;
    }
  }

  .textarea {
    min-height: 40px !important;
    height: 40px !important;
  }
`;

export const OrderUserCancelMassage = styled('div')`
  max-width: 1216px;
  width: 100%;
  margin-top: -46px;
  color: ${baseTheme.colors.primary};
  position: absolute;
  display: flex;
  z-index: 300;
  @media only screen and (max-width: 550px) {
    width: 95%;
  }

  div {
    width: 80%;
    margin: 10px 0 10px -55px;
    word-break: break-all;
  }

  .user_cancel_msg {
    color: ${baseTheme.colors.secondary} !important;
    margin: 10px 8px 0 10px !important;
    width: 20% !important;
  }
`;

export const NotificationWrapper = styled('div')`
  max-height: 60px;
  position: fixed;
  top: 56px;
  left: calc(50vw - 425px / 2);
  width: 425px;
  z-index: 600;
`;
