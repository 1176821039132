import styled from 'styled-components';
import { baseTheme } from '../../global/styles/theme';

export const Wrapper = styled('div')`
  max-height: 60vh;
  max-width: 500px;
`;

export const ContactWrapper = styled('div')`
  .title {
    font-size: 20px;
    font-weight: bold;
    margin: 16px 0;
    width: 100%;
    text-align: center;
  }

  .buttonBlock {
    width: 100%;

    .mainBtn {
      display: flex;
      justify-content: center;
      padding: 10px 40px;
      border-radius: 8px;
      border: 2px solid ${baseTheme.colors.mainLightBlue};
      color: ${baseTheme.colors.mainLightBlue};
      font-weight: 500;
      font-size: 18px;
      margin-top: 10px;
      background: transparent;
      cursor: pointer;

      @media only screen and(max-width: 1023px) {
        justify-content: center;
      }

      &:hover {
        border: 2px solid ${baseTheme.colors.mainBlue};
        background-color: ${baseTheme.colors.mainBlue};
        color: ${baseTheme.colors.white};
      }
    }
  }
`;
