export const GET_NETWORKS = 'GET_NETWORKS';
export const GET_SOURCES = 'GET_SOURCES';
export const GET_TAGS_PHARM = 'GET_TAGS_PHARM';
export const PUT_SOURCE = 'PUT_SOURCE';
export const ACTIVATE_SOURCE = 'ACTIVATE_SOURCE';
export const DEACTIVATE_SOURCE = 'DEACTIVATE_SOURCE';
export const SET_VISIBLE_SOURCE = 'SET_VISIBLE_SOURCE';
export const SET_INVISIBLE_SOURCE = 'SET_INVISIBLE_SOURCE';
export const DELETE_PHARMACY = 'DELETE_PHARMACY';
export const PHARMACY_ACTIVATE_SYNC = 'PHARMACY_ACTIVATE_SYNC';
export const PHARMACY_DEACTIVATE_SYNC = 'PHARMACY_DEACTIVATE_SYNC';
export const SET_TAG = 'SET_TAG';
export const SET_TAGS_TO_PHARMACY = 'SET_TAGS_TO_PHARMACY';
