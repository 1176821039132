import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { NetworkService } from './service';
import { NetworkResponse } from './types';
import { mappedDataForNetwork } from './helpers';
import * as actionsNotification from '../InitComponent/actions';
import {
  NOTIFICATION_CHANGES_NOT_SAVED,
  NOTIFICATION_CHANGES_SAVED,
} from '../../global/constants';
import { mappedDataForSources } from '../SourcesPage/helpers';
import { SourceResponse } from '../SourcesPage/types';
import { getMappedTagsData, usersOfSource } from '../SourcePage/helpers';
import { PharmacyTag, UserResponse } from '../SourcePage/types';
import { SourceService } from '../SourcePage/service';

interface NetworkParam {
  type: string;
  payload: { networkCode: string };
}

interface NetworkUserParam {
  type: string;
  payload: { networkCode: string };
}

interface GetSourcesResponse {
  status: string;
  result: SourceResponse[] | null;
}

interface UsersResponse {
  status: string;
  result: UserResponse[] | null;
}

interface NetworkEditParam {
  type: string;
  payload: { networkInfo: NetworkResponse; postEffect: any };
}

interface GetNetworkResponse {
  status: string;
  result: NetworkResponse;
}

interface GetNetworksResponse {
  status: string;
  result: NetworkResponse[];
}

interface NetworkTagsParam {
  type: string;
  payload: {
    tagsList: number[];
    networkCode: string;
    postEffect: () => void;
    textMeta: string;
  };
}

export function* getNetworkSaga({ payload }: NetworkParam) {
  try {
    const response: GetNetworkResponse = yield call(
      NetworkService.getNetwork,
      payload.networkCode
    );

    const tagsResponse: { result: PharmacyTag[] } = yield call(
      SourceService.getPharmacyTags,
      'network'
    );

    const mappedTagsResponse = getMappedTagsData(
      tagsResponse.result,
      response.result?.tags
    ) as PharmacyTag[];

    const mappedNetwork = mappedDataForNetwork(
      response.result,
      mappedTagsResponse
    );

    yield put(actions.getNetwork.success(mappedNetwork));

    yield put(actions.getNetworkTags.success(mappedTagsResponse));
  } catch (error) {
    yield put(actions.getNetwork.error(error));
  }
}

export function* getAllNetworkSaga() {
  try {
    const response: GetNetworksResponse = yield call(
      NetworkService.getAllNetworks
    );
    const mappedData = response.result.map((network) =>
      mappedDataForNetwork(network)
    );
    yield put(actions.getAllNetwork.success(mappedData));
  } catch (error) {
    yield put(actions.getAllNetwork.error(error));
  }
}

export function* getSourcesByNetworkSaga({ payload }: NetworkParam) {
  try {
    const response: GetSourcesResponse = yield call(
      NetworkService.getSourcesByNetwork,
      payload.networkCode
    );
    const mappedNetwork = mappedDataForSources(response.result);
    yield put(actions.getSourcesByNetwork.success(mappedNetwork));
  } catch (error) {
    yield put(actions.getSourcesByNetwork.error(error));
  }
}

export function* editNetworkSaga({ payload }: NetworkEditParam) {
  try {
    const response: GetNetworkResponse = yield call(
      NetworkService.editNetwork,
      payload.networkInfo
    );

    if (payload.postEffect) {
      payload.postEffect();
    }

    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_SAVED,
        canBeClosed: true,
        type: 'success',
      })
    );

    yield put(actions.editNetwork.success(response.result));
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.editNetwork.error(error));
  }
}

export function* getUsersNetworkSaga({ payload }: NetworkUserParam) {
  try {
    const responsePharmacist: UsersResponse = yield call(
      NetworkService.getUsers,
      'pharmacist',
      payload.networkCode
    );

    const responseManager: UsersResponse = yield call(
      NetworkService.getUsers,
      'manager',
      payload.networkCode
    );

    yield put(
      actions.getUsersOfNetwork.success(
        usersOfSource(
          responsePharmacist.result
            ?.filter((el) => el)
            ?.concat(
              responseManager.result
                ? responseManager.result?.filter((el) => el)
                : []
            )
        )
      )
    );
  } catch (error) {
    yield put(actions.getUsersOfNetwork.error(error));
  }
}

export function* getNetworkTagsSaga() {
  try {
    const response: { result: PharmacyTag[] } = yield call(
      SourceService.getPharmacyTags,
      'network'
    );

    const mappedTagsResponse = getMappedTagsData(response.result);

    yield put(actions.getNetworkTags.success(mappedTagsResponse));
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
  }
}

export function* setNetworkTagSaga({ payload }: NetworkTagsParam) {
  try {
    const response: { result: PharmacyTag[] } = yield call(
      SourceService.setSourceTag,
      payload.tagsList,
      payload.networkCode,
      'networks',
      payload.textMeta
    );

    if (payload.postEffect) {
      payload.postEffect();
    }

    yield put(actions.setNetworkTags.success(response.result));
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
  }
}

interface UnmapNetworkTagParam {
  type: string;
  payload: {
    tagId: number;
    networkCode: string;
    postEffect: () => void;
  };
}

export function* unmapNetworkTagSaga({ payload }: UnmapNetworkTagParam) {
  try {
    const response: { result: string } = yield call(
      SourceService.unmapTag,
      payload.tagId,
      payload.networkCode,
      'networks'
    );

    if (payload.postEffect) {
      payload.postEffect();
    }

    yield put(actions.unmapNetworkTag.success(response.result));
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );

    yield put(actions.unmapNetworkTag.error(error));
  }
}

export function* networkPageWatcherSaga() {
  yield all([
    takeLatest(actions.getNetwork.REQUEST, getNetworkSaga),
    takeLatest(actions.getAllNetwork.REQUEST, getAllNetworkSaga),
    takeLatest(actions.getSourcesByNetwork.REQUEST, getSourcesByNetworkSaga),
    takeLatest(actions.editNetwork.REQUEST, editNetworkSaga),
    takeLatest(actions.getUsersOfNetwork.REQUEST, getUsersNetworkSaga),
    takeLatest(actions.getNetworkTags.REQUEST, getNetworkTagsSaga),
    takeLatest(actions.setNetworkTags.REQUEST, setNetworkTagSaga),
    takeLatest(actions.unmapNetworkTag.REQUEST, unmapNetworkTagSaga),
  ]);
}
