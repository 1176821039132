import React from 'react';
import { ClickAwayListener } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectors } from '../../../../reducer';
import * as Styled from './SuggestionStatus.styles';
import { SoftType } from '../../../../../OrdersPage/types';
import { StatusType } from '../../../../../../global/types';
import { PharmacyTag } from '../../../../../SourcePage/types';

interface SuggestionStatusProps {
  onClick: (status: string, statusName: string) => void;
  onClose: () => void;
  next: string[];
  soft: string;
  data: { status: string; soft: string; sourceTags: PharmacyTag[] | null };
}

const unusedStatuses = [
  StatusType.CANCELED,
  StatusType.WAITING_FOR_RECEIPT,
  StatusType.WAITING_FOR_DELIVERY_PAYMENT,
  StatusType.RETURN_PAYMENT,
  StatusType.NO_RECEIPT,
  StatusType.CANCELED,
];

const SuggestionStatus = ({
  onClick,
  onClose,
  next,
  soft,
  data,
}: SuggestionStatusProps) => {
  const allStatuses = useSelector(selectors.allStatuses)
    .filter((el: any) => el.code !== StatusType.CANCELED)
    .filter((el: any) =>
      soft === SoftType.STANDART ? el.code !== 'in_pharmacy_issued' : el.code
    );

  const all = new Set(
    allStatuses
      .map((el: any) => el.code)
      .filter((el: any) => !unusedStatuses.includes(el))
  );

  const infoAptekaStatuses = new Set(
    allStatuses
      .map((el: any) => el.code)
      .filter(
        (el: any) =>
          el !== 'in_pharmacy_collecting' &&
          el !== 'in_pharmacy_issued' &&
          el !== 'in_pharmacy_collected' &&
          el !== 'completed'
      )
  );

  const hasTag1044 = data.sourceTags?.some((tag) => tag.id === 1044);

  const statusName = (code: string) => {
    return allStatuses.find((el: any) => el.code === code)?.name;
  };

  const isStandartAndSelectStatus =
    data.soft === SoftType.STANDART
      ? data.status !== StatusType.IN_DELIVERY &&
        data.status !== StatusType.WAITING_FOR_DELIVERY &&
        data.status !== StatusType.IN_PHARMACY_READY &&
        data.status !== StatusType.IN_PHARMACY_ISSUED &&
        data.status !== StatusType.DELIVERED
      : true;

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Styled.SuggestionContainer>
        {next
          ?.filter((el: any) => !unusedStatuses.includes(el))
          ?.map((item: any) => (
            <Styled.StatusNext
              onClick={() => onClick(item, statusName(item))}
              key={item}
            >
              {statusName(item)}
            </Styled.StatusNext>
          ))}
        {next.length !== 0 && <div className="border" />}
        {hasTag1044
          ? [...infoAptekaStatuses]?.map((item: any) => (
              <Styled.Status
                onClick={() => onClick(item, statusName(item))}
                key={item}
              >
                {statusName(item)}
              </Styled.Status>
            ))
          : [...all]?.map((item: any) => (
              <Styled.Status
                onClick={() => onClick(item, statusName(item))}
                key={item}
              >
                {statusName(item)}
              </Styled.Status>
            ))}
      </Styled.SuggestionContainer>
    </ClickAwayListener>
  );
};

export default SuggestionStatus;
