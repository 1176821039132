import styled from 'styled-components';
import { baseTheme } from '../../../../../../global/styles/theme';

export const StatusContainer = styled('div')`
  padding: 16px 0;
  max-height: 300px;
  overflow-y: auto;

  .title {
    padding: 8px 24px;
    cursor: pointer;
    font-weight: 500;
    color: ${baseTheme.colors.secondary};

    &:hover {
      background-color: rgba(10, 119, 199, 0.05);
    }
  }
`;
