import styled from 'styled-components';

export const Navigation = styled('div')`
  width: 400px;
  display: flex;
  justify-content: center;
  margin: 20px auto;
  display: flex;
  @media only screen and (max-width: 667px) {
    width: 90%;
  }
  button {
    font-size: 16px;
    font-family: Arial;
    height: 45px;
    width: 130px;
    border: none;
    margin-right: 10px;
    cursor: pointer;
  }
`;

export const Buttons = styled('div')`
  height: 60px;
  width: 600px;
  margin: 0 auto;
  display: flex;
  @media only screen and (max-width: 667px) {
    width: 90%;
  }

  button,
  label {
    font-size: 16px;
    font-family: Arial;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    border-radius: 10px;
    padding: 5px;
    border: none;
    cursor: pointer;
    width: 100px;
  }
  button {
    background: #c6ffc6;
    box-shadow: 0 2px 3px 0px #889b88;
    &:active {
      box-shadow: 0 0 black;
    }
  }
  label {
    background: lightyellow;
    box-shadow: 0 2px 3px 0px #889b88;
    &:active {
      box-shadow: 0 0 black;
    }
  }
`;

export const Editor = styled('div')`
  width: 600px;
  margin: 0 auto;
  padding: 10px;
  border: solid 1px grey;
  border-radius: 10px;
  margin-bottom: 10px;
  display: flex;
  @media only screen and (max-width: 667px) {
    width: 90%;
  }
  .codex-editor {
    width: 100%;
  }
  .image-gallery__container {
    background: transparent;
  }
`;
