import axios from 'axios';
import { formatPhoneNumberForRequest } from '../../global/helpers';

export class LoginService {
  static API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/api/v1/`;

  static auth = async (phoneNumber: string, validationCode: string) => {
    const response = await axios({
      method: 'post',
      url: `${LoginService.API_BASE_URL}accounting/auth`,
      data: JSON.stringify({
        phone: formatPhoneNumberForRequest(phoneNumber),
        validation_code: validationCode,
      }),
    });
    return response.data;
  };

  static refreshTokens = async (refreshToken: string) => {
    const response = await fetch(
      `${LoginService.API_BASE_URL}users/auth/refresh`,
      {
        method: 'POST',
        body: JSON.stringify({
          refresh_token: refreshToken,
        }),
      }
    ).then((res) => res.json());

    return response;
  };

  static validatePhoneNumber = async (phoneNumber: string) => {
    const response = await axios({
      method: 'post',
      url: `${LoginService.API_BASE_URL}users/sms`,
      data: JSON.stringify({
        phone: formatPhoneNumberForRequest(phoneNumber),
        sms_type: 'auth',
      }),
    });
    return response.data;
  };

  static getUserInfo = async (token: string) => {
    const response = await axios({
      method: 'get',
      url: `${LoginService.API_BASE_URL}users`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  static getNetworks = async (token: string, code: string) => {
    const response = await axios({
      method: 'get',
      url: code
        ? `${LoginService.API_BASE_URL}networks/${code}`
        : `${LoginService.API_BASE_URL}networks`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  static getSources = async (token: string, code?: string) => {
    const response = await axios({
      method: 'get',
      url: code
        ? `${LoginService.API_BASE_URL}sources/${code}`
        : `${LoginService.API_BASE_URL}sources`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };
}
