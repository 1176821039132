import styled from 'styled-components';
import { baseTheme } from '../../../../global/styles/theme';

export const ProductContainer = styled('div')`
  margin-top: 13px;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid ${baseTheme.colors.stroking};

  .product__title {
    font-weight: 700;
    font-size: 20px;
    padding-bottom: 24px;
  }
`;

export const ProductTableHeaderRow = styled('tr')`
  .table__header-item {
    font-size: 16px;
    color: ${baseTheme.colors.secondary};
    font-weight: 400;
    text-align: start;
    padding-bottom: 16px;
    width: 20%;
  }

  .item-bigger {
    width: 45%;
  }
`;

export const ProductTableRowContainer = styled('tbody')`
  .product-item {
    padding: 16px 10px 14px 0;
    font-size: 16px;
    font-weight: 400;
    text-align: start;
    width: 20%;
    border-top: 1px solid ${baseTheme.colors.stroking};
  }

  .item-column {
    width: 45%;
  }

  .product-empty-service {
    td {
      color: ${baseTheme.colors.mainLightBlue};
      font-weight: 700;
      cursor: pointer;
    }
  }
`;

export const ProductTable = styled('table')`
  width: 100%;
`;
