import { useState } from 'react';
import bottomArrow from '../../global/media/bottom-arrow.svg';
import saveIcon from '../../global/media/save-discet-icon.svg';
import * as Styled from './CustomDropDown.styles';
import SuggestionList from './components/SuggestionList';
import { ISuggestionOption } from './types';

interface CustomDropDownProps {
  defaultValue: string;
  onSave: () => void;
  suggestionOptions: ISuggestionOption[];
  title: string;
}

const CustomDropDown = ({
  defaultValue,
  onSave,
  suggestionOptions,
  title,
}: CustomDropDownProps) => {
  const [isOpenSuggestion, setOpenSuggestion] = useState<boolean>(false);
  const [newOption, setNewOption] = useState<{
    name: string;
    value: string | number;
  } | null>(null);

  return (
    <Styled.StyledDropDownContainer>
      <Styled.SetStatus>
        <div className="status-block">
          <div className="title">{title}</div>
          <div
            className="set-status"
            onClick={() => setOpenSuggestion(!isOpenSuggestion)}
          >
            <div className="text">
              {newOption ? newOption.name : defaultValue}
            </div>
            <img src={bottomArrow} alt="bottom arrow" />
          </div>
        </div>
        {isOpenSuggestion && (
          <SuggestionList
            optionsList={suggestionOptions}
            setOption={setNewOption}
            onClose={() => setOpenSuggestion(false)}
          />
        )}
        {newOption && defaultValue !== newOption?.name && (
          <div onClick={() => onSave()}>
            <img
              style={{ cursor: 'pointer' }}
              width="30px"
              height="30px"
              src={saveIcon}
              alt=""
            />
          </div>
        )}
      </Styled.SetStatus>
    </Styled.StyledDropDownContainer>
  );
};

export default CustomDropDown;
