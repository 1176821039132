import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './OrderCancelModal.styles';
import ModalWindow from '../ModalWindow';
import { OrderTableData } from '../../containers/OrdersPage/types';
import CustomTextarea from '../CustomTextarea';
import Button from '../Button';

interface OrderCancelModalProps {
  onDecline: (data: OrderTableData) => void;
  onSubmit: (msg: string, id: string) => void;
  data: any;
}

const OrderCancelModal = ({
  onDecline,
  onSubmit,
  data,
}: OrderCancelModalProps) => {
  const { t } = useTranslation();

  const [message, setMessage] = useState<string>('');
  const [errorTooltip, setErrorTooltip] = useState<boolean>(false);

  const handleMessageChange = (value: string) => {
    setMessage(value);
    setErrorTooltip(false);
  };

  const handleDecline = () => {
    onDecline(data);
  };

  const handleSubmit = () => {
    if (message !== '') {
      onSubmit(message, data.id);
    } else setErrorTooltip(true);
  };

  return (
    <ModalWindow onClose={handleDecline}>
      <Styled.OrderCancelModalContent>
        <div className="modal-title">{t('OrderCancelModal.MODAL_TITLE')}</div>
        <div className="modal-subtitle">
          {t('OrderCancelModal.MODAL_SUBTITLE')}
        </div>
        <div className="cancel-reason__title">
          {t('OrderCancelModal.CANCEL_REASON_TITLE')}
        </div>
        <CustomTextarea
          isResizable
          value={message}
          onChange={handleMessageChange}
          placeholder={t('OrderCancelModal.TEXT_AREA_PLACEHOLDER')}
        />
        <div className="controls-block">
          <div className="decline-btn__container">
            <Button
              onClick={handleDecline}
              text={t('OrderCancelModal.DECLINE_BTN')}
              variant="outlined"
            />
          </div>
          <div className="submit-btn__container">
            {errorTooltip && (
              <div className="error-tooltip">
                {t('OrderCancelModal.ERROR_TOOLTIP')}
              </div>
            )}
            <Button
              onClick={handleSubmit}
              text={t('OrderCancelModal.SUBMIT_BTN')}
            />
          </div>
        </div>
      </Styled.OrderCancelModalContent>
    </ModalWindow>
  );
};

export default OrderCancelModal;
