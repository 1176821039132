import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as Styled from './SourceBreadCrumbs.style';
import arrowIcon from '../../../../global/media/arrow-breadCrumbs.svg';

interface BreadCrumbsProps {
  editActive: boolean;
}

const SourceBreadCrumbs = ({ editActive }: BreadCrumbsProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleClick = (push: string) => {
    history.push(push);
  };

  return (
    <Styled.ProductsBreadCrumbsContainer>
      <div className="breadCrumb" onClick={() => handleClick('/sources')}>
        {t('SourcePage.BREADCRUMBS.PHARMACY_PAGE')}
      </div>
      <Styled.ProductsBreadCrumbIcon>
        <img src={arrowIcon} alt="arrow icon" />
      </Styled.ProductsBreadCrumbIcon>
      <div className="breadCrumb active">
        {editActive
          ? t('SourcePage.BREADCRUMBS.EDIT_PHARMACY')
          : t('SourcePage.BREADCRUMBS.VIEW_PHARMACY')}
      </div>
    </Styled.ProductsBreadCrumbsContainer>
  );
};

export default SourceBreadCrumbs;
