import { UserRole } from '../OrdersPage/types';

export const formatDataForReport = (
  startDay: Date,
  endDay: Date,
  network: string,
  statuses: string[] | null,
  role?: string
) => {
  const getDateString = (st: Date) => {
    const formatedStr = st
      .toLocaleString()
      .replace(/[a-zа-яё]/gi, '')
      .trim();
    const ar = formatedStr.split(', ');

    const month =
      st.getMonth() + 1 > 9 ? st.getMonth() + 1 : `0${st.getMonth() + 1}`;
    const day = st.getDate() > 9 ? st.getDate() : `0${st.getDate()}`;
    const year = st.getFullYear();
    const getGMT = st.toString().split('GMT')[1].split(' ')[0].slice(1, 3);

    return getGMT === '00'
      ? `${year}-${month}-${day}T${ar[1]}Z`
      : `${year}-${month}-${day}T${ar[1]}+${getGMT}:00`;
  };

  if (network && statuses?.filter((el) => el !== '').length) {
    return {
      from: getDateString(startDay),
      to: getDateString(endDay),
      network_code: network,
      statuses: statuses.filter((el) => el !== ''),
    };
  }
  if (network) {
    return {
      from: getDateString(startDay),
      to: getDateString(endDay),
      network_code: network,
    };
  }
  if (statuses?.filter((el) => el !== '').length) {
    if (role === UserRole.OPERATOR || role === UserRole.HEAD_OPERATOR) {
      return {
        from: getDateString(startDay),
        to: getDateString(endDay),
        use_paid_at: true,
      };
    }

    return {
      from: getDateString(startDay),
      to: getDateString(endDay),
      statuses: statuses.filter((el) => el !== ''),
    };
  }
  return {
    from: getDateString(startDay),
    to: getDateString(endDay),
  };
};
