import styled from 'styled-components';
import { baseTheme } from '../../../../../../global/styles/theme';

export const HistoryTableRow = styled('tr')`
  .table-cell {
    padding: 16px 10px 16px 0;
    border-bottom: 1px solid ${baseTheme.colors.stroking};
    font-size: 16px;
    font-weight: 400;
    color: ${baseTheme.colors.primary};
    cursor: pointer;

    .action-block {
      display: flex;
      flex-direction: column;

      .event-type {
        color: ${baseTheme.colors.secondary};
      }

      .statuses-block,
      .pharmacy-block,
      .timer-block {
        display: flex;
        padding-top: 12px;

        img {
          margin: 0 14px;
        }

        .status {
          color: ${baseTheme.colors.white};
          font-weight: 700;
          font-size: 16px;
          padding: 4px 10px;
          border-radius: 4px;
        }
      }
    }
  }

  .comment-block {
    padding-top: 12px;
  }

  .table-cell-created-at {
    color: ${baseTheme.colors.secondary};
  }

  .table-cell-action {
    display: flex;
  }

  .timer {
    font-size: 18px;
    font-weight: 700;
  }

  .timer-green {
    color: ${baseTheme.colors.focus};
  }

  .timer-orange {
    color: ${baseTheme.colors.warning};
  }

  .timer-red {
    color: ${baseTheme.colors.error};
  }

  .timer-grey {
    color: ${baseTheme.colors.disabled};
  }

  .comment-prev {
    color: ${baseTheme.colors.warning};
    font-weight: 500;
  }

  .comment-cur {
    color: ${baseTheme.colors.focus};
    font-weight: 500;
  }

  .pharmacy-previous {
    color: ${baseTheme.colors.error};
    font-weight: 500;
  }

  .pharmacy-current {
    color: ${baseTheme.colors.focus};
    font-weight: 500;
  }
`;
