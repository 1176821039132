export const LOCAL_STORAGE_KEY = 'daribarAdminPanel';

export const NOTIFICATION_CHANGES_SAVED = 'Изменения сохранены';
export const NOTIFICATION_CHANGES_NOT_SAVED = 'Изменения не сохранены';
export const NOTIFICATION_PRODUCT_BLOCKED = 'Товар аптеки заблокирован';
export const NOTIFICATION_PRODUCT_NOT_BLOCKED = 'Товар аптеки не заблокирован';
export const NOTIFICATION_MAPPING_SUCCESS = 'Привязка создана';
export const NOTIFICATION_MAPPING_ERROR = 'Привязка не создана';
export const NOTIFICATION_REPORT_ERROR = 'Отчет не создан. Попробуйте еще раз';
export const NOTIFICATION_TEMPLATE_SUCCESS = 'Шаблон создан';
export const NOTIFICATION_PROMO_CODE_SUCCESS = 'Промокод создан';
export const NOTIFICATION_PROMO_CODE_ACTIVATE_SUCCESS = 'Промокод  активирован';
export const NOTIFICATION_PROMO_CODE_DEACTIVATE_SUCCESS =
  'Промокод  деактевирован';
export const NOTIFICATION_PROMO_CODES_ACTIVE_ERROR =
  'Промокод не активирован/деактивирован. Попробуйте еще раз';
export const NOTIFICATION_PROMO_CODES_ERROR =
  'Промокод не создан. Попробуйте еще раз';
export const NOTIFICATION_TEMPLATES_ERROR =
  'Шаблон не создан. Попробуйте еще раз';

export const PHARMACY_ISSUED =
  'Заказ еще не выдан аптекой. Дождитесь пока аптека переведет заказ в статус "Выдан"';

export const ORDER_KEY = 'selectedOrder';
export const NOTIFICATION_REFUND_ERROR = 'Предыдущий возврат не закончен';
