import styled from 'styled-components';
import { baseTheme } from '../../global/styles/theme';

export const ControlsContainer = styled('div')`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 80px;
  background-color: white;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #e6e6e8;
  display: ${(props) => (props.className?.length ? `none` : `flex`)};
`;

export const ControlsContentContainer = styled('div')`
  display: grid;
  max-width: 100%;
  margin: auto;
`;

export const ControlButtonContainer = styled('div')`
  padding: 7px 9px;
  border-radius: 8px;
  background-color: ${(props) =>
    props.className?.length
      ? `${baseTheme.colors.mainLightBlue}`
      : `${baseTheme.colors.white}`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  margin-right: 16px;
  color: ${(props) =>
    props.className?.length ? `${baseTheme.colors.white}` : `#686868`};
  cursor: pointer;
`;
