import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { selectors } from '../../containers/OrdersPage/reducer';
import * as actions from '../../containers/OrdersPage/actions';
import * as Styled from './OrderSearchModal.styles';
import ModalWindow from '../ModalWindow';
import SearchBar from '../SearchBar/SearchBar';
import OrdersTable from './components/OrdersTable/OrdersTable';
import { getFromLocalStorage } from '../../global/helpers';

interface OrderSearchModalProps {
  onClose: () => void;
  openDetail: () => void;
}

const OrderSearchModal = ({ onClose, openDetail }: OrderSearchModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const education = history.location.pathname.includes('/test-orders');
  const [searchValue, setSearchValue] = useState('');
  const [results, setResults] = useState<any>(null);
  const dataSearch = useSelector(selectors.ordersSearchData);
  const searchLoading = useSelector(selectors.ordersSearchLoading);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchValue?.length > 2) {
        dispatch(
          actions.getOrdersSearch.request({
            searchValue,
            token: getFromLocalStorage('accessToken'),
            testOrders: !!education,
          })
        );
        if (dataSearch === null) setResults([]);
      }
    }, 1400);

    return () => clearTimeout(delayDebounceFn);
  }, [dispatch, searchValue]);

  const handleClose = () => {
    onClose();
    dispatch(actions.clearOrdersSearch());
    setResults(null);
  };

  const handleSearchClick = () => {
    if (searchLoading) {
      setResults(null);
    } else if (dataSearch) {
      setResults(dataSearch);
    } else setResults([]);
  };

  return (
    <ModalWindow onClose={handleClose}>
      <Styled.OrderSearchModalContent>
        <div className="modal-title">{t('OrderSearchModal.TITLE')}</div>
        <div className="modal-subtitle">{t('OrderSearchModal.SUBTITLE')}</div>
        <SearchBar
          value={searchValue}
          placeholder={t('OrderSearchModal.SEARCH_PLACEHOLDER')}
          setValue={setSearchValue}
          onSearchClick={handleSearchClick}
        />
        {/* eslint-disable-next-line */}
        {searchLoading ? (
          <div className="modal-loading">
            <CircularProgress size={15} />
          </div>
        ) : dataSearch && dataSearch.length > 0 ? (
          <OrdersTable
            data={dataSearch}
            searchValue={searchValue}
            onClose={onClose}
            openDetail={openDetail}
          />
        ) : (
          results?.length === 0 && (
            <div className="modal-no-results">
              {t('OrderSearchModal.NO_RESULTS')}
            </div>
          )
        )}
      </Styled.OrderSearchModalContent>
    </ModalWindow>
  );
};

export default OrderSearchModal;
