import { useState, useEffect } from 'react';
import { CashbackService } from './service';
import { formatTime } from '../../global/helpers';
import * as Styled from './CashbackPage.styles';
import { StyledCashbackPageContainer } from './CashbackPage.styles';

const CashbackPage = () => {
  const [skuValue, setSkuValue] = useState<string>('');
  const [bonusValue, setBonusValue] = useState<string>('');
  const [list, setList] = useState<any[]>([]);

  const fetchCashbackList = async () => {
    const res = await CashbackService.getCashbackList();
    const data = await Promise.all(
      res.result.map((key: any) =>
        CashbackService.getCashbackData(`${key.value}_cashback`, key.id)
      )
    );
    setList(data);
  };

  const handleSubmit = async () => {
    await CashbackService.submitCashback(skuValue, bonusValue);
    await CashbackService.addToCashbackList(skuValue);
    setSkuValue('');
    setBonusValue('');
    fetchCashbackList();
  };

  const handleDelete = async (item: any) => {
    await CashbackService.deleteCashback(item.id, item.cashback_id);
    fetchCashbackList();
  };

  useEffect(() => {
    fetchCashbackList();
  }, []);
  return (
    <StyledCashbackPageContainer>
      <Styled.SearchContainer>
        <Styled.ContainerInput>
          <input
            autoComplete="off"
            value={skuValue}
            type="text"
            className="search-input"
            placeholder="Добавить товар по sku"
            onChange={(event) => setSkuValue(event.target.value)}
          />
          {skuValue && (
            <div className="clear-input" onClick={() => setSkuValue('')}>
              Очистить
            </div>
          )}
        </Styled.ContainerInput>
        <Styled.ContainerInput style={{ maxWidth: 120 }}>
          <input
            autoComplete="off"
            value={bonusValue}
            type="number"
            className="search-input"
            placeholder="Бонусы (%)"
            onChange={(event) => {
              const { value } = event.target;
              if (Number(value) > 100 || Number(value) < 0) {
                return;
              }
              setBonusValue(event.target.value);
            }}
          />
        </Styled.ContainerInput>
        <Styled.ButtonSearch onClick={handleSubmit}>
          Добавить
        </Styled.ButtonSearch>
      </Styled.SearchContainer>
      <Styled.TableHeaderContainer>
        <div className="header-row header-row-lg">Бонус и фото</div>
        <div className="header-row">Кто и когда сделал</div>
        <div className="header-row header-row-sm">Удаление</div>
      </Styled.TableHeaderContainer>
      <Styled.TableBodyContainer>
        {list.map((item: any) => (
          <div key={item.id} style={{ display: 'flex' }}>
            <div className="table-cell table-cell-lg name-container">
              <p className="name-title">{item.key?.replace('_cashback', '')}</p>
              <p className="name-title">Бонус: {item.value}%</p>
              <img
                width="120px"
                height="120px"
                src={`https://db-images.object.pscloud.io/optimized_v4_img_middle250_${item.key?.replace(
                  '_cashback',
                  ''
                )}.webp`}
                alt=""
              />
            </div>
            <div className="table-cell name-container">
              <div>{formatTime(item.created_at)}</div>
              <br />
              <div className="user-name-title">{item.updated_by}</div>
            </div>
            <div className="table-cell table-cell-sm">
              <div className="assembly-btn" onClick={() => handleDelete(item)}>
                Удалить
              </div>
            </div>
          </div>
        ))}
      </Styled.TableBodyContainer>
    </StyledCashbackPageContainer>
  );
};

export default CashbackPage;
