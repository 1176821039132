import styled from 'styled-components';
import { baseTheme } from '../../../../../../global/styles/theme';

export const ProductsTableRowContainer = styled('tr')`
  .product-item {
    padding: 16px 40px 14px 0;
    font-size: 16px;
    font-weight: 400;
    text-align: start;
    word-break: break-word;
    max-width: 200px !important;
    border-bottom: 1px solid ${baseTheme.colors.stroking};
  }

  .item-column {
    font-weight: 500 !important;

    div {
      span {
        color: ${baseTheme.colors.secondary} !important;
        font-size: 14px;
        font-weight: normal;
      }

      a {
        color: ${baseTheme.colors.secondary} !important;

        &:hover {
          color: ${baseTheme.colors.mainLightBlue} !important;
          text-decoration: underline;
        }
      }
    }
  }

  .item-edit-container {
    display: flex;
    justify-content: space-between;

    .text-link {
      font-size: 14px !important;
    }

    div[aria-disabled] {
      padding: 2px 10px;
      background-color: ${baseTheme.colors.focus};
      border-color: ${baseTheme.colors.focus};

      &:hover {
        background-color: ${baseTheme.colors.focusLight};
        border-color: ${baseTheme.colors.focusLight};
      }
    }
  }

  .item-edit {
    color: ${baseTheme.colors.mainLightBlue};
    font-weight: 700;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
  }

  .item-edit-verification {
    padding-right: 10px !important;
  }

  .pencil-icon-container,
  .delete-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tags-container {
    display: flex;
    position: relative;
    flex-wrap: wrap;

    div {
      margin-right: 8px;
    }
  }

  .tag {
    font-size: 14px;
    margin-top: 4px;
    border-radius: 4px;
    padding: 3px 6px;
    width: fit-content;

    .tag-delete {
      margin-left: 4px;
      margin-top: 2px;
      cursor: pointer;

      img {
        height: 10px !important;
        width: auto;
      }
    }
  }

  .add-tag {
    display: flex;
    align-items: center;
    margin-top: 6px;
    opacity: 0.4;

    cursor: pointer;
  }

  .product-manufacturer {
    padding: 4px 0;
    font-size: 14px;
    font-weight: normal;

    .manufacturer {
      color: ${baseTheme.colors.primary} !important;
      font-size: 14px;
      font-weight: 700;
    }
  }

  .modal-content {
    width: 80vw;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .button-row {
      gap: 60px;
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
    }
  }
`;
