import styled from 'styled-components';
import { baseTheme } from '../../../../global/styles/theme';

export const PaginationContainer = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;

  input {
    width: 40px;
    height: 30px;
    font-size: 14px;
    text-align: center;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const Templates = styled('div')`
  width: 100%;
  .cell-header {
    width: 110px;
    border: solid 1px grey;
    padding: 5px;
    white-space: normal;
    overflow-wrap: break-word;

    &--sm {
      width: 80px !important;
    }

    &--lg {
      width: 120px !important;
    }

    &--ulg {
      width: 170px !important;
    }
  }
  .cell {
    width: 110px;
    border: solid 1px grey;
    min-height: 30px;
    padding: 5px;
    white-space: normal;
    overflow-wrap: break-word;

    &--sm {
      width: 80px !important;
    }

    &--lg {
      width: 120px !important;
    }

    &--ulg {
      width: 170px !important;
    }
  }

  .input {
    width: 100%;
    padding: 5px;
  }
  .create-templates {
    border: none;
    font: inherit;
    font-weight: 500;
    font-size: 18px;
    color: ${baseTheme.colors.white};
    background-color: ${baseTheme.colors.mainLightBlue};
    border-radius: 8px;
    padding: 12px 24px;
    text-align: center;
    max-width: 200px;
    margin-top: 24px;
    cursor: pointer;

    &:hover {
      background-color: ${baseTheme.colors.mainBlue};
    }
  }
  .container-list {
    display: flex;
  }

  .container-table {
    display: flex;
    height: fit-content;
    min-height: 105px;
    flex-wrap: wrap;
  }
  .container-header {
    margin-top: 20px;
    display: flex;
    min-height: 45px;
  }
`;
