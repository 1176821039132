import styled from 'styled-components';

export const PharmacistPromoCodeContainer = styled('div')`
  .textarea {
    min-height: 40px !important;
    height: 40px;
    max-width: 150px;
  }

  .promo-code-creating-container {
    display: flex;
  }

  .description {
    font-size: 16px;
    color: #535d6a;
    font-weight: 500;
    text-align: start;
    margin: 20px 0px;
  }

  .main-title {
    font-weight: 700;
    color: #121f30;
    font-size: 22px;
  }

  .create-btn {
    border: none;
    font: inherit;
    font-weight: 500;
    font-size: 18px;
    color: #ffffff;
    background-color: #0a77c7;
    border-radius: 8px;
    padding: 8px 16px;
    text-align: center;
    max-width: 200px;
    margin-left: 20px;
    cursor: pointer;
  }

  .pharmacist-promo-code-description {
    font-size: 16px;
    color: #535d6a;
    font-weight: 500;
    text-align: start;
    margin: 20px 0px;

    span {
      font-weight: 700;
      color: #121f30;
    }

    .text {
      font-weight: 900;
      color: rgb(127, 136, 149);
    }
  }
`;
