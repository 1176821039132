import styled from 'styled-components';
import { baseTheme } from '../../../../global/styles/theme';

export const OrdersTable = styled('table')`
  width: 100%;
  border-collapse: separate;
  margin-top: 32px;
`;

export const OrdersTableHeaderRow = styled('thead')`
  .table__header-cell {
    font-size: 16px;
    color: ${baseTheme.colors.secondary};
    font-weight: 400;
    text-align: start;
    padding-bottom: 16px;
    border-bottom: 1px solid ${baseTheme.colors.stroking};
    width: 33%;
  }
`;

export const OrdersTableRow = styled('tr')`
  .table-cell {
    padding: 16px 0;
    border-bottom: 1px solid ${baseTheme.colors.stroking};
    font-weight: 500;
    font-size: 18px;
    cursor: pointer;

    .copy-icon {
      margin-left: 5px;
    }
  }

  .table-cell--active {
    color: ${baseTheme.colors.mainLightBlue};
  }

  .table-cell--number {
    &:hover {
      text-decoration: underline;
    }
  }
`;
