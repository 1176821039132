import styled from 'styled-components';
import { baseTheme } from '../../../../global/styles/theme';

export const SourceMainBlock = styled('div')`
  .header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 34px;

    .token {
      padding-top: 8px;
      color: ${baseTheme.colors.mainLightBlue};
      font-size: 14px;
      font-weight: 500;
      display: flex;

      div {
        padding-left: 4px;
        color: ${baseTheme.colors.secondary};
      }

      img {
        cursor: pointer;
        margin: -3px 0 0 4px;
        width: 24px !important;
        height: auto;
      }
    }

    .toggle-block-visible,
    .toggle-block-active {
      margin-top: 10px;
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .flex-container {
      display: flex;
      flex-direction: column;
    }

    .textarea {
      min-height: 40px !important;
      height: 40px !important;
    }

    .name {
      font-weight: 500;
      font-size: 18px;
      color: ${baseTheme.colors.mainLightBlue};
    }

    .right-block {
      display: flex;
      flex-direction: column;

      .save-btn {
        font-weight: 500;
        font-size: 18px;
        color: ${baseTheme.colors.white};
        background-color: ${baseTheme.colors.mainLightBlue};
        border-radius: 8px;
        padding: 10px 24px;
        text-align: center;
        max-width: 150px;
        margin-top: 10px;
        cursor: pointer;
      }

      .disable {
        background-color: ${baseTheme.colors.disabled} !important;
        pointer-events: none;
      }
    }

    .toggle-block {
      display: flex;

      .disableUpdatedAt,
      .activeUpdatedAt {
        color: ${baseTheme.colors.secondary};
        padding-left: 8px;
        display: flex;
        align-items: center;
      }
    }
    .activeUpdatedAt {
      display: flex;
      flex-direction: column;
      & .active-info {
        color: ${baseTheme.colors.disabled};
      }
    }

    .comment {
      padding: 7px;
      border: solid 1px;
      border-radius: 3px;
    }

    .input-comments-error {
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 5px;
      border: solid 1px #eb5757;
    }

    .input-comments {
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 5px;
      border: solid 1px #000;
    }

    .comment-error {
      margin: 0;
      color: #eb5757;
    }

    .icons-block {
      display: flex;
      justify-content: flex-end;

      div {
        cursor: pointer;
      }

      input {
        margin-left: 16px;
      }
    }
  }
`;

export const SourceInfo = styled('div')`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .form-container {
    display: grid;
    grid-template-columns: 0.5fr 1fr 1fr 1fr;
  }

  .form-input {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  }

  .label {
    color: ${baseTheme.colors.secondary};
    margin-bottom: 8px;
    font-size: 14px;
  }

  .text-change {
    cursor: pointer;
    font-size: 14px;
    color: ${baseTheme.colors.mainLightBlue};
  }

  .error {
    background-color: rgb(${baseTheme.colors.error}, 0.1);
    border: 1px solid ${baseTheme.colors.error};
  }

  .error-message-label {
    margin: 8px 0 0;
    color: ${baseTheme.colors.error};
    font-size: 14px;
    width: 80%;
  }

  .set-message-label {
    margin: 8px 0 0;
    color: ${baseTheme.colors.focus};
    font-size: 14px;
    width: 80%;
  }

  input:disabled {
    background-color: transparent;
    color: ${baseTheme.colors.tertiary};
  }

  .input {
    outline: none !important;
    border: 1px solid ${baseTheme.colors.stroking};
    border-radius: 4px;
    color: ${baseTheme.colors.primary} !important;
    padding: 10px 12px;
    width: 80% !important;
  }

  .input-relative {
    position: relative !important;
  }

  .button {
    border: none;
    font: inherit;
    font-weight: 500;
    font-size: 18px;
    color: ${baseTheme.colors.white};
    background-color: ${baseTheme.colors.mainLightBlue};
    border-radius: 8px;
    padding: 12px 24px;
    text-align: center;
    max-width: 150px;
    width: fit-content;
    margin-top: 24px;
    cursor: pointer;

    &:hover {
      background-color: ${baseTheme.colors.mainBlue};
    }
  }
`;

export const NotificationWrapper = styled('div')`
  max-height: 60px;
  position: fixed;
  top: 56px;
  left: calc(50vw - 425px / 2);
  width: 425px;
  z-index: 600;
`;
export const ContainerDelete = styled('div')`
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;

  .buttons-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .container-error {
    display: flex;
    gap: 5px;
    margin-right: 300px;
    font-weight: 500;
  }

  .error {
    font-size: 18px;
    color: #eb5757;
  }

  .delete-pharmacy-container {
    display: flex;
    gap: 50px;
    align-items: start;
  }

  .delete-pharmacy-container-sync {
    display: flex;
    gap: 50px;
    align-items: end;
  }

  .delete-pharmacy {
    border: none;
    font: inherit;
    font-weight: 500;
    font-size: 14px;
    color: ${baseTheme.colors.white};
    background-color: ${baseTheme.colors.mainLightBlue};
    border-radius: 8px;
    padding: 8px;
    text-align: center;
    width: 150px;
    cursor: pointer;

    &:hover {
      background-color: ${baseTheme.colors.mainBlue};
    }
  }

  .error-message {
    position: absolute;
    top: -30px;
    font-size: 18px;
    color: #eb5757;
  }

  .input-code-error {
    padding: 5px;
    border: solid 1px #eb5757;
  }
  .input-code {
    margin: 3px 0;
    height: 25px;
    padding: 5px;
    border: solid 1px #000;
  }
`;
