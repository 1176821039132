export const GET_PRODUCTS_UN_ATTACHED = 'GET_PRODUCTS_UN_ATTACHED';
export const GET_PRODUCTS_AUTOMAPPING = 'GET_PRODUCTS_AUTOMAPPING';
export const GET_PRODUCTS_ATTACHED = 'GET_PRODUCTS_ATTACHED';
export const GET_PRODUCTS_ATTACHED_ALL = 'GET_PRODUCTS_ATTACHED_ALL';
export const GET_PRODUCTS_BLOCKED = 'GET_PRODUCTS_BLOCKED';
export const GET_PRODUCTS_VERIFICATION = 'GET_PRODUCTS_VERIFICATION';
export const GET_PRODUCTS_DYBL = 'GET_PRODUCTS_DYBL';
export const GET_PRODUCTS_REFRESH = 'GET_PRODUCTS_REFRESH';
export const GET_NETWORKS = 'GET_NETWORKS';
export const GET_TAGS = 'GET_TAGS';
export const SET_NETWORK = 'SET_NETWORK';
export const SET_TAG = 'SET_TAG';
export const DELETE_PRODUCT_TAG = 'DELETE_PRODUCT_TAG';
export const SET_TAGS_TO_PRODUCT = 'SET_TAGS_TO_PRODUCT';

export const GET_PRODUCTS_SEARCH_UNATTACHED = 'GET_PRODUCTS_SEARCH_UNATTACHED';
export const GET_PRODUCTS_SEARCH_ATTACHED = 'GET_PRODUCTS_SEARCH_ATTACHED';
export const GET_PRODUCTS_SEARCH_BLOCKED = 'GET_PRODUCTS_SEARCH_BLOCKED';
export const GET_PRODUCTS_SEARCH_ON_VERIFICATION =
  'GET_PRODUCTS_SEARCH_ON_VERIFICATION';
export const GET_PRODUCTS_SEARCH_DYBL = 'GET_PRODUCTS_SEARCH_DYBL';
export const GET_PRODUCTS_SEARCH_AUTOMAPPING =
  'GET_PRODUCTS_SEARCH_AUTOMAPPING';

export const CLEAR_PRODUCTS_ATTACHED = 'CLEAR_PRODUCTS_ATTACHED';
export const CLEAR_PRODUCTS_UNATTACHED = 'CLEAR_PRODUCTS_UNATTACHED';
export const CLEAR_PRODUCTS_ON_VERIFICATION = 'CLEAR_PRODUCTS_ON_VERIFICATION';
export const CLEAR_PRODUCTS_AUTOMAPPING = 'CLEAR_PRODUCTS_AUTOMAPPING';
export const CLEAR_PRODUCTS_BLOCKED = 'CLEAR_PRODUCTS_BLOCKED';

export const CLEAR_PRODUCTS_SEARCH_UNATTACHED =
  'CLEAR_PRODUCTS_SEARCH_UNATTACHED';
export const CLEAR_PRODUCTS_SEARCH_ATTACHED = 'CLEAR_PRODUCTS_SEARCH_ATTACHED';
export const CLEAR_PRODUCTS_SEARCH_BLOCKED = 'CLEAR_PRODUCTS_SEARCH_BLOCKED';
export const CLEAR_PRODUCTS_SEARCH_DYBL = 'CLEAR_PRODUCTS_SEARCH_DYBL';
export const CLEAR_PRODUCTS_SEARCH_ON_VERIFICATION =
  'CLEAR_PRODUCTS_SEARCH_ON_VERIFICATION';
export const CLEAR_PRODUCTS_SEARCH_AUTOMAPPING =
  'CLEAR_PRODUCTS_SEARCH_AUTOMAPPING';
export const SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT_PHARMACY';

export const ACTIVE_TAB_KEY = 'activeTab';

export const OPEN_NETWORKS = 'OPEN_NETWORKS';
export const CLOSE_NETWORKS = 'CLOSE_NETWORKS';

export const UNATTACHED = 'unattached';
export const ATTACHED = 'attached';
export const BLOCKED = 'blocked';
export const VERIFICATION = 'verification';
export const DYBL = 'dybl';
export const AUTOMAPPING = 'automapping';
