import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './ConfirmationModal.styles';
import ModalWindow from '../../../../components/ModalWindow';
import Button from '../../../../components/Button';

interface ModalProps {
  onClose: () => void;
  onSuccess: () => void;
  variant: 'completed' | 'cancel';
}

const ConfirmationModal = ({ onClose, onSuccess, variant }: ModalProps) => {
  const { t } = useTranslation();

  return (
    <ModalWindow onClose={onClose}>
      <Styled.MainConfirmation>
        <div className="main-title">
          {variant === 'completed' && t('OrderPage.CompletedModal.TITLE')}
          {variant === 'cancel' && t('OrderPage.CancelOrderModal.TITLE')}
        </div>
        <div className="btn-block">
          <Button
            text={t('OrderPage.CompletedModal.CANCEL')}
            variant="outlined"
            onClick={onClose}
          />
          <div className="buttonCancel">
            <Button
              text={t('OrderPage.CompletedModal.CONFIRM')}
              onClick={onSuccess}
            />
          </div>
        </div>
      </Styled.MainConfirmation>
    </ModalWindow>
  );
};

export default ConfirmationModal;
