import { formatTime } from '../../global/helpers';
import { BonusOrdersDetailsData, BonusOrdersDetailsHistoryData } from './types';

export const getFormatedBonusOrdersDetails = (bonusOrdersDetails: {
  confirmed: BonusOrdersDetailsData[];
  pending: BonusOrdersDetailsData[];
  confirmed_total?: number;
  pending_total?: number;
}) => {
  return {
    confirmed:
      bonusOrdersDetails.confirmed.map((ordersDetails) => ({
        date: formatTime(ordersDetails.date),
        amount: ordersDetails.amount,
        confirmAt: formatTime(ordersDetails.confirm_at),
        totalSum:
          bonusOrdersDetails.confirmed_total &&
          bonusOrdersDetails.confirmed_total,
        orderId: ordersDetails.order_id && ordersDetails.order_id,
      })) || [],
    pending:
      bonusOrdersDetails.pending.map((ordersDetails) => ({
        date: formatTime(ordersDetails.date),
        amount: ordersDetails.amount,
        confirmAt: formatTime(ordersDetails.confirm_at),
        totalSum:
          bonusOrdersDetails.pending_total && bonusOrdersDetails.pending_total,
        orderId: ordersDetails.order_id && ordersDetails.order_id,
      })) || [],
  };
};

export const getFormatedBonusOrdersDetailsHistory = (
  bonusOrdersDetailsHistory: BonusOrdersDetailsHistoryData
) => {
  return bonusOrdersDetailsHistory.history.map((ordersDetailsHistory) => ({
    date: ordersDetailsHistory?.date && formatTime(ordersDetailsHistory.date),
    amount: ordersDetailsHistory.amount && ordersDetailsHistory.amount,
    description:
      ordersDetailsHistory.description && ordersDetailsHistory.description,
    orderId: ordersDetailsHistory.order_id || null,
    totalSum:
      bonusOrdersDetailsHistory.total_amount &&
      bonusOrdersDetailsHistory.total_amount,
  }));
};
