import { UserData } from '../OrdersPage/types';

export interface LoginState {
  errorMessage: string | null;
  error: string | null;
  loading: boolean;
  accessToken: string | null;
  refreshToken: string | null;

  userData: {
    data: any;
    error: null | string;
    loading: boolean;
  };
}

export interface UserResponse {
  result: UserData;
}

export enum ErrorCodeList {
  A401004 = 'A401004',
}
