import { useTranslation } from 'react-i18next';
import * as Styled from './OfflineOrdersModal.styles';
import ModalWindow from '../ModalWindow';
import {
  OrderTableData,
  DeliveryType,
} from '../../containers/OrdersPage/types';

interface OfflineOrdersModalProps {
  onClose: () => void;
  data: OrderTableData[] | null;
}

const OfflineOrdersModal = ({ onClose, data }: OfflineOrdersModalProps) => {
  const { t } = useTranslation();
  const deliveryTypeTitlesStrategy = {
    [DeliveryType.DELIVERY]: t(
      'OrdersPage.OrdersTable.DELIVERY_STATUS.DELIVERY'
    ),
    [DeliveryType.SELF]: t('OrdersPage.OrdersTable.DELIVERY_STATUS.SELF'),
  };

  return (
    <ModalWindow onClose={onClose}>
      <Styled.OrderCancelModalContent>
        <div className="modal-title">{t('OfflineOrdersModal.MODAL_TITLE')}</div>

        <div className="sticky-container">
          <p className="column-order">{t('OfflineOrdersModal.ORDER_NUMBER')}</p>
          <p className="column-phone">{t('OfflineOrdersModal.ORDER_PHONE')}</p>
          <p className="column-date">{t('OfflineOrdersModal.ORDER_DATE')}</p>
          <p className="column-name">{t('OfflineOrdersModal.PHARMACY')}</p>
          <p className="column-delivery">{t('OfflineOrdersModal.DELIVERY')}</p>
          <p className="column-price">{t('OfflineOrdersModal.PRICE')}</p>
          <p className="column-status-delivery">
            {t('OfflineOrdersModal.DELIVERY_STATUS')}
          </p>
          <p className="column-status">{t('OfflineOrdersModal.STATUS')}</p>
        </div>

        <ul className="list">
          {data?.map((item) => (
            <li className="item" key={item.id}>
              <p className="column-order">{item.orderNumber}</p>
              <p className="column-phone">{item.phone}</p>
              <p className="column-date">{item.createdAt}</p>
              <p className="column-name">{item.sourceName}</p>
              <p className="column-delivery">
                {deliveryTypeTitlesStrategy[item.deliveryType]}
              </p>
              <p className="column-price">{item.amount}</p>
              <p className="column-status-delivery">
                {item.statusNameDelivery}
              </p>
              <p className="column-status">{item.statusName}</p>
            </li>
          ))}
        </ul>
      </Styled.OrderCancelModalContent>
    </ModalWindow>
  );
};

export default OfflineOrdersModal;
