import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalWindow from '../../../../components/ModalWindow';
import * as Styled from './ConfirmationDeleteProduct.styles';
import Button from '../../../../components/Button';
import { addNewProduct, deleteFromOrder } from '../../../../global/helpers';

interface Props {
  onClose: () => void;
  name: string;
  sku: string;
  analog: boolean;
  data?: any;
}

const ConfirmationDeleteProduct = ({
  onClose,
  name,
  sku,
  analog,
  data,
}: Props) => {
  const { t } = useTranslation();

  const handleDelete = () => {
    if (data?.proposal && !analog) {
      addNewProduct(data.proposal, data.proposal.pharmacyCount);
    }

    deleteFromOrder(sku, analog);
    onClose();
  };

  return (
    <ModalWindow onClose={onClose}>
      <Styled.MainConfirmation>
        <div className="main-title">{t('OrderPage.DeleteModal.TITLE')}</div>
        <div className="confirmation">
          {t('OrderPage.DeleteModal.TITLE_CONFIRMATION')} {name} ?
        </div>
        <div className="btn-block">
          <Button
            text={t('OrderPage.DeleteModal.CANCEL')}
            variant="outlined"
            onClick={onClose}
          />
          <div className="buttonCancel">
            <Button
              text={t('OrderPage.DeleteModal.DELETE')}
              onClick={handleDelete}
            />
          </div>
        </div>
      </Styled.MainConfirmation>
    </ModalWindow>
  );
};

export default ConfirmationDeleteProduct;
