import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import * as actionsNotification from '../InitComponent/actions';
import { EditService } from './service';
import {
  NOTIFICATION_CHANGES_SAVED,
  NOTIFICATION_CHANGES_NOT_SAVED,
  NOTIFICATION_PRODUCT_NOT_BLOCKED,
  NOTIFICATION_PRODUCT_BLOCKED,
  NOTIFICATION_MAPPING_ERROR,
  NOTIFICATION_MAPPING_SUCCESS,
} from '../../global/constants';
import { ProductResponseItem } from '../OrdersPage/types';
import { mapDataForProductInfo } from './helpers';
import { ProductsService } from '../ProductsPage/service';

interface PutResponse {
  status: string;
}

interface GetProductForServiceResponse {
  items: ProductResponseItem[];
  search_criteria: any;
  total_count: number;
}

interface GetProductBySkuResponse {
  items: ProductResponseItem[];
  search_criteria: any;
  total_count: number;
}

export function* getProductsSearchForServiceSaga({ payload }: any) {
  try {
    const response: GetProductForServiceResponse = yield call(
      EditService.getProducts,
      payload.searchValue
    );
    yield put(actions.getProductsSearchForPharmacy.success(response.items));

    const skus = response.items.map((el) => el.sku) || [];

    if (payload.postEffect) {
      yield call(payload.postEffect(skus));
    }
  } catch (error) {
    yield put(actions.getProductsSearchForPharmacy.error(error));
  }
}

export function* getProductBySkuSaga({ payload }: any) {
  try {
    const response: GetProductBySkuResponse = yield call(
      EditService.getProductBySku,
      payload.sku
    );

    yield put(
      actions.getProductBySku.success(mapDataForProductInfo(response.items[0]))
    );
  } catch (error) {
    yield put(actions.getProductBySku.error(error));
  }
}

export function* postDataForMapping({ payload }: any) {
  try {
    const response: PutResponse = yield call(
      EditService.postDataForMappings,
      payload.token,
      payload.sku,
      payload.wareId,
      payload.code
    );

    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_MAPPING_SUCCESS,
        canBeClosed: true,
        type: 'success',
      })
    );
    if (payload.postEffect) {
      payload.postEffect();
    }
    yield put(actions.postDataForMappings.success(response));
  } catch (error) {
    if (payload.errorEffect) {
      payload.errorEffect();
    }
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_MAPPING_ERROR,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.postDataForMappings.error(error));
  }
}

export function* blockedProduct({ payload }: any) {
  try {
    // @ts-ignore
    const response: PutResponse = yield call(
      EditService.blockProduct,
      payload.token,
      payload.wareId,
      payload.code
    );
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_PRODUCT_BLOCKED,
        canBeClosed: true,
        type: 'success',
      })
    );
    if (payload.postEffect) {
      payload.postEffect();
    }
    yield put(actions.blockedProduct.success(response));
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_PRODUCT_NOT_BLOCKED,
        canBeClosed: true,
        type: 'success',
      })
    );
    yield put(actions.blockedProduct.error(error));
  }
}

export function* unblockedProduct({ payload }: any) {
  try {
    // @ts-ignore
    const response: PutResponse = yield call(
      EditService.unblockProduct,
      payload.token,
      payload.wareId,
      payload.code
    );
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_SAVED,
        canBeClosed: true,
        type: 'success',
      })
    );
    if (payload.postEffect) {
      payload.postEffect();
    }
    yield put(actions.unblockedProduct.success(response));
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.unblockedProduct.error(error));
  }
}

export function* deleteMappings({ payload }: any) {
  try {
    // @ts-ignore
    const response: PutResponse = yield call(
      EditService.deleteMappings,
      payload.token,
      payload.wareId,
      payload.code
    );
    yield put(actions.deleteMappings.success(response));
    if (payload.postEffect) {
      payload.postEffect();
    }
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_SAVED,
        canBeClosed: true,
        type: 'success',
      })
    );
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.deleteMappings.error(error));
  }
}

export function* editPageWatcherSaga() {
  yield all([
    takeLatest(
      actions.getProductsSearchForPharmacy.REQUEST,
      getProductsSearchForServiceSaga
    ),
    takeLatest(actions.postDataForMappings.REQUEST, postDataForMapping),
    takeLatest(actions.blockedProduct.REQUEST, blockedProduct),
    takeLatest(actions.unblockedProduct.REQUEST, unblockedProduct),
    takeLatest(actions.deleteMappings.REQUEST, deleteMappings),
    takeLatest(actions.getProductBySku.REQUEST, getProductBySkuSaga),
  ]);
}
