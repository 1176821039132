import { NetworkProps, NetworkResponse } from './types';
import { getPharmacyTagsById, getTrimmedFields } from '../../global/helpers';
import { PharmacyTag } from '../SourcePage/types';

export const mappedDataForNetwork = (
  data: NetworkResponse,
  tagsResponse?: PharmacyTag[]
) => {
  return {
    bank: data.bank,
    bik: data.bik,
    bin: data.bin,
    code: data.code,
    director: data.director,
    iik: data.iik,
    legalAddress: data.legal_address,
    legalName: data.legal_name,
    name: data.name,
    organizationType: data.organization_type,
    phone: data.phone,
    rounding: data.rounding_rule || 0,
    roundingMethod: data.rounding_method,
    networkTags: getPharmacyTagsById(data.tags, tagsResponse),
    hasSyncError: data.has_sync_error,
  };
};

export const formatForEditNetwork = (
  data: NetworkProps,
  name: string,
  type: string,
  roundingMeth: string
) => {
  return {
    bank: getTrimmedFields(data.bank),
    bik: getTrimmedFields(data.bik),
    bin: getTrimmedFields(data.bin),
    code: getTrimmedFields(data.code),
    director: getTrimmedFields(data.director),
    iik: getTrimmedFields(data.iik),
    legal_address: getTrimmedFields(data.legalAddress),
    legal_name: getTrimmedFields(data.legalName),
    name: getTrimmedFields(name),
    organization_type: getTrimmedFields(type),
    phone: getTrimmedFields(data.phone),
    rounding_rule: Number(data.rounding),
    rounding_method: roundingMeth,
  };
};
