import { createAction } from '@reduxjs/toolkit';
import { asyncActionsCreator } from '../../global/redux';
import * as constants from '../EditPage/constants';

export const getProductsSearchForPharmacy = asyncActionsCreator(
  constants.GET_PRODUCTS_SEARCH_FOR_PHARMACY
);

export const getProductBySku = asyncActionsCreator(
  constants.GET_PRODUCT_BY_SKU
);

export const postDataForMappings = asyncActionsCreator(
  constants.DATA_FOR_MAPPINGS
);

export const blockedProduct = asyncActionsCreator(constants.BLOCKED_PRODUCT);
export const unblockedProduct = asyncActionsCreator(
  constants.UNBLOCKED_PRODUCT
);

export const deleteMappings = asyncActionsCreator(
  constants.DELETE_PRODUCTS_MAPPING
);

export const setSelectedProduct = createAction(constants.SET_SELECTED_PRODUCT);
export const clearSelectedProduct = createAction(
  constants.CLEAR_SELECTED_PRODUCT
);
