import { asyncActionsCreator } from '../../global/redux';
import * as constants from './constants';

export const getAnalogsListBySku = asyncActionsCreator(
  constants.GET_ANALOGS_LIST
);

export const getAnalogsListPharmacistBySku = asyncActionsCreator(
  constants.GET_ANALOGS_LIST_PHARMACIST
);

export const getAnalogsListInformationPharmacistBySku = asyncActionsCreator(
  constants.GET_ANALOGS_INFORMATION_LIST_PHARMACIST
);

export const createSuggestion = asyncActionsCreator(
  constants.CREATE_SUGGESTION
);

export const saveSuggestionsOrder = asyncActionsCreator(
  constants.SAVE_SUGGESTIONS_ORDER
);

export const deleteSuggestion = asyncActionsCreator(
  constants.DELETE_SUGGESTION
);
