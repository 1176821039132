import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { ImgResponseParam, ProductResponseItem } from '../OrdersPage/types';
import { OrderService } from './service';
import * as actionsNotification from '../InitComponent/actions';
import {
  NOTIFICATION_CHANGES_NOT_SAVED,
  NOTIFICATION_CHANGES_SAVED,
} from '../../global/constants';
import {
  OrderPutInfo,
  OrderStatusesResponse,
  OrderStatusesNewResponse,
} from './types';
import { mapDataWithPrice, mapInterpayReport } from './helpers';
import { InterpayPayout, ProductsResponse } from '../ProductsPage/types';
import { mappedResponseWithSkuName } from '../ProductsPage/helpers';

interface GetProductForServiceResponse {
  items: ProductResponseItem[];
  search_criteria: any;
  total_count: number;
}

interface SearchSagaParams {
  type: string;
  payload: { id: string; searchValue: string; source: string; orderId: string };
}

export interface N8nParams {
  type: string;
  payload: {
    client_address: string;
    created_at: string;
    entered_price: number;
    order_id: string;
    pharmacy_address: string;
    selected_price: number;
    postEffect: any;
  };
}

interface PutSagaParams {
  type: string;
  payload: { orderInfo: OrderPutInfo; id: string; postEffect: any };
}

interface ApplyPromoCode {
  type: string;
  payload: { id: string; data: string; postEffect: any };
}

interface SendEdit {
  type: string;
  payload: {
    id: string;
    data: [
      {
        name: 'string';
        need_receipt: true;
        other_price: true;
        out_of_stock: true;
        packing_diff: true;
        sku: 'string';
        term: true;
      }
    ];
    postEffect: any;
  };
}

interface GetAllStatusesParam {
  type: string;
  payload: { token: string };
}

interface changeTimerParams {
  type: string;
  payload: { orderId: string; orderTimer: any; postEffect: any };
}

interface TagsParam {
  type: string;
  payload: {
    token: string;
    wereId: string;
    postEffect?: (response: any) => void;
  };
}

interface InterpayPayoutParam {
  type: string;
  payload: {
    token: string;
    orderId: string;
    time?: string;
    status?: string;
    payoutDate?: string;
    comment?: string;
    payoutInfo?: string;
    postEffect?: any;
  };
}

export function* postImg({ payload }: any) {
  try {
    const response: ImgResponseParam = yield call(
      OrderService.postRecipe,
      payload.token,
      payload.data
    );

    yield put(actions.postImg.success(response.result.link));
  } catch (error) {
    yield put(actions.postImg.error(error));
  }
}

export function* getAllStatuses({ payload }: GetAllStatusesParam) {
  try {
    const response: OrderStatusesNewResponse = yield call(
      OrderService.getAllStatuses,
      payload.token
    );

    yield put(actions.getAllStatuses.success(response.result));
  } catch (error) {
    yield put(actions.getAllStatuses.error(error));
  }
}

export function* getAllPharmacyStatuses({ payload }: GetAllStatusesParam) {
  try {
    const response: OrderStatusesResponse = yield call(
      OrderService.getAllStatusesPharmacy,
      payload.token
    );
    yield put(actions.getAllPharmacyStatuses.success(response.result));
  } catch (error) {
    yield put(actions.getAllPharmacyStatuses.error(error));
  }
}

export function* getApproximatePrice({ payload }: any) {
  try {
    // @ts-ignore
    const response: any = yield call(
      OrderService.getApproximatePrice,
      payload.sku
    );
    yield put(actions.getPrice.success(response.result[0].min_customer_price));
    if (payload.postEffect) {
      payload.postEffect();
    }
  } catch (error) {
    yield put(actions.getPrice.error(error));
  }
}

export function* n8nPrice({ payload }: N8nParams) {
  try {
    // @ts-ignore
    const response: any = yield call(OrderService.n8nPrice, payload);
    yield put(actions.n8nPrice.success(response.result));

    if (payload.postEffect) {
      yield call(payload.postEffect);
    }
  } catch (error) {
    yield put(actions.n8nPrice.error(error));
  }
}
export function* getPriceWithQuantitySaga({ payload }: any) {
  if (payload.skus.length !== 0) {
    try {
      // @ts-ignore
      const response: any = yield call(
        OrderService.getPriceWithQuantityBySource,
        payload.source,
        payload.orderId,
        payload.skus.filter((el: string) => el !== '')
      );
      yield put(actions.getPriceWithQuantityBySource.success(response.result));
    } catch (error) {
      yield put(actions.getPriceWithQuantityBySource.error(error));
    }
  }
}

export function* sendEditSaga({ payload }: SendEdit) {
  try {
    // @ts-ignore
    const response: any = yield call(
      OrderService.sendEdit,
      payload.id,
      payload.data
    );
    yield put(actions.sendEdit.success(response.data));
    if (payload.postEffect) {
      payload.postEffect();
    }
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.sendEdit.error(error));
  }
}

export function* applyPromoCode({ payload }: ApplyPromoCode) {
  try {
    // @ts-ignore
    const response: any = yield call(
      OrderService.applyPromoCode,
      payload.id,
      payload.data
    );
    yield put(actions.applyPromoCode.success(response.availability));
    if (payload.postEffect) {
      payload.postEffect();
    }
    if (
      actions.applyPromoCode.success(response.availability)?.payload ===
      'available'
    ) {
      yield put(
        actionsNotification.addApplicationNotification({
          content: 'Промокод применен',
          canBeClosed: true,
          type: 'success',
        })
      );
    }
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.applyPromoCode.error(error));
  }
}

export function* getProductsSearchForServiceSaga({
  payload,
}: SearchSagaParams) {
  try {
    const response: GetProductForServiceResponse = yield call(
      OrderService.getProducts,
      payload.searchValue
    );
    const skus = response.items?.map((item) => item.sku);
    let responsePriceQuantity;
    if (skus.length !== 0) {
      // @ts-ignore
      responsePriceQuantity = yield call(
        OrderService.getPriceWithQuantityBySource,
        payload.source,
        payload.orderId,
        skus.filter((el: string) => el !== '')
      );
    }

    const mappedData = mapDataWithPrice(
      response.items,
      responsePriceQuantity.result
    );

    yield put(actions.getProductsSearchForAnalog.success(mappedData));
  } catch (error) {
    yield put(actions.getProductsSearchForAnalog.error(error));
  }
}

export function* putOrder({ payload }: PutSagaParams) {
  try {
    const response: { status: string } = yield call(
      OrderService.putOrder,
      payload.orderInfo,
      payload.id
    );

    if (payload.postEffect) {
      payload.postEffect();
    }
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_SAVED,
        canBeClosed: true,
        type: 'success',
      })
    );
    yield put(actions.putOrder.success(response.status));
  } catch (error) {
    // @ts-ignore
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.putOrder.error(error));
  }
}

export function* changeTimerSaga({ payload }: changeTimerParams) {
  try {
    // @ts-ignore
    const response = yield call(
      OrderService.changeOrderTimer,
      payload.orderId,
      payload.orderTimer
    );
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_SAVED,
        canBeClosed: true,
        type: 'success',
      })
    );
    if (payload.postEffect) {
      payload.postEffect();
    }

    yield put(actions.changeTimer.success(response));
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.changeTimer.error(error));
  }
}

export function* getProductsTagsSaga({ payload }: TagsParam) {
  try {
    const responseAttached: ProductsResponse = yield call(
      OrderService.getProductsTags,
      payload.token,
      payload.wereId
    );

    const mappedData = mappedResponseWithSkuName(responseAttached.result);
    yield put(actions.getProductsAtt.success(mappedData));
  } catch (error) {
    yield put(actions.getProductsAtt.error(error));
  }
}

export function* getInterpayPayoutsSaga({ payload }: InterpayPayoutParam) {
  try {
    const res: InterpayPayout = yield call(
      OrderService.getInterpayPayouts,
      payload.token,
      payload.orderId
    );
    const mappedData = mapInterpayReport(res.payouts);
    yield put(actions.getInterpayPayouts.success(mappedData));
  } catch (error) {
    yield put(actions.getInterpayPayouts.error(error));
  }
}

export function* createInterpayPayoutsSaga({ payload }: InterpayPayoutParam) {
  try {
    // @ts-ignore
    const response = yield call(
      // @ts-ignore
      OrderService.createInterpayPayouts,
      payload.orderId,
      payload.time,
      payload.token
    );

    const mappedData = mapInterpayReport(response.payouts);
    yield put(actions.createInterpayPayouts.success(mappedData));
  } catch (error) {
    yield put(actions.createInterpayPayouts.error(error));
  }
}

export function* changeInterpayPayoutsSaga({ payload }: InterpayPayoutParam) {
  try {
    // @ts-ignore
    const response = yield call(
      // @ts-ignore
      OrderService.changeInterpayPayouts,
      payload.token,
      payload.orderId,
      payload.status,
      payload.comment,
      payload.payoutInfo,
      payload.payoutDate
    );

    yield put(actions.changeInterpayPayouts.success(response));

    if (payload.postEffect) {
      yield call(payload.postEffect);
    }
  } catch (error) {
    yield put(actions.changeInterpayPayouts.error(error));
  }
}

export function* orderPageWatcherSaga() {
  yield all([
    takeLatest(actions.postImg.REQUEST, postImg),
    takeLatest(
      actions.getProductsSearchForAnalog.REQUEST,
      getProductsSearchForServiceSaga
    ),
    takeLatest(actions.putOrder.REQUEST, putOrder),
    takeLatest(actions.getAllStatuses.REQUEST, getAllStatuses),
    takeLatest(actions.getAllPharmacyStatuses.REQUEST, getAllPharmacyStatuses),
    takeLatest(actions.applyPromoCode.REQUEST, applyPromoCode),
    takeLatest(actions.n8nPrice.REQUEST, n8nPrice),
    takeLatest(actions.sendEdit.REQUEST, sendEditSaga),
    takeLatest(actions.getPrice.REQUEST, getApproximatePrice),
    takeLatest(
      actions.getPriceWithQuantityBySource.REQUEST,
      getPriceWithQuantitySaga
    ),
    takeLatest(actions.changeTimer.REQUEST, changeTimerSaga),
    takeEvery(actions.getProductsAtt.REQUEST, getProductsTagsSaga),
    takeLatest(actions.getInterpayPayouts.REQUEST, getInterpayPayoutsSaga),
    takeLatest(
      actions.createInterpayPayouts.REQUEST,
      createInterpayPayoutsSaga
    ),
    takeLatest(
      actions.changeInterpayPayouts.REQUEST,
      changeInterpayPayoutsSaga
    ),
  ]);
}
