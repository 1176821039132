import { useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import * as actions from '../../actions';
import { selectors } from '../../reducer';
import { selectors as userSelector } from '../../../LoginPage/reducer';

import * as Styled from './RefundsBlock.styles';

interface RefundsBlockParam {
  network: string;
}

const RefundsBlock = ({ network }: RefundsBlockParam) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const payments = useSelector(selectors.payments);
  const refunds = useSelector(selectors.refunds);
  const userData = useSelector(userSelector.userData);
  useEffect(() => {
    if (userData && userData.networkCode) {
      dispatch(
        actions.getPayments.request({
          network_code: userData.networkCode,
        })
      );
      dispatch(
        actions.getRefunds.request({
          network_code: userData.networkCode,
        })
      );
    }
  }, [userData]);

  return (
    <Styled.RefundsReportContainer>
      <Typography variant="h6" marginBottom="20px">
        {t('ReportPage.PAYMENTS_TITLE')}
      </Typography>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 550, tableLayout: 'fixed' }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell width="250px">
                {t('ReportPage.PAYMENTS_AND_REFUNDS')}
              </TableCell>
              <TableCell width="150px" align="center">
                {t('ReportPage.SUM')}
              </TableCell>
              <TableCell width="150px" align="center">
                {t('ReportPage.DATE')}
              </TableCell>
              <TableCell width="250px" align="center">
                {t('ReportPage.ORDER')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payments &&
              payments.map((row, index) => {
                return (
                  <TableRow>
                    <TableCell width="230px">
                      {t('ReportPage.WAITING_PAYMENT')}
                    </TableCell>
                    <TableCell align="center">{row.total_sum}</TableCell>
                    <TableCell align="center">
                      {new Date(row.payout_date).toLocaleDateString()}
                    </TableCell>
                    <TableCell>
                      {row.reports.map((el) => (
                        <span>
                          <button
                            type="button"
                            onClick={() =>
                              history.push(`/orders/${el.order_id}`)
                            }
                            className="orderBtn"
                          >
                            {el.order_id}
                          </button>
                        </span>
                      ))}
                    </TableCell>
                  </TableRow>
                );
              })}
            {refunds && (
              <TableRow>
                <TableCell width="230px">
                  {t('ReportPage.WAITING_REFUNDS')}
                </TableCell>
                <TableCell align="center">{refunds.total_sum}</TableCell>
                <TableCell align="center">
                  {t('ReportPage.PATMENTS_DATE')}
                </TableCell>
                <TableCell width="230px">
                  {refunds.reports &&
                    refunds.reports.map((el, index) => (
                      <span>
                        <button
                          type="button"
                          onClick={() => history.push(`/orders/${el.order_id}`)}
                          className="orderBtn"
                        >
                          {el.order_id}
                        </button>
                      </span>
                    ))}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Styled.RefundsReportContainer>
  );
};

export default RefundsBlock;
