import styled from 'styled-components';
import { baseTheme } from '../../global/styles/theme';

export const HeaderContainer = styled('div')`
  background-color: ${baseTheme.colors.mainBlue};
  padding: 12px 32px;
`;

export const HeaderContent = styled('div')`
  padding: 0 16px;
  margin: auto;
  display: flex;
  height: 40px;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 550px) {
    padding: 0;
  }
`;

export const HeaderLogoPharmacyNameContainer = styled('div')`
  display: flex;
  justify-content: space-between;

  .active-network {
    padding-top: 3px;
    text-decoration: underline;
    cursor: pointer;
  }
`;

const active = {
  main: 'mediumseagreen',
};

export const PharmacyNameContainer = styled('div')`
  line-height: 24px;
  font-size: 16px;
  font-weight: 500;
  color: ${baseTheme.colors.white};
  margin-left: 20px;
  margin-right: 10px;
`;

export const HeaderLogo = styled('div')`
  cursor: pointer;
`;

export const HeaderControls = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const HeaderButton = styled('div')`
  font-size: 18px;
  font-weight: 500;
  margin: 9px 9px 0 0;
  color: ${baseTheme.colors.white};
  cursor: pointer;
`;

export const HeaderButtonContainer = styled('div')`
  padding: 9px 9px;
  border-radius: 8px;
  background-color: ${(props) =>
    props.className?.length
      ? `${baseTheme.colors.focus}`
      : `${baseTheme.colors.white}`};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  margin-right: 16px;
  color: ${(props) =>
    props.className?.length
      ? `${baseTheme.colors.white}`
      : `${baseTheme.colors.mainLightBlue}`};
  cursor: pointer;
  position: relative;
  img {
    padding-right: 12px;
  }
  & .star {
    position: absolute;
    right: -10px;
    top: -10px;
    padding: 5px;
    border-radius: 5px;
    background-color: red;
    color: white;
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & .not-visited {
         animation-duration: 1s;
    animation-name: slidein-yellow;
    animation-iteration-count: infinite;

    @keyframes slidein-yellow {
      0% {
        color: ${baseTheme.colors.white}
        background-color: ${baseTheme.colors.error};
      }

      50% {
        background-color: ${baseTheme.colors.white};
        color: #000;
      }

      100% {
        color: ${baseTheme.colors.white}
        background-color: ${baseTheme.colors.error};
      }
    }
      }
`;

export const HeaderButtonQuestions = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin-right: 16px;
  color: ${baseTheme.colors.white};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const HeaderLogoutButtonContainer = styled('div')`
  padding: 9px 9px;
  border-radius: 8px;
  background-color: ${baseTheme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  color: ${baseTheme.colors.mainLightBlue};
  cursor: pointer;
`;

export const BurgerModal = styled('div')`
  background: '#003e6e', 
  height: '100vh',
  z-index: 3333
`;
