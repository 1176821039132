import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Styled from './EditBreadCrumbs.style';
// @ts-ignore
import { ReactComponent as ArrowIcon } from '../../../../global/media/arrow-breadCrumbs.svg';
import * as actionService from '../../actions';
import { setToLocalStorage } from '../../../../global/helpers';

const EditBreadCrumbs = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleProductsClick = () => {
    dispatch(actionService.clearSelectedProduct());
    //  setToLocalStorage('selectedProduct', null);
    history.push('/products');
  };

  const handleClick = (push: string) => {
    dispatch(actionService.clearSelectedProduct());
    setToLocalStorage('selectedProduct', null);
    history.push(push);
  };

  return (
    <Styled.EditBreadCrumbsContainer>
      <div className="breadCrumb" onClick={() => handleClick('orders')}>
        {t('EditPage.EditBreadcrumb.ORDERS_BREADCRUMB')}
      </div>
      <div className="breadcrumb-icon active-icon">
        <ArrowIcon />
      </div>
      <div className="breadCrumb" onClick={handleProductsClick}>
        {t('EditPage.EditBreadcrumb.PRODUCTS_BREADCRUMB')}
      </div>
      <div className="breadcrumb-icon">
        <ArrowIcon />
      </div>
      <div className="breadCrumb active">
        {t('EditPage.EditBreadcrumb.EDIT_BREADCRUMB')}
      </div>
    </Styled.EditBreadCrumbsContainer>
  );
};

export default EditBreadCrumbs;
