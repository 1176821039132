import styled from 'styled-components';
import { baseTheme } from '../../../../global/styles/theme';

export const CreateTemplates = styled('div')`
width:100%;
.cell-header{
    width:122px;
    border:solid 1px grey;
    padding:5px;
}
.cell{
    width:122px;
    border:solid 1px grey;
    
}
.input{
    width:100%;
    padding:5px;
    
}

.error-message-label {
    margin: 8px 0 0;
    color: ${baseTheme.colors.error};
    font-size: 14px;
    width: 80%;
  }
  
.create-templates{
    border: none;
    font: inherit;
    font-weight: 500;
    font-size: 18px;
    color: ${baseTheme.colors.white};
    background-color: ${baseTheme.colors.mainLightBlue};
    border-radius: 8px;
    padding: 12px 24px;
    text-align: center;
    max-width: 200px;
    margin-top: 24px;
    cursor: pointer;
    
    &:hover {
      background-color: ${baseTheme.colors.mainBlue};
    }
}

.container-table{
    display:flex;
    height:fit-content;
    min-height:105px;
}
.container-header{
   
margin-top:20px;
    display:flex;
    min-height:45px;
}
  }
`;
