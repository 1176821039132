import { createReducer } from '@reduxjs/toolkit';
import * as actions from './actions';
import { AppState } from '../../global/types';
import { ProductsPageState } from './types';

const initialState: ProductsPageState = {
  productsUnAttached: {
    data: null,
    loading: false,
    error: null,
  },

  productsAttached: {
    data: null,
    loading: false,
    error: null,
  },

  productsAttachedAll: {
    data: null,
    loading: false,
    error: null,
  },

  productsBlocked: {
    data: null,
    loading: false,
    error: null,
  },

  productsVerification: {
    data: null,
    loading: false,
    error: null,
  },

  productsAutomapping: {
    data: null,
    loading: false,
    error: null,
  },

  productsDybl: {
    data: null,
    loading: false,
    error: null,
  },

  productsSearchUnAttached: {
    data: null,
    loading: false,
    error: null,
  },

  productsSearchAttached: {
    data: null,
    loading: false,
    error: null,
  },

  productsSearchBlocked: {
    data: null,
    loading: false,
    error: null,
  },

  productsSearchOnVerification: {
    data: null,
    loading: false,
    error: null,
  },

  productsSearchDybl: {
    data: null,
    loading: false,
    error: null,
  },

  productsSearchAutomapping: {
    data: null,
    loading: false,
    error: null,
  },

  editType: {
    selectedProduct: null,
  },

  openNetworks: false,
  network: null,
  tagIds: null,

  networksData: {
    data: null,
    loading: false,
    error: null,
  },

  tagsData: {
    data: null,
    loading: false,
    error: null,
  },
};

const reducer = createReducer(initialState, {
  [actions.getProductsUnAtt.REQUEST]: (state) => {
    state.productsUnAttached.loading = true;
  },
  [actions.getProductsUnAtt.ERROR]: (state, action) => {
    state.productsUnAttached.error = action.payload;
    state.productsUnAttached.loading = false;
  },
  [actions.getProductsUnAtt.SUCCESS]: (state, action) => {
    state.productsUnAttached.data = action.payload;
    state.productsUnAttached.loading = false;
  },
  [actions.getProductsAtt.REQUEST]: (state) => {
    state.productsAttached.loading = true;
  },
  [actions.getProductsAtt.ERROR]: (state, action) => {
    state.productsAttached.error = action.payload;
    state.productsAttached.loading = false;
  },
  [actions.getProductsAtt.SUCCESS]: (state, action) => {
    state.productsAttached.data = action.payload;
    state.productsAttached.loading = false;
  },

  [actions.getProductsAttALL.REQUEST]: (state) => {
    state.productsAttachedAll.loading = true;
  },
  [actions.getProductsAttALL.ERROR]: (state, action) => {
    state.productsAttachedAll.error = action.payload;
    state.productsAttachedAll.loading = false;
  },
  [actions.getProductsAttALL.SUCCESS]: (state, action) => {
    state.productsAttachedAll.data = action.payload;
    state.productsAttachedAll.loading = false;
  },
  [actions.getProductsBlock.REQUEST]: (state) => {
    state.productsBlocked.loading = true;
  },
  [actions.getProductsBlock.ERROR]: (state, action) => {
    state.productsBlocked.error = action.payload;
    state.productsBlocked.loading = false;
  },
  [actions.getProductsBlock.SUCCESS]: (state, action) => {
    state.productsBlocked.data = action.payload;
    state.productsBlocked.loading = false;
  },

  [actions.getProductsVer.REQUEST]: (state) => {
    state.productsVerification.loading = true;
  },
  [actions.getProductsVer.ERROR]: (state, action) => {
    state.productsVerification.error = action.payload;
    state.productsVerification.loading = false;
  },
  [actions.getProductsVer.SUCCESS]: (state, action) => {
    state.productsVerification.data = action.payload;
    state.productsVerification.loading = false;
  },
  [actions.getProductsAutomapping.REQUEST]: (state) => {
    state.productsAutomapping.loading = true;
  },
  [actions.getProductsAutomapping.ERROR]: (state, action) => {
    state.productsAutomapping.error = action.payload;
    state.productsAutomapping.loading = false;
  },
  [actions.getProductsAutomapping.SUCCESS]: (state, action) => {
    state.productsAutomapping.data = action.payload;
    state.productsAutomapping.loading = false;
  },
  [actions.getProductsDybl.REQUEST]: (state) => {
    state.productsDybl.loading = true;
  },
  [actions.getProductsDybl.ERROR]: (state, action) => {
    state.productsDybl.error = action.payload;
    state.productsDybl.loading = false;
  },
  [actions.getProductsDybl.SUCCESS]: (state, action) => {
    state.productsDybl.data = action.payload;
    state.productsDybl.loading = false;
  },
  [actions.getProductsSearchUnattached.REQUEST]: (state) => {
    state.productsSearchUnAttached.loading = true;
  },
  [actions.getProductsSearchUnattached.ERROR]: (state, action) => {
    state.productsSearchUnAttached.error = action.payload;
    state.productsSearchUnAttached.loading = false;
  },
  [actions.getProductsSearchUnattached.SUCCESS]: (state, action) => {
    state.productsSearchUnAttached.data = action.payload;
    state.productsSearchUnAttached.loading = false;
  },
  [actions.getProductsSearchAttached.REQUEST]: (state) => {
    state.productsSearchAttached.loading = true;
  },
  [actions.getProductsSearchAttached.ERROR]: (state, action) => {
    state.productsSearchAttached.error = action.payload;
    state.productsSearchAttached.loading = false;
  },
  [actions.getProductsSearchAttached.SUCCESS]: (state, action) => {
    state.productsSearchAttached.data = action.payload;
    state.productsSearchAttached.loading = false;
  },
  [actions.getProductsSearchBlocked.REQUEST]: (state) => {
    state.productsSearchBlocked.loading = true;
  },
  [actions.getProductsSearchBlocked.ERROR]: (state, action) => {
    state.productsSearchBlocked.error = action.payload;
    state.productsSearchBlocked.loading = false;
  },
  [actions.getProductsSearchBlocked.SUCCESS]: (state, action) => {
    state.productsSearchBlocked.data = action.payload;
    state.productsSearchBlocked.loading = false;
  },
  [actions.getProductsSearchDybl.REQUEST]: (state) => {
    state.productsSearchDybl.loading = true;
  },
  [actions.getProductsSearchDybl.ERROR]: (state, action) => {
    state.productsSearchDybl.error = action.payload;
    state.productsSearchDybl.loading = false;
  },
  [actions.getProductsSearchDybl.SUCCESS]: (state, action) => {
    state.productsSearchDybl.data = action.payload;
    state.productsSearchDybl.loading = false;
  },
  [actions.getProductsSearchOnVerification.REQUEST]: (state) => {
    state.productsSearchOnVerification.loading = true;
  },
  [actions.getProductsSearchOnVerification.ERROR]: (state, action) => {
    state.productsSearchOnVerification.error = action.payload;
    state.productsSearchOnVerification.loading = false;
  },
  [actions.getProductsSearchOnVerification.SUCCESS]: (state, action) => {
    state.productsSearchOnVerification.data = action.payload;
    state.productsSearchOnVerification.loading = false;
  },
  [actions.getProductsSearchAutomapping.REQUEST]: (state) => {
    state.productsSearchAutomapping.loading = true;
  },
  [actions.getProductsSearchAutomapping.ERROR]: (state, action) => {
    state.productsSearchAutomapping.error = action.payload;
    state.productsSearchAutomapping.loading = false;
  },
  [actions.getProductsSearchAutomapping.SUCCESS]: (state, action) => {
    state.productsSearchAutomapping.data = action.payload;
    state.productsSearchAutomapping.loading = false;
  },
  [actions.getNetworks.REQUEST]: (state) => {
    state.networksData.loading = true;
  },
  [actions.getNetworks.ERROR]: (state, action) => {
    state.networksData.error = action.payload;
    state.networksData.loading = false;
  },
  [actions.getNetworks.SUCCESS]: (state, action) => {
    state.networksData.data = action.payload;
    state.networksData.loading = false;
  },
  [actions.getTags.REQUEST]: (state) => {
    state.tagsData.loading = true;
  },
  [actions.getTags.ERROR]: (state, action) => {
    state.tagsData.error = action.payload;
    state.tagsData.loading = false;
  },
  [actions.getTags.SUCCESS]: (state, action) => {
    state.tagsData.data = action.payload;
    state.tagsData.loading = false;
  },
  [actions.setSelectedProduct.type]: (state, action) => {
    state.editType.selectedProduct = action.payload;
  },
  [actions.openNetworks.type]: (state) => {
    state.openNetworks = true;
  },
  [actions.closeNetworks.type]: (state) => {
    state.openNetworks = false;
  },
  [actions.setNetwork.type]: (state, action) => {
    state.network = action.payload;
  },
  [actions.setTag.type]: (state, action) => {
    state.tagIds = action.payload;
  },
  [actions.clearProductsSearchUnattached.type]: (state) => {
    state.productsSearchUnAttached.data = null;
  },
  [actions.clearProductsSearchAttached.type]: (state) => {
    state.productsSearchAttached.data = null;
  },
  [actions.clearProductsSearchBlocked.type]: (state) => {
    state.productsSearchBlocked.data = null;
  },
  [actions.clearProductsSearchOnVer.type]: (state) => {
    state.productsSearchOnVerification.data = null;
  },
  [actions.clearProductsSearchDybl.type]: (state) => {
    state.productsSearchDybl.data = null;
  },
  [actions.clearProductsSearchAutomapping.type]: (state) => {
    state.productsSearchAutomapping.data = null;
  },
  [actions.clearProductsAttached.type]: (state) => {
    state.productsAttached.data = null;
  },
  [actions.clearProductsUnAttached.type]: (state) => {
    state.productsUnAttached.data = null;
  },
  [actions.clearProductsOnVer.type]: (state) => {
    state.productsVerification.data = null;
  },
  [actions.clearProductsAutomapping.type]: (state) => {
    state.productsAutomapping.data = null;
  },
  [actions.clearProductsBlocked.type]: (state) => {
    state.productsBlocked.data = null;
  },
});

const unAttachedLoading = (state: AppState) =>
  state.productsPageReducer.productsUnAttached.loading;
const unAttachedError = (state: AppState) =>
  state.productsPageReducer.productsUnAttached.error;
const unAttachedData = (state: AppState) =>
  state.productsPageReducer.productsUnAttached.data;

const attachedLoading = (state: AppState) =>
  state.productsPageReducer.productsAttached.loading;
const attachedError = (state: AppState) =>
  state.productsPageReducer.productsAttached.error;
const attachedData = (state: AppState) =>
  state.productsPageReducer.productsAttached.data;

const attachedAllLoading = (state: AppState) =>
  state.productsPageReducer.productsAttachedAll.loading;
const attachedAllError = (state: AppState) =>
  state.productsPageReducer.productsAttachedAll.error;
const attachedAllData = (state: AppState) =>
  state.productsPageReducer.productsAttachedAll.data;

const blockedLoading = (state: AppState) =>
  state.productsPageReducer.productsBlocked.loading;
const blockedError = (state: AppState) =>
  state.productsPageReducer.productsBlocked.error;
const blockedData = (state: AppState) =>
  state.productsPageReducer.productsBlocked.data;

const verificationLoading = (state: AppState) =>
  state.productsPageReducer.productsVerification.loading;
const verificationError = (state: AppState) =>
  state.productsPageReducer.productsVerification.error;
const verificationData = (state: AppState) =>
  state.productsPageReducer.productsVerification.data;

const automappingLoading = (state: AppState) =>
  state.productsPageReducer.productsAutomapping.loading;
const automappingError = (state: AppState) =>
  state.productsPageReducer.productsAutomapping.error;
const automappingData = (state: AppState) =>
  state.productsPageReducer.productsAutomapping.data;

const dyblLoading = (state: AppState) =>
  state.productsPageReducer.productsDybl.loading;
const dyblError = (state: AppState) =>
  state.productsPageReducer.productsDybl.error;
const dyblData = (state: AppState) =>
  state.productsPageReducer.productsDybl.data;

const searchUnAttLoading = (state: AppState) =>
  state.productsPageReducer.productsSearchUnAttached.loading;
const searchUnAttData = (state: AppState) =>
  state.productsPageReducer.productsSearchUnAttached.data;

const searchAttLoading = (state: AppState) =>
  state.productsPageReducer.productsSearchAttached.loading;
const searchAttData = (state: AppState) =>
  state.productsPageReducer.productsSearchAttached.data;

const searchBlockLoading = (state: AppState) =>
  state.productsPageReducer.productsSearchBlocked.loading;
const searchBlockData = (state: AppState) =>
  state.productsPageReducer.productsSearchBlocked.data;

const searchOnVerLoading = (state: AppState) =>
  state.productsPageReducer.productsSearchOnVerification.loading;
const searchOnVerData = (state: AppState) =>
  state.productsPageReducer.productsSearchOnVerification.data;

const searchDyblLoading = (state: AppState) =>
  state.productsPageReducer.productsSearchDybl.loading;
const searchDyblData = (state: AppState) =>
  state.productsPageReducer.productsSearchDybl.data;

const searchAutomappingLoading = (state: AppState) =>
  state.productsPageReducer.productsSearchAutomapping.loading;
const searchAutomappingData = (state: AppState) =>
  state.productsPageReducer.productsSearchAutomapping.data;

const selectedProductData = (state: AppState) =>
  state.productsPageReducer.editType.selectedProduct;

const clearProductsSearchUnattached = (state: AppState) =>
  state.productsPageReducer.productsSearchUnAttached.data;
const clearProductsSearchAttached = (state: AppState) =>
  state.productsPageReducer.productsSearchAttached.data;
const clearProductsSearchBlocked = (state: AppState) =>
  state.productsPageReducer.productsSearchBlocked.data;
const clearProductsSearchOnVer = (state: AppState) =>
  state.productsPageReducer.productsSearchOnVerification.data;
const clearProductsSearchDybl = (state: AppState) =>
  state.productsPageReducer.productsSearchDybl.data;
const clearProductsSearchAutomapping = (state: AppState) =>
  state.productsPageReducer.productsSearchAutomapping.data;
const clearProductsUnAttached = (state: AppState) =>
  state.productsPageReducer.productsUnAttached.data;

const networksData = (state: AppState) =>
  state.productsPageReducer.networksData.data;

const networksDataLoading = (state: AppState) =>
  state.productsPageReducer.networksData.loading;

const isOpenNetworksModal = (state: AppState) =>
  state.productsPageReducer.openNetworks;

const network = (state: AppState) => state.productsPageReducer.network;
const tags = (state: AppState) => state.productsPageReducer.tagsData.data;
const tagIds = (state: AppState) => state.productsPageReducer.tagIds;

const selectors = {
  tags,
  tagIds,
  network,
  isOpenNetworksModal,
  networksData,
  networksDataLoading,
  attachedData,
  attachedError,
  attachedLoading,
  unAttachedData,
  unAttachedLoading,
  unAttachedError,
  blockedData,
  blockedError,
  blockedLoading,
  verificationData,
  verificationError,
  verificationLoading,
  dyblData,
  dyblError,
  dyblLoading,
  searchUnAttLoading,
  searchUnAttData,
  selectedProductData,
  searchAttData,
  searchAttLoading,
  searchBlockLoading,
  searchBlockData,
  searchOnVerLoading,
  searchOnVerData,
  searchDyblLoading,
  searchDyblData,
  clearProductsSearchBlocked,
  clearProductsSearchUnattached,
  clearProductsSearchOnVer,
  clearProductsSearchAttached,
  clearProductsSearchDybl,
  attachedAllData,
  attachedAllError,
  attachedAllLoading,
  clearProductsSearchAutomapping,
  automappingLoading,
  automappingError,
  automappingData,
  searchAutomappingData,
  searchAutomappingLoading,
  clearProductsUnAttached,
};

export { selectors };
export default reducer;
