import styled from 'styled-components';
import { baseTheme } from '../../global/styles/theme';

export const MainOrderContainer = styled('div')`
  div.hint-content {
    color: ${baseTheme.colors.primary};
  }
`;

export const OrdersPageContainer = styled('div')<{ isOperator?: boolean }>`
  max-width: ${({ isOperator }) => (isOperator ? '1416px' : '1216px')};
  margin: auto;
`;

export const EmployeesNeededText = styled('div')`
  background-color: red;
  max-width: 500px;
  padding: 24px;
  width: 500px;
  margin: 0 15px 20px 15px;
  background-color: #ffffff;
  border: 1px solid #e0e5eb;
  border-radius: 8px 8px 8px 8px;
  font-size: 14px;
  font-weight: 500;
  color: #121f30;
  white-space: pre-line;
  display: flex;
  justify-content: space-around;
  p {
    color: ${baseTheme.colors.mainLightBlue};
    font-size: 16px;
    width: 60%;
    p {
      font-size: 19px;
      width: 100%;
    }
    span {
      text-decoration: underline;
    }
  }

  @media only screen and (max-width: 550px) {
    width: 100%;
    margin: 0;
  }
`;

export const TestAttention = styled('div')`
  padding-top: 16px;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  color: ${baseTheme.colors.error};
`;

export const TestAttentionIns = styled('div')`
  padding-top: 16px;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  color: ${baseTheme.colors.mainLightBlue};
  margin-bottom: 16px;

  span {
    color: ${baseTheme.colors.mainBlue};
    padding-left: 8px;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export const OrdersPageTitle = styled('div')`
  font-weight: 700;
  font-size: 32px;
  color: ${baseTheme.colors.primary};
  @media only screen and (max-width: 550px) {
    font-size: 20px;
  }
`;

export const OrdersCreateText = styled('div')`
  @keyframes pulse {
    0% {
      transform: scale(0.7);
    }

    70% {
      transform: scale(1);
    }

    100% {
      transform: scale(0.7);
    }
  }

  .pulse-animation {
    transform: scale(1);
    animation: pulse 2s infinite;
    pointer-events: auto;
    color: ${baseTheme.colors.mainLightBlue};

    &:hover {
      color: ${baseTheme.colors.mainBlue};
    }
  }

  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 6px;
  font-weight: 700;
  font-size: 20px;
  max-height: 38px;
  color: ${baseTheme.colors.mainLightBlue};
  background-color: ${baseTheme.colors.white};
  border-radius: 8px;
  margin: 3px 0 0 16px;
  border: 2px solid ${baseTheme.colors.mainLightBlue};

  &:hover {
    color: ${baseTheme.colors.mainBlue};
    border: 2px solid ${baseTheme.colors.mainBlue};
  }
  @media only screen and (max-width: 550px) {
    padding: 24px 16px;
  }
  & .mobile-create-btn {
    font-size: 15px;
    padding: 10px 0;
  }
`;

export const TextContainer = styled('div')`
  display: flex;
  align-items: center;
`;

export const OrdersPageTableContainer = styled('div')`
  padding: 50px 0;

  @media only screen and (max-width: 550px) {
    padding: 24px 16px;
    overflow-x: auto;
    white-space: nowrap;
  }
`;

export const OrdersPageHeaderContainer = styled('div')`
  padding-top: 56px;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 550px) {
    padding: 16px;
    flex-direction: column;
    justify-content: space-between;
    height: fit-content;
  }
`;

export const OrdersPageTabsContainer = styled('div')`
  padding-top: 56px;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 550px) {
    flex-wrap: wrap;
  }
  .item-overdue {
    animation-duration: 2s;
    animation-name: slidein;
    animation-iteration-count: infinite;

    @keyframes slidein {
      0% {
        background-color: ${baseTheme.colors.white};
        color: ${baseTheme.colors.secondary};
      }

      50% {
        background-color: ${baseTheme.colors.error};
        color: ${baseTheme.colors.white};
      }

      100% {
        background-color: ${baseTheme.colors.error};
        color: ${baseTheme.colors.white};
      }
    }
  }

  .item-new {
    animation-duration: 2s;
    animation-name: slidein-new;
    animation-iteration-count: infinite;

    @keyframes slidein-new {
      0% {
        background-color: ${baseTheme.colors.white};
        color: ${baseTheme.colors.secondary};
      }

      50% {
        background-color: ${baseTheme.colors.focus};
        color: ${baseTheme.colors.white};
      }

      100% {
        background-color: ${baseTheme.colors.focus};
        color: ${baseTheme.colors.white};
      }
    }
  }

  .item-yellow {
    animation-duration: 1s;
    animation-name: slidein-yellow;
    animation-iteration-count: infinite;

    @keyframes slidein-yellow {
      0% {
        background-color: ${baseTheme.colors.warning};
        color: ${baseTheme.colors};
      }

      50% {
        background-color: ${baseTheme.colors.white};
        color: ${baseTheme.colors.secondary};
      }

      100% {
        background-color: ${baseTheme.colors.warning};
        color: ${baseTheme.colors.white};
      }
    }
  }

  .tab-item {
    padding: 12px;
    border-radius: 4px 4px 0 0;
    border: 1px solid ${baseTheme.colors.stroking};
    color: ${baseTheme.colors.secondary};
    margin-right: 4px;
    width: 33%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    @media only screen and (max-width: 550px) {
      width: 48%;
      margin-bottom: 10px;
    }
  }

  .type-active {
    background-color: ${baseTheme.colors.mainLightBlue};
    color: ${baseTheme.colors.white};
  }
`;

export const InfoByDay = styled('div')`
  padding-top: 36px;
  margin-bottom: -20px;

  @media only screen and (max-width: 550px) {
    padding-top: 16px;
  }
`;

export const PharmacistContainer = styled('div')`
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;

  .error-message {
    padding: 10px;
    border-radius: 5px;
    background-color: #e9e9e9;
    font-weight: 700;
    font-size: 20px;
    color: #eb5757;
    margin-right: 50px;
  }

  img {
    width: 45px;
    height: 45px;
    cursor: pointer;
  }

  .pharmacist-tooltip {
    max-width: 500px;
    word-break: break-word;
    background-color: #f6f9ff;
    filter: drop-shadow(0px 1px 8px rgba(0, 58, 136, 0.15));
    border-radius: 4px;
    color: ${baseTheme.colors.secondary};
    font-size: 16px;
    font-weight: 400;
    padding: 8px 16px;
    position: absolute;
    top: 55px;
  }

  @media only screen and (max-width: 550px) {
    padding: 0 16px;
    justify-content: flex-start;

    .pharmacist-tooltip {
      z-index: 10;
      padding: 16px;
    }
  }
`;

export const PharmacistButton = styled('div')`
  max-width: 300px;
`;

export const NumberCallCenter = styled('div')`
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  background-color: #1fa069;
  color: #fff;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;

  @media only screen and (max-width: 550px) {
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 0;
  }
`;

export const ReportByDaysContainer = styled('div')`
  background-color: white;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media only screen and (max-width: 550px) {
    padding: 0 16px;
    display: flex;
    flex-direction: column;
  }
`;

export const OfflineOrdersButton = styled('div')`
  position: sticky;
  top: 0px;
  z-index: 100;
  width: 100%;
  background-color: #fff;
  padding: 5px;

  .button-offline {
    cursor: pointer;
    background: rgb(31, 160, 105);
    margin: 0 auto;
    margin-top: 10px;
    padding: 4px 10px;
    font-weight: 700;
    color: #ffffff;
    font-size: 16px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    border-radius: 4px;
    max-width: 170px;
    animation-duration: 1s;
    animation-name: slidein-yellow;
    animation-iteration-count: infinite;

    @keyframes slidein-yellow {
      0% {
        background-color: ${baseTheme.colors.error};
        color: ${baseTheme.colors};
      }

      50% {
        background-color: ${baseTheme.colors.white};
        color: ${baseTheme.colors.secondary};
      }

      100% {
        background-color: ${baseTheme.colors.error};
        color: ${baseTheme.colors.white};
      }
    }
  }
`;
