import axios from 'axios';
import { Limits, WidthdrawPharmacistBonusesData } from './types';

export class PromoCodeService {
  static API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/api/v2/`;

  static getTemplates = async () => {
    const response = await axios({
      method: 'get',
      url: `${PromoCodeService.API_BASE_URL}admin/promocodes/templates`,
    });
    return response.data;
  };

  static createTemplates = async (
    code: string,
    description: string,
    discount: { target: string; type: string; value: number },
    limits: Limits,
    duration: number | null
  ) => {
    const response = await axios({
      method: 'post',
      url: `${PromoCodeService.API_BASE_URL}admin/promocodes/templates`,
      data: {
        code,
        description,
        discount,
        duration,
        max_usages_total: limits.maxUsagesTotal,
        max_usages_user: limits.maxUsagesUser,
        max_user_orders: limits.maxUserOrders,
        min_order_price: limits.minOrderPrice,
      },
    });
    return response.data;
  };

  static getPromoCodes = async () => {
    const response = await axios({
      method: 'get',
      url: `${PromoCodeService.API_BASE_URL}admin/promocodes/promocodes`,
    });
    return response.data;
  };

  static createPromoCodes = async (
    code: string | null,
    description: string | null,
    templateId: number,
    user: string | null,
    validDuration: number | null,
    validFrom: string | null,
    validUntil: string | null
  ) => {
    const response = await axios({
      method: 'post',
      url: `${PromoCodeService.API_BASE_URL}admin/promocodes/promocodes`,
      data: {
        code,
        description,
        template_id: templateId,
        user,
        valid_duration: validDuration,
        valid_from: validFrom,
        valid_until: validUntil,
      },
    });
    return response.data;
  };

  static activatePromoCodes = async (code: string, enabled: boolean) => {
    const response = await axios({
      method: 'post',
      url: `${PromoCodeService.API_BASE_URL}admin/promocodes/promocodes/${code}/enabled`,
      data: {
        enabled,
      },
    });

    return response.data;
  };

  static getBonusOrdersDetails = async (
    phoneNumber: string,
    isAdmin?: boolean
  ) => {
    const response = await axios({
      method: 'get',
      url: `${PromoCodeService.API_BASE_URL}${
        isAdmin ? 'admin/' : ''
      }promocodes/bonus/orders${
        phoneNumber ? `?pharmacist_id=${phoneNumber}` : ''
      }`,
    });
    return response.data;
  };

  static getBonusOrdersDetailsHistory = async (
    phoneNumber?: string,
    isAdmin?: boolean
  ) => {
    const response = await axios({
      method: 'get',
      url: `${PromoCodeService.API_BASE_URL}${
        isAdmin ? 'admin/' : ''
      }promocodes/bonus/history${
        phoneNumber ? `?pharmacist_id=${phoneNumber}` : ''
      }`,
    });

    return response.data;
  };

  static getPharmacistIndividualExistingPromoCode = async () => {
    const response = await axios({
      method: 'get',

      url: `${PromoCodeService.API_BASE_URL}promocodes/pharmacist`,
    });
    return response.data;
  };

  static getNewPharmacistPromoCode = async (pharmacistPhoneNumber: string) => {
    const response = await axios({
      method: 'get',
      url: `${PromoCodeService.API_BASE_URL}admin/promocodes/pharmacist?pharmacist_id=${pharmacistPhoneNumber}`,
    });
    return response.data;
  };

  static getPharmacistBonusesBalance = async (
    phoneNumber?: string,
    isAdmin?: false
  ) => {
    const response = await axios({
      method: 'get',
      url: `${PromoCodeService.API_BASE_URL}${
        isAdmin ? 'admin/' : ''
      }promocodes/bonus/balance${
        phoneNumber ? `?pharmacist_id=${phoneNumber}` : ''
      }`,
    });

    return response.data;
  };

  static withdrawPharmacistBonuses = async (
    data: WidthdrawPharmacistBonusesData
  ) => {
    const response = await axios({
      method: 'post',
      data,
      url: `${PromoCodeService.API_BASE_URL}admin/promocodes/bonus/withdraw`,
    });

    return response.data;
  };

  static promocodePharmacistBlocked = async (
    promocode: string,
    data: {
      blocked: boolean;
    }
  ) => {
    const response = await axios({
      method: 'post',
      data,
      url: `${PromoCodeService.API_BASE_URL}admin/promocodes/${promocode}/blocked`,
    });

    return response.data;
  };

  static getPromocodeBonusPharmacistBlocked = async (
    pharmacistPhoneNumber: string
  ) => {
    const response = await axios({
      method: 'get',
      url: `${PromoCodeService.API_BASE_URL}admin/promocodes/bonus/blocked?pharmacist_id=${pharmacistPhoneNumber}`,
    });

    return response.data;
  };

  static getPromocodeStatusByCode = async (code: string) => {
    const response = await axios({
      method: 'get',
      url: `${PromoCodeService.API_BASE_URL}admin/promocodes/${code}`,
    });

    return response.data;
  };
}
