export const GET_PRODUCTS_SEARCH_FOR_PHARMACY =
  'GET_PRODUCTS_SEARCH_FOR_PHARMACY';

export const GET_PRODUCT_BY_SKU = 'GET_PRODUCT_BY_SKU';

export const SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT_SERVICE';
export const CLEAR_SELECTED_PRODUCT = 'CLEAR_SELECTED_PRODUCT';

export const DATA_FOR_MAPPINGS = 'DATA_FOR_MAPPINGS';

export const BLOCKED_PRODUCT = 'BLOCKED_PRODUCT';
export const UNBLOCKED_PRODUCT = 'UNBLOCKED_PRODUCT';

export const DELETE_PRODUCTS_MAPPING = 'DELETE_PRODUCTS_MAPPING';
