import styled from 'styled-components';
import { baseTheme } from '../../global/styles/theme';

export const OrderStatusBlock = styled('div')`
  padding: 4px 10px;
  font-weight: 700;
  color: ${baseTheme.colors.white};
  font-size: 16px;
  width: max-content;
  border-radius: 4px;
  max-width: 170px;

  &.completed {
    background-color: ${baseTheme.colors.focus};
  }

  &.collecting {
    background-color: ${baseTheme.colors.mainLightBlue};
  }

  &.unconfirmed {
    background-color: ${baseTheme.colors.focus};
  }

  &.canceled {
    background-color: ${baseTheme.colors.tertiary};
  }

  &.ready {
    background-color: ${baseTheme.colors.warning};
  }

  &.clarification {
    background-color: ${baseTheme.colors.warning};
  }

  &.item-overdue {
    animation-duration: 2s;
    animation-name: slidein;
    animation-iteration-count: infinite;

    @keyframes slidein {
      0% {
        background-color: ${baseTheme.colors.white};
        color: ${baseTheme.colors.secondary};
      }

      50% {
        background-color: ${baseTheme.colors.error};
        color: ${baseTheme.colors.white};
      }

      100% {
        background-color: ${baseTheme.colors.error};
        color: ${baseTheme.colors.white};
      }
    }
  }
`;
