import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikErrors,
  FormikProps,
} from 'formik';
import { isIPv4 } from 'is-ip';
import React, { ChangeEvent, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CustomTextarea from '../../../../components/CustomTextarea';
import CustomToggle from '../../../../components/CustomToggle';
import ModalEditHours from '../../../../components/ModalEditHours';
import Notification from '../../../../components/NotificationsList/components/Notification';
import { getTrimmedFields, isCodeValid } from '../../../../global/helpers';
import copyIcon from '../../../../global/media/copy-icon.png';
import editIcon from '../../../../global/media/edit-source.svg';
import * as actionsSources from '../../../SourcesPage/actions';
import * as actions from '../../actions';
import {
  DEFAULT_SOURCE_STATE,
  selectBoolean,
  selectCity,
  selectPO,
} from '../../constants';
import { formatData, isDeviceValid, isTokenValid } from '../../helpers';
import { ScheduleType, SourceDataOnSourcePage, SourceProps } from '../../types';
import SelectOptions from './components/SelectOptions';
import * as Styled from './SourceComponent.styles';

interface SourceParams {
  data?: SourceDataOnSourcePage;
  empty?: boolean;
  name?: string;
  networkCode?: string;
}

const SourceComponent = ({ data, empty, name, networkCode }: SourceParams) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [editActive, setEditActive] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hoursEmptyField, setHoursEmptyField] = useState(false);
  const [schedule, setSchedule] = useState<ScheduleType>(
    data ? data.schedule : []
  );
  const [paymentMethod, setPaymentMethod] = useState<boolean>(
    data?.payment ? data?.payment : false
  );
  const [syncFtp, setSyncFtp] = useState<boolean | string>(
    data?.syncFtp ? data?.syncFtp : false
  );
  const [rezerv, setRezerv] = useState<boolean>(
    data?.rezerv ? data?.rezerv : false
  );
  const [po, setPO] = useState<string>(data?.soft || '');
  const [to1c, setTo1c] = useState<boolean>(
    data?.revaluation ? data?.revaluation : false
  );
  const [sourceName, setSourceName] = useState<string>(data ? data?.name : '');
  const [cityName, setCityName] = useState<string>(data ? data?.city : '');
  const [paymentSelectOpen, setPaymentSelectOpen] = useState<boolean>(false);
  const [poSelectOpen, setPoSelectOpen] = useState<boolean>(false);
  const [rezervSelectOpen, setRezervSelectOpen] = useState<boolean>(false);
  const [to1CSelectOpen, setTo1CSelectOpen] = useState<boolean>(false);
  const [citySelectOpen, setCitySelectOpen] = useState<boolean>(false);
  const [syncFtpOpen, setSyncFtpOpen] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(data?.activated || false);
  const [visible, setVisible] = useState<boolean>(data?.visible || false);
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const [isBannerVisible, setIsBannerVisible] = useState<boolean>(false);
  const formikRef = useRef(null) as any;
  const [comments, setComments] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [deletePharmacyCode, setDeletePharmacyCode] = useState<string>('');
  const [isDeletePharmacy, setIsDeletePharmacy] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<boolean>(false);
  const [isChangeSync, setIsChangeSync] = useState<boolean>(false);

  useEffect(() => {
    if (checked === data?.activated && visible === data?.visible) {
      setDisableButton(true);
    } else setDisableButton(false);
  }, [checked, visible, data]);

  useEffect(() => {
    data && data && setVisible(data.visible);
  }, [data, data?.visible]);

  const deletePharmacy = () => {
    if (deletePharmacyCode !== 'zx8tr') {
      setErrorCode(true);
    }
    if (data && deletePharmacyCode === 'zx8tr') {
      dispatch(
        actionsSources.deletePharmacy.request({
          sourceCode: data.code,
          postEffect: () =>
            dispatch(dispatch(actionsSources.getSources.request())),
        })
      );
      history.push('/sources');
    }
  };

  const changeSync = () => {
    if (deletePharmacyCode !== 'zx8tr') {
      setErrorCode(true);
    }
    if (data && deletePharmacyCode === 'zx8tr') {
      if (data.enabledSync) {
        dispatch(
          actionsSources.pharmacyDeactivateSync.request({
            sourceCode: data.code,
          })
        );
        dispatch(
          actionsSources.setInvisibleSource.request({
            sourceCode: data.code,
            postEffect: () =>
              dispatch(actions.getSource.request({ sourceCode: data.code })),
          })
        );
      } else {
        dispatch(
          actionsSources.pharmacyActivateSync.request({
            sourceCode: data.code,
          })
        );
        dispatch(
          actionsSources.setVisibleSource.request({
            sourceCode: data.code,
            postEffect: () =>
              dispatch(actions.getSource.request({ sourceCode: data.code })),
          })
        );
      }
    }
  };

  useEffect(() => {
    deletePharmacyCode && setErrorCode(false);
  }, [deletePharmacyCode]);

  useEffect(() => {
    error && setError(false);
  }, [comments]);

  useEffect(() => {
    if (hoursEmptyField) {
      formikRef.current.setFieldValue('openingHours', 'данные внесены');
    }
  }, [hoursEmptyField]);

  useEffect(() => {
    if (data) {
      formikRef.current.setFieldValue('openingHours', data.openingHours);
      formikRef.current.setFieldValue(
        'hoursOfActivateDeactivate',
        data.disableUpdatedAt
      );
      formikRef.current.setFieldValue('address', data.address);
      formikRef.current.setFieldValue('internalAddress', data.internalAddress);
      formikRef.current.setFieldValue('internalCode', data.internalCode);
      formikRef.current.setFieldValue('lat', data.lat);
      formikRef.current.setFieldValue('lon', data.lon);
      formikRef.current.setFieldValue('sourceCode', data.code);
      formikRef.current.setFieldValue('mappingCode', data.mappingCode);
      formikRef.current.setFieldValue('PO', data.soft);
      formikRef.current.setFieldValue(
        'paymentMethod',
        data.payment ? 'Да' : 'Нет'
      );
      formikRef.current.setFieldValue('syncFtp', data.syncFtp ? 'Да' : 'Нет');
      formikRef.current.setFieldValue('hoursOfSynchronize', data.lastSync);
      formikRef.current.setFieldValue('sales', data.sales);
      formikRef.current.setFieldValue('commision', data.commisiom);
      formikRef.current.setFieldValue('rezerv', data.rezerv ? 'Да' : 'Нет');
      formikRef.current.setFieldValue('to1C', data.revaluation ? 'Да' : 'Нет');
      formikRef.current.setFieldValue('device', data?.connection?.device);
      formikRef.current.setFieldValue('token', data?.connection?.token);
      formikRef.current.setFieldValue('db_dsn', data?.connection?.db_dsn);
      formikRef.current.setFieldValue(
        'ip_address',
        data?.connection?.ip_address
      );
      setSourceName(data.name);
    }
  }, [data]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setComments(e.target.value);
  };

  const handleEnterCode = (e: ChangeEvent<HTMLInputElement>) => {
    setDeletePharmacyCode(e.target.value);
  };

  const handleSubmitForm = (values: any) => {
    if (data) {
      dispatch(
        actions.postSource.request({
          sourceInfo: formatData(
            values,
            data,
            sourceName,
            networkCode,
            paymentMethod,
            rezerv,
            po,
            to1c,
            schedule,
            empty
          ),
          postEffect: () => {
            setEditActive(false);
            dispatch(actions.changeEditSource(false));
            dispatch(
              actions.getSource.request({
                sourceCode: getTrimmedFields(data.code),
              })
            );
          },
        })
      );
    } else {
      dispatch(
        actions.postSource.request({
          sourceInfo: formatData(
            values,
            data,
            name,
            networkCode,
            paymentMethod,
            rezerv,
            po,
            to1c,
            schedule,
            empty
          ),
          postEffect: () => {
            setEditActive(false);
            dispatch(actions.changeEditSource(false));
            history.push(`/sources/${getTrimmedFields(values.sourceCode)}`);
            dispatch(
              actions.getSource.request({
                sourceCode: getTrimmedFields(values.sourceCode),
              })
            );
          },
        })
      );
    }
  };

  const handleChangeActivationProducts = () => {
    if (data) {
      if (checked !== data?.activated) {
        if (checked) {
          dispatch(
            actionsSources.activateSource.request({
              sourceCode: data.code,
              postEffect: () =>
                dispatch(actions.getSource.request({ sourceCode: data.code })),
            })
          );
        } else if (comments.trim() === '') {
          setError(true);
        } else {
          dispatch(
            actionsSources.deactivateSource.request({
              sourceCode: data.code,
              message: comments.trim(),
              postEffect: () =>
                dispatch(actions.getSource.request({ sourceCode: data.code })),
            })
          );
        }
      }

      if (visible !== data?.visible) {
        if (visible) {
          dispatch(
            actionsSources.setVisibleSource.request({
              sourceCode: data.code,
              postEffect: () =>
                dispatch(actions.getSource.request({ sourceCode: data.code })),
            })
          );
        } else {
          dispatch(
            actionsSources.setInvisibleSource.request({
              sourceCode: data.code,
              postEffect: () =>
                dispatch(actions.getSource.request({ sourceCode: data.code })),
            })
          );
        }
      }
    }
  };

  const handleChange = (
    props: FormikProps<SourceProps>,
    event: any,
    inputName: string
  ) => {
    if (
      inputName === 'PO' ||
      inputName === 'paymentMethod' ||
      inputName === 'rezerv' ||
      inputName === 'to1C' ||
      inputName === 'city' ||
      inputName === 'syncFtp'
    ) {
      props.setFieldValue(inputName, event.title);
    } else props.setFieldValue(inputName, event.target.value);
  };

  const validateForm = (values: SourceProps) => {
    const errors: FormikErrors<SourceProps> = {};
    if (!values.address) {
      errors.address = t('SourcePage.INPUTS_ERROR.ADDRESS');
    }

    if (
      (!values.internalCode || !isDeviceValid(values.internalCode)) &&
      po === 'INFO_APTEKA'
    ) {
      errors.internalCode = t('SourcePage.INPUTS_ERROR.INTERNAL_CODE');
    }

    if (!values.internalCode && po === 'OASIS') {
      errors.internalCode = t('SourcePage.INPUTS_ERROR.INTERNAL_ADDRESS');
    }

    if (!values.openingHours) {
      errors.openingHours = t('SourcePage.INPUTS_ERROR.OPENING_HOURS');
    }

    if (!values.paymentMethod) {
      errors.paymentMethod = t('SourcePage.INPUTS_ERROR.PAYMENT');
    }

    if (!values.to1C && (po === '1C' || po === 'OASIS' || po === 'INFO_PLUS')) {
      errors.to1C = t('SourcePage.INPUTS_ERROR.TO_1_C');
    }

    if (!values.sourceCode || !isCodeValid(values.sourceCode)) {
      errors.sourceCode = t('SourcePage.INPUTS_ERROR.SOURCE_CODE');
    }

    if (!values.syncFtp && po === 'INFO_APTEKA') {
      errors.syncFtp = t('SourcePage.INPUTS_ERROR.SYNC_FTP');
    }

    if (!values.PO) {
      errors.PO = t('SourcePage.INPUTS_ERROR.PO');
    }

    if (!values.sales && values.sales !== 0) {
      errors.sales = t('SourcePage.INPUTS_ERROR.SALES');
    }

    if (
      !values.rezerv &&
      (po.toLocaleUpperCase() === 'STANDART' ||
        po.toLocaleUpperCase() === 'STANDART_NO_DISCOUNT')
    ) {
      errors.rezerv = t('SourcePage.INPUTS_ERROR.REZERV');
    }

    if (
      !values.db_dsn &&
      !data?.connection?.db_dsn &&
      (po.toLocaleUpperCase() === 'STANDART' ||
        po.toLocaleUpperCase() === 'STANDART_NO_DISCOUNT')
    ) {
      errors.db_dsn = t('SourcePage.INPUTS_ERROR.DSN');
    }

    if (
      ((!values.ip_address && !data?.connection?.ip_address) ||
        (values.ip_address && !isIPv4(values.ip_address))) &&
      (po.toLocaleUpperCase() === 'STANDART' ||
        po.toLocaleUpperCase() === 'STANDART_NO_DISCOUNT')
    ) {
      errors.ip_address = t('SourcePage.INPUTS_ERROR.IP');
    }

    if (
      ((!values.token && !data?.connection?.token) ||
        !isTokenValid(values.token)) &&
      po === 'RUBUS'
    ) {
      errors.token = t('SourcePage.INPUTS_ERROR.TOKEN');
    }

    if (
      ((!values.device && !data?.connection?.device) ||
        !isDeviceValid(values.device)) &&
      po === 'RUBUS'
    ) {
      errors.device = t('SourcePage.INPUTS_ERROR.DEVICE');
    }

    if (!values.lat) {
      errors.lat = t('SourcePage.INPUTS_ERROR.LAT');
    }

    if (!values.lon) {
      errors.lon = t('SourcePage.INPUTS_ERROR.LON');
    }

    if (!values.mappingCode || !isCodeValid(values.mappingCode)) {
      errors.mappingCode = t('SourcePage.INPUTS_ERROR.MAPPING_CODE');
    }

    if (!values.commision && values.commision !== 0) {
      errors.commision = t('SourcePage.INPUTS_ERROR.COMMISION');
    }

    return errors;
  };

  const sourceData = data
    ? {
        city: data.city,
        openingHours: data.openingHours,
        address: data.address,
        internalAddress: data.internalAddress,
        lat: data.lat,
        lon: data.lon,
        sourceCode: data.code,
        mappingCode: data.mappingCode,
        hoursOfActivateDeactivate: data.disableUpdatedAt,
        PO: data.soft,
        hoursOfSynchronize: data.lastSync,
        paymentMethod: data.payment ? 'Да' : 'Нет',
        sales: data.sales,
        commision: data.commisiom,
        rezerv: data.rezerv ? 'Да' : 'Нет',
        to1C: data.revaluation ? 'Да' : 'Нет',
        device: '',
        token: '',
        ip_address: '',
        db_dsn: '',
        internalCode: data.internalCode,
        syncFtp: data.syncFtp ? 'Да' : 'Нет',
      }
    : DEFAULT_SOURCE_STATE;

  const handleChangeEdit = () => {
    setEditActive(!editActive);
    dispatch(actions.changeEditSource(!editActive));
  };

  const handleCopyText = (text: string) => {
    navigator.clipboard.writeText(text);
    setIsBannerVisible(true);
  };

  return (
    <>
      {isModalOpen && (
        <ModalEditHours
          setHoursEmptyField={setHoursEmptyField}
          scheduleData={data ? data.schedule : []}
          setSchedule={setSchedule}
          schedule={schedule}
          setIsModalOpen={setIsModalOpen}
        />
      )}
      <Styled.SourceMainBlock>
        <div className="header">
          {!empty && (
            <>
              {editActive ? (
                <div className="textarea">
                  <CustomTextarea
                    onChange={(e) => setSourceName(e)}
                    value={sourceName}
                  />
                  {data?.token && (
                    <div className="token">
                      {' '}
                      {t('SourcePage.TOKEN')} <span>{data.token}</span>
                    </div>
                  )}
                </div>
              ) : (
                <div className="name">
                  {data?.name}
                  {data?.token && (
                    <div className="token">
                      {t('SourcePage.TOKEN')}
                      <div>{data.token}</div>{' '}
                      <div onClick={() => handleCopyText(data.token)}>
                        {' '}
                        <img src={copyIcon} alt="copy icon" />
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className="right-block">
                <div className="icons-block">
                  <div onClick={handleChangeEdit}>
                    <img src={editIcon} alt="edit" />
                  </div>
                  {data && (
                    <div className="flex-container">
                      <div className="toggle-block">
                        <CustomToggle
                          onChange={(e) => setChecked(e)}
                          id="activate"
                          checked={checked}
                          variant="standart"
                        />
                        <div className="disableUpdatedAt">
                          {data?.disableUpdatedAt}
                        </div>
                      </div>
                      <div className="toggle-block-visible ">
                        <CustomToggle
                          onChange={() => {
                            setVisible(!visible);
                          }}
                          id={data.address}
                          checked={visible}
                          variant="standart"
                        />
                        <div className="disableUpdatedAt">
                          {visible
                            ? t('SourcesPage.VISIBLE')
                            : t('SourcesPage.INVISIBLE')}{' '}
                        </div>
                      </div>
                      {!disableButton && !checked && (
                        <input
                          placeholder={t('SourcesPage.YOUR_COMMENT')}
                          value={comments}
                          onChange={handleInputChange}
                          type="text"
                          className={
                            error ? 'input-comments-error' : 'input-comments'
                          }
                          maxLength={30}
                        />
                      )}
                      {disableButton && !checked && data.comments && (
                        <p className="comment">{data.comments}</p>
                      )}
                      {error && (
                        <p className="comment-error">Введите комментарий</p>
                      )}
                    </div>
                  )}
                </div>
                {data && (
                  <div
                    className={` save-btn  ${disableButton && 'disable'}`}
                    onClick={handleChangeActivationProducts}
                  >
                    Сохранить
                  </div>
                )}
              </div>
            </>
          )}
        </div>

        <Styled.SourceInfo>
          <Formik
            onSubmit={handleSubmitForm}
            validate={validateForm}
            innerRef={formikRef}
            initialValues={empty ? DEFAULT_SOURCE_STATE : sourceData}
          >
            {(props: FormikProps<any>) => (
              <Form className="form-container">
                <div />
                <div>
                  <div
                    className="form-input input-relative"
                    onClick={() => setCitySelectOpen(!!(editActive || empty))}
                  >
                    <label htmlFor="city" className="label">
                      {t('SourcePage.INPUTS_LABEL.CITY')}
                    </label>
                    <Field
                      id="city"
                      name="city"
                      type="text"
                      disabled={!editActive && !empty}
                      readOnly
                      className="input"
                      onChange={(e: any) => {
                        handleChange(props, e, 'city');
                      }}
                    />
                    {citySelectOpen && (
                      <SelectOptions
                        data={selectCity}
                        onSetCode={(el) => {
                          handleChange(props, el, 'city');
                          setCityName(el.code as string);
                        }}
                        onClose={() => setCitySelectOpen(false)}
                      />
                    )}{' '}
                  </div>
                  <div className="form-input">
                    <label htmlFor="openingHours" className="label">
                      {t('SourcePage.INPUTS_LABEL.OPENING_HOURS')}{' '}
                      {(editActive ||
                        history.location.pathname === '/create') && (
                        <span
                          className="text-change"
                          onClick={() => {
                            setIsModalOpen(true);
                          }}
                        >
                          {t('SourcePage.INPUTS_LABEL.CHANGE')}
                        </span>
                      )}
                    </label>
                    <Field
                      id="openingHours"
                      name="openingHours"
                      type="text"
                      disabled
                      className="input"
                      onChange={(e: any) => {
                        handleChange(props, e, 'openingHours');
                      }}
                    />
                    <ErrorMessage
                      name="openingHours"
                      render={(msg: string) => (
                        <div className="error-message-label">{msg}</div>
                      )}
                    />
                  </div>
                  <div className="form-input">
                    <label
                      htmlFor="hoursOfActivateDeactivate"
                      className="label"
                    >
                      {t('SourcePage.INPUTS_LABEL.HOUR_OF_ACT_DEACT')}
                    </label>
                    <Field
                      id="hoursOfActivateDeactivate"
                      name="hoursOfActivateDeactivate"
                      type="text"
                      disabled
                      className="input"
                      onChange={(e: any) => {
                        handleChange(props, e, 'hoursOfActivateDeactivate');
                      }}
                    />
                  </div>

                  {po === 'INFO_APTEKA' && (
                    <div
                      className="form-input input-relative"
                      onClick={() => setSyncFtpOpen(!!(editActive || empty))}
                    >
                      <label htmlFor="syncFtp" className="label">
                        {t('SourcePage.INPUTS_LABEL.SYNC_FTP')}
                      </label>
                      <Field
                        id="syncFtp"
                        name="syncFtp"
                        className="input"
                        disabled={!editActive && !empty}
                        readOnly
                      />
                      <ErrorMessage
                        name="syncFtp"
                        render={(msg: string) => (
                          <div className="error-message-label">{msg}</div>
                        )}
                      />
                      {syncFtpOpen && (
                        <SelectOptions
                          data={selectBoolean}
                          onSetCode={(el) => {
                            handleChange(props, el, 'syncFtp');
                            setSyncFtp(el.code as boolean);
                          }}
                          onClose={() => setSyncFtpOpen(false)}
                        />
                      )}
                    </div>
                  )}
                  <div
                    className="form-input input-relative"
                    onClick={() =>
                      setPaymentSelectOpen(!!(editActive || empty))
                    }
                  >
                    <label htmlFor="paymentMethod" className="label">
                      {t('SourcePage.INPUTS_LABEL.PAYMENT')}
                    </label>
                    <Field
                      id="paymentMethod"
                      name="paymentMethod"
                      disabled={!editActive && !empty}
                      className="input"
                      readOnly
                    />
                    <ErrorMessage
                      name="paymentMethod"
                      render={(msg: string) => (
                        <div className="error-message-label">{msg}</div>
                      )}
                    />
                    {paymentSelectOpen && (
                      <SelectOptions
                        data={selectBoolean}
                        onSetCode={(el) => {
                          handleChange(props, el, 'paymentMethod');
                          setPaymentMethod(el.code as boolean);
                        }}
                        onClose={() => setPaymentSelectOpen(false)}
                      />
                    )}{' '}
                  </div>

                  {(po === '1C' || po === 'OASIS' || po === 'INFO_PLUS') && (
                    <div
                      className="form-input input-relative"
                      onClick={() => setTo1CSelectOpen(!!(editActive || empty))}
                    >
                      <label htmlFor="to1C" className="label">
                        {t('SourcePage.INPUTS_LABEL.TO_1_C')}
                      </label>
                      <Field
                        id="to1C"
                        name="to1C"
                        type="text"
                        disabled={!editActive && !empty}
                        className="input"
                        readOnly
                      />
                      <ErrorMessage
                        name="to1C"
                        render={(msg: string) => (
                          <div className="error-message-label">{msg}</div>
                        )}
                      />
                      {to1CSelectOpen && (
                        <SelectOptions
                          data={selectBoolean}
                          onSetCode={(el) => {
                            handleChange(props, el, 'to1C');
                            setTo1c(el.code as boolean);
                          }}
                          onClose={() => setTo1CSelectOpen(false)}
                        />
                      )}{' '}
                    </div>
                  )}
                </div>
                <div>
                  <div className="form-input">
                    <label htmlFor="address" className="label">
                      {t('SourcePage.INPUTS_LABEL.ADDRESS')}
                    </label>
                    <Field
                      id="address"
                      name="address"
                      type="text"
                      disabled={!editActive && !empty}
                      className="input"
                      onChange={(e: any) => {
                        handleChange(props, e, 'address');
                      }}
                    />
                    <ErrorMessage
                      name="address"
                      render={(msg: string) => (
                        <div className="error-message-label">{msg}</div>
                      )}
                    />
                  </div>
                  {po.toLocaleUpperCase() === 'OASIS' && (
                    <div className="form-input">
                      <label htmlFor="internalCode" className="label">
                        {t('SourcePage.INPUTS_LABEL.INTERNAL_ADDRESS')}
                      </label>
                      <Field
                        id="internalCode"
                        name="internalCode"
                        type="text"
                        disabled={!editActive && !empty}
                        className="input"
                        onChange={(e: any) => {
                          handleChange(props, e, 'internalCode');
                        }}
                      />
                      <ErrorMessage
                        name="internalCode"
                        render={(msg: string) => (
                          <div className="error-message-label">{msg}</div>
                        )}
                      />
                    </div>
                  )}
                  {po.toLocaleUpperCase() === 'INFO_APTEKA' && (
                    <div className="form-input">
                      <label htmlFor="internalCode" className="label">
                        {t('SourcePage.INPUTS_LABEL.INTERNAL_CODE')}
                      </label>
                      <Field
                        id="internalCode"
                        name="internalCode"
                        type="text"
                        disabled={!editActive && !empty}
                        className="input"
                        onChange={(e: any) => {
                          handleChange(props, e, 'internalCode');
                        }}
                      />
                      <ErrorMessage
                        name="internalCode"
                        render={(msg: string) => (
                          <div className="error-message-label">{msg}</div>
                        )}
                      />
                    </div>
                  )}
                  <div className="form-input">
                    <label htmlFor="sourceCode" className="label">
                      {t('SourcePage.INPUTS_LABEL.SOURCE_CODE')}
                    </label>
                    <Field
                      id="sourceCode"
                      name="sourceCode"
                      type="text"
                      disabled={!empty}
                      className="input"
                      onChange={(e: any) => {
                        handleChange(props, e, 'sourceCode');
                      }}
                    />
                    <ErrorMessage
                      name="sourceCode"
                      render={(msg: string) => (
                        <div className="error-message-label">{msg}</div>
                      )}
                    />
                  </div>
                  <div
                    className="form-input input-relative"
                    onClick={() => setPoSelectOpen(!!(editActive || empty))}
                  >
                    <label htmlFor="PO" className="label">
                      {t('SourcePage.INPUTS_LABEL.PO')}
                    </label>
                    <Field
                      id="PO"
                      name="PO"
                      disabled={!editActive && !empty}
                      className="input"
                      readOnly
                    />
                    <ErrorMessage
                      name="PO"
                      render={(msg: string) => (
                        <div className="error-message-label">{msg}</div>
                      )}
                    />
                    {poSelectOpen && (
                      <SelectOptions
                        data={selectPO}
                        onSetCode={(el) => {
                          handleChange(props, el, 'PO');
                          setPO(el.code as string);
                        }}
                        onClose={() => setPoSelectOpen(false)}
                      />
                    )}{' '}
                  </div>

                  <div className="form-input">
                    <label htmlFor="sales" className="label">
                      {t('SourcePage.INPUTS_LABEL.SALES')}
                    </label>
                    <Field
                      id="sales"
                      name="sales"
                      type="number"
                      disabled={!editActive && !empty}
                      className="input"
                      onChange={(e: any) => {
                        handleChange(props, e, 'sales');
                      }}
                    />
                    <ErrorMessage
                      name="sales"
                      render={(msg: string) => (
                        <div className="error-message-label">{msg}</div>
                      )}
                    />
                  </div>
                  {(po === 'STANDART' || po === 'STANDART_NO_DISCOUNT') && (
                    <div
                      className="form-input input-relative"
                      onClick={() =>
                        setRezervSelectOpen(!!(editActive || empty))
                      }
                    >
                      <label htmlFor="rezerv" className="label">
                        {t('SourcePage.INPUTS_LABEL.REZERV')}
                      </label>
                      <Field
                        id="rezerv"
                        name="rezerv"
                        disabled={!editActive && !empty}
                        className="input"
                        readOnly
                      />
                      <ErrorMessage
                        name="rezerv"
                        render={(msg: string) => (
                          <div className="error-message-label">{msg}</div>
                        )}
                      />
                      {rezervSelectOpen && (
                        <SelectOptions
                          data={selectBoolean}
                          onSetCode={(el) => {
                            handleChange(props, el, 'rezerv');
                            setRezerv(el.code as boolean);
                          }}
                          onClose={() => setRezervSelectOpen(false)}
                        />
                      )}{' '}
                    </div>
                  )}
                  {(po.toLocaleUpperCase() === 'STANDART' ||
                    po.toLocaleUpperCase() === 'STANDART_NO_DISCOUNT') && (
                    <div className="form-input">
                      <label htmlFor="ip_address" className="label">
                        {t('SourcePage.INPUTS_LABEL.IP')}
                      </label>
                      <Field
                        id="ip_address"
                        name="ip_address"
                        type="string"
                        disabled={!editActive && !empty}
                        className="input"
                        onChange={(e: any) => {
                          handleChange(props, e, 'ip_address');
                        }}
                      />
                      <ErrorMessage
                        name="ip_address"
                        render={(msg: string) => (
                          <div className="error-message-label">{msg}</div>
                        )}
                      />
                    </div>
                  )}
                  {po.toLocaleUpperCase() === 'RUBUS' && (
                    <div className="form-input">
                      <label htmlFor="device" className="label">
                        {t('SourcePage.INPUTS_LABEL.DEVICE')}
                      </label>
                      <Field
                        id="device"
                        name="device"
                        type="string"
                        disabled={!editActive && !empty}
                        className="input"
                        onChange={(e: any) => {
                          handleChange(props, e, 'device');
                        }}
                      />
                      <ErrorMessage
                        name="device"
                        render={(msg: string) => (
                          <div className="error-message-label">{msg}</div>
                        )}
                      />
                    </div>
                  )}
                </div>
                <div>
                  <div className="form-input">
                    <label htmlFor="coordinates" className="label">
                      {t('SourcePage.INPUTS_LABEL.LON')}
                    </label>
                    <Field
                      id="lon"
                      name="lon"
                      type="number"
                      disabled={!editActive && !empty}
                      className="input"
                      onChange={(e: any) => {
                        handleChange(props, e, 'lon');
                      }}
                    />
                    <ErrorMessage
                      name="lon"
                      render={(msg: string) => (
                        <div className="error-message-label">{msg}</div>
                      )}
                    />
                  </div>
                  <div className="form-input">
                    <label htmlFor="coordinates" className="label">
                      {t('SourcePage.INPUTS_LABEL.LAT')}
                    </label>
                    <Field
                      id="lat"
                      name="lat"
                      type="number"
                      disabled={!editActive && !empty}
                      className="input"
                      onChange={(e: any) => {
                        handleChange(props, e, 'lat');
                      }}
                    />
                    <ErrorMessage
                      name="lat"
                      render={(msg: string) => (
                        <div className="error-message-label">{msg}</div>
                      )}
                    />
                  </div>

                  <div className="form-input">
                    <label htmlFor="mappingCode" className="label">
                      {t('SourcePage.INPUTS_LABEL.MAPPING_CODE')}
                    </label>
                    <Field
                      id="mappingCode"
                      name="mappingCode"
                      type="text"
                      disabled={!editActive && !empty}
                      className="input"
                      onChange={(e: any) => {
                        handleChange(props, e, 'mappingCode');
                      }}
                    />
                    <ErrorMessage
                      name="mappingCode"
                      render={(msg: string) => (
                        <div className="error-message-label">{msg}</div>
                      )}
                    />
                  </div>

                  <div className="form-input">
                    <label htmlFor="hoursOfSynchronize" className="label">
                      {t('SourcePage.INPUTS_LABEL.HOURS_OF_SYNCHRONYZE')}
                    </label>
                    <Field
                      id="hoursOfSynchronize"
                      name="hoursOfSynchronize"
                      type="text"
                      disabled
                      className="input"
                      onChange={(e: any) => {
                        handleChange(props, e, 'hoursOfSynchronize');
                      }}
                    />
                  </div>

                  <div className="form-input">
                    <label htmlFor="commision" className="label">
                      {t('SourcePage.INPUTS_LABEL.COMMISION')}
                    </label>
                    <Field
                      id="commision"
                      name="commision"
                      type="number"
                      disabled={!editActive && !empty}
                      className="input"
                      onChange={(e: any) => {
                        handleChange(props, e, 'commision');
                      }}
                    />
                    <ErrorMessage
                      name="commision"
                      render={(msg: string) => (
                        <div className="error-message-label">{msg}</div>
                      )}
                    />
                  </div>

                  {(po.toLocaleUpperCase() === 'STANDART' ||
                    po.toLocaleUpperCase() === 'STANDART_NO_DISCOUNT') && (
                    <div className="form-input">
                      <label htmlFor="db_dsn" className="label">
                        {t('SourcePage.INPUTS_LABEL.DSN')}
                      </label>
                      <Field
                        id="db_dsn"
                        name="db_dsn"
                        type="string"
                        disabled={!editActive && !empty}
                        className="input"
                        onChange={(e: any) => {
                          handleChange(props, e, 'db_dsn');
                        }}
                      />
                      <ErrorMessage
                        name="db_dsn"
                        render={(msg: string) => (
                          <div className="error-message-label">{msg}</div>
                        )}
                      />
                    </div>
                  )}
                  {po.toLocaleUpperCase() === 'RUBUS' && (
                    <div className="form-input">
                      <label htmlFor="token" className="label">
                        {t('SourcePage.INPUTS_LABEL.TOKEN')}
                      </label>
                      <Field
                        id="token"
                        name="token"
                        type="string"
                        disabled={!editActive && !empty}
                        className="input"
                        onChange={(e: any) => {
                          handleChange(props, e, 'token');
                        }}
                      />
                      <ErrorMessage
                        name="token"
                        render={(msg: string) => (
                          <div className="error-message-label">{msg}</div>
                        )}
                      />
                    </div>
                  )}
                </div>
                {(editActive || empty) && (
                  <button type="submit" className="button">
                    {t('SourcePage.SAVE')}
                  </button>
                )}
              </Form>
            )}
          </Formik>
        </Styled.SourceInfo>
      </Styled.SourceMainBlock>

      {!editActive && !empty && (
        <Styled.ContainerDelete>
          {errorCode && (
            <div className="error-message">{t('SourcesPage.WRONG_CODE')}</div>
          )}
          <div
            className={
              isChangeSync
                ? 'delete-pharmacy-container'
                : 'delete-pharmacy-container-sync'
            }
          >
            <div className="buttons-container">
              <div
                onClick={() => {
                  setIsChangeSync(true);
                  setIsDeletePharmacy(false);
                  deletePharmacyCode && changeSync();
                }}
                className="delete-pharmacy"
              >
                {data?.enabledSync
                  ? t('SourcesPage.SYNC_OFF')
                  : t('SourcesPage.SYNC_ON')}
              </div>
              <div
                onClick={() => {
                  setIsDeletePharmacy(true);
                  setIsChangeSync(false);
                  deletePharmacyCode && deletePharmacy();
                }}
                className="delete-pharmacy"
              >
                {t('SourcesPage.DELETE_PHARMACY')}
              </div>
            </div>
            {(isDeletePharmacy || isChangeSync) && (
              <input
                placeholder={t('SourcesPage.YOUR_CODE')}
                value={deletePharmacyCode}
                onChange={handleEnterCode}
                type="string"
                className={errorCode ? 'input-code-error' : 'input-code'}
                maxLength={5}
              />
            )}
          </div>

          {data && data.syncError && (
            <div className="container-error">
              {t('SourcesPage.ERROR')}
              <div className="error">{data.syncError}</div>
            </div>
          )}
        </Styled.ContainerDelete>
      )}
      {isBannerVisible && (
        <Styled.NotificationWrapper>
          <Notification
            onClose={() => setIsBannerVisible(false)}
            id={0}
            type="success"
            content={t('OrdersPage.COPY_SUCCESS')}
            canBeClosed
          />
        </Styled.NotificationWrapper>
      )}
    </>
  );
};

export default SourceComponent;
