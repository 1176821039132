import { ClickAwayListener } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Loader from '../../components/Loader';
import * as sourceActions from '../SourcePage/actions';
import TagsComponent from './components/TagsComponent';
import { selectors as sourceSelectors } from '../SourcePage/reducer';
import * as Styled from './PharmacyTagsPage.styles';
import { TagsTypeOptions } from './types';

const PharmacyTagsPage = () => {
  const dispatch = useDispatch();
  const [tagOptionsModalOpen, setTagOptionsModalOpen] = useState(false);
  const [selectedTagType, setSelectedTagType] = useState<string>(
    TagsTypeOptions.source
  );
  const tagsData = useSelector(sourceSelectors.sourceTagsData);
  const tagsDataLoading = useSelector(sourceSelectors.sourceTagsDataLoading);
  const { t } = useTranslation();

  const onTagOptionClickHandler = (value: string) => {
    setSelectedTagType(value);
    setTagOptionsModalOpen(false);
  };

  useEffect(() => {
    if (selectedTagType === TagsTypeOptions.network) {
      dispatch(
        sourceActions.getSourceTags.request({
          tagsType: TagsTypeOptions.network,
        })
      );
    } else {
      dispatch(
        sourceActions.getSourceTags.request({
          tagsType: TagsTypeOptions.source,
        })
      );
    }
  }, [selectedTagType]);

  return (
    <>
      {tagsDataLoading && <Loader />}
      <Styled.PharmacyTagsPageContainer>
        <p className="title">{t('PharmacyTagsPage.TITLE')}</p>
        <Styled.TagTypeSelect>
          <div
            className="selected-tag"
            onClick={() => setTagOptionsModalOpen(true)}
          >
            {selectedTagType}
          </div>
          {tagOptionsModalOpen && (
            <ClickAwayListener
              onClickAway={() => setTagOptionsModalOpen(false)}
            >
              <div className="tags-options">
                {Object.keys(TagsTypeOptions).map((item) => (
                  <div key={item} onClick={() => onTagOptionClickHandler(item)}>
                    {item}
                  </div>
                ))}
              </div>
            </ClickAwayListener>
          )}
        </Styled.TagTypeSelect>
        {tagsData && (
          <TagsComponent
            isAllTagsPage
            currentTags={tagsData}
            tagsType={selectedTagType}
            sourceTagsData={tagsData}
            code=""
          />
        )}
      </Styled.PharmacyTagsPageContainer>
    </>
  );
};

export default PharmacyTagsPage;
