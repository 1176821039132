import styled from 'styled-components';
import { baseTheme } from '../../../../global/styles/theme';

export const NumberCallCenter = styled('div')`
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  background-color: #1fa069;
  color: #fff;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: -25px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;

  @media only screen and (max-width: 550px) {
    padding: 10px 24px;
    border-radius: 0;
  }
`;

export const StatusPharmacyContainer = styled('div')`
  background-color: white;
  .title {
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    font-size: 24px;
    color: ${baseTheme.colors.mainBlue};

    .collecting-block {
      display: flex;

      span {
        padding-right: 10px;
        color: ${baseTheme.colors.mainLightBlue};
      }
    }

    .on_clarification {
      color: ${baseTheme.colors.warning};
    }

    .helper-block {
      padding: 5px;
      background-color: yellow;
      font-size: 16px;
      border-radius: 5px;
      cursor: pointer;
    }
  }

  .attention-block {
    display: flex;
    flex-wrap: wrap;
    color: ${baseTheme.colors.primary};
    font-size: 18px;

    div {
      margin-right: 4px;
    }

    .icon-wrapper {
      z-index: 50;
      margin: -20px 0 0 170px;
      cursor: pointer;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid ${baseTheme.colors.stroking};
    }

    img {
      height: 20px !important;
      width: auto;
      padding: 3px;
      margin-right: 1px;
    }

    .product-info-daribar {
      font-weight: bold;
      color: ${baseTheme.colors.focus} !important;
    }

    .product-info-source {
      font-weight: bold;
      color: ${baseTheme.colors.purple} !important;
    }
  }
  .attention-info {
    font-weight: 600;
  }
  .icon-wrapper2 {
    img {
      height: 15px;
    }
  }
  .attention-button {
    margin: 20px 0;
    width: fit-content;
  }

  .order-timer {
    font-size: 24px;
    font-weight: 700;
  }

  .timer-green {
    color: ${baseTheme.colors.focus};
  }

  .timer-orange {
    color: ${baseTheme.colors.warning};
  }

  .timer-red {
    color: ${baseTheme.colors.error};
  }

  .timer-grey {
    color: ${baseTheme.colors.disabled};
  }

  .collecting {
    color: ${baseTheme.colors.primary};
  }

  .ready {
    color: ${baseTheme.colors.mainLightBlue};
  }

  .subtitle {
    padding: 20px 0 16px 0;
    color: ${baseTheme.colors.secondary};
    font-weight: 500;
    font-size: 20px;

    span {
      color: ${baseTheme.colors.secondary} !important;
    }

    .on_clarification-block {
      position: relative;
      margin-bottom: -26px;

      img {
        position: absolute;
        margin-left: -25px;
      }
    }
  }

  .standart-block {
    color: ${baseTheme.colors.secondary};

    img {
      margin-top: -5px;
      padding-top: 6px;
    }

    .black {
      color: ${baseTheme.colors.primary} !important;
    }

    div {
      span {
        margin: 4px 0 0 8px;
        color: ${baseTheme.colors.mainLightBlue} !important;
      }
    }
  }

  .button-block {
    display: flex;

    .main-btn {
      display: flex;
      margin-bottom: 16px;
    }

    .assembly-btn {
      padding: 12px 24px;
      border-radius: 8px;
      background-color: ${baseTheme.colors.focus};
      cursor: pointer;
      margin-right: 24px;

      div {
        font-weight: 500;
        font-size: 18px;
        color: ${baseTheme.colors.white};
      }

      @media only screen and (max-width: 550px) {
        display: flex;
        justify-content: center;
        align-items: center;
        div {
          font-size: 14px;
          white-space: nowrap;
        }
      }
    }

    .recipe-btn {
      padding: 12px 24px;
      border-radius: 8px;
      background-color: ${baseTheme.colors.mainLightBlue};
      cursor: pointer;
      margin-right: 24px;
      position: relative;

      div {
        font-weight: 500;
        font-size: 18px;
        color: ${baseTheme.colors.white};
      }
    }

    .recipe-tooltip {
      max-width: 900px;
      word-break: break-word;
      background-color: #f6f9ff;
      filter: drop-shadow(0px 1px 8px rgba(0, 58, 136, 0.15));
      border-radius: 4px;
      color: ${baseTheme.colors.secondary};
      font-size: 16px;
      font-weight: 400;
      padding: 8px 16px;
      position: absolute;
      margin-top: -83px;
    }

    .cancel-order-btn {
      padding: 12px 24px;
      border-radius: 8px;
      border: 2px solid ${baseTheme.colors.error};
      cursor: pointer;
      width: fit-content;

      div {
        font-weight: 500;
        font-size: 18px;
        color: ${baseTheme.colors.error};
        text-align: center;
      }
    }

    .edit-btn {
      padding: 12px 24px;
      border-radius: 8px;
      border: 2px solid ${baseTheme.colors.error};
      cursor: pointer;

      div {
        font-weight: 500;
        font-size: 18px;
        color: ${baseTheme.colors.error};
      }

      @media only screen and (max-width: 550px) {
        display: flex;
        justify-content: center;
        align-items: center;
        div {
          font-size: 14px;
        }
      }
    }

    .collection-btn {
      padding: 12px 24px;
      border-radius: 8px;
      background-color: ${baseTheme.colors.focus};
      cursor: pointer;
      color: ${baseTheme.colors.mainLightBlue};
      margin-right: 24px;
      font-weight: 500;
      font-size: 18px;
      color: ${baseTheme.colors.white};
      border: none;
      height: fit-content;
      &:disabled {
        background-color: ${baseTheme.colors.secondary};
      }
    }

    .main-container {
      display: flex;
      align-items: center;
      gap: 30px;
    }
    .error-message {
      color: #eb5757;
      margin: 0;
    }

    .checkbox-text {
      margin: 0;
    }

    .checkbox-container {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-left: 10px;
    }

    .border-container {
      border: solid 1px #000;
      display: flex;
      padding: 20px;
      border-radius: 5px;
    }

    .ready-btn {
      color: ${baseTheme.colors.secondary};

      div {
        font-weight: 500;
        font-size: 20px;
      }

      .btn {
        margin: 26px 0 -20px 0;
        padding: 12px 24px;
        border-radius: 8px;
        background-color: ${baseTheme.colors.focus};
        cursor: pointer;
        font-weight: 500;
        font-size: 18px;
        color: ${baseTheme.colors.white};
        max-width: 195px;
        margin-bottom: 10px;

        &:hover {
          background-color: ${baseTheme.colors.mainBlue};
        }

        div {
          margin: 0;
        }
      }
    }

    .ready-btn2 {
      font-weight: 500;
      font-size: 20px;
      color: ${baseTheme.colors.secondary};
    }

    .completed {
      margin-top: -40px;
      color: ${baseTheme.colors.white};
      font-weight: 500;
      font-size: 24px;
      background-color: ${baseTheme.colors.focus};
      padding: 8px 10px;
      border-radius: 8px;
    }

    .canceled {
      margin-top: -40px;
      color: ${baseTheme.colors.white};
      font-weight: 500;
      font-size: 24px;
      background-color: ${baseTheme.colors.error};
      padding: 8px 10px;
      border-radius: 8px;
    }
  }

  .button-block-bottom {
    margin-top: 34px;
  }

  @media only screen and (max-width: 550px) {
    padding: 0 24px;

    .title {
      font-size: 20px;
    }

    .subtitle {
      font-size: 16px;
      padding: 0;
  }
`;
