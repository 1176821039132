import styled from 'styled-components';

export const OrderCancelModalContent = styled('div')`
  width: 1200px;

  p {
    margin: 0;
  }

  .modal-title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
    text-align: center;
  }

  .list {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .item {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .column-order {
    width: 100px;
  }

  .column-phone {
    width: 120px;
  }

  .column-delivery {
    width: 120px;
  }

  .column-date {
    width: 150px;
  }

  .column-name {
    width: 200px;
  }

  .column-price {
    width: 100px;
  }

  .column-status {
    width: 150px;
  }

  .column-status-delivery {
    width: 150px;
  }

  .sticky-container {
    background-color: white;
    border-bottom: solid 1px #e9e9e9;
    padding-bottom: 5px;
    position: sticky;
    top: -1px;
    z-index: 100;
    margin-left: 40px;
    display: flex;
    align-items: center;
    gap: 16px;
  }
`;
