import styled from 'styled-components';
import { baseTheme } from '../../global/styles/theme';

export const OperatorsPageContainer = styled('div')`
  max-width: 1216px;
  margin: auto;
  padding: 34px 0;

  .roles {
    color: ${baseTheme.colors.mainLightBlue};
    font-weight: 700;
    font-size: 20px;
    padding-bottom: 34px;
  }
`;

export const UsersBlock = styled('div')`
  padding: 20px;
  border: 1px solid ${baseTheme.colors.stroking};
  border-radius: 8px;
`;

export const Button = styled('div')`
  cursor: pointer;
  font-weight: 500;
  font-size: 18px;
  color: ${baseTheme.colors.white};
  background-color: ${baseTheme.colors.mainLightBlue};
  border-radius: 8px;
  padding: 12px 24px;
  text-align: center;
  max-width: 150px;
  margin: 24px 0 34px 0;
`;
