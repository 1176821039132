import styled from 'styled-components';
import { baseTheme } from '../../global/styles/theme';

export const BlockedProductModalContainer = styled('div')`
  color: ${baseTheme.colors.secondary};
  width: 360px;

  div {
    display: flex;
    justify-content: center;
  }

  .products-title {
    display: flex;
    justify-content: flex-start;
    font-size: 24px;
    font-weight: 700;
    color: ${baseTheme.colors.primary};
  }

  .products-sub-title {
    line-height: 22px;
    padding-top: 16px;
  }
`;

export const ModalButtonsContainer = styled('div')`
  display: flex;
  justify-content: flex-start;
  padding-top: 20px;
  margin-bottom: -10px;
  margin-left: -30px;

  .save-btn {
    margin-right: 8px;
  }
`;
