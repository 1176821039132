import axios from 'axios';

export class OperatorsService {
  static API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/api/v2/`;

  static getOperators = async () => {
    const response = await axios({
      method: 'get',
      url: `${OperatorsService.API_BASE_URL}admin/users/list?role=operator`,
    });
    return response.data;
  };

  static updateUser = async (userInfo: any) => {
    const response = await axios({
      method: 'post',
      url: `${OperatorsService.API_BASE_URL}admin/users`,
      data: userInfo,
    });
    return response.data;
  };
}
