import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  NOTIFICATION_CHANGES_NOT_SAVED,
  NOTIFICATION_CHANGES_SAVED,
} from '../../global/constants';
import { getFromLocalStorage, setToLocalStorage } from '../../global/helpers';
import * as actionsNotification from '../InitComponent/actions';
import * as actions from './actions';
import {
  mappedResponseSearchWithSkuName,
  mappedResponseWithSkuName,
  mappedTags,
} from './helpers';
import { ProductsService } from './service';
import {
  ProductsResponse,
  ProductsSearchResponse,
  TagsFieldResponse,
} from './types';

interface ProductParam {
  type: string;
  payload: {
    token: string;
    num: string;
    tags: string[];
    code: string;
    dataSearchSkus: string[];
  };
}

interface ProductSearchParam {
  type: string;
  payload: {
    token: string;
    num: string;
    tag: string[];
    code: string;
    searchValue: string;
  };
}

interface TagsParam {
  type: string;
  payload: {
    wareId: string;
    tags: string[];
    mappingCode: string;
    postEffect: any;
  };
}

interface DeleteTagParam {
  type: string;
  payload: {
    wareId: string;
    tag: string;
    mappingCode: string;
    postEffect: any;
  };
}

interface MappingsResponse {
  status: string;
  result:
    | {
        code: string;
        source_code: string;
        network_code: string;
        create_tag?: boolean;
      }[]
    | null;
}

interface TagsResponse {
  status: string;
  result: TagsFieldResponse[] | null;
}

export function* getProductsUnAttachedSaga({ payload }: ProductParam) {
  try {
    const responseUnAttached: ProductsResponse = yield call(
      ProductsService.getProductsUnAttached,
      payload.token,
      payload.num,
      payload.tags,
      payload.code
    );

    yield put(actions.getProductsUnAtt.success(responseUnAttached.result));
  } catch (error) {
    yield put(actions.getProductsUnAtt.error(error));
  }
}

export function* getProductsUnAttachedAllSaga({ payload }: ProductParam) {
  try {
    const responseAttachedAll: ProductsResponse = yield call(
      ProductsService.getProductsAttachedAll,
      payload.token,
      payload.code,
      payload.dataSearchSkus
    );

    const responseOnVerAll: ProductsResponse = yield call(
      ProductsService.getProductsOnVerificationAll,
      payload.token,
      payload.code,
      payload.dataSearchSkus
    );

    const responseBlockedAll: ProductsResponse = yield call(
      ProductsService.getProductsBlockedAll,
      payload.token,
      payload.code,
      payload.dataSearchSkus
    );

    const responseBlocked = responseBlockedAll.result.mappings.filter(
      // @ts-ignore
      (el) => el.sku
    );

    const allProducts = {
      mappings: [
        ...(responseAttachedAll.result.mappings || []),
        ...(responseOnVerAll.result.mappings || []),
        ...(responseBlocked || []),
      ],
      total_count:
        responseAttachedAll.result.total_count +
        responseOnVerAll.result.total_count +
        (responseBlocked.length || 0),
    };

    yield put(actions.getProductsAttALL.success(allProducts));
  } catch (error) {
    yield put(actions.getProductsAttALL.error(error));
  }
}

export function* getProductsAttachedSaga({ payload }: ProductParam) {
  try {
    const responseAttached: ProductsResponse = yield call(
      ProductsService.getProductsAttached,
      payload.token,
      payload.num,
      payload.tags,
      payload.code
    );

    const mappedData = mappedResponseWithSkuName(responseAttached.result);

    yield put(actions.getProductsAtt.success(mappedData));
  } catch (error) {
    yield put(actions.getProductsAtt.error(error));
  }
}

export function* getProductsBlockedSaga({ payload }: ProductParam) {
  try {
    const responseBlocked: ProductsResponse = yield call(
      ProductsService.getProductsBlocked,
      payload.token,
      payload.num,
      payload.tags,
      payload.code
    );

    yield put(actions.getProductsBlock.success(responseBlocked.result));
  } catch (error) {
    yield put(actions.getProductsBlock.error(error));
  }
}

export function* getProductsAutomappingSaga({ payload }: ProductParam) {
  try {
    const responseOnAutomapping: ProductsResponse = yield call(
      ProductsService.getProductsOnAutomapping,
      payload.token,
      payload.num,
      payload.tags,
      payload.code
    );

    // @ts-ignore
    const mappedData = mappedResponseWithSkuName(responseOnAutomapping.result);

    yield put(actions.getProductsAutomapping.success(mappedData));
  } catch (error) {
    yield put(actions.getProductsAutomapping.error(error));
  }
}

export function* getProductsVerificationSaga({ payload }: ProductParam) {
  try {
    const responseOnVerification: ProductsResponse = yield call(
      ProductsService.getProductsOnVerification,
      payload.token,
      payload.num,
      payload.tags,
      payload.code
    );
    // @ts-ignore

    const mappedData = mappedResponseWithSkuName(responseOnVerification.result);
    yield put(actions.getProductsVer.success(mappedData));
  } catch (error) {
    yield put(actions.getProductsVer.error(error));
  }
}

export function* getProductsDyblSaga({ payload }: ProductParam) {
  try {
    const responseDybl: ProductsResponse = yield call(
      ProductsService.getProductsDybl,
      payload.token,
      payload.num,
      payload.tags,
      payload.code
    );

    // @ts-ignore
    const mappedData = mappedResponseWithSkuName(responseDybl.result);

    yield put(actions.getProductsDybl.success(mappedData));
  } catch (error) {
    yield put(actions.getProductsDybl.error(error));
  }
}

export function* getProductsSearchUnAttSaga({ payload }: ProductSearchParam) {
  try {
    const response: ProductsSearchResponse = yield call(
      ProductsService.getProductsSearchUnAttached,
      payload.token,
      payload.code,
      payload.searchValue,
      payload.tag,
      payload.num
    );

    yield put(actions.getProductsSearchUnattached.success(response.result));
  } catch (error) {
    yield put(actions.getProductsSearchUnattached.error(error));
  }
}

export function* getProductsSearchDyblSaga({ payload }: ProductSearchParam) {
  try {
    const response: ProductsSearchResponse = yield call(
      ProductsService.getProductsSearchDybl,
      payload.token,
      payload.code,
      payload.searchValue,
      payload.tag,
      payload.num
    );

    const mappedData = mappedResponseSearchWithSkuName(
      response.result.mappings
    );

    yield put(
      actions.getProductsSearchDybl.success({
        mappings: mappedData,
        total_count: response.result.total_count,
      })
    );
  } catch (error) {
    yield put(actions.getProductsSearchDybl.error(error));
  }
}

export function* getProductsSearchAttSaga({ payload }: ProductSearchParam) {
  try {
    const response: ProductsSearchResponse = yield call(
      ProductsService.getProductsSearchAttached,
      payload.token,
      payload.code,
      payload.searchValue,
      payload.tag,
      payload.num
    );

    const mappedData = mappedResponseSearchWithSkuName(
      response.result.mappings
    );
    yield put(
      actions.getProductsSearchAttached.success({
        mappings: mappedData,
        total_count: response.result.total_count,
      })
    );
  } catch (error) {
    yield put(actions.getProductsSearchAttached.error(error));
  }
}

export function* getProductsSearchAutomappingSaga({
  payload,
}: ProductSearchParam) {
  try {
    const response: ProductsSearchResponse = yield call(
      ProductsService.getProductsSearchAutomapping,
      payload.token,
      payload.code,
      payload.searchValue,
      payload.tag,
      payload.num
    );

    const mappedData = mappedResponseSearchWithSkuName(
      response.result.mappings
    );

    yield put(
      actions.getProductsSearchAutomapping.success({
        mappings: mappedData,
        total_count: response.result.total_count,
      })
    );
  } catch (error) {
    yield put(actions.getProductsSearchAutomapping.error(error));
  }
}

export function* getProductsSearchBlockSaga({ payload }: ProductSearchParam) {
  try {
    const response: ProductsSearchResponse = yield call(
      ProductsService.getProductsSearchBlocked,
      payload.token,
      payload.code,
      payload.searchValue,
      payload.tag,
      payload.num
    );

    yield put(actions.getProductsSearchBlocked.success(response.result));
  } catch (error) {
    yield put(actions.getProductsSearchBlocked.error(error));
  }
}

export function* getProductsSearchOnVerSaga({ payload }: ProductSearchParam) {
  try {
    const response: ProductsSearchResponse = yield call(
      ProductsService.getProductsSearchOnVerification,
      payload.token,
      payload.code,
      payload.searchValue,
      payload.tag,
      payload.num
    );

    const mappedData = mappedResponseSearchWithSkuName(
      response.result.mappings
    );

    yield put(
      actions.getProductsSearchOnVerification.success({
        mappings: mappedData,
        total_count: response.result.total_count,
      })
    );
  } catch (error) {
    yield put(actions.getProductsSearchOnVerification.error(error));
  }
}

export function* getNetworksSaga() {
  try {
    const networksResponse: MappingsResponse = yield call(
      ProductsService.getAllNetworks
    );

    const mappings = [
      ...new Set(
        networksResponse.result?.map((el) => {
          return {
            name: el.code,
            code: el.code,
            tag: el.create_tag,
          };
        })
      ),
    ];

    if (!getFromLocalStorage('networkData')) {
      mappings &&
        setToLocalStorage('networkData', {
          name: mappings[0]?.code,
          code: mappings[0]?.code,
          tag: mappings[0]?.tag,
        });
    }

    yield put(actions.getNetworks.success(mappings));
  } catch (error) {
    yield put(actions.getNetworks.error(error));
  }
}

export function* getTagsSaga() {
  try {
    const response: TagsResponse = yield call(ProductsService.getAllTags);

    const mappedData = mappedTags(response.result);

    yield put(actions.getTags.success(mappedData));
  } catch (error) {
    yield put(actions.getTags.error(error));
  }
}

export function* setTagsToProductSaga({ payload }: TagsParam) {
  try {
    const ids = payload.tags.map((el) => Number(el));
    // @ts-ignore
    const response = yield call(
      ProductsService.setTagsToProduct,
      ids,
      payload.wareId,
      payload.mappingCode
    );

    if (payload.postEffect) {
      payload.postEffect();
    }

    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_SAVED,
        canBeClosed: true,
        type: 'success',
      })
    );

    yield put(actions.setTagsToProduct.success(response));
  } catch (error) {
    yield put(actions.setTagsToProduct.error(error));

    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
  }
}

export function* deleteProductTagSaga({ payload }: DeleteTagParam) {
  try {
    // @ts-ignore
    const response = yield call(
      ProductsService.deleteProductTag,
      Number(payload.tag),
      payload.wareId,
      payload.mappingCode
    );

    if (payload.postEffect) {
      payload.postEffect();
    }

    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_SAVED,
        canBeClosed: true,
        type: 'success',
      })
    );

    yield put(actions.deleteProductTag.success(response));
  } catch (error) {
    yield put(actions.deleteProductTag.error(error));

    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
  }
}

export function* productsPageWatcherSaga() {
  yield all([
    takeLatest(actions.getProductsUnAtt.REQUEST, getProductsUnAttachedSaga),
    takeLatest(actions.getProductsAtt.REQUEST, getProductsAttachedSaga),
    takeLatest(actions.getProductsAttALL.REQUEST, getProductsUnAttachedAllSaga),
    takeLatest(actions.getProductsBlock.REQUEST, getProductsBlockedSaga),
    takeLatest(actions.getNetworks.REQUEST, getNetworksSaga),
    takeLatest(actions.getTags.REQUEST, getTagsSaga),
    takeLatest(actions.deleteProductTag.REQUEST, deleteProductTagSaga),
    takeLatest(actions.setTagsToProduct.REQUEST, setTagsToProductSaga),
    takeLatest(actions.getProductsVer.REQUEST, getProductsVerificationSaga),
    takeLatest(actions.getProductsDybl.REQUEST, getProductsDyblSaga),
    takeLatest(
      actions.getProductsAutomapping.REQUEST,
      getProductsAutomappingSaga
    ),
    takeLatest(
      actions.getProductsSearchOnVerification.REQUEST,
      getProductsSearchOnVerSaga
    ),
    takeLatest(
      actions.getProductsSearchAttached.REQUEST,
      getProductsSearchAttSaga
    ),
    takeLatest(
      actions.getProductsSearchUnattached.REQUEST,
      getProductsSearchUnAttSaga
    ),
    takeLatest(
      actions.getProductsSearchBlocked.REQUEST,
      getProductsSearchBlockSaga
    ),
    takeLatest(
      actions.getProductsSearchDybl.REQUEST,
      getProductsSearchDyblSaga
    ),
    takeLatest(
      actions.getProductsSearchAutomapping.REQUEST,
      getProductsSearchAutomappingSaga
    ),
  ]);
}
