import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as Styled from './ProductsBreadCrumbs.style';
import arrowIcon from '../../../../global/media/arrow-breadCrumbs.svg';
import { setToLocalStorage } from '../../../../global/helpers';

const ProductsBreadCrumbs = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleClick = (push: string) => {
    setToLocalStorage('selectedProduct', null);
    history.push(push);
  };

  return (
    <Styled.ProductsBreadCrumbsContainer>
      <div className="breadCrumb" onClick={() => handleClick('/orders')}>
        {t('ProductsPage.ProductsBreadcrumb.ORDERS_BREADCRUMB')}
      </div>
      <Styled.ProductsBreadCrumbIcon>
        <img src={arrowIcon} alt="arrow icon" />
      </Styled.ProductsBreadCrumbIcon>
      <div
        className="breadCrumb active"
        onClick={() => {
          handleClick('/products');
        }}
      >
        {t('ProductsPage.ProductsBreadcrumb.PRODUCTS_BREADCRUMB')}
      </div>
    </Styled.ProductsBreadCrumbsContainer>
  );
};

export default ProductsBreadCrumbs;
