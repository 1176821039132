'use client';

import { createContext, useMemo, useRef } from 'react';
import EditorJS, { ToolConstructable } from '@editorjs/editorjs';
import AlignmentBlockTune from 'editorjs-text-alignment-blocktune';
import Paragraph from '@editorjs/paragraph';
import Header from '@editorjs/header';
import Quote from '@editorjs/quote';
import Delimiter from '@editorjs/delimiter';
import List from '@editorjs/list';
import Underline from '@editorjs/underline';
import ChangeCase from 'editorjs-change-case';
import StrikeThrough from '@sotaproject/strikethrough';
import Checklist from '@editorjs/checklist';
import ImageTool from '@editorjs/image';
import Marker from '@editorjs/marker';
import ColorPlugin from 'editorjs-text-color-plugin';
import ImageGallery from '@kiberpro/editorjs-gallery';

export const EditorContext = createContext<any>(null);

const EditorContextProvider = (props: any) => {
  const editorInstanceRef = useRef<EditorJS | null>(null);

  const initEditor = () => {
    const editor = new EditorJS({
      holder: 'editorjs',
      placeholder: 'Текст',
      tools: {
        textAlignment: {
          class: AlignmentBlockTune,
          config: {
            default: 'left',
            blocks: {
              header: 'center',
            },
          },
        },
        paragraph: {
          class: Paragraph as unknown as ToolConstructable,
          inlineToolbar: true,
          tunes: ['textAlignment'],
          config: {
            placeholder: 'Параграф',
          },
        },
        header: {
          class: Header,
          inlineToolbar: true,
          tunes: ['textAlignment'],
          config: {
            placeholder: 'Введите заголовок',
            levels: [1, 2, 3, 4, 5],
            defaultLevel: 2,
          },
        },
        quote: {
          class: Quote,
          inlineToolbar: true,
          tunes: ['textAlignment'],
          config: {
            quotePlaceholder: 'Цитата',
            captionPlaceholder: 'Автор',
          },
        },
        delimiter: Delimiter,
        list: {
          class: List,
          config: {
            defaultStyle: 'unordered',
          },
        },
        checklist: Checklist,
        image: {
          class: ImageTool as unknown as ToolConstructable,
          config: {
            endpoints: {
              byFile: `${process.env.REACT_APP_API_BASE_URL}/api/v1/receipts`,
              byUrl: `${process.env.REACT_APP_API_BASE_URL}/api/v1/receipts`,
            },
          },
        },
        gallery: {
          class: ImageGallery,
          config: {
            endpoints: {
              byFile: `${process.env.REACT_APP_API_BASE_URL}/api/v1/receipts`,
              byUrl: `${process.env.REACT_APP_API_BASE_URL}/api/v1/receipts`,
            },
          },
        },
        underline: Underline,
        strikeThrough: StrikeThrough,
        marker: Marker,
        changeCase: ChangeCase,
        color: {
          class: ColorPlugin,
          config: {
            colorCollections: [
              '#000',
              '#FFF',
              '#003e6e',
              '#0a77c7',
              '#1fa069',
              '#08d17a',
              '#0cbc70',
              '#0ba864',
              '#099458',
              '#f0f0f0',
              '#f7f7f7',
              '#c4c4c4',
              '#e8e8e8',
              '#f6f9ff',
              '#e0e5eb',
              '#121f30',
              '#535d6a',
              '#7f8895',
              '#002f54',
              '#eb5757',
              '#b18500',
              '#b8c0cc',
            ],
            defaultColor: '#08d17a',
            customPicker: true,
          },
        },
      },
      onChange: async () => {
        const data = await editor.save();
      },
    });
    editorInstanceRef.current = editor;
  };

  const contextValue = useMemo(
    () => ({
      editorInstanceRef,
      initEditor,
    }),
    []
  );

  return (
    <EditorContext.Provider value={contextValue}>
      {props.children}
    </EditorContext.Provider>
  );
};

export default EditorContextProvider;
