import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import * as actions from '../../containers/ProductsPage/actions';
import * as Styled from './NetworksModal.styles';
import ModalWindow from '../ModalWindow';
import { selectors } from '../../containers/ProductsPage/reducer';
import { getFromLocalStorage, setToLocalStorage } from '../../global/helpers';
import { UserRole } from '../../containers/OrdersPage/types';

interface NetworksModalProps {
  setManagerCode?: (code: string) => void;
  setToPage: () => void;
}

const NetworksModal = ({ setManagerCode, setToPage }: NetworksModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const role = getFromLocalStorage('role');

  const networks = useSelector(selectors.networksData);

  const [filteredNetworks, setNetworks] = useState(networks);
  const [value, setValue] = useState('');

  useEffect(() => {
    dispatch(actions.getNetworks.request());
    networks && setNetworks(networks);
  }, [dispatch]);

  const handleCLoseModal = () => {
    dispatch(actions.closeNetworks());
  };

  const handleClickNetwork = (network: {
    name: string;
    code: string;
    tag: boolean;
  }) => {
    dispatch(actions.closeNetworks());
    setToPage();
    if (
      setManagerCode &&
      (role === UserRole.MANAGER || role === UserRole.PHARMACY_MANAGER)
    ) {
      setManagerCode(network.code);
      setToLocalStorage('networkData', {
        name: network.name,
        code: network.code,
        tag: network.tag,
      });
    } else {
      dispatch(actions.setNetwork(network));
      setToLocalStorage('networkData', {
        name: network.name,
        code: network.code,
        tag: network.tag,
      });
    }
  };

  const handleSearchNetwork = () => {
    if (value) {
      setNetworks(
        (networks &&
          networks?.filter((el: { name: string; code: string }) =>
            el.name.toLowerCase().includes(value.toLowerCase())
          )) ||
          []
      );
    } else {
      setNetworks(networks);
    }
  };

  useEffect(() => {
    handleSearchNetwork();
  }, [value, networks]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return (
    <ModalWindow onClose={handleCLoseModal} zIndex>
      <Styled.NetworksContainer>
        {!setManagerCode && (
          <Styled.NetworksTitle>
            {t('ProductsPage.NETWORKS_MODAL.TITLE')}
          </Styled.NetworksTitle>
        )}
        <Styled.SearchBarInput>
          <input
            placeholder={t('ProductsPage.NETWORKS_MODAL.PLACEHOLDER')}
            value={value}
            onChange={handleInputChange}
            type="text"
            /* eslint-disable-next-line jsx-a11y/no-autofocus */
            autoFocus
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSearchNetwork();
              }
            }}
          />
        </Styled.SearchBarInput>
        <Styled.NetworksBlock>
          {filteredNetworks ? (
            filteredNetworks.map(
              (network: { name: string; code: string; tag: boolean }) => (
                <div
                  className="network-container"
                  onClick={() => handleClickNetwork(network)}
                  key={network.code}
                >
                  <div className="network">{network.name}</div>
                  {network.tag && <div className="green-point" />}
                </div>
              )
            )
          ) : (
            <CircularProgress size={10} />
          )}
        </Styled.NetworksBlock>
      </Styled.NetworksContainer>
    </ModalWindow>
  );
};

export default NetworksModal;
