import { useDispatch } from 'react-redux';
import { useState } from 'react';
import * as Styled from './WidthrowPharmacistBonuses.styles';
import * as actions from '../../../../actions';
import CustomTextarea from '../../../../../../components/CustomTextarea';

interface WidthrowPharmacistBonusesProps {
  phoneNumber: string;
  getPharmacistPromoCodeData: () => void;
}

const WidthrowPharmacistBonuses = ({
  phoneNumber,
  getPharmacistPromoCodeData,
}: WidthrowPharmacistBonusesProps) => {
  const dispatch = useDispatch();
  const [comment, setComment] = useState('');
  const [withdrawCount, setWithdrawCount] = useState('');
  const [commentError, setCommentError] = useState('');
  const [withdrawCountError, setWithdrawCountError] = useState('');

  const onWidthrowBonusesHandler = () => {
    if (comment.length < 5 || comment.length > 60) {
      setCommentError('Введите от 5 до 50 символов');
    }

    if (!withdrawCount || withdrawCount.length === 0) {
      setWithdrawCountError('Укажите значение больше 0₸');
    }
    if (Number(withdrawCount) < 10000) {
      setWithdrawCountError('Доступны для вывода от 10.000тг');
    }

    if (
      comment.length < 5 ||
      comment.length > 60 ||
      !withdrawCount ||
      withdrawCount.length === 0
    ) {
      return;
    }

    dispatch(
      actions.withdrawPharmacistBonuses.request({
        data: {
          amount: Number(withdrawCount),
          comment,
          pharmacist_id: phoneNumber,
        },
        postEffect: () => {
          getPharmacistPromoCodeData();
        },
      })
    );
  };

  return (
    <Styled.WidthdrawBonusesContainer>
      <p className="main-title">Вывод бонусов</p>
      <div className="comment-container">
        <p className="title">Комментарий</p>
        <CustomTextarea
          rows={2}
          placeholder="Укажите комментарий перед выводом бонусов"
          value={comment}
          onChange={(e) => {
            setCommentError('');
            setComment(e);
          }}
        />
        <div className="error-message">{commentError && commentError}</div>
        <div className="count-input">
          <p className="title">Суммая вывода</p>
          <CustomTextarea
            placeholder="Укажите cумму в тенге"
            value={withdrawCount}
            onChange={(e) => {
              if (Number(e) || !e) {
                setWithdrawCountError('');
                setWithdrawCount(e);
              }
            }}
          />
          <div className="error-message">
            {withdrawCountError && withdrawCountError}
          </div>
        </div>
      </div>
      <div className="withdraw-btn" onClick={onWidthrowBonusesHandler}>
        Снять бонусы
      </div>
    </Styled.WidthdrawBonusesContainer>
  );
};

export default WidthrowPharmacistBonuses;
