import { createReducer } from '@reduxjs/toolkit';
import * as actions from './actions';
import { AppState } from '../../global/types';
import { NetworkPageState } from './types';

const initialState: NetworkPageState = {
  networkData: {
    data: null,
    loading: false,
    error: null,
  },
  usersData: {
    data: null,
    loading: false,
    error: null,
  },
  sourcesData: {
    data: null,
    loading: false,
    error: null,
  },
  networksData: {
    data: null,
    loading: false,
    error: null,
  },
  networkTagsData: {
    data: null,
    loading: false,
    error: null,
  },
};

const reducer = createReducer(initialState, {
  [actions.getNetwork.REQUEST]: (state) => {
    state.networkData.loading = true;
  },
  [actions.getNetwork.ERROR]: (state, action) => {
    state.networkData.error = action.payload;
    state.networkData.loading = false;
  },
  [actions.getNetwork.SUCCESS]: (state, action) => {
    state.networkData.data = action.payload;
    state.networkData.loading = false;
  },
  [actions.getAllNetwork.REQUEST]: (state) => {
    state.networksData.loading = true;
  },
  [actions.getAllNetwork.ERROR]: (state, action) => {
    state.networksData.error = action.payload;
    state.networksData.loading = false;
  },
  [actions.getAllNetwork.SUCCESS]: (state, action) => {
    state.networksData.data = action.payload;
    state.networksData.loading = false;
  },
  [actions.clearNetwork.type]: (state) => {
    state.networkData.data = null;
  },
  [actions.clearSources.type]: (state) => {
    state.sourcesData.data = null;
  },
  [actions.getUsersOfNetwork.REQUEST]: (state) => {
    state.usersData.loading = true;
  },
  [actions.getUsersOfNetwork.ERROR]: (state, action) => {
    state.usersData.error = action.payload;
    state.usersData.loading = false;
  },
  [actions.getUsersOfNetwork.SUCCESS]: (state, action) => {
    state.usersData.data = action.payload;
    state.usersData.loading = false;
  },
  [actions.getSourcesByNetwork.REQUEST]: (state) => {
    state.sourcesData.loading = true;
  },
  [actions.getSourcesByNetwork.ERROR]: (state, action) => {
    state.sourcesData.error = action.payload;
    state.sourcesData.loading = false;
  },
  [actions.getSourcesByNetwork.SUCCESS]: (state, action) => {
    state.sourcesData.data = action.payload;
    state.sourcesData.loading = false;
  },
  [actions.getNetworkTags.SUCCESS]: (state, action) => {
    state.networkTagsData.data = action.payload;
    state.networkTagsData.loading = false;
  },
  [actions.getNetworkTags.REQUEST]: (state) => {
    state.networkTagsData.loading = true;
  },
  [actions.getNetworkTags.ERROR]: (state, action) => {
    state.networkTagsData.error = action.payload;
    state.networkTagsData.loading = false;
  },
});

const networkData = (state: AppState) =>
  state.networkPageReducer.networkData.data;

const networkLoading = (state: AppState) =>
  state.networkPageReducer.networkData.loading;

const networksData = (state: AppState) =>
  state.networkPageReducer.networksData.data;

const networksLoading = (state: AppState) =>
  state.networkPageReducer.networksData.loading;

const sourcesData = (state: AppState) =>
  state.networkPageReducer.sourcesData.data;

const sourcesLoading = (state: AppState) =>
  state.networkPageReducer.sourcesData.loading;

const usersNetworkData = (state: AppState) =>
  state.networkPageReducer.usersData.data;

const networkTagsData = (state: AppState) =>
  state.networkPageReducer.networkTagsData.data;

const networkTagsLoading = (state: AppState) =>
  state.networkPageReducer.networkTagsData.loading;

const selectors = {
  networkData,
  networkLoading,
  sourcesLoading,
  sourcesData,
  networksData,
  networksLoading,
  usersNetworkData,
  networkTagsData,
  networkTagsLoading,
};

export { selectors };
export default reducer;
