import { ProductResponseItem } from '../OrdersPage/types';
import { AnalogData } from './types';

export const formatAnalogsData = (
  analogsList: AnalogData[],
  magentaResponse: ProductResponseItem[]
) => {
  const getCustomAttribute = (customAttributes: any, attributeCode: string) =>
    customAttributes?.find((el: any) => el.attribute_code === attributeCode)
      ?.value;

  return analogsList.map((analog: AnalogData) => ({
    analogSourceIds: analog.analog_source_ids,
    createdAt: analog.created_at,
    id: analog.id,
    approved: analog.approved,
    rank: analog.rank,
    replaceable: analog.replaceable,
    value: analog.value,
    updatedAt: analog.updated_at,
    updatedBy: analog.updated_by,
    name: magentaResponse?.find((item) => item.sku === analog.value)?.name,
  }));
};
