import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { selectors } from '../../reducer';

import * as actions from '../../actions';
import CustomTextarea from '../../../../components/CustomTextarea';
import * as Styled from './PharmacistPromoCode.styles';
import PharmacistPromocodesTable from '../../../PharmacistPromoCodePage/components/PharmacistPromocodesTable/PharmacistPromocodesTable';
import WidthrowPharmacistBonuses from './components/WidthrowPharmacistBonuses/WidthrowPharmacistBonuses';
import PharmacistPromocodesTableData from '../../../PharmacistPromoCodePage/components/PharmacistPromocodesTableData/PharmacistPromocodesTableData';
import { GET_PRPMO_CODE_STATUS_BY_CODE } from '../../constants';

const PharmacistPromoCode = () => {
  const isBlockedPromoCode = useSelector(selectors.getPromocodeStatusByCode);
  const dataBlocked = useSelector(selectors.getPromocodePharmacistBlocked);
  const [pharmacistPhoneNumber, setPharmacistPhoneNumber] = useState('');
  const [isBlocked, setIsBlocked] = useState(isBlockedPromoCode?.is_blocked);
  const dispatch = useDispatch();
  const newPharmacistPromoCode = useSelector(selectors.newPharmacistPromoCode);
  const bonusOrdersDetailsHistory = useSelector(
    selectors.bonusOrdersDetailsHistory
  );
  const bonusOrdersDetails = useSelector(selectors.bonusOrdersDetails);
  const pharmacistBonusesBalance = useSelector(
    selectors.pharmacistBonusesBalance
  );

  useEffect(() => {
    isBlockedPromoCode?.is_blocked &&
      setIsBlocked(isBlockedPromoCode?.is_blocked);
  }, [isBlockedPromoCode]);

  const getPharmacistPromoCodeData = () => {
    dispatch(
      actions.getBonusOrdersDetails.request({
        phoneNumber: pharmacistPhoneNumber,
        isAdmin: true,
      })
    );
    dispatch(
      actions.getBonusOrdersDetailsHistory.request({
        phoneNumber: pharmacistPhoneNumber,
        isAdmin: true,
      })
    );
    dispatch(
      actions.getPharmacistBonusesBalance.request({
        phoneNumber: pharmacistPhoneNumber,
        isAdmin: true,
      })
    );
    dispatch(
      actions.getNewPharmacistPromoCode.request({
        pharmacistPhoneNumber,
      })
    );

    dispatch(
      actions.getPromocodePharmacistBlocked.request({
        pharmacistPhoneNumber,
      })
    );
  };
  const handleClick = (code: string) => {
    dispatch(
      actions.promocodePharmacistBlocked.request({
        promocode: code,
        data: { blocked: isBlocked },
        postEffect: () => {
          dispatch(
            actions.getPromocodeStatusByCode.request({
              code,
            })
          );
        },
      })
    );
  };

  return (
    <Styled.PharmacistPromoCodeContainer>
      <div className="description">
        Введите номер фармацевта, чтобы получить детальную информацию по его
        выплатам
      </div>
      <div className="promo-code-creating-container">
        <div className="textarea">
          <CustomTextarea
            placeholder="77070071234"
            value={pharmacistPhoneNumber}
            onChange={(e) => setPharmacistPhoneNumber(e)}
          />
        </div>
        <div onClick={getPharmacistPromoCodeData} className="create-btn">
          Проверить
        </div>
      </div>

      {newPharmacistPromoCode && (
        <PharmacistPromocodesTableData
          handleClick={handleClick}
          isBlocked={isBlocked}
          setIsBlocked={setIsBlocked}
          pharmacy={newPharmacistPromoCode?.pharmacy_code}
          phone={newPharmacistPromoCode?.promocode?.pharmacist}
          code={newPharmacistPromoCode?.promocode?.code}
        />
      )}
      {pharmacistBonusesBalance && (
        <>
          {' '}
          <p className="main-title">Бонусы</p>
          <div className="pharmacist-promo-code-balance-container">
            <p className="pharmacist-promo-code-description">
              Баланс: <span>{pharmacistBonusesBalance.balance} ₸</span>{' '}
              <span className="text">(доступны для вывода от 10.000тг)</span>
            </p>
            <p className="pharmacist-promo-code-description">
              Ожидающие: <span>{pharmacistBonusesBalance.pending} ₸</span>{' '}
              <span className="text">(не доступны)</span>
            </p>
          </div>
        </>
      )}

      {bonusOrdersDetailsHistory && (
        <PharmacistPromocodesTable
          blockedArray={dataBlocked?.result?.blocked_bonuses}
          blockedTotalSum={dataBlocked?.result?.total}
          isAdmin={false}
          bonusOrdersDetails={bonusOrdersDetails}
          bonusOrdersDetailsHistory={bonusOrdersDetailsHistory}
        />
      )}
      {newPharmacistPromoCode?.promocode?.pharmacist && (
        <WidthrowPharmacistBonuses
          getPharmacistPromoCodeData={getPharmacistPromoCodeData}
          phoneNumber={newPharmacistPromoCode.promocode.pharmacist as string}
        />
      )}
    </Styled.PharmacistPromoCodeContainer>
  );
};
export default PharmacistPromoCode;
