import { createReducer } from '@reduxjs/toolkit';
import * as actions from './actions';
import { AppState } from '../../global/types';
import { OrderHistoryState } from './types';

const initialState: OrderHistoryState = {
  historyPage: {
    data: null,
    loading: false,
    error: null,
  },
};

const reducer = createReducer(initialState, {
  [actions.getOrderHistory.REQUEST]: (state) => {
    state.historyPage.loading = true;
  },
  [actions.getOrderHistory.ERROR]: (state, action) => {
    state.historyPage.error = action.payload;
    state.historyPage.loading = false;
  },
  [actions.getOrderHistory.SUCCESS]: (state, action) => {
    state.historyPage.data = action.payload;
    state.historyPage.loading = false;
  },
});

const historyPageLoading = (state: AppState) =>
  state.historyPageReducer.historyPage.loading;
const historyPageError = (state: AppState) =>
  state.historyPageReducer.historyPage.error;
const historyPageData = (state: AppState) =>
  state.historyPageReducer.historyPage.data;

const selectors = {
  historyPageData,
  historyPageError,
  historyPageLoading,
};

export { selectors };
export default reducer;
