import styled from 'styled-components';
import { baseTheme } from '../../../../../../global/styles/theme';

export const ProductContainer = styled('div')`
  width: 270px;
  height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .product-img {
    width: 240px;
    height: 140px;
    img {
      object-fit: contain;
      width: 240px;
      height: 140px;
    }
  }

  .product-name {
    padding: 10px 0 8px;
    font-weight: 500;
    font-size: 18px;
  }

  .product-manufacturer {
    padding: 4px 0 16px;
    color: ${baseTheme.colors.secondary};
  }

  .product-mapping-block {
    display: flex;
    margin-bottom: 16px;
    color: ${baseTheme.colors.secondary};

    .mapping {
      padding-right: 7px;
    }

    .img {
      cursor: pointer;
    }
  }
`;
