import { all, call, put, takeLatest } from 'redux-saga/effects';
import { NOTIFICATION_CHANGES_NOT_SAVED } from '../../global/constants';
import * as actionsNotification from '../InitComponent/actions';
import { OrdersService } from '../OrdersPage/service';
import { ProductResponseItem } from '../OrdersPage/types';
import * as actions from './actions';
import { formatAnalogsData } from './helpers';
import { AnalogsService } from './service';
import { AnalogData } from './types';
import { getAnalogsListInformationPharmacistBySku } from './actions';

interface getAnalogsListBySkuSagaParam {
  type: string;
  payload: {
    key: string;
    type: string;
    postEffect?: () => void;
  };
}

interface CreateSuggestionSagaParam {
  type: string;
  payload: {
    token: string;
    key: string;
    type: string;
    value: string;
    postEffect?: () => void;
  };
}

interface SaveSuggestionsOrderSagaParam {
  type: string;
  payload: {
    orderedIds: number[];
    postEffect?: () => void;
  };
}

interface DeleteSuggestionSagaParam {
  type: string;
  payload: {
    id: number;
    postEffect?: () => void;
  };
}

export function* getAnalogsListBySkuSaga({
  payload,
}: getAnalogsListBySkuSagaParam) {
  try {
    const response: {
      result: AnalogData[];
    } = yield call(AnalogsService.getAnalogsBySku, payload.key, payload.type);

    const skuList = response.result.map((analog) => analog.value);

    if (payload.postEffect) {
      payload.postEffect();
    }
    let magentaResponse: { items: ProductResponseItem[] } = { items: [] };
    if (skuList.length > 0) {
      try {
        magentaResponse = !skuList[0].includes('question')
          ? yield call(OrdersService.getProductsBySkus, skuList)
          : { items: [] };
      } catch (error) {
        console.log(error);
      }
      const formatedAnalogs = formatAnalogsData(
        response.result,
        magentaResponse.items
      );

      yield put(actions.getAnalogsListBySku.success(formatedAnalogs));
    } else {
      yield put(actions.getAnalogsListBySku.success([]));
    }
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.getAnalogsListBySku.error(error));
  }
}

export function* getAnalogsListBySkuPharmacistSaga({
  payload,
}: getAnalogsListBySkuSagaParam) {
  try {
    const response: {
      result: AnalogData[];
    } = yield call(AnalogsService.getAnalogsBySkuPharmacist, payload.key);

    yield put(actions.getAnalogsListPharmacistBySku.success(response.result));
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.getAnalogsListPharmacistBySku.error(error));
  }
}

export function* getAnalogsListBySkuInformationPharmacistSaga({
  payload,
}: getAnalogsListBySkuSagaParam) {
  try {
    const response: {
      result: AnalogData[];
    } = yield call(AnalogsService.getAnalogsBySkuPharmacist, payload.key);

    yield put(
      actions.getAnalogsListInformationPharmacistBySku.success(response.result)
    );
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.getAnalogsListInformationPharmacistBySku.error(error));
  }
}

export function* createSuggestionSaga({ payload }: CreateSuggestionSagaParam) {
  try {
    const response: {
      result: {
        status: string;
      };
    } = yield call(
      AnalogsService.createSuggestionOrder,
      payload.token,
      payload.key,
      payload.type,
      payload.value
    );

    if (payload.postEffect) {
      payload.postEffect();
    }

    yield put(actions.createSuggestion.success(response.result));
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.createSuggestion.error(error));
  }
}

export function* saveSuggestionsOrderSaga({
  payload,
}: SaveSuggestionsOrderSagaParam) {
  try {
    const response: {
      result: {
        status: string;
      };
    } = yield call(AnalogsService.setSuggestionOrder, payload.orderedIds);

    if (payload.postEffect) {
      payload.postEffect();
    }

    yield put(actions.saveSuggestionsOrder.success(response));
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.saveSuggestionsOrder.error(error));
  }
}

export function* deleteSuggestionSaga({ payload }: DeleteSuggestionSagaParam) {
  try {
    const response: {
      result: {
        status: string;
      };
    } = yield call(AnalogsService.deleteSuggestion, payload.id);

    if (payload.postEffect) {
      payload.postEffect();
    }

    actions.deleteSuggestion.success(response);
  } catch (error) {
    actionsNotification.addApplicationNotification({
      content: NOTIFICATION_CHANGES_NOT_SAVED,
      canBeClosed: true,
      type: 'error',
    });

    yield put(actions.deleteSuggestion.error(error));
  }
}

export function* analogsPageWatcherSaga() {
  yield all([
    takeLatest(actions.getAnalogsListBySku.REQUEST, getAnalogsListBySkuSaga),
    takeLatest(actions.createSuggestion.REQUEST, createSuggestionSaga),
    takeLatest(actions.saveSuggestionsOrder.REQUEST, saveSuggestionsOrderSaga),
    takeLatest(actions.deleteSuggestion.REQUEST, deleteSuggestionSaga),
    takeLatest(
      actions.getAnalogsListPharmacistBySku.REQUEST,
      getAnalogsListBySkuPharmacistSaga
    ),
    takeLatest(
      actions.getAnalogsListPharmacistBySku.REQUEST,
      getAnalogsListBySkuInformationPharmacistSaga
    ),
  ]);
}
