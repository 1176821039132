import { createAction } from '@reduxjs/toolkit';
import { asyncActionsCreator } from '../../global/redux';
import * as constants from './constants';

export const getOrders = asyncActionsCreator(constants.GET_ORDERS);
export const getSchedule = asyncActionsCreator(constants.GET_SCHEDULE);
export const getAllNetworkEnabled = asyncActionsCreator(
  constants.GET_ALL_NETWORK_ENABLED
);
export const getALLOrders = asyncActionsCreator(constants.GET_ALL_ORDERS);
export const postCollectorManager = asyncActionsCreator(
  constants.POST_COLLECTOR_MANAGER
);
export const getOrdersSearch = asyncActionsCreator(constants.GET_ORDERS_SEARCH);
export const getSourcesByNetwork = asyncActionsCreator(
  constants.GET_SOURCES_BE_NETWORK
);
export const clearOrdersSearch = createAction(constants.CLEAR_ORDERS_SEARCH);
export const clearOrders = createAction(constants.CLEAR_ORDERS);
export const getOrdersRefresh = asyncActionsCreator(
  constants.GET_ORDERS_REFRESH
);
export const cancelOrder = asyncActionsCreator(constants.CANCEL_ORDER);
export const cancelPromocode = asyncActionsCreator(constants.CANCEL_PROMOCODE);
export const putCollectingStatus = asyncActionsCreator(
  constants.PUT_COLLECTING_STATUS
);
export const putWaitingReceiptStatus = asyncActionsCreator(
  constants.PUT_WAITING_RECEIPT_STATUS
);
export const putNoReceiptStatus = asyncActionsCreator(
  constants.PUT_NO_RECEIPT_STATUS
);
export const putForAdjustmentStatus = asyncActionsCreator(
  constants.PUT_FOR_ADJUSTMENT_STATUS
);
export const putReadyStatus = asyncActionsCreator(constants.PUT_READY_STATUS);
export const putCompletedStatus = asyncActionsCreator(
  constants.PUT_COMPLETED_STATUS
);
export const putClarificationStatus = asyncActionsCreator(
  constants.PUT_CLARIFICATION_STATUS
);
export const setSelectedOrder = asyncActionsCreator(
  constants.SET_SELECTED_ORDER
);

export const putSeenOrders = asyncActionsCreator(constants.PUT_SEEN_ORDERS);
export const createClaim = asyncActionsCreator(constants.CREATE_CLAIM);
export const coordinates = asyncActionsCreator(constants.COORDINATES);
export const resetCoordinates = createAction(constants.RESET_COORDINATES);
export const cancelClaim = asyncActionsCreator(constants.CANCEL_CLAIM);

export const putMutedOrders = asyncActionsCreator(constants.PUT_MUTED_ORDERS);
export const getNotViewedOrders = asyncActionsCreator(
  constants.GET_NOT_VIEWED_ORDERS
);
export const getNotMutedOrders = asyncActionsCreator(
  constants.GET_NOT_MUTED_ORDERS
);
export const generateTestOrders = asyncActionsCreator(
  constants.GENERATE_TEST_ORDERS
);

export const putPaymentStatus = asyncActionsCreator(
  constants.PUT_PAYMENT_STATUS
);

export const postRepayment = asyncActionsCreator(constants.POST_REPAYMENT);
export const postRefund = asyncActionsCreator(constants.POST_REFUND);

export const putStatus = asyncActionsCreator(constants.PUT_STATUS);
export const clearSelectedOrder = createAction(constants.CLEAR_SELECTED_ORDER);

export const setOrderId = createAction<string>(constants.SET_ORDER_ID);
export const clearOrderId = createAction(constants.CLEAR_ORDER_ID);
export const getCartHistory = asyncActionsCreator(constants.GET_CART_HISTORY);
