import styled from 'styled-components';
import { baseTheme } from '../../../../global/styles/theme';

export const TimerContainer = styled('div')`
  width: 286px;
  border: 1px solid ${baseTheme.colors.stroking};
  border-radius: 8px;
`;

export const Timer = styled('div')`
  min-height: 53px;
  padding: 12px 32px;
  border-bottom: 1px solid ${baseTheme.colors.stroking};
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  .timer {
    font-weight: 700;
    font-size: 24px;
    display: flex;
    align-items: center;
  }
  .timer-green {
    color: ${baseTheme.colors.focus};
  }
  .timer-orange {
    color: ${baseTheme.colors.warning};
  }
  .timer-red {
    color: ${baseTheme.colors.error};
  }

  .timer-comment {
    padding-top: 10px;
    max-height: 43px;
    overflow-x: hidden;
    overflow-y: auto;
    font-size: 14px;
    font-weight: 400;
    word-wrap: break-word;
  }

  .user-comment {
    font-size: 16px;
    font-weight: 500;
    color: #7f8895;

    span {
      font-size: 18px;
      color: ${baseTheme.colors.tertiary};
    }
  }
`;

export const TimerBlock = styled('div')`
  padding: 23px 32px;
  .textarea {
    height: 38px;
    min-height: 0 !important;
  }

  .wrapper {
    position: relative;
    width: 100%;
    height: 40px;
  }
  .checkbox-input {
    cursor: pointer;
    width: 20px !important;
    height: 20px;
  }
  .timer-comment-container {
    .radio-box-container {
      display: flex;
      flex-direction: column;

      p {
        font-size: 18px;
        font-weight: 500;
        color: #7f8895;
      }
    }
  }
  .label-checkbox {
    width: 200px;
    cursor: pointer;
  }

  .checkbox-container {
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .container-textarea {
    z-index: 5;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 230px;

    .textarea {
      padding: 3px !important;

      height: 29px;
    }
  }
  .select-group {
    position: absolute;
    top: 0;
    left: 0;
    padding: 5px;
  }

  .textarea::placeholder {
    color: ${baseTheme.colors.tertiary};
    font-size: 14px;
    font-family: 'Helvetica Neue', Helvetica, sans-serif;
  }
`;

export const ChangeStatusBlock = styled('div')`
  display: flex;
  .status {
    cursor: pointer;
    margin-right: 33px;
    font-size: 18px;
    font-weight: 500;
    color: ${baseTheme.colors.tertiary};
  }
  .active {
    padding-bottom: 5px;
    font-weight: 500;
    color: ${baseTheme.colors.mainLightBlue};
    border-bottom: 1px solid ${baseTheme.colors.mainLightBlue};
  }
`;

export const SetTimer = styled('div')`
  padding: 16px 0;
  display: flex;
  input[type='time'],
  input:focus {
    -webkit-appearance: none;
    color: ${baseTheme.colors.tertiary};
    outline: none;
    background-image: none;
    font-weight: 500;
    font-size: 24px;
    font-family: 'Helvetica Neue', Helvetica, sans-serif;
    letter-spacing: 2px;
    padding: 8px 12px;
    border: 1px solid ${baseTheme.colors.stroking};
    border-radius: 8px;
  }
  input[type='time'] {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='time']::-webkit-calendar-picker-indicator {
    display: none;
  }
  input[type='time']::-webkit-inner-spin-button {
    display: none;
  }
  input[type='time']::-webkit-clear-button {
    display: none;
  }
  .days-block {
    display: flex;
    align-items: center;
    .day {
      cursor: pointer;
      font-weight: 500;
      font-size: 14px;
      text-decoration-line: underline;
      margin-left: 8px;
      color: ${baseTheme.colors.tertiary};
    }
    .active {
      color: ${baseTheme.colors.mainLightBlue};
    }
  }
`;

export const StartButton = styled('div')`
  padding-top: 16px;
  position: relative;

  .start-timer {
    border: none;
    margin-top: 20px;
    cursor: pointer;
    padding: 11px 24px;
    background-color: #0a77c7;
    font-weight: 500;
    font-size: 18px;
    color: #ffffff;
    border-radius: 8px;

    &:disabled {
      background-color: ${baseTheme.colors.secondary};
    }
  }

  .error-tooltip {
    max-width: 300px;
    word-break: break-word;
    background-color: #f6f9ff;
    filter: drop-shadow(0px 1px 8px rgba(0, 58, 136, 0.15));
    border-radius: 4px;
    color: ${baseTheme.colors.error};
    font-size: 16px;
    font-weight: 400;
    padding: 8px 16px;
    position: absolute;
    top: 70px;
  }
`;
