import styled from 'styled-components';
import { baseTheme } from '../../../../../../global/styles/theme';

export const WidthdrawBonusesContainer = styled('div')`
  .comment-container {
    max-width: 300px;
    div {
      .textarea {
        height: 60px;
      }
    }
    .count-input {
      width: 200px;
      div {
        .textarea {
          height: 30px;
        }
      }
    }
  }
  .withdraw-btn {
    border: none;
    font: inherit;
    font-weight: 500;
    font-size: 18px;
    color: #ffffff;
    background-color: #0a77c7;
    border-radius: 8px;
    padding: 8px 16px;
    text-align: center;
    max-width: 200px;
    cursor: pointer;
    margin-top: 40px;
  }
  .main-title {
    font-weight: 700;
    color: #121f30;
    font-size: 22px;
  }
  .title {
    font-size: 16px;
    color: #535d6a;
    font-weight: 500;
    text-align: start;
    margin: 5px 0px;
  }
  .error-message {
    font-weight: 600;
    color: ${baseTheme.colors.error};
    height: 20px;
  }
`;
