import { asyncActionsCreator } from '../../global/redux';
import * as constants from './constants';

export const getWebStockProducts = asyncActionsCreator(
  constants.GET_WEBSTOCK_PRODUCTS
);
export const updateWebStockProduct = asyncActionsCreator(
  constants.UPDATE_WEBSTOK_PRODUCT
);

export const getProductsBySku = asyncActionsCreator(
  constants.GET_PRODUCTS_BY_SKU
);
