import { createAction } from '@reduxjs/toolkit';
import { asyncActionsCreator } from '../../global/redux';
import * as constants from './constants';

export const postImg = asyncActionsCreator(constants.POST_RECIPE_IMG);
export const getPrice = asyncActionsCreator(constants.GET_PRICE);
export const putOrder = asyncActionsCreator(constants.PUT_ORDER);
export const n8nPrice = asyncActionsCreator(constants.N8N_PRICE);

export const getAllStatuses = asyncActionsCreator(constants.GER_ALL_STATUSES);
export const getAllPharmacyStatuses = asyncActionsCreator(
  constants.GET_ALL_PHARMACY_STATUSES
);

export const setSelectedProduct = createAction<string>(
  constants.SET_SELECTED_PRODUCT
);
export const setSelectedActivePharmacistOnOrder = createAction<any>(
  constants.SET_SELECTED_ACTIVE_PHARMACIST_ON_ORDER
);
export const clearSelectedProduct = createAction(
  constants.CLEAR_SELECTED_PRODUCT
);
export const clearSelectedActivePharmacistOnOrder = createAction(
  constants.CLEAR_SELECTED_ACTIVE_PHARMACIST_ON_ORDER
);

export const getProductsSearchForAnalog = asyncActionsCreator(
  constants.GET_PRODUCTS_SEARCH_FOR_ANALOG
);

export const applyPromoCode = asyncActionsCreator(constants.APPLY_PROMO_CODE);
export const sendEdit = asyncActionsCreator(constants.SEND_EDIT);

export const getPriceWithQuantityBySource = asyncActionsCreator(
  constants.GET_PRICE_QUANTITY_BY_SOURCE
);
export const changeTimer = asyncActionsCreator(constants.CHANGE_TIMER);

export const getProductsAtt = asyncActionsCreator(
  constants.GET_PRODUCTS_ATTACHED
);

export const createInterpayPayouts = asyncActionsCreator(
  constants.CREATE_INTERPAY_PAYOUTS
);

export const getInterpayPayouts = asyncActionsCreator(
  constants.GET_INTERPAY_PAYOUTS
);

export const changeInterpayPayouts = asyncActionsCreator(
  constants.CHANGE_INTERPAY_PAYOUTS
);
