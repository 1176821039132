import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';
import { selectors as userSelectors } from '../../LoginPage/reducer';
import * as actions from '../actions';
import editIcon from '../../../global/media/edit-pencil.svg';
import deleteIcon from '../../../global/media/delete-icon.svg';
import { SalesFormatedPharmacy, SalesProductFormatedData } from '../types';
import { getFromLocalStorage } from '../../../global/helpers';
import { UserRole } from '../../OrdersPage/types';
import ModalWindow from '../../../components/ModalWindow';
import Button from '../../../components/Button';
import { isDateFresh } from '../helpers';

interface OrderProductsBlockProps {
  el: SalesProductFormatedData;
}

function getDateString(date: Date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

const SalesProductsTable = ({ el }: OrderProductsBlockProps) => {
  const userData = useSelector(userSelectors.userData);
  const [editedItemIndex, setEditedItemIndex] = useState<null | string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const role = getFromLocalStorage('role');
  const networkCode =
    getFromLocalStorage('networkData')?.code || userData?.sourceCode;
  const [currentPharmacy, setCurrentPharmacy] =
    useState<null | SalesFormatedPharmacy>(null);
  const [productData, setProductData] = useState<{
    quantity: number;
    discount_price: number;
    current_price: number;
    sku: string;
    expiration_date: string;
    series: string;
  }>({
    quantity: 0,
    current_price: 0,
    discount_price: 0,
    sku: '',
    expiration_date: '',
    series: '',
  });

  const editHandler = (sku: string) => {
    editedItemIndex === sku ? setEditedItemIndex('') : setEditedItemIndex(sku);
  };

  const handleClick = (sku: string) => {
    setProductData(el);
    editHandler(sku);
  };

  const handleUpdateCost = () => {
    dispatch(
      actions.updateCost.request({
        saleInfo: productData,
        postEffect: () => {
          dispatch(
            actions.getSalesProducts.request({
              networkCode:
                role === UserRole.MANAGER || role === UserRole.PHARMACY_MANAGER
                  ? currentPharmacy?.code
                  : networkCode,
            })
          );
          setEditedItemIndex(null);
        },
      })
    );
  };

  const handleDeleteProduct = () => {
    const { sku } = productData;
    dispatch(
      actions.deleteProduct.request({
        sku: { sku },
        postEffect: () => {
          dispatch(
            actions.getSalesProducts.request({
              networkCode:
                role === UserRole.MANAGER || role === UserRole.PHARMACY_MANAGER
                  ? currentPharmacy?.code
                  : networkCode,
            })
          );
          setIsModalOpen(!isModalOpen);
          setEditedItemIndex('');
        },
      })
    );
  };

  const handleNotDeleteProduct = () => {
    setIsModalOpen(!isModalOpen);
    setEditedItemIndex('');
  };
  return (
    <>
      <tr key={el.sku}>
        <td className="product-item item-column">
          <div>{el.pharmacy_product}</div>
        </td>
        <td className="product-item item-column">
          <div>{el.minPrice}</div>
        </td>
        <td className="product-item item-column">
          {editedItemIndex === el.sku ? (
            <input
              type="text"
              onChange={(e) => {
                const value = e.target.value.trim();
                if (value !== '' && !Number.isNaN(parseInt(value, 10))) {
                  setProductData({
                    ...productData,
                    current_price: parseInt(value, 10),
                  });
                } else {
                  setProductData({ ...productData, current_price: 0 });
                }
              }}
              value={productData?.current_price}
            />
          ) : (
            el.current_price
          )}
        </td>
        <td className="product-item item-column">
          {el.sku.split('_')[3].split('-').reverse().join('.')}
        </td>
        <td className="product-item item-column">
          {editedItemIndex === el.sku ? (
            <input
              type="text"
              onChange={(e) => {
                const value = e.target.value.trim();
                setProductData({
                  ...productData,
                  series: value,
                });
              }}
              value={productData?.series}
            />
          ) : (
            el.series
          )}
        </td>
        <td className="product-item item-column">
          {editedItemIndex === el.sku ? (
            <input
              type="text"
              onChange={(e) => {
                const value = e.target.value.trim();
                if (value !== '' && !Number.isNaN(parseInt(value, 10))) {
                  setProductData({
                    ...productData,
                    discount_price: parseInt(value, 10),
                  });
                } else {
                  setProductData({ ...productData, discount_price: 0 });
                }
              }}
              value={productData?.discount_price}
            />
          ) : (
            el.discount_price
          )}
        </td>
        <td className="product-item item-column">{el.comment}</td>
        <td className="product-item item-edit">
          {editedItemIndex === el.sku ? (
            <div
              onClick={handleUpdateCost}
              className="save-edit-btn"
              style={{ width: '100%' }}
            >
              {t('SourcePage.SAVE-BTN')}
            </div>
          ) : (
            <>
              <div
                className="pencil-icon-container"
                onClick={() => handleClick(el.sku)}
              >
                <img src={editIcon} alt="edit icon" />
              </div>
              <div
                onClick={() => {
                  handleClick(el.sku);
                  setIsModalOpen(!isModalOpen);
                }}
              >
                <img src={deleteIcon} alt="delete" className="delete-icon" />
              </div>
            </>
          )}
        </td>
      </tr>
      {isModalOpen && (
        <ModalWindow onClose={handleNotDeleteProduct}>
          <h3>{t('SALESPAGE.MODAL_WINDOW.TITLE')}</h3>
          <div className="button-row">
            <Button text="да" onClick={handleDeleteProduct} />
            <Button text="нет" onClick={handleNotDeleteProduct} />
          </div>
        </ModalWindow>
      )}
    </>
  );
};

export default SalesProductsTable;
