import styled from 'styled-components';
import { baseTheme } from '../../../../global/styles/theme';

export const Container = styled('div')`
  margin-top: -50px;
`;

export const ProductsTableType = styled('div')`
  display: flex;
  padding: 20px 0 0 5px;

  .product-type {
    padding: 12px;
    border-radius: 4px 4px 0 0;
    border: 1px solid ${baseTheme.colors.stroking};
    color: ${baseTheme.colors.secondary};
    border-bottom: 0;
    margin-right: 4px;
    width: 256px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .type-active {
    background-color: ${baseTheme.colors.mainLightBlue};
    color: ${baseTheme.colors.white};
  }
`;

export const ProductsTableDescription = styled('div')`
  font-size: 18px;
  font-weight: bold;

  .confirmation-text {
    margin-bottom: 10px;
  }

  .confirmation-btn {
    display: inline;
    margin: 0px 10px;
    width: 42px;
    height: 26px;
    padding: 2px 10px;
    color: ${baseTheme.colors.white};
    background-color: ${baseTheme.colors.focus};
    border-color: ${baseTheme.colors.focus};
    font-size: 14px;
    border-radius: 8px;
    border: 2px solid ${baseTheme.colors.mainLightBlue};
    cursor: pointer;
  }

  .warning-text {
    color: ${baseTheme.colors.error};
    margin-bottom: 10px;
  }
`;

export const ProductsTableContainerMain = styled('div')`
  border: 1px solid ${baseTheme.colors.stroking};
  border-radius: 6px;
  padding: 31px 24px;
  width: 100%;
  border-collapse: separate;
  margin-bottom: 20px;

  .products-search {
    padding-bottom: 24px;

    div:first-child {
      max-width: 370px;
    }

    display: flex;
  }

  .global-search-container {
    margin: 15px 0px 0px 10px;
  }
`;

export const ProductsTableContainer = styled('table')`
  width: 100%;
  border-collapse: separate;

  .search-no-results {
    td {
      padding-top: 16px;
    }
  }
`;

export const ProductsTableHeaderRow = styled('tr')`
  .table__header-item {
    font-size: 16px;
    color: ${baseTheme.colors.secondary};
    font-weight: 400;
    text-align: start;
    padding-bottom: 16px;
    max-width: 180px !important;
    border-bottom: 1px solid ${baseTheme.colors.stroking};
  }

  .item-bigger {
    width: 28%;
  }

  .table__header-item-second {
    font-size: 16px;
    color: ${baseTheme.colors.secondary};
    font-weight: 400;
    text-align: start;
    padding-bottom: 16px;
    width: 14%;
    border-bottom: 1px solid ${baseTheme.colors.stroking};

    span {
      font-size: 14px !important;
    }
  }

  .item-small {
    width: 10%;
    padding-right: 10px;
  }

  .item-bigger-second {
    width: 30% !important;
  }
`;

export const ProductsTable = styled('tbody')`
  width: 100%;

  .products-loading {
    padding-top: 32px;
  }
`;

export const FilterTags = styled('div')`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;

  .reset-tag {
    cursor: pointer;
    margin-left: 16px;
    font-weight: 600;
    color: ${baseTheme.colors.error};
    font-size: 14px;
  }

  .tags-block {
    max-width: none !important;
    display: flex;
    align-items: center;

    .set-tag {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-left: 18px;
      overflow-y: hidden;
      width: 80px;

      img {
        height: 10px !important;
      }

      .text {
        margin-right: 12px;
        color: ${baseTheme.colors.secondary};
      }
    }

    .filter-info {
      margin-left: 8px;
      display: flex;
      align-items: center;
      width: 85%;
      img {
        cursor: pointer;
        height: 20px;
        margin-bottom: -5px;
      }
      .filter-by {
        color: ${baseTheme.colors.secondary} !important;
      }

      span {
        color: ${baseTheme.colors.mainLightBlue};
      }
    }
  }
`;
