import styled from 'styled-components';

export const PromoCodePageContainer = styled('div')`
  display: flex;
  flex-direction: column;
  max-width: 1216px;
  margin: 40px auto;

  .title {
    font-weight: 700;
    font-size: 32px;
    padding-top: 32px;
    color: #121f30;
  }

  .title-accent {
    font-weight: 700;
    font-size: 32px;
    color: #121f30;
  }

  .flex-container {
    margin-top: 32px;
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .filter-container {
    cursor: pointer;
  }

  .select {
    border-radius: 5px;
    padding: 5px;
  }
`;
