import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { selectors } from './reducer';
import { selectors as selectorsLogin } from '../LoginPage/reducer';
import { selectors as selectorsManager } from '../OrdersPage/reducer';
import * as actions from './actions';
import * as managerActions from '../OrdersPage/actions';
import * as Styled from './OperatorsPage.styles';
import UserTable from '../../components/UserTable';
import OperatorsBreadCrumbs from './components/OperatorsBreadCrumbs';
import { getFromLocalStorage } from '../../global/helpers';
import { UserRole } from '../OrdersPage/types';
import PharmacistBlock from './components/PharmacistBlock';

const OperatorsPharmacistPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const role = getFromLocalStorage('role');

  const [newUser, addNewUser] = useState<boolean>(false);
  const operators = useSelector(selectors.operatorsData);
  const sources = useSelector(selectorsManager.sourcesData);
  const networkCode = useSelector(selectorsLogin.userData)?.networkCode;

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  useEffect(() => {
    role === UserRole.ADMIN && dispatch(actions.getOperators.request());
    !sources &&
      networkCode &&
      (role === UserRole.MANAGER || role === UserRole.PHARMACY_MANAGER) &&
      dispatch(managerActions.getSourcesByNetwork.request({ networkCode }));
  }, [dispatch, networkCode, sources]);

  const handleAddNewUser = () => {
    addNewUser(true);
  };

  const handleNoNewUser = () => {
    addNewUser(false);
  };

  const operatorsContent = (
    <>
      <OperatorsBreadCrumbs />
      <Styled.OperatorsPageContainer>
        <div className="roles">{t('SourcePage.ROLE')}</div>
        {operators ? (
          <Styled.UsersBlock>
            <UserTable
              data={operators}
              newUser={newUser}
              onAddNewUser={handleNoNewUser}
            />
          </Styled.UsersBlock>
        ) : (
          <CircularProgress />
        )}
        <Styled.Button onClick={handleAddNewUser}>
          {t('SourcePage.ADD')}
        </Styled.Button>
      </Styled.OperatorsPageContainer>
    </>
  );

  const pharmacistContent = (
    <Styled.OperatorsPageContainer>
      {sources?.map((source) => (
        <PharmacistBlock
          sourceCode={source.code}
          sourceName={source.name}
          networkCode={networkCode}
          expanded={expanded}
          handleChange={handleChange}
        />
      ))}
    </Styled.OperatorsPageContainer>
  );

  return (
    <>
      {role === UserRole.ADMIN && operatorsContent}
      {(role === UserRole.MANAGER || role === UserRole.PHARMACY_MANAGER) &&
        pharmacistContent}
    </>
  );
};

export default OperatorsPharmacistPage;
