import axios from 'axios';
import { N8nParams } from './saga';

export class OrderService {
  static API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/api/v1/`;

  static API_UPGRADE_URL = `${process.env.REACT_APP_API_BASE_URL}/api/v2/`;

  static API_SEARCH_BASE_ULR = `${process.env.REACT_APP_SEARCH_URL}/rest/default/V1/products?`;

  static getProducts = async (searchValue: string) => {
    const response = await axios({
      method: 'get',
      url: `${OrderService.API_SEARCH_BASE_ULR}searchCriteria[page_size]=50&searchCriteria[filter_groups][0][filters][0][field]=name&searchCriteria[filter_groups][0][filters][0][value]=%25${searchValue}%25&searchCriteria[filter_groups][0][filters][0][condition_type]=like`,
    });
    return response.data;
  };

  static getAllStatuses = async (token: string) => {
    const response = await axios({
      method: 'get',
      url: `${OrderService.API_UPGRADE_URL}orders/statuses`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  static getAllStatusesPharmacy = async (token: string) => {
    const response = await axios({
      method: 'get',
      url: `${OrderService.API_UPGRADE_URL}orders/pharmacy_statuses`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  static putOrder = async (orderInfo: any, id: any) => {
    const response = await axios({
      method: 'put',
      url: `${OrderService.API_BASE_URL}orders/${id}/edit`,
      data: orderInfo,
    });
    return response.data;
  };

  static postRecipe = async (token: string, data: any) => {
    const formData = new FormData();
    formData.append('file', data);

    if (formData) {
      const response = await axios({
        method: 'post',

        url: `${OrderService.API_BASE_URL}users/receipts`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      });

      return response.data;
    }
  };

  static getProductsBySkus = async (skus: string[]) => {
    const response = await axios({
      method: 'get',
      url: `${
        process.env.REACT_APP_SEARCH_URL
      }/rest/default/V1/products?searchCriteria[filter_groups][0][filters][0][field]=sku&searchCriteria[filter_groups][0][filters][0][value]=${skus.join()}&searchCriteria[filter_groups][0][filters][0][condition_type]=in`,
    });
    return response.data;
  };

  static getApproximatePrice = async (skuArray: string[]) => {
    const response = await axios({
      method: 'post',
      url: `${OrderService.API_BASE_URL}prices`,
      data: JSON.stringify(skuArray),
      headers: { 'Content-Type': 'text/plain' },
    });
    return response.data;
  };

  static n8nPrice = async (data: N8nParams) => {
    const response = await axios({
      method: 'post',
      url: `${OrderService.API_UPGRADE_URL}orders/delivery/fact_price`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data,
    });
    return response.data;
  };

  static applyPromoCode = async (id: string, data: string) => {
    const response = await axios({
      method: 'post',
      url: `${OrderService.API_UPGRADE_URL}admin/orders/${id}/apply-promocode`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: {
        promocode: data,
      },
    });
    return response.data;
  };

  static sendEdit = async (
    id: string,
    data: [
      {
        name: 'string';
        need_receipt: true;
        other_price: true;
        out_of_stock: true;
        packing_diff: true;
        sku: 'string';
        term: true;
      }
    ]
  ) => {
    const response = await axios({
      method: 'post',
      url: `${OrderService.API_UPGRADE_URL}orders/${id}/send_edit`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        items: data,
      },
    });
    return response.data;
  };

  static getPriceWithQuantityBySource = async (
    source: string,
    orderId: string,
    array: string[],
    wareId?: boolean
  ) => {
    const data = wareId
      ? {
          order_id: orderId,
          ware_ids: array.filter((el) => el !== ''),
        }
      : {
          order_id: orderId,
          skus: array.filter((el) => el !== ''),
        };
    try {
      const response = await axios({
        method: 'post',
        url: `${OrderService.API_BASE_URL}prices/${source}`,
        data,
        headers: { 'Content-Type': 'text/plain' },
      });
      return response.data;
    } catch (error) {
      return [];
    }
  };

  static changeOrderTimer = async (orderId: string, timerInfo: any) => {
    const response = await axios({
      method: 'put',
      url: `${OrderService.API_BASE_URL}orders/${orderId}/timer`,
      data: timerInfo,
    });
    return response.data;
  };

  static getProductsTags = async (token: string, wereId: string) => {
    const url = `${OrderService.API_BASE_URL}mappings/search?type=verified&q=${wereId}&offset=0&limit=1`;
    const response = await axios({
      method: 'get',
      url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  };

  static getInterpayPayouts = async (token: string, orderId: string) => {
    const response = await axios({
      method: 'get',
      url: `${OrderService.API_UPGRADE_URL}partner-payouts/${orderId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  static createInterpayPayouts = async (
    orderId: string,
    time: string,
    token: string
  ): Promise<any> => {
    const response = await axios({
      method: 'post',
      url: `${OrderService.API_UPGRADE_URL}partner-payouts/new`,
      data: JSON.stringify({
        order_id: orderId,
        time,
      }),
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  };

  static changeInterpayPayouts = async (
    token: string,
    orderId: string,
    status: string,
    comment: string,
    payoutInfo: string,
    sum?: number,
    payoutDate?: string,
    createdAt?: string
  ): Promise<any> => {
    const data = {
      order_id: orderId,
      status,
      payout_info: payoutInfo,
      ...(sum && { sum }),
      comment,
      ...(createdAt && { created_at: createdAt }),
      ...(payoutDate && { payout_date: payoutDate }),
    };

    const response = await axios({
      method: 'post',
      url: `${OrderService.API_UPGRADE_URL}partner-payouts/upsert`,
      data: JSON.stringify(data),
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data;
  };

  static getClientComment = async (
    token: string,
    phone: string,
    comment: string
  ) => {
    const response = await axios({
      method: 'put',
      url: `${OrderService.API_BASE_URL}users/${phone}/definition`,
      data: JSON.stringify({
        new_definition: comment,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };
}
