import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  NOTIFICATION_CHANGES_NOT_SAVED,
  NOTIFICATION_CHANGES_SAVED,
} from '../../global/constants';
import * as actionsNotification from '../InitComponent/actions';
import * as actions from './actions';
import { formatMagentaResponse, formatWebStockProductsData } from './helpers';
import { WebStockService } from './service';
import { WebStockProductFormatedData } from './types';
import { OrdersService } from '../OrdersPage/service';
import { ProductResponseItemCache } from '../OrdersPage/types';

interface GetWebStockProductsSagaPayload {
  type: string;
  payload: { networkCode: string };
}

interface EditWebStockProductSagaPayload {
  type: string;
  payload: {
    networkCode: string;
    editedProduct: WebStockProductFormatedData;
    postEffect: () => void;
  };
}

interface GetProductsBySkuSagaPayload {
  type: string;
  payload: {
    webStockProductsData: WebStockProductFormatedData[];
    productsSkuList: string[];
  };
}
export function* getWebStockProductsSaga({
  payload,
}: GetWebStockProductsSagaPayload) {
  try {
    const response: { result: WebStockProductFormatedData[] } = yield call(
      WebStockService.getWebStockProducts,
      payload.networkCode
    );

    const formatedWebStockProducts = formatWebStockProductsData(
      response.result
    );

    yield put(actions.getWebStockProducts.success(formatedWebStockProducts));
  } catch (err) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
  }
}

export function* getProductsBySkuSaga({
  payload,
}: GetProductsBySkuSagaPayload) {
  try {
    const magentaResponse: {
      result: ProductResponseItemCache[];
    } = yield call(OrdersService.getProductsBySkus, payload.productsSkuList);

    const formateSearchProductList = formatMagentaResponse(
      magentaResponse,
      payload.webStockProductsData
    );

    yield put(actions.getProductsBySku.success(formateSearchProductList));
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
  }
}

export function* editWebStockProductSaga({
  payload,
}: EditWebStockProductSagaPayload) {
  try {
    const response: { result: string } = yield call(
      WebStockService.editWebStockProducts,
      payload.networkCode,
      payload.editedProduct
    );

    if (payload.postEffect) {
      payload.postEffect();
    }

    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_SAVED,
        canBeClosed: true,
        type: 'success',
      })
    );

    yield put(actions.updateWebStockProduct.success(response.result));
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
  }
}

export function* webStockPageWatcherSaga() {
  yield all([
    takeLatest(actions.getWebStockProducts.REQUEST, getWebStockProductsSaga),
    takeLatest(actions.updateWebStockProduct.REQUEST, editWebStockProductSaga),
    takeLatest(actions.getProductsBySku.REQUEST, getProductsBySkuSaga),
  ]);
}
