import styled from 'styled-components';
import { baseTheme } from '../../global/styles/theme';

export const OrderCancelModalContent = styled('div')`
  width: 360px;

  .modal-title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  .modal-subtitle {
    font-size: 16px;
    font-weight: 400;
    color: ${baseTheme.colors.secondary};
    margin-bottom: 32px;
  }

  .cancel-reason__title {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 24px;
  }

  .controls-block {
    margin-top: 32px;
    display: flex;

    .decline-btn__container {
      width: 80px;
      margin-right: 12px;
    }

    .submit-btn__container {
      width: 170px;
      position: relative;

      .error-tooltip {
        background-color: #f6f9ff;
        filter: drop-shadow(0px 1px 8px rgba(0, 58, 136, 0.15));
        border-radius: 4px;
        color: #121f30;
        font-size: 16px;
        font-weight: 400;
        padding: 8px 16px;
        position: absolute;
        top: -43px;
        white-space: nowrap;
      }
    }
  }
`;
