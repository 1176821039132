import { Formik, FormikErrors } from 'formik';
import React, {
  FormEventHandler,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CustomToggle from '../../components/CustomToggle';
import Loader from '../../components/Loader';
import * as Styled from './WebStockPage.styles';
import * as actions from './actions';
import { selectors } from './reducer';
import {
  SetActionType,
  WebStockFormatedPharmacy,
  WebStockProductData,
  WebStockProductFormatedData,
  WebStockProductsTabOption,
  filterInStockOptionsValues,
  filterNameOptionsValues,
} from './types';

import SearchBar from '../../components/SearchBar';
import { getFromLocalStorage, setToLocalStorage } from '../../global/helpers';
import arrow from '../../global/media/arrow-breadCrumbs.svg';
import { selectors as userSelectors } from '../LoginPage/reducer';
import { sort as sortByAlphabet } from '../OrdersPage/components/OrdersTable/components/FilterBySource/helpers';
import { UserRole } from '../OrdersPage/types';
import SelectModal from './components/SelectModal/SelectModal';
import { WebStockPharmacyModal } from './components/WebStockPharmacyModal/WebStockPharmacyModal';
import {
  filterInStockOptions,
  filterNameOptions,
  formatWebStockProductDataForRequest,
  getArchivalWebStockProducts,
  getNewWebStockProducts,
  getOnSaleWebStockProducts,
  getProductsBySearchValue,
  getSortedProductsOnSearch,
  webStockProductsSchema,
  webStockProductsSchemaCommon,
} from './helpers';
import * as actionService from '../EditPage/actions';

const WebStockPage = () => {
  const { t } = useTranslation();
  const [editedItemIndex, setEditedItemIndex] = useState<null | number>(null);
  const [previousFormData, setPreviousFormData] = useState<
    null | WebStockProductFormatedData[]
  >(null);
  const [filterNameOpen, setFilterNameOpen] = useState<boolean>(false);
  const [filterInStockOpen, setFilterInStockOpen] = useState<boolean>(false);
  const [currentFilterNameOption, setCurrentFilterNameOption] =
    useState<string>(filterNameOptionsValues.withoutSorting);
  const [currentInStockFilterOption, setCurrentInStockFilterOption] =
    useState<string>(filterInStockOptionsValues.all);
  const webStockProductsData = useSelector(selectors.webStockProductsData);
  const webStockProductsLoading = useSelector(
    selectors.webStockProductsLoading
  );
  const searchProductList = useSelector(selectors.searchProductsData);
  const searchProductListLoading = useSelector(selectors.searchProductsLoading);
  const dispatch = useDispatch();
  const userData = useSelector(userSelectors.userData);
  const networkCode =
    getFromLocalStorage('networkData')?.code || userData?.sourceCode;
  const role = getFromLocalStorage('role');
  const [isPharmaciesModalOpen, setIsPharmaciesModalOpen] =
    useState<boolean>(false);
  const [currentPharmacy, setCurrentPharmacy] =
    useState<null | WebStockFormatedPharmacy>(null);
  const selectedPharmacy = getFromLocalStorage('selectedWebStockPharmacy');
  const [sortedWebStockProductsData, setSortedWebStockProductsData] = useState<
    WebStockProductData[] | null | WebStockProductFormatedData[]
  >(webStockProductsData);
  const [currentTab, setCurrentTab] = useState(
    WebStockProductsTabOption.newProducts
  );
  const [searchProductValue, setSearchProductValue] = useState<string>('');

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (
        searchProductValue.length >= 3 &&
        currentTab === WebStockProductsTabOption.newProducts
      ) {
        dispatch(
          actionService.getProductsSearchForPharmacy.request({
            searchValue: searchProductValue,
            postEffect: (productsSkuList: string[]) => {
              if (productsSkuList.length > 0) {
                dispatch(
                  actions.getProductsBySku.request({
                    productsSkuList,
                    webStockProductsData,
                  })
                );
              } else {
                dispatch(actions.getProductsBySku.success([]));
                if (webStockProductsData && webStockProductsData.length > 0) {
                  dispatch(
                    actions.getWebStockProducts.success(webStockProductsData)
                  );
                }
              }
            },
          })
        );
      }
    }, 2000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchProductValue, currentTab]);

  const getProductsByCurrentTab = (
    webStockProductsSortedData: WebStockProductFormatedData[]
  ) => {
    if (
      currentTab === WebStockProductsTabOption.newProducts &&
      webStockProductsSortedData
    ) {
      return getNewWebStockProducts(
        webStockProductsSortedData as WebStockProductFormatedData[]
      ) as WebStockProductData[];
    }

    if (
      currentTab === WebStockProductsTabOption.archivalProducts &&
      webStockProductsSortedData
    ) {
      return getArchivalWebStockProducts(
        webStockProductsSortedData as WebStockProductFormatedData[]
      ) as WebStockProductData[];
    }

    if (
      currentTab === WebStockProductsTabOption.onSaleProducts &&
      webStockProductsSortedData
    ) {
      return getOnSaleWebStockProducts(
        webStockProductsSortedData as WebStockProductFormatedData[]
      ) as WebStockProductData[];
    }

    return getNewWebStockProducts(
      webStockProductsSortedData as WebStockProductFormatedData[]
    ) as WebStockProductData[];
  };

  const onProductSearch = () => {
    const foundProductsList = getProductsBySearchValue(
      getProductsByCurrentTab(
        webStockProductsData as WebStockProductFormatedData[]
      ) as WebStockProductFormatedData[],
      searchProductValue
    );

    setSortedWebStockProductsData(
      getSortedProductsOnSearch(
        currentInStockFilterOption,
        currentFilterNameOption,
        foundProductsList
      )
    );
  };

  useEffect(() => {
    if (
      (!sortedWebStockProductsData ||
        sortedWebStockProductsData.length === 0) &&
      webStockProductsData
    ) {
      setSortedWebStockProductsData(
        getProductsByCurrentTab(
          webStockProductsData as WebStockProductFormatedData[]
        )
      );
    }
  }, [webStockProductsData]);

  useEffect(() => {
    let updatedSortedProductsList: WebStockProductData[] = [];

    if (
      currentInStockFilterOption === filterInStockOptionsValues.inStock &&
      webStockProductsData
    ) {
      updatedSortedProductsList = webStockProductsData?.filter(
        (product) => product.inStock
      );
    }
    if (
      currentInStockFilterOption === filterInStockOptionsValues.outOfStock &&
      webStockProductsData
    ) {
      updatedSortedProductsList = webStockProductsData?.filter(
        (product) => !product.inStock
      );
    }

    if (
      currentInStockFilterOption === filterInStockOptionsValues.all &&
      webStockProductsData
    ) {
      updatedSortedProductsList = [...webStockProductsData];
    }

    if (
      currentFilterNameOption === filterNameOptionsValues.sortByAlphabet &&
      updatedSortedProductsList
    ) {
      updatedSortedProductsList =
        updatedSortedProductsList?.sort(sortByAlphabet);
    }

    if (currentTab !== WebStockProductsTabOption.newProducts) {
      setSortedWebStockProductsData(
        getProductsBySearchValue(
          getProductsByCurrentTab(
            updatedSortedProductsList as WebStockProductFormatedData[]
          ) as WebStockProductFormatedData[],
          searchProductValue as string
        )
      );
    }
    if (currentTab === WebStockProductsTabOption.newProducts) {
      setSortedWebStockProductsData(
        getProductsByCurrentTab(
          updatedSortedProductsList as WebStockProductFormatedData[]
        ) as WebStockProductFormatedData[]
      );
    }
  }, [
    currentFilterNameOption,
    currentInStockFilterOption,
    currentPharmacy,
    currentTab,
    webStockProductsData,
    searchProductValue,
  ]);

  useEffect(() => {
    if (
      (role === UserRole.PHARMACIST && networkCode) ||
      ((role === UserRole.MANAGER || role === UserRole.PHARMACY_MANAGER) &&
        currentPharmacy?.code)
    )
      dispatch(
        actions.getWebStockProducts.request({
          networkCode:
            role === UserRole.MANAGER || role === UserRole.PHARMACY_MANAGER
              ? currentPharmacy?.code
              : networkCode,
        })
      );
  }, [networkCode, currentPharmacy]);

  const onSubmitHandler = (props: WebStockProductData[]) => {
    const isAdding = currentTab === WebStockProductsTabOption.newProducts;

    if (editedItemIndex || editedItemIndex === 0) {
      dispatch(
        actions.updateWebStockProduct.request({
          networkCode:
            role === UserRole.MANAGER || role === UserRole.PHARMACY_MANAGER
              ? currentPharmacy?.code
              : networkCode,
          editedProduct: formatWebStockProductDataForRequest(
            currentTab === WebStockProductsTabOption.newProducts
              ? { ...props[editedItemIndex], quantity: 1 }
              : props[editedItemIndex],
            isAdding
          ),
          postEffect: () => {
            dispatch(
              actions.getWebStockProducts.request({
                networkCode:
                  role === UserRole.MANAGER ||
                  role === UserRole.PHARMACY_MANAGER
                    ? currentPharmacy?.code
                    : networkCode,
              })
            );
          },
        })
      );
      setEditedItemIndex(null);
    }
  };

  useEffect(() => {
    if (
      (role === UserRole.MANAGER || role === UserRole.PHARMACY_MANAGER) &&
      !selectedPharmacy
    ) {
      setIsPharmaciesModalOpen(true);
    }
    if (selectedPharmacy) {
      setCurrentPharmacy(selectedPharmacy);
    }
  }, []);

  const onCancelEdit = (
    e: React.MouseEvent<HTMLButtonElement>,
    setValues: SetActionType
  ) => {
    if (previousFormData) {
      setValues(previousFormData);
    }
    setEditedItemIndex(null);
    e.preventDefault();
  };

  const onEdit = (
    values: WebStockProductFormatedData[] | null,
    index: number | null
  ) => {
    setPreviousFormData(values);
    setEditedItemIndex(index);
  };

  useEffect(() => {
    onEdit(null, null);
  }, [currentTab]);

  interface OrderFormProps {
    values: WebStockProductFormatedData[];
    setFieldValue: (field: string, value: string | number | boolean) => void;
    handleSubmit: FormEventHandler<HTMLFormElement>;
    setValues: SetActionType;
    errors: FormikErrors<WebStockProductFormatedData[]>;
  }

  const onClosePharmaciesModal = () => {
    setIsPharmaciesModalOpen(false);
  };

  const onPharmacyClick = (pharmacy: WebStockFormatedPharmacy) => {
    setCurrentPharmacy(pharmacy);
    setIsPharmaciesModalOpen(false);
    setToLocalStorage('selectedWebStockPharmacy', pharmacy);
  };

  function removeImgBrokenIcon(event: SyntheticEvent<HTMLImageElement, Event>) {
    const brokenImage = event.target as HTMLImageElement;
    const tagName: string = 'alt';

    const brokenImageAttributeValue = brokenImage.getAttribute(
      tagName
    ) as string;

    const alternateTextFromBrokenImage = document.createTextNode(
      brokenImageAttributeValue
    );

    brokenImage.parentNode?.insertBefore(
      alternateTextFromBrokenImage,
      brokenImage
    );

    brokenImage.parentNode?.removeChild(brokenImage);
  }

  const orderForm = ({
    values,
    setFieldValue,
    handleSubmit,
    setValues,
    errors,
  }: OrderFormProps) => {
    return (
      <form onSubmit={handleSubmit}>
        <Styled.TableBodyContainer>
          {values.map((item: WebStockProductFormatedData, index: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <div style={{ width: '100%', display: 'flex' }} key={index}>
              <div className="table-cell table-cell-lg name-container">
                <input
                  value={item.name}
                  onChange={(e) =>
                    setFieldValue(`${index}.name`, e.target.value)
                  }
                  name="name"
                  disabled
                />
                <p className="manufacturer">
                  {t('ProductsPage.HeaderTitles.MANUFACTURER')}:{' '}
                  <span className="manufacturer-name">{item.manufacturer}</span>
                </p>
                <img
                  width="120px"
                  height="120px"
                  src={`https://db-images.object.pscloud.io/optimized_v4_img_middle250_${item.sku}.webp`}
                  onError={(event) => removeImgBrokenIcon(event)}
                  alt=""
                />
                <a
                  className="product-link"
                  target="_blank"
                  rel="noreferrer"
                  href={`https://daribar.kz/products/${item?.link}--${item.sku} `}
                >
                  {item.sku}
                </a>
              </div>
              <div className="table-cell">
                <input
                  className={`${editedItemIndex === index && 'price-input'} ${
                    errors[index]?.price &&
                    editedItemIndex === index &&
                    'error-input'
                  }`}
                  style={{ textAlign: 'center' }}
                  value={item.price}
                  onChange={(e) => {
                    const regex = /^(?:\d+|0)?$/;
                    if (regex.test(e.target.value)) {
                      setFieldValue(`${index}.price`, e.target.value);
                    }
                  }}
                  name="price"
                  disabled={editedItemIndex !== index}
                />
                {errors[index]?.price && editedItemIndex === index && (
                  <div className="error-msg">{errors[index]?.price}</div>
                )}
              </div>

              {!userData?.sourceCode?.includes('food') &&
                currentTab !== WebStockProductsTabOption.newProducts && (
                  <div className="table-cell" style={{ width: '25%' }}>
                    {editedItemIndex === index ? (
                      <div style={{ width: '20%' }}>
                        <CustomToggle
                          onChange={() => {
                            setFieldValue(`${index}.inStock`, !item.inStock);
                            if (!item.inStock) {
                              setFieldValue(`${index}.quantity`, 1);
                            } else {
                              setFieldValue(`${index}.quantity`, 0);
                            }
                          }}
                          id="inStock"
                          variant="green"
                          checked={item.inStock}
                        />
                      </div>
                    ) : (
                      <span>{item.inStock ? 'да' : 'нет'}</span>
                    )}
                  </div>
                )}
              {userData?.sourceCode?.includes('food') && (
                <div
                  className="table-cell"
                  style={{
                    width:
                      currentTab !== WebStockProductsTabOption.newProducts
                        ? '20%'
                        : '20%',
                  }}
                >
                  <input
                    className={`${editedItemIndex === index && 'price-input'} ${
                      errors[index]?.quantity &&
                      editedItemIndex === index &&
                      'error-input'
                    }`}
                    style={{ textAlign: 'center' }}
                    value={item.quantity}
                    onChange={(e) => {
                      const regex = /^(?:\d+|0)?$/;
                      if (regex.test(e.target.value)) {
                        setFieldValue(`${index}.quantity`, e.target.value);
                      }
                    }}
                    name="quantity"
                    disabled={editedItemIndex !== index}
                  />
                  {errors[index]?.quantity && editedItemIndex === index && (
                    <div className="error-msg">{errors[index]?.quantity}</div>
                  )}
                </div>
              )}
              <div className="table-cell table-cell-sm">
                {item.updatedAt && `${item.updatedAt}/`}
                <br />
                {item.updatedBy && item.updatedBy}
                {!item.updatedAt && !item.updatedBy && 'не указано'}
              </div>
              <div
                style={{ flexDirection: 'column', gap: '20px' }}
                className="table-cell table-cell-md"
              >
                {editedItemIndex !== index ? (
                  <div
                    className={`assembly-btn 
                                    ${
                                      (editedItemIndex ||
                                        editedItemIndex === 0) &&
                                      editedItemIndex !== index &&
                                      'disabled'
                                    }`}
                    onClick={() => onEdit(values, index)}
                    style={{ cursor: 'pointer' }}
                  >
                    {t('WebStockPage.TABLE.BODY.BUTTONS.EDIT')}
                  </div>
                ) : (
                  <>
                    <button
                      type="submit"
                      className={`assembly-btn ${
                        (errors[editedItemIndex] ||
                          values[editedItemIndex].price === 0) &&
                        'disabled'
                      } save-button`}
                      style={{ cursor: 'pointer' }}
                      onClick={() => onSubmitHandler(values)}
                    >
                      {t('WebStockPage.TABLE.BODY.BUTTONS.SAVE')}
                    </button>
                    <button
                      type="button"
                      className="assembly-btn cancel-button"
                      onClick={(e) => onCancelEdit(e, setValues)}
                    >
                      {t('WebStockPage.TABLE.BODY.BUTTONS.CANCEL')}
                    </button>
                  </>
                )}
              </div>
            </div>
          ))}
        </Styled.TableBodyContainer>
      </form>
    );
  };
  const tableHeader = (
    <Styled.WebStockPageTableHeaderContainer>
      <div className="header-row header-row-lg">
        {t('WebStockPage.TABLE.HEADER.ORDER_NAME')}
        <Styled.StyledFilterTitle
          onClick={() => setFilterNameOpen(!filterNameOpen)}
        >
          <span>
            {
              filterNameOptions.find(
                (option) => option.value === currentFilterNameOption
              )?.name
            }
          </span>
          <div className="img">
            <img src={arrow} alt="arrow" />
          </div>
          {filterNameOpen && (
            <SelectModal
              setCurrentFilterOption={setCurrentFilterNameOption}
              selectOptionsList={filterNameOptions}
              onClose={() => setFilterNameOpen(!filterNameOpen)}
            />
          )}
        </Styled.StyledFilterTitle>
      </div>
      <div className="header-row">
        {t('WebStockPage.TABLE.HEADER.ORDER_PRICE')}
      </div>
      {!userData?.sourceCode?.includes('food') &&
        currentTab !== WebStockProductsTabOption.newProducts && (
          <div className="header-row">
            {t('WebStockPage.TABLE.HEADER.ORDER_IN_STOCK')}
            <Styled.StyledFilterTitle
              onClick={() => setFilterInStockOpen(!filterInStockOpen)}
            >
              {currentTab !== WebStockProductsTabOption.onSaleProducts &&
                currentTab !== WebStockProductsTabOption.archivalProducts && (
                  <>
                    <span>
                      {
                        filterInStockOptions.find(
                          (option) =>
                            option.value === currentInStockFilterOption
                        )?.name
                      }
                    </span>
                    <div className="img">
                      <img src={arrow} alt="arrow" />
                    </div>
                  </>
                )}
              {filterInStockOpen && (
                <SelectModal
                  setCurrentFilterOption={setCurrentInStockFilterOption}
                  selectOptionsList={filterInStockOptions}
                  onClose={() => setFilterInStockOpen(!filterInStockOpen)}
                />
              )}
            </Styled.StyledFilterTitle>
          </div>
        )}
      {userData?.sourceCode?.includes('food') && (
        <div className="header-row header-row-sm">Количество</div>
      )}
      <div className="header-row header-row-sm">Кем/когда обновлен</div>
      <div className="header-row header-row-md">
        {t('WebStockPage.TABLE.HEADER.ORDER_ACTION')}
      </div>
    </Styled.WebStockPageTableHeaderContainer>
  );

  return (
    <>
      {(webStockProductsLoading || searchProductListLoading) && <Loader />}
      {isPharmaciesModalOpen && userData?.pharmacyList && (
        <WebStockPharmacyModal
          onPharmacyClick={onPharmacyClick}
          pharmaciesList={userData?.pharmacyList}
          onClosePharmaciesModal={onClosePharmaciesModal}
        />
      )}
      {sortedWebStockProductsData && !isPharmaciesModalOpen && (
        <Styled.WebStockPageContainer>
          {(role === UserRole.MANAGER ||
            role === UserRole.PHARMACY_MANAGER) && (
            <h2 className="title">
              {' '}
              {t('WebStockPage.TITLE.PHARMACY')} {currentPharmacy?.name}
            </h2>
          )}
          {(role === UserRole.MANAGER ||
            role === UserRole.PHARMACY_MANAGER) && (
            <Styled.ButtonChangePharmacy
              onClick={() => setIsPharmaciesModalOpen(true)}
            >
              {t('WebStockPage.ACTION_BUTTONS.CHANGE_PHARMACY')}
            </Styled.ButtonChangePharmacy>
          )}
          {!userData?.sourceCode?.includes('food') && (
            <Styled.WebStockPageRulesTextContainer>
              <p className="title">
                {t('WebStockPage.ACTION_BUTTONS.USE_RULES.TITLE')}
              </p>
              <div className="rules-container">
                <p className="rule-item">
                  {t('WebStockPage.ACTION_BUTTONS.USE_RULES.FIRST_RULE')}
                </p>
                <p className="rule-item">
                  {t(
                    'WebStockPage.ACTION_BUTTONS.USE_RULES.SECOND_RULE.PRODUCT_CORRESPONDENCE'
                  )}
                  <span className="italics-text">
                    {t(
                      'WebStockPage.ACTION_BUTTONS.USE_RULES.SECOND_RULE.PRODUCT_CHARACTERISTICS'
                    )}
                  </span>
                  {t(
                    'WebStockPage.ACTION_BUTTONS.USE_RULES.SECOND_RULE.CONNECT'
                  )}
                  <span className="italics-text">
                    {t(
                      'WebStockPage.ACTION_BUTTONS.USE_RULES.SECOND_RULE.EXPIRATION_DATE'
                    )}
                  </span>
                </p>
                <p className="rule-item">
                  {t('WebStockPage.ACTION_BUTTONS.USE_RULES.THIRD_RULE')}
                </p>
                <p className="rule-item">
                  {t('WebStockPage.ACTION_BUTTONS.USE_RULES.ATTENTION')}
                </p>
              </div>
            </Styled.WebStockPageRulesTextContainer>
          )}
          <Styled.WebStockPageTabsContainer>
            <div
              className={`tab-item ${
                currentTab === WebStockProductsTabOption.newProducts &&
                'type-active'
              }`}
              onClick={() =>
                setCurrentTab(WebStockProductsTabOption.newProducts)
              }
            >
              {t('WebStockPage.TABS.NEW_PRODUCTS')}
            </div>
            <div
              className={`tab-item ${
                currentTab === WebStockProductsTabOption.archivalProducts &&
                'type-active'
              }`}
              onClick={() =>
                setCurrentTab(WebStockProductsTabOption.archivalProducts)
              }
            >
              {t('WebStockPage.TABS.ARCHIVAL_PRODUCTS')}{' '}
              {`(${
                getArchivalWebStockProducts(
                  webStockProductsData as WebStockProductFormatedData[]
                )?.length || '0'
              })`}
            </div>
            <div
              className={`tab-item ${
                currentTab === WebStockProductsTabOption.onSaleProducts &&
                'type-active'
              }`}
              onClick={() =>
                setCurrentTab(WebStockProductsTabOption.onSaleProducts)
              }
            >
              {t('WebStockPage.TABS.ON_SALE_PRODUCTS')}{' '}
              {`(${
                getOnSaleWebStockProducts(
                  webStockProductsData as WebStockProductFormatedData[]
                )?.length || '0'
              })`}
            </div>
          </Styled.WebStockPageTabsContainer>
          <div className="search-container">
            <div className="serch-field-container">
              <SearchBar
                value={searchProductValue}
                placeholder="Поиск товаров"
                setValue={(value) => setSearchProductValue(value)}
                onSearchClick={onProductSearch}
              />
              <p className="example-text">{t('WebStockPage.SEARCH_EXAMPLE')}</p>
            </div>
            {currentTab === WebStockProductsTabOption.newProducts && (
              <div className="search-captcha-container">
                <p className="captсha-title">
                  {t('WebStockPage.SEARCH_CAPTCHA.NEW_PRODUCTS.CAPTCHA_TITLE')}
                </p>
                <p className="actions-title">
                  {t('WebStockPage.SEARCH_CAPTCHA.NEW_PRODUCTS.ACTIONS_TITLE')}
                </p>
                <div className="rules-block">
                  <p className="single-rule">
                    {t(
                      'WebStockPage.SEARCH_CAPTCHA.NEW_PRODUCTS.RULES_LIST.FIRST_RULE'
                    )}
                  </p>
                  <p className="single-rule">
                    {t(
                      'WebStockPage.SEARCH_CAPTCHA.NEW_PRODUCTS.RULES_LIST.SECOND_RULE'
                    )}
                  </p>
                  <p className="single-rule">
                    {t(
                      'WebStockPage.SEARCH_CAPTCHA.NEW_PRODUCTS.RULES_LIST.THIRD_RULE'
                    )}
                  </p>
                </div>
                <p className="note-description">
                  {t(
                    'WebStockPage.SEARCH_CAPTCHA.NEW_PRODUCTS.NOTE.MAIN_DESCRIPTION'
                  )}{' '}
                  <span>
                    {t(
                      'WebStockPage.SEARCH_CAPTCHA.NEW_PRODUCTS.NOTE.BOLD_ON_SALE'
                    )}{' '}
                  </span>
                  .
                </p>
              </div>
            )}
            {currentTab === WebStockProductsTabOption.onSaleProducts && (
              <div
                className="search-captcha-container"
                style={{ margin: '0 auto' }}
              >
                <p className="captсha-title">
                  Здесь отображены товары, которые уже в продаже
                </p>
              </div>
            )}

            {currentTab === WebStockProductsTabOption.archivalProducts && (
              <div
                className="search-captcha-container"
                style={{ margin: '0 auto' }}
              >
                <p className="captсha-title">
                  Здесь отображены товары, которые сняты с продажи
                </p>
              </div>
            )}
          </div>
          {currentTab === WebStockProductsTabOption.newProducts ? (
            <h2 className="table-title">
              {t('WebStockPage.NEW_PRODUCTS_TITLE')}
            </h2>
          ) : (
            <div className="table-title" style={{ height: '69px' }} />
          )}
          {(sortedWebStockProductsData.length > 0 ||
            (searchProductValue?.length >= 3 &&
              searchProductList &&
              searchProductList?.length > 0)) && (
            <Styled.WebStockPageTableContainer>
              {tableHeader}

              <Formik
                initialValues={
                  (searchProductList &&
                    currentTab === WebStockProductsTabOption.newProducts &&
                    searchProductList?.length > 0 &&
                    searchProductValue.length >= 3 &&
                    getSortedProductsOnSearch(
                      filterInStockOptionsValues.all,
                      currentFilterNameOption,
                      searchProductList as WebStockProductFormatedData[]
                    )) ||
                  ((searchProductValue?.length < 3 &&
                    currentTab === WebStockProductsTabOption.newProducts) ||
                  currentTab !== WebStockProductsTabOption.newProducts
                    ? sortedWebStockProductsData
                    : [])
                }
                enableReinitialize
                validationSchema={
                  userData?.sourceCode?.includes('food')
                    ? webStockProductsSchemaCommon
                    : webStockProductsSchema
                }
                onSubmit={(props) => onSubmitHandler(props)}
              >
                {orderForm}
              </Formik>
            </Styled.WebStockPageTableContainer>
          )}
          {((searchProductValue.length >= 3 &&
            searchProductList?.length === 0 &&
            currentTab === WebStockProductsTabOption.newProducts) ||
            (searchProductValue?.length >= 3 &&
              currentTab !== WebStockProductsTabOption.newProducts &&
              sortedWebStockProductsData.length === 0)) && (
            <div className="empty-search-result-container">
              <p className="empty-search-result-text">
                {t('WebStockPage.NOT_FOUND_PRODUCTS')}
              </p>
            </div>
          )}
        </Styled.WebStockPageContainer>
      )}
    </>
  );
};

export default WebStockPage;
