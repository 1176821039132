import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getFromLocalStorage, setToLocalStorage } from '../../global/helpers';
import * as actionsNotification from '../InitComponent/actions';
import { UserRole } from '../OrdersPage/types';
import * as actions from './actions';
import {
  getFormatedPharmacyData,
  getFormatedResponse,
  mappedUserData,
  validationCodeErrorHandler,
} from './helpers';
import { LoginService } from './service';
import { ErrorCodeList } from './types';

interface LoginSagaParams {
  type: string;
  payload: { phone: string; validationCode: string; postEffect: any };
}
interface ValidationCodeSagaParams {
  type: string;
  payload: { phone: string };
}

interface UserSagaParams {
  type: string;
  payload: { token: string };
}
interface AuthResponse {
  status: string;
  result: {
    access_token: string;
    refresh_token: string;
  };
}

export function* authSaga({ payload }: LoginSagaParams) {
  try {
    const response: AuthResponse = yield call(
      LoginService.auth,
      payload.phone,
      payload.validationCode
    );
    setToLocalStorage('accessToken', response.result.access_token);
    setToLocalStorage('refreshToken', response.result.refresh_token);
    if (payload.postEffect) {
      yield call(payload.postEffect, response.result.access_token);
    }
    yield put(actions.auth.success(response.result));
  } catch (error) {
    const { response, config, message, request } = error as {
      response: {
        status: string;
        data: {
          code: string;
          error: string;
        };
      };
      config: {
        url: string;
      };
      message: string;
      request: {
        responseURL: string;
      };
    };
    if (response?.data?.code === ErrorCodeList.A401004) {
      yield put(
        actionsNotification.addApplicationNotification({
          content: `Пользователь не найден в базе данных, код ошибки: ${response?.data?.code}`,
          canBeClosed: true,
          type: 'error',
        })
      );
    }

    yield put(actions.auth.error(error));
  }
}

export function* getValidationCodeSaga({ payload }: ValidationCodeSagaParams) {
  try {
    yield call(LoginService.validatePhoneNumber, payload.phone);
    yield put(actions.getValidationCode.success());
  } catch (error) {
    const { response } = error as {
      response: {
        data: {
          error: string;
        };
        status: number;
      };
    };

    yield put(
      actionsNotification.addApplicationNotification({
        content: validationCodeErrorHandler(
          response.data.error,
          response.status.toString()
        ),
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.getValidationCode.error(error));
  }
}

export function* getUserDataSaga({ payload }: UserSagaParams) {
  try {
    // @ts-ignore
    const response = yield call(LoginService.getUserInfo, payload.token);
    const formatedResponse = getFormatedResponse(response);

    let userData;
    if (
      response.result.role.id === UserRole.CONTENT ||
      response.result.role.id === UserRole.PHARMACY_MANAGER ||
      response.result.role.id === UserRole.MANAGER
    ) {
      const pharmacyName: {
        result: { code: string; name: string; soft: string };
        status: string;
      } = yield call(
        LoginService.getNetworks,
        payload.token,
        response.result.values.network_code
      );

      const sourceName: {
        result: {
          code: string;
          name: string;
          magnum: boolean;
          soft: string;
          disabled: boolean;
        }[];
        status: string;
      } = yield call(LoginService.getSources, payload.token);

      const formatedPharmacyList = getFormatedPharmacyData(sourceName.result);

      userData = mappedUserData(
        formatedResponse.result,
        pharmacyName.result.name,
        false,
        pharmacyName.result.soft,
        formatedPharmacyList
      );
    } else if (formatedResponse.result.role.id === UserRole.PHARMACIST) {
      const sourceName: {
        result: {
          code: string;
          name: string;
          magnum: boolean;
          soft: string;
          disabled: boolean;
          city: string;
        };
        status: string;
      } = yield call(
        LoginService.getSources,
        payload.token,
        response.result.values.source_code
      );
      userData = mappedUserData(
        response.result,
        sourceName.result.name,
        sourceName.result.magnum,
        sourceName.result.soft,
        [],
        sourceName.result.disabled
      );
      setToLocalStorage('city', sourceName.result?.city);
    } else userData = mappedUserData(response.result);
    if (
      getFromLocalStorage('role') &&
      getFromLocalStorage('role') !== response.result.role.id &&
      getFromLocalStorage('accessToken')
    ) {
      setToLocalStorage('accessToken', null);
      setToLocalStorage('refreshToken', null);
      setToLocalStorage('education', false);
      window.location.href = '/login';
    }
    setToLocalStorage('role', response.result.role.id);
    setToLocalStorage('isCollector', response.result.is_collector);
    yield put(actions.getUserInfo.success(userData));
  } catch (error) {
    yield put(actions.getUserInfo.error(error));
  }
}

export function* loginPageWatcherSaga() {
  yield all([
    takeLatest(actions.auth.REQUEST, authSaga),
    takeLatest(actions.getValidationCode.REQUEST, getValidationCodeSaga),
    takeLatest(actions.getUserInfo.REQUEST, getUserDataSaga),
  ]);
}
