import styled from 'styled-components';
import { baseTheme } from '../../global/styles/theme';

export const EditPageContainer = styled('div')`
  max-width: 1216px;
  margin: auto;
  padding-bottom: 50px;

  .product-modal__title {
    font-size: 24px;
    font-weight: 700;
    padding-bottom: 10px;
  }

  .product-modal__condition {
    padding: 24px 0 10px;
    color: ${baseTheme.colors.secondary};
  }

  .product-modal__button {
    padding-top: 32px;
    width: 150px;
    margin-right: 24px;
  }

  .product-modal__block-button {
    padding-top: 32px;
    width: 260px;
  }

  .product-modal__delete-button {
    padding-top: 32px;
    width: 320px;
    margin-left: 24px;
  }

  .product-checkbox-container {
    padding-top: 15px;
  }

  .product-block {
    margin: -4px 0 0 30px;
  }
`;

export const EditPageHeaderContainer = styled('div')`
  display: flex;
  justify-content: space-between;

  .edit-title {
    font-weight: 700;
    font-size: 32px;
    padding-top: 16px;
    color: ${baseTheme.colors.primary};
  }
`;

export const EditPageTable = styled('div')`
  width: 100%;
  padding-top: 20px;

  .flexGroup {
    display: flex;

    .product-table-block {
      width: 90%;
    }
    justify-content: space-between;
  }

  .product-loading {
    padding-top: 32px;
  }

  .status-history {
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    padding-top: 11px;
    color: ${baseTheme.colors.mainLightBlue};
  }
`;

export const InfoSelectedProduct = styled('div')`
  .product-block {
    width: 272px;
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    border: 1px solid #e0e5eb;
    border-radius: 8px;
    padding: 24px 16px;

    .product-name {
      padding-top: 10px;
      text-align: center;
      font-weight: 500;
      font-size: 18px;
      color: ${baseTheme.colors.primary};
      line-height: 22px;
    }

    .product-img {
      display: flex;
      justify-content: center;
      height: 140px;
      img {
        max-height: 140px;
        width: auto;
      }
    }

    .product-packing {
      padding: 12px 0 4px 0;
      text-align: center;
      font-weight: 400;
      line-height: 20px;
      color: ${baseTheme.colors.primary};
    }

    .product-manufacturer {
      text-align: center;
      font-weight: 400;
      line-height: 20px;
      color: ${baseTheme.colors.primary};
    }
  }
`;

export const AttachedByBlock = styled('div')`
  width: 100%;
  padding-top: 20px;
  display: flex;

  .verified-title {
    font-weight: 600;
    color: ${baseTheme.colors.mainLightBlue};
    padding-right: 4px;
  }
`;

export const ButtonContainer = styled('div')`
  display: flex;
`;
