import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { OrderHistoryService } from './service';
import { OrderHistoryResponse } from './types';
import { StatusResponse } from '../OrdersPage/types';
import { OrderService } from '../OrderPage/service';
import { mappedHistoryEvents } from './helpers';
import { OrderStatusesNewResponse } from '../OrderPage/types';

interface GetOrderHistoryParams {
  type: string;
  payload: { orderId: string; createdAt: string; token: string };
}

export function* getOrderHistorySaga({ payload }: GetOrderHistoryParams) {
  try {
    const response: OrderHistoryResponse = yield call(
      OrderHistoryService.getOrderHistory,
      payload.orderId,
      payload.createdAt
    );
    const statuses: OrderStatusesNewResponse = yield call(
      OrderService.getAllStatuses,
      payload.token
    );

    const statusesPharmacy: StatusResponse = yield call(
      OrderService.getAllStatusesPharmacy,
      payload.token
    );

    const mappedData = mappedHistoryEvents(
      response.result.items,
      statuses,
      statusesPharmacy
    );
    yield put(actions.getOrderHistory.success(mappedData));
  } catch (error) {
    yield put(actions.getOrderHistory.error(error));
  }
}

export function* orderHistoryPageWatcherSaga() {
  yield all([takeLatest(actions.getOrderHistory.REQUEST, getOrderHistorySaga)]);
}
