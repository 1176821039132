import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import * as actions from './actions';
import { selectors as loginSelectors } from '../../containers/LoginPage/reducer';
import { selectors } from './reducer';
import * as Styled from './ActivePharmacistBlock.styles';
import Button from '../Button';
import OneActivePharmacist from './components/OneActivePharmacist';
import ModalWindow from '../ModalWindow';

interface PharmacistBlockParam {
  variant: 'isPharmacistOnShift' | 'isPharmacistOnOrder';
  onModalClose: (isOpen: boolean) => void;
  onSaveOrderPharmacist?: () => void;
  onSetOrderPharmacist?: (collector: { name: string; phone: string }) => void;
  pharmacistOnOrder?: { name: string; phone: string } | null;
  orderId?: string | null;
  onEmptyActiveUsersClick?: () => void;
}

const ActivePharmacistBlock = ({
  variant,
  onModalClose,
  onSaveOrderPharmacist,
  onSetOrderPharmacist,
  pharmacistOnOrder,
  orderId,
  onEmptyActiveUsersClick,
}: PharmacistBlockParam) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dataUser = useSelector(loginSelectors.userData);
  const [errorTooltip, setErrorTooltip] = useState<boolean>(false);

  const isPharmacistOnShift = variant === 'isPharmacistOnShift';
  const isPharmacistOnOrder = variant === 'isPharmacistOnOrder';

  const activeUsers = isPharmacistOnShift
    ? // eslint-disable-next-line
      useSelector(selectors.activeUsersData)
    : // eslint-disable-next-line
      useSelector(selectors.activeUsersData)?.filter((e) => e.active);

  const activeUsersLoading = useSelector(selectors.activeUsersDataLoading);

  useEffect(() => {
    if (dataUser && !activeUsers) {
      dispatch(
        actions.getAllUsers.request({ sourceCode: dataUser.sourceCode })
      );
    }
  }, [dispatch]);

  const [activeArray, setActiveArray] = useState<string[] | undefined>(
    undefined
  );

  useEffect(() => {
    if (activeUsers) {
      setActiveArray(
        activeUsers?.filter((el) => el.active).map((el) => el.phone)
      );
    }
  }, [isPharmacistOnShift && activeUsers]);

  const handleSetOrderPharmacist = (collector: {
    name: string;
    phone: string;
  }) => {
    onSetOrderPharmacist && onSetOrderPharmacist(collector);
    setErrorTooltip(false);
  };

  const handleSetActive = (phone: string) => {
    setErrorTooltip(false);
    if (activeArray) {
      const isPersonAdded = activeArray.find((el) => el === phone);
      if (isPersonAdded) {
        setActiveArray((currentPerson) =>
          currentPerson?.filter((el) => el !== phone)
        );
      } else setActiveArray([...activeArray, phone]);
    }
  };

  const handleUpdateUsersSuccess = () => {
    onModalClose(false);
    dispatch(actions.getAllUsers.request({ sourceCode: dataUser.sourceCode }));
    dispatch(actions.clearActiveUsers());
  };

  const handleSaveActivePharmacist = () => {
    isPharmacistOnOrder &&
      onSaveOrderPharmacist &&
      pharmacistOnOrder &&
      onSaveOrderPharmacist();
    if (dataUser && isPharmacistOnShift) {
      dispatch(
        actions.updateActiveUsers.request({
          sourceCode: dataUser.sourceCode || dataUser.collectingSourceCode,
          networkCode: dataUser.networkCode,
          phones: activeArray,
          postEffect: () => handleUpdateUsersSuccess(),
        })
      );
    } else !pharmacistOnOrder && setErrorTooltip(true);
  };

  const handleClickEmptyActiveUsers = () => {
    onEmptyActiveUsersClick && onEmptyActiveUsersClick();
  };

  const content = (
    <Styled.PharmacistContainer>
      {isPharmacistOnShift && (
        <Styled.PharmacistBlockTitle>
          {t('OrdersPage.ActivePharmacistBlock.TITLE_ON_SHIFT')}
        </Styled.PharmacistBlockTitle>
      )}
      {isPharmacistOnOrder && (
        <Styled.PharmacistBlockTitle>
          {t('OrdersPage.ActivePharmacistBlock.TITLE_ON_ORDER')}{' '}
          {orderId && orderId}
          <div>
            {t('OrdersPage.ActivePharmacistBlock.DESCRIPTION_ON_ORDER')}
          </div>
        </Styled.PharmacistBlockTitle>
      )}
      <Styled.PharmacistBlock>
        {activeUsers &&
          activeUsers.map((person) => (
            <OneActivePharmacist
              key={person.phone}
              person={person}
              onPersonClick={
                isPharmacistOnShift ? handleSetActive : handleSetOrderPharmacist
              }
              isPharmacistOnOrder={isPharmacistOnOrder}
              pharmacistOnOrder={pharmacistOnOrder}
            />
          ))}
        {activeUsersLoading && <CircularProgress size={15} />}
      </Styled.PharmacistBlock>
      {activeUsers?.length === 0 && isPharmacistOnOrder && (
        <Styled.EmptyActiveButton>
          <Button
            text={t('OrdersPage.ActivePharmacistBlock.EMPTY_ACTIVE_USERS_BTN')}
            onClick={handleClickEmptyActiveUsers}
          />
        </Styled.EmptyActiveButton>
      )}
      {activeUsers?.length !== 0 && (
        <Styled.ChangedButtonBlock>
          {errorTooltip && isPharmacistOnOrder && (
            <div className="error-tooltip">
              {t('OrdersPage.ActivePharmacistBlock.ERROR_TOOLTIP_ON_ORDER')}
            </div>
          )}
          <Button
            text={t('OrdersPage.ActivePharmacistBlock.CONFIRM_BTN')}
            variant="dark"
            onClick={handleSaveActivePharmacist}
          />
        </Styled.ChangedButtonBlock>
      )}
    </Styled.PharmacistContainer>
  );

  return (
    <ModalWindow onClose={() => onModalClose(false)}>{content}</ModalWindow>
  );
};

export default ActivePharmacistBlock;
