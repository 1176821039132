import { createReducer } from '@reduxjs/toolkit';
import * as actions from './actions';
import { AppState } from '../../global/types';

const initialState = {
  webStockProducts: {
    data: null,
    loading: false,
    error: null,
  },
  searchProducts: {
    data: null,
    loading: false,
    error: null,
  },
};

const reducer = createReducer(initialState, {
  [actions.getWebStockProducts.REQUEST]: (state) => {
    state.webStockProducts.loading = true;
  },
  [actions.getWebStockProducts.SUCCESS]: (state, action) => {
    state.webStockProducts.data = action.payload;
    state.webStockProducts.loading = false;
  },
  [actions.getWebStockProducts.ERROR]: (state, action) => {
    state.webStockProducts.error = action.payload;
    state.webStockProducts.loading = false;
  },
  [actions.getProductsBySku.REQUEST]: (state) => {
    state.searchProducts.loading = true;
  },
  [actions.getProductsBySku.SUCCESS]: (state, action) => {
    state.searchProducts.data = action.payload;
    state.searchProducts.loading = false;
  },
  [actions.getProductsBySku.ERROR]: (state, action) => {
    state.searchProducts.error = action.payload;
    state.searchProducts.loading = false;
  },
});

const webStockProductsData = (state: AppState) =>
  state.webStockPageReducer.webStockProducts.data;

const webStockProductsLoading = (state: AppState) =>
  state.webStockPageReducer.webStockProducts.loading;
const searchProductsData = (state: AppState) =>
  state.webStockPageReducer.searchProducts.data;
const searchProductsLoading = (state: AppState) =>
  state.webStockPageReducer.searchProducts.loading;

const selectors = {
  webStockProductsData,
  webStockProductsLoading,
  searchProductsData,
  searchProductsLoading,
};

export { selectors };
export default reducer;
