import styled from 'styled-components';
import { baseTheme } from '../../global/styles/theme';

export const ModalContainer = styled('div')`
  padding: 20px 30px 0 30px;
  margin-bottom: -110px;

  .no-visible {
    visibility: hidden;
  }
`;

export const Title = styled('div')`
  font-size: 24px;
  font-weight: 700;
  color: ${baseTheme.colors.primary};
`;

export const Quantity = styled('div')`
  padding: 32px 0;
  text-align: center;
  color: ${baseTheme.colors.secondary};
  font-weight: 500;
  font-size: 18px;
`;

export const ButtonConfirm = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  .div {
    width: 72px;
  }
`;
