import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as Styled from './ProductsTableRow.styles';
import {
  MappingType,
  ProductResponse,
  ProductStatus,
  TagsMappedInfo,
} from '../../../../types';
import { getDefaultValue } from '../../../../helpers';
import * as actions from '../../../../actions';
import {
  getFromLocalStorage,
  setToLocalStorage,
} from '../../../../../../global/helpers';
import { setActiveTab } from '../../../../../EditPage/helpers';
import editIcon from '../../../../../../global/media/edit-pencil.svg';
import deleteMapping from '../../../../../../global/media/red-close.svg';
import deleteTagIcon from '../../../../../../global/media/close-dark.svg';
import * as actionService from '../../../../../EditPage/actions';
import { selectors, selectors as productsSelectors } from '../../../../reducer';
import { selectors as userSelectors } from '../../../../../LoginPage/reducer';
import Button from '../../../../../../components/Button';
import addIcon from '../../../../../../global/media/add-icon.svg';
import SelectTags from '../SelectTags';
import AddDeleteTagModal from '../../../AddDeleteTagModal';
import { UserRole } from '../../../../../OrdersPage/types';
import absentImg from '../../../../../../global/media/empty-state.svg';
import ModalWindow from '../../../../../../components/ModalWindow';
import * as actionsNotification from '../../../../../InitComponent/actions';
import Loader from '../../../../../../components/Loader';

interface ProductsTableRowProps {
  data: ProductResponse;
  type:
    | 'blocked'
    | 'attached'
    | 'unattached'
    | 'verification'
    | 'dybl'
    | 'automapping';
  searchValue: string;
  setData: any;
}
const ProductsTableRow = ({
  data,
  type,
  searchValue,
  setData,
}: ProductsTableRowProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const token = getFromLocalStorage('accessToken');
  const dataUser = useSelector(userSelectors.userData);
  const networkForContent = useSelector(productsSelectors.network);
  const dataTag = useSelector(selectors.tags);
  const tagIds = useSelector(selectors.tagIds)?.tagIds;
  const storageNetworkForContent = getFromLocalStorage('networkData');
  const pagination = getFromLocalStorage('pagination');
  const userRole = getFromLocalStorage('role');
  const isNeededImg =
    (userRole === UserRole.ADMIN ||
      userRole === UserRole.MANAGER ||
      userRole === UserRole.PHARMACY_MANAGER) &&
    (type === MappingType.ATTACHED ||
      type === MappingType.AUTOMAPPING ||
      type === MappingType.VERIFICATION);

  const [isAddTagsOpen, setAddTagsOpen] = useState<boolean>(false);
  const [isDeleteTagOpen, setDeleteModalTagOpen] = useState<boolean>(false);
  const [tagForDelete, setTagForDelete] = useState<string>('');
  const [isBiggerImg, setIsBiggerImg] = useState<boolean>(false);
  const [isAbsentImg, setIsAbsentImg] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState('');
  const [isCorrectBinding, setIsCorrectBinding] = useState(false);
  const networkCode = networkForContent?.code
    ? networkForContent?.code
    : storageNetworkForContent?.code;
  const isGlobalSearch = getFromLocalStorage('isGlobalSearch');
  const isGalina = dataUser?.phone === '77056254774';
  const [reasonText, setReasonText] = useState('');

  const handlePostEffect = (isDeleted?: boolean) => {
    if (searchValue?.length) {
      dispatch(
        actions.getProductsSearchOnVerification.request({
          token,
          code: isGlobalSearch ? '' : networkCode,
          searchValue,
          tag: tagIds,
        })
      );
    }
    dispatch(
      actions.getProductsVer.request({
        token,
        num: (pagination.verificationPage || 0) * 100,
        tags: tagIds,
        code: isGlobalSearch ? '' : networkCode,
      })
    );
    dispatch(
      actions.getProductsAutomapping.request({
        token,
        num: (pagination.automappingPage || 0) * 100,
        tags: tagIds,
        code: isGlobalSearch ? '' : networkCode,
      })
    );
    isDeleted
      ? dispatch(
          actions.getProductsUnAtt.request({
            token,
            num: (pagination.unAttachedPage || 0) * 100,
            tags: tagIds,
            code: isGlobalSearch ? '' : networkCode,
          })
        )
      : dispatch(
          actions.getProductsAtt.request({
            token,
            num: (pagination.attachedPage || 0) * 100,
            tags: tagIds,
            code: isGlobalSearch ? '' : networkCode,
          })
        );
  };

  const handlePostEffectOnSearch = (isDeleted: boolean) => {
    dispatch(
      actions.getProductsSearchOnVerification.request({
        token,
        code: isGlobalSearch ? '' : networkCode,
        searchValue: getFromLocalStorage('searchValue'),
        tags: tagIds,
        num: (pagination.verificationPage || 0) * 100,
      })
    );
    isDeleted
      ? dispatch(
          actions.getProductsSearchUnattached.request({
            token,
            code: isGlobalSearch ? '' : networkCode,
            searchValue: getFromLocalStorage('searchValue'),
            tags: tagIds,
            num: (pagination.unAttachedPage || 0) * 100,
          })
        )
      : dispatch(
          actions.getProductsSearchAttached.request({
            token,
            code: isGlobalSearch ? '' : networkCode,
            searchValue: getFromLocalStorage('searchValue'),
            tags: tagIds,
            num: (pagination.attachedPage || 0) * 100,
          })
        );
    dispatch(
      actions.getProductsSearchAutomapping.request({
        token,
        code: isGlobalSearch ? '' : networkCode,
        searchValue: getFromLocalStorage('searchValue'),
        tags: tagIds,
        num: (pagination.automappingPage || 0) * 100,
      })
    );
  };

  const handlePostMappings = () => {
    dispatch(
      actionService.postDataForMappings.request({
        token,
        sku: data.sku,
        wareId: data?.ware_id,
        code: isGlobalSearch ? data.mapping_code : networkCode,
        postEffect: () =>
          getFromLocalStorage('searchValue')?.length > 2
            ? handlePostEffectOnSearch(true)
            : handlePostEffect(true),
      })
    );
    dispatch(actionService.setSelectedProduct());
  };

  const handlePostMappingsAI = async () => {
    const url =
      'https://linkdata-production-1097.up.railway.app/process_query/';

    const dataAI = {
      manufacturer: data.manufacturer ? data.manufacturer : 'not specified',
      name: data.name,
      skuManufacturer: data.skuManufacturer,
      skuName: data.skuName,
    };

    const fetchData = async () => {
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(dataAI),
        });

        if (!response.ok) {
          throw new Error(`Ошибка: ${response.statusText}`);
        }

        const responseData = await response.json();

        setModalData(responseData.response);
      } catch (error: any) {
        console.log('error', error);
        dispatch(
          actionsNotification.addApplicationNotification({
            content: `${error.statusText} Сервер не отвечает, выполнена привязка без обработки ИИ`,
            canBeClosed: true,
            type: 'error',
          })
        );
        handlePostMappings();
      }
    };

    const timeout = new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error('Сервер не отвечает в течение 15 секунд'));
      }, 15000);
    });

    try {
      await Promise.race([fetchData(), timeout]);
    } catch (error: any) {
      console.log('error', error);
      handlePostMappings();
      dispatch(
        actionsNotification.addApplicationNotification({
          content: `Сервер не отвечает, выполнена привязка без обработки ИИ`,
          canBeClosed: true,
          type: 'error',
        })
      );
    }
  };

  const sendMessageToTelegramBot = async (text?: string) => {
    const token = isGalina
      ? '7255829670:AAHGfKIEDq7agoOk6aOjqZkxjzPknblBjbU'
      : '7323198249:AAFCFQFCQtlHz-N_AfnX-tY2xhxIPavdiOM';
    const chatId = isGalina ? '-1002183137334' : '-1002210611785';
    const url = `https://api.telegram.org/bot${token}/sendMessage`;

    try {
      const res = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          chat_id: chatId,
          text: JSON.stringify({
            manufacturer: data.manufacturer
              ? data.manufacturer
              : 'not specified',
            name: data.name,
            skuManufacturer: data.skuManufacturer,
            skuName: data.skuName,
            messageFromAI: isGalina ? 'Привязка от Галины' : modalData,
            textOperator: text ?? 'Без ИИ',
          }),
        }),
      });
    } catch (error) {
      console.error('Ошибка:', error);
    }
  };

  const handleDeleteClick = () => {
    if (MappingType.VERIFICATION || MappingType.AUTOMAPPING) {
      dispatch(
        actionService.deleteMappings.request({
          token,
          wareId: data?.ware_id,
          code: isGlobalSearch ? data.mapping_code : networkCode,
          postEffect: () =>
            getFromLocalStorage('searchValue')?.length > 2
              ? handlePostEffectOnSearch(true)
              : handlePostEffect(true),
        })
      );
    }
  };

  const handleClick = (data: ProductResponse) => {
    // @ts-ignore
    dispatch(actions.setSelectedProduct(data));
    setToLocalStorage('selectedProduct', data);
    setActiveTab(type);
    history.push(`/edit?type=${type}`);
  };
  const handleOpenSelectTags = () => {
    setAddTagsOpen(true);
  };

  const getTagColor = (id: number | string) => {
    const currentTag = dataTag?.find(
      (el: { tagId: string }) => el?.tagId === id?.toString()
    );
    return currentTag?.tagColor;
  };

  const getTagAllowed = (id: number | string) => {
    const currentTag = dataTag.find(
      (el: TagsMappedInfo) => el.tagId === id.toString()
    );
    return currentTag.allowed;
  };

  const handleDeleteTag = () => {
    dispatch(
      actions.deleteProductTag.request({
        tag: tagForDelete,
        wareId: data.ware_id,
        mappingCode: isGlobalSearch ? data.mapping_code : networkCode,
        postEffect: () => setData(),
      })
    );
    setDeleteModalTagOpen(false);
  };

  const handleOpenDeleteTagModal = (id: number) => {
    setTagForDelete(id.toString());
    setDeleteModalTagOpen(true);
  };

  const statusTitlesStrategy = {
    [ProductStatus.VERIFIED]: t('ProductsPage.ProductsStatus.ATTACHED'),
    [ProductStatus.ON_VERIFICATION]: t(
      'ProductsPage.ProductsStatus.ON_VERIFICATION'
    ),
    [ProductStatus.BLOCKED]: t('ProductsPage.ProductsStatus.BLOCKED'),
    [ProductStatus.UNMAPPED]: t('ProductsPage.ProductsStatus.UNATTACHED'),
    [ProductStatus.AUTOMAPPING]: t('ProductsPage.ProductsStatus.AUTOMAPPING'),
  };

  const dyblSku = data.tags && data.tags.find((el) => el.tag === 1)?.meta;

  const okOnClickHandler = () => {
    setIsModalOpen(!isModalOpen);
    handlePostMappingsAI();
  };

  const handleClickModal = (action: any, text?: string) => {
    setIsModalOpen(!isModalOpen);
    action();
    sendMessageToTelegramBot(text);
  };

  const GalinaHandleClick = () => {
    sendMessageToTelegramBot();
    handlePostMappings();
  };

  const handleReason = () => {
    if (!reasonText) {
      return;
    }
    fetch(
      'https://ai-links-feedback-production.up.railway.app/process_ispravlenie/',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          Manufacturer_1: data.manufacturer
            ? data.manufacturer
            : 'not specified',
          Name_1: data.name,
          Manufacturer_2: data.skuManufacturer,
          Name_2: data.skuName,
          Message: reasonText,
        }),
      }
    );
    setReasonText('');
  };

  const operOrGalinaHandler = () => {
    return isGalina ? GalinaHandleClick() : okOnClickHandler();
  };

  const getFullEditBlock = () => (
    <div className="item-edit-container">
      <Button text={t('ProductsPage.OK')} onClick={operOrGalinaHandler} />
      <div className="pencil-icon-container" onClick={() => handleClick(data)}>
        <img src={editIcon} alt="edit icon" />
      </div>

      <div className="delete-icon-container" onClick={handleDeleteClick}>
        <img src={deleteMapping} alt="delete icon" />
      </div>
    </div>
  );

  useEffect(() => {
    if (modalData.includes('Привязка верная')) {
      setIsCorrectBinding(true);
    } else {
      setIsCorrectBinding(false);
    }
  }, [modalData]);

  const getEditBlock = () => {
    if (type !== MappingType.AUTOMAPPING && type !== MappingType.VERIFICATION) {
      return (
        <div
          className="pencil-icon-container"
          onClick={() => handleClick(data)}
        >
          <img src={editIcon} alt="edit icon" />
        </div>
      );
    }
    if (type === MappingType.AUTOMAPPING) {
      return getFullEditBlock();
    }

    if (type === MappingType.VERIFICATION) {
      if (
        userRole === UserRole.ADMIN ||
        userRole === UserRole.CONTENT ||
        userRole === UserRole.HEAD_OPERATOR ||
        userRole === UserRole.OPERATOR
      ) {
        return getFullEditBlock();
      }

      return <div />;
    }
  };

  return (
    <>
      <Styled.ProductsTableRowContainer id={data?.ware_id}>
        <td
          className="product-item item-column"
          style={{ verticalAlign: isNeededImg ? 'baseline' : 'inherit' }}
        >
          <div>
            {data?.name}
            {(type === MappingType.DYBL ||
              type === MappingType.VERIFICATION ||
              type === MappingType.ATTACHED ||
              type === MappingType.AUTOMAPPING) && (
              <>
                <div className="product-manufacturer">
                  {' '}
                  {t('ProductsPage.HeaderTitles.MANUFACTURER')}:{' '}
                  <span className="manufacturer">{getDefaultValue(data)}</span>
                </div>
                <span>{data?.ware_id}</span>
              </>
            )}
          </div>
          <div className="tags-container">
            {data.tags &&
              data.tags.map((el) => (
                <div
                  key={el.tag}
                  style={{ backgroundColor: `${getTagColor(el.tag)} ` }}
                  className="tag"
                >
                  {el.name}
                  {el.tag !== 1 && getTagAllowed(el.tag) && (
                    <span
                      onClick={() => handleOpenDeleteTagModal(el.tag)}
                      className="tag-delete"
                    >
                      <img src={deleteTagIcon} alt="delete icon" />
                    </span>
                  )}
                </div>
              ))}
            <div
              className="add-tag"
              onClick={() => setAddTagsOpen(!isAddTagsOpen)}
            >
              <img src={addIcon} alt="add product" />
            </div>
            {isAddTagsOpen && (
              <SelectTags
                onClick={handleOpenSelectTags}
                onClose={() => setAddTagsOpen(false)}
                data={dataTag}
                isAdd
                wareId={data.ware_id}
                mappingCode={isGlobalSearch ? data.mapping_code : networkCode}
                productName={data.name}
                setData={setData}
              />
            )}
          </div>
        </td>
        {(type === MappingType.DYBL ||
          type === MappingType.VERIFICATION ||
          type === MappingType.ATTACHED ||
          type === MappingType.AUTOMAPPING) && (
          <td
            className="product-item item-column"
            style={{ verticalAlign: 'baseline' }}
          >
            <div>
              <div>{data.skuName}</div>
              <div className="product-manufacturer">
                {' '}
                {t('ProductsPage.HeaderTitles.MANUFACTURER')}:{' '}
                <span className="manufacturer">
                  {data.skuManufacturer
                    ? data.skuManufacturer
                    : t('ProductsPage.HeaderTitles.NOT_SPECIFIED')}
                </span>
              </div>
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://daribar.kz/products/${data?.urlKey}--${
                  type === MappingType.DYBL ? dyblSku : data.sku
                } `}
              >
                {type === MappingType.DYBL ? dyblSku : data.sku}
              </a>
            </div>
            {isNeededImg && (
              <div onClick={() => setIsBiggerImg(!isBiggerImg)}>
                <img
                  style={{
                    scale: !isBiggerImg ? '1' : '1.3',
                    cursor: 'pointer',
                  }}
                  width="120px"
                  height="120px"
                  src={
                    isAbsentImg
                      ? absentImg
                      : `https://db-images.object.pscloud.io/optimized_v4_img_small_${data.sku}.webp`
                  }
                  alt=""
                  onError={() => setIsAbsentImg(true)}
                />
              </div>
            )}
          </td>
        )}
        <td className="product-item item-column">
          {data.min_price === data.avg_price &&
          data.avg_price === data.max_price
            ? data?.avg_price
            : `${data?.min_price} /${data?.avg_price}/${data?.max_price} `}
          {}
        </td>
        <td className="product-item item-column">{data?.quantity || 0}</td>
        {type !== MappingType.DYBL &&
          type !== MappingType.VERIFICATION &&
          type !== MappingType.ATTACHED &&
          type !== MappingType.AUTOMAPPING && (
            <td className="product-item">{data?.ware_id}</td>
          )}
        {type !== MappingType.DYBL &&
          type !== MappingType.VERIFICATION &&
          type !== MappingType.ATTACHED &&
          type !== MappingType.AUTOMAPPING && (
            <td className="product-item">{getDefaultValue(data)}</td>
          )}
        {(userRole === UserRole.ADMIN ||
          userRole === UserRole.CONTENT ||
          userRole === UserRole.HEAD_OPERATOR ||
          userRole === UserRole.OPERATOR) && (
          <td className="product-item item-column">{data.mapping_code}</td>
        )}
        {type === MappingType.DYBL && (
          // @ts-ignore
          <td className="product-item">{statusTitlesStrategy[data?.status]}</td>
        )}
        {/* eslint-disable-next-line */}
        <td
          className={`product - item item - edit ${
            (type === MappingType.VERIFICATION ||
              type === MappingType.AUTOMAPPING) &&
            'item-edit-verification'
          } `}
        >
          {getEditBlock()}
        </td>
        {isModalOpen && (
          <ModalWindow onClose={() => setIsModalOpen(!isModalOpen)}>
            {!modalData ? (
              <Loader />
            ) : (
              <div className="modal-content">
                <h2>по "{data.skuName}" ИИ говорит, что </h2>
                <h2>{modalData}</h2>
                <input
                  type="text"
                  placeholder="Причина почему привязка не верная:"
                  value={reasonText}
                  onChange={(e) => setReasonText(e.target.value)}
                  style={{
                    padding: '12px 24px',
                    width: '500px',
                  }}
                />

                {isCorrectBinding ? (
                  <div className="button-row">
                    <Button
                      text="ИИ дал верный ответ, привязка верная"
                      onClick={() =>
                        handleClickModal(
                          handlePostMappings,
                          'ИИ дал верный ответ, привязка верная'
                        )
                      }
                      color="green"
                    />
                    <Button
                      text="ИИ дал не верный ответ, привязка не верная"
                      onClick={() => {
                        handleReason();
                        handleClickModal(
                          handleDeleteClick,
                          'ИИ дал не верный ответ, привязка не верная'
                        );
                      }}
                      color="red"
                    />
                  </div>
                ) : (
                  <div className="button-row">
                    <Button
                      text="ИИ дал не верный ответ, привязка верная"
                      onClick={() => {
                        handleReason();
                        handleClickModal(
                          handlePostMappings,
                          'ИИ дал не верный ответ, привязка верная'
                        );
                      }}
                      color="green"
                    />
                    <Button
                      text="ИИ дал верный ответ, привязка не верная"
                      onClick={() =>
                        handleClickModal(
                          handleDeleteClick,
                          'ИИ дал верный ответ, привязка не верная'
                        )
                      }
                      color="red"
                    />
                  </div>
                )}
              </div>
            )}
          </ModalWindow>
        )}
      </Styled.ProductsTableRowContainer>
      {isDeleteTagOpen && (
        <AddDeleteTagModal
          onClose={() => setDeleteModalTagOpen(false)}
          tagsId={[tagForDelete]}
          productName={data.name ?? ''}
          onConfirm={handleDeleteTag}
          variant="delete"
        />
      )}
    </>
  );
};
export default ProductsTableRow;
