import styled from 'styled-components';
import { baseTheme } from '../../../../global/styles/theme';

export const RefundsReportContainer = styled('div')`
  width: 1200px;
  margin: 20px auto;
  .orderBtn {
    border-radius: 10px;
    border: 1px solid grey;
    cursor: pointer;
    background: transparent;
    margin: 0 5px 5px 0;
  }
`;
