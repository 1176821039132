import { createReducer } from '@reduxjs/toolkit';
import * as actions from './actions';
import { AppState } from '../../global/types';
import { TemplatesPageState } from './types';

const initialState: TemplatesPageState = {
  templates: {
    data: null,
    loading: false,
    error: null,
  },

  promoCodes: {
    data: null,
    loading: false,
    error: null,
  },
  pharmacistIndividualExistingPromoCode: {
    data: null,
    loading: false,
    error: null,
  },
  bonusOrdersDetailsHistory: {
    data: null,
    loading: false,
    error: null,
  },
  promocodePharmacistBlocked: {
    data: null,
    loading: false,
    error: null,
  },

  getPromocodeStatusByCode: {
    data: null,
    loading: false,
    error: null,
  },

  bonusOrdersDetails: {
    data: null,
    loading: false,
    error: null,
  },
  pharmacistBonusesBalance: {
    data: null,
    loading: false,
    error: null,
  },
  newPharmacistPromoCode: {
    data: null,
    loading: false,
    error: null,
  },
};

const reducer = createReducer(initialState, {
  [actions.generateTemplates.REQUEST]: (state) => {
    state.templates.loading = true;
  },

  [actions.generateTemplates.SUCCESS]: (state) => {
    state.templates.loading = false;
  },

  [actions.generateTemplates.ERROR]: (state, action) => {
    state.templates.loading = false;
    state.templates.error = action.payload;
  },

  [actions.getTemplatesData.REQUEST]: (state) => {
    state.templates.loading = true;
  },

  [actions.getTemplatesData.SUCCESS]: (state, action) => {
    state.templates.loading = false;
    state.templates.data = action.payload;
  },

  [actions.getTemplatesData.ERROR]: (state, action) => {
    state.templates.loading = false;
    state.templates.error = action.payload;
  },
  [actions.generatePromoCodes.REQUEST]: (state) => {
    state.promoCodes.loading = true;
  },

  [actions.generatePromoCodes.SUCCESS]: (state, action) => {
    state.promoCodes.loading = false;
  },

  [actions.generatePromoCodes.ERROR]: (state, action) => {
    state.promoCodes.loading = false;
    state.promoCodes.error = action.payload;
  },
  [actions.getPromoCodesData.REQUEST]: (state) => {
    state.promoCodes.loading = true;
  },

  [actions.getPromoCodesData.SUCCESS]: (state, action) => {
    state.promoCodes.loading = false;
    state.promoCodes.data = action.payload;
  },

  [actions.getPromoCodesData.ERROR]: (state, action) => {
    state.promoCodes.loading = false;
    state.promoCodes.error = action.payload;
  },

  [actions.activatePromoCodes.REQUEST]: (state) => {
    state.promoCodes.loading = true;
  },

  [actions.activatePromoCodes.SUCCESS]: (state, action) => {
    state.promoCodes.loading = false;
  },

  [actions.activatePromoCodes.ERROR]: (state, action) => {
    state.promoCodes.loading = false;
    state.promoCodes.error = action.payload;
  },
  [actions.getPharmacistIndividualExistingPromoCode.SUCCESS]: (
    state,
    action
  ) => {
    state.pharmacistIndividualExistingPromoCode.data = action.payload;
    state.pharmacistIndividualExistingPromoCode.loading = false;
  },
  [actions.getPharmacistIndividualExistingPromoCode.REQUEST]: (
    state,
    action
  ) => {
    state.pharmacistIndividualExistingPromoCode.loading = true;
  },
  [actions.getPharmacistIndividualExistingPromoCode.ERROR]: (state, action) => {
    state.pharmacistIndividualExistingPromoCode.error = action.payload;
  },
  [actions.getBonusOrdersDetailsHistory.REQUEST]: (state, action) => {
    state.bonusOrdersDetailsHistory.loading = true;
  },
  [actions.getBonusOrdersDetailsHistory.SUCCESS]: (state, action) => {
    state.bonusOrdersDetailsHistory.data = action.payload;
    state.bonusOrdersDetailsHistory.loading = false;
  },
  [actions.getBonusOrdersDetailsHistory.ERROR]: (state, action) => {
    state.bonusOrdersDetailsHistory.loading = false;
    state.bonusOrdersDetailsHistory.error = action.payload;
  },

  [actions.getPromocodeStatusByCode.REQUEST]: (state, action) => {
    state.getPromocodeStatusByCode.loading = true;
  },
  [actions.getPromocodeStatusByCode.SUCCESS]: (state, action) => {
    state.getPromocodeStatusByCode.data = action.payload;
    state.getPromocodeStatusByCode.loading = false;
  },
  [actions.getPromocodeStatusByCode.ERROR]: (state, action) => {
    state.getPromocodeStatusByCode.loading = false;
    state.getPromocodeStatusByCode.error = action.payload;
  },
  [actions.getPromocodePharmacistBlocked.REQUEST]: (state, action) => {
    state.promocodePharmacistBlocked.loading = true;
  },
  [actions.getPromocodePharmacistBlocked.SUCCESS]: (state, action) => {
    state.promocodePharmacistBlocked.data = action.payload;
    state.promocodePharmacistBlocked.loading = false;
  },
  [actions.getPromocodePharmacistBlocked.ERROR]: (state, action) => {
    state.promocodePharmacistBlocked.loading = false;
    state.promocodePharmacistBlocked.error = action.payload;
  },
  [actions.getBonusOrdersDetails.REQUEST]: (state, action) => {
    state.bonusOrdersDetails.loading = true;
  },
  [actions.getBonusOrdersDetails.SUCCESS]: (state, action) => {
    state.bonusOrdersDetails.data = action.payload;
    state.bonusOrdersDetails.loading = false;
  },
  [actions.getBonusOrdersDetails.ERROR]: (state, action) => {
    state.bonusOrdersDetails.error = action.payload;
    state.bonusOrdersDetails.loading = false;
  },
  [actions.getPharmacistBonusesBalance.REQUEST]: (state, action) => {
    state.pharmacistBonusesBalance.loading = true;
  },
  [actions.getPharmacistBonusesBalance.SUCCESS]: (state, action) => {
    state.pharmacistBonusesBalance.data = action.payload;
    state.pharmacistBonusesBalance.loading = false;
  },
  [actions.getPharmacistBonusesBalance.ERROR]: (state, action) => {
    state.pharmacistBonusesBalance.error = action.payload;
    state.pharmacistBonusesBalance.loading = false;
  },
  [actions.getNewPharmacistPromoCode.REQUEST]: (state, action) => {
    state.newPharmacistPromoCode.loading = true;
  },
  [actions.getNewPharmacistPromoCode.ERROR]: (state, action) => {
    state.newPharmacistPromoCode.loading = false;
    state.newPharmacistPromoCode.error = action.payload;
  },
  [actions.getNewPharmacistPromoCode.SUCCESS]: (state, action) => {
    state.newPharmacistPromoCode.loading = false;
    state.newPharmacistPromoCode.data = action.payload;
  },
});

const templates = (state: AppState) => {
  return state.promoCodePageReducer.templates.data;
};

const pharmacistIndividualExistingPromoCode = (state: AppState) => {
  return state.promoCodePageReducer.pharmacistIndividualExistingPromoCode.data;
};

const promoCodes = (state: AppState) => {
  return state.promoCodePageReducer.promoCodes.data;
};

const bonusOrdersDetailsHistory = (state: AppState) => {
  return state.promoCodePageReducer.bonusOrdersDetailsHistory.data;
};

const promocodePharmacistBlocked = (state: AppState) => {
  return state.promoCodePageReducer.promocodePharmacistBlocked.data;
};

const getPromocodeStatusByCode = (state: AppState) => {
  return state.promoCodePageReducer.getPromocodeStatusByCode.data;
};

const bonusOrdersDetails = (state: AppState) => {
  return state.promoCodePageReducer.bonusOrdersDetails.data;
};

const pharmacistBonusesBalance = (state: AppState) => {
  return state.promoCodePageReducer.pharmacistBonusesBalance.data;
};

const newPharmacistPromoCode = (state: AppState) => {
  return state.promoCodePageReducer.newPharmacistPromoCode.data;
};

const getPromocodePharmacistBlocked = (state: AppState) => {
  return state.promoCodePageReducer.promocodePharmacistBlocked.data;
};

const selectors = {
  getPromocodePharmacistBlocked,
  getPromocodeStatusByCode,
  templates,
  promoCodes,
  pharmacistIndividualExistingPromoCode,
  bonusOrdersDetailsHistory,
  promocodePharmacistBlocked,
  bonusOrdersDetails,
  pharmacistBonusesBalance,
  newPharmacistPromoCode,
};

export { selectors };
export default reducer;
