import React from 'react';
import { Event, OrderHistoryMappedResponse } from '../../../../types';
import * as Styled from './HistoryTableRow.styles';
import arrowNextIcon from '../../../../../../global/media/next-status-arrow.svg';
import { UserRole } from '../../../../../OrdersPage/types';
import { convertSeconds } from '../../../../../../global/helpers';

interface HistoryTableRowParams {
  event: OrderHistoryMappedResponse;
}

const HistoryTableRow = ({ event }: HistoryTableRowParams) => {
  const getTimer = (seconds: number) => {
    const getTimerColor = () => {
      if (seconds > 240) return 'timer-green';
      if (seconds <= 240) return 'timer-orange';
      if (seconds <= 0) return 'timer-red';
    };
    return (
      <div className={`timer ${getTimerColor()}`}>
        {seconds ? convertSeconds(Math.abs(seconds)) : null}
      </div>
    );
  };

  return (
    <Styled.HistoryTableRow>
      <td className="table-cell">
        {event.phone !== UserRole.SYSTEM ? event.phone : null}
      </td>
      {/* @ts-ignore */}
      <td className="table-cell">{event.role}</td>
      <td className="table-cell">{event.collector}</td>
      <td className="table-cell">{event.currentAddress}</td>
      <td className="table-cell">{event.currentDescription}</td>
      <td className="table-cell table-cell-created-at">{event.createdAt}</td>
      <td className="table-cell">
        <div className="action-block">
          {/* @ts-ignore */}
          <div className="event-type">{event.cartHistory || event.event}</div>
          {event.currentStatus && event.previousStatus && (
            <div className="statuses-block">
              <div
                className="status"
                style={{ backgroundColor: `#${event.previousStatus.color}` }}
              >
                {' '}
                {event.previousStatus?.name}
              </div>
              <img src={arrowNextIcon} alt="arrow next" />
              <div
                className="status"
                style={{ backgroundColor: `#${event.currentStatus.color}` }}
              >
                {event.currentStatus?.name}
              </div>
            </div>
          )}
          {(event.previousTimer || event.currentTimer) && (
            <div className="timer-block">
              <div className="timer">
                {' '}
                {getTimer(Number(event.previousTimer))}
              </div>
              <img src={arrowNextIcon} alt="arrow next" />
              <div className="timer">
                {getTimer(Number(event.currentTimer))}
              </div>
            </div>
          )}
          {event.event === Event.UPDATE_TIMER_COMMENT && (
            <div className="comment-block">
              {event.commentTimerPrevious && (
                <div className="comment-prev">
                  "{event.commentTimerPrevious}"
                </div>
              )}
              {event.commentTimerPrevious && (
                <img src={arrowNextIcon} alt="arrow next" />
              )}
              <div className="comment-cur">"{event.commentTimerCurrent}"</div>
            </div>
          )}
          {event.currentPharmacy && event.previousPharmacy && (
            <div className="pharmacy-block">
              <div className="pharmacy-previous">{event.previousPharmacy}</div>
              <img src={arrowNextIcon} alt="arrow next" />
              <div className="pharmacy-current">{event.currentPharmacy}</div>
            </div>
          )}
          {event.previousPaymentMethod && event.currentPaymentMethod && (
            <div className="pharmacy-block">
              <div className="pharmacy-previous">
                {event.previousPaymentMethod}
              </div>
              <img src={arrowNextIcon} alt="arrow next" />
              <div className="pharmacy-current">
                {event.currentPaymentMethod}
              </div>
            </div>
          )}
          {event.previousDeliveryMethod && event.currentDeliveryMethod && (
            <div className="pharmacy-block">
              <div className="pharmacy-previous">
                {event.previousDeliveryMethod}
              </div>
              <img src={arrowNextIcon} alt="arrow next" />
              <div className="pharmacy-current">
                {event.currentDeliveryMethod}
              </div>
            </div>
          )}
          {event.previousChangedPaymentMethod &&
            event.currentChangedPaymentMethod && (
              <div className="pharmacy-block">
                <div className="pharmacy-previous">
                  {event.previousChangedPaymentMethod}
                </div>
                <img src={arrowNextIcon} alt="arrow next" />
                <div className="pharmacy-current">
                  {event.currentChangedPaymentMethod}
                </div>
              </div>
            )}
          {event.previousChangedDeliveryPaymentMethod &&
            event.currentChangedDeliveryPaymentMethod && (
              <div className="pharmacy-block">
                <div className="pharmacy-previous">
                  {event.previousChangedDeliveryPaymentMethod}
                </div>
                <img src={arrowNextIcon} alt="arrow next" />
                <div className="pharmacy-current">
                  {event.currentChangedDeliveryPaymentMethod}
                </div>
              </div>
            )}
        </div>
      </td>
    </Styled.HistoryTableRow>
  );
};

export default HistoryTableRow;
