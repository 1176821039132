import React from 'react';
import { ClickAwayListener } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectors } from '../../../../../OrderPage/reducer';
import * as Styled from './FilterByStatus.styles';
import { getFromLocalStorage } from '../../../../../../global/helpers';
import { UserRole } from '../../../../types';
import { StatusType } from '../../../../../../global/types';

interface FilterByStatusProps {
  onClose: () => void;
  onStatusClick: (
    statusName: string,
    statusCode: string,
    statusColor: string
  ) => void;
  onAllStatusClick: () => void;
}

const FilterByStatus = ({
  onClose,
  onStatusClick,
  onAllStatusClick,
}: FilterByStatusProps) => {
  const role = getFromLocalStorage('role');
  const { t } = useTranslation();

  const allStatuses = useSelector(selectors.allStatuses);
  const pharmacyStatuses = useSelector(selectors.allPharmacyStatuses).filter(
    (status: any) => status.available
  );

  const titleStrategy = (status: string, name: string) => {
    switch (status) {
      case StatusType.ON_CLARIFICATION:
        return role === UserRole.OPERATOR || role === UserRole.HEAD_OPERATOR
          ? 'Аптека предложила изменения'
          : ' На утверждении';
      case StatusType.IN_PHARMACY_PLACED:
        return role === UserRole.OPERATOR || role === UserRole.HEAD_OPERATOR
          ? 'Аптека получила '
          : 'Новый';
      case StatusType.CANCELED:
        return role !== UserRole.OPERATOR && role !== UserRole.HEAD_OPERATOR
          ? 'Отменен'
          : name;
      default:
        return name;
    }
  };

  const colorsStrategy = (status: string, color: string) => {
    switch (status) {
      case StatusType.IN_PHARMACY_PLACED:
        return role === UserRole.OPERATOR || role === UserRole.HEAD_OPERATOR
          ? '0A77C7'
          : '1FA069';
      case StatusType.CANCELED:
        return role !== UserRole.OPERATOR && role !== UserRole.HEAD_OPERATOR
          ? '7F8895'
          : color;
      default:
        return color;
    }
  };

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Styled.StatusContainer>
        <div className="title" key="all" onClick={onAllStatusClick}>
          {t('OrdersPage.OrdersTable.statusTitles.ALl')}
        </div>
        {(role === UserRole.OPERATOR ||
        role === UserRole.ADMIN ||
        role === UserRole.HEAD_OPERATOR
          ? allStatuses
          : pharmacyStatuses
        ).map((item: any) => (
          <div
            className="title"
            key={item.code}
            onClick={() =>
              onStatusClick(
                titleStrategy(item.code, item.name),
                item.code,
                colorsStrategy(item.code, item.color)
              )
            }
          >
            {titleStrategy(item.code, item.name)}
          </div>
        ))}
      </Styled.StatusContainer>
    </ClickAwayListener>
  );
};

export default FilterByStatus;
