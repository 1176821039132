import { createReducer } from '@reduxjs/toolkit';
import * as actions from './actions';
import { AppState } from '../../global/types';
import { OperatorPageState } from './types';

const initialState: OperatorPageState = {
  usersData: {
    data: null,
    loading: false,
    error: null,
  },
};

const reducer = createReducer(initialState, {
  [actions.getOperators.REQUEST]: (state) => {
    state.usersData.loading = true;
  },
  [actions.getOperators.ERROR]: (state, action) => {
    state.usersData.error = action.payload;
    state.usersData.loading = false;
  },
  [actions.getOperators.SUCCESS]: (state, action) => {
    state.usersData.data = action.payload;
    state.usersData.loading = false;
  },
});

const operatorsData = (state: AppState) =>
  state.operatorsPageReducer.usersData.data;

const selectors = {
  operatorsData,
};

export { selectors };
export default reducer;
