import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import ModalWindow from '../../../../../../components/ModalWindow';
import { CreateTagSchema } from '../../../../../SourcePage/helpers';
import { AddTagForm } from '../../../../../SourcePage/types';
import * as Styled from './CreateTagModal.styles';

interface CreateTagModalProps {
  onCloseTagCreatingModal: () => void;
  onCreateTagHandler: (values: {
    name: string;
    color: string;
    description: string;
  }) => void;
}

const CreateTagModal = ({
  onCloseTagCreatingModal,
  onCreateTagHandler,
}: CreateTagModalProps) => {
  const { t } = useTranslation();

  const renderForm = ({
    values,
    handleSubmit,
    handleChange,
    errors,
  }: AddTagForm) => {
    return (
      <form onSubmit={handleSubmit}>
        <Styled.CreateTagFormContainer>
          <div className="field-container">
            <span className="label">
              {t('PharmacyTagsPage.TAG_CREATE_MODAL.NAME')}
            </span>
            <input value={values.name} onChange={handleChange} name="name" />
            {errors?.name && <div className="error-field">{errors?.name}</div>}
          </div>
          <div className="field-container">
            <span className="label">
              {t('PharmacyTagsPage.TAG_CREATE_MODAL.COLOR')}
            </span>
            <input value={values.color} onChange={handleChange} name="color" />
            {errors?.color && (
              <div className="error-field">{errors?.color}</div>
            )}
          </div>
          <div className="field-container">
            <span className="label">
              {t('PharmacyTagsPage.TAG_CREATE_MODAL.DESCRIPTION')}
            </span>
            <input
              value={values.description}
              onChange={handleChange}
              name="description"
            />
            {errors?.description && (
              <div className="error-field">{errors?.description}</div>
            )}
          </div>
          <button
            type="submit"
            className={`submit-btn ${
              Object.keys(errors).length > 0 && 'disabled'
            }`}
          >
            {t('PharmacyTagsPage.TAG_CREATE_MODAL.CREATE')}
          </button>
        </Styled.CreateTagFormContainer>
      </form>
    );
  };
  return (
    <ModalWindow onClose={onCloseTagCreatingModal}>
      <Styled.CreateTagContainer>
        <Formik
          initialValues={{
            name: '',
            color: '',
            description: '',
          }}
          validationSchema={CreateTagSchema}
          onSubmit={(values) => onCreateTagHandler(values)}
        >
          {renderForm}
        </Formik>
      </Styled.CreateTagContainer>
    </ModalWindow>
  );
};

export default CreateTagModal;
