import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import {
  SortableItemProps,
  SortableList,
} from '@thaddeusjiang/react-sortable-list';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  formatTime,
  getFromLocalStorage,
  setToLocalStorage,
} from '../../global/helpers';
import * as Styled from './AnalogsPage.styles';
import { StyledAnalogsPageContainer } from './AnalogsPage.styles';
import * as actions from './actions';
import { selectors } from './reducer';
import { AnalogDataFormated } from './types';

const AnalogsPage = () => {
  const dispatch = useDispatch();
  const analogsData = useSelector(selectors.analogsData);
  const { t } = useTranslation();
  const token = getFromLocalStorage('accessToken');
  const [searchBySkuValue, setSearchBySkuValue] = useState<string>('');
  const [addProductValue, setAddProductValue] = useState<string>('');
  const [dataType, setDataType] = useState<string>('analog');
  const [addedAnalogDataType, setAddedAnalogDataType] =
    useState<string>('analog');
  const [isDraggingActive, setIsDraggingActive] = useState(true);
  const [items, setItems] = useState<
    AnalogDataFormated[] | SortableItemProps[]
  >(analogsData as AnalogDataFormated[]);

  useEffect(() => {
    const foundedAnalogSkuValue =
      getFromLocalStorage('foundedAnalogSku') || null;
    if (foundedAnalogSkuValue) {
      setSearchBySkuValue(foundedAnalogSkuValue);
      setDataType(dataType);
      dispatch(
        actions.getAnalogsListBySku.request({
          key: foundedAnalogSkuValue,
          type: dataType,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (analogsData) {
      setItems(analogsData);
    }
  }, [analogsData]);

  const handleChangeType = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDataType(e.target.value);
  };

  const handleChangeAddingAnalogType = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAddedAnalogDataType(e.target.value);
  };

  const onSaveSuggestionsOrder = () => {
    dispatch(
      actions.saveSuggestionsOrder.request({
        orderedIds: items.map(
          (item: AnalogDataFormated | SortableItemProps) => item.id
        ),
        postEffect: () => {
          dispatch(
            actions.getAnalogsListBySku.request({
              key: searchBySkuValue,
              type: dataType,
            })
          );
          setIsDraggingActive(!isDraggingActive);
        },
      })
    );
  };

  const onDeleteSuggestion = (id: number | string) => {
    dispatch(
      actions.deleteSuggestion.request({
        id,
        postEffect: () => {
          dispatch(
            actions.getAnalogsListBySku.request({
              key: searchBySkuValue,
              type: dataType,
            })
          );
        },
      })
    );
  };

  const onSearchProduct = () => {
    dispatch(
      actions.getAnalogsListBySku.request({
        key: searchBySkuValue,
        type: dataType,
        postEffect: () => {
          setToLocalStorage('foundedAnalogSku', searchBySkuValue);
        },
      })
    );
  };

  const onAddProduct = () => {
    dispatch(
      actions.createSuggestion.request({
        key: searchBySkuValue,
        type: addedAnalogDataType,
        token,
        value: addProductValue,
        postEffect: () => {
          dispatch(
            actions.getAnalogsListBySku.request({
              key: searchBySkuValue,
              type: dataType,
            })
          );
        },
      })
    );
    setAddProductValue('');
  };

  const onCancelOrderChanging = () => {
    setItems(analogsData as AnalogDataFormated[]);
    setIsDraggingActive(!isDraggingActive);
  };

  const orderForm = ({
    values,
    handleSubmit,
  }: {
    values: AnalogDataFormated;
    handleSubmit: () => void;
  }) => {
    return (
      <form onSubmit={handleSubmit}>
        <Styled.TableBodyContainer>
          <SortableList
            items={items as SortableItemProps[]}
            disabled={isDraggingActive}
            setItems={
              setItems as React.Dispatch<
                React.SetStateAction<SortableItemProps[]>
              >
            }
            // eslint-disable-next-line react/no-unstable-nested-components
            itemRender={({ item }) => (
              <div
                className={`product-wrapper ${
                  !isDraggingActive ? 'product-wrapper--dragging' : ''
                }  `}
                style={{ width: '100%', display: 'flex' }}
                key={item.id}
              >
                <div className="table-cell table-cell-lg name-container">
                  <p className="name-title">{item.name}</p>
                  <p className="name-title">{item.value}</p>
                  <img
                    width="120px"
                    height="120px"
                    src={`https://db-images.object.pscloud.io/optimized_v4_img_middle250_${item.value}.webp`}
                    alt=""
                  />
                </div>
                <div className="table-cell table-cell-xsm">
                  {item.approved ? 'Да' : 'нет'}
                </div>
                <div className="table-cell table-cell-xsm">
                  {item.replaceable ? 'Да' : 'нет'}
                </div>
                <div className="table-cell name-container">
                  <div>{formatTime(item.createdAt)}</div>
                  <br />
                  <div className="user-name-title">{item.updatedBy}</div>
                </div>
                <div className="table-cell table-cell-sm">
                  <div
                    className="assembly-btn"
                    onClick={() => onDeleteSuggestion(item.id)}
                  >
                    {t('AnalogsPage.ACTIONS.UNMAP')}
                  </div>
                </div>
              </div>
            )}
          />
        </Styled.TableBodyContainer>
      </form>
    );
  };

  const tableHeader = (
    <Styled.AnalogsPageTableHeaderContainer>
      <div className="header-row header-row-lg">
        {t('AnalogsPage.TABLE.HEADER.NAME')}
      </div>
      <div className="header-row header-row-xsm">
        {t('AnalogsPage.TABLE.HEADER.APPROVED')}
      </div>
      <div className="header-row header-row-xsm">
        {t('AnalogsPage.TABLE.HEADER.REPLACEABLE')}
      </div>
      <div className="header-row">
        {t('AnalogsPage.TABLE.HEADER.CREATED_AT')}
      </div>
      <div className="header-row header-row-sm">
        {t('AnalogsPage.TABLE.HEADER.ACTIONS')}
      </div>
    </Styled.AnalogsPageTableHeaderContainer>
  );

  return (
    <StyledAnalogsPageContainer>
      <Styled.SearchContainer>
        <Styled.ContainerInput>
          <input
            autoComplete="off"
            value={searchBySkuValue}
            type="text"
            className="search-input"
            placeholder="Найти товар по sku"
            onChange={(event) => setSearchBySkuValue(event.target.value)}
          />
          {searchBySkuValue && (
            <div
              className="clear-input"
              onClick={() => setSearchBySkuValue('')}
            >
              {t('SourcesPage.CLEAR')}
            </div>
          )}
        </Styled.ContainerInput>
        <Styled.ContainerRadioBox>
          <RadioGroup
            aria-labelledby="radio-buttons-group-label"
            defaultValue="name"
            row
            name="radio-buttons-group"
            onChange={handleChangeType}
          >
            <FormControlLabel
              value="analog"
              control={<Radio />}
              label="analog"
            />
            <FormControlLabel value="form" control={<Radio />} label="form" />
          </RadioGroup>
        </Styled.ContainerRadioBox>
        <Styled.ButtonSearch onClick={onSearchProduct}>
          {t('SourcesPage.SEARCH')}
        </Styled.ButtonSearch>
      </Styled.SearchContainer>

      {items && (
        <Styled.StyledAnalogDragingButtonsContainer>
          {isDraggingActive ? (
            <div className="change-order-buttons-container">
              <div
                className="button-active"
                onClick={() => {
                  setIsDraggingActive(!isDraggingActive);
                }}
              >
                {t('AnalogsPage.ACTIONS.CHANGE_ORDER')}
              </div>
            </div>
          ) : (
            <div className="change-order-buttons-container">
              <div className="button-active" onClick={onSaveSuggestionsOrder}>
                {t('AnalogsPage.ACTIONS.SAVE')}
              </div>
              <div className="button-cancel" onClick={onCancelOrderChanging}>
                {t('AnalogsPage.ACTIONS.CANCEL')}
              </div>
            </div>
          )}
        </Styled.StyledAnalogDragingButtonsContainer>
      )}
      {items && (
        <>
          {tableHeader}
          <Formik
            initialValues={items}
            enableReinitialize
            onSubmit={(props) => {}}
          >
            {orderForm}
          </Formik>
        </>
      )}
      {items && (
        <Styled.SearchContainer>
          <Styled.ContainerInput>
            <input
              autoComplete="off"
              value={addProductValue}
              type="text"
              className="search-input"
              placeholder="Добавить товар по sku"
              onChange={(event) => setAddProductValue(event.target.value)}
            />
            {addProductValue && (
              <div
                className="clear-input"
                onClick={() => setAddProductValue('')}
              >
                {t('SourcesPage.CLEAR')}
              </div>
            )}
          </Styled.ContainerInput>
          <Styled.ContainerRadioBox>
            <RadioGroup
              aria-labelledby="radio-buttons-group-label"
              defaultValue="name"
              row
              name="radio-buttons-group"
              onChange={handleChangeAddingAnalogType}
            >
              <FormControlLabel
                value="analog"
                control={<Radio />}
                label="analog"
              />
              <FormControlLabel value="form" control={<Radio />} label="form" />
            </RadioGroup>
          </Styled.ContainerRadioBox>
          <Styled.ButtonSearch onClick={onAddProduct}>
            {t('AnalogsPage.ACTIONS.ADD_PRODUCT')}
          </Styled.ButtonSearch>
        </Styled.SearchContainer>
      )}
    </StyledAnalogsPageContainer>
  );
};

export default AnalogsPage;
