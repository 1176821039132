import React from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import { ProductResponse } from '../../../../types';
import ProductsTableRow from '../ProductsTableRow';
import {
  getFromLocalStorage,
  getUniqueID,
} from '../../../../../../global/helpers';
import { ACTIVE_TAB_KEY } from '../../../../constants';

interface ProductsTapProps {
  data: ProductResponse[] | [] | undefined;
  results: ProductResponse[] | [] | undefined;
  isOpen: boolean;
  loading: boolean;
  searchValue: string;
  type:
    | 'attached'
    | 'unattached'
    | 'blocked'
    | 'verification'
    | 'dybl'
    | 'automapping';
  setData: any;
}

const ProductsTab = ({
  data,
  results,
  isOpen,
  type,
  loading,
  searchValue,
  setData,
}: ProductsTapProps) => {
  const { t } = useTranslation();
  const activeTab = getFromLocalStorage(ACTIVE_TAB_KEY);

  return (
    <>
      {data &&
        !results &&
        isOpen &&
        !searchValue &&
        data.map((item: ProductResponse, index: number) => (
          <ProductsTableRow
            data={item}
            key={getUniqueID()}
            type={type}
            searchValue={searchValue}
            setData={setData}
          />
        ))}
      {/* eslint-disable-next-line */}
      {loading && type === activeTab && !results ? (
        <tr>
          <td style={{ paddingTop: '32px' }}>
            <CircularProgress size={15} />
          </td>
        </tr>
      ) : results?.length === 0 && isOpen ? (
        <div style={{ paddingTop: '32px' }}>{t('ProductsPage.NO_RESULTS')}</div>
      ) : (
        results !== null &&
        isOpen &&
        results?.map((item: ProductResponse) => (
          <ProductsTableRow
            data={item}
            key={getUniqueID()}
            type={type}
            searchValue={searchValue}
            setData={setData}
          />
        ))
      )}
    </>
  );
};

export default ProductsTab;
