import React, { useState } from 'react';
import { DiscountProduct, SalesFormatedPharmacy } from '../types';

const AllSalesProductsTable = ({
  el,
  ind,
}: {
  el: DiscountProduct | null;
  ind: number;
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currentPharmacy, setCurrentPharmacy] =
    useState<null | SalesFormatedPharmacy>(null);

  if (!el) return null;

  return (
    <tr key={el.sku}>
      <td
        className="product-item item-column"
        style={{ textAlign: 'center', width: 180 }}
      >
        {ind + 1}
      </td>
      <td
        className="product-item item-column"
        style={{ textAlign: 'center', width: 180 }}
      >
        {el.city}
      </td>
      <td className="product-item item-column" style={{ width: 230 }}>
        <p
          style={{ width: 230, overflow: 'hidden', overflowWrap: 'break-word' }}
        >
          {el.pharmacy_name}
        </p>
        <p
          style={{ width: 230, overflow: 'hidden', overflowWrap: 'break-word' }}
        >
          {el.pharmacy_code}
        </p>
      </td>
      <td className="product-item item-column" style={{ width: 230 }}>
        <p>{el.name}</p>
        <p>{el.sku.split('_discount')[0]}</p>
      </td>
      <td
        className="product-item item-column"
        style={{ textAlign: 'center', width: 180 }}
      >
        {el.base_price}
      </td>
      <td
        className="product-item item-column"
        style={{ textAlign: 'center', width: 180 }}
      >
        {el.sku.split('_')[3].split('-').reverse().join('.')}
      </td>
      <td
        className="product-item item-column"
        style={{ textAlign: 'center', width: 180 }}
      >
        {el.series}
      </td>
      <td
        className="product-item item-column"
        style={{ textAlign: 'center', width: 180 }}
      >
        {el.price}
      </td>
      <td
        className="product-item item-column"
        style={{ textAlign: 'center', width: 180 }}
      >
        {el.comment || ''}
      </td>
    </tr>
  );
};

export default AllSalesProductsTable;
