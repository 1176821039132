import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { ActivePharmacistService } from './service';
import { ActiveUserResponse } from './types';
import * as actionsNotification from '../../containers/InitComponent/actions';
import {
  NOTIFICATION_CHANGES_SAVED,
  NOTIFICATION_CHANGES_NOT_SAVED,
} from '../../global/constants';

interface GetActiveUsersResponse {
  result: ActiveUserResponse[] | null | [];
  status: string;
}

interface GetActiveUsersParams {
  type: string;
  payload: { sourceCode: string };
}

interface UpdateActiveUsersParams {
  type: string;
  payload: {
    sourceCode: string;
    networkCode: string;
    phones: string[];
    postEffect: any;
  };
}

export function* getActiveUsersSaga({ payload }: GetActiveUsersParams) {
  try {
    const response: GetActiveUsersResponse = yield call(
      ActivePharmacistService.getAllUsers,
      payload.sourceCode
    );

    yield put(actions.getAllUsers.success(response.result));
  } catch (error) {
    yield put(actions.getAllUsers.error(error));
  }
}

export function* updateActiveUsersSaga({ payload }: UpdateActiveUsersParams) {
  try {
    const response: GetActiveUsersResponse = yield call(
      ActivePharmacistService.updatedActiveUsers,
      payload.sourceCode,
      payload.networkCode,
      payload.phones
    );

    yield put(actions.updateActiveUsers.success(response.result));

    if (payload.postEffect) {
      payload.postEffect();
    }
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_SAVED,
        canBeClosed: true,
        type: 'success',
      })
    );
  } catch (error) {
    yield put(actions.updateActiveUsers.error(error));
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
  }
}

export function* activeUsersWatcherSaga() {
  yield all([takeLatest(actions.getAllUsers.REQUEST, getActiveUsersSaga)]);
  yield all([
    takeLatest(actions.updateActiveUsers.REQUEST, updateActiveUsersSaga),
  ]);
}
