import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Styled from './PharmacistPromocodesTableData.styles';
import CustomToggle from '../../../../components/CustomToggle';
import * as actions from '../../../PromoCodePage/actions';

interface PharmacistPromocodesTableDataProps {
  phone?: string;
  code?: string;
  pharmacy?: string;
  setIsBlocked: React.Dispatch<React.SetStateAction<boolean>>;
  isBlocked: boolean;
  handleClick: (code: string) => void;
}

const PharmacistPromocodesTableData = ({
  phone,
  code,
  pharmacy,
  setIsBlocked,
  isBlocked,
  handleClick,
}: PharmacistPromocodesTableDataProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    code &&
      dispatch(
        actions.getPromocodeStatusByCode.request({
          code,
        })
      );
  }, [code]);

  const tableHeader = (
    <Styled.StyledTableHeaderContainer>
      <div className="header-row header-row-lg  ">Номер фармацевта</div>
      <div className="header-row header-row-lg ">Аптека</div>
      <div className="header-row header-row-lg ">Закрепленный промокод</div>
      <div className="header-row header-row-lg">Заблокировать</div>
      <div className="header-row header-row-lg ">Действие</div>
    </Styled.StyledTableHeaderContainer>
  );

  return (
    <Styled.StyledTableContainer>
      {tableHeader}
      <Styled.TableBodyContainer>
        <div className="table-cell-lg table-cell">{phone}</div>
        <div className="table-cell-lg table-cell">{pharmacy}</div>
        <div className="table-cell-lg table-cell">{code}</div>
        <div className="table-cell-lg table-cell">
          <div className="flex-container-toggle">
            Нет
            <CustomToggle
              onChange={(e) => {
                setIsBlocked(!isBlocked);
              }}
              id="activate-promo-code"
              checked={isBlocked}
              variant="standart"
            />{' '}
            Да
          </div>
        </div>
        <div className="table-cell-lg table-cell">
          <button
            type="button"
            className="button"
            onClick={() => {
              code && handleClick(code);
            }}
          >
            Ок
          </button>
        </div>
      </Styled.TableBodyContainer>
    </Styled.StyledTableContainer>
  );
};

export default PharmacistPromocodesTableData;
