import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  Typography,
  Grid,
  ClickAwayListener,
  Tooltip,
  Button,
  TooltipProps,
  tooltipClasses,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import editIcon from '../../../../global/media/edit-pencil.svg';
import saveIcon from '../../../../global/media/save-discet-icon.svg';
import * as Styled from './InterPayReport.styles';
import { OrderPageState } from '../../types';
import { UserRole } from '../../../OrdersPage/types';
import { getFromLocalStorage } from '../../../../global/helpers';
import * as actionsOrder from '../../actions';

const convertStatus = (status: string) => {
  switch (status) {
    case 'order_closed':
      return 'Заказ закрыт';
    case 'wait_paid':
      return 'Выплата аптеке ожидается';
    case 'paid':
      return 'Выплата аптеке произведена';
    default:
      return '';
  }
};

interface InterpayPayouts {
  orderId: string;
  status: string;
  payoutDate: string;
  comment?: string;
  payoutInfo?: string;
}

interface EditConfirmationProps {
  interpayPayouts: OrderPageState['interpayPayouts'];
  orderId: string;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const InterPayReport = ({
  interpayPayouts,
  orderId,
}: EditConfirmationProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const role = getFromLocalStorage('role');

  const [editRowIndex, setEditRowIndex] = useState<number | null>(null);
  const [editedRow, setEditedRow] = useState<InterpayPayouts | null>(null);

  const [initialStates, setInitialStates] = useState<InterpayPayouts>({
    orderId,
    status: 'paid',
    payoutDate: new Date().toISOString().split('T')[0],
    comment: '',
    payoutInfo: '',
  });
  const [open, setOpen] = React.useState(false);

  const handleEditClick = (index: number, row: any) => {
    const transformedRow: InterpayPayouts = {
      orderId: row.order_id,
      status: row.status,
      payoutDate: row.payout_date,
      comment: row.comment,
      payoutInfo: row.payout_info,
    };

    setEditRowIndex(index);
    setEditedRow(transformedRow);
  };

  const handleChangeSubmit = () => {
    if (editedRow) {
      const formattedRow = {
        orderId: editedRow.orderId,
        status: editedRow.status,
        payoutInfo: editedRow.payoutInfo,
        comment: editedRow.comment,
        ...(editedRow.status === 'paid' && {
          payoutDate: new Date(editedRow.payoutDate).toISOString(),
        }),
      };

      dispatch(
        actionsOrder.changeInterpayPayouts.request({
          orderId,
          status: formattedRow.status,
          payoutDate: formattedRow.payoutDate,
          comment: formattedRow.comment,
          payoutInfo: formattedRow.payoutInfo,
          postEffect: () =>
            dispatch(actionsOrder.getInterpayPayouts.request({ orderId })),
        })
      );
      setEditRowIndex(null);
    }
  };

  const handleInputChange = (field: keyof InterpayPayouts, value: any) => {
    if (editedRow) {
      setEditedRow({ ...editedRow, [field]: value });
    }
  };

  const handleInitialStateChange = (
    field: keyof InterpayPayouts,
    value: any
  ) => {
    setInitialStates((prev: any) => ({ ...prev, [field]: value }));
  };

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
      placement={isMobile ? 'left-start' : 'right-start'}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: isMobile ? 350 : 550,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  const handlePaidSubmit = () => {
    const formattedState = {
      ...initialStates,
      payoutDate: new Date(initialStates.payoutDate).toISOString(),
    };

    dispatch(
      actionsOrder.changeInterpayPayouts.request({
        orderId,
        status: formattedState.status,
        payoutDate: formattedState.payoutDate,
        comment: formattedState.comment,
        payoutInfo: formattedState.payoutInfo,
        postEffect: () =>
          dispatch(actionsOrder.getInterpayPayouts.request({ orderId })),
      })
    );
  };

  return (
    <Styled.InterPayContainer>
      <Styled.InterPayTop>
        <Typography variant="h6">{t('OrderPage.INTERPAY')}</Typography>

        <ClickAwayListener
          onClickAway={(event) => {
            const target = event.target as HTMLElement;
            if (target.closest('.tooltipBtn')) {
              return;
            }
            setOpen(false);
          }}
        >
          <div>
            <HtmlTooltip
              title={
                <>
                  <Typography fontWeight={700}>
                    {t('OrderPage.INTERPAY_FAQ_1')}
                  </Typography>
                  <Typography>{t('OrderPage.INTERPAY_ANSWER_1')}</Typography>
                  <Typography>{t('OrderPage.INTERPAY_ANSWER_2')}</Typography>
                  <Typography fontWeight={700}>
                    {t('OrderPage.INTERPAY_FAQ_2')}
                  </Typography>
                  <Typography>{t('OrderPage.INTERPAY_ANSWER_3')}</Typography>
                </>
              }
              open={open}
              onClose={() => setOpen(false)}
              disableHoverListener
              disableFocusListener
              disableTouchListener
            >
              <button
                type="button"
                className="tooltipBtn"
                onClick={() => setOpen(!open)}
              >
                i
              </button>
            </HtmlTooltip>
          </div>
        </ClickAwayListener>
      </Styled.InterPayTop>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 550 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Статус</StyledTableCell>
              <StyledTableCell align="center">Дата</StyledTableCell>
              <StyledTableCell>Комментарий</StyledTableCell>
              <StyledTableCell>Платежное поручение</StyledTableCell>
              {role === UserRole.ADMIN && (
                <StyledTableCell>Действие</StyledTableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {interpayPayouts.map((row, index) => (
              <TableRow key={row.status}>
                <TableCell sx={{ maxWidth: '200px' }}>
                  {convertStatus(row.status)}
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  {editRowIndex === index &&
                  row.status === 'paid' &&
                  role === UserRole.ADMIN ? (
                    <TextField
                      type="date"
                      value={
                        editedRow
                          ? new Date(editedRow.payoutDate)
                              .toISOString()
                              .split('T')[0]
                          : ''
                      }
                      onChange={(e) =>
                        handleInputChange('payoutDate', e.target.value)
                      }
                      fullWidth
                    />
                  ) : (
                    new Date(row.payout_date).toLocaleDateString()
                  )}
                </TableCell>
                <TableCell sx={{ height: 'fit-content', maxWidth: '200px' }}>
                  {editRowIndex === index && role === UserRole.ADMIN ? (
                    <TextField
                      value={editedRow?.comment || ''}
                      onChange={(e) =>
                        handleInputChange('comment', e.target.value)
                      }
                      variant="outlined"
                      fullWidth
                    />
                  ) : (
                    row.comment || ''
                  )}
                </TableCell>
                <TableCell sx={{ maxWidth: '200px' }}>
                  {editRowIndex === index &&
                  row.status === 'paid' &&
                  role === UserRole.ADMIN ? (
                    <TextField
                      value={editedRow?.payoutInfo || ''}
                      onChange={(e) =>
                        handleInputChange('payoutInfo', e.target.value)
                      }
                      variant="outlined"
                      fullWidth
                    />
                  ) : (
                    row.payout_info || ''
                  )}
                </TableCell>
                {role === UserRole.ADMIN && (
                  <TableCell align="center" sx={{ cursor: 'pointer' }}>
                    {editRowIndex === index ? (
                      <IconButton onClick={handleChangeSubmit}>
                        <img
                          width={25}
                          height={25}
                          src={saveIcon}
                          alt="save"
                          className="save-icon"
                        />
                      </IconButton>
                    ) : (
                      <IconButton onClick={() => handleEditClick(index, row)}>
                        <img src={editIcon} alt="edit" className="edit-icon" />
                      </IconButton>
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))}
            {role === UserRole.ADMIN &&
              !interpayPayouts.some((el) => el.status === 'paid') && (
                <TableRow>
                  <TableCell>Выплата аптеке произведена</TableCell>
                  <TableCell component="th" scope="row" align="center">
                    <TextField
                      type="date"
                      value={initialStates.payoutDate || ''}
                      onChange={(e) =>
                        handleInitialStateChange('payoutDate', e.target.value)
                      }
                      fullWidth
                    />
                  </TableCell>
                  <TableCell sx={{ height: 'fit-content' }}>
                    <TextField
                      value={initialStates.comment || ''}
                      onChange={(e) =>
                        handleInitialStateChange('comment', e.target.value)
                      }
                      variant="outlined"
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={initialStates.payoutInfo || ''}
                      onChange={(e) =>
                        handleInitialStateChange('payoutInfo', e.target.value)
                      }
                      variant="outlined"
                      fullWidth
                    />
                  </TableCell>
                  {role === UserRole.ADMIN && (
                    <TableCell align="center" sx={{ cursor: 'pointer' }}>
                      <IconButton onClick={handlePaidSubmit}>
                        <img
                          width={25}
                          height={25}
                          src={saveIcon}
                          alt="save"
                          className="save-icon"
                        />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </Styled.InterPayContainer>
  );
};

export default InterPayReport;
