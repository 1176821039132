import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './ModalEditHours.styles';
import ModalWindow from '../../components/ModalWindow/ModalWindow';
import OneLine from './OneLine';
import CustomCheckbox from '../../components/CustomCheckbox/CustomCheckbox';
import { DayType, Time } from './OneLine/OneLine';
import { ScheduleType } from '../../containers/SourcePage/types';
import * as constants from './constants';

interface ModalEditHoursProps {
  setIsModalOpen: (a: boolean) => void;
  schedule: ScheduleType;
  setSchedule: (data: ScheduleType) => void;
  scheduleData: ScheduleType;
  setHoursEmptyField: (a: boolean) => void;
}

const initSchedule = () =>
  Array(7)
    .fill(1)
    .map((_, i) => ({
      dayOFWeek: i + 1,
      from: { hours: -1, minutes: -1 },
      to: { hours: -1, minutes: -1 },
    }));

const ModalEditHours: React.FC<ModalEditHoursProps> = ({
  setIsModalOpen,
  schedule: scheduleState,
  setSchedule: setScheduleState,
  scheduleData,
  setHoursEmptyField,
}) => {
  const { t } = useTranslation();
  const [chooseWorkDays, setChooseWorkDays] = useState(false);
  const [chooseAllDays, setChooseAllDays] = useState(false);
  const [needValidate, setNeedValidate] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [schedule, setSchedule] = useState(() => initSchedule());
  useEffect(() => {
    if (chooseAllDays) {
      setChooseWorkDays(false);
    }
  }, [chooseAllDays]);

  useEffect(() => {
    if (chooseWorkDays) {
      setChooseAllDays(false);
    }
  }, [chooseWorkDays]);

  useEffect(() => {
    if (scheduleData && scheduleData.length !== 0) {
      const sundayArr = scheduleData.filter((day) => day.day_of_week === 0);
      const sunday = sundayArr.length > 0 && {
        ...sundayArr[0],
        day_of_week: 7,
      };

      const partialSchedule = scheduleData.filter(
        (day) => day.day_of_week !== 0
      );
      const formatedSchedule = sunday
        ? ([...partialSchedule, sunday] as ScheduleType)
        : partialSchedule;

      const newSchedule = formatedSchedule.map((day) => {
        const from = day.from.split(':');
        const to = day.to.split(':');
        return {
          dayOFWeek: day.day_of_week,
          from: { hours: Number(from[0]), minutes: Number(from[1]) },
          to: { hours: Number(to[0]), minutes: Number(to[1]) },
        };
      });
      for (let i = 1; i < 8; i += 1) {
        if (!newSchedule.find((el) => el.dayOFWeek === i))
          newSchedule.push({
            dayOFWeek: i,
            from: { hours: -2, minutes: -2 },
            to: { hours: -2, minutes: -2 },
          });
      }

      setSchedule(
        newSchedule.sort((day1, day2) => day1.dayOFWeek - day2.dayOFWeek)
      );
    }
  }, [scheduleData]);

  const convertNumber = (val: number) => {
    switch (val) {
      case 0:
        return '00';
      case -1:
        return '';
      case -2:
        return '--';
      default:
    }
    return val < 10 ? `0${val}` : `${val}`;
  };

  const translateToBackendFormat = ({ hours, minutes }: Time) =>
    `${convertNumber(hours)}:${convertNumber(minutes)}`;

  const onSave = () => {
    if (
      schedule.find(
        (day) =>
          day.from.hours === -1 ||
          day.from.minutes === -1 ||
          day.to.hours === -1 ||
          day.to.minutes === -1
      )
    ) {
      setNeedValidate(true);
    } else {
      const sunday = { ...schedule.filter((day) => day.dayOFWeek === 7)[0] };
      sunday.dayOFWeek = 0;
      const week = schedule.filter((day) => day.dayOFWeek !== 7);
      const formattedSchedule = [sunday, ...week] as Array<DayType>;

      setScheduleState(
        formattedSchedule
          .filter(
            (day, i) =>
              day.from.hours >= 0 &&
              day.from.minutes >= 0 &&
              day.to.hours >= 0 &&
              day.to.minutes >= 0 &&
              i !== 7
          )
          .map((day) => ({
            day_of_week: day.dayOFWeek,
            from: translateToBackendFormat(day.from),
            to: translateToBackendFormat(day.to),
          }))
      );

      setIsModalOpen(false);
      setHoursEmptyField(true);
    }
  };

  return (
    <ModalWindow
      onClose={() => {
        setIsModalOpen(false);
      }}
    >
      <Styled.ContainerCheckbox>
        <CustomCheckbox
          title={t('ModalHours.SEVEN_DAYS')}
          id="allDay"
          onChange={() => {
            setChooseAllDays(!chooseAllDays);
            setSchedule(initSchedule());
          }}
          checked={chooseAllDays}
        />
        <CustomCheckbox
          title={t('ModalHours.WORK_DAYS')}
          id="workDay"
          onChange={() => {
            setChooseWorkDays(!chooseWorkDays);
            setSchedule(initSchedule());
          }}
          checked={chooseWorkDays}
        />
      </Styled.ContainerCheckbox>
      {!chooseWorkDays && !chooseAllDays && (
        <Styled.ContainerOneLine>
          {constants.arrayDays.map((el, i) => (
            <OneLine
              key={el}
              setNeedValidate={setNeedValidate}
              needValidate={needValidate}
              setIsValidated={setIsValidated}
              isValidated={isValidated}
              label={el}
              ind={i}
              workDays={false}
              daySchedule={schedule[i]}
              updateDaySchedule={(from: Time, to: Time) =>
                setSchedule((state) =>
                  state.map((day) =>
                    day.dayOFWeek === i + 1
                      ? { dayOFWeek: i + 1, from, to }
                      : day
                  )
                )
              }
            />
          ))}
        </Styled.ContainerOneLine>
      )}
      {chooseWorkDays && (
        <Styled.ContainerOneLine>
          {constants.arrayWorkDays.map((el, ind) => (
            <OneLine
              setNeedValidate={setNeedValidate}
              needValidate={needValidate}
              setIsValidated={setIsValidated}
              isValidated={isValidated}
              key={el}
              label={el}
              ind={ind}
              workDays
              daySchedule={schedule[ind]}
              updateDaySchedule={(from: Time, to: Time) =>
                setSchedule((state) =>
                  state.map((day, i) => {
                    switch (ind) {
                      case 0:
                        return i < 5 ? { dayOFWeek: i + 1, from, to } : day;
                      case 1:
                        return i > 4 ? { dayOFWeek: i + 1, from, to } : day;
                      default:
                        return { dayOFWeek: 0, from, to };
                    }
                  })
                )
              }
            />
          ))}
        </Styled.ContainerOneLine>
      )}
      {chooseAllDays && (
        <Styled.ContainerOneLine>
          {constants.arraySevenDays.map((el) => (
            <OneLine
              key={el}
              setNeedValidate={setNeedValidate}
              needValidate={needValidate}
              setIsValidated={setIsValidated}
              isValidated={isValidated}
              label={el}
              ind={0}
              workDays={false}
              daySchedule={schedule[1]}
              updateDaySchedule={(from: Time, to: Time) =>
                setSchedule((state) =>
                  state.map((_, i) => ({ dayOFWeek: i + 1, from, to }))
                )
              }
            />
          ))}
        </Styled.ContainerOneLine>
      )}
      <Styled.Button onClick={onSave}>{t('ModalHours.CONFIRM')}</Styled.Button>
    </ModalWindow>
  );
};

export default ModalEditHours;
