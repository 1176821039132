export const sort = (x: any, y: any) => {
  if (x.name[0] === ' ' && y.name[0] === ' ') {
    return x.name[1].localeCompare(y.name[1]);
  }
  if (x.name[0] === ' ' && y.name[0] !== ' ') {
    return x.name[1].localeCompare(y.name[0]);
  }
  if (x.name[0] !== ' ' && y.name[0] === ' ') {
    return x.name[0].localeCompare(y.name[1]);
  }
  return x.name.localeCompare(y.name);
};
