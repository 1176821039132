import * as Yup from 'yup';
import {
  formatTime,
  getPharmacyTagsById,
  getTrimmedFields,
} from '../../global/helpers';
import { UserRole } from '../OrdersPage/types';
import { NetworkResponse, SourceResponse } from '../SourcesPage/types';
import {
  PharmacyTag,
  ScheduleType,
  SourceDataOnSourcePage,
  SourceProps,
  User,
  UserResponse,
} from './types';

const TagSchema = {
  color: Yup.string()
    .min(6, 'Минимум 6 символов')
    .max(6, 'Максимум 6 символов')
    .required('Поле не может быть пустым')
    .matches(/^[A-Z0-9#]+$/, 'можно вводить только латинские заглавные буквы'),
  name: Yup.string().required('Поле не может быть пустым'),
};

export const UpdateTagSchema = Yup.array().of(Yup.object().shape(TagSchema));

export const CreateTagSchema = Yup.object(TagSchema);

export const getMappedTagsData = (
  tags: PharmacyTag[],
  pharmacyTags?: { tag_id: number; meta?: string }[]
) => {
  if (pharmacyTags) {
    return tags.map((tag) => ({
      ...tag,
      meta: pharmacyTags.find((item) => item.tag_id === tag.id)?.meta || null,
      color: tag.color ? tag.color.replace(/#/g, '') : '000000',
    }));
  }

  return tags.map((tag) => ({
    ...tag,
    color: tag.color ? tag.color.replace(/#/g, '') : '000000',
  }));
};

export const getFormatedSourceTag = (
  name: string,
  color: string,
  description: string,
  tagsType: string
) => {
  return {
    allowed_roles: ['admin'],
    color: `#${color}`,
    name,
    description,
    required_meta: false,
    type: tagsType,
  };
};

export const getUpdatedTag = (
  values: PharmacyTag[],
  editedItemIndex: number,
  type: string
) => {
  return {
    allowed_roles: ['admin'],
    color: `#${values[editedItemIndex].color}`,
    name: values[editedItemIndex].name,
    description: values[editedItemIndex].description,
    required_meta: false,
    type,
    allowed: values[editedItemIndex].allowed || true,
  };
};

export const mappedDataForSource = (
  data: SourceResponse | null,
  network: NetworkResponse | null,
  tagsResponse: PharmacyTag[] | undefined
) => {
  return data
    ? {
        comments: data.disable_comment,
        name: data.name,
        code: data.code,
        activated: !data.disabled,
        lastSync: data.last_sync ? formatTime(data.last_sync) : '-',
        disableUpdatedAt: data.disable_updated_at
          ? formatTime(data.disable_updated_at)
          : '',
        syncError: data?.sync_error,
        enabledSync: data?.enabled_sync,
        city: data.city,
        address: data.address,
        internalAddress: data.internal_address,
        internalCode: data.internal_code,
        syncFtp: data.sync_ftp,
        openingHours: data.opening_hours,
        networkName: network?.name,
        networkCode: network?.code,
        mappingCode: data?.mapping,
        lat: data.lat,
        lon: data.lon,
        soft: data.soft,
        commisiom: data.agent_commission,
        sales: data.discount,
        rezerv: data.with_reserve,
        payment: data.payment_on_site,
        revaluation: data.revaluation,
        schedule: data.schedule,
        token: data.token,
        connection: data.connection,
        sourceTags: getPharmacyTagsById(data.source_tags, tagsResponse),
        visible: data.visible,
      }
    : null;
};

export const usersOfSource = (users: UserResponse[] | null | undefined) => {
  return users?.map((user) => {
    return {
      name: user.name ? user.name : null,
      role: user.role,
      phone: user.phone,
      source: user.values?.source_code || '',
      createdAt: user.created_at ? formatTime(user.created_at) : '-',
      updatedAt: user.updated_at ? formatTime(user.updated_at) : '-',
    };
  });
};

export const filteredUsers = (users: User[] | null | undefined) => {
  const pharmacist = users?.filter((user) => user.role === UserRole.PHARMACIST);
  const manager = users?.filter((user) => user.role === UserRole.MANAGER);
  const pharmacyManager = users?.filter(
    (user) => user.role === UserRole.PHARMACY_MANAGER
  );

  return {
    pharmacist,
    manager,
    pharmacyManager,
  };
};

export const formatDataForUser = (data: any) => {
  return {
    role: data.role,
  };
};

export const formatDataForSource = (
  values: SourceProps,
  data?: SourceDataOnSourcePage,
  name?: string,
  networkCode?: string,
  payment?: boolean,
  rezerv?: boolean,
  po?: string,
  to1C?: boolean,
  schedule?: ScheduleType
) => {
  return {
    address: getTrimmedFields(values.address),
    internal_address: getTrimmedFields(values.internalAddress),
    agent_commission: Number(values.commision),
    city: getTrimmedFields(values.city),
    code: getTrimmedFields(values.sourceCode),
    mapping: getTrimmedFields(values.mappingCode),
    disabled: !data?.activated || false,
    internal_code: getTrimmedFields(values.internalCode),
    discount: Number(values.sales),
    name: !name ? data?.name : getTrimmedFields(name),
    lat: Number(values.lat),
    lon: Number(values.lon),
    network_code: !networkCode
      ? data?.networkCode
      : getTrimmedFields(networkCode),
    opening_hours: getTrimmedFields(values.openingHours),
    payment_on_site: payment,
    soft: po,
    with_reserve: rezerv,
    revaluation: to1C,
    schedule,
  };
};

export const formatDataForSourceStandart = (
  values: SourceProps,
  data?: SourceDataOnSourcePage,
  name?: string,
  networkCode?: string,
  payment?: boolean,
  rezerv?: boolean,
  po?: string,
  to1C?: boolean,
  schedule?: ScheduleType
) => {
  return {
    address: getTrimmedFields(values.address),
    internal_address: getTrimmedFields(values.internalAddress),
    agent_commission: Number(values.commision),
    city: getTrimmedFields(values.city),
    code: getTrimmedFields(values.sourceCode),
    mapping: getTrimmedFields(values.mappingCode),
    disabled: !data?.activated || false,
    discount: Number(values.sales),
    name: !name ? data?.name : getTrimmedFields(name),
    internal_code: getTrimmedFields(values.internalCode),
    lat: Number(values.lat),
    lon: Number(values.lon),
    network_code: !networkCode
      ? data?.networkCode
      : getTrimmedFields(networkCode),
    opening_hours: getTrimmedFields(values.openingHours),
    payment_on_site: payment,
    soft: po,
    with_reserve: rezerv,
    revaluation: to1C,
    schedule,
    connection: {
      ip_address:
        values.ip_address === ' ' && data?.connection.ip_address
          ? data?.connection.ip_address
          : getTrimmedFields(values.ip_address),
      db_dsn:
        values.db_dsn === ' ' && data?.connection.db_dsn
          ? data?.connection.db_dsn
          : getTrimmedFields(values.db_dsn),
    },
  };
};

export const formatDataForInfoApteka = (
  values: SourceProps,
  data?: SourceDataOnSourcePage,
  name?: string,
  networkCode?: string,
  payment?: boolean,
  rezerv?: boolean,
  po?: string,
  to1C?: boolean,
  schedule?: ScheduleType
) => {
  return {
    address: getTrimmedFields(values.address),
    internal_address: 'dsdsdsd',
    agent_commission: Number(values.commision),
    city: getTrimmedFields(values.city),
    code: getTrimmedFields(values.sourceCode),
    mapping: getTrimmedFields(values.mappingCode),
    disabled: !data?.activated || false,
    discount: Number(values.sales),
    name: !name ? data?.name : getTrimmedFields(name),
    lat: Number(values.lat),
    lon: Number(values.lon),
    network_code: !networkCode
      ? data?.networkCode
      : getTrimmedFields(networkCode),
    opening_hours: getTrimmedFields(values.openingHours),
    payment_on_site: payment,
    soft: po,
    with_reserve: rezerv,
    revaluation: to1C,
    schedule,
    sync_ftp: values.syncFtp === 'Да',
    internal_code: getTrimmedFields(values.internalCode),
  };
};

export const formatDataForSourceRubus = (
  values: SourceProps,
  data?: SourceDataOnSourcePage,
  name?: string,
  networkCode?: string,
  payment?: boolean,
  rezerv?: boolean,
  po?: string,
  to1C?: boolean,
  schedule?: ScheduleType
) => {
  return {
    address: getTrimmedFields(values.address),
    internal_address: getTrimmedFields(values.internalAddress),
    agent_commission: Number(values.commision),
    city: getTrimmedFields(values.city),
    code: getTrimmedFields(values.sourceCode),
    mapping: getTrimmedFields(values.mappingCode),
    disabled: !data?.activated || false,
    discount: Number(values.sales),
    name: !name ? data?.name : getTrimmedFields(name),
    lat: Number(values.lat),
    lon: Number(values.lon),
    internal_code: getTrimmedFields(values.internalCode),
    network_code: !networkCode
      ? data?.networkCode
      : getTrimmedFields(networkCode),
    opening_hours: getTrimmedFields(values.openingHours),
    payment_on_site: payment,
    soft: po,
    with_reserve: rezerv,
    revaluation: to1C,
    schedule,
    connection: {
      token:
        values.token === ' ' && data?.connection.token
          ? data?.connection.token
          : getTrimmedFields(values.token),
      device:
        values.device === ' ' && data?.connection.device
          ? data?.connection.device
          : getTrimmedFields(values.device),
    },
  };
};

export const formatData = (
  values: SourceProps,
  data?: SourceDataOnSourcePage,
  name?: string,
  networkCode?: string,
  payment?: boolean,
  rezerv?: boolean,
  po?: string,
  to1C?: boolean,
  schedule?: ScheduleType,
  empty?: boolean | undefined
) => {
  if (
    po?.toLocaleUpperCase() === 'STANDART' ||
    po?.toLocaleUpperCase() === 'STANDART_NO_DISCOUNT'
  ) {
    return formatDataForSourceStandart(
      values,
      data,
      name,
      networkCode,
      payment,
      rezerv,
      po,
      to1C,
      schedule
    );
  }

  if (po?.toLocaleUpperCase() === 'INFO_APTEKA') {
    return formatDataForInfoApteka(
      values,
      data,
      name,
      networkCode,
      payment,
      rezerv,
      po,
      to1C,
      schedule
    );
  }

  if (po?.toLocaleUpperCase() === 'RUBUS') {
    return formatDataForSourceRubus(
      values,
      data,
      name,
      networkCode,
      payment,
      rezerv,
      po,
      to1C,
      schedule
    );
  }

  return formatDataForSource(
    values,
    data,
    name,
    networkCode,
    payment,
    rezerv,
    po,
    to1C,
    schedule
  );
};

export const isDeviceValid = (device: string | undefined) => {
  const number = /^\d+$/;
  return device ? number.test(getTrimmedFields(device)) : true;
};

export const isTokenValid = (token: string | undefined) => {
  const reg = /^[a-zA-Z0-9]+$/;
  return token ? reg.test(getTrimmedFields(token)) : true;
};
