import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectors } from '../../reducer';
import { selectors as sourceSelectors } from '../../../SourcePage/reducer';
import ModalWindow from '../../../../components/ModalWindow';
import Button from '../../../../components/Button';
import * as Styled from './AddDeleteTagModal.styles';

interface Props {
  onClose: () => void;
  tagsId: string[];
  productName: string;
  onConfirm: () => void;
  variant: 'add' | 'delete';
}

const AddDeleteTagModal = ({
  onClose,
  variant,
  onConfirm,
  tagsId,
  productName,
}: Props) => {
  const { t } = useTranslation();
  const addTag = variant === 'add';
  const deleteTag = variant === 'delete';

  const tagsData = useSelector(selectors.tags);

  return (
    <ModalWindow onClose={onClose}>
      <Styled.Title>
        {addTag && t('ProductsPage.TagsModal.ADD_TITLE')}
        {deleteTag && t('ProductsPage.TagsModal.DELETE_TITLE')}
      </Styled.Title>
      <Styled.Info>
        <div className="tags">
          {addTag && t('ProductsPage.TagsModal.TAGS')}
          {tagsId.map((e) => {
            const tag = tagsData.find(
              (el: { tagId: string }) => el.tagId === e
            );
            return (
              <div
                key={e}
                className="tag"
                style={{ backgroundColor: tag.tagColor }}
              >
                {tag.tagName}
              </div>
            );
          })}
        </div>
        <div className="product-name">
          {t('ProductsPage.TagsModal.PRODUCT_NAME')}
          <div>{productName}</div>
        </div>
      </Styled.Info>
      <Styled.ButtonsContainer>
        <div className="save-btn">
          <Button
            onClick={onConfirm}
            text={t('ProductsPage.TagsModal.CONFIRM')}
            variant="contained"
          />
        </div>
        <div className="cancel-btn">
          <Button
            onClick={onClose}
            text={t('ProductsPage.TagsModal.CANCEL_BTN')}
            variant="outlined"
          />
        </div>
      </Styled.ButtonsContainer>
    </ModalWindow>
  );
};

export default AddDeleteTagModal;
