import {
  DeliveryType,
  OrderData,
  OrderSource,
  OrderStatus,
  DeliveryOption,
  OrderTableData,
  RefundItem,
  PriceResponsePharmacy,
  ProductResponseItem,
  ProductResponseItemCache,
  PaymentResponseItemCache,
  OrderHistoryEvent,
} from './types';
import { formatTime, getFromLocalStorage } from '../../global/helpers';
import {
  orderDeliveryTypesStrategy,
  orderPaymentTypesStrategy,
  orderStatusesStrategy,
} from './constants';
import { PharmacyTag } from '../SourcePage/types';
import { StatusType } from '../../global/types';

const formatDeliveryOptionsSlots = (slots: DeliveryOption[]) => {
  return slots?.map((slot) => ({
    deliveryType: slot.delivery_type,
    distance: slot.distance,
    eta: slot.eta,
    price: slot.price,
    provider: slot.provider,
  }));
};

const formatDeliveryAllOptionsSlots = (slots: DeliveryOption[]) => {
  return slots?.map((slot) => ({
    delivery_type: slot.delivery_type,
    distance: slot.distance,
    eta: slot.eta,
    price: slot.price,
    provider: slot.provider,
  }));
};

const formatDeliveryOptionsData = (deliveryData: DeliveryOption) => {
  const selectedByUserDeliveryOption =
    deliveryData.slots?.find(
      (deliveryOption) =>
        deliveryOption.delivery_type === deliveryData.type &&
        deliveryOption.provider === deliveryData.provider
    ) || deliveryData;

  return {
    deliveryType: selectedByUserDeliveryOption?.delivery_type || '',
    distance: selectedByUserDeliveryOption?.distance || '',
    priceFact: deliveryData.price_fact && deliveryData.price_fact,
    priceFactCalculated:
      deliveryData.price_fact_calculated && deliveryData.price_fact_calculated,
    deliveryRefundSum: deliveryData.refund_sum ? deliveryData.refund_sum : 0,
    claimId: deliveryData.claim_id && deliveryData.claim_id,
    claimStatus: deliveryData.claim_status && deliveryData.claim_status,
    claimPrice: deliveryData.claim_price && deliveryData.claim_price,
    eta: selectedByUserDeliveryOption?.eta || '',
    price: selectedByUserDeliveryOption?.price || '',
    provider: selectedByUserDeliveryOption?.provider || 'Yandex',
    end: deliveryData.end,
    start: deliveryData.start,
    onDemand: deliveryData.on_demand,
    orderEta: deliveryData.eta && deliveryData.eta,
    type: deliveryData.type,
    slots: deliveryData.slots
      ? formatDeliveryOptionsSlots(
          deliveryData.slots.filter(
            (deliverySlot) =>
              deliverySlot.delivery_type !==
              selectedByUserDeliveryOption?.delivery_type
          )
        )
      : null,
    slotsCurrent: deliveryData.slots
      ? formatDeliveryAllOptionsSlots(
          deliveryData.slots.filter(
            (deliverySlot) =>
              deliverySlot.delivery_type ===
              selectedByUserDeliveryOption?.delivery_type
          )
        )
      : null,
    slotsAll: deliveryData.slots
      ? formatDeliveryAllOptionsSlots(deliveryData.slots)
      : null,
  };
};

export const getOrderTimer = (diffMs: number) => {
  const hours = Math.floor(diffMs / 3600000);
  const minutes = Math.floor(
    Math.round(((diffMs % 86400000) % 3600000) / 60000)
  );
  return `${hours > 9 ? hours : `0${hours}`}:${
    minutes > 9 ? minutes : `0${minutes}`
  }`;
};

export const getDay = (yesterday?: boolean) => {
  const date = yesterday
    ? new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
    : new Date();
  const weekDay = new Array(7);
  weekDay[0] = 'Воскресенье';
  weekDay[1] = 'Понедельник';
  weekDay[2] = 'Вторник';
  weekDay[3] = 'Среда';
  weekDay[4] = 'Четверг';
  weekDay[5] = 'Пятница';
  weekDay[6] = 'Суббота';
  return weekDay[date.getDay()];
};

export const mapDataForOrdersPageTable = (
  data: { order: OrderData; source: OrderSource }[],
  productsData: ProductResponseItem[] | [],
  analogsData?: ProductResponseItem[],
  statuses?: {
    code: string;
    color: string;
    name: string;
    value: number;
    available: boolean;
  }[],
  statusesPharmacy?: {
    code: string;
    color: string;
    name: string;
    value: number;
    available: boolean;
  }[],
  statusesDelivery?: {
    code: string;
    color: string;
    name: string;
    value: number;
  }[]
) => {
  return data
    ? data.map(({ order, source }) => {
        const status = statuses?.find((el) => el.code === order.status);
        const statusDelivery = statusesDelivery?.find(
          (el) => el.code === order?.claim_status
        ) || {
          code: order?.claim_status,
          name: order?.claim_status,
          color: '003E6E',
        };
        const statusPharmacy = statusesPharmacy?.find(
          (el) => el.code === order.pharmacy_status
        );
        return {
          id: order.order_number,
          reissued_from: order.reissued_from,
          reissued_to: order.reissued_to,
          orderNumber: `№${order.order_number}`,
          phone: `+${order.phone}`,
          createdAt: formatTime(order.created_at),
          wasPaidAt: order.was_paid_at && formatTime(order.was_paid_at),
          userCancelMessage: order.cancel_message,
          userSource: order.user_source,
          test: order.is_test && order.is_test,
          viewed: order.viewed,
          muted: order.muted,
          closed: order.closed,
          paid: order.paid,
          collector: order.collector,
          timer: !order.timer
            ? null
            : {
                startTimer: formatTime(order.timer.start),
                endTimer: formatTime(order.timer.end),
                commentTimer: order.timer.comment,
                statusTimer: order.timer.status,
                remainTimer: order.timer.remaining,
                userMessage: order.timer.user_message || null,
              },
          pharmacyTimer: !order.pharmacy_timer
            ? null
            : {
                startTimer: formatTime(order.pharmacy_timer.start),
                endTimer: formatTime(order.pharmacy_timer.end),
                commentTimer: order.pharmacy_timer.comment,
                statusTimer: order.pharmacy_timer.status,
                remainTimer: order.pharmacy_timer.remaining,
              },
          diffMsTimer:
            new Date().getTime() - new Date(order.created_at).getTime(),
          amount: `${order.total_price.toFixed(2)} ₸`,
          price: `${order.total_price.toFixed(2)} ₸`,
          status: status && status?.code,
          statusName: status && status?.name,
          statusColor: status && status?.color,
          statusDelivery: statusDelivery && statusDelivery?.code,
          statusNameDelivery: statusDelivery && statusDelivery?.name,
          statusColorDelivery: statusDelivery && statusDelivery?.color,
          statusPharmacy: statusPharmacy && statusPharmacy?.code,
          statusNamePharmacy: statusPharmacy && statusPharmacy?.name,
          statusColorPharmacy: statusPharmacy && statusPharmacy?.color,
          // @ts-ignore
          statusForManger: orderStatusesStrategy[status?.code],
          deliveryType: orderDeliveryTypesStrategy[order.delivery_method],
          paymentType: orderPaymentTypesStrategy[order.payment_method],
          address: order.address ? order.address : null,
          city: order.city ? order.city : '',
          comment: order.comment ? order.comment : null,
          source: source.code,
          sourceName: source.name,
          sourceLat: source.lat ? source.lat : '',
          sourceLon: source.lon ? source.lon : '',
          networkCode: source.network_code,
          sourceAddress: source.address,
          schedule: source?.opening_hours,
          amountWithDiscount:
            order.total_price_with_discount &&
            `${order.total_price_with_discount?.toFixed(2)} ₸`,

          paymentStatus: order.payment_status ? order.payment_status : '',
          deliveryPaymentStatus: order.delivery_payment_status
            ? order.delivery_payment_status
            : '',
          refundSum: order.refund_sum ? order.refund_sum : 0,
          deliveryPriceWithDiscount:
            order.delivery_price_with_discount &&
            order.delivery_price_with_discount,

          promoCode: !order.promocode
            ? null
            : {
                code: order?.promocode?.code,
                description: order?.promocode?.description,
                discount_target: order?.promocode?.discount_target,
                discount_type: order?.promocode?.discount_type,
                type: order?.promocode?.type,
                value: order?.promocode?.value,
                max_user_orders: order?.promocode?.max_user_orders,
              },
        };
      })
    : null;
};

export const mappedDataForOrdersSearch = (
  data: { order: OrderData; source: OrderSource }[],
  productsData?: ProductResponseItem[],
  statuses?: {
    code: string;
    color: string;
    name: string;
    value: number;
  }[],
  statusesPharmacy?: {
    code: string;
    color: string;
    name: string;
    value: number;
    available: boolean;
  }[]
) => {
  if (data) {
    return data.map(({ order }) => {
      // @ts-ignore
      const status = statuses?.find((el) => el.code === order.status.status);
      const statusPharmacy = statusesPharmacy?.find(
        // @ts-ignore
        (el) => el.code === order?.pharmacy_status?.status
      );
      return {
        id: order.order_number,
        orderNumber: `№${order.order_number}`,
        viewed: order.viewed,
        muted: order.muted,
        phone: `+${order.phone}`,
        createdAt: formatTime(order.created_at),
        wasPaidAt: order.was_paid_at && formatTime(order.was_paid_at),
        amount: `${order.total_price.toFixed(2)} ₸`,
        status: status && status?.code,
        statusName: status && status?.name,
        statusColor: status && status?.color,
        statusPharmacy: statusPharmacy && statusPharmacy?.code,
        statusNamePharmacy: statusPharmacy && statusPharmacy?.name,
        statusColorPharmacy: statusPharmacy && statusPharmacy?.color,
        // @ts-ignore
        statusForManger: orderStatusesStrategy[status?.code],
        deliveryType: orderDeliveryTypesStrategy[order.delivery_method],
        paymentType: orderPaymentTypesStrategy[order.payment_method],
      };
    });
  }
  return null;
};

const getPharmacyTagsForOrder = (
  pharmacyTags: PharmacyTag[],
  sourceTags: { tag_id: number; meta?: string }[]
) => {
  return (
    pharmacyTags
      ?.filter((tag) => sourceTags?.map((tag) => tag.tag_id).includes(tag.id))
      .map((item) => ({
        ...item,
        meta: sourceTags?.find((tag) => tag.tag_id === item.id)?.meta || null,
      })) || []
  );
};

export const mapDataForOrderPage = (
  data: { order: OrderData; source: OrderSource }[],
  productsData: ProductResponseItem[],
  analogsData: ProductResponseItem[],
  priceQuantity: PriceResponsePharmacy[],
  statuses?: {
    code: string;
    color: string;
    name: string;
    value: number;
  }[],
  statusesPharmacy?: {
    code: string;
    color: string;
    name: string;
    value: number;
    available: boolean;
  }[],
  pharmacyTags: PharmacyTag[] | [] = [],
  networkTagsResponse: PharmacyTag[] | [] = []
) => {
  return data.map(({ order, source }) => {
    // @ts-ignore
    const status = statuses?.find((el) => el.code === order.status.status);
    const statusPharmacy = statusesPharmacy?.find(
      // @ts-ignore
      (el) => el.code === order?.pharmacy_status?.status
    );

    return {
      id: order.order_number,
      reissued_from: order.reissued_from,
      reissued_to: order.reissued_to,
      orderNumber: `№${order.order_number}`,
      phone: `+${order.phone}`,
      createdAt: formatTime(order.created_at),
      userSource: order.user_source,
      originalCreatedAt: order.created_at,
      wasPaidAt: order.was_paid_at && formatTime(order.was_paid_at),
      whatsappNumber: order.whatsapp_number,
      payer: order.payer,
      paymentOnSite: source.payment_on_site,
      viewed: order.viewed,
      muted: order.muted,
      paid: order.paid,
      soft: source.soft,
      test: order.is_test,
      receivedInSoft: order.received_in_soft,
      paidDelivery: order.paid_delivery,
      city: order.city ? order.city : '',
      promoCode: !order.promocode
        ? null
        : {
            code: order?.promocode?.code,
            description: order?.promocode?.description,
            discount_target: order?.promocode?.discount_target,
            discount_type: order?.promocode?.discount_type,
            type: order?.promocode?.type,
            value: order?.promocode?.value,
            max_user_orders: order?.promocode?.max_user_orders,
          },

      collector: order.collector,
      timer: !order.timer
        ? null
        : {
            startTimer: formatTime(order.timer.start),
            endTimer: formatTime(order.timer.end),
            commentTimer: order.timer.comment,
            statusTimer: order.timer.status,
            remainTimer: order.timer.remaining,
            localTime: order.timer.local_time,
            userMessage: order.timer.user_message || null,
          },
      pharmacyTimer: !order.pharmacy_timer
        ? null
        : {
            startTimer: formatTime(order.pharmacy_timer.start),
            endTimer: formatTime(order.pharmacy_timer.end),
            commentTimer: order.pharmacy_timer.comment,
            statusTimer: order.pharmacy_timer.status,
            remainTimer: order.pharmacy_timer.remaining,
          },
      delivery: !order.delivery
        ? null
        : formatDeliveryOptionsData(order.delivery as DeliveryOption),

      deliveryMethod: order.delivery_method,
      diffMsTimer: new Date().getTime() - new Date(order.created_at).getTime(),
      amount: `${order.total_price.toFixed(2)} ₸`,
      oldPrice: `${order.total_price.toFixed(2)} ₸`,
      deliveryPriceWithDiscount:
        order.delivery_price_with_discount &&
        order.delivery_price_with_discount,
      paymentStatus: order.payment_status ? order.payment_status : '',
      paymentStatusDelivery: order?.delivery?.payment_status
        ? order.delivery.payment_status
        : '',
      amountWithDiscount:
        order.total_sum_with_discount &&
        `${order.total_sum_with_discount?.toFixed(2)} ₸`,
      refundSum: order.refund_sum ? order.refund_sum : 0,
      price: `${order.items
        .reduce((accum, product) => {
          return (
            accum +
            product.price_with_warehouse_discount * product.pharmacy_count
          );
        }, 0)
        .toFixed(2)} ₸`,
      status: status && status?.code,
      statusName: status && status?.name,
      statusColor: status && status?.color,
      // @ts-ignore
      statusNext: order.status.next,
      statusPharmacy: statusPharmacy && statusPharmacy?.code,
      statusNamePharmacy: statusPharmacy && statusPharmacy?.name,
      statusColorPharmacy: statusPharmacy && statusPharmacy?.color,
      // @ts-ignore
      statusForManger: orderStatusesStrategy[status?.code],
      deliveryType: orderDeliveryTypesStrategy[order.delivery_method],
      paymentType: orderPaymentTypesStrategy[order.payment_method],
      recipe: order.receipts ? order.receipts[0] : null,
      address: order.address ? order.address : null,
      comment: order.comment ? order.comment : null,
      source: source.code,
      sourceTags: getPharmacyTagsForOrder(pharmacyTags, source.source_tags),
      networkTags: getPharmacyTagsForOrder(
        networkTagsResponse,
        source.network_tags
      ),
      sourceName: source.name,
      sourceLat: source.lat ? source.lat : '',
      sourceLon: source.lon ? source.lon : '',
      sourceAddress: source.address,
      schedule: source?.opening_hours,
      networkCode: source.network_code,
      products: order.items
        .map((product) => {
          const productData = productsData?.find(
            (productItem) => productItem.sku === product.sku
          );
          const analogData = analogsData?.find(
            (analogItem) => analogItem.sku === product.proposal?.sku
          );
          const priceAndQuantity = priceQuantity?.find(
            (item) => item.ware_id === product.ware_id
          );
          const priceAndQuantityAnalog = priceQuantity?.find(
            (item) => item.ware_id === product.proposal?.ware_id
          );
          return {
            deliveryPriceWithDiscount:
              order.delivery_price_with_discount &&
              order.delivery_price_with_discount,
            paymentStatus: order.payment_status ? order.payment_status : '',
            paymentStatusDelivery: order?.delivery?.payment_status
              ? order.delivery.payment_status
              : '',
            amountWithDiscount:
              order.total_sum_with_discount &&
              `${order.total_sum_with_discount?.toFixed(2)} ₸`,
            refundSum: order.refund_sum ? order.refund_sum : 0,
            promoCode: !order.promocode
              ? null
              : {
                  code: order?.promocode?.code,
                  description: order?.promocode?.description,
                  discount_target: order?.promocode?.discount_target,
                  discount_type: order?.promocode?.discount_type,
                  type: order?.promocode?.type,
                  value: order?.promocode?.value,
                  max_user_orders: order?.promocode?.max_user_orders,
                },
            name: productData ? productData.name : '',
            productPharmacyName: product.name,
            pharmacyName: priceAndQuantity
              ? priceAndQuantity.pharmacy_name
              : '',
            sku: product.sku,
            status: status && status?.code,
            amount: `${product.total_price.toFixed(2)} ₸`,
            quantity: product.quantity,
            allQuantity: priceAndQuantity ? priceAndQuantity.quantity : null,
            price: product.price_with_warehouse_discount,
            minPrice: priceAndQuantity?.min_customer_price || 0,
            avgPrice: priceAndQuantity?.avg_customer_price || 0,
            maxPrice: priceAndQuantity?.max_customer_price || 0,
            // @ts-ignore
            pharmacyCount: product.pharmacy_count,
            vendorCode: productData
              ? productData.custom_attributes.find(
                  (attribute) => attribute.attribute_code === 'ndda_num'
                )?.value || ''
              : '',
            packing: productData
              ? productData.custom_attributes.find(
                  (attribute) => attribute.attribute_code === 'pp_packing'
                )?.value || ''
              : '',
            manufacturer: productData
              ? productData.custom_attributes.find(
                  (attribute) => attribute.attribute_code === 'manufacturer_id'
                )?.value || ''
              : '',
            needsRecipe: productData
              ? Boolean(
                  Number(
                    productData.custom_attributes.find(
                      (attribute) =>
                        attribute.attribute_code === 'recipe_needed'
                    )?.value
                  )
                ) || false
              : false,
            needsStrongRecipe: productData
              ? Boolean(
                  Number(
                    productData.custom_attributes.find(
                      (attribute) =>
                        attribute.attribute_code === 'strong_recipe'
                    )?.value
                  )
                ) || false
              : false,
            proposal: !product.proposal
              ? null
              : {
                  name: product.proposal.name,
                  sku: product.proposal.sku,
                  skuName: priceAndQuantityAnalog?.pharmacy_name || '',
                  amount: `${(
                    (product.proposal.count || 0) *
                    product.proposal.price_with_warehouse_discount
                  ).toFixed(2)} ₸`,
                  price: product.proposal.price_with_warehouse_discount,
                  minPrice: priceAndQuantityAnalog?.min_customer_price || 0,
                  avgPrice: priceAndQuantityAnalog?.avg_customer_price || 0,
                  maxPrice: priceAndQuantityAnalog?.max_customer_price || 0,
                  count: product.proposal.count || 0,
                  allQuantity: product.proposal.quantity,
                  currentPrice:
                    product.proposal.price_with_warehouse_discount *
                    (product.proposal.count || 0),
                  pharmacyCount: product.proposal.pharmacy_count || 0,
                  packing: analogData
                    ? analogData.custom_attributes.find(
                        (attribute) => attribute.attribute_code === 'pp_packing'
                      )?.value || ''
                    : '',
                  manufacturer: analogData
                    ? analogData.custom_attributes.find(
                        (attribute) =>
                          attribute.attribute_code === 'manufacturer_id'
                      )?.value || ''
                    : '',
                  needsRecipe: analogData
                    ? Boolean(
                        Number(
                          analogData.custom_attributes.find(
                            (attribute) =>
                              attribute.attribute_code === 'recipe_needed'
                          )?.value
                        )
                      ) || false
                    : false,
                },
          };
        })
        .sort((a, b) => {
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        }),
    };
  });
};

const getDataByTypeAndRefund = (
  data: PaymentResponseItemCache[],
  type: string
): any[] => {
  const formatted = data.map((f) => ({
    ...f,
    payment_type:
      f.payment_type === 'order' &&
      ['kaspi', 'in_place'].includes(f.payment_method)
        ? 'items'
        : f.payment_type,
  }));
  let paymentIndex = 1;
  let refundIndex = 1;

  return formatted.flatMap((result) => {
    if (result.payment_type !== type) return [];

    const payment: any = {
      type: 'payment',
      ...result,
      /* eslint-disable no-plusplus */
      label: `Платеж-${paymentIndex++}`,
    };

    const refunds: RefundItem[] =
      result.refunds?.map((refund) => ({
        type: 'refund',
        ...refund,
        /* eslint-disable no-plusplus */
        label: `Возврат-${refundIndex++}`,
      })) || [];

    return [payment, ...refunds]; // Возвращаем и payment, и refunds
  });
};

const sortByDate = (array: any[]) => {
  return array.sort(
    (a, b) =>
      new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
  );
};

export const mapDataForOrderPageCache = (
  data: { order: OrderData; source: OrderSource }[],
  productsData: ProductResponseItemCache[],
  wareIds: string[],
  analogsData: ProductResponseItemCache[],
  priceQuantity: PriceResponsePharmacy[],
  statuses?: {
    code: string;
    color: string;
    name: string;
    value: number;
  }[],
  statusesPharmacy?: {
    code: string;
    color: string;
    name: string;
    value: number;
    available: boolean;
  }[],
  pharmacyTags: PharmacyTag[] | [] = [],
  networkTagsResponse: PharmacyTag[] | [] = [],
  payments: PaymentResponseItemCache[] | [] = []
) => {
  return data.map(({ order, source }) => {
    // @ts-ignore
    const status = statuses?.find((el) => el.code === order.status.status);
    const statusPharmacy = statusesPharmacy?.find(
      // @ts-ignore
      (el) => el.code === order?.pharmacy_status?.status
    );

    return {
      id: order.order_number,
      orderNumber: `№${order.order_number}`,
      phone: `+${order.phone}`,
      createdAt: formatTime(order.created_at),
      userSource: order.user_source,
      originalCreatedAt: order.created_at,
      wasPaidAt: order.was_paid_at && formatTime(order.was_paid_at),
      whatsappNumber: order.whatsapp_number,
      payer: order.payer,
      paymentOnSite: source.payment_on_site,
      viewed: order.viewed,
      muted: order.muted,
      paid: order.paid,
      soft: source.soft,
      reissued_from: order.reissued_from,
      reissued_to: order.reissued_to,
      test: order.is_test,
      receivedInSoft: order.received_in_soft,
      paidDelivery: order.paid_delivery,
      city: order.city ? order.city : '',
      bonusUsed: order.bonus_used,
      promoCode: !order.promocode
        ? null
        : {
            code: order?.promocode?.code,
            description: order?.promocode?.description,
            discount_target: order?.promocode?.discount_target,
            discount_type: order?.promocode?.discount_type,
            type: order?.promocode?.type,
            value: order?.promocode?.value,
            max_user_orders: order?.promocode?.max_user_orders,
          },

      collector: order.collector,
      timer: !order.timer
        ? null
        : {
            startTimer: formatTime(order.timer.start),
            endTimer: formatTime(order.timer.end),
            commentTimer: order.timer.comment,
            statusTimer: order.timer.status,
            remainTimer: order.timer.remaining,
            localTime: order.timer.local_time,
            userMessage: order.timer.user_message || null,
          },
      pharmacyTimer: !order.pharmacy_timer
        ? null
        : {
            startTimer: formatTime(order.pharmacy_timer.start),
            endTimer: formatTime(order.pharmacy_timer.end),
            commentTimer: order.pharmacy_timer.comment,
            statusTimer: order.pharmacy_timer.status,
            remainTimer: order.pharmacy_timer.remaining,
          },
      delivery: !order.delivery
        ? null
        : formatDeliveryOptionsData(order.delivery as DeliveryOption),

      deliveryMethod: order.delivery_method,
      diffMsTimer: new Date().getTime() - new Date(order.created_at).getTime(),
      amount: `${order.total_price.toFixed(2)} ₸`,
      oldPrice: `${order.total_price.toFixed(2)} ₸`,
      paymentStatus: order.payment_status ? order.payment_status : '',
      paymentStatusDelivery: order?.delivery?.payment_status
        ? order.delivery.payment_status
        : '',
      deliveryPriceWithDiscount:
        order.delivery_price_with_discount &&
        order.delivery_price_with_discount,
      refundSum: order.refund_sum ? order.refund_sum : 0,
      amountWithDiscount:
        order.total_sum_with_discount &&
        `${order.total_sum_with_discount?.toFixed(2)} ₸`,
      price: `${order.items
        .reduce((accum, product) => {
          return (
            accum +
            product.price_with_warehouse_discount * product.pharmacy_count
          );
        }, 0)
        .toFixed(2)} ₸`,
      status: status && status?.code,
      statusName: status && status?.name,
      statusColor: status && status?.color,
      // @ts-ignore
      statusNext: order.status.next,
      statusPharmacy: statusPharmacy && statusPharmacy?.code,
      statusNamePharmacy: statusPharmacy && statusPharmacy?.name,
      statusColorPharmacy: statusPharmacy && statusPharmacy?.color,
      // @ts-ignore
      statusForManger: orderStatusesStrategy[status?.code],
      deliveryType: orderDeliveryTypesStrategy[order.delivery_method],
      paymentType: orderPaymentTypesStrategy[order.payment_method],
      recipe: order.receipts ? order.receipts[0] : null,
      address: order.address ? order.address : null,
      comment: order.comment ? order.comment : null,
      source: source.code,
      sourceTags: getPharmacyTagsForOrder(pharmacyTags, source.source_tags),
      networkTags: getPharmacyTagsForOrder(
        networkTagsResponse,
        source.network_tags
      ),
      sourceName: source.name,
      sourceLat: source.lat ? source.lat : '',
      sourceLon: source.lon ? source.lon : '',
      sourceAddress: source.address,
      schedule: source?.opening_hours,
      networkCode: source.network_code,
      payments:
        /* eslint-disable no-nested-ternary */
        payments?.length > 0
          ? {
              order: getDataByTypeAndRefund(sortByDate(payments), 'order'),
              items: getDataByTypeAndRefund(sortByDate(payments), 'items'),
              delivery: getDataByTypeAndRefund(
                sortByDate(payments),
                'delivery'
              ),
            }
          : {
              order: [],
              items: [],
              delivery: [],
            },
      products: order.items
        .map((product, ind) => {
          const productData = productsData?.find(
            (productItem) => productItem.sku === product.sku
          );
          const analogData = analogsData?.find(
            (analogItem) => analogItem.sku === product.proposal?.sku
          );
          const priceAndQuantity = priceQuantity?.find(
            (item) => item.ware_id === product.ware_id
          );
          const priceAndQuantityAnalog = priceQuantity?.find(
            (item) => item.ware_id === product.proposal?.ware_id
          );
          const productWereId = wareIds[ind];
          return {
            deliveryPriceWithDiscount:
              order.delivery_price_with_discount &&
              order.delivery_price_with_discount,
            refundSum: order.refund_sum ? order.refund_sum : 0,
            amountWithDiscount:
              order.total_sum_with_discount &&
              `${order.total_sum_with_discount?.toFixed(2)} ₸`,
            promoCode: !order.promocode
              ? null
              : {
                  code: order?.promocode?.code,
                  description: order?.promocode?.description,
                  discount_target: order?.promocode?.discount_target,
                  discount_type: order?.promocode?.discount_type,
                  type: order?.promocode?.type,
                  value: order?.promocode?.value,
                  max_user_orders: order?.promocode?.max_user_orders,
                },
            name: productData ? productData.name : '',
            were_id: productWereId || '',
            productPharmacyName: product.name,
            pharmacyName: priceAndQuantity
              ? priceAndQuantity.pharmacy_name
              : '',
            sku: product.sku,
            status: status && status?.code,
            amount: `${product.total_price.toFixed(2)} ₸`,
            quantity: product.quantity,
            allQuantity: priceAndQuantity ? priceAndQuantity.quantity : null,
            price: product.price_with_warehouse_discount,
            minPrice: priceAndQuantity?.min_customer_price || 0,
            avgPrice: priceAndQuantity?.avg_customer_price || 0,
            maxPrice: priceAndQuantity?.max_customer_price || 0,
            // @ts-ignore
            pharmacyCount: product.pharmacy_count,
            vendorCode: productData
              ? productData.attributes.find(
                  (attribute) => attribute.code === 'ndda_num'
                )?.values?.[0] || ''
              : '',
            packing: productData
              ? productData.attributes.find(
                  (attribute) => attribute.code === 'pp_packing'
                )?.values?.[0] || ''
              : '',
            manufacturer: productData
              ? productData.attributes.find(
                  (attribute) => attribute.code === 'manufacturer_id'
                )?.values?.[0] || ''
              : '',
            needsRecipe: productData
              ? Boolean(
                  Number(
                    productData.attributes.find(
                      (attribute) => attribute.code === 'recipe_needed'
                    )?.values?.[0]
                  )
                ) || false
              : false,
            needsStrongRecipe: productData
              ? Boolean(
                  Number(
                    productData.attributes.find(
                      (attribute) => attribute.code === 'strong_recipe'
                    )?.values?.[0]
                  )
                ) || false
              : false,
            proposal: !product.proposal
              ? null
              : {
                  name: product.proposal.name,
                  sku: product.proposal.sku,
                  skuName: priceAndQuantityAnalog?.pharmacy_name || '',
                  amount: `${(
                    (product.proposal.count || 0) *
                    product.proposal.price_with_warehouse_discount
                  ).toFixed(2)} ₸`,
                  price: product.proposal.price_with_warehouse_discount,
                  minPrice: priceAndQuantityAnalog?.min_customer_price || 0,
                  avgPrice: priceAndQuantityAnalog?.avg_customer_price || 0,
                  maxPrice: priceAndQuantityAnalog?.max_customer_price || 0,
                  count: product.proposal.count || 0,
                  allQuantity: product.proposal.quantity,
                  currentPrice:
                    product.proposal.price_with_warehouse_discount *
                    (product.proposal.count || 0),
                  pharmacyCount: product.proposal.pharmacy_count || 0,
                  packing: analogData
                    ? analogData.attributes.find(
                        (attribute) => attribute.code === 'pp_packing'
                      )?.values?.[0] || ''
                    : '',
                  manufacturer: analogData
                    ? analogData.attributes.find(
                        (attribute) => attribute.code === 'manufacturer_id'
                      )?.values?.[0] || ''
                    : '',
                  needsRecipe: analogData
                    ? Boolean(
                        Number(
                          analogData.attributes.find(
                            (attribute) => attribute.code === 'recipe_needed'
                          )?.values?.[0]
                        )
                      ) || false
                    : false,
                },
          };
        })
        .sort((a, b) => {
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        }),
    };
  });
};

export const newPriceProduct = (data: any, notLocal?: boolean) => {
  if (!notLocal) {
    return data?.reduce((accum: any, product: any) => {
      const priceArr = [];
      let x = 0;
      if (product.visiblePrice) {
        priceArr.push(accum + product.currentPrice);
      } else if (product.currentPrice === 0) {
        priceArr.push(accum + product.currentPrice);
      } else priceArr.push(accum);

      if (product.proposal && product.proposal.visiblePrice) {
        priceArr.push(product.proposal.currentPrice);
      }

      // eslint-disable-next-line
      return priceArr.map((i) => (x += i), x).reverse()[0];
    }, 0);
  }
  return data?.reduce((accum: any, product: any) => {
    const priceArr = [];
    let x = 0;
    if (product.proposal) {
      priceArr.push(accum + product.price);
      priceArr.push(accum + product.proposal.price);
    } else priceArr.push(accum + product.price);
    // eslint-disable-next-line
    return priceArr.map((i) => (x += i), x).reverse()[0];
  }, 0);
};

export const availableStatuses = (statuses: any) => {
  const role = getFromLocalStorage('role');
  if (role === 'pharmacist') {
    const pharmacyNew = statuses.find(
      (el: any) => el.code === 'in_pharmacy_placed'
    );
    const pharmacyNewIndex = statuses.indexOf(pharmacyNew);
    if (pharmacyNewIndex !== -1) {
      statuses.splice(pharmacyNewIndex, 1);
    }
    return statuses;
  }
  return statuses;
};

export const sortedDataForTable = (data: OrderTableData[] | null) => {
  const sortedData = data
    ?.filter((el) => el.timer)
    .concat(
      data
        ?.filter((el) => !el.timer)
        .sort((a, b) => a.diffMsTimer - b.diffMsTimer)
    );
  return !sortedData ? null : sortedData;
};

export const amountOfOrders = (orders: any, refund?: boolean) => {
  return {
    closedAmountOfOrdersInPlace: `${orders?.in_place?.total || 0} ₸`,
    closedAmountOfOrdersOnline: `${
      refund ? orders?.online?.refund_total || 0 : orders?.online?.total || 0
    } ₸`,
    closedAmountOfOrdersInterpay: `${
      refund
        ? orders?.interpay?.refund_total || 0
        : orders?.interpay?.total || 0
    } ₸`,

    closedOrdersInPlace: orders?.in_place?.sources || [],
    closedOrdersOnline: refund
      ? orders?.online?.sources.filter((el: any) => el.refund_total) || []
      : orders?.online?.sources || [],
    closedOrdersInterpay: refund
      ? orders?.interpay?.sources.filter((el: any) => el.refund_total) || []
      : orders?.interpay?.sources || [],

    webMobile: orders?.web_mobile?.sources || [],
    webDesktop: orders?.web_desktop?.sources || [],
    appAndroid: orders?.app_android?.sources || [],
    appIOS: orders?.app_ios?.sources || [],
    operator: orders?.operator?.sources || [],
  };
};

export const getOverdueOrders = (data: OrderTableData[] | null) => {
  return (
    data?.filter((order: OrderTableData) =>
      order.timer
        ? order.timer?.remainTimer < 0 &&
          order.status !== OrderStatus.CANCELED &&
          order.status !== OrderStatus.COMPLETED
        : false
    ) || null
  );
};

export const getDeliveryOrders = (data: OrderTableData[] | null) => {
  return (
    data?.filter(
      (order: OrderTableData) =>
        order.deliveryType === DeliveryType.DELIVERY &&
        order.status !== OrderStatus.CANCELED &&
        order.status !== OrderStatus.COMPLETED
    ) || null
  );
};

export const getFoodOrders = (data: OrderTableData[] | null) => {
  return (
    data?.filter(
      (order: OrderTableData) =>
        order.source.includes('food') &&
        order.status !== 'canceled' &&
        order.status !== 'completed'
    ) || null
  );
};

export const getSelfOrders = (data: OrderTableData[] | null) => {
  return (
    data?.filter(
      (order: OrderTableData) =>
        order.deliveryType === DeliveryType.SELF &&
        order.status !== OrderStatus.CANCELED &&
        order.status !== OrderStatus.COMPLETED
    ) || null
  );
};

export const getNetworkOrders = (
  data: OrderTableData[] | null,
  dataNetwork: {
    [key: string]: {
      automation_state: string;
    };
  } | null
) => {
  if (!data || !dataNetwork) return null;

  const networkKeys = Object.keys(dataNetwork);
  const filteredData = data?.filter(
    (order) =>
      !networkKeys.includes(order.networkCode) &&
      order.status !== OrderStatus.CANCELED &&
      order.status !== OrderStatus.COMPLETED
  );

  return filteredData;
};

export const getNewOrders = (data: OrderTableData[] | null) => {
  return (
    data?.filter(
      (order: OrderTableData) =>
        order.statusPharmacy === StatusType.NEW ||
        order.status === StatusType.NEW
    ) || null
  );
};

export const getYellowOrders = (data: OrderTableData[] | null) => {
  return (
    data?.filter(
      (item) =>
        item.timer?.remainTimer &&
        item.timer?.remainTimer <= 240 &&
        item.timer?.remainTimer >= 0 &&
        !item.closed
    ) || null
  );
};

export const sortOrdersByNewest = (orders: OrderTableData[]) => {
  return orders
    .map((order) => order)
    .sort((currentOrder, previousOrder) => {
      if (
        currentOrder.statusName === 'Новый заказ' &&
        previousOrder.statusName !== 'Новый заказ'
      ) {
        return -1;
      }
      if (
        currentOrder.statusName !== 'Новый заказ' &&
        previousOrder.statusName === 'Новый заказ'
      ) {
        return 1;
      }
      return 0;
    });
};

export const mappedHistoryEvents = (events: OrderHistoryEvent[]) =>
  events.filter((event) => event.event === 'changed_total_sum');
