import React from 'react';
import ReactPlayer from 'react-player';

interface VideoProps {
  url: string;
}

const VideoTestPage = ({ url }: VideoProps) => {
  return (
    <div
      style={{ position: 'relative', paddingTop: '56.25%', margin: '20px 0' }}
    >
      <ReactPlayer
        url={url}
        controls
        width="100%"
        height="100%"
        style={{ position: 'absolute', top: 0, left: 0 }}
      />
    </div>
  );
};

export default VideoTestPage;
