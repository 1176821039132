import React, { useEffect, useRef } from 'react';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import iconA from '../../global/media/mapA.png';
import iconB from '../../global/media/mapB.png';

interface Props {
  sourceLat: string;
  sourceLon: string;
  setLatB: React.Dispatch<React.SetStateAction<number>>;
  setLonB: React.Dispatch<React.SetStateAction<number>>;
  latB: number;
  lonB: number;
  setSearchCoordinates: React.Dispatch<React.SetStateAction<number[]>>;
  setSearchAddress: React.Dispatch<React.SetStateAction<string>>;
}

const MapYandex = ({
  sourceLat,
  sourceLon,
  setLatB,
  setLonB,
  lonB,
  latB,
  setSearchCoordinates,
  setSearchAddress,
}: Props) => {
  const mapRef = useRef<any>(null);

  useEffect(() => {
    if (!mapRef.current || latB === 0 || lonB === 0) {
      return;
    }

    mapRef.current.geoObjects.each((geoObject: any) => {
      if (geoObject.properties.get('myCustomId') === 'markerB') {
        geoObject.geometry.setCoordinates([latB, lonB]);
      }
    });
  }, [latB, lonB]);
  const kazakhstanBounds = [
    [40.9057, 46.5053644],
    [55.4414799, 87.3363696],
  ];

  const handleMapLoad = (ymaps: any) => {
    if (
      mapRef.current.controls &&
      mapRef.current.controls.get('searchControl')
    ) {
      mapRef.current.controls.remove(
        mapRef.current.controls.get('searchControl')
      );
    }

    const searchControl = new ymaps.control.SearchControl({
      options: {
        boundedBy: kazakhstanBounds,
        results: 5,
      },
    });

    mapRef.current.controls.add(searchControl);

    searchControl.events.add('resultselect', (e: any) => {
      const search = e.get('target');
      const resultIndex = e.get('index');
      const selected = search.getResultsArray()[resultIndex];

      if (!selected || !selected.geometry) {
        return;
      }

      const coords = selected.geometry.getCoordinates();
      setSearchCoordinates(coords);

      const text = selected.properties.get('text');
      const correctedText = text?.substring(text.indexOf(',') + 1).trim();
      setSearchAddress(correctedText);
    });
  };

  return (
    <YMaps
      query={{
        apikey: '3b99fdf5-1a7c-444b-aa89-c9c6a07eb85a',
        ns: 'use-load-option',
        load: 'package.full',
        lang: 'ru_RU',
        mode: 'kazakhstan',
        loadByRequire: 1,
        loadByRequireInterval: 30000,
      }}
    >
      <div style={{ height: '850px', width: '600px' }}>
        <Map
          defaultState={{ center: [sourceLat, sourceLon], zoom: 13 }}
          width="100%"
          height="100%"
          // eslint-disable-next-line no-return-assign
          instanceRef={(ref: any) => (mapRef.current = ref)}
          onLoad={handleMapLoad}
        >
          <Placemark
            geometry={[sourceLat, sourceLon]}
            options={{
              iconLayout: 'default#image',
              iconImageHref: iconA,
              iconImageSize: [32, 32],
            }}
          />
          <Placemark
            geometry={[latB, lonB]}
            options={{
              iconLayout: 'default#image',
              iconImageHref: iconB,
              iconImageSize: [32, 32],
              draggable: true,
            }}
            properties={{ myCustomId: 'markerB' }}
            onDrag={(e: any) => {
              const coords = e.get('target').geometry.getCoordinates();
              setLatB(coords[0]);
              setLonB(coords[1]);
            }}
          />
        </Map>
      </div>
    </YMaps>
  );
};

export default MapYandex;
