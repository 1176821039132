import styled from 'styled-components';
import { baseTheme } from '../../../../global/styles/theme';

export const UserRow = styled('tr')`
  .vertical-align-top {
    vertical-align: top;
  }
  .table-cell {
    padding: 24px 10px 16px 0;
    font-size: 16px;
    font-weight: 400;
    color: ${baseTheme.colors.primary};
    cursor: pointer;
    border-top: 1px solid ${baseTheme.colors.stroking};
    vertical-align: top;

    div {
      width: 80%;
    }

    .textarea {
      min-height: 40px !important;
      height: 40px;
    }
  }

  .save-btn {
    margin-top: 20px;
    font-weight: 500;
    font-size: 18px;
    color: ${baseTheme.colors.white};
    padding: 10px 24px;
    background-color: ${baseTheme.colors.mainLightBlue};
    border-radius: 8px;
    width: 143px;
  }

  .save-edit-btn {
    margin-top: 16px;
    margin-left: -45%;
    font-weight: 500;
    font-size: 18px;
    color: ${baseTheme.colors.white};
    padding: 10px 18px;
    text-align: center;
    background-color: ${baseTheme.colors.mainLightBlue};
    border-radius: 8px;
    width: 100% !important;
  }

  .error-message-label {
    margin: 8px 0 0;
    color: ${baseTheme.colors.error};
    font-size: 14px;
    width: 80%;
  }

  .disable {
    background-color: ${baseTheme.colors.disabled} !important;
    pointer-events: none;
  }
  .icons-block {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    div {
      cursor: pointer;
      margin-right: -45px;
    }

    .delete-icon {
      margin-left: 22px;
    }
  }
`;
