import styled from 'styled-components';
import { baseTheme } from '../../global/styles/theme';

export const SettingsPageContainer = styled('div')`
  width: 700px;
  margin: 0 auto;
  margin-top: 150px;

  .container {
    height: 50px;
    display: flex;
    gap: 50px;
    align-items: center;
  }

  .title {
    font-size: 24px;
    font-weight: 700;
  }

  .confirm-btn {
    cursor: pointer;
    background-color: ${baseTheme.colors.mainLightBlue};
    color: ${baseTheme.colors.white};
    font-weight: 500;
    font-size: 14px;
    width: 110px;
    padding: 6px 19px;
    border-radius: 8px;
  }
  .disabled {
    background-color: #b8c0cc;
    &:hover {
      background-color: #b8c0cc;
      cursor: default;
    }
  }
`;
