import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './BlockedProductModal.styles';
import ModalWindow from '../ModalWindow';
import Button from '../Button';

interface BlockedProductModalProps {
  onClose: () => void;
  onBlock: () => void;
}

const BlockedProductModal = ({
  onClose,
  onBlock,
}: BlockedProductModalProps) => {
  const { t } = useTranslation();

  const handleCancelClick = () => {
    onClose();
  };

  const handleBlockClick = () => {
    onBlock();
    onClose();
  };

  return (
    <ModalWindow onClose={onClose}>
      <Styled.BlockedProductModalContainer>
        <div className="products-title">
          {t('EditPage.BLOCKED_MODAL.TITLE')}
        </div>
        <div className="products-sub-title">
          {t('EditPage.BLOCKED_MODAL.SUB_TITLE')}
        </div>
        <Styled.ModalButtonsContainer>
          <div className="save-btn">
            <Button
              onClick={handleBlockClick}
              text={t('EditPage.BLOCKED_MODAL.BLOCKED_BTN')}
              variant="contained"
            />
          </div>
          <div className="cancel-btn">
            <Button
              onClick={handleCancelClick}
              text={t('EditPage.BLOCKED_MODAL.CANCEL_BTN')}
              variant="outlined"
            />
          </div>
        </Styled.ModalButtonsContainer>
      </Styled.BlockedProductModalContainer>
    </ModalWindow>
  );
};

export default BlockedProductModal;
