import styled from 'styled-components';
import { baseTheme } from '../../../../global/styles/theme';

export const StatusContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 825px;
  position: relative;
  border: 1px solid ${baseTheme.colors.stroking};
  border-radius: 8px;
  padding: 32px 25px;
  @media only screen and (max-width: 550px) {
    width: 100%;
  }
  .title {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: ${baseTheme.colors.secondary};
    padding-bottom: 16px;
  }

  .cancel-and-history-container {
    display: flex;
    padding-bottom: 32px;

    .status-history {
      cursor: pointer;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      padding-top: 11px;
      color: ${baseTheme.colors.mainLightBlue};
    }
  }
`;

export const OperatorContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding-top: 32px;

  .item {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: solid 1px #e0e5eb;
    align-items: center;
    &:last-of-type {
      border: none;
    }
  }

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .text-contacts {
    display: flex;
    justify-content: center;
    width: 23%;
    font-size: 14px;
  }

  .schedule {
    margin-top: 20px;
    margin-left: 125px;
  }

  .textAccent {
    width: 31%;
    font-size: 14px;
  }

  .online {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: green;
  }
  .offline {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: red;
  }

  .flex-container {
    display: flex;
    align-items: center;
    gap: 50px;
  }

  .button-contacts {
    margin-top: 20px;
    height: 18px;
    cursor: pointer;
    border: none;
    text-align: start;
    background: transparent;
    text-decoration: underline;
    color: ${baseTheme.colors.mainLightBlue};
  }

  .active-set-status-block {
    display: flex;
    justify-content: space-between;
  }

  .status-timer-block {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 72px;
  }
  .flex-container-paid {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .status-pharmacy-block {
    padding-top: 20px;

    .status-pharmacy {
      font-weight: 700;
      font-size: 18px;
    }

    .pharmacy-desc {
      font-weight: 500;
      font-size: 18px;
      color: ${baseTheme.colors.secondary};
      padding-right: 8px;
    }
  }

  .status-paid-container {
    margin-left: 30px !important;
  }
  .input-price {
    width: 200px;
  }

  .input-price-container {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-bottom: 20px;
    margin-left: auto !important;
  }

  .network-button-enabled {
    background-color: rgb(31, 160, 105);
    width: max-content;
    border-radius: 4px;
    border: solid 1px #000;
    padding: 5px 10px;
  }
  .network-button-disabled {
    background-color: rgb(31, 160, 105);
    width: max-content;
    border-radius: 4px;
    border: solid 1px #000;
    padding: 5px 10px;
  }
  .network-button-manual_mode {
    background-color: rgb(31, 160, 105);
    width: max-content;
    border-radius: 4px;
    border: solid 1px #000;
    padding: 5px 10px;
  }

  .network-button-automator_is_disabled {
    width: max-content;
    border-radius: 4px;
    padding: 5px 10px;
    color: red;
    border: 1px solid red;
    background-color: #fff;
  }

  .text-accent-manual_mode {
    color: red;
  }
  .text-accent-disabled {
    color: red;
  }

  .bottom-block {
    display: flex;
    justify-content: space-between;

    .line {
      border-left: 1px solid ${baseTheme.colors.stroking};

      .toggle-payment,
      .confirm-btn {
        margin-left: 50px;
      }
    }

    .status-wrapper {
      display: flex;
      align-items: center;
      gap: 5px;
      margin: 10px 0px 10px 30px;
    }
    .status-combined {
      position: relative;

      input {
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 16px);
      }
    }
    .status-title {
      max-width: 100px;
      font-size: 14px;
      color: #535d6a;
    }
    .field-status {
      color: #000;
      border-radius: 5px;
      padding: 5px;
      border: solid 1px ${baseTheme.colors.mainLightBlue};
    }

    .toggle-block {
      margin: 32px 20px 0 0;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      div {
        margin: 2px 0;
      }
    }
    .btn {
      margin-top: 10px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-color: ${baseTheme.colors.mainLightBlue};
      color: ${baseTheme.colors.white};
      font-weight: 500;
      font-size: 14px;
      padding: 6px 19px;
      border-radius: 8px;

      &:hover {
        background-color: ${baseTheme.colors.mainBlue};
      }
    }
    .confirm-btn {
      cursor: pointer;
      height: fit-content;
      background-color: ${baseTheme.colors.mainLightBlue};
      color: ${baseTheme.colors.white};
      font-weight: 500;
      font-size: 14px;
      width: 110px;
      padding: 6px 19px;
      border-radius: 8px;
      border: none;

      &:hover {
        background-color: ${baseTheme.colors.mainBlue};
      }
    }
    .disable {
      pointer-events: none;
      background-color: #b8c0cc;
    }
  }
  .input {
    ::placeholder {
      font-size: 14px;
    }
  }
  .container-n8n-input {
    border-radius: 5px;
    padding: 5px;
    display: flex;
    gap: 10px;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .error-text {
    color: ${baseTheme.colors.error};
    font-size: 12px;
    margin-top: 4px !important;
    max-width: 210px;
  }

  .error-text-price {
    color: ${baseTheme.colors.error};
    font-size: 12px;
  }

  .error-text-accent {
    letter-spacing: 1px;
    text-shadow: #000 0.5px 0 0.5px;
    color: orange;
    font-weight: 700;
    font-size: 12px;
    margin-top: 4px !important;
    max-width: 210px;
  }

  .error-text-margin {
    margin-left: 50px !important;
  }
`;

export const ActiveStatus = styled('div')`
  margin-right: 10px;
  display: flex;
  flex-direction: column;

  .status {
    padding: 10px 0;
    font-weight: 700;
    font-size: 18px;
  }
`;

export const SetStatus = styled('div')`
  display: flex;
  position: relative;

  .status-block {
    margin-right: 24px;

    .set-status {
      display: flex;
      max-height: 170px !important;
      overflow-y: auto;
      justify-content: space-between;
      padding: 10px 8px;
      border-radius: 8px;
      border: 2px solid ${baseTheme.colors.mainLightBlue};
      min-height: 40px;
      width: 187px;
      cursor: pointer;
      max-width: 350px;
      @media (max-width: 768px) {
        width: 150px;
      }
      .text {
        font-weight: 500;
        color: ${baseTheme.colors.mainLightBlue};
        margin-right: 12px;
      }
    }
  }
`;

export const ConfirmButton = styled('div')`
  display: flex;
  align-items: start;
  padding-top: 57px;
  cursor: pointer;

  .btn-text {
    margin-top: -20px;
    padding: 11px 24px;
    background-color: ${baseTheme.colors.mainLightBlue};
    font-weight: 500;
    font-size: 18px;
    color: ${baseTheme.colors.white};
    border-radius: 8px;

    &:hover {
      background-color: ${baseTheme.colors.mainBlue};
    }
  }

  .disable {
    pointer-events: none;
  }
`;

export const CancelAndHistoryBlock = styled('div')`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${baseTheme.colors.stroking};
  align-items: center;
  .cancel-btn-disabled {
    pointer-events: none;
    border-color: ${baseTheme.colors.disabled};
  }
  .cancel-btn {
    @media only screen and (max-width: 550px) {
      width: 100%;
      height: 70px;
      margin: 0 0 0 20px;
      padding: 10px !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
