import styled from 'styled-components';
import { baseTheme } from '../../global/styles/theme';

export const PharmacistPromoCodePageContainer = styled('div')`
  max-width: 1216px;
  margin: auto;
  padding: 34px 0;

  .text-warning {
    border-radius: 30px;
    font-weight: 700;
    line-height: 22px;
    padding: 16px;
    background-color: #e7e7e7;
    color: rgb(127, 136, 149);
  }
  .bolt {
    font-weight: 800;
    color: #000;
  }

  .text-warning-accent {
    font-weight: 900;
    font-style: italic;
    color: rgb(235, 87, 87);
  }

  .promocode {
    font-size: 16px;
    font-weight: 400;
    color: #535d6a;

    .promocode-name {
      font-weight: 700;
      color: #121f30;
    }
  }

  .bonus-title-error {
    font-size: 16px;
    font-weight: 600;
    color: ${baseTheme.colors.error};
  }
  .bonus-title {
    font-size: 16px;
    font-weight: 400;
    color: #535d6a;
  }
`;

export const TableBodyContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .table-cell-lg {
    width: 40% !important;
  }

  .table-cell-md {
    width: 30% !important;
  }

  .table-cell-sm {
    width: 20% !important;
  }

  .table-cell {
    padding: 12px;
    border: 1px solid ${baseTheme.colors.stroking};
    color: ${baseTheme.colors.secondary};
    width: 25%;
    text-align: center;

    .description-bold {
      font-weight: 700;
      color: #121f30;
      margin: 15px 0px;
    }

    .price-input {
      width: 100%;
      color: #535d6a;
      font-size: 16px;
      -webkit-appearance: none;
      border: 2px solid ${baseTheme.colors.stroking};
      border-radius: 10px;
      padding: 5px 10px;
    }

    .error-input {
      border: 2px solid ${baseTheme.colors.error};
    }

    .error-msg {
      position: absolute;
      bottom: 50px;
      color: ${baseTheme.colors.error};
      width: max-content;
      height: 20px;
    }

    .disabled {
      pointer-events: none;
      background: ${baseTheme.colors.disabled} !important;
      border: 2px solid ${baseTheme.colors.disabled};
    }

    .assembly-btn {
      padding: 12px 24px;
      border-radius: 8px;
      background-color: ${baseTheme.colors.focus};
      cursor: pointer;
      margin-right: 24px;
      color: ${baseTheme.colors.white};
    }
  }
`;
