import { createReducer, createAction } from '@reduxjs/toolkit';
import { OrdersPageState } from './types';
import * as actions from './actions';
import { AppState } from '../../global/types';

export const resetCoordinates = createAction('RESET_COORDINATES');

const initialState: OrdersPageState = {
  ordersPage: {
    data: null,
    loading: false,
    error: null,
  },
  ordersSearch: {
    data: null,
    error: null,
    loading: false,
  },

  selectedOrder: {
    data: null,
    loading: false,
    error: null,
  },

  allOrders: {
    data: null,
    loading: false,
    error: null,
  },
  allNetworkEnabled: {
    data: null,
    loading: false,
    error: null,
  },

  sourcesData: {
    data: null,
    loading: false,
    error: null,
  },

  scheduleData: {
    data: null,
    loading: false,
    error: null,
  },

  notMuted: {
    data: null,
    loading: false,
    error: null,
  },

  notViewed: {
    data: null,
    loading: false,
    error: null,
  },

  createClaim: {
    data: null,
    loading: false,
    error: null,
  },
  coordinates: {
    data: null,
    loading: false,
    error: null,
  },

  orderId: null,

  historyModal: {
    data: null,
    loading: false,
    error: null,
  },
};

const reducer = createReducer(initialState, {
  [actions.getOrders.REQUEST]: (state) => {
    state.ordersPage.loading = true;
  },
  [actions.getOrders.ERROR]: (state, action) => {
    state.ordersPage.error = action.payload;
    state.ordersPage.loading = false;
  },
  [actions.getOrders.SUCCESS]: (state, action) => {
    state.ordersPage.data = action.payload;
    state.ordersPage.loading = false;
  },
  [actions.getALLOrders.REQUEST]: (state) => {
    state.allOrders.loading = true;
  },
  [actions.getALLOrders.ERROR]: (state, action) => {
    state.allOrders.error = action.payload;
    state.allOrders.loading = false;
  },
  [actions.getALLOrders.SUCCESS]: (state, action) => {
    state.allOrders.data = action.payload;
    state.allOrders.loading = false;
  },
  [actions.getAllNetworkEnabled.REQUEST]: (state) => {
    state.allNetworkEnabled.loading = true;
  },
  [actions.getAllNetworkEnabled.ERROR]: (state, action) => {
    state.allNetworkEnabled.error = action.payload;
    state.allNetworkEnabled.loading = false;
  },
  [actions.getAllNetworkEnabled.SUCCESS]: (state, action) => {
    state.allNetworkEnabled.data = action.payload;
    state.allNetworkEnabled.loading = false;
  },
  [actions.getOrdersSearch.REQUEST]: (state) => {
    state.ordersSearch.loading = true;
  },
  [actions.getOrdersSearch.ERROR]: (state, action) => {
    state.ordersSearch.error = action.payload;
    state.ordersSearch.loading = false;
  },
  [actions.getOrdersSearch.SUCCESS]: (state, action) => {
    state.ordersSearch.data = action.payload;
    state.ordersSearch.loading = false;
  },
  [actions.clearOrdersSearch.type]: (state) => {
    state.ordersSearch.data = null;
  },
  [actions.clearOrders.type]: (state) => {
    state.ordersPage.data = null;
  },
  [actions.getOrdersRefresh.SUCCESS]: (state, action) => {
    state.ordersPage.data = action.payload;
  },
  [actions.getOrdersRefresh.ERROR]: (state, action) => {
    state.ordersPage.error = action.payload;
  },
  [actions.cancelOrder.REQUEST]: (state) => {
    state.ordersPage.loading = true;
  },
  [actions.cancelOrder.SUCCESS]: (state) => {
    state.ordersPage.loading = false;
  },
  [actions.cancelOrder.ERROR]: (state) => {
    state.ordersPage.loading = false;
  },
  [actions.putCompletedStatus.REQUEST]: (state) => {
    state.ordersPage.loading = true;
  },
  [actions.putCompletedStatus.SUCCESS]: (state) => {
    state.ordersPage.loading = false;
  },
  [actions.putCompletedStatus.ERROR]: (state) => {
    state.ordersPage.loading = false;
  },
  [actions.putReadyStatus.REQUEST]: (state) => {
    state.ordersPage.loading = true;
  },
  [actions.putReadyStatus.SUCCESS]: (state) => {
    state.ordersPage.loading = false;
  },
  [actions.putReadyStatus.ERROR]: (state) => {
    state.ordersPage.loading = false;
  },
  [actions.putCollectingStatus.REQUEST]: (state) => {
    state.ordersPage.loading = true;
  },
  [actions.putCollectingStatus.ERROR]: (state) => {
    state.ordersPage.loading = false;
  },
  [actions.putCollectingStatus.SUCCESS]: (state) => {
    state.ordersPage.loading = false;
  },
  [actions.putClarificationStatus.REQUEST]: (state) => {
    state.ordersPage.loading = true;
  },
  [actions.putClarificationStatus.ERROR]: (state) => {
    state.ordersPage.loading = false;
  },
  [actions.putClarificationStatus.SUCCESS]: (state) => {
    state.ordersPage.loading = false;
  },
  [actions.putStatus.REQUEST]: (state) => {
    state.ordersPage.loading = true;
  },
  [actions.putStatus.ERROR]: (state, action) => {
    state.ordersPage.loading = false;
    state.ordersPage.error = action.payload.response.data;
  },
  [actions.putStatus.SUCCESS]: (state) => {
    state.ordersPage.loading = false;
  },
  [actions.setSelectedOrder.REQUEST]: (state) => {
    state.selectedOrder.loading = true;
  },
  [actions.setSelectedOrder.SUCCESS]: (state, action) => {
    state.selectedOrder.data = action.payload;
    state.selectedOrder.loading = false;
  },
  [actions.setSelectedOrder.ERROR]: (state, action) => {
    state.selectedOrder.error = action.payload;
    state.selectedOrder.loading = false;
  },
  [actions.clearSelectedOrder.type]: (state) => {
    state.selectedOrder.data = null;
  },
  [actions.setOrderId.type]: (state, action) => {
    state.orderId = action.payload;
  },
  [actions.clearOrderId.type]: (state, action) => {
    state.orderId = null;
  },
  [actions.getSourcesByNetwork.REQUEST]: (state) => {
    state.sourcesData.loading = true;
  },
  [actions.getSourcesByNetwork.ERROR]: (state, action) => {
    state.sourcesData.error = action.payload;
    state.sourcesData.loading = false;
  },
  [actions.getSourcesByNetwork.SUCCESS]: (state, action) => {
    state.sourcesData.data = action.payload;
    state.sourcesData.loading = false;
  },

  [actions.getSchedule.REQUEST]: (state) => {
    state.scheduleData.loading = true;
  },
  [actions.getSchedule.ERROR]: (state, action) => {
    state.scheduleData.error = action.payload;
    state.scheduleData.loading = false;
  },
  [actions.getSchedule.SUCCESS]: (state, action) => {
    state.scheduleData.data = action.payload;
    state.scheduleData.loading = false;
  },

  [actions.getNotMutedOrders.REQUEST]: (state) => {
    state.notMuted.loading = true;
  },
  [actions.getNotMutedOrders.ERROR]: (state, action) => {
    state.notMuted.error = action.payload;
    state.notMuted.loading = false;
  },
  [actions.getNotMutedOrders.SUCCESS]: (state, action) => {
    state.notMuted.data = action.payload;
    state.notMuted.loading = false;
  },

  [actions.getNotViewedOrders.REQUEST]: (state) => {
    state.notViewed.loading = true;
  },
  [actions.getNotViewedOrders.ERROR]: (state, action) => {
    state.notViewed.error = action.payload;
    state.notViewed.loading = false;
  },
  [actions.getNotViewedOrders.SUCCESS]: (state, action) => {
    state.notViewed.data = action.payload;
    state.notViewed.loading = false;
  },
  [actions.createClaim.REQUEST]: (state) => {
    state.createClaim.loading = true;
  },
  [actions.createClaim.ERROR]: (state, action) => {
    state.createClaim.error = action.payload;
    state.createClaim.loading = false;
  },
  [actions.createClaim.SUCCESS]: (state, action) => {
    state.createClaim.data = action.payload;
    state.createClaim.loading = false;
  },

  [actions.coordinates.REQUEST]: (state) => {
    state.coordinates.loading = true;
  },
  [actions.coordinates.ERROR]: (state, action) => {
    state.coordinates.error = action.payload;
    state.coordinates.loading = false;
  },
  [actions.coordinates.SUCCESS]: (state, action) => {
    state.coordinates.data = action.payload;
    state.coordinates.loading = false;
  },
  [resetCoordinates.type]: (state) => {
    state.coordinates = {
      data: null,
      loading: false,
      error: null,
    };
  },
  [actions.getCartHistory.REQUEST]: (state) => {
    state.historyModal.loading = true;
  },
  [actions.getCartHistory.ERROR]: (state, action) => {
    state.historyModal.error = action.payload;
    state.historyModal.loading = false;
  },
  [actions.getCartHistory.SUCCESS]: (state, action) => {
    state.historyModal.data = action.payload;
    state.historyModal.loading = false;
  },
});

const ordersPageLoading = (state: AppState) =>
  state.ordersPageReducer.ordersPage.loading;
const ordersPageError = (state: AppState) =>
  state.ordersPageReducer.ordersPage.error;
const ordersPageData = (state: AppState) =>
  state.ordersPageReducer.ordersPage.data;

const allNetworkEnabledData = (state: AppState) =>
  state.ordersPageReducer.allNetworkEnabled.data;

const ordersAllData = (state: AppState) =>
  state.ordersPageReducer.allOrders.data;

const ordersSearchLoading = (state: AppState) =>
  state.ordersPageReducer.ordersSearch.loading;
const ordersSearchError = (state: AppState) =>
  state.ordersPageReducer.ordersSearch.error;
const ordersSearchData = (state: AppState) =>
  state.ordersPageReducer.ordersSearch.data;

const selectedOrderData = (state: AppState) =>
  state.ordersPageReducer.selectedOrder.data;

const selectedOrderLoading = (state: AppState) =>
  state.ordersPageReducer.selectedOrder.loading;

const orderId = (state: AppState) => state.ordersPageReducer.orderId;

const sourcesData = (state: AppState) =>
  state.ordersPageReducer.sourcesData.data;

const scheduleData = (state: AppState) =>
  state.ordersPageReducer.scheduleData.data;
const scheduleDataLoading = (state: AppState) =>
  state.ordersPageReducer.scheduleData.loading;

const notMutedOrders = (state: AppState) =>
  state.ordersPageReducer.notMuted.data;

const notViewedOrders = (state: AppState) =>
  state.ordersPageReducer.notViewed.data;

const createClaim = (state: AppState) => state.ordersPageReducer.createClaim;

const coordinates = (state: AppState) => state.ordersPageReducer.coordinates;

const historyModalData = (state: AppState) =>
  state.ordersPageReducer.historyModal.data;
const historyModalLoading = (state: AppState) =>
  state.ordersPageReducer.historyModal.loading;

const selectors = {
  coordinates,
  createClaim,
  ordersPageLoading,
  ordersPageError,
  ordersPageData,
  allNetworkEnabledData,
  selectedOrderData,
  selectedOrderLoading,
  ordersSearchData,
  ordersSearchError,
  ordersSearchLoading,
  orderId,
  ordersAllData,
  sourcesData,
  scheduleData,
  scheduleDataLoading,
  notMutedOrders,
  notViewedOrders,
  historyModalData,
  historyModalLoading,
};

export { selectors };
export default reducer;
