import styled from 'styled-components';
import { baseTheme } from '../../../../../../global/styles/theme';

export const TagsContainer = styled('div')<{ isAdd?: boolean }>`
  @keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translate3d(0px, 10%, 0px);
    }
    100% {
      opacity: 1;
      transform: none;
    }
  }

  position: absolute;
  z-index: 200;
  width: auto;
  top: ${({ isAdd }) => (isAdd ? '35px' : '54px')};
  background-color: ${baseTheme.colors.white};
  padding: 0 16px 16px;
  box-shadow: 0 4px 10px rgba(37, 54, 67, 0.2);
  border-radius: 8px;
  max-height: 290px;
  margin-left: ${({ isAdd }) => (isAdd ? '0' : '12px')};
  overflow-y: auto;
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-name: fadeInDown;

  .border {
    height: 1px;
    background: #e0e5eb;
  }

  .sticky-btn {
    position: sticky;
    bottom: 0;
  }
`;

export const Tag = styled('div')<{ color?: string }>`
  cursor: pointer;
  padding: 10px 0;
  font-weight: 500;
  font-size: 16px;
  color: ${baseTheme.colors.secondary};

  label.filter-title {
    background-color: ${({ color }) =>
      color ? `${color} !important` : `${baseTheme.colors.secondary}`};
    padding: 4px 8px;
    border-radius: 4px !important;
    font-weight: 600 !important;
    width: fit-content;
  }

  label:before {
    top: 4.5px !important;
    left: 4px !important;
  }

  label:after {
    top: 8px !important;
    left: 8px !important;
  }

  &:hover {
    color: ${baseTheme.colors.primary} !important;
  }
`;
