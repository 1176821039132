import copy from 'copy-to-clipboard';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectors as loginSelectors } from '../../containers/LoginPage/reducer';
import { setToLocalStorage } from '../../global/helpers';
import * as Styled from './ErrorAlertModal.styles';
import * as actionsNotification from '../../containers/InitComponent/actions';

interface ErrorAlertModalInfoProps {
  errorModalInfo: {
    url: string;
    status: number | string;
    error_message: string;
    time: number | string;
    code: string | null;
  };
}

const ErrorAlertModal = ({ errorModalInfo }: ErrorAlertModalInfoProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userData = useSelector(loginSelectors.userData);

  const [timerValue, setTimerValue] = useState<string | null>(null);

  const toHoursAndMinutes = (totalSeconds: number) => {
    const minutes = Math.floor(totalSeconds / 60000);
    const seconds = Math.floor(totalSeconds % 60000) / 1000;

    const formatedSeconds =
      Number(seconds.toFixed()) > 9
        ? seconds.toFixed()
        : `0${seconds.toFixed()}`;

    return `${minutes} : ${formatedSeconds}`;
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const targetDate = new Date(errorModalInfo.time);
      const currentDate = new Date();
      const timeDifference = +targetDate - +currentDate;

      if (timeDifference === 0 || timeDifference < 0) {
        clearInterval(intervalId);
        setTimerValue(null);
        return;
      }

      setTimerValue(toHoursAndMinutes(timeDifference));
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const handleCopyToClipboard = () => {
    const jsonString = JSON.stringify(
      errorModalInfo,
      (key, value) => (key === 'key3' ? undefined : value),
      0
    );

    copy(jsonString);

    dispatch(
      actionsNotification.addApplicationNotification({
        content: t('ERROR_MESSAGE_MODAL.SUCCESS_COPY'),
        canBeClosed: true,
        type: 'success',
      })
    );
  };

  if (errorModalInfo.code && errorModalInfo.code.includes('O')) {
    return null;
  }
  return (
    <Styled.ErrorAlertModalContainer>
      <div className="content-container">
        <div className="title-text-container">
          {errorModalInfo.error_message !==
            t('ERROR_MESSAGE_MODAL.POOR_CONNECTION') &&
            errorModalInfo.error_message !== 'Network Error' && (
              <>
                <p className="error-title">
                  {t('ERROR_MESSAGE_MODAL.DESCRIPTION')}
                </p>

                <p className="call-center-title">
                  {t('ERROR_MESSAGE_MODAL.CALL_CENTER.TITLE')}
                  <span>{t('ERROR_MESSAGE_MODAL.CALL_CENTER.NUMBER')}</span>.
                </p>
              </>
            )}
        </div>
        <div className="error-text-container">
          {errorModalInfo.url && (
            <p className="error-text">
              {t('ERROR_MESSAGE_MODAL.URL_FIELD')}{' '}
              <span>{errorModalInfo.url}</span>
            </p>
          )}
          {errorModalInfo.status && (
            <p className="error-text">
              {t('ERROR_MESSAGE_MODAL.STATUS_FIELD')}
              <span>{errorModalInfo.status}</span>
            </p>
          )}
          {errorModalInfo.error_message && (
            <p className="error-text">
              {t('ERROR_MESSAGE_MODAL.MESSAGE_FIELD')}{' '}
              <span>{errorModalInfo.error_message}</span>
            </p>
          )}
          <div>
            {userData?.role && (
              <span className="error-text">
                {t('ERROR_MESSAGE_MODAL.ROLE')} <span>{userData?.role},</span>
              </span>
            )}
            {userData?.networkName && (
              <span className="error-text">
                {t('ERROR_MESSAGE_MODAL.NETWORK_NAME')}{' '}
                <span>{userData?.networkName},</span>
              </span>
            )}
            {userData?.sourceCode && (
              <span className="error-text">
                {t('ERROR_MESSAGE_MODAL.SOURCE_CODE')}{' '}
                <span>{userData?.sourceCode},</span>
              </span>
            )}

            {userData?.phone && (
              <span className="error-text">
                {t('ERROR_MESSAGE_MODAL.PHONE_NUMBER')}{' '}
                <span>{userData?.phone}</span>
              </span>
            )}
          </div>
        </div>
        <div className="buttons-container">
          {errorModalInfo.time && <p className="timer-text">{timerValue}</p>}
          <div
            className="close-btn"
            onClick={() => {
              setToLocalStorage('requestErrorData', null);
              window.dispatchEvent(new Event('storage'));
            }}
          >
            V
          </div>
        </div>
      </div>
      <div className="copy-button" onClick={handleCopyToClipboard}>
        Скопировать в буфер обмена
      </div>
    </Styled.ErrorAlertModalContainer>
  );
};

export default ErrorAlertModal;
