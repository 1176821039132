import { OrderTableProductData } from '../OrdersPage/types';
import { OrderPageState, PharmacyMappedMedicineItemProps } from './types';
import {
  isLocalStorageAvailable,
  setToLocalStorage,
} from '../../global/helpers';
import { ORDER_KEY } from '../../global/constants';
import { synonymsList } from './constants';

export const needsRecipe = (data: OrderTableProductData[] | null) => {
  const arrayOfRecipe = data?.map((item) => item.needsRecipe);
  return arrayOfRecipe?.find((item: boolean) => item);
};

export const formatOrderInfo = (
  addressId: number,
  deliveryType: string,
  paymentMethod: string,
  img: string,
  source: string,
  paid: boolean,
  paidDelivery: boolean,
  items: any
) => {
  return {
    address_id: addressId,
    delivery_method: deliveryType === 'self' ? deliveryType : 'delivery_yandex',
    receipts: [img],
    items: Object.values(items).map((item: any) => {
      if (item.proposal) {
        return {
          sku: item.sku,
          count_desired: item.quantity,
          pharmacy_count: item.pharmacyCount,
          proposal: {
            count_desired: item.proposal.count,
            pharmacy_count: item.proposal.pharmacyCount,
            sku: item.proposal.sku,
          },
        };
      }
      return {
        sku: item.sku,
        count_desired: item.quantity,
        pharmacy_count: item.pharmacyCount,
      };
    }),
    payment_method: paymentMethod === 'kaspi_pay' ? 'kaspi' : 'in_place',
    source,
    paid,
    paid_delivery: paidDelivery,
  };
};

export const setOrderInfo = (
  sourceCode: string,
  medicineList: PharmacyMappedMedicineItemProps[]
) => {
  if (isLocalStorageAvailable()) {
    setToLocalStorage(ORDER_KEY, { sourceCode, medicineList });
  }
};

export const mapDataWithPrice = (data: any, price: any) => {
  return {
    data,
    pricesAndQuantity: price,
  };
};

export const mapInterpayReport = (data: OrderPageState['interpayPayouts']) => {
  const statusesWithoutDate = [
    'order_closed',
    'refund_withholding',
    'wait_refund',
    'wait_refund',
  ];
  return data.map((el) => {
    return {
      status: el.status,
      payout_date: statusesWithoutDate.includes(el.status)
        ? el.created_at
        : el.payout_date,
      comment: el.comment || '',
      payout_info: el.payout_info || '',
      sum: el.sum || 0,
      created_at: el.created_at,
    };
  });
};

export const formatOrderTimer = (
  comment: string,
  remaining: number | null,
  userMessage?: string | null
) => {
  return {
    comment,
    remaining,
    user_message: userMessage || null,
  };
};

export const formatOrderHours = (
  comment: string,
  date: string,
  userMessage?: string | null
) => {
  return {
    comment,
    end: date,
    user_message: userMessage || null,
  };
};

const getSplitedName = (name: string) => {
  const productNameArr = name
    .replace(/,/g, '')
    .split(' ')
    .map((item) =>
      item.toLowerCase().replace(/(\d+)/g, '$1 ').replace(/-/g, '').trim()
    );

  const productNameSliced: string[] = [];

  productNameArr.forEach((item) => {
    const wordsAndNumbers = item
      .split(/(\d+)/)
      .filter((el) => el !== '')
      .map((item) => item.trim());
    productNameSliced.push(...wordsAndNumbers);
  });

  return productNameSliced;
};

export const getClassNameByWordType = (
  filteredElements: { value: string; type: string }[],
  currentWord: string
) => {
  const currentWordTheSameElems = filteredElements.filter(
    (item: { value: string; type: string }) =>
      item.value.toLowerCase() ===
      currentWord
        .toLowerCase()
        .replace(/[.,]/g, '')
        .replace(/-/g, '')
        .replace(/(\d+)/g, '$1 ')
        .trim()
  );

  const correctWord =
    currentWordTheSameElems.find((item) => item.type === 'synonym') ||
    currentWordTheSameElems[0];
  if (correctWord?.type === 'same') {
    return 'same-word';
  }
  if (correctWord?.type === 'synonym') {
    return 'synonym-word';
  }
  return 'not-same-word';
};

export const handleCompare = (name: string, productName: string) => {
  const allSynonymsList: string[] = [].concat(...(synonymsList as any));
  const nameWordsList = getSplitedName(name);
  const productNameWordsList = getSplitedName(productName);

  const filteredElements: { value: string; type: string }[] = [];

  let longName =
    name.length > productName.length ? nameWordsList : productNameWordsList;
  let shortName =
    name.length < productName.length ? nameWordsList : productNameWordsList;
  if (longName === shortName) {
    longName = nameWordsList;
    shortName = productNameWordsList;
  }

  longName.forEach((elem: string) => {
    if (
      shortName
        .map((item) => item.replace(/\./g, ''))
        .includes(elem.toLowerCase().replace(/[.,]/g, ''))
    ) {
      filteredElements.push({
        value: elem.replace(/[.,]/g, ''),
        type: 'same',
      });
    }

    if (allSynonymsList.includes(elem.toLowerCase().replace(/[.,]/g, ''))) {
      const list = synonymsList.filter((item) =>
        item.includes(elem.toLowerCase().replace(/[.,]/g, ''))
      );

      if (
        shortName.includes(elem) ||
        shortName
          .map((item) => item.replace(/\./g, ''))
          .every((element) => !list[0].includes(element))
      ) {
        return;
      }

      const synonymElements = [].concat(...(list[0] as any)).map((item) => ({
        value: item as string,
        type: 'synonym' as string,
      }));
      filteredElements.push(...synonymElements);
    }
  });

  return filteredElements;
};

export const getStrongRecipeProducts = (products: OrderTableProductData[]) => {
  return products.filter((product) => product.needsStrongRecipe) || [];
};
