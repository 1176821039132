import React from 'react';
import { ClickAwayListener } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as Styled from './FilterByDelivery.styles';
import { deliverySelect } from './constants';
import success from '../../../../../../global/media/Vector (2).svg';

interface FilterByPaymentProps {
  onClose: () => void;
  onDeliveryCLick: (delivery: string) => void;
}

const FilterByPayment = ({
  onClose,
  onDeliveryCLick,
}: FilterByPaymentProps) => {
  const { t } = useTranslation();

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Styled.DeliveryContainer>
        <div
          className="title"
          key="all"
          onClick={() => onDeliveryCLick('' as string)}
        >
          Все способы
        </div>
        {deliverySelect.map((item: { title: string; code: string }) => (
          <div
            className="title"
            key={item.title}
            onClick={() => onDeliveryCLick(item.code)}
          >
            {item.title}
          </div>
        ))}
      </Styled.DeliveryContainer>
    </ClickAwayListener>
  );
};

export default FilterByPayment;
