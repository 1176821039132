import styled from 'styled-components';

const goldenApple = require('../../../../global/media/golden_apple.jpg');

export const GiftCard = styled('div')`
  width: 320px;
  height: 192px;
  border-radius: 1rem;
  background-image: url('${goldenApple}');
  background-size: cover;
  background-position: center;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  transform-style: preserve-3d;
  animation: cardRotate 1.5s ease-in-out infinite alternate;
  position: relative;
  .gift-card-shine {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    opacity: 0.3;
    filter: blur(4px);
    background: linear-gradient(
      135deg,
      transparent 0%,
      rgba(255, 255, 255, 0.4) 100%
    );
    transform: translateZ(1px);
  }

  @keyframes cardRotate {
    0% {
      transform: perspective(1500px) rotateY(-15deg);
    }
    100% {
      transform: perspective(1500px) rotateY(15deg);
    }
  }
`;
