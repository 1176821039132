import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './OneActivePharmacist.styles';

interface OneActiveParam {
  person: {
    name: string;
    phone: string;
    role: string;
    active: boolean;
  };
  onPersonClick: (e: any) => void;
  isPharmacistOnOrder: boolean;
  pharmacistOnOrder?: { name: string; phone: string } | null;
}

const OneActivePharmacist = ({
  person,
  onPersonClick,
  isPharmacistOnOrder,
  pharmacistOnOrder,
}: OneActiveParam) => {
  const { t } = useTranslation();
  const { name, active, phone, role } = person;
  const [oneActive, setActive] = useState<boolean>(active);

  useEffect(() => {
    isPharmacistOnOrder ? setActive(false) : setActive(active);
    isPharmacistOnOrder &&
    pharmacistOnOrder &&
    // @ts-ignore
    pharmacistOnOrder?.collector?.phone === phone
      ? setActive(true)
      : isPharmacistOnOrder && setActive(false);
  }, [active, pharmacistOnOrder]);

  const handleClickPerson = () => {
    if (isPharmacistOnOrder) {
      setActive(true);
      onPersonClick({
        collector: {
          name,
          phone,
        },
      });
    } else {
      onPersonClick(phone);
      setActive(!oneActive);
    }
  };

  return (
    <Styled.OneContainer onClick={handleClickPerson}>
      <div className={`one-pharmacist-block ${oneActive && 'active-green'}`}>
        <div className="one-pharmacist-block__name">{name}</div>
        <div className="one-pharmacist-block__phone">+{phone}</div>
        <div className="one-pharmacist-block__role">{role}</div>
        {active && (
          <div className="one-pharmacist-block__on-shift">
            {t('OrdersPage.ActivePharmacistBlock.ON_SHIFT')}
          </div>
        )}
      </div>
    </Styled.OneContainer>
  );
};

export default OneActivePharmacist;
