import styled from 'styled-components';
import { baseTheme } from '../../../../global/styles/theme';

export const PharmacyContainer = styled('div')`
  width: 400px;
  margin: 0 -30px 20px -30px;
  max-height: 460px;
  z-index: 1000;
`;

export const PharmacyTitle = styled('div')`
  font-weight: 500;
  font-size: 18px;
  color: ${baseTheme.colors.mainLightBlue};
`;

export const PharmaciesBlock = styled('div')`
  margin-top: 24px;
  .network-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
    width: 100%;
    cursor: pointer;
    border-bottom: 1px solid ${baseTheme.colors.stroking};
    &:hover {
      background-color: rgba(10, 119, 199, 0.1);
    }

    .pharmacy {
      cursor: pointer;
      padding: 6px 12px;
    }
  }
`;
