import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './CustomerModalInfo.styles';
import { OrderTableData } from '../../../OrdersPage/types';
import ModalWindow from '../../../../components/ModalWindow';

interface CustomerModalInfoProps {
  data: OrderTableData;
  onClose: () => void;
}

const CustomerModalInfo = ({ data, onClose }: CustomerModalInfoProps) => {
  const { t } = useTranslation();

  const productsRecipe = data.products.filter((item) => item.needsRecipe);

  return (
    <ModalWindow onClose={onClose}>
      <Styled.Number>
        {t('OrderPage.RecipeModal.NUMBER')}
        <span>{data.phone}</span>
      </Styled.Number>
      <Styled.Title>{t('OrderPage.RecipeModal.TITLE')}</Styled.Title>
      <Styled.Description>
        <div className="greeting">
          {t('OrderPage.RecipeModal.GREETING')}{' '}
          <span className="source-name">{data.sourceName}</span>
        </div>
        <div className="order">
          {t('OrderPage.RecipeModal.ORDER')}{' '}
          <span className="order-number">{data.orderNumber}</span>
          {t('OrderPage.RecipeModal.SERVICE')}
        </div>
        <div className="recipe-product">
          {t('OrderPage.RecipeModal.RECIPE_PRODUCT')}{' '}
        </div>
      </Styled.Description>
    </ModalWindow>
  );
};

export default CustomerModalInfo;
