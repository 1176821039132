export const getCities = () => {
  try {
    return JSON.parse(process.env.REACT_APP_CITY_LIST || '') || [];
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getEncryptedCity = (firstParam: string) => {
  const cityList = getCities();
  return encodeURIComponent(
    (
      cityList.find((f: any) =>
        f.ruName.includes((firstParam || '')?.toLowerCase())
      ) || cityList[0]
    ).ruName
  );
};
