import axios from 'axios';
import { WebStockProductData } from './types';

export class WebStockService {
  static API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/api/v2/`;

  static getWebStockProducts = async (networkCode: string) => {
    const response = await axios({
      method: 'get',
      url: `${WebStockService.API_BASE_URL}admin/webstock/products/${networkCode}`,
    });
    return response.data;
  };

  static editWebStockProducts = async (
    networkCode: string,
    editedProduct: WebStockProductData
  ) => {
    const response = await axios({
      method: 'post',
      url: `${WebStockService.API_BASE_URL}admin/webstock/products/${networkCode}/update`,
      data: JSON.stringify(editedProduct),
    });

    return response.data;
  };
}
