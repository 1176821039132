import { useTranslation } from 'react-i18next';
import ModalWindow from '../../../../../../components/ModalWindow';
import { TagsTypeOptions } from '../../../../types';
import { PharmacyTag } from '../../../../../SourcePage/types';
import * as Styled from './TagConfirmationModal.styles';

interface DeleteConfirmationModalProps {
  tagValues: PharmacyTag;
  onCloseHandler: () => void;
  isDelete: boolean;
  onDeleteSuccess: (value: number) => void;
  tagType: string;
  code: string;
}

const TagsConfirmationModal = ({
  tagType,
  code,
  onDeleteSuccess,
  tagValues,
  onCloseHandler,
  isDelete,
}: DeleteConfirmationModalProps) => {
  const { t } = useTranslation();

  return (
    <ModalWindow onClose={onCloseHandler}>
      <Styled.TagConfirmationModalContainer>
        {isDelete && (
          <p className="text-content">
            {t('PharmacyTagsPage.TAG_CONFIRMATION_MODAL.UNMAP_TAG')}{' '}
            <span
              style={{ color: tagValues.color }}
              className="highlighted-text"
            >
              {tagValues.name}
            </span>{' '}
            {t('PharmacyTagsPage.TAG_CONFIRMATION_MODAL.FROM')}{' '}
            {tagType === TagsTypeOptions.network
              ? t('PharmacyTagsPage.TAG_CONFIRMATION_MODAL.NETWORK')
              : t('PharmacyTagsPage.TAG_CONFIRMATION_MODAL.SOURCE')}{' '}
            <span className="highlighted-text"> {code}</span> ?
          </p>
        )}
        <Styled.ActionBtnContainer>
          <Styled.ActionBtn onClick={() => onDeleteSuccess(tagValues.id)}>
            {t('PharmacyTagsPage.TAG_CONFIRMATION_MODAL.SUCCESS')}
          </Styled.ActionBtn>
          <Styled.ActionBtn className="warning-btn" onClick={onCloseHandler}>
            {t('PharmacyTagsPage.TAG_CONFIRMATION_MODAL.CANCEL')}
          </Styled.ActionBtn>
        </Styled.ActionBtnContainer>
      </Styled.TagConfirmationModalContainer>
    </ModalWindow>
  );
};

export default TagsConfirmationModal;
