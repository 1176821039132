import { createReducer } from '@reduxjs/toolkit';
import * as actions from './actions';
import { AppState } from '../../global/types';

const initialState = {
  analogsData: {
    data: null,
    loading: false,
    error: null,
  },
  questionsData: {
    data: null,
    loading: false,
    error: null,
  },
  informationData: {
    data: null,
    loading: false,
    error: null,
  },
};

const reducer = createReducer(initialState, {
  [actions.getAnalogsListBySku.REQUEST]: (state) => {
    state.analogsData.loading = true;
  },
  [actions.getAnalogsListBySku.SUCCESS]: (state, action) => {
    state.analogsData.data = action.payload;
    state.analogsData.loading = false;
  },
  [actions.getAnalogsListBySku.ERROR]: (state, action) => {
    state.analogsData.error = action.payload;
    state.analogsData.loading = false;
  },
});

const analogsData = (state: AppState) =>
  state.analogsPageReducer.analogsData.data;

const questionsData = (state: AppState) =>
  state.analogsPageReducer.questionsData.data;
const informationData = (state: AppState) =>
  state.analogsPageReducer.informationData.data;

const selectors = {
  analogsData,
  questionsData,
  informationData,
};

export { selectors };
export default reducer;
