import { createReducer } from '@reduxjs/toolkit';
import * as actions from './actions';
import { AppState } from '../../global/types';
import { ReportPageState } from './types';

const initialState: ReportPageState = {
  pdfReport: {
    data: null,
    loading: false,
    error: null,
  },
  ordersReport: {
    data: null,
    loading: false,
    error: null,
  },
  ordersReportYest: {
    data: null,
    loading: false,
    error: null,
  },
  refunds: {
    data: null,
    error: null,
    loading: false,
  },
  payments: {
    data: null,
    error: null,
    loading: false,
  },
};

const reducer = createReducer(initialState, {
  [actions.generateReport.REQUEST]: (state) => {
    state.pdfReport.loading = true;
  },

  [actions.generateReport.SUCCESS]: (state, action) => {
    state.pdfReport.loading = false;
    state.pdfReport.data = action.payload;
  },

  [actions.generateReport.ERROR]: (state, action) => {
    state.pdfReport.loading = false;
    state.pdfReport.error = action.payload;
  },

  [actions.getReportByDate.REQUEST]: (state) => {
    state.ordersReport.loading = true;
  },

  [actions.getReportByDate.SUCCESS]: (state, action) => {
    state.ordersReport.loading = false;
    state.ordersReport.data = action.payload;
  },

  [actions.getReportByDate.ERROR]: (state, action) => {
    state.ordersReport.loading = false;
    state.ordersReport.error = action.payload;
  },

  [actions.getReportByDateYest.REQUEST]: (state) => {
    state.ordersReportYest.loading = true;
  },

  [actions.getReportByDateYest.SUCCESS]: (state, action) => {
    state.ordersReportYest.loading = false;
    state.ordersReportYest.data = action.payload;
  },

  [actions.getReportByDateYest.ERROR]: (state, action) => {
    state.ordersReportYest.loading = false;
    state.ordersReportYest.error = action.payload;
  },

  [actions.clearReport.type]: (state) => {
    state.pdfReport.data = null;
  },

  [actions.getRefunds.REQUEST]: (state) => {
    state.refunds.loading = true;
  },

  [actions.getRefunds.SUCCESS]: (state, action) => {
    state.refunds.loading = false;
    state.refunds.data = action.payload;
  },

  [actions.getRefunds.ERROR]: (state, action) => {
    state.refunds.loading = false;
    state.refunds.error = action.payload;
  },
  [actions.getPayments.REQUEST]: (state) => {
    state.payments.loading = true;
  },

  [actions.getPayments.SUCCESS]: (state, action) => {
    state.payments.loading = false;
    state.payments.data = action.payload;
  },

  [actions.getPayments.ERROR]: (state, action) => {
    state.payments.loading = false;
    state.payments.error = action.payload;
  },
});

const pdf = (state: AppState) => state.reportPageReducer.pdfReport.data;
const ordersToday = (state: AppState) =>
  state.reportPageReducer.ordersReport.data;
const ordersTodayLoading = (state: AppState) =>
  state.reportPageReducer.ordersReport.loading;
const ordersYesterday = (state: AppState) =>
  state.reportPageReducer.ordersReportYest.data;
const ordersYesterdayLoading = (state: AppState) =>
  state.reportPageReducer.ordersReportYest.loading;
const refunds = (state: AppState) => state.reportPageReducer.refunds.data;
const payments = (state: AppState) => state.reportPageReducer.payments.data;

const selectors = {
  pdf,
  ordersToday,
  ordersYesterday,
  ordersTodayLoading,
  ordersYesterdayLoading,
  refunds,
  payments,
};

export { selectors };
export default reducer;
