import styled from 'styled-components';
import { baseTheme } from '../../../../global/styles/theme';

export const TagsComponentContainer = styled('div')`
  .title {
    color: ${baseTheme.colors.mainLightBlue};
    font-weight: 700;
    font-size: 20px;
    padding-bottom: 34px;
    padding: 0px;
  }

  .highlighted-text {
    font-weight: 500;
    font-size: 18px;
    color: #0a77c7;
  }
`;

export const TagsTableContainer = styled('div')`
  padding: 50px 0;
`;

export const TagsTableHeaderContainer = styled('div')`
  padding-top: 56px;
  display: flex;
  justify-content: flex-start;

  .header-row-lg {
    width: 40% !important;
  }

  .header-row-md {
    width: 30% !important;
  }

  .header-row-sm {
    width: 10% !important;
  }

  .header-row {
    padding: 12px;
    border: 1px solid ${baseTheme.colors.stroking};
    color: ${baseTheme.colors.secondary};
    width: 25%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`;

export const TableBodyContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .table-cell-lg {
    width: 40% !important;
  }

  .table-cell-md {
    width: 30% !important;
  }

  .table-cell-sm {
    width: 10% !important;
  }

  .table-cell {
    position: relative;
    padding: 12px;
    border: 1px solid ${baseTheme.colors.stroking};
    color: ${baseTheme.colors.secondary};
    width: 25%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    .error-msg {
      position: absolute;
      top: 42px;
      left: 0мpx;
      color: ${baseTheme.colors.error};
      width: max-content;
      height: 20px;
    }

    .single-elem {
      margin: 0 auto !important;
    }

    .action-container {
      width: 100%;
      display: flex;
      justify-content: space-between !important;

      .submit-changes-btn {
        font-weight: 400;
        font-size: 16px;
        padding: 8px 20px;
        border-radius: 8px;
        background-color: ${baseTheme.colors.focus};
        border: none;
        cursor: pointer;
        color: ${baseTheme.colors.white};
      }
    }

    .disabled {
      pointer-events: none;
      background: ${baseTheme.colors.disabled} !important;
      border: 2px solid ${baseTheme.colors.disabled};
      padding: 6px 18px;
    }

    input {
      width: 100%;
      color: ${baseTheme.colors.secondary};
      font-size: 16px;
      -webkit-appearance: none;
      border: none;
      padding: 0;
      background-color: inherit;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: ${baseTheme.colors.tertiary};
      }
    }
  }
`;
export const CustomTextareaContainer = styled('div')`
  width: 100%;

  .textarea {
    width: 100%;
    outline: none !important;
    border: none;
    background-color: transparent;
    color: ${baseTheme.colors.primary} !important;
    font-family: 'Helvetica Neue', Helvetica, sans-serif;
    font-size: 16px;
    padding: 10px 12px;
    min-height: 100px;
    min-width: 100%;
  }

  .textarea::placeholder {
    color: ${baseTheme.colors.tertiary};
    font-family: 'Helvetica Neue', Helvetica, sans-serif;
  }

  .textarea:active {
    border: 1px solid ${baseTheme.colors.mainLightBlue};
  }

  .textarea.error {
    background-color: rgb(${baseTheme.colors.error}, 0.05);
    border: 1px solid ${baseTheme.colors.error};
  }

  .nonResizable {
    resize: none;
  }

  .disabled {
    pointer-events: none;
    overflow-y: hidden !important;
  }
`;

export const AddTag = styled('div')`
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  color: ${baseTheme.colors.white};
  background-color: ${baseTheme.colors.mainLightBlue};
  border-radius: 8px;
  padding: 12px 24px;
  text-align: center;
  max-width: 300px;
  margin: 24px 0 34px 0;
`;

export const EditBtn = styled('div')`
  padding: 8px 20px;
  border-radius: 8px;
  background-color: ${baseTheme.colors.focus};
  cursor: pointer;
  color: ${baseTheme.colors.white};
`;

export const AddTagContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  max-width: 500px;
`;

export const TagsSearchContainer = styled('div')`
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 12px;
  min-width: 316px;
  font-weight: 400;
  font-size: 16px;
  color: ${baseTheme.colors.primary};
  border: 1px solid ${baseTheme.colors.stroking};
  border-radius: 8px;
  width: 200px;

  input {
    width: 100%;
    color: ${baseTheme.colors.secondary};
    font-size: 16px;
    border: none;
    -webkit-appearance: none;
    padding: 0;
    background-color: inherit;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: ${baseTheme.colors.tertiary};
    }
  }
`;
