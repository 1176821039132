import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalWindow from '../ModalWindow';
import Button from '../Button';
import * as Styled from './ProductConfirmationConnectModal.styles';
import { ProductResponse } from '../../containers/EditPage/types';
import { getFromLocalStorage } from '../../global/helpers';

interface ProductConfirmationProps {
  onClose: () => void;
  dataPharmacy: ProductResponse;
  dataService: ProductResponse;
  onSaveClick: () => void;
}

const ProductConfirmationConnectModal = ({
  onClose,
  dataPharmacy,
  dataService,
  onSaveClick,
}: ProductConfirmationProps) => {
  const { t } = useTranslation();

  const handleCancelClick = () => {
    onClose();
  };

  const handleSaveClick = () => {
    onSaveClick();
  };

  return (
    <ModalWindow onClose={onClose}>
      <Styled.ModalInfoContainer>
        <div> Вы собираетесь привязать Товар</div>
        <div className="products-title">
          {dataPharmacy
            ? dataPharmacy.name
            : getFromLocalStorage('selectedProduct').name}
        </div>
        <div> К Товару </div>
        <div className="products-title">{dataService?.name}</div>
      </Styled.ModalInfoContainer>
      <Styled.ModalButtonsContainer>
        <div className="save-btn">
          <Button
            onClick={handleSaveClick}
            text={t('EditPage.BTN_TEXT_CONTINUE')}
            variant="outlined"
          />
        </div>
        <div className="cancel-btn">
          <Button
            onClick={handleCancelClick}
            text={t('EditPage.BTN_TEXT_CANCEL')}
            variant="contained"
          />
        </div>
      </Styled.ModalButtonsContainer>
    </ModalWindow>
  );
};

export default ProductConfirmationConnectModal;
