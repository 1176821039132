import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { User } from '../../containers/SourcePage/types';
import * as Styled from './UserTable.styles';
import UserTableRow from './components/UserTableRow';
import { UserRole } from '../../containers/OrdersPage/types';

interface UserTableProps {
  data: User[] | undefined;
  newUser?: boolean;
  newUserManager?: boolean;
  networkCode?: string;
  sourceCode?: string;
  onAddNewUser?: () => void;
}

const UserTable = ({
  data,
  newUser,
  newUserManager,
  networkCode,
  sourceCode,
  onAddNewUser,
}: UserTableProps) => {
  const { t } = useTranslation();
  const [newArrUser, setNewArrUser] = useState<any>([]);
  const [newArrUserManager, setNewArrUserManager] = useState<any>([]);
  const history = useHistory();
  const isOperators = history.location.pathname === '/operators';

  useEffect(() => {
    if (newUser && data) {
      setNewArrUser([
        {
          name: null,
          role: isOperators
            ? 'operator'
            : data[0]?.role || (sourceCode ? 'pharmacist' : 'manager'),
          phone: '',
          createdAt: '',
          updatedAt: '',
        },
      ]);
    } else setNewArrUser([]);
  }, [newUser]);

  useEffect(() => {
    if (newUserManager && data) {
      setNewArrUserManager([
        {
          name: null,
          role: 'pharmacy_manager',
          phone: '',
          createdAt: '',
          updatedAt: '',
        },
      ]);
    } else setNewArrUserManager([]);
  }, [newUserManager]);

  const TableHeader = (
    <Styled.UsersHead>
      <Styled.UsersHeaderRow>
        <th className="table__header-cell table__header-cell-number">
          {t('SourcePage.UserTableHeader.NUMBER')}
        </th>
        <th className="table__header-cell">
          {t('SourcePage.UserTableHeader.ROLE')}
        </th>
        <th className="table__header-cell">
          {t('SourcePage.UserTableHeader.NAME')}
        </th>
        <th className="table__header-cell">
          {t('SourcePage.UserTableHeader.PHONE')}
        </th>
        <th className="table__header-cell">
          {t('SourcePage.UserTableHeader.CREATED_AT')}
        </th>
        <th className="table__header-cell">
          {t('SourcePage.UserTableHeader.EDITED_AT')}
        </th>
        <th className="table__header-cell table__header-cell-empty " />
      </Styled.UsersHeaderRow>
    </Styled.UsersHead>
  );

  const roleTitlesStrategy = {
    [UserRole.PHARMACIST]: t('SourcePage.ROLES.PHARMACIST'),
    [UserRole.MANAGER]: t('SourcePage.ROLES.MANAGERS'),
    [UserRole.OPERATOR]: t('SourcePage.ROLES.OPERATORS'),
    [UserRole.PHARMACY_MANAGER]: t('SourcePage.ROLES.PHARMACY_MANAGER'),
  } as any;

  return (
    // eslint-disable-next-line
    <>
      {data && (
        <>
          {/* // @ts-ignore */}
          <Styled.Role>
            {
              roleTitlesStrategy[
                isOperators
                  ? 'operator'
                  : data[0]?.role || (sourceCode ? 'pharmacist' : 'manager')
              ]
            }
          </Styled.Role>
          {TableHeader}
          {data?.map((user, index) => (
            <UserTableRow
              data={user}
              number={index + 1}
              key={user.phone}
              networkCode={networkCode}
              sourceCode={sourceCode}
              onAddNewUser={onAddNewUser}
            />
          ))}
          {newArrUser.length !== 0 &&
            newUser &&
            newArrUser?.map((user: User) => (
              <UserTableRow
                data={user}
                number={Number(data?.length) + 1}
                networkCode={networkCode}
                sourceCode={sourceCode}
                key={user.phone}
                active
                onAddNewUser={onAddNewUser}
              />
            ))}
          {newArrUserManager.length !== 0 &&
            newUserManager &&
            newArrUserManager?.map((user: User) => (
              <UserTableRow
                data={user}
                number={Number(data?.length) + 1}
                networkCode={networkCode}
                sourceCode={sourceCode}
                key={user.phone}
                active
                onAddNewUser={onAddNewUser}
              />
            ))}
        </>
      )}
    </>
  );
};

export default UserTable;
