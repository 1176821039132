import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import * as Styled from './OrdersTable.styles';
import * as actions from '../../../../containers/OrdersPage/actions';
import {
  OrderTableData,
  UserRole,
} from '../../../../containers/OrdersPage/types';
import OrderStatusBlock from '../../../OrderStatusBlock';
import {
  getFromLocalStorage,
  setToLocalStorage,
} from '../../../../global/helpers';
import * as actionsNotification from '../../../../containers/InitComponent/actions';
import copyIcon from '../../../../global/media/copy-icon.png';

interface OrdersTableProps {
  data: any;
  searchValue: string;
  onClose: () => void;
  openDetail: () => void;
}

const OrdersTable = ({
  data,
  searchValue,
  onClose,
  openDetail,
}: OrdersTableProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const role = getFromLocalStorage('role');

  /**
   * Helper for highlighting strings matches
   */
  const getHighlightedString = (itemString: string, searchString: string) => {
    const req = new RegExp(searchString.replace('+', '\\+'), 'gi');
    return itemString?.replace(
      req,
      (str) => `<span className="table-cell--active">${str}</span>`
    );
  };

  const handleOrderClick = (data: OrderTableData) => {
    onClose();
    setToLocalStorage('selectedOrderId', data.id);
    dispatch(actions.clearOrdersSearch());
    dispatch(actions.setOrderId(data.id));
    if (
      role === UserRole.OPERATOR ||
      role === UserRole.HEAD_OPERATOR ||
      role === UserRole.ADMIN ||
      role === UserRole.PHARMACIST ||
      role === UserRole.PHARMACY_MANAGER ||
      role === UserRole.MANAGER
    ) {
      history.push(`/orders/${data.id}`);
    } else openDetail();
  };

  const handleCopyText = (text: string, msgText: string) => {
    navigator.clipboard.writeText(text);
    dispatch(
      actionsNotification.addApplicationNotification({
        content: msgText,
        canBeClosed: true,
        type: 'success',
      })
    );
  };

  return (
    <Styled.OrdersTable>
      <Styled.OrdersTableHeaderRow>
        <tr>
          <th className="table__header-cell">
            {t('OrderSearchModal.OrdersTable.NUMBER')}
          </th>
          {(role === UserRole.OPERATOR ||
            role === UserRole.ADMIN ||
            role === UserRole.HEAD_OPERATOR) && (
            <th className="table__header-cell">
              {t('OrderSearchModal.OrdersTable.PHONE')}
            </th>
          )}
          <th className="table__header-cell">
            {t('OrderSearchModal.OrdersTable.STATUS')}
          </th>
          <th className="table__header-cell">
            {' '}
            {t('OrderSearchModal.OrdersTable.ACTION')}
          </th>
        </tr>
      </Styled.OrdersTableHeaderRow>

      <tbody>
        {data?.map((item: any) => (
          <Styled.OrdersTableRow key={item.orderNumber}>
            <td className="table-cell table-cell--number">
              {parse(getHighlightedString(item.orderNumber, searchValue))}
              <span
                className="copy-icon"
                onClick={() =>
                  handleCopyText(item.orderNumber, 'Номер заказа скопирован')
                }
              >
                <img
                  src={copyIcon}
                  width="16px"
                  height="16px"
                  alt="copy icon"
                />
              </span>
            </td>
            {(role === UserRole.OPERATOR ||
              role === UserRole.ADMIN ||
              role === UserRole.HEAD_OPERATOR) && (
              <td className="table-cell">
                {parse(getHighlightedString(item.phone, searchValue))}
                <span
                  className="copy-icon"
                  onClick={() =>
                    handleCopyText(item.phone, 'Номер телефона скопирован')
                  }
                >
                  <img
                    src={copyIcon}
                    width="16px"
                    height="16px"
                    alt="copy icon"
                  />
                </span>
              </td>
            )}
            {role === UserRole.MANAGER ||
            role === UserRole.PHARMACIST ||
            role === UserRole.PHARMACY_MANAGER ? (
              <td className="table-cell">
                <OrderStatusBlock
                  status={item.statusPharmacy}
                  statusColor={item.statusColorPharmacy}
                  statusName={item.statusNamePharmacy}
                />
              </td>
            ) : (
              <td className="table-cell">
                <OrderStatusBlock
                  status={item.status}
                  statusColor={item.statusColor}
                  statusName={item.statusName}
                />
              </td>
            )}
            <td className="table-cell">
              <div onClick={() => handleOrderClick(item)}>
                {t('OrderSearchModal.OrdersTable.TABLE_BODY.OPEN')}
              </div>
            </td>
          </Styled.OrdersTableRow>
        ))}
      </tbody>
    </Styled.OrdersTable>
  );
};

export default OrdersTable;
