export const baseTheme = {
  colors: {
    mainBlue: '#003e6e',
    mainLightBlue: '#0a77c7',
    focus: '#1fa069',
    focusLight: '#08d17a',
    white: '#ffffff',
    background: '#f6f9ff',
    stroking: '#e0e5eb',
    primary: '#121f30',
    secondary: '#535d6a',
    tertiary: '#7f8895',
    darkBlue: '#002f54',
    error: '#eb5757',
    warning: '#b18500',
    disabled: '#b8c0cc',
    purple: '#8C3AAA',
    grey: '#e7e7e7',
  },
};
