import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  NOTIFICATION_CHANGES_NOT_SAVED,
  NOTIFICATION_CHANGES_SAVED,
} from '../../global/constants';
import * as actionsNotification from '../InitComponent/actions';
import * as actions from './actions';
import { formatSalesProductsData } from './helpers';
import { SalesService } from './service';
import { GetDiscountData, SalesNew, SalesProductFormatedData } from './types';

interface GetSalesProductsSagaPayload {
  type: string;
  payload: { networkCode: string };
}

interface EditSalesProductSagaPayload {
  type: string;
  payload: {
    networkCode: string;
    editedProduct: SalesProductFormatedData;
    postEffect: () => void;
  };
}

interface SaleUpdateResponse {
  type: string;
  payload: { saleInfo: any; postEffect: any };
}

interface DeleteUserParam {
  type: string;
  payload: { sku: string; networkCode: string; postEffect: any };
}

interface CreateNewOrderSaga {
  type: string;
  payload: { createdNewOrder: SalesNew; postEffect: () => void };
}

interface GetProductsInfoSagaPayload {
  type: string;
  payload: { skus: string[]; sourceCode: string; postEffect: any };
}

interface GetAllProductsSagaPayload {
  type: string;
  payload: { city: string };
}

export function* getSalesProductsSaga({
  payload,
}: GetSalesProductsSagaPayload) {
  try {
    const response: { result: SalesProductFormatedData[] } = yield call(
      SalesService.getSalesProducts,
      payload.networkCode
    );

    const formatedSalesProducts = formatSalesProductsData(response.result);

    yield put(actions.getSalesProducts.success(formatedSalesProducts));
  } catch (err) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
  }
}

export function* editSalesProductSaga({
  payload,
}: EditSalesProductSagaPayload) {
  try {
    const response: { result: string } = yield call(
      SalesService.editSalesProducts,
      payload.networkCode,
      payload.editedProduct
    );

    if (payload.postEffect) {
      payload.postEffect();
    }

    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_SAVED,
        canBeClosed: true,
        type: 'success',
      })
    );

    yield put(actions.updateSalesProduct.success(response.result));
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
  }
}

export function* updateSaleCostSaga({ payload }: SaleUpdateResponse) {
  try {
    const response: { status: string } = yield call(
      SalesService.updateCost,
      payload.saleInfo
    );

    if (payload.postEffect) {
      payload.postEffect();
    }

    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_SAVED,
        canBeClosed: true,
        type: 'success',
      })
    );
    yield put(actions.updateCost.success(response.status));
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.updateCost.error(error));
  }
}

export function* deleteProductSaga({ payload }: DeleteUserParam) {
  try {
    const response: { status: string } = yield call(
      SalesService.deleteProduct,
      payload.sku,
      payload.networkCode
    );

    if (payload.postEffect) {
      payload.postEffect();
    }
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_SAVED,
        canBeClosed: true,
        type: 'success',
      })
    );
    yield put(actions.deleteProduct.success(response.status));
  } catch (e) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.deleteProduct.error(e));
  }
}

export function* createNewOrderSaga({ payload }: CreateNewOrderSaga) {
  try {
    const response: { result: any } = yield call(
      SalesService.createNewOrder,
      payload.createdNewOrder
    );

    if (payload.postEffect) {
      payload.postEffect();
    }
    yield put(actions.createNewSaleOrder.success(response.result));
  } catch (error) {
    const response = error as { response: { data: { error: string } } };

    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );

    yield put(actions.createNewSaleOrder.error(error));
  }
}

export function* getAllSalesProductsSaga({
  payload,
}: GetAllProductsSagaPayload) {
  try {
    const response: { result: GetDiscountData[] } = yield call(
      SalesService.getAllSalesProducts,
      payload.city
    );
    yield put(actions.getAllSalesProducts.success(response));
  } catch (err) {
    console.log(err);
  }
}

export function* getProductsInfoSaga({ payload }: GetProductsInfoSagaPayload) {
  try {
    // @ts-ignore
    const response = yield call(
      SalesService.getProductsInfo,
      payload.skus,
      payload.sourceCode
    );
    if (payload.postEffect) {
      payload.postEffect(response);
    }
  } catch (err) {
    console.log(err);
  }
}

export function* salesPageWatcherSaga() {
  yield all([
    takeLatest(actions.getSalesProducts.REQUEST, getSalesProductsSaga),
    takeLatest(actions.deleteProduct.REQUEST, deleteProductSaga),
    takeLatest(actions.updateSalesProduct.REQUEST, editSalesProductSaga),
    takeLatest(actions.updateCost.REQUEST, updateSaleCostSaga),
    takeLatest(actions.createNewSaleOrder.REQUEST, createNewOrderSaga),
    takeLatest(actions.getAllSalesProducts.REQUEST, getAllSalesProductsSaga),
    takeLatest(actions.getProductsInfo.REQUEST, getProductsInfoSaga),
  ]);
}
