import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as actions from './actions';
import * as Styled from '../SettingsPage/SettingsPage.styles';
import { selectors } from './reducer';
import CustomToggle from '../../components/CustomToggle';

const SettingsPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const dataConfig = useSelector(selectors.config);
  const dataCardPayment = useSelector(selectors.cardPayment);

  const [config, setConfig] = useState(
    dataConfig?.data.config.delivery_enabled || false
  );
  const [configPromocode, setConfigPromocode] = useState(
    dataConfig?.data.config.promocode_add_enabled || false
  );
  const [version, setVersion] = useState(
    dataConfig?.data.config.min_app_version || ''
  );

  const [cardPayment, setCardPayment] = useState(dataCardPayment || false);

  useEffect(() => {
    dataConfig?.data && setConfig(dataConfig.data.config.delivery_enabled);
    dataConfig?.data && setVersion(dataConfig.data.config.min_app_version);
    dataConfig?.data &&
      setConfigPromocode(dataConfig.data.config.promocode_add_enabled);
    dataCardPayment && setCardPayment(dataCardPayment);
  }, [dataConfig, dataCardPayment]);

  useEffect(() => {
    dispatch(actions.getConfig.request());
    dispatch(actions.getCardPayment.request());
  }, []);

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVersion(event.target.value);
  };

  const handleClick = () => {
    const isConfigCganges =
      config !== dataConfig?.data.config.delivery_enabled ||
      configPromocode !== dataConfig?.data.config.promocode_add_enabled ||
      version !== dataConfig?.data.config.min_app_version;
    isConfigCganges &&
      dispatch(
        actions.setConfig.request({
          delivery_enabled: config,
          min_app_version: version,
          promocode_add_enabled: configPromocode,
          postEffect: () => {
            dispatch(actions.getConfig.request());
          },
        })
      );
    if (cardPayment !== dataCardPayment) {
      dispatch(
        actions.setCardPayment.request({
          enabled: cardPayment,
          postEffect: () => {
            dispatch(actions.getCardPayment.request());
          },
        })
      );
    }
  };

  return (
    <Styled.SettingsPageContainer>
      <div className="container">
        <div className="title">{t('SettingsPage.TITLE')}</div>
        <div className="toggle-payment">
          <CustomToggle
            checked={config}
            onChange={() => setConfig(!config)}
            title={t('SettingsPage.ON_SETTINGS')}
            id="paid"
            variant="standart"
          />
        </div>
      </div>

      <div className="container">
        <div className="title">{t('SettingsPage.TITLE-PROMO_CODE')}</div>
        <div className="toggle-payment">
          <CustomToggle
            checked={configPromocode}
            onChange={() => setConfigPromocode(!configPromocode)}
            title={t('SettingsPage.ON_SETTINGS')}
            id="promocode"
            variant="standart"
          />
        </div>
      </div>
      <div className="container">
        <div className="title">{t('OrderPage.StatusOperator.VERSION')}</div>
        <input type="text" value={version} onChange={handleChangeInput} />
      </div>
      <div className="container">
        <div className="title">{t('SettingsPage.TITLE-CARD_PAYMENT')}</div>
        <div className="toggle-card-payment">
          <CustomToggle
            checked={cardPayment}
            onChange={() => setCardPayment(!cardPayment)}
            title={t('SettingsPage.ON_SETTINGS')}
            id="cardPayment"
            variant="standart"
          />
        </div>
      </div>
      <div
        className={`confirm-btn ${
          config === dataConfig?.data.config.delivery_enabled &&
          configPromocode === dataConfig?.data.config.promocode_add_enabled &&
          version === dataConfig?.data.config.min_app_version &&
          cardPayment === dataCardPayment &&
          'disabled'
        }`}
        onClick={() =>
          (config !== dataConfig?.data.config.delivery_enabled ||
            configPromocode !== dataConfig?.data.config.promocode_add_enabled ||
            version !== dataConfig?.data.config.min_app_version ||
            cardPayment !== dataCardPayment) &&
          handleClick()
        }
      >
        {t('OrderPage.StatusOperator.SAVE')}
      </div>
    </Styled.SettingsPageContainer>
  );
};

export default SettingsPage;
