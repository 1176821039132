import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import { ClickAwayListener } from '@mui/material';
import * as Styled from './ProductsSearch.styles';
import SearchBar from '../../../../components/SearchBar/SearchBar';
import * as actions from '../../actions';
import { ProductResponse } from '../../types';
import { mapDataForProductInfo } from '../../helpers';
import ProductCard from './comppnents/ProductCard';
import { getFromLocalStorage } from '../../../../global/helpers';
import { selectors } from '../../reducer';
import ProductConfirmationConnectModal from '../../../../components/ProductConfirmationConnectModal';
import { selectors as productsSelectors } from '../../../ProductsPage/reducer';
import { MappingType } from '../../../ProductsPage/types';

interface ProductSearchProps {
  value: string;
  placeholder: string;
  loading: boolean;
  setValue: (value: string) => void;
  results: ProductResponse[];
  attachedProduct: ProductResponse[];
  isSuggestionOpen: boolean;
  onSuggestionClose: () => void;
  selectedProductPharmacy: ProductResponse;
  type:
    | 'blocked'
    | 'verification'
    | 'attached'
    | 'unattached'
    | 'dybl'
    | 'automapping';
}

const ProductsSearch = ({
  value,
  placeholder,
  setValue,
  selectedProductPharmacy,
  results,
  attachedProduct,
  isSuggestionOpen,
  onSuggestionClose,
  loading,
  type,
}: ProductSearchProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const token = getFromLocalStorage('accessToken');
  const [isModalOpen, setModalOpen] = useState(false);

  const selectedProductService = useSelector(selectors.setSelectedProduct);
  const networkForContent = useSelector(productsSelectors.network);
  const storageNetworkForContent = getFromLocalStorage('networkData');
  const sku = selectedProductService?.sku;
  const wareId = selectedProductPharmacy?.ware_id;
  const mappingCodeForGlobalSearch =
    getFromLocalStorage('selectedProduct').mapping_code;
  const isGlobalSearch = getFromLocalStorage('isGlobalSearch');

  const networkCode = networkForContent?.code
    ? networkForContent?.code
    : storageNetworkForContent?.code;

  const handleSuccessMapping = () => {
    history.push('/products');
    // @ts-ignore
    dispatch(actions.setSelectedProduct(selectedProductService));
  };

  const handleSavedClick = () => {
    if (selectedProductService) {
      dispatch(
        actions.postDataForMappings.request({
          token,
          sku,
          wareId,
          code: isGlobalSearch ? mappingCodeForGlobalSearch : networkCode,
          postEffect: handleSuccessMapping,
          // @ts-ignore
          errorEffect: () => dispatch(actions.setSelectedProduct(null)),
        })
      );
    }
    setModalOpen(false);
  };

  const handleCancelClick = () => {
    setModalOpen(false);
    dispatch(actions.setSelectedProduct());
  };

  const handleModalOpen = () => {
    onSuggestionClose();
    setModalOpen(true);
  };
  return (
    <>
      <Styled.ProductsSearchContainer>
        <ClickAwayListener
          onClickAway={() => {
            onSuggestionClose();
          }}
        >
          <div className="search-bar-container">
            <div className="search-bar">
              <SearchBar
                value={value}
                placeholder={placeholder}
                setValue={setValue}
                onSearchClick={() => false}
              />
            </div>

            <Styled.ProductsItemMainContainer>
              {isSuggestionOpen && results && value !== '' ? (
                <>
                  {/* eslint-disable-nest-line */}
                  {results.length > 0 ? (
                    <Styled.ProductsSearchItemContainer>
                      {results?.map((el: ProductResponse) => (
                        <ProductCard
                          data={mapDataForProductInfo(el, attachedProduct)}
                          key={el.id}
                          value={value}
                          onClick={handleModalOpen}
                          selectedProductPharmacy={selectedProductPharmacy}
                          selectedProductService={selectedProductService}
                          code={
                            networkForContent?.code
                              ? networkForContent?.code
                              : storageNetworkForContent?.code
                          }
                        />
                      ))}
                    </Styled.ProductsSearchItemContainer>
                  ) : (
                    !results.length && (
                      <div className="search-no-results">
                        {t('OrderSearchModal.NO_RESULTS')}
                      </div>
                    )
                  )}
                </>
              ) : (
                loading && (
                  <div className="product-loading">
                    <CircularProgress size={25} />
                  </div>
                )
              )}
            </Styled.ProductsItemMainContainer>
          </div>
        </ClickAwayListener>
      </Styled.ProductsSearchContainer>
      {isModalOpen && type === MappingType.UNATTACHED && (
        <ProductConfirmationConnectModal
          onClose={handleCancelClick}
          dataPharmacy={selectedProductPharmacy}
          dataService={selectedProductService}
          onSaveClick={handleSavedClick}
        />
      )}
    </>
  );
};

export default ProductsSearch;
