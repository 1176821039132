import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  Typography,
  ClickAwayListener,
  Tooltip,
  TooltipProps,
  tooltipClasses,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import editIcon from '../../../../global/media/edit-pencil.svg';
import saveIcon from '../../../../global/media/save-discet-icon.svg';
import * as Styled from './InterPayReport.styles';
import { OrderPageState } from '../../types';
import { UserRole } from '../../../OrdersPage/types';
import { getFromLocalStorage } from '../../../../global/helpers';
import * as actionsOrder from '../../actions';

const convertStatus = (status: string) => {
  switch (status) {
    case 'order_closed':
      return 'Заказ закрыт';
    case 'wait_paid':
      return 'Выплата аптеке ожидается';
    case 'to_paid':
      return 'Итого Выплата аптеке ожидается';
    case 'refund_withholding':
      return 'Возврат удержан';
    case 'paid':
      return 'Выплата аптеке произведена';
    case 'wait_refund':
      return 'Возврат (на возмещение) оформлен';
    case 'refund_confirmed':
      return 'Возмещение от аптеки за возврат подтверждено Дарибаром';
    case 'to_refund':
      return 'Итого задолженность аптеки перед Daribar за возвраты (возмещения)';
    default:
      return '';
  }
};

interface InterpayPayouts {
  orderId: string;
  status: string;
  payoutDate: string;
  sum?: number;
  comment?: string;
  payoutInfo?: string;
  created_at?: string;
}

interface EditConfirmationProps {
  interpayPayouts: OrderPageState['interpayPayouts'];
  orderId: string;
  isFirst: boolean;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const InterPayReport = ({
  interpayPayouts,
  orderId,
  isFirst,
}: EditConfirmationProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const role = getFromLocalStorage('role');

  const [editRowIndex, setEditRowIndex] = useState<number | null>(null);
  const [editedRow, setEditedRow] = useState<InterpayPayouts | null>(null);
  const [waitingPayment, setWaitingPayment] = useState<boolean>(false);
  const [waitingRefund, setWaitingRefund] = useState<boolean>(false);
  const [initialStates, setInitialStates] = useState<InterpayPayouts>({
    orderId,
    status: interpayPayouts.some((el) => el.status === 'wait_refund')
      ? 'refund_confirmed'
      : 'paid',
    sum: 0,
    payoutDate: new Date().toISOString().split('T')[0],
    comment: '',
    payoutInfo: '',
  });

  useEffect(() => {
    const waitingPayment = !interpayPayouts.some((el) => el.status === 'paid');
    const waitingRefund = interpayPayouts.some(
      (el) => el.status === 'wait_refund'
    );
    setWaitingPayment(waitingPayment);
    setWaitingRefund(waitingRefund);

    const payoutDate = (() => {
      const toPaidDate = interpayPayouts.find(
        (el) => el.status === 'to_paid'
      )?.payout_date;
      if (waitingPayment && !waitingRefund) {
        return toPaidDate
          ? new Date(toPaidDate).toISOString().split('T')[0]
          : '';
      }
      return new Date().toISOString().split('T')[0];
    })();

    setInitialStates({
      orderId,
      status: interpayPayouts.some((el) => el.status === 'wait_refund')
        ? 'refund_confirmed'
        : 'paid',
      sum: 0,
      payoutDate,
      comment: '',
      payoutInfo: '',
    });
  }, [interpayPayouts]);

  const [open, setOpen] = React.useState(false);

  const handleEditClick = (index: number, row: any) => {
    const transformedRow: InterpayPayouts = {
      orderId: row.order_id,
      status: row.status,
      payoutDate: row.payout_date,
      comment: row.comment,
      payoutInfo: row.payout_info,
      created_at: row.created_at,
    };

    setEditRowIndex(index);
    setEditedRow(transformedRow);
  };

  const handleChangeSubmit = () => {
    if (editedRow) {
      const formattedRow = {
        orderId: editedRow.orderId,
        status: editedRow.status,
        payoutInfo: editedRow.payoutInfo,
        comment: editedRow.comment,
        createdAt: editedRow.created_at,
        ...(editedRow.status === 'paid' && {
          payoutDate: new Date(editedRow.payoutDate).toISOString(),
        }),
      };

      dispatch(
        actionsOrder.changeInterpayPayouts.request({
          orderId,
          status: formattedRow.status,
          payoutDate: formattedRow.payoutDate,
          comment: formattedRow.comment,
          payoutInfo: formattedRow.payoutInfo,
          createdAt: formattedRow.createdAt,
          postEffect: () =>
            dispatch(actionsOrder.getInterpayPayouts.request({ orderId })),
        })
      );
      setEditRowIndex(null);
    }
  };

  const handleInputChange = (field: keyof InterpayPayouts, value: any) => {
    if (editedRow) {
      setEditedRow({ ...editedRow, [field]: value });
    }
  };

  const handleInitialStateChange = (
    field: keyof InterpayPayouts,
    value: any
  ) => {
    setInitialStates((prev: any) => ({ ...prev, [field]: value }));
  };

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
      placement={isMobile ? 'left-start' : 'right-start'}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: isMobile ? 350 : 550,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  const handlePaidSubmit = () => {
    const formattedState = {
      ...initialStates,
      payoutDate: new Date(initialStates.payoutDate).toISOString(),
    };

    dispatch(
      actionsOrder.changeInterpayPayouts.request({
        orderId,
        status: formattedState.status,
        payoutDate: formattedState.payoutDate,
        comment: formattedState.comment,
        payoutInfo: formattedState.payoutInfo,
        sum: Number(formattedState.sum) || 0,
        postEffect: () =>
          dispatch(actionsOrder.getInterpayPayouts.request({ orderId })),
      })
    );
  };

  return (
    <Styled.InterPayContainer>
      <Styled.InterPayTop>
        <Typography variant="h6">
          {t(`OrderPage.${isFirst ? 'INTERPAY' : 'INTERPAY_REPORT'}`)}
        </Typography>

        <ClickAwayListener
          onClickAway={(event) => {
            const target = event.target as HTMLElement;
            if (target.closest('.tooltipBtn')) {
              return;
            }
            setOpen(false);
          }}
        >
          <div>
            <HtmlTooltip
              title={
                <>
                  <Typography fontWeight={700}>
                    {t('OrderPage.INTERPAY_FAQ_1')}
                  </Typography>
                  <Typography>{t('OrderPage.INTERPAY_ANSWER_1')}</Typography>
                  <Typography>{t('OrderPage.INTERPAY_ANSWER_2')}</Typography>
                  <Typography fontWeight={700}>
                    {t('OrderPage.INTERPAY_FAQ_2')}
                  </Typography>
                  <Typography>{t('OrderPage.INTERPAY_ANSWER_3')}</Typography>
                </>
              }
              open={open}
              onClose={() => setOpen(false)}
              disableHoverListener
              disableFocusListener
              disableTouchListener
            >
              <button
                type="button"
                className="tooltipBtn"
                onClick={() => setOpen(!open)}
              >
                i
              </button>
            </HtmlTooltip>
          </div>
        </ClickAwayListener>
      </Styled.InterPayTop>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 550, tableLayout: 'fixed' }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell width="230px">
                {t('OrderPage.INTERPAY_STATUS')}
              </StyledTableCell>
              <StyledTableCell width="170px" align="center">
                {t('OrderPage.INTERPAY_DATE')}
              </StyledTableCell>
              <StyledTableCell width="100px" align="center">
                {t('OrderPage.INTERPAY_SUM')}
              </StyledTableCell>
              <StyledTableCell>
                {t('OrderPage.INTERPAY_COMMENT')}
              </StyledTableCell>
              <StyledTableCell>
                {t('OrderPage.INTERPAY_PAYOUT_INFO')}
              </StyledTableCell>
              {role === UserRole.ADMIN && (
                <StyledTableCell width="100px">
                  {t('OrderPage.INTERPAY_ACTION')}
                </StyledTableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {interpayPayouts.map((row, index) => {
              const payoutDate =
                row.status === 'to_refund'
                  ? ''
                  : new Date(row.payout_date).toLocaleDateString();
              return (
                <TableRow
                  key={row.status}
                  sx={{
                    backgroundColor:
                      row.status === 'to_paid' || row.status === 'to_refund'
                        ? '#abd7f9'
                        : 'transparent',
                  }}
                >
                  <TableCell width="230px">
                    {convertStatus(row.status)}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    width="170px"
                    align="center"
                    padding="none"
                  >
                    {editRowIndex === index &&
                    row.status === 'paid' &&
                    role === UserRole.ADMIN ? (
                      <TextField
                        type="date"
                        value={
                          new Date(editedRow ? editedRow.payoutDate : '')
                            .toISOString()
                            .split('T')[0]
                        }
                        onChange={(e) =>
                          handleInputChange('payoutDate', e.target.value)
                        }
                        fullWidth
                      />
                    ) : (
                      payoutDate
                    )}
                  </TableCell>
                  <TableCell width="100px" align="center" padding="none">
                    {(row.status === 'wait_refund' ||
                      row.status === 'refund_withholding' ||
                      (row.status === 'to_refund' && row.sum > 0)) &&
                      '- '}
                    {row.sum || 0}
                  </TableCell>
                  <TableCell sx={{ height: 'fit-content', maxWidth: '200px' }}>
                    {editRowIndex === index && role === UserRole.ADMIN ? (
                      <TextField
                        value={editedRow?.comment || ''}
                        onChange={(e) =>
                          handleInputChange('comment', e.target.value)
                        }
                        variant="outlined"
                        fullWidth
                      />
                    ) : (
                      row.comment || ''
                    )}
                  </TableCell>
                  <TableCell sx={{ maxWidth: '200px' }}>
                    {editRowIndex === index &&
                    row.status === 'paid' &&
                    role === UserRole.ADMIN ? (
                      <TextField
                        value={editedRow?.payoutInfo || ''}
                        onChange={(e) =>
                          handleInputChange('payoutInfo', e.target.value)
                        }
                        variant="outlined"
                        fullWidth
                      />
                    ) : (
                      row.payout_info || ''
                    )}
                  </TableCell>
                  {role === UserRole.ADMIN && (
                    <TableCell
                      width="80px"
                      align="center"
                      sx={{ cursor: 'pointer' }}
                    >
                      {editRowIndex === index ? (
                        <IconButton onClick={handleChangeSubmit}>
                          <img
                            width={25}
                            height={25}
                            src={saveIcon}
                            alt="save"
                            className="save-icon"
                          />
                        </IconButton>
                      ) : (
                        <IconButton onClick={() => handleEditClick(index, row)}>
                          <img
                            src={editIcon}
                            alt="edit"
                            className="edit-icon"
                          />
                        </IconButton>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
            {role === UserRole.ADMIN && (waitingPayment || waitingRefund) && (
              <TableRow>
                <TableCell>
                  {waitingRefund
                    ? t('OrderPage.REFUND_ACCEPTED')
                    : t('OrderPage.PAYMENT_ACCEPTED')}
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  <TextField
                    type="date"
                    value={initialStates.payoutDate || ''}
                    onChange={(e) =>
                      handleInitialStateChange('payoutDate', e.target.value)
                    }
                    fullWidth
                  />
                </TableCell>
                <TableCell sx={{ height: 'fit-content' }}>
                  <TextField
                    value={initialStates.sum || ''}
                    onChange={(e) =>
                      handleInitialStateChange('sum', e.target.value)
                    }
                    variant="outlined"
                    fullWidth
                    type="number"
                  />
                </TableCell>
                <TableCell sx={{ height: 'fit-content' }}>
                  <TextField
                    value={initialStates.comment || ''}
                    onChange={(e) =>
                      handleInitialStateChange('comment', e.target.value)
                    }
                    variant="outlined"
                    fullWidth
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    value={initialStates.payoutInfo || ''}
                    onChange={(e) =>
                      handleInitialStateChange('payoutInfo', e.target.value)
                    }
                    variant="outlined"
                    fullWidth
                  />
                </TableCell>
                {role === UserRole.ADMIN && (
                  <TableCell align="center" sx={{ cursor: 'pointer' }}>
                    <IconButton onClick={handlePaidSubmit}>
                      <img
                        width={25}
                        height={25}
                        src={saveIcon}
                        alt="save"
                        className="save-icon"
                      />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Styled.InterPayContainer>
  );
};

export default InterPayReport;
