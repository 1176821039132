import styled from 'styled-components';
import { baseTheme } from '../../../../global/styles/theme';

export const Title = styled('div')`
  font-weight: 500;
  font-size: 20px;
  color: ${baseTheme.colors.primary};
  max-width: 400px;
  margin: 0 !important;
`;

export const Info = styled('div')`
  max-width: 400px;

  .tags {
    margin-top: 16px;
    display: flex;
    flex-direction: column;

    .tag {
      padding: 4px 8px;
      border-radius: 4px;
      font-weight: 600;
      width: fit-content;
    }
  }

  .product-name {
    margin-top: 16px;

    div {
      padding-top: 4px;
      font-size: 18px;
      font-weight: 700;
      text-decoration: underline;
      color: ${baseTheme.colors.mainBlue};
    }
  }
`;

export const ButtonsContainer = styled('div')`
  display: flex;
  justify-content: center;
  padding-top: 20px;

  .save-btn {
    margin-right: 8px;
  }
`;
