import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ClickAwayListener } from '@mui/material';
import * as actions from './actions';
import * as networkActions from '../NetworkPage/actions';
import * as Styled from './SourcePage.styles';
import { selectors } from './reducer';
import { selectors as networkSelectors } from '../NetworkPage/reducer';
import Loader from '../../components/Loader';
import SourceComponent from './components/SourceComponent';
import { filteredUsers } from './helpers';
import SourceBreadCrumbs from './components/SourceBreadCrumbs';
import UserTable from '../../components/UserTable';
import networksArrow from '../../global/media/networks-arrow.svg';
import TagsComponent from '../PharmacyTagsPage/components/TagsComponent';
import { TagsTypeOptions } from '../PharmacyTagsPage/types';

const SourcePage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const sourceCode = history.location.pathname.split('/')[2];
  const [newUser, addNewUser] = useState<boolean>(false);
  const [newUserManager, addNewUserManager] = useState<boolean>(false);

  const source = useSelector(selectors.sourceData);
  const editSource = useSelector(selectors.editSource);
  const sourceLoading = useSelector(selectors.sourceDLoading);
  const users = useSelector(selectors.usersData);
  const sourceTagsData = useSelector(selectors.sourceTagsData);

  const networks = useSelector(networkSelectors.networksData)?.map(
    (network) => {
      return {
        code: network.code,
        name: network.name,
      };
    }
  );
  const [networksOpen, setNetworksOpen] = useState<boolean>(false);
  const [networkForSourceName, setNetworkForSourceName] = useState<string>(
    source ? source?.networkName : ''
  );
  const [networkForSourceCode, setNetworkForSourceCode] = useState<string>(
    source ? source?.networkCode : ''
  );

  useEffect(() => {
    dispatch(actions.getSourceTags.request({ tagsType: 'source' }));
  }, []);

  useEffect(() => {
    dispatch(actions.getSource.request({ sourceCode }));
    dispatch(networkActions.getAllNetwork.request());
  }, [dispatch, sourceCode]);

  useEffect(() => {
    if (source) {
      setNetworkForSourceName(source.networkName);
      setNetworkForSourceCode(source.networkCode);
      dispatch(
        actions.getUsersOfSource.request({
          role: 'pharmacist',
          networkCode: source.networkCode,
          sourceCode,
        })
      );
    }
  }, [source]);

  const handleToNetwork = (code: string) => {
    history.push(`/networks/${code}`);
  };

  const handleAddNewUser = () => {
    addNewUser(true);
  };

  const handleNoNewUser = () => {
    addNewUser(false);
  };
  const handleNewUserManager = () => {
    addNewUserManager(!newUserManager);
  };

  useEffect(() => {
    dispatch(actions.getSourceTags.request({ tagsType: 'source' }));
  }, []);

  const handleCLickNetwork = (network: { code: string; name: string }) => {
    setNetworkForSourceCode(network.code);
    setNetworkForSourceName(network.name);
    setNetworksOpen(false);
  };

  return (
    <>
      {sourceLoading && <Loader />}
      {source && (
        <>
          <SourceBreadCrumbs editActive={false} />
          <Styled.SourcePageContainer>
            {!editSource ? (
              <Styled.NetworksBlock>
                <div className="network">{t('SourcePage.NETWORK')}</div>
                <div
                  className="network-name"
                  onClick={() => handleToNetwork(source?.networkCode)}
                >
                  {source?.networkName}
                </div>
              </Styled.NetworksBlock>
            ) : (
              <Styled.SelectNetwork>
                <div
                  className="selected-network"
                  onClick={() => setNetworksOpen(!networksOpen)}
                >
                  {networkForSourceName}
                  <img src={networksArrow} alt="networks-arrow" />
                </div>
                {networksOpen && (
                  <ClickAwayListener onClickAway={() => setNetworksOpen(false)}>
                    <div className="networks-options">
                      {networks?.map((network) => (
                        <div onClick={() => handleCLickNetwork(network)}>
                          {network.name}
                        </div>
                      ))}
                    </div>
                  </ClickAwayListener>
                )}
              </Styled.SelectNetwork>
            )}

            <Styled.SourceBlock>
              <SourceComponent
                data={source}
                networkCode={networkForSourceCode}
              />
            </Styled.SourceBlock>
            <>
              <div className="roles">{t('SourcePage.ROLE')}</div>
              <>
                {(users && filteredUsers(users).pharmacist?.length !== 0) ||
                newUser ? (
                  <Styled.UsersBlock>
                    <UserTable
                      data={filteredUsers(users).pharmacist}
                      newUser={newUser}
                      networkCode={source?.networkCode}
                      sourceCode={sourceCode}
                      onAddNewUser={handleNoNewUser}
                    />
                  </Styled.UsersBlock>
                ) : (
                  <div className="role">{t('SourcePage.ROLES.PHARMACIST')}</div>
                )}
                {!newUser ? (
                  <Styled.Button onClick={handleAddNewUser}>
                    {t('SourcePage.ADD')}
                  </Styled.Button>
                ) : (
                  <div />
                )}
              </>
              <>
                {(users &&
                  filteredUsers(users).pharmacyManager?.length !== 0) ||
                newUserManager ? (
                  <Styled.UsersBlock>
                    <UserTable
                      data={filteredUsers(users).pharmacyManager}
                      newUserManager={newUserManager}
                      networkCode={source?.networkCode}
                      sourceCode={sourceCode}
                      onAddNewUser={handleNewUserManager}
                    />
                  </Styled.UsersBlock>
                ) : (
                  <div className="role">
                    {t('SourcePage.ROLES.PHARMACY_MANAGER')}
                  </div>
                )}
                {!newUserManager ? (
                  <Styled.Button onClick={handleNewUserManager}>
                    {t('SourcePage.ADD')}
                  </Styled.Button>
                ) : (
                  <div />
                )}
              </>
            </>
            {sourceTagsData && (
              <Styled.TagsBlock>
                <TagsComponent
                  isAllTagsPage={false}
                  currentTags={source.sourceTags}
                  tagsType={TagsTypeOptions.source}
                  sourceTagsData={sourceTagsData}
                  code={sourceCode}
                />
              </Styled.TagsBlock>
            )}
          </Styled.SourcePageContainer>
        </>
      )}
    </>
  );
};

export default SourcePage;
