import styled from 'styled-components';
import { baseTheme } from '../../../../global/styles/theme';

export const MainContainer = styled('div')`
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }

  .report-btns-container {
    display: flex;
    width: 550px;
    justify-content: space-between;
    @media (max-width: 768px) {
      height: 100px;
      width: 50%;
      margin: 0 auto;
      flex-direction: column;
    }
  }
`;

export const NetworkBlock = styled('div')`
  display: flex;
  margin-bottom: 20px;
  margin-right: 55px;
  position: relative;

  .selected-network {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    word-break: break-word;
    align-items: center;
    padding: 10px 12px;
    width: 240px;
    font-weight: 400;
    font-size: 16px;
    color: ${baseTheme.colors.secondary};
    border: 1px solid ${baseTheme.colors.stroking};
    border-radius: 8px;
  }
`;

export const PickersBlock = styled('div')`
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Picker = styled('div')`
  display: flex;
  flex-direction: column;
  margin-right: 55px;

  .label {
    color: ${baseTheme.colors.secondary};
    font-weight: 500;
    padding-bottom: 4px;
  }

  .day {
    min-height: 30px;
    padding: 4px;
    border: 1px solid ${baseTheme.colors.secondary};
    border-radius: 8px;
    margin: 8px 0;
  }
  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

export const GenerateButton = styled('div')`
  margin-top: 73px;
  position: relative;

  label {
    color: ${baseTheme.colors.secondary} !important;
    margin-bottom: 16px;
    font-weight: 500 !important;
  }

  .error-tooltip {
    background-color: #f6f9ff;
    filter: drop-shadow(0px 1px 8px rgba(0, 58, 136, 0.15));
    border-radius: 4px;
    color: ${baseTheme.colors.primary};
    font-size: 16px;
    font-weight: 400;
    padding: 8px 16px;
    position: absolute;
    top: -45px;
    white-space: nowrap;
    @media (max-width: 768px) {
      width: 50vw;
      white-space: normal;
      word-wrap: break-word;
      height: auto;
      top: -95px;
    }
  }
`;

export const TogglesBlock = styled('div')`
  margin-top: 16px;

  div:first-child {
    margin-bottom: 8px;
  }
`;
