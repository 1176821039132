import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { SettingsService } from './service';
import * as actionsNotification from '../InitComponent/actions';
import {
  NOTIFICATION_CHANGES_SAVED,
  NOTIFICATION_CHANGES_NOT_SAVED,
} from '../../global/constants';

interface SettingsParam {
  type: string;
  payload: { delivery_enabled: boolean; postEffect: any };
}

interface GetConfigResponse {
  result: string | null;
  status: string;
}

export function* getConfigSaga() {
  try {
    const response: GetConfigResponse = yield call(SettingsService.getConfig);
    yield put(
      actions.getConfig.success({
        data: response.result,
      })
    );
  } catch (error) {
    yield put(actions.getConfig.error(error));
  }
}

export function* setConfigSaga({ payload }: SettingsParam) {
  try {
    // @ts-ignore
    const response: any = yield call(SettingsService.setConfig, payload);
    yield put(actions.setConfig.success(response.result));
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_SAVED,
        canBeClosed: true,
        type: 'success',
      })
    );
    if (payload.postEffect) {
      payload.postEffect();
    }
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.setConfig.error(error));
  }
}

export function* settingPageWatcherSaga() {
  yield all([takeLatest(actions.getConfig.REQUEST, getConfigSaga)]);
  yield all([takeLatest(actions.setConfig.REQUEST, setConfigSaga)]);
}
