import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import React, {
  MouseEvent,
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { isMobile } from 'react-device-detect';
import * as Styled from './OrdersTableRow.styles';
import {
  DeliveryType,
  OrderTableData,
  PaymentType,
  UserRole,
} from '../../../../types';
import OrderStatusBlock from '../../../../../../components/OrderStatusBlock';
import { StatusRunTimer } from '../../../../../OrderPage/types';
import {
  convertSeconds,
  getFromLocalStorage,
} from '../../../../../../global/helpers';
import success from '../../../../../../global/media/Vector (2).svg';
import CustomTextarea from '../../../../../../components/CustomTextarea';
import editIcon from '../../../../../../global/media/edit-pencil.svg';
import saveIcon from '../../../../../../global/media/save-discet-icon.svg';
import copyIcon from '../../../../../../global/media/copy-icon.png';
import Notification from '../../../../../../components/NotificationsList/components/Notification';
import { StatusType } from '../../../../../../global/types';

function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef(callback);
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  useEffect(() => {
    if (delay !== null) {
      const tick = () => savedCallback.current();
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

interface OrdersTableRowProps {
  data: OrderTableData;
  dataOrders?: OrderTableData[] | null;
  onRowClick: (data: OrderTableData) => void;
  dataByTab?: OrderTableData[] | null;
  // style?: React.CSSProperties;
  // as?: React.ElementType;
}

const OrdersTableRow: React.FC<OrdersTableRowProps> = ({
  data,
  onRowClick,
  dataOrders,
  dataByTab,
  // style,
  // as: Component = Styled.OrdersTableRow,
}) => {
  const { t } = useTranslation();
  const userRole = getFromLocalStorage('role');
  const [operatorComment, setOperatorComment] = useState<string>('');
  const [directorComment, setDirectorComment] = useState<string>('');
  const [editCommentActive, setEditCommentActive] = useState<boolean>(false);
  const [isBannerVisible, setIsBannerVisible] = useState<boolean>(false);
  const [operatorErrorTooltip, setOperatorErrorTooltip] =
    useState<boolean>(false);
  const [directorErrorTooltip, setDirectorErrorTooltip] =
    useState<boolean>(false);

  useEffect(() => {
    if (editCommentActive) {
      if (
        userRole === UserRole.OPERATOR ||
        userRole === UserRole.HEAD_OPERATOR
      ) {
        setDirectorErrorTooltip(true);
      } else {
        setOperatorErrorTooltip(true);
      }
    } else {
      setDirectorErrorTooltip(false);
      setOperatorErrorTooltip(false);
    }
  }, [userRole, editCommentActive]);

  const status =
    userRole === UserRole.OPERATOR ||
    userRole === UserRole.ADMIN ||
    userRole === UserRole.HEAD_OPERATOR
      ? data.status
      : data.statusPharmacy;
  const orderTimer =
    userRole === UserRole.OPERATOR ||
    userRole === UserRole.ADMIN ||
    userRole === UserRole.HEAD_OPERATOR
      ? data.timer?.remainTimer
      : data.pharmacyTimer?.remainTimer;
  const orderStatusTimer =
    userRole === UserRole.OPERATOR ||
    userRole === UserRole.ADMIN ||
    userRole === UserRole.HEAD_OPERATOR
      ? data.timer?.statusTimer
      : data.pharmacyTimer?.statusTimer;

  const [seconds, setSeconds] = useState<number>(orderTimer || 1200);
  const [secondsPharmacy, setSecondsPharmacy] = useState<number>(
    data.pharmacyTimer?.remainTimer || 1200
  );

  useEffect(() => {
    if (orderTimer) setSeconds(orderTimer);
    if (data.pharmacyTimer?.remainTimer)
      setSecondsPharmacy(data.pharmacyTimer?.remainTimer);
  }, [orderTimer, data.pharmacyTimer?.remainTimer]);

  useInterval(
    () => setSeconds((prev) => (prev > 0 ? prev - 1 : 0)),
    orderStatusTimer === StatusRunTimer.RUNNING && seconds > 0 ? 1000 : null
  );
  useInterval(
    () => setSecondsPharmacy((prev) => (prev > 0 ? prev - 1 : 0)),
    data.pharmacyTimer?.statusTimer === StatusRunTimer.RUNNING &&
      secondsPharmacy > 0
      ? 1000
      : null
  );

  const formattedSeconds = useMemo(
    () => convertSeconds(Math.abs(seconds)),
    [seconds]
  );
  const formattedSecondsPharmacy = useMemo(
    () => convertSeconds(Math.abs(secondsPharmacy)),
    [secondsPharmacy]
  );

  const getTimerColor = useCallback((timerValue: number, sec: number) => {
    if (sec > 240 && timerValue > 0) return 'timer-green';
    if (sec <= 240 && timerValue > 0) return 'timer-orange';
    if (sec <= 0 || timerValue <= 0) return 'timer-red';
    return '';
  }, []);

  const getTimer = useCallback(
    (sec: number, pharmacy?: boolean) => {
      const timerValue = pharmacy ? secondsPharmacy : seconds;
      if (
        Math.abs(sec) / 3600 > 48 ||
        status === StatusType.CANCELED ||
        status === StatusType.COMPLETED
      ) {
        return null;
      }
      let content: React.ReactNode = null;
      if (
        (!pharmacy && !formattedSeconds) ||
        (pharmacy && !formattedSecondsPharmacy)
      ) {
        content = <CircularProgress size={10} />;
      } else if (!pharmacy) {
        content = timerValue <= 0 ? `-${formattedSeconds}` : formattedSeconds;
      } else {
        content =
          timerValue <= 0
            ? `-${formattedSecondsPharmacy}`
            : formattedSecondsPharmacy;
      }
      return (
        <div className={`order-timer ${getTimerColor(timerValue, sec)}`}>
          {content}
        </div>
      );
    },
    [
      seconds,
      secondsPharmacy,
      formattedSeconds,
      formattedSecondsPharmacy,
      status,
      getTimerColor,
    ]
  );

  const getErrorStatus = useCallback(() => {
    if (data && (data.paymentStatus || data.deliveryPaymentStatus)) {
      return (
        data.paymentStatus === 'refund_failed' ||
        data.paymentStatus === 'failed' ||
        data.paymentStatus === 'kaspi_not_found' ||
        data.deliveryPaymentStatus === 'refund_failed' ||
        data.deliveryPaymentStatus === 'failed' ||
        data.deliveryPaymentStatus === 'kaspi_not_found'
      );
    }
    return false;
  }, [data]);

  const handleSaveComment = useCallback(() => {
    alert(
      `operator comment - ${operatorComment}, director comment - ${directorComment}`
    );
    setEditCommentActive(false);
  }, [operatorComment, directorComment]);

  const deliveryTypeTitlesStrategy = useMemo(
    () => ({
      [DeliveryType.DELIVERY]: t(
        'OrdersPage.OrdersTable.DELIVERY_STATUS.DELIVERY'
      ),
      [DeliveryType.SELF]: t('OrdersPage.OrdersTable.DELIVERY_STATUS.SELF'),
    }),
    [t]
  );

  const paymentTypeTitlesStrategy = useMemo(
    () => ({
      [PaymentType.IN_PLACE]: t('OrdersPage.OrdersTable.PAYMENT_STATUS.SOURCE'),
      [PaymentType.KASPI_PAY]: t(
        'OrdersPage.OrdersTable.PAYMENT_STATUS.DARIBAR'
      ),
      [PaymentType.INTERPAY]: t(
        'OrdersPage.OrdersTable.PAYMENT_STATUS.INTERPAY'
      ),
      [PaymentType.MB]: t('OrdersPage.OrdersTable.PAYMENT_STATUS.INTERPAY'),
      [PaymentType.MB_WALLET_FACTORY]: t(
        'OrdersPage.OrdersTable.PAYMENT_STATUS.INTERPAY'
      ),
      [PaymentType.MB_PAYMENT_CARDS]: t(
        'OrdersPage.OrdersTable.PAYMENT_STATUS.INTERPAY'
      ),
      [PaymentType.MB_APPLE_PAY]: t(
        'OrdersPage.OrdersTable.PAYMENT_STATUS.INTERPAY'
      ),
      [PaymentType.MB_GOOGLE_PAY]: t(
        'OrdersPage.OrdersTable.PAYMENT_STATUS.INTERPAY'
      ),
    }),
    [t]
  );

  const paymentTypeTitlesOperator = useMemo(
    () => ({
      [PaymentType.IN_PLACE]: t(
        'OrdersPage.OrdersTable.PAYMENT_STATUS_OPERATOR.SOURCE'
      ),
      [PaymentType.KASPI_PAY]: t(
        'OrdersPage.OrdersTable.PAYMENT_STATUS_OPERATOR.DARIBAR'
      ),
      [PaymentType.INTERPAY]: t(
        'OrdersPage.OrdersTable.PAYMENT_STATUS_OPERATOR.INTERPAY'
      ),
      [PaymentType.MB]: t(
        'OrdersPage.OrdersTable.PAYMENT_STATUS_OPERATOR.MB_TITLE'
      ),
      [PaymentType.MB_WALLET_FACTORY]: t(
        'OrdersPage.OrdersTable.PAYMENT_STATUS_OPERATOR.MB_WALLET_FACTORY_TITLE'
      ),
      [PaymentType.MB_PAYMENT_CARDS]: t(
        'OrdersPage.OrdersTable.PAYMENT_STATUS_OPERATOR.MB_PAYMENT_CARDS_TITLE'
      ),
      [PaymentType.MB_APPLE_PAY]: t(
        'OrdersPage.OrdersTable.PAYMENT_STATUS_OPERATOR.MB_APPLE_PAY_TITLE'
      ),
      [PaymentType.MB_GOOGLE_PAY]: t(
        'OrdersPage.OrdersTable.PAYMENT_STATUS_OPERATOR.MB_GOOGLE_PAY_TITLE'
      ),
    }),
    [t]
  );

  const dataAlign = useMemo(() => {
    return userRole === UserRole.ADMIN ||
      userRole === UserRole.OPERATOR ||
      userRole === UserRole.HEAD_OPERATOR
      ? 'vertical-align-top'
      : '';
  }, [userRole]);

  const tableCellCanceled = useMemo(() => {
    return status === 'canceled' &&
      (userRole === UserRole.ADMIN ||
        userRole === UserRole.OPERATOR ||
        userRole === UserRole.HEAD_OPERATOR)
      ? 'table-cell-canceled'
      : '';
  }, [status, userRole]);

  const handleRowClick = useCallback(
    (e: MouseEvent<HTMLTableRowElement>) => {
      if ((e.target as HTMLElement).tagName !== 'IMG') {
        onRowClick(data);
      }
    },
    [onRowClick, data]
  );

  const handleCopyText = useCallback((text: string) => {
    navigator.clipboard.writeText(text);
    setIsBannerVisible(true);
  }, []);

  useEffect(() => {
    if (isBannerVisible) {
      const timeout = setTimeout(() => setIsBannerVisible(false), 500);
      return () => clearTimeout(timeout);
    }
  }, [isBannerVisible]);

  const isDeliveryKaspi =
    data.deliveryType === DeliveryType.DELIVERY &&
    data.paymentType === PaymentType.KASPI_PAY;
  const isSelfKaspi =
    data.deliveryType === DeliveryType.SELF &&
    data.paymentType === PaymentType.KASPI_PAY;

  let rowId = 'self';
  if (isDeliveryKaspi) {
    rowId = 'delivery_kaspi';
  } else if (isSelfKaspi) {
    rowId = 'self_kaspi';
  }

  const amountClassName = useMemo(() => {
    if (
      userRole === UserRole.OPERATOR ||
      userRole === UserRole.ADMIN ||
      userRole === UserRole.HEAD_OPERATOR
    ) {
      if (data.promoCode && data.promoCode.discount_target !== 'delivery') {
        return 'table-cell__amount-discount';
      }
      return '';
    }
    return '';
  }, [userRole, data.promoCode]);

  return (
    <>
      <Styled.OrdersTableRow
        id={rowId}
        onClick={handleRowClick}
        // style={style}
      >
        <td
          className={`table-cell table-cell__order-timer${
            userRole === UserRole.MANAGER ? '-manager' : ''
          }${
            userRole === UserRole.PHARMACIST ? '-pharmacist' : ''
          } ${dataAlign} ${tableCellCanceled}`}
        >
          {orderTimer ? getTimer(seconds) : <div className="order-timer" />}
          {(userRole === UserRole.ADMIN ||
            userRole === UserRole.OPERATOR ||
            userRole === UserRole.HEAD_OPERATOR) &&
            data.pharmacyTimer &&
            data.statusPharmacy &&
            !data.closed && (
              <>
                <div className="in-pharmacy">
                  {t('OrdersPage.OrdersTable.IN_PHARMACY_STATUS')}
                </div>
                {orderTimer && getTimer(secondsPharmacy, true)}
              </>
            )}
        </td>
        <td
          className={`table-cell table-cell__order-number${
            userRole === UserRole.MANAGER ? '-manager' : ''
          }${
            userRole === UserRole.PHARMACIST ? '-pharmacist' : ''
          } ${dataAlign} ${tableCellCanceled}`}
        >
          <div className="orderNumber-block">
            <div className={getErrorStatus() ? 'item-yellow' : ''}>
              {data.orderNumber}
            </div>
            {(userRole === UserRole.ADMIN ||
              userRole === UserRole.OPERATOR ||
              userRole === UserRole.HEAD_OPERATOR) && (
              <div onClick={() => handleCopyText(data.orderNumber)}>
                <img src={copyIcon} alt="copy icon" />
              </div>
            )}
          </div>
        </td>
        {!isMobile && (
          <>
            <td
              className={`table-cell table-cell__created${
                userRole === UserRole.MANAGER ? '-manager' : ''
              }${
                userRole === UserRole.PHARMACIST ? '-pharmacist' : ''
              } ${dataAlign} ${tableCellCanceled}`}
            >
              {data.createdAt}
            </td>
            {(userRole === UserRole.OPERATOR ||
              userRole === UserRole.ADMIN ||
              userRole === UserRole.HEAD_OPERATOR) && (
              <td
                className={`table-cell ${dataAlign} ${tableCellCanceled} table-cell-source`}
              >
                {data.userSource}
              </td>
            )}
            {(userRole === UserRole.OPERATOR ||
              userRole === UserRole.ADMIN ||
              userRole === UserRole.HEAD_OPERATOR) && (
              <td
                className={`table-cell phoneNumber-cell ${dataAlign} ${tableCellCanceled}`}
              >
                <div className="phoneNumber-block">
                  <div>{data.phone}</div>
                  <div onClick={() => handleCopyText(data.phone)}>
                    <img src={copyIcon} alt="copy icon" />
                  </div>
                </div>
              </td>
            )}
            {(userRole === UserRole.OPERATOR ||
              userRole === UserRole.ADMIN ||
              userRole === UserRole.HEAD_OPERATOR ||
              userRole === UserRole.PHARMACY_MANAGER ||
              userRole === UserRole.MANAGER) && (
              <td
                className={`table-cell ${
                  userRole === UserRole.MANAGER ||
                  userRole === UserRole.PHARMACY_MANAGER
                    ? 'table-cell__source'
                    : 'table-cell-pharmacy'
                } ${dataAlign}`}
              >
                {data.sourceName}
              </td>
            )}
            {(userRole === UserRole.OPERATOR ||
              userRole === UserRole.ADMIN ||
              userRole === UserRole.HEAD_OPERATOR) && (
              <td
                className={`table-cell table-cell-collector ${dataAlign} ${tableCellCanceled}`}
              >
                {data.collector.phone ? (
                  <>
                    {data.collector.name} <br />+{data.collector.phone}
                  </>
                ) : (
                  '-'
                )}
              </td>
            )}
            <td
              className={`table-cell ${dataAlign} ${tableCellCanceled} table-cell-delivery${
                userRole === UserRole.MANAGER ? '-manager' : ''
              }${userRole === UserRole.PHARMACIST ? '-pharmacist' : ''}`}
            >
              {deliveryTypeTitlesStrategy[data.deliveryType]}
            </td>
          </>
        )}
        {(userRole === UserRole.OPERATOR ||
          userRole === UserRole.ADMIN ||
          userRole === UserRole.HEAD_OPERATOR) && (
          <td
            className={`table-cell table-cell-delivery-status ${dataAlign} ${tableCellCanceled}`}
          >
            <OrderStatusBlock
              status={data.statusDelivery}
              statusName={data.statusNameDelivery}
              statusColor={data.statusColorDelivery}
              blink={
                (data.status === 'in_delivery' &&
                  data.statusDelivery === 'delivered_finish') ||
                (data.status === 'in_delivery' &&
                  data.statusDelivery === 'returning')
              }
            />
          </td>
        )}
        <td
          className={`table-cell table-cell-order-status${
            userRole === UserRole.MANAGER ? '-manager' : ''
          }${
            userRole === UserRole.PHARMACIST ? '-pharmacist' : ''
          } ${dataAlign} ${tableCellCanceled}`}
        >
          <OrderStatusBlock
            status={
              userRole === UserRole.ADMIN ||
              userRole === UserRole.OPERATOR ||
              userRole === UserRole.HEAD_OPERATOR
                ? data.status
                : data.statusPharmacy
            }
            statusName={
              userRole === UserRole.OPERATOR ||
              userRole === UserRole.ADMIN ||
              userRole === UserRole.HEAD_OPERATOR
                ? data.statusName
                : data.statusNamePharmacy
            }
            statusColor={
              userRole === UserRole.OPERATOR ||
              userRole === UserRole.ADMIN ||
              userRole === UserRole.HEAD_OPERATOR
                ? data.statusColor
                : data.statusColorPharmacy
            }
          />
          {(userRole === UserRole.ADMIN ||
            userRole === UserRole.OPERATOR ||
            userRole === UserRole.HEAD_OPERATOR) &&
            data.statusPharmacy &&
            !data.closed && (
              <>
                <div className="in-pharmacy">
                  {t('OrdersPage.OrdersTable.IN_PHARMACY_STATUS')}
                </div>
                <OrderStatusBlock
                  status={data.statusPharmacy}
                  statusName={data.statusNamePharmacy}
                  statusColor={data.statusColorPharmacy}
                />
              </>
            )}
        </td>
        {!isMobile &&
          (userRole === UserRole.PHARMACIST ||
            userRole === UserRole.MANAGER ||
            userRole === UserRole.PHARMACY_MANAGER) && (
            <td
              className={`table-cell table-cell__payment${
                userRole === UserRole.MANAGER ? '-manager' : ''
              }${
                userRole === UserRole.PHARMACIST ? '-pharmacist' : ''
              } ${dataAlign} ${tableCellCanceled}`}
            >
              {paymentTypeTitlesStrategy[data.paymentType]}
            </td>
          )}
        <td
          className={`table-cell table-cell__payment-status${
            userRole === UserRole.MANAGER ? '-manager' : ''
          }${
            userRole === UserRole.PHARMACIST ? '-pharmacist' : ''
          } ${dataAlign} ${tableCellCanceled}`}
        >
          {(userRole === UserRole.OPERATOR ||
            userRole === UserRole.ADMIN ||
            userRole === UserRole.HEAD_OPERATOR) && (
            <span className="operator-payment-type">
              {paymentTypeTitlesOperator[data.paymentType]}
              <br />
              <br />
            </span>
          )}
          {data.paymentType === PaymentType.IN_PLACE && '-'}
          {data.paymentType !== PaymentType.IN_PLACE &&
            (data.paid ? (
              <div className="payment-true-block">
                <div>
                  <img className="success" src={success} alt="success" />
                  {t('OrdersPage.OrdersTable.PAYMENT.TRUE')}
                </div>
                <div className="was_paid_at">{data.wasPaidAt}</div>
              </div>
            ) : (
              t('OrdersPage.OrdersTable.PAYMENT.FALSE')
            ))}
        </td>
        {!isMobile && (
          <>
            <td
              className={`table-cell table-cell__amount${
                userRole === UserRole.MANAGER ? '-manager' : ''
              }${
                userRole === UserRole.PHARMACIST ? '-pharmacist' : ''
              } ${dataAlign} ${tableCellCanceled}`}
            >
              <div className={amountClassName}>{data.amount}</div>
              {(userRole === UserRole.OPERATOR ||
                userRole === UserRole.ADMIN ||
                userRole === UserRole.HEAD_OPERATOR) &&
                data.promoCode &&
                data.promoCode.discount_target !== 'delivery' && (
                  <>
                    <div>
                      - {data.promoCode.value}{' '}
                      {data.promoCode?.discount_type === 'money' ? '₸' : '%'}
                    </div>
                    <div className="table-cell__amount-accent">
                      {data.amountWithDiscount}
                    </div>
                  </>
                )}
            </td>
            {data?.source.includes('food') &&
              dataByTab?.every((order: OrderTableData) =>
                order.source.includes('food')
              ) && (
                <td className="table-cell">
                  <div className="table-cell__comment">{data.comment}</div>
                </td>
              )}
          </>
        )}
      </Styled.OrdersTableRow>
      {status === StatusType.CANCELED &&
        (userRole === UserRole.OPERATOR ||
          userRole === UserRole.ADMIN ||
          userRole === UserRole.HEAD_OPERATOR) && (
          <>
            <Styled.OrderCancelTableRow>
              <div className="comment-area">
                {operatorErrorTooltip && (
                  <div className="error-tooltip">
                    {t('OrdersPage.OrdersTable.ERROR_TOOLTIP')}
                  </div>
                )}
                <CustomTextarea
                  onChange={setOperatorComment}
                  value={operatorComment}
                  placeholder={t(
                    'OrdersPage.OrdersTable.CANCELED_AREA_PLACEHOLDER.OPERATOR_COMMENT'
                  )}
                  disabled={!editCommentActive || operatorErrorTooltip}
                />
              </div>
              <div className="comment-area">
                {directorErrorTooltip && (
                  <div className="error-tooltip">
                    {t('OrdersPage.OrdersTable.ERROR_TOOLTIP')}
                  </div>
                )}
                <CustomTextarea
                  onChange={setDirectorComment}
                  value={directorComment}
                  placeholder={t(
                    'OrdersPage.OrdersTable.CANCELED_AREA_PLACEHOLDER.DIRECTOR_COMMENT'
                  )}
                  disabled={!editCommentActive || directorErrorTooltip}
                />
                <Styled.IconsContainer>
                  {!editCommentActive ? (
                    <div onClick={() => setEditCommentActive(true)}>
                      <img src={editIcon} alt="edit icon" />
                    </div>
                  ) : (
                    <div onClick={handleSaveComment}>
                      <img src={saveIcon} alt="save icon" />
                    </div>
                  )}
                </Styled.IconsContainer>
              </div>
            </Styled.OrderCancelTableRow>
            <Styled.OrderUserCancelMassage>
              <div className="user_cancel_msg">
                {t(
                  'OrdersPage.OrdersTable.CANCELED_AREA_PLACEHOLDER.USER_COMMENT'
                )}
              </div>
              <div>{data.userCancelMessage}</div>
            </Styled.OrderUserCancelMassage>
          </>
        )}
      {isBannerVisible && (
        <Styled.NotificationWrapper>
          <Notification
            onClose={() => setIsBannerVisible(false)}
            id={0}
            type="success"
            content={t('OrdersPage.COPY_SUCCESS')}
            canBeClosed
          />
        </Styled.NotificationWrapper>
      )}
    </>
  );
};

export default React.memo(OrdersTableRow);
