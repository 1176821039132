export const AUTH = 'AUTH';
export const GET_VALIDATION_CODE = 'GET_VALIDATION_CODE';
export const LOGOUT = 'LOGOUT';
export const LOGIN_CLEAR_ERROR = 'LOGIN_CLEAR_ERROR';
export const GET_USER_INFO = 'GET_USER_INFO';

export const INITIAL_AUTHORIZATION_MODAL_FORM_STATE = {
  phone: '+7',
  agreementAccepted: false,
};
