import axios from 'axios';
import { getFromLocalStorage } from '../../global/helpers';

export class EditService {
  static API_SEARCH_BASE_ULR = `${process.env.REACT_APP_SEARCH_URL}/rest/default/V1/products?`;

  static API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/api/v1/`;

  static role = getFromLocalStorage('role');

  static getProducts = async (searchValue: string) => {
    const response = await axios({
      method: 'get',
      url: `${EditService.API_SEARCH_BASE_ULR}searchCriteria[page_size]=50&searchCriteria[filter_groups][0][filters][0][field]=name&searchCriteria[filter_groups][0][filters][0][value]=%25${searchValue}%25&searchCriteria[filter_groups][0][filters][0][condition_type]=like`,
    });

    return response.data;
  };

  static postDataForMappings = async (
    token: string,
    sku: string,
    wareId: string,
    code: string
  ) => {
    const response = await axios({
      method: 'put',
      url: `${EditService.API_BASE_URL}mappings`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: JSON.stringify({
        sku,
        ware_id: wareId,
        mapping_code: code,
      }),
    });
    return response.data;
  };

  static deleteMappings = async (
    token: string,
    wareId: string,
    code: string
  ) => {
    const response = await axios({
      method: 'put',
      url: `${EditService.API_BASE_URL}mappings/unmap`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: JSON.stringify({
        ware_id: wareId,
        mapping_code: code,
      }),
    });
    return response.data;
  };

  static blockProduct = async (token: string, wareId: string, code: string) => {
    const response = await axios({
      method: 'put',
      url: `${EditService.API_BASE_URL}mappings/block`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: JSON.stringify({
        ware_id: wareId,
        mapping_code: code,
      }),
    });
    return response.data;
  };

  static unblockProduct = async (
    token: string,
    wareId: string,
    code: string
  ) => {
    const response = await axios({
      method: 'put',
      url: `${EditService.API_BASE_URL}mappings/unblock`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: JSON.stringify({
        ware_id: wareId,
        mapping_code: code,
      }),
    });
    return response.data;
  };

  static getProductBySku = async (sku: string) => {
    const response = await axios({
      method: 'get',
      url: `${EditService.API_SEARCH_BASE_ULR}searchCriteria[filter_groups][0][filters][0][field]=sku&searchCriteria[filter_groups][0][filters][0][value]=${sku}`,
    });
    return response.data;
  };
}
