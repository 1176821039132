import styled from 'styled-components';
import { baseTheme } from '../../../../../../global/styles/theme';

export const SetCreatedTagModalContainer = styled('div')`
  width: 500px;
  margin: 0 -30px 20px -30px;

  .title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .meta-input-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 16px;
    width: 100%;
    height: 48px;
    border: 1px solid ${baseTheme.colors.stroking};
    border-radius: 8px;

    input {
      width: 100%;
      color: ${baseTheme.colors.secondary};
      font-size: 16px;
      -webkit-appearance: none;
      border: none;
      padding: 0;
      background-color: inherit;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: ${baseTheme.colors.tertiary};
      }
    }
  }
`;

export const SetPharmacyTagBtn = styled('div')`
  margin-top: auto;
  padding: 8px 20px;
  border-radius: 8px;
  margin-top: 30px;
  background-color: ${baseTheme.colors.focus};
  cursor: pointer;
  color: ${baseTheme.colors.white};
`;
