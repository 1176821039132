import axios from 'axios';
import { PharmacyTag } from './types';

export class SourceService {
  static API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/api/v1/`;

  static getSource = async (sourceCode: string) => {
    const response = await axios({
      method: 'get',
      url: `${SourceService.API_BASE_URL}admin/sources/${sourceCode}`,
    });
    return response.data;
  };

  static getNetwork = async (networkCode: string) => {
    const response = await axios({
      method: 'get',
      url: `${SourceService.API_BASE_URL}admin/networks/${networkCode}`,
    });
    return response.data;
  };

  static getUsers = async (
    role: string,
    networkCode: string,
    sourceCode: string
  ) => {
    const response = await axios({
      method: 'get',
      url: `${SourceService.API_BASE_URL}users/admin/users/list?role=${role}&network_code=${networkCode}&source_code=${sourceCode}`,
    });
    return response.data;
  };

  static updateUser = async (userInfo: any) => {
    const response = await axios({
      method: 'post',
      url: `${SourceService.API_BASE_URL}users/admin/users`,
      data: userInfo,
    });
    return response.data;
  };

  static deleteUser = async (phone: any) => {
    const response = await axios({
      method: 'put',
      url: `${SourceService.API_BASE_URL}users/admin/users/${phone}/delete`,
    });
    return response.data;
  };

  static postSource = async (sourceInfo: any) => {
    const response = await axios({
      method: 'post',
      url: `${SourceService.API_BASE_URL}admin/sources`,
      data: sourceInfo,
    });
    return response.data;
  };

  static activateSource = async (sourceCode: string) => {
    const response = await axios({
      method: 'put',
      url: `${SourceService.API_BASE_URL}admin/sources/${sourceCode}/visible`,
    });
    return response.data;
  };

  static deactivateSource = async (sourceCode: string) => {
    const response = await axios({
      method: 'put',
      url: `${SourceService.API_BASE_URL}admin/sources/${sourceCode}/invisible`,
    });
    return response.data;
  };

  static createPharmacyTag = async (createdTag: PharmacyTag) => {
    const response = await axios({
      method: 'post',
      url: `${SourceService.API_BASE_URL}admin/tags`,
      data: JSON.stringify(createdTag),
    });
    return response.data;
  };

  static getPharmacyTags = async (tagsType: 'source' | 'network') => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${SourceService.API_BASE_URL}admin/tags?type=${tagsType}`,
      });
      return response?.data;
    } catch (error) {
      return [];
    }
  };

  /*  actions.getTags.request() */

  static setSourceTag = async (
    tagIdsList: number[],
    sourceCode: string,
    type: string,
    textMeta: string
  ) => {
    const payloadData =
      textMeta?.length > 0
        ? [
            {
              id: tagIdsList[0],
              meta: textMeta,
            },
          ]
        : [
            {
              id: tagIdsList[0],
            },
          ];

    const response = await axios({
      method: 'put',
      url: `${SourceService.API_BASE_URL}admin/${type}/${sourceCode}/tag`,
      data: payloadData,
    });
    return response.data;
  };

  static putTag = async (tagId: number, updatedTag: PharmacyTag) => {
    const response = await axios({
      method: 'put',
      url: `${SourceService.API_BASE_URL}admin/tags/${tagId}`,
      data: JSON.stringify(updatedTag),
    });
    return response.data;
  };

  static unmapTag = async (tagId: number, sourceCode: string, type: string) => {
    const response = await axios({
      method: 'delete',
      url: `${SourceService.API_BASE_URL}admin/${type}/${sourceCode}/tag`,
      data: JSON.stringify({
        tag: tagId,
      }),
    });

    return response.data;
  };
}
