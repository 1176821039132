import { ClickAwayListener } from '@mui/material';
import * as Styled from './SelectModal.styles';

interface SelectModalProps {
  onClose: () => void;
  selectOptionsList: { value: string; name: string }[];
  setCurrentFilterOption: (value: string) => void;
}

const SelectModal = ({
  onClose,
  selectOptionsList,
  setCurrentFilterOption,
}: SelectModalProps) => {
  return (
    <ClickAwayListener onClickAway={onClose}>
      <Styled.SelectModalContainer>
        <div className="options-list-container">
          {selectOptionsList.map((option) => (
            <div
              className="title"
              onClick={() => setCurrentFilterOption(option.value)}
            >
              {option.name}
            </div>
          ))}
        </div>
      </Styled.SelectModalContainer>
    </ClickAwayListener>
  );
};

export default SelectModal;
