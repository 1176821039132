import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
// @ts-ignore
import { Helmet } from 'react-helmet';
import { isMobile } from 'react-device-detect';
import axios from 'axios';
import ActivePharmacistBlock from '../../components/ActivePharmacistBlock';
import * as actionMagnum from '../../components/ActivePharmacistBlock/actions';
import { selectors as selectorsMagnum } from '../../components/ActivePharmacistBlock/reducer';
import Button from '../../components/Button';
import InfoReportByDay from '../../components/InfoReportByDay';
import Loader from '../../components/Loader';
import NewOrderNotification from '../../components/NewOrderNotification';
import OrderCancelModal from '../../components/OrderCancelModal';
import OrderDetailedModal from '../../components/OrderDetailedModal';
import OrderSearchModal from '../../components/OrderSearchModal';
import {
  addToProducts,
  clearProducts,
  formatDate,
  getFromLocalStorage,
  setToLocalStorage,
} from '../../global/helpers';
import useRefresh from '../../global/hooks/useRefresh';
import faviconSvg from '../../global/media/favicon.svg';
import questionIcon from '../../global/media/question.png';
import searchIcon from '../../global/media/search.svg';
import { selectors as selectorsLogin } from '../LoginPage/reducer';
import * as actionsOrder from '../OrderPage/actions';
import { selectors as selectorsOrder } from '../OrderPage/reducer';
import * as ordersActions from '../OrdersPage/actions';
import * as actionsReport from '../ReportPage/actions';
import { selectors as selectorsReport } from '../ReportPage/reducer';
import * as Styled from './OrdersPage.styles';
import * as actions from './actions';
import ManagerCollector from './components/ManagerCollector';
import OrdersTable from './components/OrdersTable';
import {
  amountOfOrders,
  getDay,
  getDeliveryOrders,
  getFoodOrders,
  getNewOrders,
  getOverdueOrders,
  getSelfOrders,
  getYellowOrders,
  sortOrdersByNewest,
  getNetworkOrders,
} from './helpers';
import { selectors } from './reducer';
import { OrderTableData, OrdersTabOptions, UserRole } from './types';
import { StatusType } from '../../global/types';
import OfflineOrdersModal from '../../components/OfflineOrdersModal';
import InformationModal from '../../components/InformationModal';
import * as actionsAnalogs from '../AnalogsPage/actions';
import { selectors as selectorsAnalogs } from '../AnalogsPage/reducer';
import GoldenAppleCard from './components/GoldenAppleCard';
import GoldenAppleBanner from './components/GoldenAppleCard/GoldenAppleCard';

const OrdersPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const role =
    getFromLocalStorage('role') === 'manager' ? 'network' : 'pharmacy';
  const userRole = getFromLocalStorage('role');
  const education = history.location.pathname.includes('/test-orders');
  const [isShowSearchModal, setShowSearchModal] = useState(false);
  const [cancelOrder, setCancelOrder] = useState<null | OrderTableData>(null);
  const [statusOrders, setStatusOrders] = useState<string>('');
  const [paymentOrders, setPaymentOrders] = useState<string | boolean>('');
  const [paymentTypeOrders, setPaymentTypeOrders] = useState<string>('');
  const [deliveryOrders, setDeliveryOrders] = useState<string>('');
  const [sourceOrders, setSourceOrders] = useState<string>('');
  const [confirmOrderOpen, setConfirmOrderOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const informationData = useSelector(selectorsAnalogs.informationData);
  const [informationModal, setInformationModal] = useState<boolean>(false);
  const [isPharmacistOnOrderShift, setPharmacistOnOrderShift] =
    useState<boolean>(false);
  const [isPharmacistOnOrderOpen, setPharmacistOnOrderOpen] =
    useState<boolean>(false);
  const [isShownPharmacistToolTip, setShownPharmacistToolTip] =
    useState<boolean>(false);
  const [pharmacistOnOrder, setPharmacistOnOrder] = useState<{
    name: string;
    phone: string;
  } | null>(null);

  const data = useSelector(selectors.ordersPageData);
  const dataNetwork = useSelector(selectors.allNetworkEnabledData);
  const dataLoading = useSelector(selectors.ordersPageLoading);
  const orderId = useSelector(selectors.orderId);
  const selectedActivePharmacist = useSelector(
    selectorsOrder.selectedActivePharmacist
  );
  const dataUser = useSelector(selectorsLogin.userData);
  const newOrdersMuted = useSelector(selectors.notMutedOrders);
  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const sourcesData = useSelector(selectors.sourcesData);

  const dataToday = useSelector(selectorsReport.ordersToday);
  const dataYesterday = useSelector(selectorsReport.ordersYesterday);

  const magnumUsers = useSelector(selectorsMagnum.activeUsersData);

  const [currentTab, setCurrentTab] = useState(OrdersTabOptions.allOrders);
  const [dataByTab, setDataByTab] = useState<null | OrderTableData[]>(null);
  const [isOfflineOrdersOpen, setIsOfflineOrdersOpen] =
    useState<boolean>(false);

  const [isMobileMode, setMobileMode] = useState(isMobile);

  const time = process.env.REACT_APP_EMPLOYEES_NEEDED_TIME_VALUE?.split(
    ','
  ) || ['0', '7'];
  const isNightTime = () => {
    const currentTime = new Date();
    const currentHour = currentTime.getHours();
    return currentHour >= Number(time[0]) && currentHour < Number(time[1]);
  };

  const checkDeliveryOverdue = () =>
    data?.some(
      (item) =>
        (item.status === 'in_delivery' &&
          item.statusDelivery === 'delivered_finish') ||
        (item.status === 'in_delivery' && item.statusDelivery === 'returning')
    );

  const getOrdersByCurrentTab = () => {
    if (currentTab === OrdersTabOptions.overdueOrders && data) {
      setDataByTab(getOverdueOrders(data));
    }

    if (currentTab === OrdersTabOptions.deliveryOrders && data) {
      setDataByTab(getDeliveryOrders(data));
    }
    if (currentTab === OrdersTabOptions.foodOrders && data) {
      setDataByTab(getFoodOrders(data));
    }

    if (currentTab === OrdersTabOptions.networkOrders && data && dataNetwork) {
      setDataByTab(getNetworkOrders(data, dataNetwork));
    }
    if (currentTab === OrdersTabOptions.selfOrders && data) {
      setDataByTab(getSelfOrders(data));
    }

    if (currentTab === OrdersTabOptions.allOrders) {
      setDataByTab(data ? sortOrdersByNewest(data) : data);
    }

    if (currentTab === OrdersTabOptions.newOrders && data) {
      setDataByTab(getNewOrders(data));
    }
    if (currentTab === OrdersTabOptions.yellowOrders && data) {
      setDataByTab(getYellowOrders(data));
    }
  };

  const isDataPresent = (informationData: any): boolean => {
    const savedInformation = getFromLocalStorage('looked-information');

    if (
      !Array.isArray(savedInformation) ||
      savedInformation.length !== informationData.length
    ) {
      return true;
    }

    return informationData.some(
      (item: any, i: number) =>
        item.toLowerCase() !== savedInformation[i].toLowerCase()
    );
  };

  const formatDateForRequest = (yesterday?: boolean) => {
    return !yesterday
      ? formatDate().split('.').reverse().join('-')
      : formatDate(true).split('.').reverse().join('-');
  };

  useEffect(() => {
    setLoading(true);
    if (
      userRole === UserRole.ADMIN ||
      userRole === UserRole.OPERATOR ||
      userRole === UserRole.HEAD_OPERATOR
    ) {
      dispatch(actions.getAllNetworkEnabled.request());
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!dataUser?.sourceCode) {
      return;
    }
    dispatch(
      actionMagnum.getAllUsers.request({ sourceCode: dataUser.sourceCode })
    );
  }, [dataUser]);

  useEffect(() => {
    if (magnumUsers?.every((f) => !f.active)) {
      setPharmacistOnOrderShift(true);
    }
  }, [magnumUsers]);

  useEffect(() => {
    addToProducts();
    setToLocalStorage('selectedOrderId', '');
    setToLocalStorage('selectedOrder', {});
  }, []);

  useEffect(() => {
    if (
      !getFromLocalStorage('looked-information') &&
      informationData &&
      !informationData[0].includes('{')
    ) {
      setInformationModal(true);
    }
    if (
      informationData &&
      !informationData[0].includes('{') &&
      getFromLocalStorage('looked-information')
    ) {
      setInformationModal(isDataPresent(informationData));
    }
  }, [informationData]);

  useEffect(() => {
    if (userRole === UserRole.PHARMACIST) {
      dispatch(
        actionsAnalogs.getAnalogsListPharmacistBySku.request({
          key: 'notice_of_changes',
        })
      );
    }
  }, [userRole]);

  useEffect(() => {
    if (
      userRole === UserRole.OPERATOR ||
      userRole === UserRole.ADMIN ||
      userRole === UserRole.HEAD_OPERATOR
    ) {
      getOrdersByCurrentTab();
    }
  }, [currentTab, data]);

  useEffect(() => {
    if (data) {
      const filteredData = data.map(
        ({
          id,
          orderNumber,
          phone,
          createdAt,
          sourceName,
          deliveryType,
          amount,
          statusNameDelivery,
          statusName,
        }) => ({
          id,
          orderNumber,
          phone,
          createdAt,
          sourceName,
          deliveryType,
          amount,
          statusNameDelivery,
          statusName,
        })
      );
      setToLocalStorage('ordersList', filteredData);
    }
  }, [data]);

  useEffect(() => {
    setLoading(true);
    if (
      userRole === UserRole.PHARMACIST ||
      userRole === UserRole.MANAGER ||
      userRole === UserRole.PHARMACY_MANAGER
    ) {
      dispatch(actions.getNotMutedOrders.request({ testOrders: !!education }));
    }
    if (!education) {
      dispatch(
        actions.getOrders.request({
          status:
            statusOrders === StatusType.NEW &&
            getFromLocalStorage('role') === UserRole.PHARMACIST
              ? StatusType.IN_PHARMACY_PLACED
              : statusOrders,
          payment: paymentOrders,
          source: sourceOrders,
          paymentType: paymentTypeOrders,
          delivery: deliveryOrders,
          limit:
            userRole === UserRole.OPERATOR ||
            userRole === UserRole.ADMIN ||
            userRole === UserRole.HEAD_OPERATOR
              ? '200'
              : '100',
          postEffect: () => setLoading(false),
        })
      );
    } else {
      dispatch(
        actions.getOrders.request({
          testOrders: true,
          limit:
            userRole === UserRole.OPERATOR ||
            userRole === UserRole.ADMIN ||
            userRole === UserRole.HEAD_OPERATOR
              ? '200'
              : '100',
          postEffect: () => setLoading(false),
        })
      );
    }
    (userRole === UserRole.PHARMACIST ||
      userRole === UserRole.MANAGER ||
      userRole === UserRole.PHARMACY_MANAGER) &&
      dispatch(
        actionsReport.getReportByDate.request({
          date: formatDateForRequest(),
          dateYest: formatDateForRequest(true),
          testOrders: !!education,
        })
      );
    clearProducts();
    setLoading(false);
  }, [
    dispatch,
    role,
    statusOrders,
    paymentOrders,
    sourceOrders,
    education,
    deliveryOrders,
    paymentTypeOrders,
  ]);

  useRefresh(() => {
    if (
      userRole === UserRole.PHARMACIST ||
      userRole === UserRole.MANAGER ||
      userRole === UserRole.PHARMACY_MANAGER
    ) {
      dispatch(actions.getNotMutedOrders.request({ testOrders: !!education }));
    }
    if (!education) {
      dispatch(
        actions.getOrdersRefresh.request({
          status:
            statusOrders === StatusType.NEW &&
            getFromLocalStorage('role') === UserRole.PHARMACIST
              ? StatusType.IN_PHARMACY_PLACED
              : statusOrders,
          payment: paymentOrders,
          source: sourceOrders,
          paymentType: paymentTypeOrders,
          delivery: deliveryOrders,
          limit:
            userRole === UserRole.OPERATOR ||
            userRole === UserRole.ADMIN ||
            userRole === UserRole.HEAD_OPERATOR
              ? '200'
              : '100',
        })
      );
    } else {
      dispatch(
        actions.getOrders.request({
          status:
            statusOrders === StatusType.NEW &&
            getFromLocalStorage('role') === UserRole.PHARMACIST
              ? StatusType.IN_PHARMACY_PLACED
              : statusOrders,
          testOrders: true,
          limit:
            userRole === UserRole.OPERATOR ||
            userRole === UserRole.ADMIN ||
            userRole === UserRole.HEAD_OPERATOR
              ? '200'
              : '100',
        })
      );
    }
    (userRole === UserRole.PHARMACIST ||
      userRole === UserRole.MANAGER ||
      userRole === UserRole.PHARMACY_MANAGER) &&
      dispatch(
        actionsReport.getReportByDate.request({
          date: formatDateForRequest(),
          dateYest: formatDateForRequest(true),
          testOrders: !!education,
        })
      );
  }, [dispatch, statusOrders, paymentOrders, sourceOrders, education]);

  useEffect(() => {
    if (
      userRole === UserRole.PHARMACIST ||
      userRole === UserRole.MANAGER ||
      userRole === UserRole.PHARMACY_MANAGER
    ) {
      if (dataUser && !magnumUsers) {
        dispatch(
          actionMagnum.getAllUsers.request({ sourceCode: dataUser.sourceCode })
        );
      }
    }
  }, [dataUser]);

  const handleSetOrdersStatus = (status: string) => {
    setStatusOrders(status);
  };
  const handleSetSourceStatus = (source: string) => {
    setSourceOrders(source);
  };

  const handleSetPaymentStatus = (payment: boolean | string) => {
    setPaymentOrders(payment);
  };

  const handleSetPaymentTypeStatus = (payment: string) => {
    setPaymentTypeOrders(payment);
  };

  const handleSetDeliveryStatus = (delivery: string) => {
    setDeliveryOrders(delivery);
  };

  const handleOrderModalClose = () => {
    dispatch(actions.clearOrderId());
    dispatch(actions.clearSelectedOrder());
    dispatch(
      actions.getOrders.request({
        role,
        status: '',
        limit:
          userRole === UserRole.OPERATOR ||
          userRole === UserRole.ADMIN ||
          userRole === UserRole.HEAD_OPERATOR
            ? '200'
            : '100',
      })
    );
    setOpenDetail(false);
    setShowSearchModal(true);
  };

  const handleOrderCancel = (data: OrderTableData) => {
    setCancelOrder(data);
    setShowSearchModal(false);
  };

  const handleDeclineOrder = (data: OrderTableData) => {
    setCancelOrder(null);
  };

  const handleSubmitOrder = (message: string, id: string) => {
    setCancelOrder(null);
    dispatch(
      ordersActions.cancelOrder.request({
        message,
        id,
        postEffect: () =>
          dispatch(
            actions.setSelectedOrder.request({
              role,
              id: orderId,
              testOrders: !!education,
            })
          ),
      })
    );
  };

  useEffect(() => {
    if (
      newOrdersMuted &&
      newOrdersMuted?.count > 0 &&
      (userRole === UserRole.PHARMACIST ||
        userRole === UserRole.MANAGER ||
        userRole === UserRole.PHARMACY_MANAGER)
    ) {
      setConfirmOrderOpen(true);
    } else setConfirmOrderOpen(false);
  }, [newOrdersMuted]);

  const handleGenerateTestOrders = () => {
    dispatch(
      actions.generateTestOrders.request({
        phone: dataUser.phone,
        source: dataUser.sourceCode,
        postEffect: () => {
          dispatch(
            actions.getOrders.request({
              testOrders: true,
              limit:
                userRole === UserRole.OPERATOR ||
                userRole === UserRole.ADMIN ||
                userRole === UserRole.HEAD_OPERATOR
                  ? '200'
                  : '100',
            })
          );
          dispatch(actions.getNotMutedOrders.request({ testOrders: true }));
        },
      })
    );
  };

  const handleSetOrderPharmacist = (collector: {
    name: string;
    phone: string;
  }) => {
    dispatch(actionsOrder.setSelectedActivePharmacistOnOrder(collector));
    setPharmacistOnOrder(collector);
  };

  const handleOpenPharmacistOnOrderShift = (isOpen: boolean) => {
    setPharmacistOnOrderShift(isOpen);
  };

  const handleOpenPharmacistOnOrder = (isOpen: boolean) => {
    setPharmacistOnOrderOpen(isOpen);
  };

  const handleEmptyActiveUserClick = () => {
    handleOpenPharmacistOnOrder(false);
    handleOpenPharmacistOnOrderShift(true);
  };

  const handleSaveOrderPharmacist = () => {
    selectedActivePharmacist && orderId && history.push(`/orders/${orderId}`);
  };

  const toggleView = () => {
    let viewport = document.querySelector(
      'meta[name="viewport"]'
    ) as HTMLMetaElement;

    if (!viewport) {
      viewport = document.createElement('meta');
      viewport.name = 'viewport';
      document.head.appendChild(viewport);
    }

    if (isMobileMode) {
      viewport.content = 'width=1200';
    } else {
      viewport.content = 'width=device-width, initial-scale=1';
    }

    setMobileMode(!isMobileMode);

    window.dispatchEvent(new Event('viewportChange'));
  };
  useEffect(() => {
    const handleResize = () => {
      setMobileMode(window.innerWidth < 700);
    };

    const handleViewportChange = () => {
      const viewport = document.querySelector(
        'meta[name="viewport"]'
      ) as HTMLMetaElement | null;
      if (viewport) {
        setMobileMode(!viewport.content.includes('width=1200'));
      }
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('viewportChange', handleViewportChange);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('viewportChange', handleViewportChange);
    };
  }, []);
  return (
    <>
      {(userRole === UserRole.OPERATOR ||
        userRole === UserRole.HEAD_OPERATOR) &&
        !data &&
        !dataLoading && (
          <Styled.OfflineOrdersButton
            onClick={() => setIsOfflineOrdersOpen(true)}
          >
            <div className="button-offline">Офлайн заказы</div>
          </Styled.OfflineOrdersButton>
        )}
      {!confirmOrderOpen && (
        <Helmet>
          <link rel="icon" type="image/png" href={faviconSvg} />
        </Helmet>
      )}
      {((!data && dataLoading) || loading) && <Loader />}
      <Styled.OrdersPageContainer
        isOperator={
          userRole === UserRole.OPERATOR ||
          userRole === UserRole.ADMIN ||
          userRole === UserRole.HEAD_OPERATOR
        }
      >
        {(userRole === UserRole.MANAGER ||
          userRole === UserRole.PHARMACY_MANAGER) && <ManagerCollector />}
        {(userRole === UserRole.PHARMACIST ||
          ((userRole === UserRole.MANAGER ||
            userRole === UserRole.PHARMACY_MANAGER) &&
            dataUser?.isCollector &&
            sourcesData?.length !== 0)) && (
          <>
            <Styled.NumberCallCenter>
              {t('OrderPage.Collection.NUMBER_CALL_CENTER')}
            </Styled.NumberCallCenter>
            <Styled.PharmacistContainer>
              {userRole === UserRole.PHARMACIST && dataUser?.disabled && (
                <div className="error-message">
                  {t('OrderPage.Collection.PHARMACY_DISABLED')}
                </div>
              )}
              <Styled.PharmacistButton>
                <Button
                  variant="dark"
                  text={t('OrdersPage.ActivePharmacistBlock.TITLE_ON_SHIFT')}
                  onClick={() => setPharmacistOnOrderShift(true)}
                />
              </Styled.PharmacistButton>
              <div
                onClick={() => setShownPharmacistToolTip((prev) => !prev)}
                onMouseEnter={() => setShownPharmacistToolTip(true)}
                onMouseLeave={() => setShownPharmacistToolTip(false)}
              >
                <img src={questionIcon} alt="pharmacist-tooltip" />
              </div>
              {isShownPharmacistToolTip && (
                <div className="pharmacist-tooltip">
                  {t('OrdersPage.ActivePharmacistBlock.TOOLTIP_FIRST')} <br />
                  <br />
                  {t('OrdersPage.ActivePharmacistBlock.TOOLTIP_SECOND')}
                </div>
              )}
            </Styled.PharmacistContainer>
          </>
        )}
        {(userRole === UserRole.PHARMACIST ||
          userRole === UserRole.MANAGER ||
          userRole === UserRole.PHARMACY_MANAGER) && (
          <Styled.InfoByDay>
            <Styled.ReportByDaysContainer id="report">
              <InfoReportByDay
                data={dataYesterday}
                day={`${getDay(true)}, ${formatDate(true)}`}
              />
              <InfoReportByDay
                data={dataToday}
                day={` ${getDay()}, ${formatDate()}`}
              />
            </Styled.ReportByDaysContainer>
          </Styled.InfoByDay>
        )}
        <Styled.OrdersPageHeaderContainer>
          {!isMobile && (
            <Styled.TextContainer>
              <Styled.OrdersPageTitle>
                {t('OrdersPage.TITLE')}
              </Styled.OrdersPageTitle>
              {education && (
                <Styled.OrdersCreateText
                  onClick={handleGenerateTestOrders}
                  id="generate_orders"
                >
                  {t('OrdersPage.CREATE_ORDERS_BTN_TEXT')}
                </Styled.OrdersCreateText>
              )}
            </Styled.TextContainer>
          )}
          {/* {process.env.REACT_APP_EMPLOYEES_NEEDED_TEXT &&
            // isNightTime() &&
            userRole === UserRole.PHARMACIST && (
              <Styled.EmployeesNeededText>
                {process.env.REACT_APP_EMPLOYEES_NEEDED_TEXT.split('\\n').map(
                  (line) => (
                    <p key={line}>{line}</p>
                  )
                )}
              </Styled.EmployeesNeededText>
            )} */}
          {userRole === UserRole.PHARMACIST && (
            <Styled.EmployeesNeededText>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '12px' }}
              >
                {!isMobileMode && (
                  <img src="/images/qr.png" alt="qr" width="160" />
                )}
                <p
                  style={{
                    fontSize: isMobileMode ? '14px' : '16px',
                    lineHeight: isMobileMode ? '18px' : '24px',
                  }}
                >
                  Дорогие коллеги, подписывайтесь на телеграмм канал, где будут
                  размещаться новости и конкурсы от Daribar.kz!
                  <br />
                  <br />
                  <a
                    href="https://t.me/+hF8I6oOTWkRhY2Qy"
                    rel="noreferrer"
                    target="_blank"
                    style={{ textDecoration: 'underline', color: 'blue' }}
                  >
                    https://t.me/+hF8I6oOTWkRhY2Qy
                  </a>
                  <br />
                  Фармацевты Daribar
                </p>
              </div>
            </Styled.EmployeesNeededText>
          )}
          {userRole === UserRole.ADMIN && (
            <Button
              onClick={toggleView}
              text={
                isMobileMode ? t('Header.TO_DESKTOP') : t('Header.TO_MOBILE')
              }
              variant="dark"
            >
              <img
                src={isMobileMode ? '/images/desktop.svg' : '/images/phone.svg'}
                alt="icon"
              />
            </Button>
          )}
          <div id="search" style={{ marginTop: isMobileMode ? '16px' : '0' }}>
            <Button
              onClick={() => setShowSearchModal(true)}
              text={t('OrdersPage.BTN_TEXT')}
              img={searchIcon}
              variant="dark"
            />
          </div>
        </Styled.OrdersPageHeaderContainer>
        {userRole === UserRole.PHARMACIST && !isMobileMode && (
          <Styled.EmployeesNeededText
            style={{
              minWidth: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <p>
              Запускаем новый функционал — уведомление фармацевтов о новом
              заказе в приложении на телефоне. Для корректной работы важно,
              чтобы в графе "Кто на смене?" был указан фармацевт, работающий в
              этот день, а также чтобы фармацевт был авторизован в приложении по
              номеру телефона, указанному в личном кабинете.
            </p>
            <a
              href="https://t.me/+hF8I6oOTWkRhY2Qy"
              rel="noreferrer"
              target="_blank"
              style={{ textDecoration: 'underline', color: 'blue' }}
            >
              https://t.me/c/2159390166/33
            </a>
          </Styled.EmployeesNeededText>
          // <GoldenAppleBanner/>
        )}
        {(userRole === UserRole.OPERATOR ||
          userRole === UserRole.ADMIN ||
          userRole === UserRole.HEAD_OPERATOR) && (
          <Styled.OrdersPageTabsContainer>
            <div
              className={`tab-item ${
                currentTab === OrdersTabOptions.allOrders && 'type-active'
              }`}
              onClick={() => setCurrentTab(OrdersTabOptions.allOrders)}
            >
              {t('OrdersPage.TABS.ALL')} {data?.length || '0'}
            </div>

            <div
              className={`tab-item ${
                currentTab === OrdersTabOptions.yellowOrders && 'type-active'
              } ${
                getYellowOrders(data)?.length !== 0 &&
                currentTab !== OrdersTabOptions.yellowOrders &&
                'item-yellow'
              }`}
              onClick={() => setCurrentTab(OrdersTabOptions.yellowOrders)}
            >
              {t('OrdersPage.TABS.YELLOW')}{' '}
              {getYellowOrders(data)?.length || '0'}
            </div>

            <div
              className={`tab-item ${
                currentTab === OrdersTabOptions.overdueOrders && 'type-active'
              } ${
                getOverdueOrders(data)?.length !== 0 &&
                currentTab !== OrdersTabOptions.overdueOrders &&
                'item-overdue'
              }`}
              onClick={() => setCurrentTab(OrdersTabOptions.overdueOrders)}
            >
              {t('OrdersPage.TABS.OVERDUE')}{' '}
              {getOverdueOrders(data)?.length || '0'}
            </div>

            <div
              className={`tab-item ${
                currentTab === OrdersTabOptions.newOrders && 'type-active'
              } ${
                getNewOrders(data)?.length !== 0 &&
                currentTab !== OrdersTabOptions.newOrders &&
                'item-new'
              }`}
              onClick={() => setCurrentTab(OrdersTabOptions.newOrders)}
            >
              {t('OrdersPage.TABS.NEW_ORDERS')}{' '}
              {getNewOrders(data)?.length || '0'}
            </div>

            <div
              className={`tab-item ${
                currentTab === OrdersTabOptions.selfOrders && 'type-active'
              }`}
              onClick={() => setCurrentTab(OrdersTabOptions.selfOrders)}
            >
              {t('OrdersPage.TABS.SELF')} {getSelfOrders(data)?.length || '0'}
            </div>

            <div
              className={`tab-item ${
                currentTab === OrdersTabOptions.deliveryOrders && 'type-active'
              } ${
                checkDeliveryOverdue() &&
                getDeliveryOrders(data)?.length &&
                currentTab !== OrdersTabOptions.deliveryOrders &&
                'item-overdue'
              }`}
              onClick={() => setCurrentTab(OrdersTabOptions.deliveryOrders)}
            >
              {t('OrdersPage.TABS.DELIVERY')}{' '}
              {getDeliveryOrders(data)?.length || '0'}
            </div>

            <div
              className={`tab-item ${
                currentTab === OrdersTabOptions.networkOrders && 'type-active'
              }`}
              onClick={() => setCurrentTab(OrdersTabOptions.networkOrders)}
            >
              {t('OrdersPage.TABS.NETWORK')}{' '}
              {getNetworkOrders(data, dataNetwork)?.length || '0'}
            </div>
            <div
              className={`tab-item ${
                currentTab === OrdersTabOptions.foodOrders && 'type-active'
              }`}
              onClick={() => setCurrentTab(OrdersTabOptions.foodOrders)}
            >
              {t('OrdersPage.TABS.FOOD')} {getFoodOrders(data)?.length || '0'}
            </div>
          </Styled.OrdersPageTabsContainer>
        )}
        <Styled.OrdersPageTableContainer>
          <OrdersTable
            data={
              userRole === UserRole.OPERATOR ||
              userRole === UserRole.ADMIN ||
              userRole === UserRole.HEAD_OPERATOR
                ? dataByTab
                : data
            }
            dataByTab={dataByTab}
            setStatus={handleSetOrdersStatus}
            setPayment={handleSetPaymentStatus}
            setDelivery={handleSetDeliveryStatus}
            setPaymentType={handleSetPaymentTypeStatus}
            setSource={
              (userRole === UserRole.MANAGER ||
                userRole === UserRole.OPERATOR ||
                userRole === UserRole.HEAD_OPERATOR ||
                userRole === UserRole.PHARMACY_MANAGER ||
                userRole === UserRole.ADMIN) &&
              handleSetSourceStatus
            }
            onOrderClick={handleOpenPharmacistOnOrder}
            paymentOrders={paymentOrders}
            onSetOrderPharmacist={handleSetOrderPharmacist}
          />
        </Styled.OrdersPageTableContainer>
      </Styled.OrdersPageContainer>
      {isShowSearchModal && (
        <OrderSearchModal
          openDetail={() => setOpenDetail(true)}
          onClose={() => setShowSearchModal(false)}
        />
      )}
      {openDetail && (
        <OrderDetailedModal
          onClose={handleOrderModalClose}
          onOrderCancel={handleOrderCancel}
        />
      )}
      {isOfflineOrdersOpen && (
        <OfflineOrdersModal
          onClose={() => setIsOfflineOrdersOpen(false)}
          data={
            getFromLocalStorage('ordersList')
              ? getFromLocalStorage('ordersList')
              : null
          }
        />
      )}
      {cancelOrder && (
        <OrderCancelModal
          onDecline={handleDeclineOrder}
          onSubmit={handleSubmitOrder}
          data={cancelOrder}
        />
      )}
      {isPharmacistOnOrderShift &&
        (userRole === UserRole.PHARMACIST ||
          userRole === UserRole.MANAGER ||
          userRole === UserRole.PHARMACY_MANAGER) && (
          <ActivePharmacistBlock
            variant="isPharmacistOnShift"
            onModalClose={handleOpenPharmacistOnOrderShift}
          />
        )}

      {informationModal && userRole === UserRole.PHARMACIST && (
        <InformationModal
          onClose={() => setInformationModal(false)}
          setInformationModal={setInformationModal}
          informationData={informationData}
        />
      )}
      {isPharmacistOnOrderOpen && (
        <ActivePharmacistBlock
          variant="isPharmacistOnOrder"
          onModalClose={handleOpenPharmacistOnOrder}
          onSaveOrderPharmacist={handleSaveOrderPharmacist}
          onSetOrderPharmacist={handleSetOrderPharmacist}
          pharmacistOnOrder={pharmacistOnOrder}
          orderId={orderId}
          onEmptyActiveUsersClick={handleEmptyActiveUserClick}
        />
      )}
      {(userRole === UserRole.PHARMACIST ||
        userRole === UserRole.MANAGER ||
        userRole === UserRole.PHARMACY_MANAGER) &&
        confirmOrderOpen &&
        !dataLoading && (
          <NewOrderNotification
            onClose={() => setConfirmOrderOpen(false)}
            newOrders={newOrdersMuted}
          />
        )}
    </>
  );
};

export default OrdersPage;
