import axios from 'axios';
import { SalesNew, SalesProductData } from './types';

export class SalesService {
  static API_BASE_URL_1 = `${process.env.REACT_APP_API_BASE_URL}/api/v1/`;

  static API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/api/v2/`;

  static getSalesProducts = async (networkCode: string) => {
    const response = await axios({
      method: 'get',
      url: `${SalesService.API_BASE_URL}products/discount-products`,
    });
    return response.data;
  };

  static editSalesProducts = async (
    networkCode: string,
    editedProduct: SalesProductData
  ) => {
    const response = await axios({
      method: 'post',
      url: `${SalesService.API_BASE_URL}admin/sales/products/${networkCode}/update`,
      data: JSON.stringify(editedProduct),
    });

    return response.data;
  };

  static updateCost = async (saleInfo: any) => {
    const response = await axios({
      method: 'put',
      url: `${SalesService.API_BASE_URL}products/discount-products`,
      data: saleInfo,
    });
    return response.data;
  };

  static deleteProduct = async (sku: any) => {
    const response = await axios({
      method: 'delete',
      url: `${SalesService.API_BASE_URL}products/discount-products`,
      data: sku,
    });
    return response.data;
  };

  static createNewOrder = async (createdNewOrder: SalesNew) => {
    const response = await axios({
      method: 'post',
      url: `${SalesService.API_BASE_URL}products/discount-products`,
      data: createdNewOrder,
    });
    return response.data;
  };

  static getAllSalesProducts = async (selectedCity: string) => {
    const response = await axios({
      method: 'get',
      url: `${SalesService.API_BASE_URL_1}admin/stock/admin?discount=true&city=${selectedCity}`,
    });
    return response.data;
  };

  static getProductsInfo = async (arr: string[], sourceCode: string) => {
    const response = await axios({
      method: 'post',
      url: `${SalesService.API_BASE_URL_1}prices/${sourceCode}`,
      data: JSON.stringify({ skus: arr }),
    });
    return response.data;
  };
}
