import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './HistoryTable.styles';
import HistoryTableRow from './components/HistoryTableRow';
import { OrderHistoryMappedResponse } from '../../types';

interface HistoryTableParams {
  data: OrderHistoryMappedResponse[];
}

const HistoryTable = ({ data }: HistoryTableParams) => {
  const { t } = useTranslation();

  const TableHeader = (
    <Styled.HistoryHead>
      <Styled.HistoryTableHeaderRow>
        <th className="table__header-cell">
          {t('OrderHistoryPage.HistoryTable.headerTitles.USER')}
        </th>
        <th className="table__header-cell">
          {t('OrderHistoryPage.HistoryTable.headerTitles.ROLE')}
        </th>
        <th className="table__header-cell">
          {t('OrderHistoryPage.HistoryTable.headerTitles.EXECUTOR')}
        </th>
        <th className="table__header-cell">
          {t('OrderHistoryPage.HistoryTable.headerTitles.TIME')}
        </th>
        <th className="table__header-cell table__header-cell-action">
          {t('OrderHistoryPage.HistoryTable.headerTitles.ACTION')}
        </th>
      </Styled.HistoryTableHeaderRow>
    </Styled.HistoryHead>
  );

  return (
    <Styled.HistoryTable>
      {data && (
        <>
          {TableHeader}
          <tbody>
            {data.map((el) => (
              <HistoryTableRow event={el} />
            ))}
          </tbody>
        </>
      )}
    </Styled.HistoryTable>
  );
};

export default HistoryTable;
