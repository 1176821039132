import React, {
  ChangeEvent,
  FormEventHandler,
  useEffect,
  useState,
} from 'react';
import { t } from 'i18next';
import { FormikErrors } from 'formik';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../actions';
import { SalesProductFormatedData } from '../types';
import { selectors } from '../reducer';
import * as Styled from './NewProductCreate.styles';

interface OrderFormProps {
  values: {
    pharmacy_product: string;
    discount_price: string;
    current_price: string;
    expiration_date: string;
    quantity: string;
    sku: string;
    series: string;
    comment: string;
  };
  setFieldValue?: (field: string, value: string | number | boolean) => void;
  handleSubmit: FormEventHandler<HTMLFormElement>;
  handleChange: {
    (e: ChangeEvent<any>): void;
    <T = string | ChangeEvent<any>>(field: T): T extends ChangeEvent<any>
      ? void
      : (e: string | ChangeEvent<any>) => void;
  };
  errors: FormikErrors<SalesProductFormatedData>;
}

const NewProductCreate = ({
  values,
  handleSubmit,
  setFieldValue,
  handleChange,
  errors,
}: OrderFormProps) => {
  const dispatch = useDispatch();
  const createSaleError = useSelector(selectors.salesProductsError);

  useEffect(() => {
    dispatch(actions.clearCreateErrors());
  }, [values]);

  return (
    <form onSubmit={handleSubmit}>
      <Styled.CreateOrderFormContainer>
        <div className="field-container">
          <span className="label">{t('SALESPAGE.TABLE.HEADER.PRODUCT')}*</span>
          <input
            value={values.pharmacy_product}
            readOnly
            name="pharmacy_product"
            style={{ opacity: 0.5 }}
          />
          {errors?.pharmacy_product && (
            <div className="error-field">{errors?.pharmacy_product}</div>
          )}
        </div>
        <div className="field-container">
          <span className="label">
            {t('SALESPAGE.TABLE.HEADER.CURRENT_AMOUNT')}*
          </span>
          <input
            value={values.current_price}
            readOnly
            onChange={handleChange}
            name="current_price"
            style={{ opacity: 0.5 }}
          />
          {errors?.current_price && (
            <div className="error-field">{errors?.current_price}</div>
          )}
        </div>
        <div className="field-container">
          <span className="label">
            {t('SALESPAGE.TABLE.HEADER.NEW_AMOUNT')}*
          </span>
          <input
            value={values.discount_price}
            onChange={handleChange}
            name="discount_price"
          />
          {errors?.discount_price && (
            <div className="error-field">{errors?.discount_price}</div>
          )}
        </div>
        <div
          className="field-container"
          style={{ marginTop: errors?.discount_price ? '15px' : 0 }}
        >
          <span className="label">
            {t('SALESPAGE.TABLE.HEADER.BEST_BEFORE')}
          </span>
          <input
            type="date"
            value={values.expiration_date}
            onChange={handleChange}
            name="expiration_date"
            min={
              new Date(new Date().setDate(new Date().getDate() + 3))
                .toISOString()
                .split('T')[0]
            }
          />
          {errors?.expiration_date && (
            <div className="error-field">{errors?.expiration_date}</div>
          )}
        </div>
        <div className="field-container">
          <span className="label">{t('SALESPAGE.TABLE.HEADER.SERIES')}*</span>
          <input value={values.series} onChange={handleChange} name="series" />
          {errors?.series && (
            <div className="error-field">{errors?.series}</div>
          )}
        </div>
        <div className="field-container">
          <span className="label">{t('SALESPAGE.TABLE.HEADER.COMMENT')}</span>
          <input
            value={values.comment}
            onChange={handleChange}
            name="comment"
          />
          {errors?.comment && (
            <div className="error-field">{errors?.comment}</div>
          )}
        </div>
        <button
          type="submit"
          className={`submit-btn ${
            Object.keys(errors).length > 0 && 'disabled'
          }`}
        >
          {t('SALESPAGE.TABLE.HEADER.CREATE')}
        </button>
        {createSaleError &&
          createSaleError === 'scanning one: no rows in result set' && (
            <div className="create-error">
              {t('SALESPAGE.TABLE.HEADER.PRODUCT_NO_EXIST')}
            </div>
          )}
      </Styled.CreateOrderFormContainer>
    </form>
  );
};

export default NewProductCreate;
