import { createAction } from '@reduxjs/toolkit';
import * as constants from './constants';
import { asyncActionsCreator } from '../../global/redux';

export const getSource = asyncActionsCreator(constants.GET_SOURCE);
export const postSource = asyncActionsCreator(constants.POST_SOURCE);
export const updateUser = asyncActionsCreator(constants.UPDATE_USER);
export const deleteUser = asyncActionsCreator(constants.DELETE_USER);
export const getUsersOfSource = asyncActionsCreator(
  constants.GET_USERS_OF_SOURCE
);

export const clearSource = createAction(constants.CLEAR_SOURCE);
export const changeEditSource = createAction<boolean>(
  constants.CHANGE_EDIT_SOURCE
);

export const createPharmacyTag = asyncActionsCreator(
  constants.CREATE_PHARMACY_TAG
);
export const getSourceTags = asyncActionsCreator(constants.GET_SOURCE_TAGS);
export const setSourceTag = asyncActionsCreator(constants.SET_SOURCE_TAG);
export const updateTag = asyncActionsCreator(constants.UPDATE_SOURCE_TAG);
export const unmapSourceTag = asyncActionsCreator(constants.UNMAP_SOURCE_TAG);
export const setTag = createAction<{ tagIds: string[] }>(constants.SET_TAG);
