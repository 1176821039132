import React from 'react';
import { ClickAwayListener } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as Styled from './FilterByPayment.styles';
import { paymentSelect } from './constants';
import success from '../../../../../../global/media/Vector (2).svg';

interface FilterByPaymentProps {
  onClose: () => void;
  onPaymentCLick: (payment: boolean | string) => void;
}

const FilterByPayment = ({ onClose, onPaymentCLick }: FilterByPaymentProps) => {
  const { t } = useTranslation();

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Styled.PaymentContainer>
        <div
          className="title"
          key="all"
          onClick={() => onPaymentCLick('' as string)}
        >
          {t('OrdersPage.OrdersTable.PAYMENT.ALL')}
        </div>
        {paymentSelect.map((item: { title: string; code: boolean | string }) =>
          item.code ? (
            <div
              className="title"
              key={item.title}
              onClick={() => onPaymentCLick(item.code as boolean)}
            >
              <img className="success" src={success} alt="success" />
              {item.title}
            </div>
          ) : (
            <div
              className="title"
              key={item.title}
              onClick={() => onPaymentCLick(item.code as boolean)}
            >
              {item.title}
            </div>
          )
        )}
      </Styled.PaymentContainer>
    </ClickAwayListener>
  );
};

export default FilterByPayment;
