import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { PromoCodeService } from './service';
import * as actionsNotification from '../InitComponent/actions';
import {
  NOTIFICATION_TEMPLATES_ERROR,
  NOTIFICATION_PROMO_CODES_ERROR,
  NOTIFICATION_TEMPLATE_SUCCESS,
  NOTIFICATION_PROMO_CODE_SUCCESS,
  NOTIFICATION_PROMO_CODE_ACTIVATE_SUCCESS,
  NOTIFICATION_PROMO_CODE_DEACTIVATE_SUCCESS,
  NOTIFICATION_PROMO_CODES_ACTIVE_ERROR,
  NOTIFICATION_CHANGES_SAVED,
  NOTIFICATION_CHANGES_NOT_SAVED,
} from '../../global/constants';
import {
  GenerateTemplatesProps,
  GeneratePromoCodeProps,
  ActivatePromoCodeProps,
  BonusOrdersDetailsHistoryData,
  BonusOrdersDetailsData,
  PharmacistIndividualExistingPromocodeData,
  PharmacistBonusesBalanceData,
  WidthdrawPharmacistBonusesData,
  PharmacistBlockedPromocodeData,
  getPromocodeStatusByCodeData,
} from './types';
import {
  getFormatedBonusOrdersDetails,
  getFormatedBonusOrdersDetailsHistory,
} from './helpers';
import {
  getPromocodePharmacistBlocked,
  promocodePharmacistBlocked,
} from './actions';
import { SettingsService } from '../SettingsPage/service';

interface GenerateTemplates {
  type: string;
  payload: GenerateTemplatesProps;
}

interface GeneratePromoCodes {
  type: string;
  payload: GeneratePromoCodeProps;
}
interface ActivatePromoCodes {
  type: string;
  payload: ActivatePromoCodeProps;
}

interface GetBonusOrdersDetailsSagaProps {
  type: string;
  payload: any;
}

interface GetBonusOrdersDetailsHistorySagaProps {
  type: string;
  payload: any;
}

interface WithdrawPharmacistBonusesSagaProps {
  type: string;
  payload: {
    data: WidthdrawPharmacistBonusesData;
    postEffect?: () => void;
  };
}

export function* getTemplatesDataSaga() {
  try {
    const response: { status: string; result: any } = yield call(
      PromoCodeService.getTemplates
    );

    yield put(actions.getTemplatesData.success(response.result));
  } catch (error) {
    yield put(actions.getTemplatesData.error(error));
  }
}

export function* generateTemplatesSaga({ payload }: GenerateTemplates) {
  try {
    const response: { status: string; result: string } = yield call(
      PromoCodeService.createTemplates,
      payload.code,
      payload.description,
      payload.discount,
      payload.limits,
      payload.duration
    );
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_TEMPLATE_SUCCESS,
        canBeClosed: true,
        type: 'success',
      })
    );
    if (payload.postEffect) {
      payload.postEffect();
    }
    yield put(actions.generateTemplates.success(response.result));
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_TEMPLATES_ERROR,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.generateTemplates.error(error));
  }
}

export function* getPromoCodesDataSaga() {
  try {
    const response: { status: string; result: any } = yield call(
      PromoCodeService.getPromoCodes
    );

    yield put(actions.getPromoCodesData.success(response.result));
  } catch (error) {
    yield put(actions.getPromoCodesData.error(error));
  }
}

export function* generatePromoCodesSaga({ payload }: GeneratePromoCodes) {
  try {
    const response: { status: string; result: string } = yield call(
      PromoCodeService.createPromoCodes,
      payload.code,
      payload.description,
      payload.templateId,
      payload.user,
      payload.validDuration,
      payload.validFrom,
      payload.validUntil
    );
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_PROMO_CODE_SUCCESS,
        canBeClosed: true,
        type: 'success',
      })
    );
    if (payload.postEffect) {
      payload.postEffect();
    }
    yield put(actions.generatePromoCodes.success(response));
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_PROMO_CODES_ERROR,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.generatePromoCodes.error(error));
  }
}

export function* activatePromoCodesSaga({ payload }: ActivatePromoCodes) {
  try {
    const response: { status: string; result: string } = yield call(
      PromoCodeService.activatePromoCodes,
      payload.code,
      payload.enabled
    );
    yield put(
      actionsNotification.addApplicationNotification({
        content: payload.enabled
          ? NOTIFICATION_PROMO_CODE_ACTIVATE_SUCCESS
          : NOTIFICATION_PROMO_CODE_DEACTIVATE_SUCCESS,
        canBeClosed: true,
        type: 'success',
      })
    );
    if (payload.postEffect) {
      payload.postEffect();
    }
    yield put(actions.activatePromoCodes.success(response));
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_PROMO_CODES_ACTIVE_ERROR,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.activatePromoCodes.error(error));
  }
}

function* getBonusOrdersDetailsSaga({
  payload,
}: GetBonusOrdersDetailsSagaProps) {
  try {
    const response: {
      result: {
        confirmed: BonusOrdersDetailsData[];
        pending: BonusOrdersDetailsData[];
      };
    } = yield call(
      PromoCodeService.getBonusOrdersDetails,
      payload.phoneNumber,
      payload.isAdmin
    );

    const formatedBonusOrdersDetailsData = getFormatedBonusOrdersDetails(
      response.result
    );

    yield put(
      actions.getBonusOrdersDetails.success(formatedBonusOrdersDetailsData)
    );
  } catch (error) {
    yield put(actions.getBonusOrdersDetails.error(error));
  }
}

function* getPharmacistIndividualExistingPromocodeSaga() {
  try {
    const response: PharmacistIndividualExistingPromocodeData = yield call(
      PromoCodeService.getPharmacistIndividualExistingPromoCode
    );

    yield put(
      actions.getPharmacistIndividualExistingPromoCode.success(response)
    );
  } catch (error) {
    yield put(actions.getPharmacistIndividualExistingPromoCode.error(error));
  }
}
function* getBonusOrdersDetailsHistorySaga({
  payload,
}: GetBonusOrdersDetailsHistorySagaProps) {
  try {
    const response: {
      result: BonusOrdersDetailsHistoryData;
    } = yield call(
      PromoCodeService.getBonusOrdersDetailsHistory,
      payload.phoneNumber,
      payload.isAdmin
    );

    const formatedBonusOrdersDetailsHistoryData =
      getFormatedBonusOrdersDetailsHistory(response.result);

    yield put(
      actions.getBonusOrdersDetailsHistory.success(
        formatedBonusOrdersDetailsHistoryData
      )
    );
  } catch (error) {
    yield put(actions.getBonusOrdersDetailsHistory.error(error));
  }
}
interface GetPharmacistBonusesBalanceSagaProps {
  type: string;
  payload: any;
}

function* getPharmacistBonusesBalanceSaga({
  payload,
}: GetPharmacistBonusesBalanceSagaProps) {
  try {
    const response: PharmacistBonusesBalanceData = yield call(
      PromoCodeService.getPharmacistBonusesBalance,
      payload.phoneNumber,
      payload.isAdmin
    );
    yield put(actions.getPharmacistBonusesBalance.success(response));
  } catch (error) {
    yield put(actions.getPharmacistBonusesBalance.error(error));
  }
}

interface GetNewPharmacistPromoCodeSagaProps {
  type: string;
  payload: {
    pharmacistPhoneNumber: string;
  };
}

interface getPromocodeStatusByCodeSagProps {
  type: string;
  payload: {
    code: string;
  };
}

interface PromocodePharmacistBlockedParam {
  type: string;
  payload: {
    data: {
      blocked: boolean;
    };
    promocode: string;
    postEffect: any;
  };
}

function* getNewPharmacistPromoCodeSaga({
  payload,
}: GetNewPharmacistPromoCodeSagaProps) {
  try {
    const response: {
      result: PharmacistIndividualExistingPromocodeData;
    } = yield call(
      PromoCodeService.getNewPharmacistPromoCode,
      payload.pharmacistPhoneNumber
    );

    yield put(actions.getNewPharmacistPromoCode.success(response.result));
  } catch (err) {
    actions.getNewPharmacistPromoCode.error(err);
  }
}

function* getPromocodeStatusByCodeSaga({
  payload,
}: getPromocodeStatusByCodeSagProps) {
  try {
    const response: {
      result: getPromocodeStatusByCodeData;
    } = yield call(PromoCodeService.getPromocodeStatusByCode, payload.code);

    yield put(actions.getPromocodeStatusByCode.success(response.result));
  } catch (err) {
    actions.getPromocodeStatusByCode.error(err);
  }
}

function* getPromocodePharmacistBlockedSaga({
  payload,
}: GetNewPharmacistPromoCodeSagaProps) {
  try {
    const response: PharmacistBlockedPromocodeData = yield call(
      PromoCodeService.getPromocodeBonusPharmacistBlocked,
      payload.pharmacistPhoneNumber
    );

    yield put(actions.getPromocodePharmacistBlocked.success(response));
  } catch (error) {
    yield put(actions.getPromocodePharmacistBlocked.error(error));
  }
}

export function* PromocodePharmacistBlockedSaga({
  payload,
}: PromocodePharmacistBlockedParam) {
  try {
    // @ts-ignore
    const response: any = yield call(
      PromoCodeService.promocodePharmacistBlocked,
      payload.promocode,
      payload.data
    );
    yield put(actions.promocodePharmacistBlocked.success(response.result));
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_SAVED,
        canBeClosed: true,
        type: 'success',
      })
    );
    if (payload.postEffect) {
      payload.postEffect();
    }
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.promocodePharmacistBlocked.error(error));
  }
}

function* withdrawPharmacistBonusesSaga({
  payload,
}: WithdrawPharmacistBonusesSagaProps) {
  try {
    const response: {
      status: string;
    } = yield call(PromoCodeService.withdrawPharmacistBonuses, payload.data);

    if (payload.postEffect) {
      payload.postEffect();
    }
    yield put(actions.getNewPharmacistPromoCode.success(response));
  } catch (err) {
    yield put(actions.withdrawPharmacistBonuses.success(err));
  }
}

export function* templatesPageWatcherSaga() {
  yield all([
    takeLatest(actions.generateTemplates.REQUEST, generateTemplatesSaga),
    takeLatest(actions.getTemplatesData.REQUEST, getTemplatesDataSaga),
    takeLatest(actions.generatePromoCodes.REQUEST, generatePromoCodesSaga),
    takeLatest(actions.getPromoCodesData.REQUEST, getPromoCodesDataSaga),
    takeLatest(actions.activatePromoCodes.REQUEST, activatePromoCodesSaga),
    takeLatest(
      actions.getPromocodePharmacistBlocked.REQUEST,
      getPromocodePharmacistBlockedSaga
    ),

    takeLatest(
      actions.promocodePharmacistBlocked.REQUEST,
      PromocodePharmacistBlockedSaga
    ),

    takeLatest(
      actions.getBonusOrdersDetails.REQUEST,
      getBonusOrdersDetailsSaga
    ),
    takeLatest(
      actions.getPharmacistIndividualExistingPromoCode.REQUEST,
      getPharmacistIndividualExistingPromocodeSaga
    ),
    takeLatest(
      actions.getBonusOrdersDetailsHistory.REQUEST,
      getBonusOrdersDetailsHistorySaga
    ),
    takeLatest(
      actions.getPharmacistBonusesBalance.REQUEST,
      getPharmacistBonusesBalanceSaga
    ),
    takeLatest(
      actions.getNewPharmacistPromoCode.REQUEST,
      getNewPharmacistPromoCodeSaga
    ),
    takeLatest(
      actions.withdrawPharmacistBonuses.REQUEST,
      withdrawPharmacistBonusesSaga
    ),

    takeLatest(
      actions.getPromocodeStatusByCode.REQUEST,
      getPromocodeStatusByCodeSaga
    ),
  ]);
}
