import { Formik, FormikHelpers } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as networkActions from '../../../NetworkPage/actions';
import { TagsTypeOptions } from '../../types';
import { SetActionType } from '../../../WebStockPage/types';
import * as sourceActions from '../../../SourcePage/actions';
import {
  UpdateTagSchema,
  getFormatedSourceTag,
  getUpdatedTag,
} from '../../../SourcePage/helpers';
import { PharmacyTag, TagFormProps } from '../../../SourcePage/types';
import * as Styled from './TagsComponent.styles';
import { AddSourceTagModal } from './components/AddSourceTagModal/AddSourceTagModal';
import CreateTagModal from './components/CreateTagModal';
import TagsConfirmationModal from './components/DeleteConfirmationModal/TagConfirmationModal';
import SetCreatedPharmacyTagModal from './components/SetCreatedPharmacyTagModal';
import CustomTextarea from '../../../../components/CustomTextarea';

interface TagsComponentProps {
  tagsType: string;
  sourceTagsData: PharmacyTag[];
  code: string;
  currentTags: PharmacyTag[];
  isAllTagsPage: boolean;
}

const TagsComponent = ({
  currentTags,
  tagsType,
  sourceTagsData,
  code,
  isAllTagsPage,
}: TagsComponentProps) => {
  const dispatch = useDispatch();
  const [editedItemIndex, setEditedItemIndex] = useState<null | number>(null);
  const [previousFormData, setPreviousFormData] = useState<
    PharmacyTag[] | null
  >(null);
  const [deletingTag, setDeletingTag] = useState<PharmacyTag | null>(null);
  const [isAddExistingTag, setIsAddExistingTag] = useState<boolean>(false);
  const [isTagCreating, setIsTagCreating] = useState<boolean>(false);
  const [unusedTags, setUnusedTags] = useState<PharmacyTag[]>([]);
  const [filteredTags, setFilteredTags] = useState<PharmacyTag[] | null>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [createdTagId, setCreatedTagId] = useState<number | null>(null);

  const { t } = useTranslation();

  const onEdit = (values: PharmacyTag[], index: number) => {
    setPreviousFormData(values);
    setEditedItemIndex(index);
  };

  const getUnusedTags = () => {
    const checkIsExist = (tag: PharmacyTag) =>
      currentTags.some((item) => item.id === tag.id);
    setUnusedTags(sourceTagsData.filter((obj) => !checkIsExist(obj)));
  };

  useEffect(() => {
    getUnusedTags();
  }, [sourceTagsData]);

  useEffect(() => {
    if (searchValue?.length > 3) {
      setFilteredTags(
        currentTags?.filter((el) =>
          el.name.toLowerCase().includes(searchValue.toLowerCase())
        ) || []
      );
    } else {
      setFilteredTags(null);
    }
  }, [searchValue]);

  const onCancelEdit = (
    e: React.MouseEvent<HTMLDivElement>,
    setValues: SetActionType
  ) => {
    if (previousFormData) {
      setValues(previousFormData);
    }
    setEditedItemIndex(null);
    e.preventDefault();
  };

  const onSubmitHandler = (
    values: PharmacyTag[],
    { setValues }: FormikHelpers<PharmacyTag[]>
  ) => {
    if (editedItemIndex || editedItemIndex === 0) {
      dispatch(
        sourceActions.updateTag.request({
          id: values[editedItemIndex].id,
          updatedTag: getUpdatedTag(values, editedItemIndex, tagsType),
          errorEffect: () => {
            if (previousFormData) {
              setValues(previousFormData);
            }
            setPreviousFormData(null);
          },
        })
      );
    }
    if (isAllTagsPage) {
      dispatch(sourceActions.getSourceTags.request({ tagsType }));
      setEditedItemIndex(null);
      return;
    }
    if (tagsType === TagsTypeOptions.source) {
      dispatch(sourceActions.getSource.request({ sourceCode: code }));
    } else {
      dispatch(networkActions.getNetwork.request({ networkCode: code }));
    }
    setEditedItemIndex(null);
  };

  const onCloseAddTagModal = () => {
    setIsAddExistingTag(!isAddExistingTag);
  };

  const handleSetTags = (addedTags: number[], metaValue: string) => {
    if (tagsType === TagsTypeOptions.source) {
      dispatch(
        sourceActions.setSourceTag.request({
          tagsList: addedTags,
          textMeta: metaValue,
          sourceCode: code,
          postEffect: () => {
            dispatch(sourceActions.getSource.request({ sourceCode: code }));
          },
        })
      );
    } else {
      dispatch(
        networkActions.setNetworkTags.request({
          tagsList: addedTags,
          textMeta: metaValue,
          networkCode: code,
          postEffect: () => {
            dispatch(networkActions.getNetwork.request({ networkCode: code }));
          },
        })
      );
    }
    onCloseAddTagModal();
  };

  const cancelDeleting = () => {
    setDeletingTag(null);
  };

  const onCloseTagCreatingModal = () => {
    setIsTagCreating(!isTagCreating);
  };

  const onDeleteSuccess = (tagId: number) => {
    if (tagsType === TagsTypeOptions.source) {
      dispatch(
        sourceActions.unmapSourceTag.request({
          tagId,
          sourceCode: code,
          postEffect: () => {
            dispatch(sourceActions.getSource.request({ sourceCode: code }));
          },
        })
      );
    } else {
      dispatch(
        networkActions.unmapNetworkTag.request({
          tagId,
          networkCode: code,
          postEffect: () => {
            dispatch(networkActions.getNetwork.request({ networkCode: code }));
          },
        })
      );
    }
    setDeletingTag(null);
  };

  const setCreatedTag = (meta: string) => {
    if (tagsType === TagsTypeOptions.network) {
      dispatch(
        networkActions.setNetworkTags.request({
          tagsList: [createdTagId],
          textMeta: meta,
          networkCode: code,
          postEffect: () => {
            dispatch(networkActions.getNetwork.request({ networkCode: code }));
          },
        })
      );

      dispatch(networkActions.getNetwork.request({ networkCode: code }));
    } else {
      dispatch(
        sourceActions.setSourceTag.request({
          tagsList: [createdTagId],
          textMeta: meta,
          sourceCode: code,
          postEffect: () => {
            dispatch(sourceActions.getSource.request({ sourceCode: code }));
          },
        })
      );
    }
    setCreatedTagId(null);
  };

  const onCreateTagHandler = ({
    name,
    color,
    description,
  }: {
    name: string;
    color: string;
    description: string;
  }) => {
    dispatch(
      sourceActions.createPharmacyTag.request({
        createdTag: getFormatedSourceTag(name, color, description, tagsType),
        postEffect: (id: number) => {
          if (!isAllTagsPage) {
            setCreatedTagId(id);
          } else {
            dispatch(sourceActions.getSourceTags.request({ tagsType }));
          }
        },
      })
    );
    setIsTagCreating(false);
  };

  const renderForm = ({
    values,
    errors,
    setFieldValue,
    handleSubmit,
    setValues,
  }: TagFormProps) => {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(values, setValues);
        }}
      >
        <Styled.TableBodyContainer>
          {values.map((tag: PharmacyTag, index: number) => (
            <div style={{ width: '100%', display: 'flex' }}>
              <div className="table-cell table-cell-md">
                <input
                  value={tag.name}
                  onChange={(e) =>
                    setFieldValue(`${index}.name`, e.target.value)
                  }
                  name="name"
                  disabled={editedItemIndex !== index}
                />
                {errors[index]?.name && (
                  <div className="error-msg">{errors[index]?.name}</div>
                )}
              </div>
              <div className="table-cell table-cell-sm">
                <input
                  style={{
                    color: `#${values[index].color}`,
                  }}
                  value={tag.color}
                  onChange={(e) =>
                    setFieldValue(`${index}.color`, e.target.value)
                  }
                  name="color"
                  disabled={editedItemIndex !== index}
                />
                <div />
                {errors[index]?.color && (
                  <div className="error-msg">{errors[index]?.color}</div>
                )}
              </div>
              <div className="table-cell table-cell-lg">
                <Styled.CustomTextareaContainer>
                  <textarea
                    className="textarea  nonResizable"
                    value={tag.description}
                    onChange={(e) =>
                      setFieldValue(`${index}.description`, e.target.value)
                    }
                    name="description"
                    disabled={editedItemIndex !== index}
                  />
                </Styled.CustomTextareaContainer>

                {errors[index]?.description && (
                  <div className="error-msg">{errors[index]?.description}</div>
                )}
              </div>
              {!isAllTagsPage && (
                <div className="table-cell table-cell-sm">
                  {tag.meta || 'не указана'}
                </div>
              )}
              <div className="table-cell table-cell-sm">{tag.id}</div>
              <div className="table-cell table-cell-md">
                {editedItemIndex !== index ? (
                  <div className="action-container">
                    <Styled.EditBtn
                      onClick={() => onEdit(values, index)}
                      className={`
                    ${
                      (editedItemIndex || editedItemIndex === 0) &&
                      editedItemIndex !== index &&
                      'disabled'
                    }`}
                    >
                      {t('PharmacyTagsPage.TAGS_COMPONENT.FORM.EDIT')}
                    </Styled.EditBtn>
                    {!isAllTagsPage && (
                      <Styled.EditBtn
                        className={`${
                          (editedItemIndex || editedItemIndex === 0) &&
                          editedItemIndex !== index &&
                          'disabled'
                        }`}
                        onClick={() => setDeletingTag(tag)}
                      >
                        {t('PharmacyTagsPage.TAGS_COMPONENT.FORM.UNMAP')}
                      </Styled.EditBtn>
                    )}
                  </div>
                ) : (
                  <div className="action-container">
                    <button
                      className={`submit-changes-btn ${
                        Object.keys(errors).length > 0 && 'disabled'
                      }`}
                      type="submit"
                    >
                      {t('PharmacyTagsPage.TAGS_COMPONENT.FORM.SAVE')}
                    </button>
                    <Styled.EditBtn onClick={(e) => onCancelEdit(e, setValues)}>
                      {t('PharmacyTagsPage.TAGS_COMPONENT.FORM.CANCEL')}
                    </Styled.EditBtn>
                  </div>
                )}
              </div>
            </div>
          ))}
        </Styled.TableBodyContainer>
      </form>
    );
  };

  const tableHeader = (
    <Styled.TagsTableHeaderContainer>
      <div className="header-row header-row-md">
        {t('PharmacyTagsPage.TAGS_COMPONENT.FORM.NAME')}
      </div>
      <div className="header-row header-row-sm">
        {t('PharmacyTagsPage.TAGS_COMPONENT.FORM.COLOR')}
      </div>
      <div className="header-row header-row-lg">
        {t('PharmacyTagsPage.TAGS_COMPONENT.FORM.DESCRIPTION')}
      </div>
      {!isAllTagsPage && (
        <div className="header-row header-row-sm">
          {t('PharmacyTagsPage.TAGS_COMPONENT.FORM.META')}
        </div>
      )}
      <div className="header-row header-row-sm">
        {t('PharmacyTagsPage.TAGS_COMPONENT.FORM.ID')}
      </div>
      <div className="header-row header-row-md">
        {t('PharmacyTagsPage.TAGS_COMPONENT.FORM.ACTIONS')}
      </div>
    </Styled.TagsTableHeaderContainer>
  );

  return (
    <Styled.TagsComponentContainer>
      <p className="title">{t('PharmacyTagsPage.TAGS_COMPONENT.TITLE')}</p>

      <Styled.TagsSearchContainer>
        <input
          type="text"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </Styled.TagsSearchContainer>

      <Styled.AddTagContainer>
        <Styled.AddTag onClick={() => setIsTagCreating(!isTagCreating)}>
          {t('PharmacyTagsPage.TAGS_COMPONENT.CREATE_NEW_TAG')}
        </Styled.AddTag>

        {!isAllTagsPage && (
          <Styled.AddTag onClick={() => setIsAddExistingTag(!isAddExistingTag)}>
            {t('PharmacyTagsPage.TAGS_COMPONENT.ADD_EXISTING_TAG')}
          </Styled.AddTag>
        )}
      </Styled.AddTagContainer>
      {currentTags.length > 0 || (filteredTags && filteredTags?.length > 0) ? (
        <>
          {tableHeader}
          <Formik
            initialValues={filteredTags || currentTags}
            validationSchema={UpdateTagSchema}
            enableReinitialize
            onSubmit={(values: PharmacyTag[], setValues) =>
              onSubmitHandler(values, setValues)
            }
          >
            {renderForm}
          </Formik>
        </>
      ) : (
        <div className="highlighted-text">
          {t('PharmacyTagsPage.TAGS_COMPONENT.EMPTY_TAG_TABLE')}
        </div>
      )}

      {searchValue?.length > 3 &&
        (filteredTags?.length === 0 || !filteredTags) && (
          <div>{t('PharmacyTagsPage.TAGS_COMPONENT.EMPTY_SEARCH_RESULT')}</div>
        )}

      {deletingTag && (
        <TagsConfirmationModal
          tagType={tagsType}
          code={code}
          onDeleteSuccess={(tagId) => onDeleteSuccess(tagId)}
          onCloseHandler={cancelDeleting}
          tagValues={deletingTag}
          isDelete
        />
      )}
      {isAddExistingTag && (
        <AddSourceTagModal
          tagsType={tagsType}
          handleSetTags={(addedTags: number[], metaValue) =>
            handleSetTags(addedTags, metaValue)
          }
          onCloseAddTagModal={onCloseAddTagModal}
          tagsList={unusedTags}
          code={code}
        />
      )}
      {createdTagId && (
        <SetCreatedPharmacyTagModal
          onClose={() => setCreatedTagId(null)}
          setCreatedTag={setCreatedTag}
        />
      )}
      {isTagCreating && (
        <CreateTagModal
          onCloseTagCreatingModal={onCloseTagCreatingModal}
          onCreateTagHandler={(values) => onCreateTagHandler(values)}
        />
      )}
    </Styled.TagsComponentContainer>
  );
};

export default TagsComponent;
