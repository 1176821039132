import { Event, OrderHistoryEvent } from './types';
import { StatusResponse } from '../OrdersPage/types';
import { formatTime } from '../../global/helpers';
import { OrderStatusesNewResponse } from '../OrderPage/types';

export const mappedHistoryEvents = (
  events: OrderHistoryEvent[],
  statuses: OrderStatusesNewResponse,
  statusesPharmacy: StatusResponse
) => {
  return events
    .map((event) => {
      const prevStatus = (
        event.event === Event.CHANGE_STATUS
          ? statuses.result.internal_statuses
          : statusesPharmacy.result.internal_statuses
      ).find((status) => status.code === event.previous);
      const currStatus = (
        event.event === Event.CHANGE_STATUS
          ? statuses.result.internal_statuses
          : statusesPharmacy.result.internal_statuses
      ).find((status) => status.code === event.current);

      return {
        eventType: event.event_type,
        event: event.event,
        role: event.role,
        phone: event.phone,
        collector: event.collector ? event.collector : '',
        previousTimer:
          event.event === Event.UPDATE_TIMER ? event.previous : null,
        currentTimer: event.event === Event.UPDATE_TIMER ? event.current : null,
        commentTimerCurrent:
          event.event === Event.UPDATE_TIMER_COMMENT ? event.current : null,
        commentTimerPrevious:
          event.event === Event.UPDATE_TIMER_COMMENT ? event.previous : null,
        createdAt: formatTime(event.created_at, true),
        previousPaymentMethod:
          event.event === Event.CHANGED_PAYMENT_METHOD ? event.previous : null,
        previousDeliveryMethod:
          event.event === Event.CHANGED_DELIVERY_METHOD ? event.previous : null,
        previousChangedPaymentMethod:
          event.event === Event.CHANGED_ORDER_PAYMENT_STATUS
            ? event.previous
            : null,

        currentPaymentMethod:
          event.event === Event.CHANGED_PAYMENT_METHOD ? event.current : null,
        currentDeliveryMethod:
          event.event === Event.CHANGED_DELIVERY_METHOD ? event.current : null,
        currentChangedPaymentMethod:
          event.event === Event.CHANGED_ORDER_PAYMENT_STATUS
            ? event.current
            : null,
        currentDescription: Event.CURRENT_DESCRIPTION
          ? event.current_description
          : null,
        currentAddress: Event.CREATE_ORDER ? event.current : null,
        previousChangedDeliveryPaymentMethod:
          event.event === Event.CHANGED_DELIVERY_PAYMENT_STATUS
            ? event.previous
            : null,

        currentChangedDeliveryPaymentMethod:
          event.event === Event.CHANGED_DELIVERY_PAYMENT_STATUS
            ? event.current
            : null,
        diffMsTimer:
          new Date().getTime() - new Date(event.created_at).getTime(),
        previousStatus: prevStatus
          ? {
              code: prevStatus.code,
              name: prevStatus.name,
              color: prevStatus.color,
            }
          : null,
        currentStatus: currStatus
          ? {
              code: currStatus.code,
              name: currStatus.name,
              color: currStatus.color,
            }
          : null,
        currentPharmacy:
          event.event === Event.CHANGED_SOURCE ? event.current : null,
        previousPharmacy:
          event.event === Event.CHANGED_SOURCE ? event.previous : null,
        cartHistory:
          event.event === Event.CHANGED_TOTAL_SUM ? 'Изменение корзины' : null,
      };
    })
    .filter(
      (event) =>
        event.event === Event.CREATE_ORDER ||
        event.event === Event.CHANGE_STATUS ||
        event.event === Event.CHANGE_STATUS_PHARMACY ||
        event.event === Event.UPDATE_TIMER ||
        event.event === Event.UPDATE_TIMER_COMMENT ||
        event.event === Event.CHANGED_SOURCE ||
        event.event === Event.CHANGED_SOURCE ||
        event.event === Event.CHANGED_PAYMENT_METHOD ||
        event.event === Event.CHANGED_DELIVERY_METHOD ||
        event.event === Event.CHANGED_DELIVERY_PAYMENT_STATUS ||
        event.event === Event.CHANGED_ORDER_PAYMENT_STATUS ||
        event.event === Event.CHANGED_TOTAL_SUM
    )
    .sort((a, b) => b.diffMsTimer - a.diffMsTimer);
};
