import { asyncActionsCreator } from '../../global/redux';
import * as constants from './constants';
import * as actions from './actions';

export const generateTemplates = asyncActionsCreator(
  constants.GENERATE_TEMPLATES
);
export const generatePromoCodes = asyncActionsCreator(
  constants.GENERATE_PROMO_CODES
);

export const activatePromoCodes = asyncActionsCreator(
  constants.ACTIVATE_PROMO_CODES
);
export const getPromocodePharmacistBlocked = asyncActionsCreator(
  constants.GET_PRPMO_CODE_PHARMACIST_BLOCKED
);

export const getPromocodeStatusByCode = asyncActionsCreator(
  constants.GET_PRPMO_CODE_STATUS_BY_CODE
);

export const promocodePharmacistBlocked = asyncActionsCreator(
  constants.PRPMO_CODE_PHARMACIST_BLOCKED
);

export const getBonusOrdersDetails = asyncActionsCreator(
  constants.GET_BONUS_ORDERS_DETAILS
);

export const getBonusOrdersDetailsHistory = asyncActionsCreator(
  constants.GET_BONUS_ORDERS_DETAILS_HISTORY
);

export const getPharmacistBonusesBalance = asyncActionsCreator(
  constants.GET_PHARMACIST_BONUSES_BALANCE
);

export const getPromoCodesData = asyncActionsCreator(constants.GET_PROMO_CODES);

export const getTemplatesData = asyncActionsCreator(constants.GET_TEMPLATES);

export const getPharmacistIndividualExistingPromoCode = asyncActionsCreator(
  constants.GET_PHARMACIST_INDIVIDUAL_EXISTING_PROMO_CODE
);
export const getNewPharmacistPromoCode = asyncActionsCreator(
  constants.GET_NEW_PHARMACIST_PROMO_CODE
);

export const withdrawPharmacistBonuses = asyncActionsCreator(
  constants.WITHDRAW_PHARMACIST_BONUSES
);
