import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './CompletedConfirmationModal.styles';
import ModalWindow from '../../../../components/ModalWindow';
import Button from '../../../../components/Button';

interface ModalProps {
  onClose: () => void;
  setStatusCompleted: () => void;
}

const CompletedConfirmationModal = ({
  onClose,
  setStatusCompleted,
}: ModalProps) => {
  const { t } = useTranslation();

  return (
    <ModalWindow onClose={onClose}>
      <Styled.MainConfirmation>
        <div className="main-title">{t('OrderPage.CompletedModal.TITLE')}</div>
        <div className="btn-block">
          <Button
            text={t('OrderPage.CompletedModal.CANCEL')}
            variant="outlined"
            onClick={onClose}
          />
          <div className="buttonCancel">
            <Button
              text={t('OrderPage.CompletedModal.CONFIRM')}
              onClick={setStatusCompleted}
            />
          </div>
        </div>
      </Styled.MainConfirmation>
    </ModalWindow>
  );
};

export default CompletedConfirmationModal;
