import { createAction } from '@reduxjs/toolkit';
import { asyncActionsCreator } from '../../global/redux';
import * as constants from './constants';

export const getProductsUnAtt = asyncActionsCreator(
  constants.GET_PRODUCTS_UN_ATTACHED
);
export const getProductsAtt = asyncActionsCreator(
  constants.GET_PRODUCTS_ATTACHED
);
export const getProductsAttALL = asyncActionsCreator(
  constants.GET_PRODUCTS_ATTACHED_ALL
);
export const getProductsBlock = asyncActionsCreator(
  constants.GET_PRODUCTS_BLOCKED
);
export const getProductsVer = asyncActionsCreator(
  constants.GET_PRODUCTS_VERIFICATION
);
export const getProductsAutomapping = asyncActionsCreator(
  constants.GET_PRODUCTS_AUTOMAPPING
);
export const getProductsDybl = asyncActionsCreator(constants.GET_PRODUCTS_DYBL);
export const getProductsRefresh = asyncActionsCreator(
  constants.GET_PRODUCTS_REFRESH
);

export const getProductsSearchUnattached = asyncActionsCreator(
  constants.GET_PRODUCTS_SEARCH_UNATTACHED
);
export const getProductsSearchAttached = asyncActionsCreator(
  constants.GET_PRODUCTS_SEARCH_ATTACHED
);
export const getProductsSearchBlocked = asyncActionsCreator(
  constants.GET_PRODUCTS_SEARCH_BLOCKED
);
export const getProductsSearchOnVerification = asyncActionsCreator(
  constants.GET_PRODUCTS_SEARCH_ON_VERIFICATION
);

export const getProductsSearchDybl = asyncActionsCreator(
  constants.GET_PRODUCTS_SEARCH_DYBL
);

export const getProductsSearchAutomapping = asyncActionsCreator(
  constants.GET_PRODUCTS_SEARCH_AUTOMAPPING
);

export const getNetworks = asyncActionsCreator(constants.GET_NETWORKS);
export const getTags = asyncActionsCreator(constants.GET_TAGS);
export const deleteProductTag = asyncActionsCreator(
  constants.DELETE_PRODUCT_TAG
);
export const setTagsToProduct = asyncActionsCreator(
  constants.SET_TAGS_TO_PRODUCT
);
export const setNetwork = createAction<{ name: string; code: string }>(
  constants.SET_NETWORK
);
export const setTag = createAction<{ tagIds: string[] }>(constants.SET_TAG);

export const clearProductsSearchUnattached = createAction(
  constants.CLEAR_PRODUCTS_SEARCH_UNATTACHED
);
export const clearProductsSearchAttached = createAction(
  constants.CLEAR_PRODUCTS_SEARCH_ATTACHED
);

export const clearProductsAttached = createAction(
  constants.CLEAR_PRODUCTS_ATTACHED
);
export const clearProductsUnAttached = createAction(
  constants.CLEAR_PRODUCTS_UNATTACHED
);
export const clearProductsOnVer = createAction(
  constants.CLEAR_PRODUCTS_ON_VERIFICATION
);
export const clearProductsAutomapping = createAction(
  constants.CLEAR_PRODUCTS_AUTOMAPPING
);
export const clearProductsBlocked = createAction(
  constants.CLEAR_PRODUCTS_BLOCKED
);
export const clearProductsSearchBlocked = createAction(
  constants.CLEAR_PRODUCTS_SEARCH_BLOCKED
);
export const clearProductsSearchOnVer = createAction(
  constants.CLEAR_PRODUCTS_SEARCH_ON_VERIFICATION
);
export const clearProductsSearchDybl = createAction(
  constants.CLEAR_PRODUCTS_SEARCH_DYBL
);
export const clearProductsSearchAutomapping = createAction(
  constants.CLEAR_PRODUCTS_SEARCH_AUTOMAPPING
);

export const setSelectedProduct = createAction(constants.SET_SELECTED_PRODUCT);
export const openNetworks = createAction(constants.OPEN_NETWORKS);
export const closeNetworks = createAction(constants.CLOSE_NETWORKS);
