export const GENERATE_TEMPLATES = 'GENERATE_TEMPLATES';
export const GENERATE_PROMO_CODES = 'GENERATE_PROMO_CODES';
export const GET_PROMO_CODES = 'GET_PROMO_CODES';
export const GET_TEMPLATES = 'GET_TEMPLATES';
export const ACTIVATE_PROMO_CODES = 'ACTIVATE_PROMO_CODES';
export const GET_BONUS_ORDERS_DETAILS = 'GET_BONUS_ORDERS_DETAILS';
export const GET_BONUS_ORDERS_DETAILS_HISTORY =
  'GET_BONUS_ORDERS_DETAILS_HISTORY';
export const GET_PHARMACIST_INDIVIDUAL_EXISTING_PROMO_CODE =
  'GET_PHARMACIST_INDIVIDUAL_EXISTING_PROMO_CODE';
export const GET_PHARMACIST_BONUSES_BALANCE = 'GET_PHARMACIST_BONUSES_BALANCE';
export const GET_NEW_PHARMACIST_PROMO_CODE = 'GET_NEW_PHARMACIST_PROMO_CODE';
export const WITHDRAW_PHARMACIST_BONUSES = 'WITHDRAW_PHARMACIST_BONUSES';
export const GET_PRPMO_CODE_PHARMACIST_BLOCKED =
  'GET_PRPMO_CODE_PHARMACIST_BLOCKED';
export const GET_PRPMO_CODE_STATUS_BY_CODE = 'GET_PRPMO_CODE_STATUS_BY_CODE';

export const PRPMO_CODE_PHARMACIST_BLOCKED = ' PRPMO_CODE_PHARMACIST_BLOCKED';
