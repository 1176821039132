import axios from 'axios';

export class OrderHistoryService {
  static API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/api/v2/`;

  static getOrderHistory = async (orderId: string, createdAt: string) => {
    const response = await axios({
      method: 'get',
      url: `${OrderHistoryService.API_BASE_URL}orders/${orderId}/history?from=${createdAt}`,
    });
    return response.data;
  };
}
