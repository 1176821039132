import styled from 'styled-components';

export const ProductsSearchContainer = styled('div')`
  width: 100%;
  display: contents;

  .search-bar {
    width: 470px;
  }

  .product-loading,
  .search-no-results {
    padding: 16px;
  }
`;

export const ProductsItemMainContainer = styled('div')`
  max-width: 1216px;
`;

export const ProductsSearchItemContainer = styled('div')`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  grid-gap: 30px;
  padding: 30px 0;
`;
