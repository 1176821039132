import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import * as Styled from './OrdersTable.styles';
import * as actions from '../../actions';
import { OrderTableData, UserRole } from '../../types';
import OrdersTableRow from './components/OrdersTableRow';
import OrderDetailedModal from '../../../../components/OrderDetailedModal';
import OrderCancelModal from '../../../../components/OrderCancelModal';
import ModalWindow from '../../../../components/ModalWindow';
import {
  getFromLocalStorage,
  setToLocalStorage,
} from '../../../../global/helpers';
import { selectors } from '../../reducer';
import FilterByStatus from './components/FilterByStatus/FilterByStatus';
import FilterByPayment from './components/FilterByPayment';
import FilterBySource from './components/FilterBySource';
import { selectors as selectorsOrder } from '../../../OrderPage/reducer';
import { selectors as selectorsLogin } from '../../../LoginPage/reducer';
import { selectors as magnumSelectors } from '../../../../components/ActivePharmacistBlock/reducer';
import FilterByDelivery from './components/FilterByDelivery/FilterByDelivery';
import arrow from '../../../../global/media/arrow-breadCrumbs.svg';
import FilterByPaymentType from './components/FilterByPaymentType';
import { StatusType } from '../../../../global/types';

interface OrdersTableProps {
  data: OrderTableData[] | null;
  setStatus: (status: string) => void;
  setSource?: false | ((source: string) => void);
  setPaymentType?: false | ((payment: string) => void);
  setPayment?: false | ((payment: boolean | string) => void);
  setDelivery?: false | ((delivery: string) => void);
  onOrderClick?: (isOpen: boolean) => void;
  paymentOrders: string | boolean;
  onSetOrderPharmacist?: (collector: { name: string; phone: string }) => void;
  dataByTab?: OrderTableData[] | null;
}

const OrdersTable = ({
  data,
  setStatus,
  setPayment,
  setSource,
  setDelivery,
  setPaymentType,
  onOrderClick,
  paymentOrders,
  onSetOrderPharmacist,
  dataByTab,
}: OrdersTableProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const token = getFromLocalStorage('accessToken');
  const userRole = getFromLocalStorage('role');
  const role =
    getFromLocalStorage('role') === 'manager' ? 'network' : 'pharmacy';

  const orderId = useSelector(selectors.orderId);
  const dataUser = useSelector(selectorsLogin.userData);
  const selectedActivePharmacist = useSelector(
    selectorsOrder.selectedActivePharmacist
  );
  const activeUsers = useSelector(magnumSelectors.activeUsersData)?.filter(
    (e) => e.active
  );

  const [searchByName, setSearchByName] = useState<string>('');
  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const [cancelOrder, setCancelOrder] = useState<null | OrderTableData>(null);
  const [selectedStatus, setSelectedStatus] = useState<string>(
    t('OrdersPage.OrdersTable.headerTitles.STATUS')
  );

  const [filterStatusOpen, setFilterStatusOpen] = useState<boolean>(false);
  const [filterPaymentOpen, setFilterPaymentOpen] = useState<boolean>(false);
  const [filterPaymentTypeOpen, setFilterPaymentTypeOpen] =
    useState<boolean>(false);
  const [filterDeliveryOpen, setFilterDeliveryOpen] = useState<boolean>(false);
  const [isFilterPharmacyOpen, setIsFilterPharmacyOpen] =
    useState<boolean>(false);

  const handleSetStatus = (statusName: string, code: string, color: string) => {
    // @ts-ignore
    setSelectedStatus(statusName);
    setStatus(code);
    setFilterStatusOpen(false);
  };

  const handleSetPayment = (payment: boolean | string) => {
    setPayment && setPayment(payment);
  };

  const handleSetPaymentType = (payment: string) => {
    setPaymentType && setPaymentType(payment);
  };

  const handleSetDelivery = (delivery: string) => {
    setDelivery && setDelivery(delivery);
  };

  const handleSetSource = (source: string) => {
    setSource && setSource(source);
    setIsFilterPharmacyOpen(false);
  };

  const handleSetAllStatus = () => {
    setSelectedStatus(t('OrdersPage.OrdersTable.headerTitles.STATUS'));
    setStatus('');
    setFilterStatusOpen(false);
  };

  const tableHeaderClass =
    userRole === UserRole.OPERATOR ||
    userRole === UserRole.ADMIN ||
    userRole === UserRole.HEAD_OPERATOR
      ? 'table__header-cell-operator'
      : 'table__header-cell';

  const TableHeader = (
    // @ts-ignore
    <Styled.OrdersHead>
      <Styled.OrdersTableHeaderRow>
        <th className={`${tableHeaderClass} table__header-timer-cell`}>
          <span id="timer">
            {t('OrdersPage.OrdersTable.headerTitles.TIMER')}
          </span>
        </th>
        <th
          className={`${tableHeaderClass} ${
            (userRole === UserRole.MANAGER ||
              userRole === UserRole.PHARMACY_MANAGER) &&
            'table__header-order'
          } ${
            userRole !== UserRole.OPERATOR &&
            userRole !== UserRole.ADMIN &&
            userRole !== UserRole.HEAD_OPERATOR &&
            (isMobile ? 'table__header-order' : 'table__header-order-large')
          }`}
        >
          <span id="order_number">
            {t('OrdersPage.OrdersTable.headerTitles.ORDER')}
          </span>
        </th>
        {!isMobile && (
          <>
            <th
              className={`${tableHeaderClass} ${
                (userRole === UserRole.MANAGER ||
                  userRole === UserRole.PHARMACY_MANAGER) &&
                'table__header-created-at'
              } ${
                userRole !== UserRole.OPERATOR &&
                userRole !== UserRole.ADMIN &&
                userRole !== UserRole.HEAD_OPERATOR &&
                'table__header-created-at-large'
              }`}
            >
              <span id="date">
                {t('OrdersPage.OrdersTable.headerTitles.CREATED_AT')}
              </span>
            </th>
            {(userRole === UserRole.OPERATOR ||
              userRole === UserRole.ADMIN ||
              userRole === UserRole.HEAD_OPERATOR) && (
              <th className={`${tableHeaderClass} table__header-user-source`}>
                {t('OrdersPage.OrdersTable.headerTitles.USER_SOURCE')}
              </th>
            )}
            {(userRole === UserRole.OPERATOR ||
              userRole === UserRole.ADMIN ||
              userRole === UserRole.HEAD_OPERATOR) && (
              <th className="table__header-cell table__header-phone-cell">
                {t('OrdersPage.OrdersTable.headerTitles.PHONE')}
              </th>
            )}
            {(userRole === UserRole.OPERATOR ||
              userRole === UserRole.ADMIN ||
              userRole === UserRole.HEAD_OPERATOR ||
              userRole === UserRole.PHARMACY_MANAGER ||
              userRole === UserRole.MANAGER) && (
              <th
                className={`table__header-cell table__header-source ${
                  (userRole === UserRole.OPERATOR ||
                    userRole === UserRole.ADMIN ||
                    userRole === UserRole.HEAD_OPERATOR) &&
                  'table__header-source-operator'
                }`}
              >
                <span>{t('OrdersPage.OrdersTable.headerTitles.SOURCE')}</span>
                <div
                  onClick={() => setIsFilterPharmacyOpen(true)}
                  className="img"
                >
                  <img src={arrow} alt="arrow" />
                </div>
                {isFilterPharmacyOpen && (
                  <ModalWindow
                    onClose={() => {
                      setIsFilterPharmacyOpen(false);
                    }}
                  >
                    <input
                      className="input-status-filter"
                      type="text"
                      placeholder={t('ProductsPage.NETWORKS_MODAL.PLACEHOLDER')}
                      onChange={({ target: { value } }) =>
                        setSearchByName(value)
                      }
                    />

                    <div className="status-filter-accent">
                      <FilterBySource
                        isPharmacy
                        onClose={() => {}}
                        onSourceCLick={handleSetSource}
                        operatorFilter={
                          userRole === UserRole.OPERATOR ||
                          userRole === UserRole.HEAD_OPERATOR ||
                          userRole === UserRole.ADMIN
                        }
                        searchStr={searchByName}
                      />
                    </div>
                  </ModalWindow>
                )}
              </th>
            )}
            {(userRole === UserRole.OPERATOR ||
              userRole === UserRole.ADMIN ||
              userRole === UserRole.HEAD_OPERATOR) && (
              <th className={`${tableHeaderClass} `}>
                {t('OrdersPage.OrdersTable.headerTitles.COLLECTOR')}
              </th>
            )}
            <th
              className={`${tableHeaderClass} table__header-cell-operator-delivery`}
              onClick={() => setFilterDeliveryOpen(!filterDeliveryOpen)}
            >
              <span id="delivery_type">
                {' '}
                {t('OrdersPage.OrdersTable.headerTitles.DELIVERY')}
              </span>
              <div className="img">
                <img src={arrow} alt="arrow" />
              </div>

              {filterDeliveryOpen && (
                <div className="status-filter">
                  <FilterByDelivery
                    onClose={() => setFilterDeliveryOpen(false)}
                    onDeliveryCLick={handleSetDelivery}
                  />
                </div>
              )}
            </th>
            {(userRole === UserRole.ADMIN ||
              userRole === UserRole.OPERATOR ||
              userRole === UserRole.HEAD_OPERATOR) && (
              <th className={`${tableHeaderClass} padding-top`}>
                <span id="status-delivery">Статус курьерской службы</span>
              </th>
            )}
          </>
        )}
        <th
          className={`${tableHeaderClass} table__header-status ${
            (userRole === UserRole.ADMIN ||
              userRole === UserRole.OPERATOR ||
              userRole === UserRole.HEAD_OPERATOR) &&
            'padding-top'
          } `}
          onClick={() => setFilterStatusOpen(!filterStatusOpen)}
        >
          <span id="status">{selectedStatus}</span>
          <div className="img">
            <img src={arrow} alt="arrow" />
          </div>
          {filterStatusOpen && (
            <div className="status-filter">
              <FilterByStatus
                onClose={() => setFilterStatusOpen(false)}
                onStatusClick={handleSetStatus}
                onAllStatusClick={handleSetAllStatus}
              />
            </div>
          )}
        </th>
        {!isMobile &&
          (userRole === UserRole.PHARMACIST ||
            userRole === UserRole.MANAGER ||
            userRole === UserRole.PHARMACY_MANAGER) && (
            <th
              className={`${tableHeaderClass} table__header-payment-type`}
              onClick={() => setFilterPaymentTypeOpen(!filterPaymentTypeOpen)}
            >
              <span id="payment_type">
                {t('OrdersPage.OrdersTable.headerTitles.PAYMENT')}
              </span>
              <div className="img">
                <img src={arrow} alt="arrow" />
              </div>
              {filterPaymentTypeOpen && (
                <div className="status-filter">
                  <FilterByPaymentType
                    onClose={() => setFilterPaymentTypeOpen(false)}
                    onDeliveryCLick={handleSetPaymentType}
                  />
                </div>
              )}
            </th>
          )}
        <th
          className={`${tableHeaderClass} table__header-payment`}
          onClick={() => setFilterPaymentOpen(!filterPaymentOpen)}
        >
          <span
            id="payment_status"
            className={`${paymentOrders !== '' && 'active-filter'}`}
          >
            {t('OrdersPage.OrdersTable.headerTitles.STATUS_PAYMENT')}
          </span>
          <div className="img">
            <img src={arrow} alt="arrow" />
          </div>
          {filterPaymentOpen && (
            <div className="status-filter">
              <FilterByPayment
                onClose={() => setFilterPaymentOpen(false)}
                onPaymentCLick={handleSetPayment}
              />
            </div>
          )}
        </th>
        {!isMobile && (
          <>
            <th className={`${tableHeaderClass} table__header-amount-cell`}>
              {t('OrdersPage.OrdersTable.headerTitles.AMOUNT')}
            </th>

            {data &&
              data.every((order: OrderTableData) =>
                order.source.includes('food')
              ) && (
                <th className={`${tableHeaderClass} table__header-amount-cell`}>
                  {t('OrdersPage.OrdersTable.headerTitles.COMMENT')}
                </th>
              )}
          </>
        )}
      </Styled.OrdersTableHeaderRow>
      {/* @ts-ignore */}
    </Styled.OrdersHead>
  );

  const handleOrderClick = (order: OrderTableData) => {
    dispatch(actions.setOrderId(order.id));
    setToLocalStorage('selectedOrderId', order.id);
    if (
      userRole === UserRole.ADMIN ||
      userRole === UserRole.OPERATOR ||
      userRole === UserRole.HEAD_OPERATOR
    ) {
      history.push(`${history.location.pathname}/${order.id}`);
    } else if (
      (userRole === UserRole.PHARMACIST &&
        order.status !== StatusType.CANCELED &&
        order.status !== StatusType.COMPLETED) ||
      ((userRole === UserRole.MANAGER ||
        userRole === UserRole.PHARMACY_MANAGER) &&
        dataUser?.isCollector &&
        order.status !== StatusType.CANCELED &&
        order.status !== StatusType.COMPLETED)
    ) {
      if (activeUsers && activeUsers.length === 1) {
        onSetOrderPharmacist &&
          onSetOrderPharmacist({
            // @ts-ignore
            collector: {
              name: activeUsers[0].name,
              phone: activeUsers[0].phone,
            },
          });
        selectedActivePharmacist && history.push(`/orders/${order.id}`);
      } else {
        order.collector.phone
          ? history.push(`${history.location.pathname}/${order.id}`)
          : onOrderClick && onOrderClick(true);
      }
    } else history.push(`${history.location.pathname}/${order.id}`);
  };

  const handleCloseOrderDetailedModal = () => {
    dispatch(actions.clearOrderId());
    dispatch(actions.clearSelectedOrder());
    dispatch(actions.getOrders.request({ role, status: '' }));
    setOpenDetail(false);
  };

  const handleCancelOrder = (data: OrderTableData | null) => {
    // @ts-ignore
    setCancelOrder(data);
  };

  const handleSubmitCancelOrder = (message: string, id: string) => {
    setCancelOrder(null);
    dispatch(
      actions.cancelOrder.request({
        message,
        id,
        token,
        postEffect: () =>
          dispatch(actions.setSelectedOrder.request({ role, id: orderId })),
      })
    );
  };

  const handleDeclineCancelOrder = (data: any) => {
    setCancelOrder(null);
  };

  return (
    <>
      <Styled.OrdersTable>
        {TableHeader}
        {data && (
          <tbody
            style={{ borderTop: '1px solid ${baseTheme.colors.stroking' }}
            id="table"
          >
            {data.map((item) => (
              <OrdersTableRow
                dataByTab={dataByTab}
                onRowClick={handleOrderClick}
                data={item}
                key={item.id}
                dataOrders={data}
              />
            ))}
          </tbody>
        )}
      </Styled.OrdersTable>
      {openDetail && (
        <OrderDetailedModal
          onClose={handleCloseOrderDetailedModal}
          onOrderCancel={handleCancelOrder}
        />
      )}
      {cancelOrder && (
        <OrderCancelModal
          data={cancelOrder}
          onDecline={handleDeclineCancelOrder}
          onSubmit={handleSubmitCancelOrder}
        />
      )}
    </>
  );
};

export default OrdersTable;
