import axios from 'axios';

export class ActivePharmacistService {
  static API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/api/v1/`;

  static getAllUsers = async (sourceCode: string) => {
    const response = await axios({
      method: 'get',
      url: `${ActivePharmacistService.API_BASE_URL}users/schedule`,
    });
    return response.data;
  };

  static updatedActiveUsers = async (
    sourceCode: string,
    networkCode: string,
    phones: string[]
  ) => {
    const response = await axios({
      method: 'post',
      url: `${ActivePharmacistService.API_BASE_URL}users/schedule`,
      data: {
        network_code: networkCode,
        source_code: sourceCode,
        phones,
      },
    });
    return response.data;
  };
}
