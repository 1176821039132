import { t } from 'i18next';

const BestBeforeProductsTable = ({ el, data, setIsModalOpen }: any) => {
  return (
    <tr>
      <td className="product-item item-column">
        <div style={{ width: '100%', display: 'flex' }}>
          <div className="table-cell table-cell-lg name-container">
            <span style={{ fontWeight: 'bold' }}>{data.name}</span>
            <p className="manufacturer">
              {t('ProductsPage.HeaderTitles.MANUFACTURER')}{' '}
              <span className="manufacturer-name">
                {
                  data.attributes?.find(
                    (f: any) => f?.code === 'manufacturer_id'
                  )?.values
                }
              </span>
            </p>
            <img
              width="120px"
              height="120px"
              src={`https://db-images.object.pscloud.io/optimized_v4_img_middle250_${data.sku}.webp`}
              alt=""
            />
            <div>
              <a
                className="product-link"
                target="_blank"
                rel="noreferrer"
                href={`https://daribar.kz/products/${data?.link}--${data.sku} `}
              >
                {data.sku}
              </a>
            </div>
          </div>
        </div>
      </td>
      <td className="product-item item-edit">
        <button
          type="button"
          className="save-edit-btn"
          onClick={setIsModalOpen}
        >
          Создать
        </button>
      </td>
    </tr>
  );
};

export default BestBeforeProductsTable;
