import { createReducer } from '@reduxjs/toolkit';
import * as actions from './actions';
import { AppState } from '../../global/types';
import { SourcePageState } from './types';

const initialState: SourcePageState = {
  sourceData: {
    data: null,
    loading: false,
    error: null,
  },
  usersData: {
    data: null,
    loading: false,
    error: null,
  },
  sourceTagsData: {
    data: null,
    loading: false,
    error: null,
  },
  edit: false,
};

const reducer = createReducer(initialState, {
  [actions.getSource.REQUEST]: (state) => {
    state.sourceData.loading = true;
  },
  [actions.getSource.ERROR]: (state, action) => {
    state.sourceData.error = action.payload;
    state.sourceData.loading = false;
  },
  [actions.getSource.SUCCESS]: (state, action) => {
    state.sourceData.data = action.payload;
    state.sourceData.loading = false;
  },
  [actions.getUsersOfSource.REQUEST]: (state) => {
    state.usersData.loading = true;
  },
  [actions.getUsersOfSource.ERROR]: (state, action) => {
    state.usersData.error = action.payload;
    state.usersData.loading = false;
  },
  [actions.getUsersOfSource.SUCCESS]: (state, action) => {
    state.usersData.data = action.payload;
    state.usersData.loading = false;
  },
  [actions.clearSource.type]: (state, action) => {
    state.sourceData.data = null;
  },
  [actions.changeEditSource.type]: (state, action) => {
    state.edit = action.payload;
  },
  [actions.getSourceTags.SUCCESS]: (state, action) => {
    state.sourceTagsData.data = action.payload;
    state.sourceTagsData.loading = false;
  },
  [actions.getSourceTags.REQUEST]: (state, action) => {
    state.sourceTagsData.loading = true;
  },
  [actions.getSourceTags.ERROR]: (state, action) => {
    state.sourceTagsData.error = action.payload;
    state.sourceTagsData.loading = false;
  },
});

const sourceData = (state: AppState) => state.sourcePageReducer.sourceData.data;
const editSource = (state: AppState) => state.sourcePageReducer.edit;
const sourceTagsData = (state: AppState) =>
  state.sourcePageReducer.sourceTagsData.data;
const sourceTagsDataLoading = (state: AppState) =>
  state.sourcePageReducer.sourceTagsData.loading;

const sourceDLoading = (state: AppState) =>
  state.sourcePageReducer.sourceData.loading;

const usersData = (state: AppState) => state.sourcePageReducer.usersData.data;

const selectors = {
  sourceData,
  sourceDLoading,
  usersData,
  editSource,
  sourceTagsData,
  sourceTagsDataLoading,
};

export { selectors };
export default reducer;
