import React, { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Styled from './PharmacistBlock.styles';
import UserTable from '../../../../components/UserTable';
import * as actions from '../../../SourcePage/actions';
import { selectors } from '../../../SourcePage/reducer';
import bottomArrow from '../../../../global/media/bottom-arrow.svg';

interface PharmacistBlockParam {
  sourceCode: string;
  sourceName: string;
  networkCode: string;
  expanded: string | boolean;
  handleChange: any;
}

const PharmacistBlock = ({
  sourceCode,
  sourceName,
  networkCode,
  expanded,
  handleChange,
}: PharmacistBlockParam) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [newUser, addNewUser] = useState<boolean>(false);
  const pharmacist = useSelector(selectors.usersData);

  const handleAddNewUser = () => {
    addNewUser(true);
  };

  const handleNoNewUser = () => {
    addNewUser(false);
  };

  useEffect(() => {
    dispatch(actions.clearSource());
    expanded === sourceCode &&
      dispatch(
        actions.getUsersOfSource.request({
          role: 'pharmacist',
          networkCode,
          sourceCode,
        })
      );
  }, [dispatch, networkCode, expanded]);

  return (
    <Styled.PharmacistContainer>
      <Accordion
        TransitionProps={{ unmountOnExit: true }}
        expanded={expanded === sourceCode}
        onChange={handleChange(sourceCode)}
      >
        <AccordionSummary
          expandIcon={<img src={bottomArrow} alt="bottom arrow" />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Styled.SourceName>{sourceName}</Styled.SourceName>
        </AccordionSummary>
        <AccordionDetails>
          {pharmacist && (
            <Styled.UsersBlock>
              <UserTable
                data={pharmacist}
                newUser={newUser}
                networkCode={networkCode}
                sourceCode={sourceCode}
                onAddNewUser={handleNoNewUser}
              />
            </Styled.UsersBlock>
          )}
          <Styled.Button onClick={handleAddNewUser}>
            {t('SourcePage.ADD')}
          </Styled.Button>
        </AccordionDetails>
      </Accordion>
    </Styled.PharmacistContainer>
  );
};

export default PharmacistBlock;
