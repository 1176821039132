import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ClickAwayListener } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../NetworkPage/actions';
import { selectors } from '../NetworkPage/reducer';
import * as Styled from './CreateSourceNetworkPage.styles';
import { DataType } from './types';
import NetworkComponent from '../NetworkPage/components/NetworkComponent';
import CustomTextarea from '../../components/CustomTextarea';
import SourceComponent from '../SourcePage/components/SourceComponent';
import networksArrow from '../../global/media/networks-arrow.svg';
import CreateBreadCrumbs from './components/CreateBreadCrumbs';
import { sort as sortByAlphabet } from '../OrdersPage/components/OrdersTable/components/FilterBySource/helpers';

const CreateSourceNetworkPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [networkName, setNetworkName] = useState<string>('');
  const [sourceName, setSourceName] = useState<string>('');
  const typeForCreate = history.location.hash.slice(1);
  const networks = useSelector(selectors.networksData)?.map((network) => {
    return {
      code: network.code,
      name: network.name,
    };
  });
  const networkFromPage = history.location.search.split('=')[1]
    ? networks?.find(
        (network) => network.code === history.location.search.split('=')[1]
      )
    : '';

  const [networkForSourceName, setNetworkForSourceName] = useState<string>(
    networkFromPage && networkFromPage.name
      ? networkFromPage.name
      : 'Выбрать сеть'
  );
  const [networkForSourceCode, setNetworkForSourceCode] = useState<string>(
    networkFromPage && networkFromPage.code ? networkFromPage.code : ''
  );
  const [networksOpen, setNetworksOpen] = useState<boolean>(false);

  useEffect(() => {
    dispatch(actions.getAllNetwork.request());
  }, [dispatch]);

  useEffect(() => {
    if (networkFromPage && networkFromPage.code) {
      setNetworkForSourceName(networkFromPage.name);
      setNetworkForSourceCode(networkFromPage.code);
    }
  }, [networkFromPage]);

  const handleCLickNetwork = (network: { code: string; name: string }) => {
    setNetworkForSourceCode(network.code);
    setNetworkForSourceName(network.name);
    setNetworksOpen(false);
  };

  return (
    <Styled.CreatePageContainer>
      <CreateBreadCrumbs />
      {typeForCreate === DataType.NETWORK && (
        <Styled.CreatedBlock>
          <div className="label">{t('NetworkPage.INPUTS_LABEL.NAME')}</div>
          <div className="textarea">
            <CustomTextarea
              onChange={(e) => setNetworkName(e)}
              value={networkName}
            />
          </div>
          <NetworkComponent empty networkName={networkName} />
        </Styled.CreatedBlock>
      )}
      {typeForCreate === DataType.SOURCE && (
        <>
          <div className="network-label">{t('CreatePage.NETWORK')}</div>
          <Styled.SelectNetwork>
            <div
              className="selected-network"
              onClick={() => setNetworksOpen(!networksOpen)}
            >
              {networkForSourceName}
              <img src={networksArrow} alt="networks-arrow" />
            </div>
            {networksOpen && (
              <ClickAwayListener onClickAway={() => setNetworksOpen(false)}>
                <div className="networks-options">
                  {networks?.sort(sortByAlphabet).map((network) => (
                    <div onClick={() => handleCLickNetwork(network)}>
                      {network.name}
                    </div>
                  ))}
                </div>
              </ClickAwayListener>
            )}
            <div
              className="create-network-btn"
              onClick={() => history.push(`/create#network`)}
            >
              {t('CreatePage.CREATE_NETWORK')}
            </div>
          </Styled.SelectNetwork>
          <Styled.CreatedBlock>
            <div className="label">{t('NetworkPage.INPUTS_LABEL.NAME')}</div>
            <div className="textarea">
              <CustomTextarea
                onChange={(e) => setSourceName(e)}
                value={sourceName}
              />
            </div>
            <SourceComponent
              empty
              name={sourceName}
              networkCode={networkForSourceCode}
            />
          </Styled.CreatedBlock>
        </>
      )}
    </Styled.CreatePageContainer>
  );
};

export default CreateSourceNetworkPage;
