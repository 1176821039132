import { createReducer } from '@reduxjs/toolkit';
import * as actions from './actions';
import { AppState } from '../../global/types';

const initialState = {
  salesProducts: {
    data: null,
    loading: false,
    error: null,
  },
  allSalesProducts: {
    result: null,
    total: 0,
    loading: false,
    error: null,
  },
};

const reducer = createReducer(initialState, {
  [actions.getSalesProducts.REQUEST]: (state) => {
    state.salesProducts.loading = true;
  },
  [actions.getSalesProducts.SUCCESS]: (state, action) => {
    state.salesProducts.data = action.payload;
    state.salesProducts.loading = false;
  },
  [actions.getSalesProducts.ERROR]: (state, action) => {
    state.salesProducts.error = action.payload;
    state.salesProducts.loading = false;
  },
  [actions.createNewSaleOrder.ERROR]: (state, action) => {
    state.salesProducts.error = action.payload.response.data.error;
    state.salesProducts.loading = false;
  },
  [actions.clearCreateErrors.type]: (state) => {
    state.salesProducts.error = null;
  },
  [actions.getAllSalesProducts.REQUEST]: (state) => {
    state.allSalesProducts.loading = true;
  },
  [actions.getAllSalesProducts.SUCCESS]: (state, action) => {
    const { result, total } = action.payload;
    state.allSalesProducts.result = result;
    state.allSalesProducts.total = total;
    state.allSalesProducts.loading = false;
  },
  [actions.getAllSalesProducts.ERROR]: (state, action) => {
    state.allSalesProducts.error = action.payload;
    state.allSalesProducts.loading = false;
  },
});

const salesProductsData = (state: AppState) =>
  state.salesPageReducer.salesProducts.data;

const salesProductsError = (state: AppState) =>
  state.salesPageReducer.salesProducts.error;

const allSalesProducts = (state: AppState) =>
  state.salesPageReducer.allSalesProducts.result;

const selectors = {
  salesProductsData,
  salesProductsError,
  allSalesProducts,
};

export { selectors };
export default reducer;
