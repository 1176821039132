import styled from 'styled-components';
import { baseTheme } from '../../../../global/styles/theme';

export const ProductsBreadCrumbsContainer = styled('div')`
  padding-top: 12px;
  display: flex;

  .breadCrumb {
    margin-right: 10px;
    cursor: pointer;
  }

  .active {
    color: ${baseTheme.colors.secondary};
  }
`;

export const ProductsBreadCrumbIcon = styled('div')`
  margin-right: 10px;
`;
