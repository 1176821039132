import React, { ChangeEvent, useState, useEffect, useRef } from 'react';

import {
  Form,
  Formik,
  FormikProps,
  Field,
  ErrorMessage,
  FormikErrors,
} from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import ModalWindow from '../../../../components/ModalWindow';
import * as actions from '../../../OrdersPage/actions';
import * as Styled from './AutoDeliveryModal.styles';
import { AutoDeliveryRequest, AutoDeliveryRequestErrors } from '../../types';
import { OrderTableData, UserRole } from '../../../OrdersPage/types';
import { selectors } from '../../../OrdersPage/reducer';

import * as actionsOrder from '../../actions';
import Map2Gis from '../../../Map2Gis';
import MapYandex from '../../../YandexMap';
import { getFromLocalStorage } from '../../../../global/helpers';
import Loader from '../../../../components/Loader';
import Button from '../../../../components/Button';

interface AutoDeliveryModalProps {
  data: OrderTableData | null;
  userPhone: string;
  userRole: string;
  onClose: () => void;
  setN8nPrice: (price: string) => void;
  n8nPrice: string;
  handleApply: () => void;
  promoCodeError: string;
  setLoader: React.Dispatch<React.SetStateAction<boolean>>;
  loader: boolean;
  handlePromoCodeChange: (event: ChangeEvent<HTMLInputElement>) => void;
  promoCode: string;
  cancelPromocode: (id: string) => void;
}

const AutoDeliveryModal = ({
  onClose,
  data,
  userPhone,
  setN8nPrice,
  n8nPrice,
  userRole,
  handleApply,
  promoCodeError,
  promoCode,
  loader,
  setLoader,
  handlePromoCodeChange,
  cancelPromocode,
}: AutoDeliveryModalProps) => {
  const role = getFromLocalStorage('role');
  const formikRef = useRef(null) as any;
  const dispatch = useDispatch();
  const [deliveryChosenType, setDeliveryChosenType] = useState(
    data?.delivery?.provider === 'choco' ? 'Ручной' : 'Автоматом'
  );
  const [map, setMap] = useState('yandex');
  const coordinates = useSelector(selectors.coordinates);
  const [latB, setLatB] = useState(
    data?.address?.latitude ? data?.address?.latitude : 0
  );
  const [lonB, setLonB] = useState(
    data?.address?.longitude ? data?.address?.longitude : 0
  );
  const [searchCoordinates, setSearchCoordinates] = useState<number[]>([]);
  const [searchAddress, setSearchAddress] = useState('');
  const getCoordinates = async () => {
    const dataParams = {
      city: data?.address?.city ? data?.address?.city : '',
      entrance: data?.address.entrance ? data?.address.entrance : '',
      flat: data?.address?.flat ? data?.address?.flat.toString() : '',
      floor: data?.address?.floor ? data?.address?.floor : 0,
      street: data?.address?.address ? data?.address?.address : '',
      address_id: data?.address?.id ? data?.address?.id : 0,
    };
  };

  useEffect(() => {
    if (data?.address?.latitude) {
      setLatB(data?.address?.latitude ? data?.address?.latitude : 0);
      setLonB(data?.address?.longitude ? data?.address?.longitude : 0);
    }
  }, [data?.address?.longitude]);

  useEffect(() => {
    if (coordinates?.data?.result?.lat) {
      setLatB(coordinates?.data?.result?.lat ? coordinates.data.result.lat : 0);
      setLonB(coordinates?.data?.result?.lon ? coordinates.data.result.lon : 0);
    }
  }, [coordinates]);

  useEffect(() => {
    const price = data?.deliveryPriceWithDiscount ?? data?.delivery?.priceFact;
    if (price !== undefined) {
      setN8nPrice(price.toString());
    }
    if (
      (data?.delivery?.claimStatus === 'cancelled' &&
        data?.delivery?.priceFactCalculated) ||
      (!data?.delivery?.claimId && data?.delivery?.priceFactCalculated) ||
      data?.delivery?.provider === 'choco'
    ) {
      setDeliveryChosenType('Ручной');
    } else setDeliveryChosenType('Автоматом');
  }, []);

  useEffect(() => {
    if (data?.deliveryPriceWithDiscount) {
      setN8nPrice(data?.deliveryPriceWithDiscount.toString());
    } else if (
      data?.delivery?.claimPrice &&
      data?.delivery?.price &&
      data?.delivery?.claimPrice > data?.delivery?.price
    ) {
      setN8nPrice(data?.delivery?.price.toString());
    } else if (data?.delivery?.claimPrice && !data?.delivery?.price) {
      setN8nPrice(data?.delivery?.claimPrice.toString());
    }
  }, [
    data?.delivery?.priceFact,
    data?.delivery?.claimPrice,
    data?.deliveryPriceWithDiscount,
  ]);

  const typeDelivery = (type?: string) => {
    switch (type) {
      case 'ondemand':
        return 'На машине';
      case 'slot':
        return 'В течении 4-х ч.';
      case 'pedestrian':
        return 'Курьер';

      default:
        return 'Курьер';
    }
  };

  const nameDelivery = (type?: string) => {
    switch (type) {
      case 'На машине':
        return 'ondemand';
      case 'В течении 4-х ч.':
        return 'slot';
      case 'Курьер':
        return 'pedestrian';

      default:
        return 'pedestrian';
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setN8nPrice(e.target.value);
  };
  const handleCopyText = (text: string, content: string) => {
    navigator.clipboard.writeText(text);
  };

  const INITIAL_STATE = {
    addressFrom: data?.sourceAddress ? data?.sourceAddress : '',
    commentsFrom:
      data?.sourceName && data?.orderNumber
        ? `${data?.sourceName}, ${data?.orderNumber}`
        : '',
    senderFrom: userPhone,
    addressTo: data?.address?.address ? data?.address?.address : '',
    cityTo: data?.address?.city ? data?.address?.city : '',
    entranceTo: data?.address.entrance ? data?.address.entrance : '',
    floorTo: data?.address?.floor ? data?.address?.floor.toString() : '',
    apartmentTo: data?.address?.flat ? data?.address?.flat.toString() : '',
    commentsTo: data?.comment ? data?.comment : '',
    recipientTo: data?.phone ? data?.phone : '',
    lat: latB,
    lon: lonB,
    sms: true,
    deliveryMethod: typeDelivery(data?.delivery?.type),
    link: true,
    code: true,
    confirm_transmission: false,
  };
  const { t } = useTranslation();

  const handleSubmitForm = async (values: AutoDeliveryRequest) => {
    const data1 = {
      destination: {
        city: values.cityTo,
        comment: values.commentsTo,
        contact: {
          phone: data?.phone.slice(1),
        },
        entrance: values.entranceTo,
        flat: values.apartmentTo,
        floor: values.floorTo,
        lat: latB,
        lng: lonB,
        street: values.addressTo,
      },

      due_time: data?.delivery?.end,
      order_id: data?.id,
      order_price: Number(data?.price.split('.')[0]),
      pharmacy_code: data?.source,
      provider: data?.delivery?.provider,
      source: {
        city: data?.city,
        comment: values.commentsFrom,
        contact: {
          phone: userPhone,
        },
      },
      taxi_class: nameDelivery(values.deliveryMethod),
      disable_sms_code: !values.sms,
      send_link: !values.link,
      confirm_return: !values.code,
      confirm_start_transfer: !values.confirm_transmission,
    };

    await dispatch(
      actions.createClaim.request({
        data: data1,
        postEffect: () => {
          dispatch(
            actions.setSelectedOrder.request({
              role: userRole,
              id: data?.id,
            })
          );
        },
      })
    );
  };
  const handleClose = () => {
    onClose();
    dispatch(actions.resetCoordinates());
  };
  const confirmSelf = () => {
    const {
      addressTo,
      cityTo,
      entranceTo,
      floorTo,
      apartmentTo,
      lat,
      lon,
      recipientTo,
    } = INITIAL_STATE;

    if (
      lon !== data?.address?.longitude ||
      lat !== data?.address?.latitude ||
      addressTo !== data?.address?.address ||
      cityTo !== data?.address?.city ||
      entranceTo !== data?.address.entrance ||
      apartmentTo !== data?.address?.flat.toString() ||
      floorTo !== data?.address?.floor.toString()
    ) {
      dispatch(
        actions.setSelectedOrder.request({
          role: userRole,
          id: data?.id,
          postEffect: () => {
            dispatch(
              actionsOrder.n8nPrice.request({
                address: {
                  address: data?.address?.address,
                  city: cityTo,
                  entrance: entranceTo,
                  flat: floorTo,
                  floor: Number(floorTo),
                  icon: data?.address?.icon,
                  id: data?.address?.id,
                  latitude: lat,
                  longitude: lon,
                  user_phone: recipientTo.includes('+')
                    ? recipientTo.slice(1)
                    : recipientTo,
                },
                client_address: data?.address?.address
                  ? data?.address?.address
                  : '',
                created_at: data?.createdAt ? data?.createdAt : '',
                entered_price: n8nPrice ? Number(n8nPrice) : 0,
                order_id: data?.id,
                pharmacy_address: data?.sourceAddress
                  ? data?.sourceAddress
                  : '',
                selected_price: data?.delivery?.price
                  ? data?.delivery?.price
                  : 0,
                claim_price: data?.delivery?.claimPrice
                  ? data?.delivery?.claimPrice
                  : 0,
                slots: data?.delivery?.slotsAll ? data.delivery.slotsAll : [],
                delivery_slot: data?.delivery?.slotsCurrent
                  ? data.delivery.slotsCurrent?.[0]
                  : {},
                postEffect: () => {
                  dispatch(
                    actions.setSelectedOrder.request({
                      role: userRole,
                      id: data?.id,
                    })
                  );
                },
              })
            );
            handleClose();
          },
        })
      );
    } else {
      dispatch(
        actions.setSelectedOrder.request({
          role: userRole,
          id: data?.id,
          postEffect: () => {
            dispatch(
              actionsOrder.n8nPrice.request({
                client_address: data?.address?.address
                  ? data?.address?.address
                  : '',
                created_at: data?.createdAt ? data?.createdAt : '',
                entered_price: n8nPrice ? Number(n8nPrice) : 0,
                order_id: data?.id,
                pharmacy_address: data?.sourceAddress
                  ? data?.sourceAddress
                  : '',
                selected_price: data?.delivery?.price
                  ? data?.delivery?.price
                  : 0,
                claim_price: data?.delivery?.claimPrice
                  ? data?.delivery?.claimPrice
                  : 0,
                slots: data?.delivery?.slotsAll ? data.delivery.slotsAll : [],
                delivery_slot: data?.delivery?.slotsCurrent
                  ? data.delivery.slotsCurrent?.[0]
                  : {},
                postEffect: () => {
                  dispatch(
                    actions.setSelectedOrder.request({
                      role: userRole,
                      id: data?.id,
                    })
                  );
                },
              })
            );
            handleClose();
          },
        })
      );
    }
  };

  const cancelDelivery = () => {
    if (data?.delivery?.claimId) {
      dispatch(
        actions.cancelClaim.request({
          claimId: data?.delivery?.claimId,
          data: { cancel_state: 'free', provider: data?.deliveryMethod },
          postEffect: () => {
            dispatch(
              actions.setSelectedOrder.request({
                role: userRole,
                id: data?.id,
                postEffect: () => {
                  handleClose();
                },
              })
            );
          },
        })
      );
    }
  };

  const confirmDeliverySelf = () => {
    if (
      data?.delivery?.claimStatus !== 'cancelled' &&
      data?.delivery?.claimId
    ) {
      dispatch(
        actions.cancelClaim.request({
          claimId: data?.delivery?.claimId,
          data: { cancel_state: 'free', provider: data?.deliveryMethod },
          postEffect: () => {
            confirmSelf();
          },
        })
      );
    } else {
      confirmSelf();
    }
  };

  const information = `  Номер заказа: заказ№${data?.id},
  Ист.заказа: ${data?.userSource},
  Номер получателя:  ${data?.phone},
  Координаты адреса клиента(точка Б): [${latB}, ${lonB}],
  id адреса: ${data?.address?.id},
  Claim id: ${data?.delivery.claimId},`;

  const validateForm = (values: AutoDeliveryRequest) => {
    const errors: FormikErrors<AutoDeliveryRequestErrors> = {};

    return errors;
  };

  const handleChange = (
    props: FormikProps<AutoDeliveryRequest>,
    event: ChangeEvent<HTMLInputElement>,
    inputName: string
  ) => {
    const { name, value, type, checked } = event.target;
    const fieldValue = type === 'checkbox' ? checked : value;

    props.setFieldValue(inputName, fieldValue);
  };
  const disabledField =
    deliveryChosenType === 'Ручной' ||
    (deliveryChosenType === 'Автоматом' &&
      data?.delivery?.claimStatus !== 'cancelled' &&
      data?.delivery?.priceFactCalculated);
  return (
    <Styled.Wrapper>
      <ModalWindow onClose={handleClose}>
        <div className="common-container">
          <div className="auto-delivery-modal">
            <Formik
              innerRef={formikRef}
              onSubmit={handleSubmitForm}
              validate={validateForm}
              initialValues={INITIAL_STATE}
            >
              {(props: FormikProps<AutoDeliveryRequest>) => (
                <Form className="form-container">
                  <div className="title">
                    {' '}
                    {t('OrdersPage.AutoDelivery.FROM')}
                  </div>
                  <div className="container-table">
                    <div className="flex-container">
                      <div className="subtitle">
                        {t('OrdersPage.AutoDelivery.ADDRESS')}
                      </div>
                      <div className="cell">
                        <Field
                          placeholder="адрес"
                          id="addressFrom"
                          name="addressFrom"
                          type="text"
                          disabled
                          className="input"
                        />
                        <ErrorMessage
                          name="addressFrom"
                          render={(msg: string) => (
                            <div className="error-message-label">{msg}</div>
                          )}
                        />
                      </div>
                    </div>

                    <div className="flex-container-accent">
                      <div className="subtitle">
                        {' '}
                        {t('OrdersPage.AutoDelivery.COMMENTS_FOR_DELIVER')}
                      </div>

                      <div className="cell">
                        <Field
                          placeholder="комментарий курьеру"
                          id="commentsFrom"
                          name="commentsFrom"
                          type="text"
                          disabled={disabledField}
                          className="input"
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            handleChange(props, e, 'comments');
                          }}
                        />
                        <ErrorMessage
                          name="commentsFrom"
                          render={(msg: string) => (
                            <div className="error-message-label">{msg}</div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="flex-container">
                      <div className="subtitle">
                        {' '}
                        {t('OrdersPage.AutoDelivery.SENDER')}
                      </div>
                      <div className="cell">
                        <Field
                          placeholder="отправитель"
                          id="senderFrom"
                          name="senderFrom"
                          type="text"
                          disabled={disabledField}
                          className="input"
                        />
                        <ErrorMessage
                          name="senderFrom"
                          render={(msg: string) => (
                            <div className="error-message-label">{msg}</div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="flex-container">
                      <div className="subtitle">
                        Подтвердить передачу смс кодом
                      </div>
                      <div className="cell">
                        <Field
                          type="checkbox"
                          id="confirm_transmission"
                          name="confirm_transmission"
                          disabled={disabledField}
                          checked={props.values.confirm_transmission}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            handleChange(props, e, 'confirm_transmission');
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="title">
                    {' '}
                    {t('OrdersPage.AutoDelivery.TO')}
                  </div>
                  <div className="container-table">
                    <div className="flex-container">
                      <div className="subtitle">
                        {t('OrdersPage.AutoDelivery.STREET')}
                      </div>
                      <div className="cell">
                        <div>{t('OrdersPage.AutoDelivery.ADDRESS')}</div>
                        <Field
                          placeholder="город"
                          id="cityTo"
                          name="cityTo"
                          type="text"
                          disabled={disabledField}
                          className="input-small"
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            handleChange(props, e, 'cityTo');
                          }}
                        />
                        <ErrorMessage
                          name="cityTo"
                          render={(msg: string) => (
                            <div className="error-message-label">{msg}</div>
                          )}
                        />
                      </div>
                      <div className="cell">
                        <div> {t('OrdersPage.AutoDelivery.STREET')}</div>
                        <Field
                          placeholder="адрес"
                          id="addressTo"
                          name="addressTo"
                          type="text"
                          disabled={disabledField}
                          className="input"
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            handleChange(props, e, 'addressTo');
                          }}
                        />
                        <ErrorMessage
                          name="addressTo"
                          render={(msg: string) => (
                            <div className="error-message-label">{msg}</div>
                          )}
                        />
                      </div>
                    </div>

                    <div className="flex-container-accent">
                      <div className="subtitle">
                        {t('OrdersPage.AutoDelivery.COMMENTS_FOR_DELIVER')}
                      </div>
                      <div className="flex-container-column">
                        <div className="flex-container">
                          <div className="cell">
                            <div> {t('OrdersPage.AutoDelivery.ENTRANCE')}</div>
                            <Field
                              placeholder="подьезд"
                              id="entranceTo"
                              name="entranceTo"
                              type="text"
                              disabled={disabledField}
                              className="input-small"
                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                handleChange(props, e, 'entranceTo');
                              }}
                            />
                            <ErrorMessage
                              name="entranceTo"
                              render={(msg: string) => (
                                <div className="error-message-label">{msg}</div>
                              )}
                            />
                          </div>

                          <div className="cell">
                            <div>{t('OrdersPage.AutoDelivery.FLOOR')}</div>
                            <Field
                              placeholder="этаж"
                              id="floorTo"
                              name="floorTo"
                              type="text"
                              disabled={disabledField}
                              className="input-small"
                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                handleChange(props, e, 'floorTo');
                              }}
                            />
                            <ErrorMessage
                              name="floorTo"
                              render={(msg: string) => (
                                <div className="error-message-label">{msg}</div>
                              )}
                            />
                          </div>
                          <div className="cell">
                            <div> {t('OrdersPage.AutoDelivery.FLAT')}</div>
                            <Field
                              placeholder="квартира"
                              id="apartmentTo"
                              name="apartmentTo"
                              type="text"
                              disabled={disabledField}
                              className="input-small"
                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                handleChange(props, e, 'apartmentTo');
                              }}
                            />
                            <ErrorMessage
                              name="apartmentTo"
                              render={(msg: string) => (
                                <div className="error-message-label">{msg}</div>
                              )}
                            />
                          </div>
                        </div>
                        <div className="cell">
                          <Field
                            placeholder="комментарий курьеру"
                            id="commentsTo"
                            name="commentsTo"
                            type="text"
                            disabled={disabledField}
                            className="input"
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              handleChange(props, e, 'comments');
                            }}
                          />
                          <ErrorMessage
                            name="commentsFrom"
                            render={(msg: string) => (
                              <div className="error-message-label">{msg}</div>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex-container">
                      <div className="subtitle">
                        {' '}
                        {t('OrdersPage.AutoDelivery.CLIENT')}
                      </div>
                      <div className="cell">
                        <Field
                          placeholder="получатель"
                          id="recipientTo"
                          name="recipientTo"
                          type="text"
                          disabled={disabledField}
                          className="input"
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            handleChange(props, e, 'recipientTo');
                          }}
                        />
                        <ErrorMessage
                          name="recipientTo"
                          render={(msg: string) => (
                            <div className="error-message-label">{msg}</div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="flex-container">
                      <div className="subtitle">Широта</div>
                      <div className="cell">
                        <Field
                          id="lat"
                          name="lat"
                          type="text"
                          disabled={disabledField}
                          className="input"
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            handleChange(props, e, 'lat');
                          }}
                          value={latB}
                        />
                        <ErrorMessage
                          name="lat"
                          render={(msg: string) => (
                            <div className="error-message-label">{msg}</div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="flex-container">
                      <div className="subtitle">Долгота</div>
                      <div className="cell">
                        <Field
                          id="lon"
                          name="lon"
                          type="text"
                          disabled={disabledField}
                          className="input"
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            handleChange(props, e, 'lon');
                          }}
                          value={lonB}
                        />
                        <ErrorMessage
                          name="lon"
                          render={(msg: string) => (
                            <div className="error-message-label">{msg}</div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="flex-container">
                      <div className="subtitle">
                        {t('OrdersPage.AutoDelivery.CONFIRM_SMS')}
                      </div>
                      <div className="cell">
                        <Field
                          type="checkbox"
                          id="sms"
                          name="sms"
                          disabled={disabledField}
                          checked={props.values.sms}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            handleChange(props, e, 'sms');
                          }}
                        />
                      </div>
                    </div>
                    {searchCoordinates.length > 0 && (
                      <button
                        className="button-autoDelivery"
                        type="button"
                        onClick={() => {
                          setLatB(searchCoordinates[0]);
                          setLonB(searchCoordinates[1]);
                          setSearchCoordinates([]);
                          formikRef.current.setFieldValue(
                            'addressTo',
                            searchAddress
                          );
                          setSearchAddress('');
                        }}
                      >
                        применить для точки Б
                      </button>
                    )}
                    <div className="cell">
                      <div className="flex-container">
                        <div> {t('OrdersPage.AutoDelivery.PRICES')}</div>
                        <div>
                          <label>
                            <Field
                              type="radio"
                              name="deliveryMethod"
                              value="Курьер"
                              disabled={disabledField}
                            />
                            Курьер
                          </label>
                          <label>
                            <Field
                              type="radio"
                              name="deliveryMethod"
                              value="На машине"
                              disabled={disabledField}
                            />
                            {t('OrdersPage.AutoDelivery.BY_CAR')}
                          </label>
                          <label>
                            <Field
                              type="radio"
                              name="deliveryMethod"
                              disabled={disabledField}
                              value="В течении 4-х ч."
                            />
                            {t('OrdersPage.AutoDelivery.FOUR_OURS')}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="flex-container">
                      <div className="subtitle">
                        {t('OrdersPage.AutoDelivery.OPTION')}
                      </div>
                      <div>
                        <div className="cell">
                          <div className="flex-container">
                            <div>
                              {t('OrdersPage.AutoDelivery.SENDER_GET_LINK')}
                            </div>
                            <Field
                              type="checkbox"
                              id="link"
                              name="link"
                              checked={props.values.link}
                              disabled={disabledField}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                handleChange(props, e, 'link');
                              }}
                            />
                          </div>
                        </div>
                        <div className="cell">
                          <div className="flex-container">
                            <div> {t('OrdersPage.AutoDelivery.CONFIRM')}</div>
                            <Field
                              type="checkbox"
                              disabled={disabledField}
                              id="code"
                              name="code"
                              checked={props.values.code}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                handleChange(props, e, 'code');
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {data?.delivery?.price ? (
                    <div>
                      {t('OrdersPage.AutoDelivery.CLIENT_PRICE')}{' '}
                      {data &&
                      data.deliveryPriceWithDiscount &&
                      data.delivery &&
                      data.delivery.price &&
                      data.deliveryPriceWithDiscount !== data.delivery.price ? (
                        <span className="crossed-text">
                          {data?.delivery?.price}₸
                        </span>
                      ) : (
                        <span>{data?.delivery?.price}₸</span>
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="flex-container-promo">
                    {data &&
                      data.promoCode &&
                      data.promoCode.discount_target === 'delivery' && (
                        <div className="delivery-type__title-accent">
                          {t(
                            'OrdersPage.OrderDetailedModal.PRICE_WITH_PROMOCODE'
                          )}
                          <span className="delivery-type__value-red">
                            {data.deliveryPriceWithDiscount
                              ? data.deliveryPriceWithDiscount
                              : 0}
                            ₸
                          </span>
                        </div>
                      )}
                    <div>
                      {data &&
                        data.promoCode &&
                        data.promoCode.discount_target === 'delivery' &&
                        (((role === UserRole.OPERATOR ||
                          role === UserRole.HEAD_OPERATOR) &&
                          data?.address?.city !== data?.city) ||
                          role === UserRole.ADMIN) && (
                          <>
                            <div
                              onClick={() => {
                                data.promoCode?.max_user_orders !== 1 &&
                                  cancelPromocode(data.id);
                              }}
                              className={`cancel_button ${
                                data.promoCode?.max_user_orders === 1 &&
                                'disabled-button '
                              } `}
                            >
                              Oтменить применение
                            </div>
                            {data.promoCode?.max_user_orders === 1 && (
                              <div className="error-message-promo">
                                Промокод на <b>первый заказ</b>, клиент не
                                сможет применить его повторно
                              </div>
                            )}
                          </>
                        )}

                      {data && !data.promoCode && role === UserRole.ADMIN && (
                        <>
                          <div className="flex-container-accent">
                            <div className="promo-text">Применить промокод</div>{' '}
                            <input
                              placeholder="Введите промокод"
                              type="text"
                              value={promoCode}
                              onChange={handlePromoCodeChange}
                              className={
                                promoCodeError
                                  ? 'input-promocode-error'
                                  : 'input-promocode'
                              }
                            />
                            <div
                              onClick={handleApply}
                              className="apply-promo-code"
                            >
                              Ok
                            </div>
                          </div>
                          {promoCodeError && (
                            <p className="error-message">{promoCodeError}</p>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  {(role === UserRole.OPERATOR ||
                    role === UserRole.ADMIN ||
                    role === UserRole.HEAD_OPERATOR) &&
                    data &&
                    data.delivery &&
                    (data.deliveryPriceWithDiscount ||
                      data.delivery?.price ||
                      data.delivery?.priceFact) && (
                      <div>
                        Выставить счет за доставку:{' '}
                        <span className="promo-text">
                          {data?.promoCode
                            ? data.deliveryPriceWithDiscount || 0
                            : data.delivery?.priceFact ||
                              data.delivery?.price ||
                              0}{' '}
                          ₸
                        </span>
                      </div>
                    )}

                  {data?.delivery?.claimStatus && data?.delivery?.claimId && (
                    <div className="claim">
                      Claim:
                      <div className="claim-container">
                        <div className="claim-title">id:</div>{' '}
                        <div className="claim-value">
                          {data?.delivery?.claimId}
                        </div>
                      </div>
                      <div className="claim-container">
                        <div className="claim-title">статус:</div>{' '}
                        <div className="claim-value">
                          {data?.delivery?.claimStatus}
                        </div>
                      </div>
                    </div>
                  )}

                  <button
                    disabled={
                      (!!data?.delivery?.claimId &&
                        data?.delivery?.claimStatus !== 'cancelled') ||
                      data?.delivery?.provider === 'choco'
                    }
                    type="submit"
                    className={`button-autoDelivery ${
                      ((data?.delivery?.claimId &&
                        data?.delivery?.claimStatus !== 'cancelled') ||
                        data?.delivery?.provider === 'choco') &&
                      'disabled'
                    }  `}
                  >
                    {t('OrdersPage.AutoDeliveryPrice')}
                  </button>
                </Form>
              )}
            </Formik>
            <div className="container-button-copy">
              <Button
                text={t('OrdersPage.OrderDetailedModal.PHARMACY_TAGS.COPY')}
                variant="outlined"
                onClick={() =>
                  handleCopyText(
                    information,
                    t(
                      'OrdersPage.OrderDetailedModal.PHARMACY_TAGS.ADDED_TO_BUFFER'
                    )
                  )
                }
              />
            </div>
            <div className="container-type">
              <div> {t('OrdersPage.AutoDelivery.DELIVERY_TYPE')}</div>
              <div className="choose-container">
                <div
                  className={
                    deliveryChosenType === 'Автоматом'
                      ? 'active-flex-container-button'
                      : 'flex-container-button'
                  }
                  onClick={() => {
                    deliveryChosenType !== 'Автоматом' &&
                      setDeliveryChosenType(
                        data?.delivery?.provider === 'choco'
                          ? 'Ручной'
                          : 'Автоматом'
                      );
                  }}
                >
                  <div>{t('OrdersPage.AutoDelivery.AUTO')}</div>
                </div>
                <div
                  className={
                    deliveryChosenType === 'Ручной'
                      ? 'active-flex-container-button'
                      : 'flex-container-button'
                  }
                  onClick={() => {
                    deliveryChosenType !== 'Ручной' &&
                      setDeliveryChosenType('Ручной');
                  }}
                >
                  <div>{t('OrdersPage.AutoDelivery.SELF')}</div>
                </div>
              </div>
            </div>

            <div className="container-type-price">
              <div>{t('OrdersPage.AutoDelivery.DELIVERY_PRICE_TOTAL')}</div>
              <div className="price">
                {data?.delivery?.claimPrice ||
                  (data?.delivery?.claimStatus !== 'cancelled' &&
                  data?.delivery?.claimId
                    ? n8nPrice
                    : 0)}
              </div>
              <input
                value={n8nPrice}
                onChange={handleInputChange}
                type="text"
                className="price"
                maxLength={30}
              />
            </div>
            <div className="container-button">
              <div
                className={`button-cancel ${
                  (!data?.delivery?.claimId ||
                    data?.delivery?.claimStatus === 'cancelled') &&
                  'disabled'
                }  `}
                onClick={() => {
                  data?.delivery?.claimId && cancelDelivery();
                }}
              >
                {t('OrdersPage.AutoDelivery.CANCEL')}
              </div>
              <div
                className={`button-apply ${
                  ((data?.delivery?.claimStatus === 'cancelled' &&
                    deliveryChosenType === 'Автоматом') ||
                    (data?.delivery?.priceFactCalculated &&
                      deliveryChosenType === 'Автоматом') ||
                    (!data?.delivery?.claimId &&
                      deliveryChosenType === 'Автоматом') ||
                    (deliveryChosenType === 'Ручной' && !n8nPrice)) &&
                  'disabled'
                }  `}
                onClick={() => {
                  if (
                    data?.delivery?.claimId &&
                    deliveryChosenType === 'Автоматом'
                  ) {
                    confirmSelf();
                  }
                  if (deliveryChosenType === 'Ручной' && n8nPrice) {
                    confirmDeliverySelf();
                  }
                }}
              >
                {t('OrdersPage.AutoDelivery.APPLY')}
              </div>
            </div>
          </div>
          <div>
            <div className="maps-container">
              <div
                className={map === 'yandex' ? 'yandex-active' : 'yandex'}
                onClick={() => {
                  setMap('yandex');
                }}
              >
                Yandex
              </div>
              <div
                className={map === 'gis' ? 'gis-active' : 'gis'}
                onClick={() => {
                  setMap('gis');
                }}
              >
                2Gis
              </div>
            </div>

            {map === 'yandex' ? (
              <MapYandex
                setSearchAddress={setSearchAddress}
                setSearchCoordinates={setSearchCoordinates}
                setLatB={setLatB}
                setLonB={setLonB}
                latB={latB}
                lonB={lonB}
                sourceLat={data?.sourceLat ? data?.sourceLat : ''}
                sourceLon={data?.sourceLon ? data?.sourceLon : ''}
              />
            ) : (
              <Map2Gis
                setLatB={setLatB}
                setLonB={setLonB}
                latB={latB}
                lonB={lonB}
                sourceLat={data?.sourceLat ? data?.sourceLat : ''}
                sourceLon={data?.sourceLon ? data?.sourceLon : ''}
              />
            )}
          </div>
        </div>
      </ModalWindow>
      {loader && <Loader />}
    </Styled.Wrapper>
  );
};

export default AutoDeliveryModal;
