import React from 'react';
import { useTranslation } from 'react-i18next';
import { SourceData } from '../../types';
import * as Styled from './Source.styles';
import SourceRow from './components/SourceRow';

interface SourceParam {
  data: SourceData;
}

const Source = ({ data }: SourceParam) => {
  const { t } = useTranslation();

  const TableHeader = (
    <Styled.SourceHead>
      <Styled.SourceHeaderRow>
        <th className="table__header-cell table__header-cell-number">
          {t('SourcesPage.SourceTable.headerTitles.NUMBER')}
        </th>
        <th className="table__header-cell">
          {t('SourcesPage.SourceTable.headerTitles.NAME')}
        </th>
        <th className="table__header-cell table__header-cell-city">
          {t('SourcesPage.SourceTable.headerTitles.CITY')}
        </th>
        <th className="table__header-cell">
          {t('SourcesPage.SourceTable.headerTitles.ADDRESS')}
        </th>
        <th className="table__header-cell">
          {t('SourcesPage.SourceTable.headerTitles.OPENING_HOURS')}
        </th>
        <th className="table__header-cell table__header-cell-time">
          {t('SourcesPage.SourceTable.headerTitles.TIME')}
        </th>
        <th className="table__header-cell table__header-cell-visibility">
          {t('SourcesPage.SourceTable.headerTitles.VISIBILITY_PRODUCTS')}
        </th>
      </Styled.SourceHeaderRow>
    </Styled.SourceHead>
  );

  return (
    <Styled.SourceTable>
      {TableHeader}
      {/* // eslint-disable-next-line */}
      <SourceRow data={data} />
    </Styled.SourceTable>
  );
};

export default Source;
