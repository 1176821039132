import React, { useRef, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
// import { useVirtualizer, VirtualItem } from '@tanstack/react-virtual';
import * as Styled from './OrdersTable.styles';
import * as actions from '../../actions';
import { OrderTableData, UserRole } from '../../types';
import OrdersTableRow from './components/OrdersTableRow';
import OrderDetailedModal from '../../../../components/OrderDetailedModal';
import OrderCancelModal from '../../../../components/OrderCancelModal';
import ModalWindow from '../../../../components/ModalWindow';
import {
  getFromLocalStorage,
  setToLocalStorage,
} from '../../../../global/helpers';
import { selectors } from '../../reducer';
import FilterByStatus from './components/FilterByStatus/FilterByStatus';
import FilterByPayment from './components/FilterByPayment';
import FilterBySource from './components/FilterBySource';
import { selectors as selectorsOrder } from '../../../OrderPage/reducer';
import { selectors as selectorsLogin } from '../../../LoginPage/reducer';
import { selectors as magnumSelectors } from '../../../../components/ActivePharmacistBlock/reducer';
import FilterByDelivery from './components/FilterByDelivery/FilterByDelivery';
import arrow from '../../../../global/media/arrow-breadCrumbs.svg';
import FilterByPaymentType from './components/FilterByPaymentType';
import { StatusType } from '../../../../global/types';

interface OrdersTableProps {
  data: OrderTableData[] | null;
  setStatus: (status: string) => void;
  setSource?: false | ((source: string) => void);
  setPaymentType?: false | ((payment: string) => void);
  setPayment?: false | ((payment: boolean | string) => void);
  setDelivery?: false | ((delivery: string) => void);
  onOrderClick?: (isOpen: boolean) => void;
  paymentOrders: string | boolean;
  onSetOrderPharmacist?: (collector: { name: string; phone: string }) => void;
  dataByTab?: OrderTableData[] | null;
}

const OrdersTable: React.FC<OrdersTableProps> = ({
  data,
  setStatus,
  setPayment,
  setSource,
  setDelivery,
  setPaymentType,
  onOrderClick,
  paymentOrders,
  onSetOrderPharmacist,
  dataByTab,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const token = getFromLocalStorage('accessToken');
  const userRole = getFromLocalStorage('role');
  const role =
    getFromLocalStorage('role') === 'manager' ? 'network' : 'pharmacy';

  const orderId = useSelector(selectors.orderId);
  const dataUser = useSelector(selectorsLogin.userData);
  const selectedActivePharmacist = useSelector(
    selectorsOrder.selectedActivePharmacist
  );
  const activeUsers = useSelector(magnumSelectors.activeUsersData)?.filter(
    (e) => e.active
  );

  const [searchByName, setSearchByName] = useState<string>('');
  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const [cancelOrder, setCancelOrder] = useState<null | OrderTableData>(null);
  const [selectedStatus, setSelectedStatus] = useState<string>(
    t('OrdersPage.OrdersTable.headerTitles.STATUS')
  );

  const [filterStatusOpen, setFilterStatusOpen] = useState<boolean>(false);
  const [filterPaymentOpen, setFilterPaymentOpen] = useState<boolean>(false);
  const [filterPaymentTypeOpen, setFilterPaymentTypeOpen] =
    useState<boolean>(false);
  const [filterDeliveryOpen, setFilterDeliveryOpen] = useState<boolean>(false);
  const [isFilterPharmacyOpen, setIsFilterPharmacyOpen] =
    useState<boolean>(false);

  const handleSetStatus = useCallback(
    (statusName: string, code: string, color: string) => {
      setSelectedStatus(statusName);
      setStatus(code);
      setFilterStatusOpen(false);
    },
    [setStatus]
  );

  const handleSetPayment = useCallback(
    (payment: boolean | string) => {
      setPayment && setPayment(payment);
    },
    [setPayment]
  );

  const handleSetPaymentType = useCallback(
    (payment: string) => {
      setPaymentType && setPaymentType(payment);
    },
    [setPaymentType]
  );

  const handleSetDelivery = useCallback(
    (delivery: string) => {
      setDelivery && setDelivery(delivery);
    },
    [setDelivery]
  );

  const handleSetSource = useCallback(
    (source: string) => {
      setSource && setSource(source);
      setIsFilterPharmacyOpen(false);
    },
    [setSource]
  );

  const handleSetAllStatus = useCallback(() => {
    setSelectedStatus(t('OrdersPage.OrdersTable.headerTitles.STATUS'));
    setStatus('');
    setFilterStatusOpen(false);
  }, [setStatus, t]);

  const tableHeaderClass = useMemo(() => {
    return userRole === UserRole.OPERATOR ||
      userRole === UserRole.ADMIN ||
      userRole === UserRole.HEAD_OPERATOR
      ? 'table__header-cell-operator'
      : 'table__header-cell';
  }, [userRole]);

  const TableHeader = useMemo(
    () => (
      <Styled.OrdersHead>
        <Styled.OrdersTableHeaderRow>
          <th className={`${tableHeaderClass} table__header-timer-cell`}>
            <span id="timer">
              {t('OrdersPage.OrdersTable.headerTitles.TIMER')}
            </span>
          </th>
          <th
            className={`${tableHeaderClass} order-number-cell ${
              userRole === UserRole.MANAGER ||
              userRole === UserRole.PHARMACY_MANAGER
                ? 'table__header-order'
                : userRole !== UserRole.OPERATOR &&
                  userRole !== UserRole.ADMIN &&
                  userRole !== UserRole.HEAD_OPERATOR &&
                  (isMobile
                    ? 'table__header-order'
                    : 'table__header-order-large')
            }`}
          >
            <span id="order_number">
              {t('OrdersPage.OrdersTable.headerTitles.ORDER')}
            </span>
          </th>
          {!isMobile && (
            <>
              <th
                className={`${tableHeaderClass} ${
                  userRole === UserRole.MANAGER ||
                  userRole === UserRole.PHARMACY_MANAGER
                    ? 'table__header-created-at'
                    : 'table__header-created-at-large'
                }`}
              >
                <span id="date">
                  {t('OrdersPage.OrdersTable.headerTitles.CREATED_AT')}
                </span>
              </th>
              {(userRole === UserRole.OPERATOR ||
                userRole === UserRole.ADMIN ||
                userRole === UserRole.HEAD_OPERATOR) && (
                <th className={`${tableHeaderClass} table__header-user-source`}>
                  {t('OrdersPage.OrdersTable.headerTitles.USER_SOURCE')}
                </th>
              )}
              {(userRole === UserRole.OPERATOR ||
                userRole === UserRole.ADMIN ||
                userRole === UserRole.HEAD_OPERATOR) && (
                <th className="table__header-cell table__header-phone-cell">
                  {t('OrdersPage.OrdersTable.headerTitles.PHONE')}
                </th>
              )}
              {(userRole === UserRole.OPERATOR ||
                userRole === UserRole.ADMIN ||
                userRole === UserRole.HEAD_OPERATOR ||
                userRole === UserRole.PHARMACY_MANAGER ||
                userRole === UserRole.MANAGER) && (
                <th
                  className={`table__header-cell table__header-source ${
                    userRole === UserRole.OPERATOR ||
                    userRole === UserRole.ADMIN ||
                    userRole === UserRole.HEAD_OPERATOR
                      ? 'table__header-source-operator'
                      : ''
                  }`}
                >
                  <span>{t('OrdersPage.OrdersTable.headerTitles.SOURCE')}</span>
                  <div
                    onClick={() => setIsFilterPharmacyOpen(true)}
                    className="img"
                  >
                    <img src={arrow} alt="arrow" />
                  </div>
                  {isFilterPharmacyOpen && (
                    <ModalWindow onClose={() => setIsFilterPharmacyOpen(false)}>
                      <input
                        className="input-status-filter"
                        type="text"
                        placeholder={t(
                          'ProductsPage.NETWORKS_MODAL.PLACEHOLDER'
                        )}
                        onChange={({ target: { value } }) =>
                          setSearchByName(value)
                        }
                      />
                      <div className="status-filter-accent">
                        <FilterBySource
                          isPharmacy
                          onClose={() => {}}
                          onSourceCLick={handleSetSource}
                          operatorFilter={
                            userRole === UserRole.OPERATOR ||
                            userRole === UserRole.HEAD_OPERATOR ||
                            userRole === UserRole.ADMIN
                          }
                          searchStr={searchByName}
                        />
                      </div>
                    </ModalWindow>
                  )}
                </th>
              )}
              {(userRole === UserRole.OPERATOR ||
                userRole === UserRole.ADMIN ||
                userRole === UserRole.HEAD_OPERATOR) && (
                <th className={`${tableHeaderClass} cell-collector`}>
                  {t('OrdersPage.OrdersTable.headerTitles.COLLECTOR')}
                </th>
              )}
              <th
                className={`${tableHeaderClass} table__header-cell-operator-delivery`}
                onClick={() => setFilterDeliveryOpen(!filterDeliveryOpen)}
              >
                <span id="delivery_type">
                  {t('OrdersPage.OrdersTable.headerTitles.DELIVERY')}
                </span>
                <div className="img">
                  <img src={arrow} alt="arrow" />
                </div>
                {filterDeliveryOpen && (
                  <div className="status-filter">
                    <FilterByDelivery
                      onClose={() => setFilterDeliveryOpen(false)}
                      onDeliveryCLick={handleSetDelivery}
                    />
                  </div>
                )}
              </th>
              {(userRole === UserRole.ADMIN ||
                userRole === UserRole.OPERATOR ||
                userRole === UserRole.HEAD_OPERATOR) && (
                <th
                  className={`${tableHeaderClass} padding-top status-delivery-cell`}
                >
                  <span id="status-delivery">Статус курьерской службы</span>
                </th>
              )}
            </>
          )}
          <th
            className={`${tableHeaderClass} table__header-status ${
              userRole === UserRole.ADMIN ||
              userRole === UserRole.OPERATOR ||
              userRole === UserRole.HEAD_OPERATOR
                ? 'padding-top'
                : ''
            }`}
            onClick={() => setFilterStatusOpen(!filterStatusOpen)}
          >
            <span id="status">{selectedStatus}</span>
            <div className="img">
              <img src={arrow} alt="arrow" />
            </div>
            {filterStatusOpen && (
              <div className="status-filter">
                <FilterByStatus
                  onClose={() => setFilterStatusOpen(false)}
                  onStatusClick={handleSetStatus}
                  onAllStatusClick={handleSetAllStatus}
                />
              </div>
            )}
          </th>
          {!isMobile &&
            (userRole === UserRole.PHARMACIST ||
              userRole === UserRole.MANAGER ||
              userRole === UserRole.PHARMACY_MANAGER) && (
              <th
                className={`${tableHeaderClass} table__header-payment-type`}
                onClick={() => setFilterPaymentTypeOpen(!filterPaymentTypeOpen)}
              >
                <span id="payment_type">
                  {t('OrdersPage.OrdersTable.headerTitles.PAYMENT')}
                </span>
                <div className="img">
                  <img src={arrow} alt="arrow" />
                </div>
                {filterPaymentTypeOpen && (
                  <div className="status-filter">
                    <FilterByPaymentType
                      onClose={() => setFilterPaymentTypeOpen(false)}
                      onDeliveryCLick={handleSetPaymentType}
                    />
                  </div>
                )}
              </th>
            )}
          <th
            className={`${tableHeaderClass} table__header-payment`}
            onClick={() => setFilterPaymentOpen(!filterPaymentOpen)}
          >
            <span
              id="payment_status"
              className={paymentOrders !== '' ? 'active-filter' : ''}
            >
              {t('OrdersPage.OrdersTable.headerTitles.STATUS_PAYMENT')}
            </span>
            <div className="img">
              <img src={arrow} alt="arrow" />
            </div>
            {filterPaymentOpen && (
              <div className="status-filter">
                <FilterByPayment
                  onClose={() => setFilterPaymentOpen(false)}
                  onPaymentCLick={handleSetPayment}
                />
              </div>
            )}
          </th>
          {!isMobile && (
            <>
              <th className={`${tableHeaderClass} table__header-amount-cell`}>
                {t('OrdersPage.OrdersTable.headerTitles.AMOUNT')}
              </th>
              {data &&
                data.every((order: OrderTableData) =>
                  order.source.includes('food')
                ) && (
                  <th
                    className={`${tableHeaderClass} table__header-amount-cell`}
                  >
                    {t('OrdersPage.OrdersTable.headerTitles.COMMENT')}
                  </th>
                )}
            </>
          )}
        </Styled.OrdersTableHeaderRow>
      </Styled.OrdersHead>
    ),
    [
      tableHeaderClass,
      t,
      userRole,
      isMobile,
      filterDeliveryOpen,
      filterStatusOpen,
      isFilterPharmacyOpen,
      filterPaymentTypeOpen,
      filterPaymentOpen,
      paymentOrders,
      selectedStatus,
      searchByName,
      handleSetSource,
      handleSetDelivery,
      handleSetStatus,
      handleSetAllStatus,
      handleSetPaymentType,
      handleSetPayment,
    ]
  );

  const handleOrderClick = useCallback(
    (order: OrderTableData) => {
      dispatch(actions.setOrderId(order.id));
      setToLocalStorage('selectedOrderId', order.id);
      if (
        userRole === UserRole.ADMIN ||
        userRole === UserRole.OPERATOR ||
        userRole === UserRole.HEAD_OPERATOR
      ) {
        history.push(`${history.location.pathname}/${order.id}`);
      } else if (
        (userRole === UserRole.PHARMACIST &&
          order.status !== StatusType.CANCELED &&
          order.status !== StatusType.COMPLETED) ||
        ((userRole === UserRole.MANAGER ||
          userRole === UserRole.PHARMACY_MANAGER) &&
          dataUser?.isCollector &&
          order.status !== StatusType.CANCELED &&
          order.status !== StatusType.COMPLETED)
      ) {
        if (activeUsers && activeUsers.length === 1) {
          onSetOrderPharmacist &&
            onSetOrderPharmacist({
              name: activeUsers[0].name,
              phone: activeUsers[0].phone,
            });
          selectedActivePharmacist && history.push(`/orders/${order.id}`);
        } else {
          order.collector.phone
            ? history.push(`${history.location.pathname}/${order.id}`)
            : onOrderClick && onOrderClick(true);
        }
      } else {
        history.push(`${history.location.pathname}/${order.id}`);
      }
    },
    [
      dispatch,
      history,
      userRole,
      dataUser,
      activeUsers,
      onSetOrderPharmacist,
      selectedActivePharmacist,
      onOrderClick,
    ]
  );

  const handleCloseOrderDetailedModal = useCallback(() => {
    dispatch(actions.clearOrderId());
    dispatch(actions.clearSelectedOrder());
    dispatch(actions.getOrders.request({ role, status: '' }));
    setOpenDetail(false);
  }, [dispatch, role]);

  const handleCancelOrder = useCallback((order: OrderTableData | null) => {
    setCancelOrder(order);
  }, []);

  const handleSubmitCancelOrder = useCallback(
    (message: string, id: string) => {
      setCancelOrder(null);
      dispatch(
        actions.cancelOrder.request({
          message,
          id,
          token,
          postEffect: () =>
            dispatch(actions.setSelectedOrder.request({ role, id: orderId })),
        })
      );
    },
    [dispatch, token, role, orderId]
  );

  const handleDeclineCancelOrder = useCallback(() => {
    setCancelOrder(null);
  }, []);

  const parentRef = useRef<HTMLDivElement>(null);
  // const virtualizer = useVirtualizer({
  //   count: data ? data.length : 0,
  //   getScrollElement: () => parentRef.current,
  //   estimateSize: () => 210,
  //   overscan: 5,
  // });
  /* eslint-disable react/no-unused-prop-types */
  // const Row = useCallback(
  //   ({ index, style }: { index: number; style: React.CSSProperties }) => {
  //     const order = data ? data[index] : null;
  //     if (!order) return null;
  //     return (
  //       <OrdersTableRow
  //         as="div"
  //         style={{ ...style, display: 'flex' }}
  //         data={order}
  //         onRowClick={handleOrderClick}
  //         dataByTab={dataByTab}
  //       />
  //     );
  //   },
  //   [data, dataByTab, handleOrderClick]
  // );

  return (
    <>
      {/* <Styled.OrdersTable>{TableHeader}</Styled.OrdersTable>
      <Styled.VirtualTableBody ref={parentRef}>
        <div
          style={{
            height: `${virtualizer.getTotalSize()}px`,
            position: 'relative',
            width: '100%',
          }}
        >
          {virtualizer.getVirtualItems().map((virtualRow: VirtualItem) => (
            <Styled.VirtualTableRow
              key={virtualRow.index}
              ref={(el: HTMLDivElement | null) =>
                virtualizer.measureElement(el)
              }
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                transform: `translateY(${virtualRow.start}px)`,
              }}
            >
              <Row index={virtualRow.index} style={{}} />
            </Styled.VirtualTableRow>
          ))}
        </div>
      </Styled.VirtualTableBody> */}
      <Styled.OrdersTable>
        {TableHeader}
        {data && (
          <tbody
            style={{ borderTop: '1px solid ${baseTheme.colors.stroking' }}
            id="table"
          >
            {data.map((item) => (
              <OrdersTableRow
                dataByTab={dataByTab}
                onRowClick={handleOrderClick}
                data={item}
                key={item.id}
                dataOrders={data}
              />
            ))}
          </tbody>
        )}
      </Styled.OrdersTable>
      {openDetail && (
        <OrderDetailedModal
          onClose={handleCloseOrderDetailedModal}
          onOrderCancel={handleCancelOrder}
        />
      )}
      {cancelOrder && (
        <OrderCancelModal
          data={cancelOrder}
          onDecline={handleDeclineCancelOrder}
          onSubmit={handleSubmitCancelOrder}
        />
      )}
    </>
  );
};

export default React.memo(OrdersTable);
