import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as actions from './actions';
import * as Styled from './OrderHistoryPage.styles';
import HistoryTable from './components/HistoryTable';
import { getFromLocalStorage } from '../../global/helpers';
import { selectors } from './reducer';
import Loader from '../../components/Loader';
import arrowNextIcon from '../../global/media/next-status-arrow.svg';

const OrderHistoryPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const orderId = history.location.pathname.split('/')[2];
  const createdAt = history.location.search.split('=')[1];
  const token = getFromLocalStorage('accessToken');

  const data = useSelector(selectors.historyPageData);
  const dataLoading = useSelector(selectors.historyPageLoading);

  useEffect(() => {
    dispatch(actions.getOrderHistory.request({ orderId, token, createdAt }));
  }, [dispatch]);

  return (
    <Styled.OrderHistoryContainer>
      {dataLoading && <Loader />}
      <div className="back" onClick={() => history.goBack()}>
        <img src={arrowNextIcon} alt="arrow next" />
        {t('OrderHistoryPage.BACK')}
      </div>
      {!dataLoading && data && <HistoryTable data={data} />}
    </Styled.OrderHistoryContainer>
  );
};

export default OrderHistoryPage;
