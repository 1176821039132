import React, { SyntheticEvent, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import * as Styled from './ProductsTableRow.styles';
import Button from '../../../../../../components/Button';
import Counter from '../../../../../../components/Counter';
import AnalogModal from '../../../../../../components/AnalogModal';
import {
  changeCountInProduct,
  changeOperatorCountAnalogInProduct,
  changeOperatorCountInProduct,
  changeCountAnnalogInProduct,
  getCurrentOrderProducts,
  getFromLocalStorage,
  changeVisiblePriceProduct,
  changeVisiblePriceAnalog,
} from '../../../../../../global/helpers';
import * as actions from '../../../../actions';
import { newPriceProduct } from '../../../../../OrdersPage/helpers';
import ConfirmationDeleteProduct from '../../../ConfirmationDeleteProduct';
import closeIcon from '../../../../../../global/media/modal-close-icon.svg';
import CustomCheckbox from '../../../../../../components/CustomCheckbox';
import { UserRole } from '../../../../../OrdersPage/types';
import { StatusType } from '../../../../../../global/types';
import { isDateFresh } from '../../../../../SalesPage/helpers';
import { selectors } from '../../../../reducer';

interface ProductsTableRowProps {
  data: any;
  source: string;
  orderId: string;
  itemIndex: number;
  handleOpenAnalogModal: () => void;
  handleCloseAnalogModal: () => void;
  onCountChange: (price: number) => void;
  isAnalogModalOpen: boolean;
  sku: string;
  editActive: boolean;
  editOperator: boolean;
  updateProductFieldById?: (id: string, field: string, value: any) => void;
}

const ProductsTableRow = ({
  data,
  itemIndex,
  source,
  handleOpenAnalogModal,
  handleCloseAnalogModal,
  isAnalogModalOpen,
  orderId,
  editActive,
  editOperator,
  onCountChange,
  updateProductFieldById,
}: ProductsTableRowProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const userRole = getFromLocalStorage('role');
  const currentOrder = getCurrentOrderProducts();
  const selectorProductTags = useSelector(selectors.productTags);
  const [isVisibleProductPrice, setVisibleProductPrice] = useState<boolean>(
    getFromLocalStorage('selectedOrder')[data.sku]?.visiblePrice
  );
  const [isVisibleAnalogPrice, setVisibleAnalogPrice] = useState<boolean>(
    getFromLocalStorage('selectedOrder')[data.sku]?.proposal?.visiblePrice
  );
  const [isBiggerImg, setIsBiggerImg] = useState<boolean>(false);
  const [dateInput, setDateInput] = useState<string>('');
  const [count, setCount] = useState(Number(data.pharmacyCount));
  const [quantity, setQuantity] = useState(Number(data.quantity));
  const [productTags, setProductTags] = useState<boolean>(false);
  const [analogQuantity, setAnalogQuantity] = useState(
    Number(data?.proposal?.count)
  );
  // eslint-disable-next-line
  const [analogCount, setAnalogCount] = useState(
    getFromLocalStorage('selectedOrder')[data.sku]?.proposal?.pharmacyCount
  );

  // eslint-disable-next-line
  useEffect(() => {
    if (data?.proposal) setAnalogQuantity(Number(data?.proposal?.count));
  }, [data?.proposal && data?.proposal?.count]);

  // eslint-disable-next-line
  useEffect(() => {
    const token = getFromLocalStorage('accessToken');

    if (data) {
      dispatch(
        actions.getProductsAtt.request({
          token,
          wereId: data.were_id.includes('_DISCOUNT')
            ? data.were_id.split('_DISCOUNT')[0]
            : data.were_id,
        })
      );
    }
  }, []);

  // eslint-disable-next-line
  useEffect(() => {
    const wereId = data.were_id.includes('_DISCOUNT')
      ? data.were_id.split('_')[0]
      : data.were_id;

    const tag = selectorProductTags?.find((tag) => tag.ware_id === wereId);

    if (selectorProductTags && data && tag && tag.tags) {
      const isAI = tag.tags.some((el) => el.name === 'Топ-100 V1 auto');
      if (isAI) {
        setProductTags(true);
      }
    }
  }, [selectorProductTags, data, dispatch]);

  const [deleteConfirmationOpenProduct, setDeleteConfirmationOpenProduct] =
    // eslint-disable-next-line
    useState<boolean>(false);
  const [deleteConfirmationOpenAnalog, setDeleteConfirmationOpenAnalog] =
    // eslint-disable-next-line
    useState<boolean>(false);

  const zeroCount = count === 0;
  const notEnough = count < data.quantity;
  const handleOpen = () => {
    dispatch(actions.setSelectedProduct(data.sku));
    handleOpenAnalogModal();
  };

  // eslint-disable-next-line
  useEffect(() => {
    if (data) {
      setVisibleProductPrice(
        getFromLocalStorage('selectedOrder')[data.sku]?.visiblePrice
      );
    }
  }, [getFromLocalStorage('selectedOrder')[data.sku]?.visiblePrice, data]);

  // eslint-disable-next-line
  useEffect(() => {
    if (data.proposal) {
      setVisibleAnalogPrice(
        getFromLocalStorage('selectedOrder')[data.sku]?.proposal?.visiblePrice
      );
    }
  }, [
    getFromLocalStorage('selectedOrder')[data.sku]?.proposal?.visiblePrice,
    data,
  ]);

  // eslint-disable-next-line
  useEffect(() => {
    if (
      data.proposal &&
      getFromLocalStorage('selectedOrder')[data.sku]?.proposal?.pharmacyCount
    ) {
      setAnalogCount(
        getFromLocalStorage('selectedOrder')[data.sku]?.proposal?.pharmacyCount
      );
    } else Number(data?.proposal?.count);
  }, [
    getFromLocalStorage('selectedOrder')[data.sku]?.proposal?.pharmacyCount,
    data,
  ]);

  // eslint-disable-next-line
  useEffect(() => {
    if (
      userRole === UserRole.OPERATOR ||
      userRole === UserRole.ADMIN ||
      userRole === UserRole.HEAD_OPERATOR
    ) {
      onCountChange(newPriceProduct(Object.values(currentOrder)));
    }
  }, [currentOrder[data.sku]?.currentPrice, currentOrder, userRole]);

  // eslint-disable-next-line
  useEffect(() => {
    if (
      userRole !== UserRole.OPERATOR &&
      userRole !== UserRole.ADMIN &&
      userRole !== UserRole.HEAD_OPERATOR
    ) {
      onCountChange(newPriceProduct(Object.values(currentOrder)));
    }
  }, [currentOrder[data.sku]?.proposal?.currentPrice, currentOrder, userRole]);

  const increaseCount = () => {
    const updatedCount = changeCountInProduct('plus', data.sku);
    setCount(updatedCount);
  };
  const decreaseCount = () => {
    const updatedCount = changeCountInProduct('minus', data.sku);
    setCount(updatedCount);
  };

  const increaseCountAnalog = () => {
    const updatedCount = changeCountAnnalogInProduct('plus', data.sku);
    setAnalogCount(updatedCount);
  };
  const decreaseCountAnalog = () => {
    const updatedCount = changeCountAnnalogInProduct('minus', data.sku);
    setAnalogCount(updatedCount);
  };

  const setCountProduct = (count: string | number) => {
    const updatedCount = changeCountInProduct('set', data.sku, Number(count));
    setQuantity(updatedCount);
  };

  const increaseOperatorCount = () => {
    const updatedCount = changeOperatorCountInProduct('plus', data.sku);
    setQuantity(updatedCount);
  };
  const decreaseOperatorCount = () => {
    const updatedCount = changeOperatorCountInProduct('minus', data.sku);
    setQuantity(updatedCount);
  };

  const increaseOperatorCountAnalog = () => {
    const updatedCount = changeOperatorCountAnalogInProduct('plus', data.sku);
    setAnalogQuantity(updatedCount);
  };
  const decreaseOperatorCountAnalog = () => {
    const updatedCount = changeOperatorCountAnalogInProduct('minus', data.sku);
    setAnalogQuantity(updatedCount);
  };

  const setCountOperatorProduct = (count: string | number) => {
    const updatedCount = changeOperatorCountInProduct(
      'set',
      data.sku,
      Number(count)
    );
    setCount(updatedCount);
  };

  const handleChangeProductPriceVisible = (value: boolean) => {
    changeVisiblePriceProduct(currentOrder[data.sku], value);
    setVisibleProductPrice(value);
  };

  const handleChangeAnalogPriceVisible = (value: boolean) => {
    changeVisiblePriceAnalog(currentOrder[data.sku], value);
    setVisibleAnalogPrice(value);
  };

  const productImgClickHandler = () => {
    setIsBiggerImg(!isBiggerImg);
  };
  const product = getFromLocalStorage('selectedOrder')[data.sku] || data;

  const analog = getFromLocalStorage('selectedOrder')[data.sku]?.proposal;

  function removeImgBrokenIcon(event: SyntheticEvent<HTMLImageElement, Event>) {
    const brokenImage = event.target as HTMLImageElement;
    const tagName: string = 'alt';

    const brokenImageAttributeValue = brokenImage.getAttribute(
      tagName
    ) as string;

    const alternateTextFromBrokenImage = document.createTextNode(
      userRole === UserRole.ADMIN ||
        userRole === UserRole.OPERATOR ||
        userRole === UserRole.HEAD_OPERATOR
        ? ''
        : brokenImageAttributeValue
    );

    brokenImage.parentNode?.insertBefore(
      alternateTextFromBrokenImage,
      brokenImage
    );

    brokenImage.parentNode?.removeChild(brokenImage);
  }

  const handleCompare = (str1: string, str2: string) => {
    if (!str1 || !str2) {
      return false;
    }

    const str1Numbers = (str1.match(/\d+([.,]\d+)?/g) || []).sort().join('');

    const str2Numbers = (str2.match(/\d+([.,]\d+)?/g) || []).sort().join('');
    return str1Numbers === str2Numbers;
  };

  const isOperatorOrAdmin =
    userRole === UserRole.OPERATOR ||
    userRole === UserRole.ADMIN ||
    userRole === UserRole.HEAD_OPERATOR;

  return (
    <>
      {product && (
        <>
          <Styled.ProductsTableRow>
            <td
              className={`table-cell item-number   ${
                userRole !== UserRole.OPERATOR &&
                userRole !== UserRole.HEAD_OPERATOR &&
                userRole !== UserRole.ADMIN &&
                (editActive
                  ? 'item-number-pharmacy'
                  : 'item-number-pharmacy-edit')
              }  ${
                isOperatorOrAdmin &&
                (editOperator
                  ? 'item-number-operator'
                  : 'item-number-operator-edit')
              }`}
            >
              {itemIndex}
            </td>
            <td
              className={`table-cell table-cell__info ${
                userRole !== UserRole.OPERATOR &&
                userRole !== UserRole.HEAD_OPERATOR &&
                userRole !== UserRole.ADMIN &&
                (editActive
                  ? 'table-cell__info-pharmacy'
                  : 'table-cell__info-pharmacy-edit')
              }  ${
                isOperatorOrAdmin &&
                (editOperator
                  ? 'table-cell__info-operator'
                  : 'table-cell__info-operator-edit')
              }  ${
                isOperatorOrAdmin &&
                (handleCompare(data.name, data.productPharmacyName)
                  ? 'table-cell__info-operator'
                  : 'table-cell__info-operator-accent')
              }`}
            >
              <div className={isMobile ? '' : 'grid-block'}>
                <div className="flex-block">
                  <div className="name">{data.name}</div>
                  <div className="manufacturer">{data.manufacturer}</div>
                  {Boolean(data.needsRecipe) && (
                    <div className="needs-recipe">
                      {t(
                        'OrdersPage.OrderDetailedModal.ProductsTable.NEEDS_RECIPE'
                      )}
                    </div>
                  )}
                  {(isOperatorOrAdmin || userRole === UserRole.PHARMACIST) && (
                    <>
                      <div onClick={productImgClickHandler}>
                        <img
                          style={{
                            scale: !isBiggerImg ? '1' : '1.3',
                            cursor: 'pointer',
                          }}
                          width="120px"
                          height="120px"
                          src={`https://db-images.object.pscloud.io/optimized_v4_img_small_${
                            data.sku.includes('_discount')
                              ? data.sku.split('_')[0]
                              : data.sku
                          }.webp`}
                          alt={data.pharmacyName}
                          onError={(event) => removeImgBrokenIcon(event)}
                        />
                      </div>
                      {product && product.needsStrongRecipe && (
                        <div
                          className="needs-recipe"
                          style={{ color: '#ff0000' }}
                        >
                          {t(
                            'OrdersPage.OrderDetailedModal.ProductsTable.NEEDS_STRONG_RECIPE'
                          )}
                        </div>
                      )}
                      {productTags && (
                        <p className="ai-description">
                          {t('OrdersPage.AI_CONNECTED')}
                        </p>
                      )}
                      {product.sku.includes('discount') &&
                        !isDateFresh(product.sku.split('_')[3]) && (
                          <span className="sale-date-info">
                            {t('OrdersPage.EXPIRES_ON')}
                            {isMobile && <br />}
                            {product.sku.split('_')[3]}
                          </span>
                        )}
                    </>
                  )}
                </div>
                {userRole === UserRole.PHARMACIST ||
                userRole === UserRole.MANAGER ||
                userRole === UserRole.PHARMACY_MANAGER ? (
                  !(
                    isMobile && (
                      <div onClick={productImgClickHandler}>
                        <img
                          style={{
                            scale: !isBiggerImg ? '1' : '1.3',
                            cursor: 'pointer',
                          }}
                          width="120px"
                          height="120px"
                          src={`https://db-images.object.pscloud.io/optimized_v4_img_small_${
                            data.sku.includes('_discount')
                              ? data.sku.split('_')[0]
                              : data.sku
                          }.webp`}
                          alt={data.pharmacyName}
                          onError={(event) => removeImgBrokenIcon(event)}
                        />
                      </div>
                    )
                  )
                ) : (
                  <div className="name">
                    <div>{data.productPharmacyName}</div>
                  </div>
                )}
              </div>
            </td>
            <td className="flex-container-column">
              <div className="flex-container">
                <div
                  className={`table-cell quantity  ${
                    userRole !== UserRole.OPERATOR &&
                    userRole !== UserRole.HEAD_OPERATOR &&
                    userRole !== UserRole.ADMIN &&
                    (editActive
                      ? 'quantity-pharmacy'
                      : 'quantity-pharmacy-edit')
                  }  ${
                    isOperatorOrAdmin &&
                    (editOperator
                      ? 'quantity-operator'
                      : 'quantity-operator-edit')
                  }`}
                >
                  {userRole !== UserRole.OPERATOR &&
                    userRole !== UserRole.HEAD_OPERATOR &&
                    userRole !== UserRole.ADMIN && (
                      <span>{`${data.quantity} шт`}</span>
                    )}
                  {isOperatorOrAdmin && editOperator && (
                    <span>{`${data.quantity} шт`}</span>
                  )}
                  {isOperatorOrAdmin && !editOperator && (
                    <div className="counter">
                      <Counter
                        value={quantity}
                        colorCount={`${zeroCount && 'zero'} ${
                          notEnough && 'not-enough'
                        }`}
                        increaseCount={increaseOperatorCount}
                        decreaseCount={decreaseOperatorCount}
                        setCountInCart={setCountOperatorProduct}
                      />
                    </div>
                  )}
                </div>
                {!isMobile && editActive && (
                  <div
                    className={`table-cell amount  ${
                      userRole !== UserRole.OPERATOR &&
                      userRole !== UserRole.HEAD_OPERATOR &&
                      userRole !== UserRole.ADMIN &&
                      (editActive
                        ? ` amount-pharmacy ${
                            userRole !== UserRole.OPERATOR &&
                            userRole !== UserRole.HEAD_OPERATOR &&
                            userRole !== UserRole.ADMIN &&
                            editActive &&
                            data.status === StatusType.ON_CLARIFICATION &&
                            'amount-pharmacy-20'
                          }`
                        : 'amount-pharmacy-edit')
                    }   ${
                      isOperatorOrAdmin &&
                      (editOperator
                        ? 'amount-operator'
                        : 'amount-operator-edit')
                    } `}
                  >
                    <div
                      className={
                        isOperatorOrAdmin &&
                        data.promoCode &&
                        data.promoCode.discount_target !== 'delivery'
                          ? 'table-cell__amount-discount'
                          : ''
                      }
                    >
                      {data.amount}
                    </div>
                    {isOperatorOrAdmin &&
                      data.promoCode &&
                      data.promoCode.discount_target !== 'delivery' && (
                        <div>
                          - {data.promoCode.value}{' '}
                          {data.promoCode?.discount_type === 'money'
                            ? '₸'
                            : '%'}
                        </div>
                      )}
                  </div>
                )}
                {isOperatorOrAdmin && (
                  <div
                    className={`table-cell amount   ${
                      isOperatorOrAdmin &&
                      (editOperator
                        ? 'amount-operator'
                        : 'amount-operator-edit')
                    } `}
                  >
                    {/* {data.minPrice === data.avgPrice &&
                    data.avgPrice === data.maxPrice
                      ? data?.avgPrice
                      : `${data?.minPrice}/${data?.avgPrice}/${data?.maxPrice}`} */}
                    {`${data.quantity * data.price} ₸`}
                  </div>
                )}
                <div
                  className={`table-cell counter ${
                    userRole !== UserRole.OPERATOR &&
                    userRole !== UserRole.HEAD_OPERATOR &&
                    userRole !== UserRole.ADMIN &&
                    (editActive
                      ? `counter-pharmacy ${
                          data.status === StatusType.ON_CLARIFICATION &&
                          'counter-pharmacy-20'
                        }`
                      : 'counter-pharmacy-edit')
                  }  ${
                    isOperatorOrAdmin &&
                    (editOperator
                      ? 'counter-operator'
                      : 'counter-operator-edit')
                  }`}
                >
                  <div
                    className={`counter counter-operator ${
                      zeroCount && 'zero'
                    } ${notEnough && 'not-enough'}`}
                  >
                    {(userRole === UserRole.OPERATOR ||
                      userRole === UserRole.ADMIN ||
                      userRole === UserRole.HEAD_OPERATOR ||
                      (userRole !== UserRole.OPERATOR &&
                        userRole !== UserRole.ADMIN &&
                        userRole !== UserRole.HEAD_OPERATOR &&
                        editActive &&
                        data.status === StatusType.ON_CLARIFICATION)) && (
                      <span>{`${
                        currentOrder[data.sku]?.pharmacyCount ||
                        data.pharmacyCount
                      } шт`}</span>
                    )}
                    {!editActive &&
                      userRole !== UserRole.OPERATOR &&
                      userRole !== UserRole.HEAD_OPERATOR &&
                      userRole !== UserRole.ADMIN && (
                        <Counter
                          value={count}
                          colorCount={`${zeroCount && 'zero'} ${
                            notEnough && 'not-enough'
                          }`}
                          increaseCount={increaseCount}
                          decreaseCount={decreaseCount}
                          setCountInCart={setCountProduct}
                        />
                      )}
                  </div>
                </div>

                {!editActive &&
                  userRole !== UserRole.OPERATOR &&
                  userRole !== UserRole.HEAD_OPERATOR &&
                  userRole !== UserRole.ADMIN && (
                    <div
                      className={`table-cell amount ${
                        editActive ? 'amount-edit' : 'amount-pharmacy-new-edit'
                      }  ${
                        isOperatorOrAdmin &&
                        (editOperator
                          ? 'amount-operator-new'
                          : 'amount-operator-new-edit')
                      } `}
                    >
                      {`${currentOrder[data.sku]?.currentPrice.toFixed(2)} ₸`}
                    </div>
                  )}
                {!editActive &&
                  userRole !== UserRole.OPERATOR &&
                  userRole !== UserRole.HEAD_OPERATOR &&
                  userRole !== UserRole.ADMIN && (
                    <div className="table-cell analog-btn">
                      <div className="btn">
                        {!isMobile && (
                          <Button
                            onClick={handleOpen}
                            text={t(
                              'OrdersPage.OrderDetailedModal.ProductsTable.ANALOG'
                            )}
                          />
                        )}
                      </div>
                    </div>
                  )}
                {!editOperator && isOperatorOrAdmin && (
                  <div
                    className={`table-cell amount ${
                      userRole !== UserRole.OPERATOR &&
                      userRole !== UserRole.HEAD_OPERATOR &&
                      userRole !== UserRole.ADMIN &&
                      !editActive &&
                      'amount-edit'
                    }  ${
                      isOperatorOrAdmin &&
                      (editOperator
                        ? 'amount-operator-new'
                        : 'amount-operator-new-edit')
                    } `}
                  >
                    <div className="current-price-block">
                      {`${currentOrder[data.sku]?.currentPrice.toFixed(2)} ₸`}
                      {userRole === 'operator' && !editOperator && (
                        <div className="current-price-checkbox">
                          <CustomCheckbox
                            checked={isVisibleProductPrice}
                            onChange={(e) => handleChangeProductPriceVisible(e)}
                            id={data.sku}
                          />
                        </div>
                      )}
                    </div>
                    {isOperatorOrAdmin && !editOperator && (
                      <Styled.ProductDelete>
                        {/* eslint-disable-next-line */}
                        <img
                          src={closeIcon}
                          alt="close icon"
                          onClick={() => setDeleteConfirmationOpenAnalog(true)}
                        />
                      </Styled.ProductDelete>
                    )}
                  </div>
                )}
              </div>

              {!editOperator &&
                !editActive &&
                userRole === UserRole.PHARMACIST &&
                !isMobile && (
                  <div className="checkbox-container">
                    <div className="form-container">
                      <div className="container-table">
                        <div className="flex-container">
                          <div className="cell">
                            <input
                              id="term"
                              name="term"
                              type="checkbox"
                              disabled={false}
                              checked={
                                data?.item?.term?.is_term
                                  ? data.item.term.is_term
                                  : false
                              }
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                updateProductFieldById &&
                                  updateProductFieldById(
                                    product.sku,
                                    'item.term.is_term',
                                    isChecked
                                  );
                              }}
                            />
                            Сроковый
                          </div>

                          {data?.item?.term?.is_term && (
                            <div className="cell">
                              <input
                                id="termDate"
                                name="termDate"
                                type="text"
                                disabled={false}
                                placeholder="10.2001"
                                className="input-data"
                                value={dateInput}
                                onChange={(e) => {
                                  let value = e.target.value.replace(/\D/g, '');
                                  value = value.slice(0, 6);
                                  if (value.length >= 2) {
                                    value =
                                      // eslint-disable-next-line
                                      value.slice(0, 2) + '.' + value.slice(2);
                                  }
                                  setDateInput(value);
                                  updateProductFieldById &&
                                    updateProductFieldById(
                                      product.sku,
                                      'item.term.exp_date',
                                      value
                                    );
                                }}
                                onKeyDown={(e) => {
                                  const inputElement =
                                    e.target as HTMLInputElement;
                                  if (
                                    !(
                                      e.key === 'Backspace' ||
                                      e.key === 'Delete' ||
                                      e.key === '.'
                                    )
                                  ) {
                                    // eslint-disable-next-line
                                    if (isNaN(parseInt(e.key))) {
                                      e.preventDefault();
                                    }
                                  } else if (
                                    e.key === 'Backspace' &&
                                    inputElement.selectionStart ===
                                      inputElement.selectionEnd &&
                                    inputElement.selectionStart === 3
                                  ) {
                                    // eslint-disable-next-line
                                    let value = inputElement.value;
                                    value = value.slice(0, 2) + value.slice(3);
                                    inputElement.value = value;
                                    setDateInput(value);
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </div>
                          )}
                          {data?.item?.term?.is_term && (
                            <div className="cell">
                              Предлагаемая цена
                              <input
                                id="termPrice"
                                name="termPrice"
                                type="text"
                                disabled={false}
                                className="input"
                                onChange={(e) => {
                                  const isChecked = e.target.value;
                                  updateProductFieldById &&
                                    updateProductFieldById(
                                      product.sku,
                                      'item.term.offer_price',
                                      isChecked
                                    );
                                }}
                              />
                            </div>
                          )}
                        </div>
                        <div className="flex-container">
                          <div className="cell">
                            <input
                              id="notAvailable"
                              name="notAvailable"
                              type="checkbox"
                              disabled={false}
                              checked={
                                data?.item?.out_of_stock?.is_out_of_stock
                                  ? data.item.out_of_stock.is_out_of_stock
                                  : false
                              }
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                updateProductFieldById &&
                                  updateProductFieldById(
                                    product.sku,
                                    'item.out_of_stock.is_out_of_stock',
                                    isChecked
                                  );
                              }}
                            />
                            Нет в наличии
                          </div>

                          {data?.item?.out_of_stock?.is_out_of_stock && (
                            <div className="cell">
                              По базе
                              <input
                                id="notAvailableBase"
                                name="notAvailableBase"
                                type="checkbox"
                                disabled={false}
                                checked={
                                  data?.item?.out_of_stock?.in_database
                                    ? data.item.out_of_stock.in_database
                                    : false
                                }
                                onChange={(e) => {
                                  const isChecked = e.target.checked;
                                  updateProductFieldById &&
                                    updateProductFieldById(
                                      product.sku,
                                      'item.out_of_stock.in_database',
                                      isChecked
                                    );
                                }}
                              />
                            </div>
                          )}
                          {data?.item?.out_of_stock?.is_out_of_stock && (
                            <div className="cell">
                              По факту
                              <input
                                id="notAvailableFact"
                                name="notAvailableFact"
                                type="checkbox"
                                disabled={false}
                                checked={
                                  data?.item?.out_of_stock?.in_fact
                                    ? data.item.out_of_stock.in_fact
                                    : false
                                }
                                onChange={(e) => {
                                  const isChecked = e.target.checked;
                                  updateProductFieldById &&
                                    updateProductFieldById(
                                      product.sku,
                                      'item.out_of_stock.in_fact',
                                      isChecked
                                    );
                                }}
                              />
                            </div>
                          )}
                        </div>

                        <div className="flex-container">
                          <div className="cell">
                            <input
                              id="differentPrice"
                              name="differentPrice"
                              type="checkbox"
                              disabled={false}
                              checked={
                                data?.item?.other_price?.is_other_price
                                  ? data.item.other_price.is_other_price
                                  : false
                              }
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                updateProductFieldById &&
                                  updateProductFieldById(
                                    product.sku,
                                    'item.other_price.is_other_price',
                                    isChecked
                                  );
                              }}
                            />
                            Другая цена по базе
                          </div>
                          {data?.item?.other_price?.is_other_price && (
                            <div className="cell">
                              Ваша цена
                              <input
                                id="yourPrice"
                                name="yourPrice"
                                type="text"
                                disabled={false}
                                className="input"
                                onChange={(e) => {
                                  const isChecked = e.target.value;
                                  updateProductFieldById &&
                                    updateProductFieldById(
                                      product.sku,
                                      'item.other_price.price',
                                      isChecked
                                    );
                                }}
                              />
                            </div>
                          )}
                        </div>

                        <div className="flex-container">
                          <div className="cell">
                            <input
                              id="differentPack"
                              name="differentPack"
                              type="checkbox"
                              disabled={false}
                              checked={
                                data?.item?.packing_diff?.is_packing_diff
                                  ? data.item.packing_diff.is_packing_diff
                                  : false
                              }
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                updateProductFieldById &&
                                  updateProductFieldById(
                                    product.sku,
                                    'item.packing_diff.is_packing_diff',
                                    isChecked
                                  );
                              }}
                            />
                            Есть разница в упаковке, в виде
                          </div>

                          {data?.item?.packing_diff?.is_packing_diff && (
                            <div className="cell">
                              <input
                                id="differentPackDescription"
                                name="differentPackDescription"
                                type="text"
                                placeholder="комментарий"
                                disabled={false}
                                className="input-accent"
                                onChange={(e) => {
                                  const isChecked = e.target.value;
                                  updateProductFieldById &&
                                    updateProductFieldById(
                                      product.sku,
                                      'item.packing_diff.diff',
                                      isChecked
                                    );
                                }}
                              />
                            </div>
                          )}
                        </div>
                        <div className="cell">
                          <input
                            id="needRecipe"
                            name="needRecipe"
                            type="checkbox"
                            disabled={false}
                            checked={
                              data?.item?.need_receipt
                                ? data.item.need_receipt
                                : false
                            }
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              updateProductFieldById &&
                                updateProductFieldById(
                                  product.sku,
                                  'item.need_receipt',
                                  isChecked
                                );
                            }}
                          />
                          Нужен рецепт
                        </div>
                      </div>
                    </div>
                  </div>
                )}
            </td>
          </Styled.ProductsTableRow>
          {!editOperator &&
            !editActive &&
            userRole === UserRole.PHARMACIST &&
            isMobile && (
              <div className="checkbox-container">
                <div className="form-container">
                  <div className="container-table">
                    <div
                      className="flex-container"
                      style={{
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                      }}
                    >
                      <div className="cell">
                        <input
                          id="term"
                          name="term"
                          type="checkbox"
                          disabled={false}
                          checked={
                            data?.item?.term?.is_term
                              ? data.item.term.is_term
                              : false
                          }
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            updateProductFieldById &&
                              updateProductFieldById(
                                product.sku,
                                'item.term.is_term',
                                isChecked
                              );
                          }}
                        />
                        Сроковый
                      </div>

                      {data?.item?.term?.is_term && (
                        <div className="cell">
                          <input
                            id="termDate"
                            name="termDate"
                            type="text"
                            disabled={false}
                            placeholder="10.2001"
                            className="input-data"
                            value={dateInput}
                            onChange={(e) => {
                              let value = e.target.value.replace(/\D/g, '');
                              value = value.slice(0, 6);
                              if (value.length >= 2) {
                                value =
                                  // eslint-disable-next-line
                                  value.slice(0, 2) + '.' + value.slice(2);
                              }
                              setDateInput(value);
                              updateProductFieldById &&
                                updateProductFieldById(
                                  product.sku,
                                  'item.term.exp_date',
                                  value
                                );
                            }}
                            onKeyDown={(e) => {
                              const inputElement = e.target as HTMLInputElement;
                              if (
                                !(
                                  e.key === 'Backspace' ||
                                  e.key === 'Delete' ||
                                  e.key === '.'
                                )
                              ) {
                                // eslint-disable-next-line
                                if (isNaN(parseInt(e.key))) {
                                  e.preventDefault();
                                }
                              } else if (
                                e.key === 'Backspace' &&
                                inputElement.selectionStart ===
                                  inputElement.selectionEnd &&
                                inputElement.selectionStart === 3
                              ) {
                                // eslint-disable-next-line
                                let value = inputElement.value;
                                value = value.slice(0, 2) + value.slice(3);
                                inputElement.value = value;
                                setDateInput(value);
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                      )}
                      {data?.item?.term?.is_term && (
                        <div className="cell">
                          Предлагаемая цена
                          <input
                            id="termPrice"
                            name="termPrice"
                            type="text"
                            disabled={false}
                            className="input"
                            style={{ marginLeft: 10 }}
                            onChange={(e) => {
                              const isChecked = e.target.value;
                              updateProductFieldById &&
                                updateProductFieldById(
                                  product.sku,
                                  'item.term.offer_price',
                                  isChecked
                                );
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div
                      className="flex-container"
                      style={{
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                      }}
                    >
                      <div className="cell">
                        <input
                          id="notAvailable"
                          name="notAvailable"
                          type="checkbox"
                          disabled={false}
                          checked={
                            data?.item?.out_of_stock?.is_out_of_stock
                              ? data.item.out_of_stock.is_out_of_stock
                              : false
                          }
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            updateProductFieldById &&
                              updateProductFieldById(
                                product.sku,
                                'item.out_of_stock.is_out_of_stock',
                                isChecked
                              );
                          }}
                        />
                        Нет в наличии
                      </div>

                      {data?.item?.out_of_stock?.is_out_of_stock && (
                        <div className="cell">
                          По базе
                          <input
                            id="notAvailableBase"
                            name="notAvailableBase"
                            type="checkbox"
                            disabled={false}
                            checked={
                              data?.item?.out_of_stock?.in_database
                                ? data.item.out_of_stock.in_database
                                : false
                            }
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              updateProductFieldById &&
                                updateProductFieldById(
                                  product.sku,
                                  'item.out_of_stock.in_database',
                                  isChecked
                                );
                            }}
                          />
                        </div>
                      )}
                      {data?.item?.out_of_stock?.is_out_of_stock && (
                        <div className="cell">
                          По факту
                          <input
                            id="notAvailableFact"
                            name="notAvailableFact"
                            type="checkbox"
                            disabled={false}
                            checked={
                              data?.item?.out_of_stock?.in_fact
                                ? data.item.out_of_stock.in_fact
                                : false
                            }
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              updateProductFieldById &&
                                updateProductFieldById(
                                  product.sku,
                                  'item.out_of_stock.in_fact',
                                  isChecked
                                );
                            }}
                          />
                        </div>
                      )}
                    </div>

                    <div
                      className="flex-container"
                      style={{
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                      }}
                    >
                      <div className="cell">
                        <input
                          id="differentPrice"
                          name="differentPrice"
                          type="checkbox"
                          disabled={false}
                          checked={
                            data?.item?.other_price?.is_other_price
                              ? data.item.other_price.is_other_price
                              : false
                          }
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            updateProductFieldById &&
                              updateProductFieldById(
                                product.sku,
                                'item.other_price.is_other_price',
                                isChecked
                              );
                          }}
                        />
                        Другая цена по базе
                      </div>
                      {data?.item?.other_price?.is_other_price && (
                        <div className="cell">
                          Ваша цена
                          <input
                            id="yourPrice"
                            name="yourPrice"
                            type="text"
                            disabled={false}
                            className="input"
                            style={{ marginLeft: 10 }}
                            onChange={(e) => {
                              const isChecked = e.target.value;
                              updateProductFieldById &&
                                updateProductFieldById(
                                  product.sku,
                                  'item.other_price.price',
                                  isChecked
                                );
                            }}
                          />
                        </div>
                      )}
                    </div>

                    <div
                      className="flex-container"
                      style={{
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                      }}
                    >
                      <div className="cell">
                        <input
                          id="differentPack"
                          name="differentPack"
                          type="checkbox"
                          disabled={false}
                          checked={
                            data?.item?.packing_diff?.is_packing_diff
                              ? data.item.packing_diff.is_packing_diff
                              : false
                          }
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            updateProductFieldById &&
                              updateProductFieldById(
                                product.sku,
                                'item.packing_diff.is_packing_diff',
                                isChecked
                              );
                          }}
                        />
                        Есть разница в упаковке, в виде
                      </div>

                      {data?.item?.packing_diff?.is_packing_diff && (
                        <div className="cell">
                          <input
                            id="differentPackDescription"
                            name="differentPackDescription"
                            type="text"
                            placeholder="комментарий"
                            disabled={false}
                            className="input-accent"
                            onChange={(e) => {
                              const isChecked = e.target.value;
                              updateProductFieldById &&
                                updateProductFieldById(
                                  product.sku,
                                  'item.packing_diff.diff',
                                  isChecked
                                );
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div className="cell">
                      <input
                        id="needRecipe"
                        name="needRecipe"
                        type="checkbox"
                        disabled={false}
                        checked={
                          data?.item?.need_receipt
                            ? data.item.need_receipt
                            : false
                        }
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          updateProductFieldById &&
                            updateProductFieldById(
                              product.sku,
                              'item.need_receipt',
                              isChecked
                            );
                        }}
                      />
                      Нужен рецепт
                    </div>
                  </div>
                </div>
              </div>
            )}
          {analog && (
            <>
              <Styled.Analog>Аналог</Styled.Analog>
              <Styled.AnalogRow>
                <td
                  className={`table-cell item-number  ${
                    userRole !== UserRole.OPERATOR &&
                    userRole !== UserRole.HEAD_OPERATOR &&
                    userRole !== UserRole.ADMIN &&
                    (editActive
                      ? 'item-number-pharmacy'
                      : 'item-number-pharmacy-edit')
                  }  ${
                    (userRole === UserRole.OPERATOR ||
                      userRole === UserRole.HEAD_OPERATOR ||
                      userRole === UserRole.ADMIN) &&
                    (editOperator
                      ? 'item-number-operator'
                      : 'item-number-operator-edit')
                  }`}
                />
                <td
                  className={`table-cell table-cell__info ${
                    userRole !== UserRole.OPERATOR &&
                    userRole !== UserRole.HEAD_OPERATOR &&
                    userRole !== UserRole.ADMIN &&
                    (editActive
                      ? 'table-cell__info-pharmacy'
                      : 'table-cell__info-pharmacy-edit')
                  }  ${
                    (userRole === UserRole.OPERATOR ||
                      userRole === UserRole.HEAD_OPERATOR ||
                      userRole === UserRole.ADMIN) &&
                    (editOperator
                      ? 'table-cell__info-operator'
                      : 'table-cell__info-operator-edit')
                  }`}
                >
                  <div className="grid-block">
                    <div className="flex-block">
                      <div className="name">{analog.name}</div>
                      <div className="manufacturer">{analog.manufacturer}</div>
                      {Boolean(analog.needsRecipe) && (
                        <div className="needs-recipe">
                          {t(
                            'OrdersPage.OrderDetailedModal.ProductsTable.NEEDS_RECIPE'
                          )}
                        </div>
                      )}
                    </div>
                    <div className="name">{analog.skuName}</div>
                    <div />
                  </div>
                </td>
                <td
                  className={`table-cell quantity  ${
                    userRole !== UserRole.OPERATOR &&
                    userRole !== UserRole.HEAD_OPERATOR &&
                    userRole !== UserRole.ADMIN &&
                    (editActive
                      ? 'quantity-pharmacy'
                      : 'quantity-pharmacy-edit')
                  }  ${
                    (userRole === UserRole.OPERATOR ||
                      userRole === UserRole.HEAD_OPERATOR ||
                      userRole === UserRole.ADMIN) &&
                    (editOperator
                      ? 'quantity-operator'
                      : 'quantity-operator-edit')
                  }`}
                >
                  {userRole !== UserRole.OPERATOR &&
                    userRole !== UserRole.HEAD_OPERATOR &&
                    userRole !== UserRole.ADMIN && (
                      <span>{`${analog.count} шт`}</span>
                    )}
                  {(userRole === UserRole.OPERATOR ||
                    userRole === UserRole.HEAD_OPERATOR ||
                    userRole === UserRole.ADMIN) &&
                    editOperator && <span>{`${analog.count} шт`}</span>}
                  {(userRole === UserRole.OPERATOR ||
                    userRole === UserRole.HEAD_OPERATOR ||
                    userRole === UserRole.ADMIN) &&
                    !editOperator && (
                      <div className="counter">
                        <Counter
                          value={analogQuantity}
                          colorCount={`${zeroCount && 'zero'} ${
                            notEnough && 'not-enough'
                          }`}
                          increaseCount={increaseOperatorCountAnalog}
                          decreaseCount={decreaseOperatorCountAnalog}
                          setCountInCart={setCountOperatorProduct}
                        />
                      </div>
                    )}
                </td>
                <td
                  className={`table-cell amount  ${
                    userRole !== UserRole.OPERATOR &&
                    userRole !== UserRole.HEAD_OPERATOR &&
                    userRole !== UserRole.ADMIN &&
                    (editActive
                      ? ` amount-pharmacy ${
                          userRole !== UserRole.OPERATOR &&
                          userRole !== UserRole.HEAD_OPERATOR &&
                          userRole !== UserRole.ADMIN &&
                          editActive &&
                          data.status === StatusType.ON_CLARIFICATION &&
                          'amount-pharmacy-20'
                        }`
                      : 'amount-pharmacy-edit')
                  }   ${
                    (userRole === UserRole.OPERATOR ||
                      userRole === UserRole.HEAD_OPERATOR ||
                      userRole === UserRole.ADMIN) &&
                    (editOperator ? 'amount-operator' : 'amount-operator-edit')
                  } `}
                >
                  {!analog?.amount
                    ? userRole !== UserRole.OPERATOR &&
                      userRole !== UserRole.HEAD_OPERATOR &&
                      userRole !== UserRole.ADMIN &&
                      editActive && (
                        <span>
                          {' '}
                          {`${currentOrder[
                            data.sku
                          ].proposal?.currentPrice.toFixed(2)} ₸`}
                        </span>
                      )
                    : data.proposal?.amount}
                </td>
                {(userRole === UserRole.OPERATOR ||
                  userRole === UserRole.HEAD_OPERATOR ||
                  userRole === UserRole.ADMIN) && (
                  <td
                    className={`table-cell amount   ${
                      (userRole === UserRole.OPERATOR ||
                        userRole === UserRole.HEAD_OPERATOR ||
                        userRole === UserRole.ADMIN) &&
                      (editOperator
                        ? 'amount-operator'
                        : 'amount-operator-edit')
                    } `}
                  >
                    {data.proposal?.minPrice === data.proposal?.avgPrice &&
                    data.proposal?.avgPrice === data.proposal?.maxPrice
                      ? data?.proposal?.avgPrice
                      : `${data?.proposal?.minPrice}/${data?.proposal?.avgPrice}/${data?.proposal?.maxPrice}`}
                  </td>
                )}
                <td
                  className={`table-cell counter ${
                    userRole !== UserRole.OPERATOR &&
                    userRole !== UserRole.HEAD_OPERATOR &&
                    userRole !== UserRole.ADMIN &&
                    (editActive
                      ? ` counter-pharmacy ${
                          data.status === StatusType.ON_CLARIFICATION &&
                          'counter-pharmacy-20'
                        }`
                      : 'counter-pharmacy-edit')
                  }  ${
                    (userRole === UserRole.OPERATOR ||
                      userRole === UserRole.HEAD_OPERATOR ||
                      userRole === UserRole.ADMIN) &&
                    (editOperator
                      ? 'counter-operator'
                      : 'counter-operator-edit')
                  }`}
                >
                  <div className="counter">
                    {(userRole === UserRole.OPERATOR ||
                      userRole === UserRole.ADMIN ||
                      userRole === UserRole.HEAD_OPERATOR ||
                      (userRole !== UserRole.OPERATOR &&
                        userRole !== UserRole.HEAD_OPERATOR &&
                        userRole !== UserRole.ADMIN &&
                        editActive &&
                        data.status === StatusType.ON_CLARIFICATION)) && (
                      <span>{`${analog.pharmacyCount} шт`}</span>
                    )}
                    {userRole !== UserRole.OPERATOR &&
                      userRole !== UserRole.ADMIN &&
                      userRole !== UserRole.HEAD_OPERATOR &&
                      !editActive && (
                        <Counter
                          value={analogCount}
                          colorCount={`${zeroCount && 'zero'} ${
                            notEnough && 'not-enough'
                          }`}
                          increaseCount={increaseCountAnalog}
                          decreaseCount={decreaseCountAnalog}
                          setCountInCart={setCountOperatorProduct}
                        />
                      )}
                  </div>
                </td>
                {!editActive &&
                  userRole !== UserRole.OPERATOR &&
                  userRole !== UserRole.HEAD_OPERATOR &&
                  userRole !== UserRole.ADMIN && (
                    <td
                      className={`table-cell amount ${
                        editActive ? 'amount-edit' : 'amount-pharmacy-new-edit'
                      }  ${
                        (userRole === UserRole.OPERATOR ||
                          userRole === UserRole.HEAD_OPERATOR ||
                          userRole === UserRole.ADMIN) &&
                        (editOperator
                          ? 'amount-operator-new'
                          : 'amount-operator-new-edit')
                      } `}
                    >
                      {`${currentOrder[
                        data.sku
                      ]?.proposal?.currentPrice.toFixed(2)} ₸`}
                    </td>
                  )}
                {userRole !== UserRole.OPERATOR &&
                userRole !== UserRole.HEAD_OPERATOR &&
                userRole !== UserRole.ADMIN &&
                !editActive ? (
                  <td className="table-cell analog-btn">
                    {((!editActive &&
                      userRole !== UserRole.OPERATOR &&
                      userRole !== UserRole.HEAD_OPERATOR &&
                      userRole !== UserRole.ADMIN) ||
                      !editOperator) && (
                      <div style={{ marginTop: '22px' }}>
                        <Styled.AnalogDelete
                          onClick={() => setDeleteConfirmationOpenProduct(true)}
                        >
                          <img src={closeIcon} alt="close icon" />
                        </Styled.AnalogDelete>
                      </div>
                    )}
                    <div className="btn" />{' '}
                  </td>
                ) : null}
                {!editOperator &&
                  (userRole === UserRole.OPERATOR ||
                    userRole === UserRole.HEAD_OPERATOR ||
                    userRole === UserRole.ADMIN) && (
                    <td
                      className={`table-cell amount ${
                        userRole !== UserRole.OPERATOR &&
                        userRole !== UserRole.ADMIN &&
                        !editActive &&
                        'amount-edit'
                      }  ${
                        (userRole === UserRole.OPERATOR ||
                          userRole === UserRole.HEAD_OPERATOR ||
                          userRole === UserRole.ADMIN) &&
                        (editOperator
                          ? 'amount-operator-new'
                          : 'amount-operator-new-edit')
                      } `}
                    >
                      <div className="current-price-block">
                        {`${currentOrder[
                          data.sku
                        ]?.proposal?.currentPrice.toFixed(2)} ₸`}
                        {(userRole === UserRole.OPERATOR ||
                          userRole === UserRole.HEAD_OPERATOR ||
                          userRole === UserRole.ADMIN) &&
                          !editOperator && (
                            <div className="current-price-checkbox">
                              <CustomCheckbox
                                checked={isVisibleAnalogPrice}
                                onChange={(e) =>
                                  handleChangeAnalogPriceVisible(e)
                                }
                                id={analog.sku}
                              />
                            </div>
                          )}
                      </div>
                      {((!editActive &&
                        userRole !== UserRole.OPERATOR &&
                        userRole !== UserRole.HEAD_OPERATOR &&
                        userRole !== UserRole.ADMIN) ||
                        !editOperator) && (
                        <Styled.AnalogDelete>
                          {/* eslint-disable-next-line */}
                          <img
                            src={closeIcon}
                            alt="close icon"
                            onClick={() =>
                              setDeleteConfirmationOpenProduct(true)
                            }
                          />
                        </Styled.AnalogDelete>
                      )}
                    </td>
                  )}
              </Styled.AnalogRow>
            </>
          )}
        </>
      )}
      {isAnalogModalOpen && (
        <AnalogModal
          onClose={handleCloseAnalogModal}
          source={source}
          orderId={orderId}
        />
      )}
      {deleteConfirmationOpenAnalog && (
        <ConfirmationDeleteProduct
          name={data?.name}
          sku={data?.sku}
          onClose={() => setDeleteConfirmationOpenAnalog(false)}
          analog={false}
          data={data}
        />
      )}
      {deleteConfirmationOpenProduct && (
        <ConfirmationDeleteProduct
          name={data.proposal?.name}
          sku={data?.sku}
          onClose={() => setDeleteConfirmationOpenProduct(false)}
          analog
        />
      )}
    </>
  );
};

export default ProductsTableRow;
