import styled from 'styled-components';
import { baseTheme } from '../../../../../../global/styles/theme';

export const SourceContainer = styled('div')<{ isPharmacy?: boolean }>`
  padding: 16px 0;
  max-height: 300px;
  overflow-y:${({ isPharmacy }) => (isPharmacy ? 'visible' : 'auto')};
  width: 100%;


  .titleAccent {
    justify-content:space-between;
    padding: 8px 10px;
    cursor: pointer;
    font-weight: 500;
    color: ${baseTheme.colors.secondary};
    border-bottom: 1px solid #e0e5eb;

    &:hover {
      background-color: rgba(10, 119, 199, 0.05);
    }
    .container-item{
      display:flex;
      justify-content:space-between;
    }
    .contacts-list-header,
    .item {
      padding-top:10px;
      padding-bottom:10px;
      border-bottom:solid 1px #e0e5eb;
      align-items:center;
      &:last-of-type{
        border:none;
      }
    }
   
    .item{
      width:100%;
      display:flex;
      align-items:center;
      justify-content:space-between;
    }
    .text{
      display:flex;
      justify-content:center;
      width:23%;
      font-size: 14px;
    }
    .textAccent{
      width:31%;
      font-size: 14px;
    }
    .table{
      border-spacing: 0px;
      border-collapse: collapse;
      width:100%;
    }
.online{
  width:10px;
  height:10px;
  border-radius:50%;
  background-color:green;
}
.offline{
  width:10px;
  height:10px;
  border-radius:50%;
  background-color:red;
}
.container-status{
  display:flex;
  align-items:center;
}
  .title {
    padding: 8px 24px;
    cursor: pointer;
    font-weight: 500;
    color: ${baseTheme.colors.secondary};
    border-bottom: 1px solid #e0e5eb;

    &:hover {
      background-color: rgba(10, 119, 199, 0.05);
    }
  }
`;
