import { createAction } from '@reduxjs/toolkit';
import * as constants from './constants';
import { asyncActionsCreator } from '../../global/redux';

export const getNetworks = asyncActionsCreator(constants.GET_NETWORKS);
export const getSources = asyncActionsCreator(constants.GET_SOURCES);
export const getTagsPharm = asyncActionsCreator(constants.GET_TAGS_PHARM);
export const deletePharmacy = asyncActionsCreator(constants.DELETE_PHARMACY);
export const putSource = asyncActionsCreator(constants.PUT_SOURCE);
export const activateSource = asyncActionsCreator(constants.ACTIVATE_SOURCE);
export const pharmacyActivateSync = asyncActionsCreator(
  constants.PHARMACY_ACTIVATE_SYNC
);
export const pharmacyDeactivateSync = asyncActionsCreator(
  constants.PHARMACY_DEACTIVATE_SYNC
);
export const deactivateSource = asyncActionsCreator(
  constants.DEACTIVATE_SOURCE
);
export const setInvisibleSource = asyncActionsCreator(
  constants.SET_INVISIBLE_SOURCE
);
export const setVisibleSource = asyncActionsCreator(
  constants.SET_VISIBLE_SOURCE
);
export const setActiveStatus = asyncActionsCreator(constants.SET_ACTIVE_STATUS);

export const setTagsToPharmacy = asyncActionsCreator(
  constants.SET_TAGS_TO_PHARMACY
);

export const setTag = createAction<{ tagIds: string[] }>(constants.SET_TAG);
