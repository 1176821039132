import axios from 'axios';
import { SourceService } from '../SourcePage/service';

export class AdminService {
  static API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/api/v1/`;

  static getAllNetworks = async () => {
    const response = await axios({
      method: 'get',
      url: `${AdminService.API_BASE_URL}admin/networks`,
    });
    return response.data;
  };

  static getAllSources = async () => {
    const response = await axios({
      method: 'get',
      url: `${AdminService.API_BASE_URL}admin/sources`,
    });
    return response.data;
  };

  static putSource = async (sourceCode: string, sourceInfo: any) => {
    const response = await axios({
      method: 'put',
      url: `${AdminService.API_BASE_URL}admin/sources/${sourceCode}`,
    });
    return response.data;
  };

  static activateSource = async (sourceCode: string) => {
    const response = await axios({
      method: 'put',
      url: `${AdminService.API_BASE_URL}admin/sources/${sourceCode}/activate`,
    });
    return response.data;
  };

  static deletePharmacy = async (sourceCode: string) => {
    const response = await axios({
      method: 'delete',
      url: `${AdminService.API_BASE_URL}admin/pharmacies/${sourceCode}`,
    });
    return response.data;
  };

  static pharmacyActivateSync = async (sourceCode: string) => {
    const response = await axios({
      method: 'put',
      url: `${SourceService.API_BASE_URL}admin/sources/${sourceCode}/sync_enable`,
    });
    return response.data;
  };

  static pharmacyDeactivateSync = async (sourceCode: string) => {
    const response = await axios({
      method: 'put',
      url: `${SourceService.API_BASE_URL}admin/sources/${sourceCode}/sync_disable`,
    });
    return response.data;
  };

  static deactivateSource = async (sourceCode: string, message?: string) => {
    const response = await axios({
      method: 'put',
      url: `${AdminService.API_BASE_URL}admin/sources/${sourceCode}/deactivate`,

      data: {
        comment: message,
      },
    });

    return response.data;
  };

  static setInvisibleSource = async (sourceCode: string) => {
    const response = await axios({
      method: 'put',
      url: `${AdminService.API_BASE_URL}admin/sources/${sourceCode}/invisible`,
    });
    return response.data;
  };

  static setVisibleSource = async (sourceCode: string) => {
    const response = await axios({
      method: 'put',
      url: `${AdminService.API_BASE_URL}admin/sources/${sourceCode}/visible`,
    });
    return response.data;
  };
}
