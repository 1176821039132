export const formatDataForUserInfo = (
  data: any,
  networkCode: string | undefined,
  sourceCode?: string
) => {
  return {
    phone: data.phone,
    role: data.role,
    name: data.name,
    values: {
      network_code: networkCode || '',
      source_code: sourceCode || '',
    },
  };
};
