import { t } from 'i18next';
import { NOTIFICATION_CHANGES_NOT_SAVED } from '../../global/constants';
import { UserData } from '../OrdersPage/types';
import { WebStockFormatedPharmacy } from '../WebStockPage/types';
import { UserResponse } from './types';

export const getFormatedResponse = (response: UserResponse) => {
  return {
    ...response,
    result: {
      ...response.result,
      role: {
        ...response.result.role,
        id: response.result?.role.id?.toLowerCase(),
      },
    },
  };
};

export const mappedUserData = (
  data: UserData,
  name?: string,
  isMagnumSource?: boolean,
  soft?: string,
  pharmacyList?: WebStockFormatedPharmacy[],
  disabled?: boolean,
  active?: boolean,
  activeUpdatedAt?: string,
  activeUpdatedBy?: string,
  sourceTags?: []
) => {
  return {
    phone: data?.phone,
    role: data?.role.id,
    isCollector: data?.is_collector,
    collectingSourceCode: data?.collecting_source_code,
    networkCode: data?.values.network_code,
    networkName: !name ? data?.values.network_code : name,
    sourceCode: data.values.source_code,
    isMagnumSource,
    soft,
    pharmacyList,
    disabled,
    active,
    activeUpdatedAt,
    activeUpdatedBy,
    sourceTags,
  };
};

export const getFormatedPharmacyData = (
  pharmacyList: { code: string; name: string; magnum: boolean; soft: string }[]
) => {
  return pharmacyList
    .filter((item) => item.soft.toUpperCase() === 'WEB_STOCK')
    .map((item) => ({
      code: item.code,
      name: item.name,
      soft: item.soft,
    }));
};

export const validationCodeErrorHandler = (
  errorText: string,
  errorCode: string
) => {
  if (errorCode[0] === '5') {
    return t('LoginPage.SERVICE_UNAVAILABLE');
  }

  switch (errorText) {
    case 'wait for 1 minute before send new code':
      return t('LoginPage.CODE_RE_REQUEST_ONE_MIN');
    case 'wait for 5 minute before send new code':
      return t('LoginPage.CODE_RE_REQUEST_FIVE_MIN');
    default:
      return NOTIFICATION_CHANGES_NOT_SAVED;
  }
};
