import styled from 'styled-components';
import { baseTheme } from '../../../../global/styles/theme';

const goldenApple = require('../../../../global/media/golden_apple.jpg');

export const PharmacyActiveModalContainer = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 33;
  background-color: rgb(240, 237, 191);
  width: 800px;
  padding: 24px;
  height: fit-content;
  border: solid 4px ${baseTheme.colors.mainLightBlue};
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  white-space: pre-line;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  & .manager-top {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    & button {
      font-size: 18px;
      background-color: ${baseTheme.colors.background};
      border-radius: 8px;
      border: none;
      padding: 5px 10px;
      cursor: pointer;
    }
  }

  & .title,
  .text,
  .text-blue {
    color: ${baseTheme.colors.mainLightBlue};
    font-size: 17px;
  }

  & .text {
    margin-top: 10px;
    font-size: 15px;
    width: 100%;
  }

  & .text-blue {
    background-color: ${baseTheme.colors.mainLightBlue};
    color: white;
    font-size: 15px;
  }

  & .text-select {
    width: 100%;
    margin: 5px 0 0;
    font-size: 15px;
  }

  & .buttons {
    width: 100%;
    margin: 30px 0 20px;
    display: flex;
    justify-content: space-around;
    & button {
      background-color: ${baseTheme.colors.mainLightBlue};
      color: white;
      font-size: 22px;
      border: none;
      padding: 5px 10px;
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 550px) {
    width: 100%;
    margin: 0;
  }
`;
