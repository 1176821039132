import styled from 'styled-components';
import { baseTheme } from '../../../../global/styles/theme';

export const SelectModalContainer = styled('div')`
  position: absolute;
  top: 10px;
  background-color: ${baseTheme.colors.white};
  box-shadow: 0 4px 10px rgba(37, 54, 67, 0.2);
  width: 195px;
  border-radius: 8px;
  position: absolute;
  display: flex;
  word-wrap: break-word;
  flex-direction: column;
  margin-left: -20px;
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-name: fadeInDown;
  z-index: 400;
  margin-top: 20px;

  .options-list-container {
    .title {
      padding: 8px 24px;
      cursor: pointer;
      font-weight: 400;
      color: #535d6a;
      font-size: 16px;

      &:hover {
        background-color: rgba(10, 119, 199, 0.05);
      }
    }
  }
`;
