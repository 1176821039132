import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Pagination from '@mui/material/Pagination';
import { useTranslation } from 'react-i18next';
import * as Styled from './Templates.styles';
import { selectors } from '../../reducer';
import { TemplatesProps } from '../../types';

const Templates = () => {
  const { t } = useTranslation();
  const itemsPerPage = 10;

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);

  const templates = useSelector(selectors.templates);

  useEffect(() => {
    if (templates) {
      const totalPages = Math.ceil(templates.length / itemsPerPage);
      setCount(totalPages);
    }
  }, [templates]);

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(Number(event.target.value));
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const renderTemplates = () => {
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return templates
      ?.slice(startIndex, endIndex)
      .map((template: TemplatesProps) => (
        <div key={template.id} className="container-list">
          <div className="cell cell--sm">{template.id}</div>
          <div className="cell cell--ulg">{template.code}</div>
          <div className="cell cell--ulg">{template.description}</div>
          <div className="cell cell--sm">{template.discount.target}</div>
          <div className="cell cell--sm">{template.discount.type}</div>
          <div className="cell">{template.discount.value}</div>
          <div className="cell">{template.duration}</div>
          <div className="cell cell--sm">
            {template.limits.max_usages_total}
          </div>
          <div className="cell cell--sm">{template.limits.max_usages_user}</div>
          <div className="cell cell--sm">{template.limits.max_user_orders}</div>
          <div className="cell">{template.limits.min_order_price}</div>
        </div>
      ));
  };

  return (
    <Styled.Templates>
      <div className="container-header">
        <div className="cell-header cell-header--sm">
          {t('PROMO_CODE_PAGE.ID_TEMPLATE')}
        </div>
        <div className="cell-header cell-header--ulg">
          {t('PROMO_CODE_PAGE.CODE_TEMPLATE')}
        </div>
        <div className="cell-header cell-header--ulg">
          {t('PROMO_CODE_PAGE.DESCRIPTION_TEMPLATE')}
        </div>
        <div className="cell-header cell-header--sm">
          {t('PROMO_CODE_PAGE.TARGET_TEMPLATE')}
        </div>
        <div className="cell-header cell-header--sm">
          {t('PROMO_CODE_PAGE.TYPE_TEMPLATE')}
        </div>
        <div className="cell-header">{t('PROMO_CODE_PAGE.VALUE_TEMPLATE')}</div>
        <div className="cell-header">
          {t('PROMO_CODE_PAGE.DURATION_TEMPLATE')}
        </div>
        <div className="cell-header cell-header--sm">
          {t('PROMO_CODE_PAGE.MAX_USAGES_TOTAL_TEMPLATE')}
        </div>
        <div className="cell-header cell-header--sm">
          {t('PROMO_CODE_PAGE.MAX_USAGES_USERS_TEMPLATE')}
        </div>
        <div className="cell-header cell-header--sm">
          {t('PROMO_CODE_PAGE.MAX_USER_ORDERS_TEMPLATE')}
        </div>
        <div className="cell-header">
          {t('PROMO_CODE_PAGE.MIN_ORDER_PRICE_TEMPLATE')}
        </div>
      </div>

      <div className="container-table">{renderTemplates()}</div>

      <Styled.PaginationContainer>
        <input
          type="tel"
          max={count}
          value={page}
          onChange={handleChangeInput}
        />
        <Pagination count={count} onChange={handleChange} page={page} />
      </Styled.PaginationContainer>
    </Styled.Templates>
  );
};

export default Templates;
