import styled from 'styled-components';
import { baseTheme } from '../../../../global/styles/theme';

export const SourceHead = styled('thead')`
  border-bottom: 10px solid ${baseTheme.colors.stroking};
`;

export const SourceHeaderRow = styled('tr')`
  .table__header-cell {
    font-size: 16px;
    color: ${baseTheme.colors.secondary};
    font-weight: 400;
    text-align: start;
    padding: 34px 0 26px 0;
    width: 15%;

    .img {
      padding-left: 19px;
      cursor: pointer;
    }
  }

  .table__header-cell-number {
    width: 5%;
  }

  .table__header-cell-visibility,
  .table__header-cell-time {
    width: 17%;
  }
`;
