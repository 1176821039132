import styled from 'styled-components';
import { baseTheme } from '../../global/styles/theme';

export const ProductsPageContainer = styled('div')`
  max-width: 1316px;
  margin: auto;
  min-height: calc(100vh - 200px);
  padding: 16px 32px;
`;

export const ProductsPageHeaderContainer = styled('div')`
  display: flex;

  .products-title {
    font-weight: 700;
    font-size: 32px;
    padding-top: 16px;
    color: ${baseTheme.colors.primary};
  }

  .mapping-code {
    font-weight: 700;
    font-size: 20px;
    padding: 26px 0 0 0;
    margin-left: 12px;
    color: ${baseTheme.colors.mainLightBlue};
    border-bottom: 1px solid ${baseTheme.colors.mainLightBlue};
    cursor: pointer;
  }
`;

export const ProductsPageTableContainer = styled('div')`
  padding: 40px 0;
`;
