import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import * as actions from './actions';
import { SourceService } from './service';
import { NetworkResponse, SourceResponse } from '../SourcesPage/types';
import {
  getMappedTagsData,
  mappedDataForSource,
  usersOfSource,
} from './helpers';
import { PharmacyTag, UserResponse } from './types';
import * as actionsNotification from '../InitComponent/actions';
import {
  NOTIFICATION_CHANGES_NOT_SAVED,
  NOTIFICATION_CHANGES_SAVED,
} from '../../global/constants';
import { getTagErrorMsg } from '../PharmacyTagsPage/helpers';

interface SourceParam {
  type: string;
  payload: { sourceCode: string };
}

interface SourceUserParam {
  type: string;
  payload: { role: string; networkCode: string; sourceCode: string };
}

interface SourcesResponse {
  status: string;
  result: SourceResponse | null;
}

interface NetworksResponse {
  status: string;
  result: NetworkResponse | null;
}

interface UsersResponse {
  status: string;
  result: UserResponse[] | null;
}

interface UsersUpdateResponse {
  type: string;
  payload: { phone: string; userInfo: any; postEffect: any };
}

interface PutSourceResponse {
  type: string;
  payload: { sourceCode: string; sourceInfo: any; postEffect: any };
}

interface DeleteUserParam {
  type: string;
  payload: { phone: string; postEffect: any };
}

interface GetSourceTagsSagaParam {
  type: string;
  payload: { tagsType: 'source' | 'network' };
}

interface SetSourceTagParam {
  type: string;
  payload: {
    tagsList: number[];
    sourceCode: string;
    postEffect: () => void;
    textMeta: string;
  };
}

interface PutSourceTagParam {
  type: string;
  payload: {
    id: number;
    updatedTag: PharmacyTag;
    errorEffect: () => void;
  };
}

interface UnmapSourceTagParam {
  type: string;
  payload: {
    tagId: number;
    sourceCode: string;
    postEffect: () => void;
  };
}

interface CreatePharmacyTagSaga {
  type: string;
  payload: { createdTag: PharmacyTag; postEffect: (value: number) => void };
}

export function* getSourceSaga({ payload }: SourceParam) {
  try {
    const response: SourcesResponse = yield call(
      SourceService.getSource,
      payload.sourceCode
    );
    const network: NetworksResponse = yield call(
      // @ts-ignore
      SourceService.getNetwork,
      response.result?.network_code
    );
    const tagsResponse: { result: PharmacyTag[] } = yield call(
      SourceService.getPharmacyTags,
      'source'
    );

    const mappedTagsData = getMappedTagsData(
      tagsResponse.result,
      response.result?.source_tags
    ) as PharmacyTag[];

    yield put(
      actions.getSource.success(
        mappedDataForSource(response.result, network.result, mappedTagsData)
      )
    );
    yield put(actions.getSourceTags.success(mappedTagsData));
  } catch (error) {
    yield put(actions.getSource.error(error));
  }
}

export function* getUsersSaga({ payload }: SourceUserParam) {
  try {
    const response: UsersResponse = yield call(
      SourceService.getUsers,
      payload.role,
      payload.networkCode,
      payload.sourceCode
    );
    yield put(actions.getUsersOfSource.success(usersOfSource(response.result)));
  } catch (error) {
    yield put(actions.getUsersOfSource.error(error));
  }
}

export function* updateUserSaga({ payload }: UsersUpdateResponse) {
  try {
    const response: { status: string } = yield call(
      SourceService.updateUser,
      payload.userInfo
    );

    if (payload.postEffect) {
      payload.postEffect();
    }

    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_SAVED,
        canBeClosed: true,
        type: 'success',
      })
    );
    yield put(actions.updateUser.success(response.status));
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.updateUser.error(error));
  }
}

export function* deleteUserSaga({ payload }: DeleteUserParam) {
  try {
    const response: { status: string } = yield call(
      SourceService.deleteUser,
      payload.phone
    );

    if (payload.postEffect) {
      payload.postEffect();
    }
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_SAVED,
        canBeClosed: true,
        type: 'success',
      })
    );
    yield put(actions.deleteUser.success(response.status));
  } catch (e) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.deleteUser.error(e));
  }
}

export function* putSourceSaga({ payload }: PutSourceResponse) {
  try {
    // @ts-ignore
    const response: any = yield call(
      SourceService.postSource,
      payload.sourceInfo
    );

    if (payload.postEffect) {
      payload.postEffect();
    }

    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_SAVED,
        canBeClosed: true,
        type: 'success',
      })
    );
    yield put(actions.postSource.success(response.result));
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.postSource.error(error));
  }
}

export function* getSourceTagsSaga({ payload }: GetSourceTagsSagaParam) {
  try {
    const response: { result: PharmacyTag[] } = yield call(
      SourceService.getPharmacyTags,
      payload.tagsType
    );

    const mappedData = getMappedTagsData(response.result);

    yield put(actions.getSourceTags.success(mappedData));
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.getSourceTags.error(error));
  }
}

export function* createPharmacyTagSaga({ payload }: CreatePharmacyTagSaga) {
  try {
    const response: { result: { id: number } } = yield call(
      SourceService.createPharmacyTag,
      payload.createdTag
    );

    if (payload.postEffect) {
      payload.postEffect(response.result.id);
    }
    yield put(actions.createPharmacyTag.success(response.result));
  } catch (error) {
    const { response } = error as { response: { data: { error: string } } };

    yield put(
      actionsNotification.addApplicationNotification({
        content: getTagErrorMsg(response.data.error),
        canBeClosed: true,
        type: 'error',
      })
    );

    yield put(actions.createPharmacyTag.error(error));
  }
}

export function* setSourceTag({ payload }: SetSourceTagParam) {
  try {
    const response: { result: string } = yield call(
      SourceService.setSourceTag,
      payload.tagsList,
      payload.sourceCode,
      'sources',
      payload.textMeta
    );

    if (payload.postEffect) {
      payload.postEffect();
    }
    yield put(actions.setSourceTag.success(response.result));
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );

    yield put(actions.setSourceTag.error(error));
  }
}

export function* putSourceTagSaga({ payload }: PutSourceTagParam) {
  try {
    const response: { result: string } = yield call(
      SourceService.putTag,
      payload.id,
      payload.updatedTag
    );

    yield put(actions.updateTag.success(response.result));
  } catch (error) {
    const { response } = error as { response: { data: { error: string } } };

    yield put(
      actionsNotification.addApplicationNotification({
        content: getTagErrorMsg(response.data.error),
        canBeClosed: true,
        type: 'error',
      })
    );
    if (payload.errorEffect) {
      payload.errorEffect();
    }
    yield put(actions.updateTag.error(error));
  }
}

export function* unmapSourceTagSaga({ payload }: UnmapSourceTagParam) {
  try {
    const response: {
      result: string;
    } = yield call(
      SourceService.unmapTag,
      payload.tagId,
      payload.sourceCode,
      'sources'
    );

    if (payload.postEffect) {
      payload.postEffect();
    }

    yield put(actions.unmapSourceTag.success(response.result));
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
  }
}

export function* sourcePageWatcherSaga() {
  yield all([
    takeLatest(actions.getSource.REQUEST, getSourceSaga),
    takeLatest(actions.getUsersOfSource.REQUEST, getUsersSaga),
    takeLatest(actions.updateUser.REQUEST, updateUserSaga),
    takeLatest(actions.deleteUser.REQUEST, deleteUserSaga),
    takeLatest(actions.postSource.REQUEST, putSourceSaga),
    takeLatest(actions.createPharmacyTag.REQUEST, createPharmacyTagSaga),
    takeLatest(actions.getSourceTags.REQUEST, getSourceTagsSaga),
    takeLatest(actions.setSourceTag.REQUEST, setSourceTag),
    takeLatest(actions.updateTag.REQUEST, putSourceTagSaga),
    takeLatest(actions.unmapSourceTag.REQUEST, unmapSourceTagSaga),
  ]);
}
