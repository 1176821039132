import styled from 'styled-components';
import { baseTheme } from '../../../../global/styles/theme';

export const HistoryHead = styled('thead')`
  border-bottom: 10px solid ${baseTheme.colors.stroking};
`;

export const HistoryTableHeaderRow = styled('tr')`
  .table__header-cell {
    font-size: 16px;
    color: ${baseTheme.colors.secondary};
    font-weight: 400;
    text-align: start;
    padding-bottom: 26px;
    border-bottom: 1px solid ${baseTheme.colors.stroking};
    width: 17%;

    .img {
      padding-left: 19px;
      cursor: pointer;
    }
  }

  .table__header-cell-action {
    width: 49%;
  }
`;

export const HistoryTable = styled('table')`
  width: 100%;
  border-collapse: separate;
`;
