import styled from 'styled-components';
import { baseTheme } from '../../../../../../global/styles/theme';

export const PaymentContainer = styled('div')`
  padding: 16px 0;
  max-height: 300px;
  overflow-y: auto;

  .title {
    padding: 8px 24px;
    cursor: pointer;
    font-weight: 500;
    color: ${baseTheme.colors.secondary};

    img {
      margin: 0 10px -4px 0;
      transform: rotate(0);
    }

    &:hover {
      background-color: rgba(10, 119, 199, 0.05);
    }
  }
`;
