import styled from 'styled-components';
import { baseTheme } from '../../global/styles/theme';

export const EmptyUserPageContainer = styled('div')`
  max-width: 1216px;
  margin: auto;

  .title {
    color: ${baseTheme.colors.mainBlue};
    font-weight: 700;
    font-size: 24px;
    margin-top: 50px;
  }
`;
