import styled from 'styled-components';
import { baseTheme } from '../../../../global/styles/theme';

export const MainConfirmation = styled('div')`
  width: 300px;
  margin-bottom: -25px;
  @media only screen and (max-width: 550px) {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
    text-align: center;
  }

  .main-title {
    font-weight: 700;
    font-size: 20px;
    color: ${baseTheme.colors.primary};
    padding-bottom: 8px;
  }

  .confirmation {
    color: ${baseTheme.colors.secondary};
  }

  .btn-block {
    display: flex;
    margin-top: 24px;

    .buttonCancel {
      margin-left: 10px;
    }
  }
`;
