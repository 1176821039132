import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { SourceData } from '../../../../types';
import * as Styled from './SourceRow.styles';
import CustomToggle from '../../../../../../components/CustomToggle';
import * as actions from '../../../../actions';
import { formatDataForReport } from '../../../../../ReportPage/helpers';

interface SourceRowParam {
  data: SourceData;
}

const SourceRow = ({ data }: SourceRowParam) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState<boolean>(data.activated);
  const [visible, setVisible] = useState<boolean>(data.visible);
  const [active, setActive] = useState<boolean>(data?.active || false);
  const [activeInfo, setActiveInfo] = useState<{
    activeUpdatedBy: string | null;
    activeUpdatedAt: Date | null;
  }>({
    activeUpdatedBy: data?.activeUpdatedBy || null,
    activeUpdatedAt: data?.activeUpdatedAt
      ? new Date(data?.activeUpdatedAt) || ''
      : null,
  });

  const [disableButton, setDisableButton] = useState<boolean>(true);
  const [comments, setComments] = useState<string>('');
  const [error, setError] = useState<boolean>(false);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setComments(e.target.value);
  };

  const formattedDate = (date: Date | null) =>
    date
      ? `${date.toLocaleDateString('ru-RU', {
          timeZone: 'Asia/Almaty',
        })} ${date.toLocaleTimeString('ru-RU', {
          hour: '2-digit',
          minute: '2-digit',
          timeZone: 'Asia/Almaty',
        })}`
      : '';

  useEffect(() => {
    setChecked(data.activated);
  }, [data.activated]);

  useEffect(() => {
    error && setError(false);
  }, [comments]);

  useEffect(() => {
    if (
      checked === data.activated &&
      visible === data.visible &&
      active === data?.active
    ) {
      setDisableButton(true);
    } else setDisableButton(false);
  }, [checked, visible, active, data]);

  const handleChangeActivationProducts = () => {
    if (checked !== data?.activated) {
      if (checked) {
        dispatch(
          actions.activateSource.request({
            sourceCode: data.code,
            postEffect: () => dispatch(actions.getSources.request()),
          })
        );
      } else if (comments.trim() === '') {
        setError(true);
      } else {
        dispatch(
          actions.deactivateSource.request({
            sourceCode: data.code,
            message: comments.trim(),
            postEffect: () => dispatch(actions.getSources.request()),
          })
        );
      }
    }

    if (visible !== data?.visible) {
      if (visible) {
        dispatch(
          actions.setVisibleSource.request({
            sourceCode: data.code,
            postEffect: () => dispatch(actions.getSources.request()),
          })
        );
      } else {
        dispatch(
          actions.setInvisibleSource.request({
            sourceCode: data.code,
            postEffect: () => dispatch(actions.getSources.request()),
          })
        );
      }
    }
    if (active !== data?.active) {
      dispatch(
        actions.setActiveStatus.request({
          sourceCode: data.code,
          status: active,
          postEffect: () =>
            dispatch(actions.getSources.request({ sourceCode: data.code })),
        })
      );
    }
  };

  return (
    <Styled.SourceRow>
      <td className="table-cell">1</td>
      <td className="table-cell">{data.name}</td>
      <td className="table-cell" style={{ marginTop: isMobile ? 20 : 0 }}>
        {data.city}
      </td>
      <td className="table-cell" style={{ marginTop: isMobile ? 10 : 0 }}>
        {data.address}
      </td>
      <td className="table-cell" style={{ marginTop: isMobile ? 40 : 0 }}>
        {data.openingHours}
      </td>
      <td className="table-cell" style={{ marginTop: isMobile ? 40 : 0 }}>
        {data.lastSync}
      </td>
      <td className="table-cell" style={{ marginTop: isMobile ? 20 : 0 }}>
        <div>
          <div className="toggle-block">
            <CustomToggle
              onChange={() => {
                setChecked(!checked);
                setDisableButton(false);
              }}
              id={data.name}
              checked={checked}
              variant="standart"
            />
            <div className="disableUpdatedAt"> {data.disableUpdatedAt} </div>
          </div>

          <div className="toggle-block-visible ">
            <CustomToggle
              onChange={() => {
                setVisible(!visible);
                setDisableButton(false);
              }}
              id={data.address}
              checked={visible}
              variant="standart"
            />
            <div className="disableUpdatedAt">
              {visible ? t('SourcesPage.VISIBLE') : t('SourcesPage.INVISIBLE')}{' '}
            </div>
          </div>
          <div className="toggle-block-active ">
            <CustomToggle
              onChange={() => {
                setActive(!active);
              }}
              id="active pharmacy"
              checked={active}
              variant="standart"
            />
            <div className="activeUpdatedAt">
              <span>
                {active ? t('SourcesPage.ACTIVE') : t('SourcesPage.INACTIVE')}
              </span>
              <span className="active-info">
                {formattedDate(activeInfo.activeUpdatedAt) || ''}
              </span>
              <span className="active-info">
                {activeInfo.activeUpdatedBy || ''}
              </span>
            </div>
          </div>
          {!disableButton && !checked && (
            <input
              placeholder={t('SourcesPage.YOUR_COMMENT')}
              value={comments}
              onChange={handleInputChange}
              type="text"
              className={error ? 'input-comments-error' : 'input-comments'}
              maxLength={30}
            />
          )}
          {disableButton && !checked && data.comments && (
            <p className="comment">{data.comments}</p>
          )}
          {error && (
            <p className="comment-error">{t('SourcesPage.ERROR_COMMENTS')}</p>
          )}

          <div className="disableUpdatedBy">
            {checked ? 'Включена:' : 'Отключена:'}{' '}
            <span>+{data.disableUpdatedBy || 'не указано'}</span>
          </div>

          <div
            className={`save-btn ${disableButton && 'disable'}`}
            onClick={handleChangeActivationProducts}
          >
            {t('SourcesPage.SAVE')}
          </div>
          {data && data.hasSyncError && (
            <div className="container-error">
              {t('SourcesPage.ERROR')}
              <div className="error"> {t('SourcesPage.DETAILS_ERROR')}</div>
            </div>
          )}
        </div>
      </td>
    </Styled.SourceRow>
  );
};

export default SourceRow;
