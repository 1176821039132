import * as Styled from './OrderStatusBlock.styles';

interface OrderStatusBlockProps {
  status: string;
  statusName: string;
  statusColor: string;
  blink?: boolean;
}

const OrderStatusBlock = ({
  status,
  statusName,
  statusColor,
  blink,
}: OrderStatusBlockProps) => {
  return (
    <Styled.OrderStatusBlock
      className={blink ? 'item-overdue' : ''}
      style={{
        background: blink || !statusName ? '' : `#${statusColor}`,
      }}
      key={status}
    >
      {statusName}
    </Styled.OrderStatusBlock>
  );
};

export default OrderStatusBlock;
