import { FormEventHandler } from 'react';

export interface SalesProductData {
  sku: string;
  quantity: number;
  discount_price: number;
  current_price: number;
  expiration_date: string;
  pharmacy_product: string;
  series: string;
  comment: string;
}

export interface SalesProductFormatedData extends SalesProductData {
  sku: string;
  quantity: number;
  discount_price: number;
  current_price: number;
  expiration_date: string;
  pharmacy_product: string;
  series: string;
  comment: string;
  minPrice?: number;
}

export interface DiscountProduct {
  sku: string;
  name: string;
  pharmacy_name: string;
  pharmacy_code: string;
  mapping_code: string;
  city: string;
  quantity: number;
  quantity_with_reserve: number;
  price: number;
  base_price: number;
  updated_at: string;
  series: number;
  comment?: string;
}

export interface GetDiscountData {
  result: DiscountProduct[];
  total: number;
}

export interface SalesPageReducer {
  salesProducts: {
    data: null | SalesProductData[];
    loading: boolean;
    error: null | string;
  };
  allSalesProducts: {
    result: null | DiscountProduct[];
    total: number;
    loading: boolean;
    error: null | string;
  };
}

export interface SalesFormatedPharmacy {
  code: string;
  name: string;
  soft: string;
}
export interface SalesSelectedPharmacy {
  code: string;
  name: string;
}
export interface SalesNew {
  discount_price: number;
  current_price: number;
  quantity: number;
  sku: string;
  pharmacy_product: string;
  series: string;
  comment: string;
  source_code: string;
}

export enum SalesProductsTabOption {
  salesProducts = 'onSaleProducts',
  bestBeforeProducts = 'bestBeforeProducts',
}

export interface AddSaleForm {
  values: SalesNew;
  errors: any;
  handleChange?: () => void;
  handleSubmit: FormEventHandler<HTMLFormElement>;
  visible: boolean;
}
