import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { selectors } from '../../containers/OrdersPage/reducer';
import * as Styled from './OrderDetailedModal.styles';
import * as actions from '../../containers/OrdersPage/actions';
import ModalWindow from '../ModalWindow';
import {
  DeliveryType,
  OrderStatus,
  OrderTableData,
  PaymentType,
} from '../../containers/OrdersPage/types';
import ControlButton from './components/ControlButton';
import ProductsTable from './components/ProductsTable';
import { getFromLocalStorage } from '../../global/helpers';
import OrderStatusBlock from '../OrderStatusBlock';

interface OrderDetailedModalProps {
  onClose: () => void;
  onOrderCancel: (id: OrderTableData) => void;
}

const OrderDetailedModal = ({
  onClose,
  onOrderCancel,
}: OrderDetailedModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const role =
    getFromLocalStorage('role') === 'manager' ? 'network' : 'pharmacy';
  const token = getFromLocalStorage('accessToken');

  const data = useSelector(selectors.selectedOrderData);
  const id = useSelector(selectors.orderId);

  useEffect(() => {
    dispatch(actions.setSelectedOrder.request({ role, id }));
  }, [dispatch]);

  const deliveryTypeTitlesStrategy = {
    [DeliveryType.DELIVERY]: t('OrdersPage.OrderDetailedModal.DELIVERY_TITLE'),
    [DeliveryType.SELF]: t('OrdersPage.OrderDetailedModal.SELF_DELIVERY_TITLE'),
  };

  const paymentTypeTitlesStrategy = {
    [PaymentType.IN_PLACE]: t(
      'OrdersPage.OrderDetailedModal.IN_PLACE_PAY_TITLE'
    ),
    [PaymentType.KASPI_PAY]: t('OrdersPage.OrderDetailedModal.KASPI_PAY_TITLE'),
    [PaymentType.INTERPAY]: t(
      'OrdersPage.OrderDetailedModal.INTERPAY_PAY_TITLE'
    ),
  };

  const handleCollectingStatusClick = (data: OrderTableData) => {
    dispatch(
      actions.putCollectingStatus.request({
        id: data?.id,
        token,
        postEffect: () =>
          dispatch(actions.setSelectedOrder.request({ role, id })),
      })
    );
  };

  const handleCancelOrderClick = () => {
    // @ts-ignore
    onOrderCancel(data);
  };

  const handleReadyStatusClick = () => {
    dispatch(
      actions.putReadyStatus.request({
        id: data?.id,
        token,
        postEffect: () =>
          dispatch(actions.setSelectedOrder.request({ role, id })),
      })
    );
  };

  const handleCompletedStatusClick = () => {
    dispatch(
      actions.putCompletedStatus.request({
        id: data?.id,
        token,
        postEffect: () =>
          dispatch(actions.setSelectedOrder.request({ role, id })),
      })
    );
  };

  return (
    <ModalWindow onClose={onClose}>
      <Styled.OrderDetailedModalContent>
        {!data && <CircularProgress size={20} />}
        {data && (
          <>
            <Styled.OrderDetailedHeaderContainer>
              <Styled.OrderDetailedGeneralInfo>
                <div className="order-number">{`Заказ ${data.orderNumber}`}</div>
                <div className="creation-date">{`От ${data.createdAt}`}</div>
                <div className="phone-number">{data.phone}</div>
                <OrderStatusBlock
                  statusName={data.statusName}
                  statusColor={data.statusColor}
                  status={data.status}
                />
              </Styled.OrderDetailedGeneralInfo>
              <Styled.OrderDetailedControlsContainer>
                <div className="left-block">
                  <ControlButton
                    active={data.statusForManger === OrderStatus.UNCONFIRMED}
                    onClick={() => handleCollectingStatusClick(data)}
                    title={t(
                      'OrdersPage.OrderDetailedModal.controls.COLLECTING'
                    )}
                    disabled={data.statusForManger !== OrderStatus.UNCONFIRMED}
                  />
                  <ControlButton
                    active={
                      data.statusForManger !== OrderStatus.COMPLETED &&
                      data.statusForManger !== OrderStatus.CANCELED
                    }
                    onClick={handleCancelOrderClick}
                    title={t('OrdersPage.OrderDetailedModal.controls.CANCEL')}
                    disabled={
                      data.statusForManger === OrderStatus.COMPLETED ||
                      data.statusForManger === OrderStatus.CANCELED
                    }
                  />
                </div>
                <div className="right-block">
                  <ControlButton
                    active={data.statusForManger === OrderStatus.COLLECTING}
                    onClick={handleReadyStatusClick}
                    title={t('OrdersPage.OrderDetailedModal.controls.READY')}
                    disabled={data.statusForManger !== OrderStatus.COLLECTING}
                  />
                  <ControlButton
                    active={data.statusForManger === OrderStatus.READY}
                    onClick={handleCompletedStatusClick}
                    title={t(
                      'OrdersPage.OrderDetailedModal.controls.COMPLETED'
                    )}
                    disabled={data.statusForManger !== OrderStatus.READY}
                  />
                </div>
              </Styled.OrderDetailedControlsContainer>
            </Styled.OrderDetailedHeaderContainer>

            <Styled.OrderDetailedProductsContainer>
              <div className="products-container__title">
                {t('OrdersPage.OrderDetailedModal.PRODUCTS_TABLE_TITLE')}
              </div>
              <ProductsTable products={data.products} />
              <div className="total-amount__container">
                <div className="total-amount__title">
                  {t(
                    'OrdersPage.OrderDetailedModal.ProductsTable.TOTAL_AMOUNT_TITLE'
                  )}
                </div>
                <div className="total-amount__value">{data.amount}</div>
              </div>
            </Styled.OrderDetailedProductsContainer>

            <Styled.OrderDetailedDetailsInfo>
              <div className="details-info__title">
                {t('OrdersPage.OrderDetailedModal.DETAILS_TITLE')}
              </div>
              <div className="delivery-type">
                <div className="delivery-type__title">
                  {t('OrdersPage.OrderDetailedModal.DELIVERY_TYPE_TITLE')}
                </div>
                <div className="delivery-type__value">
                  {deliveryTypeTitlesStrategy[data.deliveryType]}
                </div>
              </div>
              <div className="pharmacy">
                <div className="pharmacy__title">
                  {t('OrdersPage.OrderDetailedModal.PHARMACY_TITLE')}
                </div>
                <div>{data.source}</div>
              </div>
              {data?.address.address && (
                <div className="address">
                  <div className="address__title">
                    {t('OrdersPage.OrderDetailedModal.ADDRESS_TITLE')}
                  </div>
                  <div>
                    {data.address.address ? `${data.address.address}` : null}
                    {data.address.flat ? `, ${data.address.flat} кв` : null}
                    {data.address.entrance
                      ? `, ${data.address.entrance} подъезд`
                      : null}
                    {data.address.floor ? `, ${data.address.floor} этаж` : null}
                    {data.address.city ? `, ${data.address.city}` : null}
                  </div>
                </div>
              )}
              {data.comment && (
                <div className="comment">
                  <div className="comment__title">
                    {t('OrdersPage.OrderDetailedModal.COMMENT_TITLE')}
                  </div>
                  <div>{data.comment}</div>
                </div>
              )}
              <div className="payment-type">
                <div className="payment-type__title">
                  {t('OrdersPage.OrderDetailedModal.PAYMENT_TYPE_TITLE')}
                </div>
                <div
                  className={`payment-type__value ${
                    data.paymentType === PaymentType.KASPI_PAY && 'paid'
                  }`}
                >
                  {paymentTypeTitlesStrategy[data.paymentType]}
                </div>
              </div>
            </Styled.OrderDetailedDetailsInfo>
          </>
        )}
      </Styled.OrderDetailedModalContent>
    </ModalWindow>
  );
};

export default OrderDetailedModal;
