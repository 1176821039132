import styled from 'styled-components';
import { baseTheme } from '../../global/styles/theme';

export const ModalInfoContainer = styled('div')`
  color: ${baseTheme.colors.secondary};

  div {
    display: flex;
    justify-content: center;
  }

  .products-title {
    padding: 8px 0;
    font-weight: 700;
    color: ${baseTheme.colors.primary};
  }
`;

export const ModalButtonsContainer = styled('div')`
  display: flex;
  justify-content: center;
  padding-top: 20px;
  margin-bottom: -10px;

  .save-btn {
    margin-right: 8px;
  }
`;
