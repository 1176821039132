import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ClickAwayListener } from '@mui/material';
import * as Styled from './SelectTags.styles';
import * as actions from '../../actions';
import SelectTag from './SelectTag';
import Button from '../../../../components/Button';
import { TagsMappedInfo } from '../../types';
import AddDeleteTagModal from '../AddDeleteTagModal';

interface SelectTagsProps {
  onClick: (ids: string[]) => void;
  onClose: () => void;
  data: any;
  isAdd?: boolean;
  isSource?: boolean;
  wareId?: string;
  mappingCode?: string;
  productName?: string;
  setData: any;
  selectedTags: any;
  setSelectedTags: any;
  handleButtonClick?: () => void;
}

const SelectTags = ({
  isSource,
  handleButtonClick,
  onClick,
  onClose,
  selectedTags,
  setSelectedTags,
  data,
  isAdd,
  wareId,
  mappingCode,
  productName,
  setData,
}: SelectTagsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isConfirmModalOpen, setModalOpen] = useState<boolean>(false);

  const handleAddToTags = (id: string) => {
    if (id === selectedTags.find((el: string) => el === id)) {
      setSelectedTags([...selectedTags.filter((el: string) => el !== id)]);
    } else
      setSelectedTags([...selectedTags.filter((el: string) => el !== ''), id]);
  };

  const handleSaveTags = () => {
    if (wareId && mappingCode) {
      dispatch(
        actions.setTagsToPharmacy.request({
          tags: selectedTags,
          wareId,
          mappingCode,
          postEffect: () => {
            setData();
          },
        })
      );
    }
  };

  const handleClickModalBtn = () => {
    if (isSource && handleButtonClick) {
      handleButtonClick();
    }
    if (isAdd) {
      setModalOpen(true);
    } else {
      onClick(selectedTags);
    }
  };

  const handleClose = () => setModalOpen(false);

  return (
    <>
      <ClickAwayListener onClickAway={onClose}>
        <Styled.TagsContainer isAdd={isAdd}>
          {data.map((item: TagsMappedInfo) => (
            <Styled.Tag key={item.tagId} color={`${item.tagColor}`}>
              <SelectTag
                name={item.tagName}
                id={item.tagId}
                checked={item.isChecked}
                onClick={handleAddToTags}
              />
            </Styled.Tag>
          ))}
          <div className="sticky-btn">
            <Button
              onClick={handleClickModalBtn}
              text={t('CodeConfirmation.CONFIRM')}
              variant="outlined"
            />
          </div>
        </Styled.TagsContainer>
      </ClickAwayListener>
      {isConfirmModalOpen && (
        <AddDeleteTagModal
          onClose={handleClose}
          tagsId={selectedTags}
          productName={productName ?? ''}
          onConfirm={handleSaveTags}
          variant="add"
        />
      )}
    </>
  );
};

export default SelectTags;
