import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as Styled from './ProductsTable.styles';
import { useProductsDataType } from '../../../ProductsPage/helpers';
import SearchBar from '../../../../components/SearchBar/SearchBar';
import { selectors } from '../../../ProductsPage/reducer';
import * as actions from '../../../ProductsPage/actions';
import {
  getFromLocalStorage,
  setToLocalStorage,
} from '../../../../global/helpers';
import ProductsTab from './components/ProductsTab';
import * as constants from '../../constants';
import CustomPagination from '../Pagination';
import { CountPropsStr, CountPropsNumb } from '../../types';
import deleteIcon from '../../../../global/media/delete.svg';
import filterIcon from '../../../../global/media/filter.svg';
import SelectTags from './components/SelectTags';
import { UserRole } from '../../../OrdersPage/types';
import CustomCheckbox from '../../../../components/CustomCheckbox';

interface ProductsTableProps {
  data: any;
  countPages: CountPropsNumb;
  count: CountPropsStr;
  unAttachedNumberPage: string;
  attachedNumberPage: string;
  blockedNumberPage: string;
  verificationNumberPage: string;
  dyblNumberPage: string;
  automappingNumberPage: string;
  setUnAttPage: (value: string) => void;
  setAttPage: (value: string) => void;
  setBlockPage: (value: string) => void;
  setVerPage: (value: string) => void;
  setDyblPage: (value: string) => void;
  setAutomappingPage: (value: string) => void;
  setData: any;
  isGlobalSearch: boolean;
  setIsGlobalSearch: (value: boolean) => void;
  searchValue: string;
  setSearchValue: (value: any) => void;
  clearProductsData: () => void;
}

const ProductsTable = ({
  data,
  setVerPage,
  setAttPage,
  setBlockPage,
  setUnAttPage,
  setDyblPage,
  unAttachedNumberPage,
  attachedNumberPage,
  verificationNumberPage,
  dyblNumberPage,
  blockedNumberPage,
  countPages,
  count,
  setData,
  automappingNumberPage,
  setAutomappingPage,
  isGlobalSearch,
  setIsGlobalSearch,
  searchValue,
  setSearchValue,
  clearProductsData,
}: ProductsTableProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = getFromLocalStorage('accessToken');
  const networkForContent = useSelector(selectors.network);
  const storageNetworkForContent = getFromLocalStorage('networkData');
  const userRole = getFromLocalStorage('role');

  const [tagName, setTagName] = useState<
    { tag: string; color: string; id: string }[]
  >([]);
  const [isOpenTagsOpen, setTagsOpen] = useState<boolean>(false);

  const [isOpenTab, setIsOpenTab] = useState<string>(
    getFromLocalStorage(constants.ACTIVE_TAB_KEY)
  );

  useEffect(() => {
    setIsOpenTab(getFromLocalStorage(constants.ACTIVE_TAB_KEY));
  }, [networkForContent && networkForContent?.code]);

  const tagIds = useSelector(selectors.tagIds)?.tagIds;
  const searchUnAttData = useSelector(selectors.searchUnAttData);
  const searchUnAttLoading = useSelector(selectors.searchUnAttLoading);
  const searchAttData = useSelector(selectors.searchAttData);
  const searchAttLoading = useSelector(selectors.searchAttLoading);
  const searchBlockData = useSelector(selectors.searchBlockData);
  const searchBlockLoading = useSelector(selectors.searchBlockLoading);
  const searchOnVerData = useSelector(selectors.searchOnVerData);
  const searchOnVerLoading = useSelector(selectors.searchOnVerLoading);
  const searchDyblData = useSelector(selectors.searchDyblData);
  const searchDyblLoading = useSelector(selectors.searchDyblLoading);
  const searchAutomappingLoading = useSelector(
    selectors.searchAutomappingLoading
  );
  const searchAutomappingData = useSelector(selectors.searchAutomappingData);
  const networkCode = networkForContent?.code
    ? networkForContent?.code
    : storageNetworkForContent?.code;

  const clearSearchValue = () => {
    dispatch(actions.clearProductsSearchAttached());
    dispatch(actions.clearProductsSearchOnVer());
    dispatch(actions.clearProductsSearchUnattached());
    dispatch(actions.clearProductsSearchBlocked());
    dispatch(actions.clearProductsSearchDybl());
    dispatch(actions.clearProductsSearchAutomapping());
  };

  const setPageOnSearch = () => {
    setAttPage('0');
    setAutomappingPage('0');
    setBlockPage('0');
    setDyblPage('0');
    setVerPage('0');
    setUnAttPage('0');
  };

  const setSearchData = () => {
    if (searchValue?.length > 2) {
      dispatch(
        actions.getProductsSearchUnattached.request({
          num: unAttachedNumberPage,
          token,
          code: isGlobalSearch ? '' : networkCode,
          searchValue,
          tag: tagIds,
        })
      );

      dispatch(
        actions.getProductsSearchAttached.request({
          num: attachedNumberPage,
          token,
          code: isGlobalSearch ? '' : networkCode,
          searchValue,
          tag: tagIds,
        })
      );

      dispatch(
        actions.getProductsSearchBlocked.request({
          num: blockedNumberPage,
          token,
          code: isGlobalSearch ? '' : networkCode,
          searchValue,
          tag: tagIds,
        })
      );

      dispatch(
        actions.getProductsSearchOnVerification.request({
          num: verificationNumberPage,
          token,
          code: isGlobalSearch ? '' : networkCode,
          searchValue,
          tag: tagIds,
        })
      );

      dispatch(
        actions.getProductsSearchDybl.request({
          num: dyblNumberPage,
          token,
          code: isGlobalSearch ? '' : networkCode,
          searchValue,
          tag: tagIds,
        })
      );

      dispatch(
        actions.getProductsSearchAutomapping.request({
          num: automappingNumberPage,
          token,
          code: isGlobalSearch ? '' : networkCode,
          searchValue,
          tag: tagIds,
        })
      );
    } else clearSearchValue();
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchData();
    }, 1400);

    return () => clearTimeout(delayDebounceFn);
  }, [
    dispatch,
    searchValue,
    networkForContent && networkForContent?.code,
    isGlobalSearch,
    automappingNumberPage,
    unAttachedNumberPage,
    attachedNumberPage,
    verificationNumberPage,
    dyblNumberPage,
    blockedNumberPage,
  ]);

  useEffect(() => {
    if (getFromLocalStorage('searchValue') !== '') {
      setSearchValue(getFromLocalStorage('searchValue'));
    }
  }, []);

  useEffect(() => {
    if (
      searchAttLoading &&
      searchAutomappingLoading &&
      searchBlockLoading &&
      searchDyblLoading &&
      searchOnVerLoading &&
      searchUnAttLoading
    ) {
      setPageOnSearch();
    }
  }, [searchValue]);

  useEffect(() => {
    setToLocalStorage('searchValue', searchValue);
  }, [searchValue]);

  const handleSearchClick = () => {
    return null;
  };

  const dataTag = useSelector(selectors.tags);

  const handleTagCLick = (ids: string[]) => {
    const mappedTags = () => {
      return ids.map((e) => {
        const tag = dataTag.find((el: { tagId: string }) => el.tagId === e);
        return {
          tag: tag.tagName,
          color: tag.tagColor,
          id: e,
        };
      });
    };
    if (ids) {
      setTagName(mappedTags);
      dispatch(actions.setTag({ tagIds: ids }));
    }
    setTagsOpen(false);
  };

  const handleResetTags = () => {
    setTagName([]);
    dispatch(actions.setTag({ tagIds: [] }));
  };

  const getSearchCount = () => {
    return {
      countUnAttached: searchUnAttData
        ? searchUnAttData.total_count.toString()
        : '0',
      countAttached: searchAttData ? searchAttData.total_count.toString() : '0',
      countBlocked: searchBlockData
        ? searchBlockData.total_count.toString()
        : '0',
      countOnVerification: searchOnVerData
        ? searchOnVerData.total_count.toString()
        : '0',
      countDybl: searchDyblData ? searchDyblData.total_count.toString() : '0',
      countAutomapping: searchAutomappingData
        ? searchAutomappingData.total_count.toString()
        : '0',
    };
  };

  const gatTagsName = () => tagName.map((el) => el.tag).join(', ');

  const productsTableType = useProductsDataType(
    data,
    searchValue ? getSearchCount() : count,
    isOpenTab
  );

  const handleTypeClick = (type: string) => {
    switch (type) {
      case `${t('ProductsPage.ProductsType.UNATTACHED')}`:
        setIsOpenTab(constants.UNATTACHED);
        setToLocalStorage(constants.ACTIVE_TAB_KEY, constants.UNATTACHED);
        setToLocalStorage('selectedProduct', null);
        break;
      case `${t('ProductsPage.ProductsType.ATTACHED')}`:
        setIsOpenTab(constants.ATTACHED);
        setToLocalStorage(constants.ACTIVE_TAB_KEY, constants.ATTACHED);
        setToLocalStorage('selectedProduct', null);
        break;
      case `${t('ProductsPage.ProductsType.BLOCKED')}`:
        setIsOpenTab(constants.BLOCKED);
        setToLocalStorage(constants.ACTIVE_TAB_KEY, constants.BLOCKED);
        setToLocalStorage('selectedProduct', null);
        break;
      case `${t('ProductsPage.ProductsType.ON_VERIFICATION')}`:
        setIsOpenTab(constants.VERIFICATION);
        setToLocalStorage(constants.ACTIVE_TAB_KEY, constants.VERIFICATION);
        setToLocalStorage('selectedProduct', null);
        break;
      case `${t('ProductsPage.ProductsType.DYBL')}`:
        setIsOpenTab(constants.DYBL);
        setToLocalStorage(constants.ACTIVE_TAB_KEY, constants.DYBL);
        setToLocalStorage('selectedProduct', null);
        break;
      case `${t('ProductsPage.ProductsType.AUTOMAPPING')}`:
        setIsOpenTab(constants.AUTOMAPPING);
        setToLocalStorage(constants.ACTIVE_TAB_KEY, constants.AUTOMAPPING);
        setToLocalStorage('selectedProduct', null);
        break;
      default:
        break;
    }
    setData();
  };

  const tableClassName =
    isOpenTab === constants.DYBL ||
    isOpenTab === constants.VERIFICATION ||
    isOpenTab === constants.ATTACHED ||
    isOpenTab === constants.AUTOMAPPING
      ? 'table__header-item-second'
      : 'table__header-item';

  const isDetailedView =
    isOpenTab === constants.DYBL ||
    isOpenTab === constants.VERIFICATION ||
    isOpenTab === constants.ATTACHED ||
    isOpenTab === constants.AUTOMAPPING;

  const isDetailedUnView =
    isOpenTab !== constants.DYBL &&
    isOpenTab !== constants.VERIFICATION &&
    isOpenTab !== constants.ATTACHED &&
    isOpenTab !== constants.AUTOMAPPING;

  const TableHeader = (
    <thead>
      <Styled.ProductsTableHeaderRow>
        <th
          className={`${
            isDetailedView ? 'item-bigger-second' : 'item-bigger'
          } ${tableClassName}`}
        >
          {t('ProductsPage.HeaderTitles.PRODUCT')}
          {isDetailedView && (
            <>
              <br />
              <span>{t('ProductsPage.HeaderTitles.MANUFACTURER')}</span>
              <br />
              <span>{t('ProductsPage.HeaderTitles.VENDOR_CODE')}</span>
            </>
          )}
        </th>
        {isDetailedView && (
          <th className="table__header-item-second item-bigger-second">
            {t('ProductsPage.HeaderTitles.PRODUCT_SERVICE')}
            <br />
            <span> {t('ProductsPage.HeaderTitles.MANUFACTURER')}</span>
            <br />
            <span>{t('ProductsPage.HeaderTitles.VENDOR_CODE')}</span>
          </th>
        )}
        <th className={`${tableClassName} item-small`}>
          {t('ProductsPage.HeaderTitles.AMOUNT')}
          <br />
          {t('ProductsPage.HeaderTitles.AMOUNT2')}
        </th>
        <th className={`${tableClassName} item-small`}>
          {t('ProductsPage.HeaderTitles.QUANTITY')}
        </th>
        {isDetailedUnView && (
          <th className={`${tableClassName}`}>
            {t('ProductsPage.HeaderTitles.VENDOR_CODE')}
          </th>
        )}
        {isDetailedUnView && (
          <th className={`${tableClassName}`}>
            {t('ProductsPage.HeaderTitles.MANUFACTURER')}
          </th>
        )}
        {(userRole === UserRole.ADMIN ||
          userRole === UserRole.CONTENT ||
          userRole === UserRole.HEAD_OPERATOR ||
          userRole === UserRole.OPERATOR) && (
          <th className={`${tableClassName} item-small`}>
            {t('ProductsPage.HeaderTitles.MAPPING_CODE')}
          </th>
        )}
        {isOpenTab === constants.DYBL && (
          <th className={`${tableClassName} item-small`}>
            {' '}
            {t('ProductsPage.HeaderTitles.STATUS')}
          </th>
        )}
        <th className={`${tableClassName} item-small`}>
          {t('ProductsPage.HeaderTitles.ACTION')}
        </th>
      </Styled.ProductsTableHeaderRow>
    </thead>
  );

  const getSearchPagination = () => {
    return {
      countUnAttached: Math.ceil(Number(searchUnAttData?.total_count) / 50),
      countAttached: Math.ceil(Number(searchAttData?.total_count) / 50),
      countBlocked: Math.ceil(Number(searchBlockData?.total_count) / 50),
      countOnVerification: Math.ceil(Number(searchOnVerData?.total_count) / 50),
      countDybl: Math.ceil(Number(searchDyblData?.total_count) / 50),
      countAutomapping: Math.ceil(
        Number(searchAutomappingData?.total_count) / 50
      ),
    };
  };

  return (
    <Styled.Container>
      <CustomPagination
        countPages={
          searchValue?.length > 2 ? getSearchPagination() : countPages
        }
        unAttachedNumberPage={unAttachedNumberPage}
        attachedNumberPage={attachedNumberPage}
        blockedNumberPage={blockedNumberPage}
        verificationNumberPage={verificationNumberPage}
        automappingNumberPage={automappingNumberPage}
        dyblNumberPage={dyblNumberPage}
        setUnAttPage={setUnAttPage}
        setAttPage={setAttPage}
        setBlockPage={setBlockPage}
        setVerPage={setVerPage}
        setDyblPage={setDyblPage}
        setAutomappingPage={setAutomappingPage}
      />
      <Styled.ProductsTableType>
        {productsTableType.map((item) => (
          <div
            className={`product-type ${item.isActive && 'type-active'}`}
            key={item.title}
            onClick={() => handleTypeClick(item.title)}
          >
            <div>{`${item.title} (${
              !data ? ' ' : `${item.amount || '0'}`
            })`}</div>
          </div>
        ))}
      </Styled.ProductsTableType>
      <Styled.ProductsTableContainerMain>
        <div className="products-search">
          <SearchBar
            value={searchValue}
            placeholder={t('ProductsPage.SEARCH_PLACEHOLDER')}
            setValue={setSearchValue}
            onSearchClick={handleSearchClick}
          />
          {(userRole === UserRole.CONTENT ||
            userRole === UserRole.ADMIN ||
            userRole === UserRole.HEAD_OPERATOR ||
            userRole === UserRole.OPERATOR) && (
            <div className="global-search-container">
              <CustomCheckbox
                title={t('ProductsPage.GLOBAL_SEARCH')}
                id="global-search"
                onChange={() => {
                  setToLocalStorage('isGlobalSearch', !isGlobalSearch);
                  clearProductsData();
                  setIsGlobalSearch(!isGlobalSearch);
                }}
                checked={isGlobalSearch}
              />
            </div>
          )}
          <Styled.FilterTags>
            <div className="tags-block">
              <div
                className="set-tag"
                onClick={() => setTagsOpen(!isOpenTagsOpen)}
              >
                <div className="text">Теги</div>
                <img src={filterIcon} alt="filter icon" />
              </div>
              {tagName.length > 0 && (
                <div className="filter-info">
                  <span>
                    <span className="filter-by">Фильтр по: </span>
                    {gatTagsName()}{' '}
                    <span onClick={handleResetTags}>
                      <img src={deleteIcon} alt="delete icon" />
                    </span>
                  </span>{' '}
                </div>
              )}
            </div>
            {isOpenTagsOpen && (
              <SelectTags
                onClick={handleTagCLick}
                onClose={() => setTagsOpen(false)}
                data={dataTag}
                setData={() => {}}
              />
            )}
          </Styled.FilterTags>
        </div>
        {(userRole === UserRole.MANAGER ||
          userRole === UserRole.PHARMACY_MANAGER) &&
          isOpenTab === constants.AUTOMAPPING && (
            <Styled.ProductsTableDescription>
              <div className="confirmation-text">
                {t('ProductsPage.AUTOMAPPING_DESCRIPTION.PRESSING_ON')}
                <div className="confirmation-btn">{t('ProductsPage.OK')}</div>
                {t('ProductsPage.AUTOMAPPING_DESCRIPTION.BINDING')}
              </div>
              <div className="warning-text">
                {t('ProductsPage.AUTOMAPPING_DESCRIPTION.WARNING')}
              </div>
            </Styled.ProductsTableDescription>
          )}
        {(userRole === UserRole.MANAGER ||
          userRole === UserRole.PHARMACY_MANAGER) &&
          isOpenTab === constants.VERIFICATION && (
            <Styled.ProductsTableDescription>
              <div className="confirmation-text">
                {t('ProductsPage.AUTOMAPPING_DESCRIPTION.VERIFICATION_WARNING')}
              </div>
            </Styled.ProductsTableDescription>
          )}
        <Styled.ProductsTableContainer>
          {TableHeader}
          <Styled.ProductsTable>
            <>
              <ProductsTab
                data={data?.dataUnAttached}
                results={searchUnAttData?.mappings}
                isOpen={Boolean(isOpenTab === constants.UNATTACHED)}
                loading={searchUnAttLoading}
                type={constants.UNATTACHED}
                searchValue={searchValue}
                setData={searchValue ? setSearchData : setData}
              />
              <ProductsTab
                data={data?.dataAttached}
                results={searchAttData?.mappings}
                isOpen={Boolean(isOpenTab === constants.ATTACHED)}
                loading={searchAttLoading}
                type={constants.ATTACHED}
                searchValue={searchValue}
                setData={searchValue ? setSearchData : setData}
              />
              <ProductsTab
                data={data?.dataBlocked}
                results={searchBlockData?.mappings}
                isOpen={Boolean(isOpenTab === constants.BLOCKED)}
                loading={searchBlockLoading}
                type={constants.BLOCKED}
                searchValue={searchValue}
                setData={searchValue ? setSearchData : setData}
              />
              <ProductsTab
                data={data?.dataOnVerification}
                results={searchOnVerData?.mappings}
                isOpen={Boolean(isOpenTab === constants.VERIFICATION)}
                loading={searchOnVerLoading}
                type={constants.VERIFICATION}
                searchValue={searchValue}
                setData={searchValue ? setSearchData : setData}
              />
              <ProductsTab
                data={data?.dataDybl}
                results={searchDyblData?.mappings}
                isOpen={Boolean(isOpenTab === constants.DYBL)}
                loading={searchDyblLoading}
                type={constants.DYBL}
                searchValue={searchValue}
                setData={searchValue ? setSearchData : setData}
              />
              <ProductsTab
                data={data?.dataAutomapping}
                results={searchAutomappingData?.mappings}
                isOpen={Boolean(isOpenTab === constants.AUTOMAPPING)}
                loading={searchAutomappingLoading}
                type={constants.AUTOMAPPING}
                searchValue={searchValue}
                setData={searchValue ? setSearchData : setData}
              />
            </>
          </Styled.ProductsTable>
        </Styled.ProductsTableContainer>
      </Styled.ProductsTableContainerMain>
      <CustomPagination
        countPages={
          searchValue?.length > 2 ? getSearchPagination() : countPages
        }
        unAttachedNumberPage={unAttachedNumberPage}
        attachedNumberPage={attachedNumberPage}
        blockedNumberPage={blockedNumberPage}
        verificationNumberPage={verificationNumberPage}
        automappingNumberPage={automappingNumberPage}
        dyblNumberPage={dyblNumberPage}
        setUnAttPage={setUnAttPage}
        setAttPage={setAttPage}
        setBlockPage={setBlockPage}
        setVerPage={setVerPage}
        setDyblPage={setDyblPage}
        setAutomappingPage={setAutomappingPage}
      />
    </Styled.Container>
  );
};

export default ProductsTable;
