import { PharmacyTag } from '../SourcePage/types';

export interface WebStockProductData {
  name: string;
  sku: string;
  inStock: boolean;
  price: number | string;
  manufacturer: string;
  updatedAt: string;
  updatedBy: string;
  quantity: number;
}

export interface WebStockProductFormatedData extends WebStockProductData {
  in_stock: boolean;
  price: number;
  updated_at: string;
  updated_by: string;
  link: string;
  quantity: number;
}

export type SetActionType = (
  value: WebStockProductFormatedData[] | PharmacyTag[]
) => void;

export interface WebStockPageReducer {
  webStockProducts: {
    data: null | WebStockProductData[];
    loading: boolean;
    error: null | string;
  };
  searchProducts: {
    data: null | WebStockProductData[];
    loading: boolean;
    error: null | string;
  };
}

export interface WebStockFormatedPharmacy {
  code: string;
  name: string;
  soft: string;
}

export enum filterNameOptionsValues {
  sortByAlphabet = 'sortByAlphabet',
  withoutSorting = 'withoutSorting',
}

export enum filterInStockOptionsValues {
  inStock = 'inStock',
  outOfStock = 'outOfStock',
  all = 'all',
}

export enum WebStockProductsTabOption {
  newProducts = 'newProducts',
  onSaleProducts = 'onSaleProducts',
  archivalProducts = 'archivalProducts',
}
