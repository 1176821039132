import React from 'react';
import * as Styled from './GoldenAppleCard.styles';

const GoldenAppleCard = () => {
  return (
    <Styled.GiftCard>
      <div className="gift-card-shine" />
    </Styled.GiftCard>
  );
};

export default GoldenAppleCard;
