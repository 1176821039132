import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as action from '../../../../containers/SourcePage/actions';
import * as actionNetwork from '../../../../containers/NetworkPage/actions';
import { User } from '../../../../containers/SourcePage/types';
import * as Styled from './UserTableRow.styles';
import editIcon from '../../../../global/media/edit-pencil.svg';
import deleteIcon from '../../../../global/media/delete-icon.svg';
import CustomTextarea from '../../../CustomTextarea';
import { formatDataForUserInfo } from '../../helpers';
import { formatPhoneNumberToBeValid } from '../../../../global/helpers';
import * as actions from '../../../../containers/OperatorsPharmacistPage/actions';

interface UserTableRowProps {
  data: User;
  number: number;
  networkCode?: string;
  sourceCode?: string;
  active?: boolean;
  onAddNewUser?: () => void;
}

const UserTableRow = ({
  data,
  number,
  networkCode,
  sourceCode,
  active,
  onAddNewUser,
}: UserTableRowProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [editActive, setEditActive] = useState<boolean>(!!active);
  const [error, setError] = useState<boolean>(false);

  const [userName, setUserName] = useState<string>(data?.name || '');
  const [userPhone, setUserPhone] = useState<string>(`+${data?.phone}` || '+7');

  const dataUser = {
    name: userName,
    role: data.role,
    phone: userPhone.slice(1).trim(),
    networkCode,
    sourceCode: data.source ? data.source : sourceCode,
  };

  const handleUpdateUser = () => {
    if (formatPhoneNumberToBeValid(userPhone)) {
      dispatch(
        action.updateUser.request({
          userInfo: formatDataForUserInfo(dataUser, networkCode, sourceCode),
          postEffect: () => {
            if (sourceCode) {
              dispatch(
                action.getUsersOfSource.request({
                  role: 'pharmacist',
                  networkCode,
                  sourceCode,
                })
              );
              onAddNewUser && onAddNewUser();
            }
            if (data.role !== 'operator') {
              dispatch(
                actionNetwork.getUsersOfNetwork.request({ networkCode })
              );
              onAddNewUser && onAddNewUser();
            } else {
              dispatch(actions.getOperators.request());
              onAddNewUser && onAddNewUser();
            }
            setEditActive(false);
          },
        })
      );
    } else setError(true);
  };

  const handleDeleteUser = () => {
    if (userPhone && userPhone !== '+7' && !active) {
      dispatch(
        action.deleteUser.request({
          phone: data.phone,
          postEffect: () => {
            if (sourceCode) {
              dispatch(
                action.getUsersOfSource.request({
                  role: 'pharmacist',
                  networkCode,
                  sourceCode,
                })
              );
            }
            if (data.role !== 'operator') {
              dispatch(
                actionNetwork.getUsersOfNetwork.request({ networkCode })
              );
            }

            if (data.role === 'operator') {
              dispatch(actions.getOperators.request());
            }
            setEditActive(false);
          },
        })
      );
    }
    if (onAddNewUser) {
      onAddNewUser();
      setEditActive(false);
    }
  };

  return (
    <Styled.UserRow>
      <td className={`table-cell ${editActive && 'vertical-align-top'}`}>
        {number}
      </td>
      <td className={`table-cell ${editActive && 'vertical-align-top'}`}>
        <div>
          <div>{data.role}</div>
          {editActive && (
            <div onClick={handleUpdateUser} className="save-edit-btn">
              {t('SourcePage.SAVE-BTN')}
            </div>
          )}
        </div>
      </td>
      <td className={`table-cell ${editActive && 'vertical-align-top'}`}>
        {editActive ? (
          <CustomTextarea onChange={(e) => setUserName(e)} value={userName} />
        ) : (
          data.name
        )}
      </td>
      <td className={`table-cell ${editActive && 'vertical-align-top'}`}>
        {editActive ? (
          <>
            <CustomTextarea
              onChange={(e) => setUserPhone(e)}
              value={userPhone}
            />
            {error && (
              <div className="error-message-label">
                {t('NetworkPage.INPUTS_ERROR.PHONE')}
              </div>
            )}
          </>
        ) : (
          `+${data.phone}`
        )}
      </td>
      <td className={`table-cell ${editActive && 'vertical-align-top'}`}>
        {data.createdAt}
      </td>
      <td className={`table-cell ${editActive && 'vertical-align-top'}`}>
        {data.updatedAt}
      </td>
      <td className={`table-cell ${editActive && 'vertical-align-top'}`}>
        <div className="icons-block">
          <div onClick={() => setEditActive(!editActive)}>
            <img src={editIcon} alt="edit" className="edit-icon" />
          </div>
          <div onClick={handleDeleteUser}>
            <img src={deleteIcon} alt="delete" className="delete-icon" />
          </div>
        </div>
      </td>
    </Styled.UserRow>
  );
};

export default UserTableRow;
