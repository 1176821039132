import { useTranslation } from 'react-i18next';
import * as Styled from './EmptyUserPage.styles';

const EmptyUserPage = () => {
  const { t } = useTranslation();

  return (
    <Styled.EmptyUserPageContainer>
      <div className="title">{t('EmptyUserPage.TITLE')}</div>
    </Styled.EmptyUserPageContainer>
  );
};

export default EmptyUserPage;
