import styled from 'styled-components';
import { baseTheme } from '../../../../global/styles/theme';

export const SuggestionContainer = styled('div')`
  @keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translate3d(0px, 10%, 0px);
    }
    100% {
      opacity: 1;
      transform: none;
    }
  }
  position: absolute;
  z-index: 600;
  top: 95px;
  width: calc(120% - 50px);
  background-color: ${baseTheme.colors.white};
  padding: 0 16px 16px;
  box-shadow: 0 4px 10px rgba(37, 54, 67, 0.2);
  border-radius: 8px;
  max-height: 290px;
  overflow-y: auto;
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-name: fadeInDown;

  .border {
    height: 1px;
    background: #e0e5eb;
  }
`;

export const SuggestionListOption = styled('div')`
  cursor: pointer;
  padding: 8px 0;
  font-weight: 500;
  font-size: 16px;
  min-height: 40px;
  color: ${baseTheme.colors.primary};

  &:hover {
    color: ${baseTheme.colors.mainLightBlue};
  }
`;
