import styled from 'styled-components';
import { baseTheme } from '../../../../global/styles/theme';

export const OrdersTable = styled.table`
  width: 100%;
  border-collapse: separate;
`;

export const VirtualTableBody = styled.div`
  height: 600px;
  width: 100%;
  overflow-y: auto;
  position: relative;
`;

export const VirtualTableRow = styled.div`
  position: absolute;
  width: 100%;
`;

export const OrdersHead = styled('thead')`
  border-bottom: 1px solid ${baseTheme.colors.stroking};
`;

export const OrdersTableHeaderRow = styled('tr')`
  img {
    transform: rotate(90deg);
  }

  background-color: white;
  @keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translate3d(0px, 10%, 0px);
    }
    100% {
      opacity: 1;
      transform: none;
    }
  }
  .table__header-cell {
    font-size: 16px;
    color: ${baseTheme.colors.secondary};
    font-weight: 400;
    text-align: start;
    padding-bottom: 16px;
    width: 15%;
    .img {
      padding-left: 8px;
      cursor: pointer;
      display: inline-block;
    }
  }

  .table__header-cell-operator {
    font-size: 16px;
    color: ${baseTheme.colors.secondary};
    font-weight: 400;
    text-align: start;
    padding-bottom: 16px;
    width: 8%;

    .img {
      padding-left: 8px;
      cursor: pointer;
      display: inline-block;
    }
  }

  .table__header-amount-cell {
    width: 6.8% !important;
  }

  .table__header-timer-cell {
    width: 8.5%;
  }

  .table__header-amount-cell {
    padding-right: 20px;
  }

  .table__header-order,
  .table__header-created-at {
    width: 12% !important;
  }

  .table__header-phone-cell {
    width: 9.3%;
  }

  .table__header-source-operator {
    padding-left: 0 !important;
    width: 9%;
  }

  .table__header-source {
    padding-left: 25px;
    position: relative;
    width: 8.7%;

    svg {
      cursor: pointer;
    }
  }
  .status-filter-accent {
    background-color: ${baseTheme.colors.white};
    width: 195px;
    border-radius: 8px;
    width: 400px;
    height: 400px;
    animation-duration: 0.4s;
    display: flex;
    margin: 0 -30px 20px -30px;
  }
  .input-status-filter {
    padding: 14px 16px;
    width: 400px;
    height: 48px;
    border: 1px solid #e0e5eb;
    border-radius: 8px;
    margin: 0 -30px 8px -30px;
    outline: none;
  }
  .pharmacy {
  }
  .container-pharmacy {
    margin-top: 40px;
    height: 400px;
    display: flex;

    width: 400px;
    justify-content: space-between;
    margin-left: -30px;
    margin-right: -30px;
  }
  .button-contacts {
    height: 18px;
    cursor: pointer;
    border: none;
    background: transparent;
    text-decoration: underline;
    color: ${baseTheme.colors.mainLightBlue};
  }

  .table__header-order-large {
    width: 9%;
  }

  .table__header-created-at-large {
    width: 6.4%;
  }

  .table__header-user-source {
    width: 6.7% !important;
  }

  .table__header-cell-operator-delivery {
    width: 6.7%;
  }

  .table__header-cell-operator-delivery,
  .table__header-payment-type,
  .table__header-status {
    cursor: pointer;
    .status-filter {
      background-color: ${baseTheme.colors.white};
      box-shadow: 0 4px 10px rgba(37, 54, 67, 0.2);
      width: 195px;
      border-radius: 8px;
      position: absolute;
      display: flex;
      word-wrap: break-word;
      flex-direction: column;
      margin-left: -20px;
      margin-top: 20px;
      animation-duration: 0.4s;
      animation-fill-mode: both;
      animation-name: fadeInDown;
      z-index: 400;
    }
  }
  .order-number-cell {
    width: 7.4%;
  }
  .table__header-status {
    width: 12.5%;
  }
  .cell-collector {
    width: 8.3%;
  }
  .status-delivery-cell {
    width: 12.7%;
  }
  .table__header-payment {
    position: relative;
    cursor: pointer;
    width: 6% !important;

    svg {
      margin-left: 15px;
    }

    .status-filter {
      background-color: ${baseTheme.colors.white};
      box-shadow: 0 4px 10px rgba(37, 54, 67, 0.2);
      width: 195px;
      border-radius: 8px;
      position: absolute;
      top: 40px;
      display: flex;
      word-wrap: break-word;
      flex-direction: column;
      margin-left: -20px;
      animation-duration: 0.4s;
      animation-fill-mode: both;
      animation-name: fadeInDown;
      z-index: 400;
    }
  }

  .table__header-status-first-cell {
    width: 23% !important;
    position: relative;
    display: flex;

    .status-filter {
      background-color: ${baseTheme.colors.white};
      box-shadow: 0 4px 10px rgba(37, 54, 67, 0.2);
      width: 195px;
      border-radius: 8px;
      position: absolute;
      top: 40px;
      display: flex;
      word-wrap: break-word;
      flex-direction: column;
      margin-left: -40px;
      animation-duration: 0.4s;
      animation-fill-mode: both;
      animation-name: fadeInDown;
      z-index: 400;
    }
  }
  .contacts-list-header {
    list-style: none;
    width: 100%;
    display: flex;
    padding: 0;
  }
  .table__header-status-cell {
    position: relative;

    .status-filter {
      background-color: ${baseTheme.colors.white};
      box-shadow: 0 4px 10px rgba(37, 54, 67, 0.2);
      max-height: 300px;
      width: 195px;
      border-radius: 8px;
      position: absolute;
      top: 70px;
      display: flex;
      word-wrap: break-word;
      flex-direction: column;
      margin-left: -2px;
      animation-duration: 0.4s;
      animation-fill-mode: both;
      animation-name: fadeInDown;
      z-index: 400;
    }

    .selected-status {
      display: inline-flex;
      cursor: pointer;
      justify-content: space-between;
      max-width: 180px;
      padding: 10px 10px 10px 0;

      div {
        padding-left: 10px;
        display: flex;
        align-items: center;
      }
    }
    .completed {
      padding: 8px 10px;
      font-weight: 500;
      border-radius: 8px;
      color: ${baseTheme.colors.focus};
      border: 2px solid ${baseTheme.colors.focus};
    }

    .collecting {
      padding: 8px 10px;
      font-weight: 500;
      border-radius: 8px;
      color: ${baseTheme.colors.mainLightBlue};
      border: 2px solid ${baseTheme.colors.mainLightBlue};
    }

    .unconfirmed {
      padding: 8px 10px;
      font-weight: 500;
      border-radius: 8px;
      color: ${baseTheme.colors.error};
      border: 2px solid ${baseTheme.colors.error};
    }

    .canceled {
      padding: 8px 10px;
      font-weight: 500;
      border-radius: 8px;
      color: ${baseTheme.colors.error};
      border: 2px solid ${baseTheme.colors.error};
    }

    .ready {
      padding: 8px 10px;
      font-weight: 500;
      border-radius: 8px;
      color: ${baseTheme.colors.warning};
      border: 2px solid ${baseTheme.colors.warning};
    }
  }

  .padding-top {
    padding-top: 10px;
  }
`;
