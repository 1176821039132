import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import InfoReportByDay from '../../components/InfoReportByDay';
import Loader from '../../components/Loader';
import SearchModal from '../../components/SearchModal';
import {
  clearProducts,
  formatDate,
  getFromLocalStorage,
} from '../../global/helpers';
import networksArrow from '../../global/media/networks-arrow.svg';
import * as networkAction from '../NetworkPage/actions';
import { selectors as networkSelectors } from '../NetworkPage/reducer';
import * as actionsOrder from '../OrderPage/actions';
import { selectors as orderSelectors } from '../OrderPage/reducer';
import { amountOfOrders, getDay } from '../OrdersPage/helpers';
import { UserRole } from '../OrdersPage/types';
import * as Styled from './ReportPage.styles';
import * as actions from './actions';
import RangeBlock from './components/RangeBlock';
import { selectors } from './reducer';
import RefundsBlock from './components/RefundsBlock';

const ReportPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const role = getFromLocalStorage('role');
  const token = getFromLocalStorage('accessToken');
  const education = history.location.pathname.includes('/test-orders');

  const dataToday = useSelector(selectors.ordersToday);
  const dataYesterday = useSelector(selectors.ordersYesterday);
  const loadingT = useSelector(selectors.ordersTodayLoading);
  const loadingY = useSelector(selectors.ordersYesterdayLoading);

  const [checked, setChecked] = useState<boolean>(false);

  const [network, setNetwork] = useState<{ code: string; name: string }>({
    code: '',
    name: 'Все сети',
  });
  const allStatuses = useSelector(orderSelectors.allStatuses);
  const pharmacyStatuses = useSelector(orderSelectors.allPharmacyStatuses);
  const [statusesArray, setStatusesArray] = useState<
    { code: string; name: string }[]
  >([{ code: '', name: 'Все статусы' }]);

  const handleAddStatus = (code: string, name: string) => {
    if (code === '') {
      setStatusesArray([{ code: '', name: 'Все статусы' }]);
      return;
    }
    if (code === statusesArray.find((el) => el.code === code)?.code) {
      setStatusesArray([...statusesArray.filter((el) => el.code !== code)]);
    } else
      setStatusesArray([
        ...statusesArray.filter((el) => el.code !== ''),
        { code, name },
      ]);
  };

  const networks = useSelector(networkSelectors.networksData)?.map(
    (network) => {
      return {
        code: network.code,
        name: network.name,
      };
    }
  );

  const handleSetNetwork = (code: string, name: string) => {
    setNetwork({
      code,
      name,
    });
  };

  const formatDateForRequest = (yesterday?: boolean) => {
    return !yesterday
      ? formatDate().split('.').reverse().join('-')
      : formatDate(true).split('.').reverse().join('-');
  };

  useEffect(() => {
    dispatch(
      actions.getReportByDate.request({
        date: formatDateForRequest(),
        dateYest: formatDateForRequest(true),
        testOrders: !!education,
      })
    );
    (role === UserRole.ADMIN ||
      role === UserRole.OPERATOR ||
      role === UserRole.HEAD_OPERATOR) &&
      dispatch(networkAction.getAllNetwork.request());
    role === UserRole.ADMIN &&
      !allStatuses &&
      dispatch(actionsOrder.getAllStatuses.request({ token }));
    (role === UserRole.MANAGER || role === UserRole.PHARMACY_MANAGER) &&
      dispatch(actionsOrder.getAllPharmacyStatuses.request({ token }));
    clearProducts();
  }, [dispatch, education]);

  return (
    <>
      {(loadingT || loadingY) && <Loader />}
      <RefundsBlock network={network.code} />
      {dataToday && dataYesterday && (
        <Styled.ReportContainer>
          <Styled.ReportByDaysContainer>
            <InfoReportByDay
              data={dataYesterday}
              day={` ${getDay(true)}, ${formatDate(true)}`}
            />

            <InfoReportByDay
              data={dataToday}
              day={` ${getDay()}, ${formatDate()}`}
            />
          </Styled.ReportByDaysContainer>
          <>
            <Styled.InfoText>
              {role === UserRole.OPERATOR || role === UserRole.HEAD_OPERATOR
                ? t('ReportPage.INFO_TEXT_OPERATOR')
                : t('ReportPage.INFO_TEXT')}
            </Styled.InfoText>

            <Styled.CalendarBlock>
              <RangeBlock
                network={network}
                statuses={statusesArray.map((el) => el.code)}
                checked={checked}
                setChecked={(e: boolean) => setChecked(e)}
                handleSetNetwork={handleSetNetwork}
                networks={networks}
                statusesArray={statusesArray}
                handleAddStatus={handleAddStatus}
              />
            </Styled.CalendarBlock>
          </>
        </Styled.ReportContainer>
      )}
    </>
  );
};

export default ReportPage;
