export const POST_RECIPE_IMG = 'POST_RECIPE_IMG';
export const PUT_ORDER = 'PUT_ORDER';

export const GER_ALL_STATUSES = 'GER_ALL_STATUSES';
export const GET_ALL_PHARMACY_STATUSES = 'GET_ALL_PHARMACY_STATUSES';

export const GET_PRODUCTS_SEARCH_FOR_ANALOG = 'GET_PRODUCTS_SEARCH_FOR_ANALOG';
export const SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT_ORDER';
export const CLEAR_SELECTED_PRODUCT = 'CLEAR_SELECTED_PRODUCT';
export const GET_PRICE = 'GET_PRICE';
export const APPLY_PROMO_CODE = 'APPLY_PROMO_CODE';
export const SEND_EDIT = 'SEND_EDIT';
export const CHANGE_TIMER = 'CHANGE_TIMER';
export const SET_SELECTED_ACTIVE_PHARMACIST_ON_ORDER =
  'SET_SELECTED_ACTIVE_PHARMACIST_ON_ORDER';
export const CLEAR_SELECTED_ACTIVE_PHARMACIST_ON_ORDER =
  'CLEAR_SELECTED_ACTIVE_PHARMACIST_ON_ORDER';

export const N8N_PRICE = 'N8N_PRICE';
export const GET_PRICE_QUANTITY_BY_SOURCE = 'GET_PRICE_QUANTITY_BY_SOURCE';

export const DEFAULT_TIME = '00:00';

export const synonymsList: string[][] = [
  ['таблетки', 'табл', 'таб', 'таблет'],
  ['к/май', 'кызыл', 'май'],
  ['капсулы', 'капс', 'кап'],
  ['ампулы', 'амп'],
  ['раствор', 'рас', 'раств', 'р-р', 'р-вор', 'раст'],
  ['капли', 'кап'],
  ['суспензия', 'cус', 'сусп', 'сус-я'],
  ['порошок', 'пор'],
  ['cуппозитории', 'cупп', 'суппоз', 'свечи', 'свеч'],
  ['аэрозоль', 'aэр', 'aэроз'],
  ['драже', 'др'],
  ['масло', 'мас', 'масл', 'м-ло'],
  ['Крем', 'кр'],
  ['cироп', 'сир', 'с-оп', 'си-п'],
  ['гранулы', 'гран', 'гр', 'г-лы'],
  ['паста', 'паст', 'пас'],
  ['настойка', 'н-ка', 'наст'],
  ['эмульсия', 'эмульс', 'эмул', 'эм-я'],
  ['микстура', 'м-ра', 'мик-ра'],
  ['отвар', 'о-р', 'от-р'],
  ['каплеты', 'к-ты', 'кап-ты'],
  ['пеллеты', 'п-ты', 'пел-ты'],
  ['cпрей', 'сп-й', 'с-ей', 'спр'],
  ['мазь', 'м-зь'],
  ['линимент', 'л-мент', 'линим', 'лин'],
  ['пессарий', 'песс', 'пес-ий'],
  ['№', 'n'],
];

export const GET_PRODUCTS_ATTACHED = 'GET_PRODUCTS_ATTACHED';

export const CREATE_INTERPAY_PAYOUTS = 'CREATE_INTERPAY_PAYOUTS';
export const GET_INTERPAY_PAYOUTS = 'GET_INTERPAY_PAYOUTS';
export const CHANGE_INTERPAY_PAYOUTS = 'CHANGE_INTERPAY_PAYOUTS';
