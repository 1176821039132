import { all, call, put, takeLatest } from 'redux-saga/effects';
import { NOTIFICATION_CHANGES_NOT_SAVED } from '../../global/constants';
import * as actionsNotification from '../InitComponent/actions';
import { OrdersService } from '../OrdersPage/service';
import { ProductResponseItem } from '../OrdersPage/types';
import * as actions from './actions';
import { formatAnalogsData } from './helpers';
import { AnalogsService } from './service';
import { AnalogData } from './types';

interface getAnalogsListBySkuSagaParam {
  type: string;
  payload: {
    key: string;
    type: string;
    postEffect?: () => void;
  };
}

interface SubmitReviewSagaParam {
  type: string;
  payload: {
    sku: string;
    value: string;
    postEffect?: () => void;
  };
}

interface DeleteReiviewSagaParam {
  type: string;
  payload: {
    id: number;
    postEffect?: () => void;
  };
}

export function* getAnalogsListBySkuSaga({
  payload,
}: getAnalogsListBySkuSagaParam) {
  try {
    const response: {
      result: AnalogData[];
    } = yield call(AnalogsService.getAnalogsBySku, payload.key, payload.type);

    const skuList = response.result.map((analog) => analog.value);

    if (payload.postEffect) {
      payload.postEffect();
    }
    let magentaResponse: { items: ProductResponseItem[] } = { items: [] };
    if (skuList.length > 0) {
      try {
        magentaResponse = !skuList[0].includes('question')
          ? yield call(OrdersService.getProductsBySkus, skuList)
          : { items: [] };
      } catch (error) {
        console.log(error);
      }
      const formatedAnalogs = formatAnalogsData(
        response.result,
        magentaResponse.items
      );

      yield put(actions.getAnalogsListBySku.success(formatedAnalogs));
    } else {
      yield put(actions.getAnalogsListBySku.success([]));
    }
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.getAnalogsListBySku.error(error));
  }
}

export function* submitReviewSaga({ payload }: SubmitReviewSagaParam) {
  try {
    const response: {
      result: {
        status: string;
      };
    } = yield call(AnalogsService.submitReview, payload.sku, payload.value);

    if (payload.postEffect) {
      payload.postEffect();
    }

    yield put(actions.submitReview.success(response.result));
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.submitReview.error(error));
  }
}

export function* deleteReviewSaga({ payload }: DeleteReiviewSagaParam) {
  try {
    const response: {
      result: {
        status: string;
      };
    } = yield call(AnalogsService.deleteReview, payload.id);

    if (payload.postEffect) {
      payload.postEffect();
    }

    yield put(actions.deleteReview.success(response));
  } catch (error) {
    actionsNotification.addApplicationNotification({
      content: NOTIFICATION_CHANGES_NOT_SAVED,
      canBeClosed: true,
      type: 'error',
    });

    yield put(actions.deleteReview.error(error));
  }
}

export function* moderatePageWatcherSaga() {
  yield all([
    takeLatest(actions.getAnalogsListBySku.REQUEST, getAnalogsListBySkuSaga),
    takeLatest(actions.deleteReview.REQUEST, deleteReviewSaga),
    takeLatest(actions.submitReview.REQUEST, submitReviewSaga),
  ]);
}
