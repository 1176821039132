import styled from 'styled-components';
import { baseTheme } from '../../global/styles/theme';

export const AnalogModalContainer = styled('div')`
  width: 900px;
`;

export const AnalogSearchContainer = styled('div')`
  display: flex;
  flex-direction: column;

  .description {
    color: ${baseTheme.colors.secondary};
  }

  .search-bar {
    margin-top: 16px;
    max-width: 485px;
  }
`;

export const AnalogSuggestion = styled('div')`
  margin-top: 20px;
  max-height: 455px;
  overflow: auto;
`;

export const ProductsSearchItemContainer = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  grid-gap: 30px;
  padding: 30px 0;
`;
