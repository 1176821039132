import styled from 'styled-components';
import { baseTheme } from '../../../../../../global/styles/theme';

export const TagConfirmationModalContainer = styled('div')`
  width: 100%;

  .text-content {
    .highlighted-text {
      font-weight: bold;
    }
  }
`;

export const ActionBtnContainer = styled('div')`
  display: flex;
  justify-content: space-between;

  .warning-btn {
    background-color: ${baseTheme.colors.error};
  }
`;

export const ActionBtn = styled('div')`
  padding: 8px 16px;
  border-radius: 8px;
  background-color: ${baseTheme.colors.focus};
  cursor: pointer;
  color: ${baseTheme.colors.white};
  text-align: center;
  width: 200px;
`;
