import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button';
import * as Styled from './ProductCard.styles';
import emptyImg from '../../../../global/media/empty-state.svg';
import Counter from '../../../Counter';
import { addAnalog, addNewProduct } from '../../../../global/helpers';
import { selectors } from '../../../../containers/OrderPage/reducer';

interface ProductCardProp {
  data: any;
  onClose: () => void;
  newProduct?: boolean;
}

const ProductCard = ({ data, onClose, newProduct }: ProductCardProp) => {
  const { t } = useTranslation();

  const [srcImg, setSrcImg] = useState(data.img);
  const sku = useSelector(selectors.skuSelectedProduct);

  const [value, setValue] = useState<number>(1);
  const [newProductCount, setProductCount] = useState<number>(1);

  const handleClick = async (data: any) => {
    if (sku && !newProduct) {
      addAnalog(data, value, sku);
      onClose();
    } else if (newProduct) {
      addNewProduct(data, newProductCount);
      onClose();
    }
  };

  const increaseCount = () => {
    if (data.allQuantity === 0 || data.allQuantity === undefined) {
      return value;
    }
    if (value >= data.allQuantity) {
      return value;
    }
    if (newProduct) {
      setProductCount(value + 1);
    }
    setValue(value + 1);
  };

  const decreaseCount = () => {
    if (data.allQuantity === 0) {
      return value;
    }
    if (value === 1) {
      return value;
    }
    if (newProduct) {
      setProductCount(value - 1);
    }
    setValue(value - 1);
  };

  const setCountInCart = (value: number) => {
    if (data.allQuantity === 0) {
      return value;
    }
    setValue(value);
  };

  return (
    <Styled.ProductContainer>
      <div className="product-img">
        <img src={srcImg} alt="img" onError={() => setSrcImg(emptyImg)} />
      </div>
      <div className="product-info">
        <div className="product-name">{data.name}</div>
        <div className="product-packing">{data.packing}</div>
        <div className="product-manufacturer">{data.manufacturer}</div>
        <div className="product-counter">
          <Counter
            value={!newProduct ? value : newProductCount}
            analogCounter
            increaseCount={increaseCount}
            decreaseCount={decreaseCount}
            setCountInCart={() => setCountInCart(value)}
          />
          {data.allQuantity !== 0 && data.allQuantity !== undefined ? (
            <div className="green">
              {data.allQuantity}{' '}
              {t('OrdersPage.OrderDetailedModal.ANALOG_MODAL.IN_STOCK')}{' '}
            </div>
          ) : (
            <div className="red">
              {' '}
              {t('OrdersPage.OrderDetailedModal.ANALOG_MODAL.NO_STOCK')}
            </div>
          )}
        </div>
      </div>
      <Button
        onClick={() => handleClick(data)}
        disabled={data.allQuantity === 0 || !data.allQuantity}
        text={
          newProduct
            ? t('OrdersPage.OrderDetailedModal.ANALOG_MODAL.SUGGEST_PRODUCT')
            : t('OrdersPage.OrderDetailedModal.ANALOG_MODAL.SUGGEST_ANALOG')
        }
        variant="outlined"
      />
    </Styled.ProductContainer>
  );
};

export default ProductCard;
