import { useTranslation } from 'react-i18next';
import ModalWindow from '../../../../components/ModalWindow';
import { WebStockFormatedPharmacy } from '../../types';
import * as Styled from './WebStockPharmacyModal.styles';

interface WebStockPharmacyModalProps {
  onClosePharmaciesModal: () => void;
  pharmaciesList: WebStockFormatedPharmacy[];
  onPharmacyClick: (value: WebStockFormatedPharmacy) => void;
}

export const WebStockPharmacyModal = ({
  onClosePharmaciesModal,
  pharmaciesList,
  onPharmacyClick,
}: WebStockPharmacyModalProps) => {
  const { t } = useTranslation();

  return (
    <ModalWindow onClose={() => onClosePharmaciesModal()}>
      <Styled.PharmacyContainer>
        <Styled.PharmacyTitle>
          {t('WebStockPage.MODAL_WINDOW.TITLE')}
        </Styled.PharmacyTitle>
        <Styled.PharmaciesBlock>
          {pharmaciesList.map((pharmacy) => (
            <div
              className="network-container"
              onClick={() => onPharmacyClick(pharmacy)}
            >
              <div className="pharmacy">{pharmacy.name}</div>
            </div>
          ))}
        </Styled.PharmaciesBlock>
      </Styled.PharmacyContainer>
    </ModalWindow>
  );
};
