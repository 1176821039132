import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalWindow from '../ModalWindow';
import * as Styled from './QuestionsModal.styles';
import Accordion from '../Accordion';
import Button from '../Button';

interface QuestionsModalProps {
  onClose: () => void;
}

const QuestionsModal = ({ onClose }: QuestionsModalProps) => {
  const { t } = useTranslation();

  const contents = [
    {
      title: t('QuestionsModal.Q1'),
      content: t('QuestionsModal.A1'),
    },
    {
      title: t('QuestionsModal.Q2'),
      content: t('QuestionsModal.A2'),
    },
    {
      title: t('QuestionsModal.Q3'),
      content: t('QuestionsModal.A3'),
    },
    {
      title: t('QuestionsModal.Q4'),
      content: t('QuestionsModal.A4'),
    },
    {
      title: t('QuestionsModal.Q5'),
      content: t('QuestionsModal.A5'),
    },
    {
      title: t('QuestionsModal.Q6'),
      content: t('QuestionsModal.A6'),
    },
    {
      title: t('QuestionsModal.Q7'),
      content: t('QuestionsModal.A7'),
    },
    {
      title: t('QuestionsModal.Q8'),
      content: t('QuestionsModal.A8'),
    },
    {
      title: t('QuestionsModal.Q9'),
      content: t('QuestionsModal.A9'),
    },
    {
      title: t('QuestionsModal.Q10'),
      content: t('QuestionsModal.A10'),
    },
    {
      title: t('QuestionsModal.Q11'),
      content: t('QuestionsModal.A11'),
    },
  ];

  return (
    <ModalWindow onClose={onClose}>
      <Styled.Wrapper>
        {contents.map((el) => (
          <Accordion title={el.title} content={el.content} />
        ))}
        <Styled.ContactWrapper>
          <div className="title">{t('QuestionsModal.Q12')}</div>
          <div className="buttonBlock">
            <a
              rel="noreferrer"
              href="https://api.whatsapp.com/send?phone=77715045100"
              target="_blank"
            >
              <Button
                text={t('QuestionsModal.WRITE_TO_WHATSAPP')}
                onClick={() => {}}
              />
            </a>
            <a href="tel: +77000001464" className="mainBtn">
              {`${t('QuestionsModal.CALL_TO')} +77000001464`}
            </a>
          </div>
        </Styled.ContactWrapper>
      </Styled.Wrapper>
    </ModalWindow>
  );
};

export default QuestionsModal;
