import styled from 'styled-components';

export const Number = styled('div')`
  padding-bottom: 8px;
  font-size: 20px;
  font-weight: bold;
`;

export const Title = styled('div')`
  font-weight: bold;
`;

export const Description = styled('div')`
  max-width: 400px;
  padding-top: 16px;

  div {
    padding: 4px 0;
  }

  .source-name {
    font-weight: bold;
  }

  .order-number {
    font-weight: bold;
  }
`;
