import styled from 'styled-components';
import { baseTheme } from '../../../../global/styles/theme';

export const StyledStrongRecipeNotificationModal = styled('div')`
  width: 500px;
  height: fit-content;
  .description {
    width: 100%;
    font-weight: 700;
    font-size: 20px;
    color: ${baseTheme.colors.primary};
  }
  .close-btn-container {
    margin: 0px;
    display: flex;
    justify-content: center;
    div {
      width: 140px;
      margin: 10px 0px 0px 0px;
    }
  }
`;
