import styled from 'styled-components';
import { baseTheme } from '../../../../global/styles/theme';

export const EditBreadCrumbsContainer = styled('div')`
  padding-top: 24px;
  display: flex;

  .breadCrumb {
    margin-right: 10px;
    cursor: pointer;
  }

  .active {
    color: ${baseTheme.colors.secondary};
  }

  .breadcrumb-icon {
    margin-right: 10px;
  }

  .active-icon {
    svg {
      path {
        fill: black;
      }
    }
  }
`;
