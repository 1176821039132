import { NetworkResponse, SourceResponse, TagsFieldResponse } from './types';
import { formatTime } from '../../global/helpers';

export const mappedDataForSources = (
  data: SourceResponse[] | null,
  networks?: NetworkResponse[] | null
) => {
  return data
    ?.map((source) => {
      const networkName = networks?.find(
        (el) => el.code === source.network_code
      );

      return {
        name: source.name,
        code: source.code,
        diffMs: new Date().getTime() - new Date(source.created_at).getTime(),
        activated: !source.disabled,
        lastSync: source.last_sync ? formatTime(source.last_sync) : '-',
        lastSyncOrigin: source.last_sync,
        comments: source?.disable_comment,
        disableUpdatedAt: source.disable_updated_at
          ? formatTime(source.disable_updated_at)
          : '',
        disableUpdatedBy: source.disable_updated_by || '',
        city: source.city,
        address: source.address,
        openingHours: source.opening_hours,
        networkName: networkName?.name,
        networkCode: networkName?.code,
        magnum: source.magnum,
        visible: source.visible,
        hasSyncError: source.has_sync_error,
        tags: source.source_tags,
      };
    })
    .sort((a, b) => a.diffMs - b.diffMs);
};

export const mappedTags = (data: TagsFieldResponse[] | null) => {
  return data?.map((el) => {
    return {
      name: el.name,
      color: el.color || '',
      requiredMeta: el.required_meta || false,
      id: el.id.toString(),
      allowed: el.allowed || false,
    };
  });
};
