import React, { useState, useEffect, useContext, useRef } from 'react';
import { TArticleBlock } from './types';
import { EditorContext } from './EditorContext';
import * as Styled from './EditorPage.styles';

const EditorPage = () => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editedArticle, setEditedArticle] = useState<TArticleBlock[] | null>(
    null
  );
  const { initEditor, editorInstanceRef } = useContext(EditorContext);
  const editorRef = useRef<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (!editorRef.current) {
      initEditor();
      editorRef.current = true;
    }
  }, [initEditor]);

  useEffect(() => {
    editedArticle && setEditedArticle([]);
  }, [isEdit]);

  useEffect(() => {
    if (editedArticle) {
      editorInstanceRef.current.render({
        blocks: editedArticle,
      });
    }
  }, [editedArticle]);

  const onSaveHandler = async () => {
    const data = await editorInstanceRef.current.save();
    if (data) {
      const jsonData = JSON.stringify(data.blocks, null, 2);
      const blob = new Blob([jsonData], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'article.json';
      a.click();
      URL.revokeObjectURL(url);
    }
  };

  const onUploadFileHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const parsedContent = JSON.parse(e.target?.result as string);
        setEditedArticle(parsedContent);
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      };
      reader.readAsText(file);
    }
  };

  return (
    <>
      <Styled.Navigation>
        <button
          type="button"
          onClick={() => setIsEdit(false)}
          style={{
            background: isEdit ? 'lightGrey' : 'lightBlue',
            borderRadius: '10px 0 0 10px',
          }}
        >
          Создать
        </button>
        <button
          type="button"
          onClick={() => setIsEdit(true)}
          style={{
            background: isEdit ? 'lightBlue' : 'lightGrey',
            borderRadius: '0 10px 10px 0',
          }}
        >
          Редактировать
        </button>
      </Styled.Navigation>

      <Styled.Buttons
        style={{ justifyContent: isEdit ? 'space-between' : 'flex-end' }}
      >
        {isEdit && (
          <label>
            Загрузить
            <input
              type="file"
              accept=".json"
              onChange={onUploadFileHandler}
              ref={fileInputRef}
              hidden
            />
          </label>
        )}
        <button type="button" onClick={onSaveHandler}>
          Скачать
        </button>
      </Styled.Buttons>
      <Styled.Editor id="editorjs" />
    </>
  );
};

export default EditorPage;
