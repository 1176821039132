import { Route, Redirect, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import React from 'react';
import { useSelector } from 'react-redux';
import MainPage from '../MainPage';
import { selectors as loginSelectors } from '../LoginPage/reducer';
import LoginPage from '../LoginPage';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import NotificationsList from '../../components/NotificationsList';
import faviconSvg from '../../global/media/favicon.svg';
import EditorPage from '../EditorPage/EditorPage';

const InitComponent = () => {
  const accessToken = useSelector(loginSelectors.accessToken);
  const refreshToken = useSelector(loginSelectors.refreshToken);

  const selectStartPage = () => {
    if (accessToken && refreshToken) {
      return <MainPage />;
    }
    return (
      <>
        <Helmet>
          <link rel="icon" type="image/png" href={faviconSvg} />
        </Helmet>
        <div>
          <Header />
          <Switch>
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/editor" component={EditorPage} />
            {!accessToken && <Redirect to="/login" />}
          </Switch>
          <Footer />
          <NotificationsList />
        </div>
      </>
    );
  };
  return selectStartPage();
};

export default InitComponent;
