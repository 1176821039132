import styled from 'styled-components';
import { baseTheme } from '../../../../global/styles/theme';

export const Wrapper = styled('div')`
  .common-container {
    display: flex;
    gap: 10px;
  }

  .flex-container-accent {
    margin-bottom: 5px;
    margin-top: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .error-message {
    margin: 0;
    color: #eb5757;
    margin-top: 5px;
  }

  .error-message-promo {
    margin: 0;
    color: #eb5757;
    font-size: 12px;
    margin-top: 5px;
  }
  .apply-promo-code {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    background-color: ${baseTheme.colors.mainLightBlue};
    color: ${baseTheme.colors.white};
    font-weight: 500;
    font-size: 14px;
    padding: 6px;
    border-radius: 8px;

    &:hover {
      background-color: ${baseTheme.colors.mainBlue};
    }
  }

  .input-promocode {
    padding-top: 5px;
    padding-bottom: 5px;
    width: 165px;
  }

  .input-promocode-error {
    padding-top: 5px;
    padding-bottom: 5px;
    width: 165px;
    color: #eb5757;
  }
  .maps-container {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 10px;
  }
  .yandex {
    color: orange;
  }

  .yandex-active {
    color: orange;
    font-weight: 700;
    border-bottom: solid 1px orange;
  }
  .gis {
    color: #1fa069;
  }
  .container-button-copy {
    margin-top: 20px;
    width: fit-content;
  }
  .gis-active {
    font-weight: 700;
    color: #1fa069;
    border-bottom: solid 1px #1fa069;
  }

  .modal-window {
    padding: 0 0 56px 64px;
    margin-bottom: 20px;
    max-height: 90vh;
    overflow: auto;
    transform: translate(0%, 0%);
  }
  .delivery-type__value-red {
    color: #eb5757;
    font-weight: 700;
  }

  .crossed-text {
    text-decoration: line-through;
  }

  .cell {
    margin-bottom: 5px;
  }
  .auto-delivery-modal {
    width: 600px;
    height: fit-content;
  }
  .form-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .title {
    font-size: 20px;
    font-weight: 600;
  }
  .subtitle {
    font-size: 16px;
    font-weight: 60px;
  }
  .input-container {
    margin: 20px 0;
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .flex-container-button {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .flex-container {
    gap: 10px;
    display: flex;
    justify-content: space-between;
  }
  .flex-container-column {
    gap: 10px;
    display: flex;
    flex-direction: column;
  }
  .input-small {
    width: 110px;
  }
  .container-table {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .input {
    width: 350px;
  }
  .flex-container-accent {
    justify-content: space-between;
    display: flex;
    gap: 10px;
    align-items: end;
  }

  .button-autoDelivery {
    border: none;
    font: inherit;
    font-weight: 500;
    font-size: 18px;
    color: ${baseTheme.colors.white};
    background-color: ${baseTheme.colors.mainLightBlue};
    border-radius: 8px;
    padding: 12px 24px;
    text-align: center;
    margin-top: 24px;
    cursor: pointer;

    &:hover {
      background-color: ${baseTheme.colors.mainBlue};
    }
  }

  .choose-container {
    gap: 20px;
    display: flex;
    align-items: center;
  }

  .claim {
    font-weight: 700;
    .claim-title {
      font-weight: 500;
    }
    .claim-value {
      font-weight: 500;
      color: #1fa069;
    }
    .claim-container {
      margin-top: 5px;
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }

  .promo-text {
    color: #fff;
    border-radius: 2px;
    padding: 5px;
    background-color: #1fa069;
  }

  .text-accent {
    margin: 0;
    color: #fff;
    background-color: #1fa069;
    padding: 5px;
    border-radius: 2px;
  }

  .flex-container-accent {
    width: fit-content;
    display: flex;
    gap: 5px;
    align-items: center;
  }

  .container-type {
    display: flex;
    gap: 80px;
    margin-top: 20px;
  }

  .container-type-price {
    display: flex;
    gap: 55px;
    margin-top: 20px;
  }

  .flex-container {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .flex-container-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    border-radius: 10px;
    border: solid 1px grey;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 10px;
  }

  .active-flex-container-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    color: #fff;
    font-weight: 700;
    border-radius: 10px;
    background-color: #1fa069;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 10px;
  }

  .flex-container-promo {
    display: flex;
    width: 100%;
    color: #000;
    font-weight: 700;
    border-radius: 10px;
    cursor: pointer;
    gap: 5px;
    padding: 10px;
    padding-left: 0;
  }

  .type {
    border: solid 1px #0a77c7;
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }

  .active-type {
    border: solid 1px #0a77c7;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #0a77c7;
  }

  .price {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 180px;
    background-color: gray;
    height: 30px;
    padding: 5px;
    padding-left: 10px;
  }

  .button-cancel {
    cursor: pointer;
    width: 200px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: red;
    color: #fff;
  }

  .button-apply {
    cursor: pointer;
    width: 200px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0a77c7;
    color: #fff;
  }

  .container-button {
    margin-left: 170px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 50px;
  }

  .disabled {
    cursor: default;
    background-color: gray !important;
  }

  .cancel_button {
    margin-left: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    background-color: ${baseTheme.colors.mainLightBlue};
    color: ${baseTheme.colors.white};
    font-weight: 500;
    font-size: 14px;
    width: 200px;
    padding: 6px;
    border-radius: 8px;

    &:hover {
      background-color: ${baseTheme.colors.mainBlue};
    }
  }

  .disabled-button {
    pointer-events: none;
    background: ${baseTheme.colors.disabled};
    border: 2px solid ${baseTheme.colors.disabled};
  }
`;
