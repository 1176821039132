import styled from 'styled-components';
import { baseTheme } from '../../../../global/styles/theme';

export const NetworkMainBlock = styled('div')`
  .header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 34px;

    .textarea {
      width: 316px !important;
      min-height: 40px !important;
      height: 40px !important;
    }

    .name {
      font-weight: 500;
      font-size: 18px;
      color: ${baseTheme.colors.mainLightBlue};
    }

    .icons-block {
      display: flex;
      justify-content: flex-end;

      div {
        cursor: pointer;
      }

      .delete-icon {
        margin-left: 32px;
      }
    }
  }
`;

export const NetworkInfo = styled('div')`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  .form-container {
    display: grid;
    grid-template-columns: 0.5fr 1fr 1fr 1fr;
  }

    .form-input {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
    }

  .label {
    color: ${baseTheme.colors.secondary};
    margin-bottom: 8px;
    font-size: 14px;
  }
  
  input:disabled {
    background-color: transparent;
    color: ${baseTheme.colors.tertiary};
  }

  .input {
    outline: none !important;
    border: 1px solid ${baseTheme.colors.stroking};
    border-radius: 4px;
    color: ${baseTheme.colors.primary}; !important;
    padding: 10px 12px;
    width: 80% !important;
  }

  .input-relative {
    position: relative !important;
  }

  .input-error {
    background-color: rgb(${baseTheme.colors.error}, 0.05);
    border: 1px solid ${baseTheme.colors.error};
  }

  .error {
    background-color: rgb( ${baseTheme.colors.error}, 0.1);
    border: 1px solid  ${baseTheme.colors.error};
  }

  .error-message-label {
    margin: 8px 0 0;
    color: ${baseTheme.colors.error};
    font-size: 14px;
    width: 80%;
  }
  
  .button-create {
    
  }
  
  .button {
    border: none;
    font: inherit;
    font-weight: 500;
    font-size: 18px;
    color: ${baseTheme.colors.white};
    background-color: ${baseTheme.colors.mainLightBlue};
    border-radius: 8px;
    padding: 12px 24px;
    text-align: center;
    max-width: 150px;
    margin-top: 24px;
    cursor: pointer;

    &:hover {
      background-color: ${baseTheme.colors.mainBlue};
    }

  }
`;
