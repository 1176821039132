import styled from 'styled-components';

export const PaginationContainer = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  input {
    width: 40px;
    height: 30px;
    font-size: 14px;
    text-align: center;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
