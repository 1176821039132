import axios from 'axios';

export class CashbackService {
  static API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/api/v2/`;

  static getCashbackList = async () => {
    const response = await axios({
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      url: `${CashbackService.API_BASE_URL}admin/suggestions?key=cashback&type=analog`,
    });
    return response.data;
  };

  static getCashbackData = async (key: string, id: string) => {
    const response = await axios({
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      url: `${CashbackService.API_BASE_URL}admin/suggestions?key=${key}&type=analog`,
    });
    if (!response.data?.result?.length) {
      return null;
    }
    return {
      ...response.data?.result?.[response.data.result.length - 1],
      key,
      cashback_id: id,
    };
  };

  static addToCashbackList = async (value: string) => {
    const response = await axios({
      method: 'post',
      data: {
        value,
      },
      url: `${CashbackService.API_BASE_URL}admin/suggestions?type=analog&key=cashback`,
    });

    return response.data;
  };

  static submitCashback = async (sku: string, value: string) => {
    const response = await axios({
      method: 'post',
      data: {
        value,
      },
      url: `${CashbackService.API_BASE_URL}admin/suggestions?type=analog&key=${sku}_cashback`,
    });

    return response.data;
  };

  static deleteCashback = async (id: number, cashbackId: number) => {
    await Promise.all([
      axios({
        method: 'delete',
        url: `${CashbackService.API_BASE_URL}admin/suggestions?id=${id}`,
      }),
      axios({
        method: 'delete',
        url: `${CashbackService.API_BASE_URL}admin/suggestions?id=${cashbackId}`,
      }),
    ]);
  };
}
