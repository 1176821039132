import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as Styled from './CreateBreadCrumbs.style';
import arrowIcon from '../../../../global/media/arrow-breadCrumbs.svg';
import { DataType } from '../../types';

interface BreadCrumbsProps {
  editActive?: boolean;
}

const CreateBreadCrumbs = ({ editActive }: BreadCrumbsProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const typeForCreate = history.location.hash.slice(1);

  const handleClick = (push: string) => {
    history.push(push);
  };

  return (
    <Styled.ProductsBreadCrumbsContainer>
      <div className="breadCrumb" onClick={() => handleClick('/sources')}>
        {t('SourcePage.BREADCRUMBS.PHARMACY_PAGE')}
      </div>
      <Styled.ProductsBreadCrumbIcon>
        <img src={arrowIcon} alt="arrow icon" />
      </Styled.ProductsBreadCrumbIcon>
      <div className="breadCrumb active">
        {typeForCreate === DataType.SOURCE
          ? t('SourcePage.BREADCRUMBS.ADD_PHARMACY')
          : t('NetworkPage.BREADCRUMBS.ADD_NETWORK')}
      </div>
    </Styled.ProductsBreadCrumbsContainer>
  );
};

export default CreateBreadCrumbs;
