import styled from 'styled-components';
import { baseTheme } from '../../global/styles/theme';

export const OrderSearchModalContent = styled('div')`
  width: 675px;
  max-height: 70vh;

  .modal-title {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 16px;
  }

  .modal-subtitle {
    color: ${baseTheme.colors.secondary};
    margin-bottom: 24px;
  }

  .modal-no-results {
    margin-top: 32px;
  }

  .modal-loading {
    margin-top: 32px;
  }

  @media only screen and (max-width: 550px) {
    padding: 16px;
    width: 100%;
  }
`;
