import { getCities } from '../../global/cities';

export const GET_SOURCE = 'GET_SOURCE';
export const POST_SOURCE = 'POST_SOURCE';
export const GET_NETWORK = 'GET_NETWORK';
export const GET_USERS_OF_SOURCE = 'GET_USERS_OF_SOURCE';
export const GET_TAGS = 'GET_TAGS';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const CLEAR_SOURCE = 'CLEAR_SOURCE';
export const CHANGE_EDIT_SOURCE = 'CHANGE_EDIT_SOURCE';
export const CREATE_PHARMACY_TAG = 'CREATE_PHARMACY_TAG';
export const GET_SOURCE_TAGS = 'GET_SOURCE_TAGS';
export const SET_SOURCE_TAG = 'SET_SOURCE_TAG';
export const UPDATE_SOURCE_TAG = 'UPDATE_SOURCE_TAG';
export const UNMAP_SOURCE_TAG = 'UNMAP_SOURCE_TAG';
export const SET_TAG = 'SET_TAG';

export const DEFAULT_SOURCE_STATE = {
  city: 'Алматы',
  openingHours: '',
  address: '',
  internalAddress: '',
  lat: 0,
  lon: 0,
  sourceCode: '',
  mappingCode: '',
  hoursOfActivateDeactivate: '',
  PO: '',
  hoursOfSynchronize: '',
  paymentMethod: '',
  sales: 0,
  commision: 0,
  rezerv: '',
  to1C: '',
  ip_address: '',
  db_dsn: '',
  device: '',
  token: '',
  internalCode: '',
  syncFtp: '',
};

export const selectBoolean = [
  { title: 'Да', code: true },
  { title: 'Нет', code: false },
];

export const selectPO = [
  { title: '1C', code: '1C' },
  { title: 'STANDART', code: 'STANDART' },
  { title: 'RUBUS', code: 'RUBUS' },
  { title: 'INFO_APTEKA', code: 'INFO_APTEKA' },
  { title: 'STANDART_NO_DISCOUNT', code: 'STANDART_NO_DISCOUNT' },
  { title: 'OASIS', code: 'OASIS' },
  { title: 'INFO_PLUS', code: 'INFO_PLUS' },
  { title: 'WEB_STOCK', code: 'WEB_STOCK' },
];

export const selectCity = getCities().map((f: any) => ({
  title: f.ruName,
  code: f.ruName,
}));
