import styled from 'styled-components';
import { baseTheme } from '../../global/styles/theme';

export const OrderHistoryContainer = styled('div')`
  display: flex;
  flex-direction: column;
  max-width: 1216px;
  margin: auto auto 20px auto;

  input[type='file'] {
    opacity: 0;
    z-index: -1;
    position: absolute;
  }

  input[type='file']:focus + label {
    outline: 2px solid;
  }

  .back {
    margin: 30px 0;
    display: flex;
    color: ${baseTheme.colors.secondary};
    font-size: 14px;
    cursor: pointer;

    img {
      transform: rotate(180deg);
      margin-right: 10px;
    }
  }
`;
