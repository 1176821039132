import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { ReportService } from './service';
import * as actionsNotification from '../InitComponent/actions';
import { NOTIFICATION_REPORT_ERROR } from '../../global/constants';

interface GenerateReportParam {
  type: string;
  payload: {
    data: {
      from: string;
      to: string;
      network_code: string;
    };
    pdf: boolean;
    xls: boolean;
    postEffect: any;
  };
}

interface GetReportByDateParam {
  type: string;
  payload: {
    date: string;
    dateYest: string;
    testOrders?: boolean;
  };
}

interface GetRefundsParam {
  type: string;
  payload: {
    network_code: string;
  };
}

export function* getReportByDateSaga({ payload }: GetReportByDateParam) {
  try {
    const response: { status: string; result: any } = yield call(
      ReportService.getReport,
      payload.date,
      payload.testOrders
    );

    const responseYest: { status: string; result: any } = yield call(
      ReportService.getReport,
      payload.dateYest,
      payload.testOrders
    );

    yield put(actions.getReportByDate.success(response.result));
    yield put(actions.getReportByDateYest.success(responseYest.result));
  } catch (error) {
    yield put(actions.getReportByDate.error(error));
    yield put(actions.getReportByDateYest.error(error));
  }
}

export function* generateReportSaga({ payload }: GenerateReportParam) {
  try {
    const response: { status: string; result: string } = yield call(
      ReportService.generateReport,
      payload.data,
      payload.pdf,
      payload.xls
    );

    if (payload.postEffect) {
      yield call(payload.postEffect);
    }
    yield put(actions.generateReport.success(response.result));
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_REPORT_ERROR,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.generateReport.error(error));
  }
}

export function* getRefundsSaga({ payload }: GetRefundsParam) {
  try {
    const response: { reports: any } = yield call(
      ReportService.getRefunds,
      payload.network_code
    );

    yield put(actions.getRefunds.success(response));
  } catch (error) {
    yield put(actions.getRefunds.error(error));
  }
}

export function* getPaymentsSaga({ payload }: GetRefundsParam) {
  try {
    const response: { reports: any } = yield call(
      ReportService.getPayments,
      payload.network_code
    );
    yield put(actions.getPayments.success(response.reports));
  } catch (error) {
    yield put(actions.getPayments.error(error));
  }
}

export function* reportPageWatcherSaga() {
  yield all([
    takeLatest(actions.generateReport.REQUEST, generateReportSaga),
    takeLatest(actions.getReportByDate.REQUEST, getReportByDateSaga),
    takeLatest(actions.getRefunds.REQUEST, getRefundsSaga),
    takeLatest(actions.getPayments.REQUEST, getPaymentsSaga),
  ]);
}
