export const GET_NETWORK = 'GET_NETWORK';
export const GET_ALL_NETWORK = 'GET_ALL_NETWORK';
export const GET_SOURCES_BY_NETWORK = 'GET_SOURCES_BY_NETWORK';
export const CLEAR_NETWORK = 'CLEAR_NETWORK';
export const EDIT_NETWORK = 'CLEAR_NETWORK';
export const GET_USERS_OF_NETWORK = 'GET_USERS_OF_NETWORK';
export const CLEAR_SOURCES = 'CLEAR_SOURCES';
export const GET_NETWORK_TAGS = 'GET_NETWORK_TAGS';
export const SET_NETWORK_TAG = 'SET_NETWORK_TAG';
export const UNMAP_NETWORK_TAG = 'UNMAP_NETWORK_TAG';

export const DEFAULT_NETWORK_STATE = {
  bank: '',
  bik: '',
  bin: '',
  code: '',
  director: '',
  rounding: 0,
  roundingMethod: 'up',
  iik: '',
  legalAddress: '',
  legalName: '',
  name: '',
  organizationType: '',
  phone: '+7',
};

export const selectRoundingMethod = [
  { title: 'Вверх', code: 'up' },
  { title: 'Вниз', code: 'down' },
];
