import styled from 'styled-components';
import { baseTheme } from '../../../global/styles/theme';

export const Input = styled('input')<{ error: boolean }>`
  margin-left: 5px;
  width: 40px;
  padding: 3px 7px;
  border-radius: 4px;
  text-align: center;
  border: ${({ error }) =>
    error
      ? `1px solid ${baseTheme.colors.error}`
      : `1px solid ${baseTheme.colors.stroking}`};
`;

export const Label = styled('label')`
  margin-left: 7px;
`;

export const Text = styled('p')`
  width: 50px;
  color: ${baseTheme.colors.mainLightBlue};
  font-weight: 700;
`;

export const Container = styled('div')`
  display: flex;
  align-items: center;
`;

export const ContainerCheckbox = styled('div')`
  margin-left: 20px;
`;
