import { createAction } from '@reduxjs/toolkit';
import { asyncActionsCreator } from '../../global/redux';
import * as constants from './constants';

export const generateReport = asyncActionsCreator(constants.GENERATE_REPORT);
export const clearReport = createAction(constants.CLEAR_REPORT);
export const getReportByDate = asyncActionsCreator(
  constants.GET_REPORT_BY_DATE
);
export const getReportByDateYest = asyncActionsCreator(
  constants.GET_REPORT_BY_DATE_YEST
);

export const getPayments = asyncActionsCreator(constants.GET_PAYMENTS);
export const getRefunds = asyncActionsCreator(constants.GET_REFUNDS);
