import styled from 'styled-components';
import { baseTheme } from '../../../../global/styles/theme';

export const StyledTableContainer = styled('div')`
  padding: 0px;
`;

export const PaginationContainer = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;

  input {
    width: 40px;
    height: 30px;
    font-size: 14px;
    text-align: center;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const StyledTableHeaderContainer = styled('div')`
  display: flex;
  justify-content: space-between;

  .header-row-lg {
    width: 40% !important;
  }

  .dark-blue-row {
    background-color: #2686ec;
    color: ${baseTheme.colors.white} !important;
  }

  .orange-row {
    background-color: #f8bd12;
    color: ${baseTheme.colors.white} !important;
  }

  .dark-green-row {
    background-color: #00cf00;
    color: ${baseTheme.colors.white} !important;
  }

  .blue-row {
    background-color: #1fc4ca;
    color: ${baseTheme.colors.white} !important;
  }

  .green-row {
    background-color: ${baseTheme.colors.focusLight};
    color: ${baseTheme.colors.white} !important;
  }

  .description-secondary {
    font-size: 12px;
    padding: 5px;
    color: rgb(127, 136, 149);
    width: 100%;
    background-color: #e7e7e7;
  }

  .main-description {
    padding: 15px;
  }

  .header-row-md {
    width: 30% !important;
  }

  .header-row-sm {
    width: 20% !important;
  }

  .header-row {
    border: 1px solid ${baseTheme.colors.stroking};
    color: ${baseTheme.colors.secondary};
    width: 25%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex-direction: column;
  }
`;

export const TableBodyContainer = styled('div')`
  display: flex;
  flex-direction: column;
  flex-direction: row;
  justify-content: space-between;

  .table-cell-lg {
    width: 40% !important;
  }

  .table-cell-md {
    width: 30% !important;
  }

  .table-cell-sm {
    width: 20% !important;
  }

  .table-cell {
    padding: 12px;
    border: 1px solid ${baseTheme.colors.stroking};
    color: ${baseTheme.colors.secondary};
    width: 25%;
    text-align: center;

    .description-bold {
      font-weight: 700;
      color: #121f30;
      margin: 15px 0px;
    }

    .price-input {
      width: 100%;
      color: #535d6a;
      font-size: 16px;
      -webkit-appearance: none;
      border: 2px solid ${baseTheme.colors.stroking};
      border-radius: 10px;
      padding: 5px 10px;
    }

    .error-input {
      border: 2px solid ${baseTheme.colors.error};
    }

    .error-msg {
      position: absolute;
      bottom: 50px;
      color: ${baseTheme.colors.error};
      width: max-content;
      height: 20px;
    }

    .disabled {
      pointer-events: none;
      background: ${baseTheme.colors.disabled} !important;
      border: 2px solid ${baseTheme.colors.disabled};
    }

    .assembly-btn {
      padding: 12px 24px;
      border-radius: 8px;
      background-color: ${baseTheme.colors.focus};
      cursor: pointer;
      margin-right: 24px;
      color: ${baseTheme.colors.white};
    }
  }
`;
