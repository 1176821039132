import styled from 'styled-components';
import { baseTheme } from '../../global/styles/theme';

export const SourcePageContainer = styled('div')`
  max-width: 1216px;
  margin: auto;
  padding: 34px 0;

  .roles {
    color: ${baseTheme.colors.mainLightBlue};
    font-weight: 700;
    font-size: 20px;
    padding-bottom: 34px;
  }

  .role {
    font-weight: 500;
    font-size: 18px;
    color: ${baseTheme.colors.mainLightBlue};
  }
`;

export const NetworksBlock = styled('div')`
  display: flex;
  flex-direction: column;

  .network {
    color: ${baseTheme.colors.secondary};
    font-weight: 400;
  }

  .network-name {
    color: ${baseTheme.colors.mainLightBlue};
    font-weight: 500;
    font-size: 18px;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export const SourceBlock = styled('div')`
  padding: 20px;
  border: 1px solid ${baseTheme.colors.stroking};
  border-radius: 8px;
  margin: 34px 0 65px 0;
`;

export const UsersBlock = styled('div')`
  padding: 20px;
  border: 1px solid ${baseTheme.colors.stroking};
  border-radius: 8px;
`;

export const Button = styled('div')`
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
  color: ${baseTheme.colors.white};
  background-color: ${baseTheme.colors.mainLightBlue};
  border-radius: 8px;
  padding: 12px 24px;
  text-align: center;
  max-width: 150px;
  margin: 24px 0 34px 0;
`;

export const SelectNetwork = styled('div')`
  display: flex;

  .selected-network {
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 12px;
    min-width: 316px;
    font-weight: 400;
    font-size: 16px;
    color: ${baseTheme.colors.primary};
    border: 1px solid ${baseTheme.colors.stroking};
    border-radius: 8px;
  }

  .networks-options {
    @keyframes fadeInDown {
      0% {
        opacity: 0;
        transform: translate3d(0px, 10%, 0px);
      }
      100% {
        opacity: 1;
        transform: none;
      }
    }
    position: absolute;
    z-index: 600;
    top: 155px;
    min-width: 316px;
    background-color: ${baseTheme.colors.white};
    padding: 0 16px 16px;
    box-shadow: 0 4px 10px rgba(37, 54, 67, 0.2);
    border-radius: 8px;
    max-height: 290px;
    overflow-y: auto;
    animation-duration: 0.4s;
    animation-fill-mode: both;
    animation-name: fadeInDown;

    div {
      font-weight: 500;
      font-size: 16px;
      color: #535d6a;
      padding-top: 16px;

      &:hover {
        color: ${baseTheme.colors.mainLightBlue};
        cursor: pointer;
      }
    }
  }

  .create-network-btn {
    border: none;
    font: inherit;
    font-weight: 500;
    font-size: 18px;
    color: ${baseTheme.colors.white};
    background-color: ${baseTheme.colors.mainLightBlue};
    border-radius: 8px;
    padding: 12px 24px;
    text-align: center;
    margin-left: 24px;
    cursor: pointer;

    &:hover {
      background-color: ${baseTheme.colors.mainBlue};
    }
  }
`;

export const TagsBlock = styled('div')`
  padding: 20px;
  border: 1px solid ${baseTheme.colors.stroking};
  border-radius: 8px;
  margin: 34px 0 65px 0;
`;
