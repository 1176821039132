import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import * as Styled from './ProductsPage.styles';
import ProductsBreadCrumbs from './components/ProductsBreadCrumbs';
import ProductsTable from './components/ProductsTable';
import { selectors } from './reducer';
import { selectors as loginSelectors } from '../LoginPage/reducer';
import Loader from '../../components/Loader';
import * as actions from './actions';
import { getFromLocalStorage, setToLocalStorage } from '../../global/helpers';
import * as actionService from '../EditPage/actions';
import {
  mappedCount,
  mappedCountProducts,
  mappedDataForProductsPageTable,
} from './helpers';
import { ProductTableData } from './types';
import NetworksModal from '../../components/NetworksModal';
import { UserRole } from '../OrdersPage/types';
import * as constants from './constants';

const ProductsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const dataUn = useSelector(selectors.unAttachedData);
  const dataAtt = useSelector(selectors.attachedData);
  const dataBl = useSelector(selectors.blockedData);
  const dataVer = useSelector(selectors.verificationData);
  const dataDybl = useSelector(selectors.dyblData);
  const dataUser = useSelector(loginSelectors.userData);
  const dataAutomapping = useSelector(selectors.automappingData);
  const mappings = useSelector(selectors.networksData);
  const isOpenNetworksModal = useSelector(selectors.isOpenNetworksModal);
  const networkForContent = useSelector(selectors.network);
  const tagIds = useSelector(selectors.tagIds)?.tagIds;
  const storageNetworkForContent = getFromLocalStorage('networkData');
  const role = getFromLocalStorage('role');
  const [managerCode, setManagerCode] = useState<string>(
    (mappings && mappings[0].code) || ''
  );
  const networkCode = networkForContent?.code
    ? networkForContent?.code
    : storageNetworkForContent?.code || managerCode;
  const [isGlobalSearch, setIsGlobalSearch] = useState<boolean>(
    getFromLocalStorage('isGlobalSearch') || false
  );
  const [searchValue, setSearchValue] = useState<string>('');
  const [allCounts, setAllCounts] = useState<any[]>([]);

  const countPages = mappedCount(
    dataUn,
    dataAtt,
    dataBl,
    dataVer,
    dataDybl,
    dataAutomapping
  );
  const count = mappedCountProducts(
    allCounts?.[0] || {},
    allCounts?.[1] || {},
    allCounts?.[2] || {},
    allCounts?.[3] || {},
    allCounts?.[4] || {},
    allCounts?.[5] || {}
  );

  const data: ProductTableData = mappedDataForProductsPageTable(
    dataUn,
    dataAtt,
    dataBl,
    dataVer,
    dataDybl,
    dataAutomapping
  );

  const token = getFromLocalStorage('accessToken');
  const [loading, setLoading] = useState(true);
  const [unAttachedNumberPage, setUnAttachedNumberPage] = useState(
    String(
      getFromLocalStorage('pagination')?.unAttachedPage
        ? getFromLocalStorage('pagination').unAttachedPage * 50
        : 0
    )
  );
  const [attachedNumberPage, setAttachedNumberPage] = useState(
    String(
      getFromLocalStorage('pagination')?.attachedPage
        ? getFromLocalStorage('pagination').attachedPage * 50
        : 0
    )
  );
  const [blockedNumberPage, setBlockedNumberPage] = useState(
    String(
      getFromLocalStorage('pagination')?.blockedPage
        ? getFromLocalStorage('pagination').blockedPage * 50
        : 0
    )
  );
  const [verificationNumberPage, setVerificationNumberPage] = useState(
    String(
      getFromLocalStorage('pagination')?.verificationPage
        ? getFromLocalStorage('pagination').verificationPage * 50
        : 0
    )
  );

  const [automappingNumberPage, setAutomappingNumberPage] = useState(
    String(
      getFromLocalStorage('pagination')?.automappingPage
        ? getFromLocalStorage('pagination').automappingPage * 50
        : 0
    )
  );

  const [dyblNumberPage, setDyblNumberPage] = useState(
    String(
      getFromLocalStorage('pagination')?.dyblPage
        ? getFromLocalStorage('pagination').dyblPage * 50
        : 0
    )
  );

  const setToPage = () => {
    setDyblNumberPage('0');
    setUnAttachedNumberPage('0');
    setVerificationNumberPage('0');
    setBlockedNumberPage('0');
    setAttachedNumberPage('0');
    setAutomappingNumberPage('0');
  };

  const clearProductsData = () => {
    dispatch(actions.clearProductsAttached());
    dispatch(actions.clearProductsUnAttached());
    dispatch(actions.clearProductsOnVer());
    dispatch(actions.clearProductsAutomapping());
    dispatch(actions.clearProductsBlocked());
  };

  const setData = () => {
    const tab = getFromLocalStorage(constants.ACTIVE_TAB_KEY);
    if (
      Number(blockedNumberPage) >= 0 &&
      Number(verificationNumberPage) >= 0 &&
      Number(attachedNumberPage) >= 0 &&
      Number(unAttachedNumberPage) >= 0 &&
      Number(dyblNumberPage) >= 0 &&
      Number(automappingNumberPage) >= 0 &&
      (!searchValue || searchValue?.length === 0)
    ) {
      if (tab === constants.UNATTACHED) {
        dispatch(
          actions.getProductsUnAtt.request({
            token,
            num: unAttachedNumberPage,
            tags: tagIds,
            code: isGlobalSearch ? '' : networkCode,
          })
        );
      }
      if (tab === constants.ATTACHED) {
        dispatch(
          actions.getProductsAtt.request({
            token,
            num: attachedNumberPage,
            tags: tagIds,
            code: isGlobalSearch ? '' : networkCode,
          })
        );
      }
      if (tab === constants.DYBL) {
        dispatch(
          actions.getProductsDybl.request({
            token,
            num: dyblNumberPage,
            tags: tagIds,
            code: isGlobalSearch ? '' : networkCode,
          })
        );
      }
      if (tab === constants.BLOCKED) {
        dispatch(
          actions.getProductsBlock.request({
            token,
            num: blockedNumberPage,
            tags: tagIds,
            code: isGlobalSearch ? '' : networkCode,
          })
        );
      }
      if (tab === constants.VERIFICATION) {
        dispatch(
          actions.getProductsVer.request({
            token,
            num: verificationNumberPage,
            tags: tagIds,
            code: isGlobalSearch ? '' : networkCode,
          })
        );
      }
      if (tab === constants.AUTOMAPPING) {
        dispatch(
          actions.getProductsAutomapping.request({
            token,
            num: automappingNumberPage,
            tags: tagIds,
            code: isGlobalSearch ? '' : networkCode,
          })
        );
      }
    }
  };

  const setCounts = async () => {
    const response = await axios({
      method: 'get',
      url: `${
        process.env.REACT_APP_API_BASE_URL
      }/api/v1/mappings/count/all?mapping_code=${
        isGlobalSearch ? '' : networkCode
      }`,
    });
    const result = response.data?.result;
    setAllCounts([
      { total_count: result.unmapped, mappings: [] },
      { total_count: result.verified, mappings: [] },
      { total_count: result.blocked, mappings: [] },
      { total_count: result.on_verification, mappings: [] },
      { total_count: result?.dubles || 0, mappings: [] },
      { total_count: result.automapping, mappings: [] },
    ]);
  };

  const trigger = true;

  useEffect(() => {
    const scrollToHashElement = () => {
      const elementToScroll = document.getElementById(
        getFromLocalStorage('selectedProduct')?.ware_id
      );

      if (!elementToScroll) return;

      window.scrollTo({
        top: elementToScroll.offsetTop,
        behavior: 'smooth',
      });
    };

    if (!trigger) return;

    scrollToHashElement();

    window.addEventListener('hashchange', scrollToHashElement);
    return () => window.removeEventListener('hashchange', scrollToHashElement);
  }, [loading]);

  useEffect(() => {
    (role === UserRole.MANAGER || role === UserRole.PHARMACY_MANAGER) &&
      dispatch(actions.getNetworks.request());
    if (
      storageNetworkForContent &&
      storageNetworkForContent?.code &&
      mappings
    ) {
      !managerCode && setManagerCode(mappings[0]?.code);
    } else {
      !managerCode && mappings && setManagerCode(mappings[0]?.code);
      (role === UserRole.MANAGER || role === UserRole.PHARMACY_MANAGER) &&
        mappings &&
        setToLocalStorage('networkData', {
          name: mappings[0]?.code,
          code: mappings[0]?.code,
          tag: mappings[0]?.tag,
        });
    }
  }, [dataUser, storageNetworkForContent && storageNetworkForContent?.code]);

  useEffect(() => {
    dispatch(actionService.clearSelectedProduct());
    if (
      storageNetworkForContent?.code ||
      networkForContent?.code ||
      managerCode
    ) {
      setData();
      setCounts();
    }
  }, [
    dispatch,
    token,
    unAttachedNumberPage,
    attachedNumberPage,
    blockedNumberPage,
    verificationNumberPage,
    dyblNumberPage,
    automappingNumberPage,
    networkForContent && networkForContent.code,
    storageNetworkForContent && storageNetworkForContent?.code,
    managerCode,
    tagIds,
    isGlobalSearch,
    searchValue,
  ]);

  useEffect(() => {
    if (!loading) {
      setToPage();
    }
  }, [isGlobalSearch]);

  useEffect(() => {
    if (dataUn || dataAtt || dataBl || dataVer || dataDybl || dataAutomapping)
      setLoading(false);
  }, [dataUn, dataAtt, dataBl, dataVer, dataDybl, dataAutomapping]);

  useEffect(() => {
    if (
      (role === UserRole.CONTENT ||
        role === UserRole.OPERATOR ||
        role === UserRole.HEAD_OPERATOR ||
        role === UserRole.ADMIN) &&
      !networkForContent?.code &&
      !storageNetworkForContent?.code
    ) {
      dispatch(actions.openNetworks());
    }
  }, [
    dispatch,
    role,
    networkForContent && networkForContent.code,
    storageNetworkForContent && storageNetworkForContent?.code,
  ]);

  useEffect(() => {
    dispatch(actions.getTags.request());
  }, [dispatch]);

  const handleSetManagerCode = (code: string) => {
    setManagerCode(code);
  };
  return (
    <>
      <Styled.ProductsPageContainer>
        {role !== UserRole.CONTENT &&
          role !== UserRole.OPERATOR &&
          role !== UserRole.HEAD_OPERATOR && <ProductsBreadCrumbs />}
        <Styled.ProductsPageHeaderContainer>
          <div className="products-title"> {t('ProductsPage.TITLE')}</div>{' '}
          {mappings?.length > 1 &&
            role !== UserRole.CONTENT &&
            role !== UserRole.OPERATOR &&
            role !== UserRole.HEAD_OPERATOR &&
            role !== UserRole.ADMIN && (
              <div
                className="mapping-code"
                onClick={() => dispatch(actions.openNetworks())}
              >
                {managerCode}
              </div>
            )}
        </Styled.ProductsPageHeaderContainer>
        {!loading && (
          <Styled.ProductsPageTableContainer>
            <ProductsTable
              data={data}
              countPages={countPages}
              // @ts-ignore
              count={count}
              setData={setData}
              unAttachedNumberPage={unAttachedNumberPage}
              attachedNumberPage={attachedNumberPage}
              blockedNumberPage={blockedNumberPage}
              verificationNumberPage={verificationNumberPage}
              dyblNumberPage={dyblNumberPage}
              automappingNumberPage={automappingNumberPage}
              setUnAttPage={(value: string) => setUnAttachedNumberPage(value)}
              setAttPage={(value: string) => setAttachedNumberPage(value)}
              setBlockPage={(value: string) => setBlockedNumberPage(value)}
              setVerPage={(value: string) => setVerificationNumberPage(value)}
              setDyblPage={(value: string) => setDyblNumberPage(value)}
              setAutomappingPage={(value: string) =>
                setAutomappingNumberPage(value)
              }
              isGlobalSearch={isGlobalSearch}
              setIsGlobalSearch={setIsGlobalSearch}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              clearProductsData={clearProductsData}
            />
          </Styled.ProductsPageTableContainer>
        )}
      </Styled.ProductsPageContainer>
      {isOpenNetworksModal && (
        <NetworksModal
          setManagerCode={handleSetManagerCode}
          setToPage={setToPage}
        />
      )}
      {loading && <Loader />}
    </>
  );
};

export default ProductsPage;
