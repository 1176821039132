import { createReducer } from '@reduxjs/toolkit';
import * as actions from './actions';
import { AppState } from '../../global/types';
import { AdminState } from './types';

const initialState: AdminState = {
  sourcesData: {
    data: null,
    loading: false,
    error: null,
  },
};

const reducer = createReducer(initialState, {
  [actions.getSources.REQUEST]: (state) => {
    state.sourcesData.loading = true;
  },
  [actions.getSources.ERROR]: (state, action) => {
    state.sourcesData.error = action.payload;
    state.sourcesData.loading = false;
  },
  [actions.getSources.SUCCESS]: (state, action) => {
    state.sourcesData.data = action.payload;
    state.sourcesData.loading = false;
  },
});

const sourcesData = (state: AppState) =>
  state.adminPageReducer.sourcesData.data;

const sourcesDLoading = (state: AppState) =>
  state.adminPageReducer.sourcesData.loading;

const tags = (state: AppState) => state.productsPageReducer.tagsData.data;

const selectors = {
  sourcesData,
  sourcesDLoading,
  tags,
};

export { selectors };
export default reducer;
