import styled from 'styled-components';
import { baseTheme } from '../../global/styles/theme';

export const StyledDropDownContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 10px 0px;

  .active-set-status-block {
    display: flex;
    justify-content: space-between;
  }
`;

export const SetStatus = styled('div')`
  display: flex;
  align-items: flex-end;
  position: relative;
  max-width: 350px;

  .status-block {
    margin-right: 10px;

    .set-status {
      display: flex;
      max-height: 170px !important;
      overflow-y: auto;
      justify-content: space-between;
      padding: 10px 8px;
      border-radius: 8px;
      border: 2px solid ${baseTheme.colors.mainLightBlue};
      min-height: 40px;
      width: 187px;
      cursor: pointer;

      .text {
        font-weight: 500;
        color: ${baseTheme.colors.mainLightBlue};
        margin-right: 12px;
      }
    }

    .title {
      width: 100%;
      font-size: 16px;
      font-weight: 700;
      color: #535d6a;
      margin-right: 8px;
      margin-bottom: 8px;
    }
  }
`;
