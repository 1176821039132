import React, { useState } from 'react';
import CustomCheckbox from '../../../../components/CustomCheckbox';

interface TagProps {
  name: string;
  id: string;
  onClick: (name: string) => void;
  checked: boolean;
}

const SelectTag = ({ name, id, onClick, checked }: TagProps) => {
  const handleChange = () => {
    onClick(id);
  };

  return (
    <CustomCheckbox
      id={id}
      checked={checked}
      onChange={handleChange}
      title={name}
    />
  );
};

export default SelectTag;
