import { createAction } from '@reduxjs/toolkit';
import * as constants from './constants';
import { asyncActionsCreator } from '../../global/redux';

export const getNetwork = asyncActionsCreator(constants.GET_NETWORK);
export const getAllNetwork = asyncActionsCreator(constants.GET_ALL_NETWORK);
export const getSourcesByNetwork = asyncActionsCreator(
  constants.GET_SOURCES_BY_NETWORK
);
export const getUsersOfNetwork = asyncActionsCreator(
  constants.GET_USERS_OF_NETWORK
);
export const editNetwork = asyncActionsCreator(constants.EDIT_NETWORK);
export const clearNetwork = createAction(constants.CLEAR_NETWORK);
export const clearSources = createAction(constants.CLEAR_SOURCES);
export const getNetworkTags = asyncActionsCreator(constants.GET_NETWORK_TAGS);
export const setNetworkTags = asyncActionsCreator(constants.SET_NETWORK_TAG);
export const unmapNetworkTag = asyncActionsCreator(constants.UNMAP_NETWORK_TAG);
