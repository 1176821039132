import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Loader from '../../components/Loader';
import UserTable from '../../components/UserTable';
import * as actionsSource from '../SourcePage/actions';
import TagsComponent from '../PharmacyTagsPage/components/TagsComponent';
import { filteredUsers } from '../SourcePage/helpers';
import * as Styled from './NetworkPage.styles';
import * as actions from './actions';
import NetworkBreadCrumbs from './components/NetworkBreadCrumbs';
import NetworkComponent from './components/NetworkComponent';
import Source from './components/Source';
import { selectors } from './reducer';
import { TagsTypeOptions } from '../PharmacyTagsPage/types';

const NetworkPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const networkCode = history.location.pathname.split('/')[2];
  const [newUser, addNewUser] = useState<boolean>(false);
  const [newUserManager, addNewUserManager] = useState<boolean>(false);
  const network = useSelector(selectors.networkData);
  const networkLoading = useSelector(selectors.networkLoading);
  const networkTagsData = useSelector(selectors.networkTagsData);
  const sources = useSelector(selectors.sourcesData);
  const users = useSelector(selectors.usersNetworkData);

  const handleAddNewUser = () => {
    addNewUser(true);
  };
  const handleNewUserManager = () => {
    addNewUserManager(!newUserManager);
  };

  const handleNoNewUser = () => {
    addNewUser(false);
  };

  useEffect(() => {
    dispatch(actions.getNetwork.request({ networkCode }));
    dispatch(actions.getUsersOfNetwork.request({ networkCode }));
    dispatch(actionsSource.clearSource());
  }, [dispatch]);

  useEffect(() => {
    dispatch(actions.clearSources());
    dispatch(actions.getSourcesByNetwork.request({ networkCode }));
    dispatch(actions.getNetworkTags.request());
  }, []);

  return (
    <>
      {networkLoading && <Loader />}
      {network && (
        <>
          <NetworkBreadCrumbs editActive={false} />
          <Styled.NetworkPageContainer>
            <Styled.NetworkBlock>
              <NetworkComponent data={network} />
            </Styled.NetworkBlock>
            <Styled.SourcesContainer>
              <div className="title">{t('NetworkPage.PHARMACY')}</div>
              {sources?.length !== 0 && sources && (
                <Styled.SourcesBlock>
                  <Source data={sources} />
                </Styled.SourcesBlock>
              )}
              <Styled.Button
                onClick={() =>
                  history.push(`/create?network_code=${networkCode}#source`)
                }
              >
                {t('SourcePage.ADD')}
              </Styled.Button>
            </Styled.SourcesContainer>
            <>
              <div className="roles">{t('SourcePage.ROLE')}</div>
              <>
                {(users && filteredUsers(users).manager?.length !== 0) ||
                newUser ? (
                  <Styled.UsersBlock>
                    <UserTable
                      data={filteredUsers(users).manager}
                      newUser={newUser}
                      networkCode={networkCode}
                      onAddNewUser={handleNoNewUser}
                    />
                  </Styled.UsersBlock>
                ) : (
                  <div className="role">{t('SourcePage.ROLES.MANAGERS')}</div>
                )}
                <Styled.Button onClick={handleAddNewUser}>
                  {t('SourcePage.ADD')}
                </Styled.Button>
              </>
              <>
                {(users &&
                  filteredUsers(users).pharmacyManager?.length !== 0) ||
                newUserManager ? (
                  <Styled.UsersBlock>
                    <UserTable
                      data={filteredUsers(users).pharmacyManager}
                      newUserManager={newUserManager}
                      networkCode={networkCode}
                      onAddNewUser={handleNewUserManager}
                    />
                  </Styled.UsersBlock>
                ) : (
                  <div className="role">
                    {t('SourcePage.ROLES.PHARMACY_MANAGER')}
                  </div>
                )}
                {!newUserManager ? (
                  <Styled.Button onClick={handleNewUserManager}>
                    {t('SourcePage.ADD')}
                  </Styled.Button>
                ) : (
                  <div />
                )}
              </>
            </>
            {networkTagsData && network.networkTags && (
              <Styled.TagsBlock>
                <TagsComponent
                  isAllTagsPage={false}
                  tagsType={TagsTypeOptions.network}
                  currentTags={network.networkTags}
                  sourceTagsData={networkTagsData}
                  code={networkCode}
                />
              </Styled.TagsBlock>
            )}
          </Styled.NetworkPageContainer>
        </>
      )}
    </>
  );
};

export default NetworkPage;
