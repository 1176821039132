import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as Styled from './PromoCodePage.styles';
import * as actions from './actions';
import TablePromoCode from './components/TablePromoCode/TablePromoCode';
import CreateTemplates from './components/CreateTemplates/CreateTemplates';
import Templates from './components/Templates/Templates';
import PromoCodes from './components/PromoCodes/PromoCodes';
import { getFromLocalStorage, setToLocalStorage } from '../../global/helpers';
import PharmacistPromoCode from './components/PharmacistPromoCode';
import { UserRole } from '../OrdersPage/types';

const PromoCodePage = () => {
  const { t } = useTranslation();
  const role = getFromLocalStorage('role');
  const dispatch = useDispatch();
  const [filter, setFilter] = useState('active');
  useEffect(() => {
    setToLocalStorage('selectedProduct', null);
  }, []);

  useEffect(() => {
    dispatch(actions.getTemplatesData.request());
    dispatch(actions.getPromoCodesData.request());
  }, [dispatch]);

  return (
    <Styled.PromoCodePageContainer>
      {role === UserRole.HEAD_OPERATOR && <PharmacistPromoCode />}
      {(role === UserRole.ADMIN || role === UserRole.PHARMACIST) && (
        <>
          <div className="title">{t('PROMO_CODE_PAGE.CREATE_TEMPLATES')}</div>
          <CreateTemplates />
          <div className="title">{t('PROMO_CODE_PAGE.TEMPLATES')}</div>
          <Templates />

          <div className="title">{t('PROMO_CODE_PAGE.CREATE_PROMO_CODE')}</div>
          <TablePromoCode />
          <div className="flex-container">
            {' '}
            <div className="title-accent">
              {t('PROMO_CODE_PAGE.PROMO_CODES')}
            </div>
            <div className="filter-container">
              <select
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                className="select"
              >
                <option value="active">Активные</option>
                <option value="archive">Архивные</option>
              </select>
            </div>
          </div>

          <PromoCodes filter={filter} />
          <div className="title">
            {t('PROMO_CODE_PAGE.REFERAL_PROMO_CODES')}
          </div>
          <PharmacistPromoCode />
        </>
      )}
    </Styled.PromoCodePageContainer>
  );
};

export default PromoCodePage;
