import styled from 'styled-components';
import { baseTheme } from '../../../../global/styles/theme';

export const OneContainer = styled('div')`
  .one-pharmacist-block {
    display: flex;
    flex-direction: column;
    border: 2px solid ${baseTheme.colors.stroking};
    border-radius: 8px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 100%;
  }

  .active-green {
    border: 2px solid ${baseTheme.colors.focus} !important;
  }

  .one-pharmacist-block__name {
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    max-width: 150px;
    word-break: break-word;
    color: ${baseTheme.colors.primary};
    padding: 4px;
  }

  .one-pharmacist-block__phone {
    text-decoration: underline;
    color: ${baseTheme.colors.primary};
  }

  .one-pharmacist-block__role {
    font-size: 14px;
    color: ${baseTheme.colors.secondary};
    padding: 4px;
  }

  .one-pharmacist-block__on-shift {
    color: #006622;
    font-weight: 700;
  }
`;
