import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as Styled from './Controls.styles';
import { setToLocalStorage, getFromLocalStorage } from '../../global/helpers';
import * as actionService from '../../containers/EditPage/actions';
import * as actionOrder from '../../containers/OrdersPage/actions';
import * as constants from '../../containers/ProductsPage/constants';
import { UserRole } from '../../containers/OrdersPage/types';

const Controls = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const role = getFromLocalStorage('role');

  const [isDesktopMode, setIsDesktopMode] = useState(window.innerWidth >= 1200);

  const [education, setEducation] = useState(
    history.location.pathname.includes('/test-orders') || false
  );

  const [isOpen, setOpen] = useState(false);

  const getActivePage = (active: string) => {
    return history.location.pathname === active ||
      history.location.pathname.includes(active)
      ? 'active'
      : '';
  };

  const handleClick = (push: string) => {
    setOpen(false);
    setToLocalStorage('selectedProduct', null);
    setToLocalStorage(constants.ACTIVE_TAB_KEY, constants.UNATTACHED);
    dispatch(actionService.clearSelectedProduct());
    dispatch(actionOrder.clearSelectedOrder());
    push !== '/orders' && dispatch(actionOrder.clearOrders());
    if (push === '/orders') {
      !education
        ? dispatch(
            actionOrder.getOrders.request({
              status: '',
              limit:
                role === UserRole.OPERATOR ||
                role === UserRole.ADMIN ||
                role === UserRole.HEAD_OPERATOR
                  ? 200
                  : 100,
              postEffect: () => history.push(push),
            })
          )
        : history.push('/orders');
    } else history.push(push);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsDesktopMode(window.innerWidth >= 1200);
    };

    const handleViewportChange = () => {
      const viewport = document.querySelector(
        'meta[name="viewport"]'
      ) as HTMLMetaElement | null;
      if (viewport) {
        setIsDesktopMode(viewport.content.includes('width=1200'));
      }
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('viewportChange', handleViewportChange);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('viewportChange', handleViewportChange);
    };
  }, []);

  return (
    <Styled.ControlsContainer className={`${isDesktopMode ? 'desktop' : ''}`}>
      <Styled.ControlsContentContainer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '20px',
            padding: '16px',
          }}
        >
          {role === UserRole.ADMIN && (
            <Styled.ControlButtonContainer
              className={`${getActivePage('/sources')}`}
              onClick={() => handleClick('/sources')}
              style={{
                margin: 0,
              }}
            >
              <img
                src={
                  getActivePage('/sources')
                    ? '/images/sources-white.svg'
                    : '/images/sources.svg'
                }
                alt="icon"
              />
              {t('Header.SOURCES_BTN_TEXT')}
            </Styled.ControlButtonContainer>
          )}
          {role === UserRole.PHARMACIST && (
            <Styled.ControlButtonContainer
              id="test-orders"
              className={`${getActivePage('/test-orders')}`}
              onClick={() => handleClick('/test-orders')}
              style={{
                margin: 0,
              }}
            >
              <img
                src={
                  getActivePage('/test-orders')
                    ? '/images/study-white.svg'
                    : '/images/study.svg'
                }
                alt="icon"
              />
              {t('Header.STUDY_BTN')}
            </Styled.ControlButtonContainer>
          )}
          {role === UserRole.MANAGER && (
            <Styled.ControlButtonContainer
              id="test-orders"
              className={`${getActivePage('/report')}`}
              onClick={() => handleClick('/report')}
              style={{
                margin: 0,
              }}
            >
              <img
                src={
                  getActivePage('/report')
                    ? '/images/report-white.svg'
                    : '/images/report.svg'
                }
                alt="icon"
              />
              {t('Header.REPORT_BTN_TEXT')}
            </Styled.ControlButtonContainer>
          )}
          {(role === UserRole.ADMIN ||
            role === UserRole.PHARMACIST ||
            role === UserRole.MANAGER ||
            role === UserRole.OPERATOR) && (
            <Styled.ControlButtonContainer
              id="orders"
              className={`${getActivePage('/orders')}`}
              onClick={() => handleClick('/orders')}
              style={{
                margin: 0,
              }}
            >
              <img
                src={
                  getActivePage('/orders')
                    ? '/images/orders-white.svg'
                    : '/images/orders.svg'
                }
                alt="icon"
              />
              {t('Header.ORDER_SEARCH_BTN_TEXT')}
            </Styled.ControlButtonContainer>
          )}
          {(role === UserRole.ADMIN ||
            role === UserRole.PHARMACIST ||
            role === UserRole.MANAGER) && (
            <Styled.ControlButtonContainer
              className={`${getActivePage('/sales')}`}
              onClick={() => handleClick('/sales')}
              style={{
                margin: 0,
              }}
            >
              {' '}
              <img
                src={
                  getActivePage('/sales')
                    ? '/images/sales-white.svg'
                    : '/images/sales.svg'
                }
                alt="icon"
              />
              {t('Header.SALES')}
            </Styled.ControlButtonContainer>
          )}
        </div>
      </Styled.ControlsContentContainer>
    </Styled.ControlsContainer>
  );
};

export default Controls;
