import { useTranslation } from 'react-i18next';
import ModalWindow from '../../../../components/ModalWindow';
import * as Styled from './StrongRecipeNotificationModal.styles';
import { Button } from '../../../OperatorsPharmacistPage/OperatorsPage.styles';

interface StrongRecipeNotificationModalProps {
  onClose: () => void;
}

const StrongRecipeNotificationModal = ({
  onClose,
}: StrongRecipeNotificationModalProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <ModalWindow onClose={onClose}>
        <Styled.StyledStrongRecipeNotificationModal>
          <div className="description">
            {process.env.REACT_APP_STRONG_RECIPE_MODAL_TEXT}
          </div>
          <div className="close-btn-container">
            <Button onClick={onClose}>{t('ProductsPage.OK')}</Button>
          </div>
        </Styled.StyledStrongRecipeNotificationModal>
      </ModalWindow>
    </div>
  );
};

export default StrongRecipeNotificationModal;
