import { createReducer } from '@reduxjs/toolkit';
import * as actions from './actions';
import { AppState } from '../../global/types';
import { ActiveUsersState } from './types';

const initialState: ActiveUsersState = {
  data: null,
  loading: false,
  error: null,
};

const reducer = createReducer(initialState, {
  [actions.getAllUsers.REQUEST]: (state) => {
    state.loading = true;
  },
  [actions.getAllUsers.ERROR]: (state, action) => {
    state.error = action.payload;
    state.loading = false;
  },
  [actions.getAllUsers.SUCCESS]: (state, action) => {
    state.data = action.payload;
    state.loading = false;
  },
  [actions.clearActiveUsers.type]: (state) => {
    state.data = null;
  },
});

const activeUsersData = (state: AppState) => state.activeUsersReducer.data;
const activeUsersDataLoading = (state: AppState) =>
  state.activeUsersReducer.loading;

const selectors = {
  activeUsersData,
  activeUsersDataLoading,
};

export { selectors };
export default reducer;
