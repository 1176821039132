import styled from 'styled-components';

export const InterPayContainer = styled('div')`
  width: 825px;
  margin-top: 20px;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    overflow: hidden;
    padding: 0 10px;
  }
`;

export const InterPayTop = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  & .tooltipBtn {
    border: 1px solid grey;
    padding: 10px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    font-size: 16px;
    cursor: pointer;
  }
`;
