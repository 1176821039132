import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalWindow from '../../../../../../components/ModalWindow';
import * as Styled from './SetCreatedPharmacyTagModal.styles';

interface SetCreatedPharmacyTagModalProps {
  onClose: () => void;
  setCreatedTag: (value: string) => void;
}

const SetCreatedPharmacyTagModal = ({
  onClose,
  setCreatedTag,
}: SetCreatedPharmacyTagModalProps) => {
  const { t } = useTranslation();
  const [textMeta, setTextMeta] = useState('');

  return (
    <ModalWindow onClose={onClose}>
      <Styled.SetCreatedTagModalContainer>
        <p className="title">
          {t('PharmacyTagsPage.SET_CREATED_PHARMACY_TAG_MODAL.TITLE')}
        </p>
        <div className="meta-input-container">
          <input
            name="textMeta"
            onChange={(e) => setTextMeta(e.target.value)}
          />
        </div>
        <Styled.SetPharmacyTagBtn onClick={() => setCreatedTag(textMeta)}>
          {t('PharmacyTagsPage.SET_CREATED_PHARMACY_TAG_MODAL.BIND')}
        </Styled.SetPharmacyTagBtn>
      </Styled.SetCreatedTagModalContainer>
    </ModalWindow>
  );
};

export default SetCreatedPharmacyTagModal;
