import React, { useState } from 'react';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Modal } from '@mui/material';
import * as actionsSources from '../../../SourcesPage/actions';
import * as Styled from './PharmacyActiveModal.styles';
import { UserRole } from '../../types';

const PharmacyActiveModal = ({
  sourceCode,
  role,
}: {
  sourceCode: string;
  role: string;
}) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(true);
  const handleClick = (status: boolean, close?: boolean) => {
    if (!close) {
      dispatch(
        actionsSources.setActiveStatus.request({
          sourceCode,
          status,
        })
      );
    }
    setIsOpen(false);
  };

  return (
    <Modal open={isOpen} onClose={() => {}}>
      <Styled.PharmacyActiveModalContainer>
        {role === UserRole.MANAGER && (
          <div className="manager-top">
            <button type="button" onClick={() => handleClick(true, true)}>
              {t('PharmacyActiveModal.CLOSE')}
            </button>
          </div>
        )}
        <h1 className="title">{t('PharmacyActiveModal.TITLE')}</h1>
        <li className="text">{t('PharmacyActiveModal.PLEASE_CONFIRM')}</li>
        <li className="text">{t('PharmacyActiveModal.MAKE_SURE')}</li>
        <li className="text">
          <Trans
            i18nKey="PharmacyActiveModal.CONTACT_NUMBER"
            components={{ b: <b /> }}
          />
        </li>
        <p className="text-blue">{t('PharmacyActiveModal.CONTACT')}</p>
        <p className="text-select">
          <Trans
            i18nKey="PharmacyActiveModal.SELECT_YES"
            components={{ b: <b /> }}
          />
        </p>
        <p className="text-select">
          <Trans
            i18nKey="PharmacyActiveModal.SELECT_NO"
            components={{ b: <b /> }}
          />
        </p>
        <div className="buttons">
          <button type="button" onClick={() => handleClick(false)}>
            {t('PharmacyActiveModal.NO')}
          </button>
          <button type="button" onClick={() => handleClick(true)}>
            {t('PharmacyActiveModal.YES')}
          </button>
        </div>
      </Styled.PharmacyActiveModalContainer>
    </Modal>
  );
};

export default PharmacyActiveModal;
