import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Field,
  Form,
  FormikProps,
  Formik,
  FormikErrors,
  ErrorMessage,
} from 'formik';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { NetworkProps } from '../../types';
import * as actions from '../../actions';
import * as Styled from './NetworkComponent.styles';
import editIcon from '../../../../global/media/edit-pencil.svg';
import { formatForEditNetwork } from '../../helpers';
import {
  formatBikNumberToBeValid,
  formatBinNumberToBeValid,
  formatIikNumberToBeValid,
  formatPhoneNumberToBeValid,
  getTrimmedFields,
  isCodeValid,
} from '../../../../global/helpers';
import { DEFAULT_NETWORK_STATE, selectRoundingMethod } from '../../constants';
import CustomTextarea from '../../../../components/CustomTextarea';
import SelectOptions from '../../../SourcePage/components/SourceComponent/components/SelectOptions';

interface NetworkParam {
  data?: NetworkProps;
  empty?: boolean;
  networkName?: string;
}

const NetworkComponent = ({ data, empty, networkName }: NetworkParam) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [editActive, setEditActive] = useState<boolean>(false);
  const formikRef = useRef(null) as any;
  const [roundingMeth, setRoundingMeth] = useState<string>(
    data?.roundingMethod === 'up' ? data?.roundingMethod : 'down'
  );
  const [networkNaming, setNetworkNaming] = useState<string>(
    data ? data?.name : ''
  );

  const [roundingMethOpen, setRoundingMethOpen] = useState<boolean>(false);

  const handleChange = (
    props: FormikProps<NetworkProps>,
    event: any,
    inputName: string
  ) => {
    if (inputName === 'phone') {
      const phoneInputValue = event.target.value.replaceAll(/[\s-()]/g, '');
      if (!phoneInputValue.match(/^\+7\d{0,10}$/)) {
        return;
      }
      props.setErrors({
        ...props.errors,
        [inputName]: '',
      });
      props.setFieldValue(inputName, phoneInputValue);
    }
    if (inputName === 'roundingMethod') {
      props.setFieldValue(inputName, event.title);
    } else props.setFieldValue(inputName, event.target.value);
  };

  useEffect(() => {
    if (data) {
      formikRef.current.setFieldValue('bank', data.bank);
      formikRef.current.setFieldValue('bik', data.bik);
      formikRef.current.setFieldValue('bin', data.bin);
      formikRef.current.setFieldValue('code', data.code);
      formikRef.current.setFieldValue('director', data.director);
      formikRef.current.setFieldValue('iik', data.iik);
      formikRef.current.setFieldValue('legalAddress', data.legalAddress);
      formikRef.current.setFieldValue('legalName', data.legalName);
      formikRef.current.setFieldValue('name', data.name);
      formikRef.current.setFieldValue(
        'organizationType',
        data.organizationType
      );
      formikRef.current.setFieldValue('phone', data.phone);
      formikRef.current.setFieldValue('rounding', data.rounding);
      formikRef.current.setFieldValue(
        'roundingMethod',
        data.roundingMethod === 'up' ? 'Вверх' : 'Вниз'
      );
      setNetworkNaming(data.name);
    } else {
      formikRef.current.setFieldValue(
        'roundingMethod',
        DEFAULT_NETWORK_STATE.roundingMethod === 'up' ? 'Вверх' : 'Вниз'
      );
      setRoundingMeth(DEFAULT_NETWORK_STATE.roundingMethod);
    }
  }, [data]);

  const handleSubmitForm = (values: NetworkProps) => {
    if (data) {
      dispatch(
        actions.editNetwork.request({
          networkInfo: formatForEditNetwork(
            values,
            networkNaming,
            data.organizationType,
            roundingMeth
          ),
          postEffect: () => {
            setEditActive(false);
            dispatch(
              actions.getNetwork.request({
                networkCode: getTrimmedFields(values.code),
              })
            );
          },
        })
      );
    } else if (empty && networkName) {
      dispatch(
        actions.editNetwork.request({
          networkInfo: formatForEditNetwork(
            values,
            networkName,
            '',
            roundingMeth
          ),
          postEffect: () => {
            setEditActive(false);
            dispatch(
              actions.getNetwork.request({
                networkCode: getTrimmedFields(values.code),
              })
            );
            history.push(`/networks/${getTrimmedFields(values.code)}`);
          },
        })
      );
    }
  };

  const validateForm = (values: NetworkProps) => {
    const errors: FormikErrors<NetworkProps> = {};
    if (!values.code || !isCodeValid(values.code)) {
      errors.code = t('NetworkPage.INPUTS_ERROR.CODE');
    }

    if (!values.legalName) {
      errors.legalName = t('NetworkPage.INPUTS_ERROR.LEGAL_NAME');
    }

    if (!values.bank) {
      errors.bank = t('NetworkPage.INPUTS_ERROR.BANK');
    }

    if (!values.legalAddress) {
      errors.legalAddress = t('NetworkPage.INPUTS_ERROR.ADDRESS');
    }

    if (!values.rounding) {
      errors.rounding = t('NetworkPage.INPUTS_ERROR.ROUNDING');
    }

    if (!values.roundingMethod) {
      errors.roundingMethod = t('NetworkPage.INPUTS_ERROR.ROUNDING_METHOD');
    }

    if (!formatIikNumberToBeValid(values.iik)) {
      errors.iik = t('NetworkPage.INPUTS_ERROR.IIK');
    }

    if (!formatBikNumberToBeValid(values.bik)) {
      errors.bik = t('NetworkPage.INPUTS_ERROR.BIK');
    }

    if (!values.director) {
      errors.director = t('NetworkPage.INPUTS_ERROR.DIRECTOR');
    }

    if (!formatPhoneNumberToBeValid(values.phone)) {
      errors.phone = t('NetworkPage.INPUTS_ERROR.PHONE');
    }

    if (!formatBinNumberToBeValid(values.bin)) {
      errors.bin = t('NetworkPage.INPUTS_ERROR.BIN');
    }

    return errors;
  };

  const INITIAL_STATE = data
    ? {
        bank: data.bank,
        bik: data.bik,
        bin: data.bin,
        code: data.code,
        director: data.director,
        iik: data.iik,
        legalAddress: data.legalAddress,
        legalName: data.legalName,
        name: data.name,
        organizationType: data.organizationType,
        phone: data.phone,
        rounding: data.rounding,
        roundingMethod: data.roundingMethod,
      }
    : DEFAULT_NETWORK_STATE;

  return (
    <Styled.NetworkMainBlock>
      <div className="header">
        {data && editActive ? (
          <div className="textarea">
            <CustomTextarea
              onChange={(e) => setNetworkNaming(e)}
              value={networkNaming}
            />
          </div>
        ) : (
          data && (
            <div className="name">
              {data.organizationType && data.organizationType} {data.name}
            </div>
          )
        )}
        {!empty && (
          <div className="icons-block">
            <div onClick={() => setEditActive(!editActive)}>
              <img src={editIcon} alt="edit" />
            </div>
            {/* <div> */}
            {/*  <img src={deleteIcon} alt="delete" className="delete-icon" /> */}
            {/* </div> */}
          </div>
        )}
      </div>
      <Styled.NetworkInfo>
        <Formik
          onSubmit={handleSubmitForm}
          validate={validateForm}
          innerRef={formikRef}
          initialValues={empty ? DEFAULT_NETWORK_STATE : INITIAL_STATE}
        >
          {(props: FormikProps<any>) => (
            <Form className="form-container">
              <div />
              <div>
                <div className="form-input">
                  <label htmlFor="code" className="label">
                    {t('NetworkPage.INPUTS_LABEL.CODE')}
                  </label>
                  <Field
                    id="code"
                    name="code"
                    disabled={!empty}
                    type="text"
                    className="input"
                    onChange={(e: any) => {
                      handleChange(props, e, 'code');
                    }}
                  />
                  <ErrorMessage
                    name="code"
                    render={(msg: string) => (
                      <div className="error-message-label">{msg}</div>
                    )}
                  />
                </div>
                <div className="form-input">
                  <label htmlFor="legalAddress" className="label">
                    {t('NetworkPage.INPUTS_LABEL.ADDRESS')}
                  </label>
                  <Field
                    id="legalAddress"
                    name="legalAddress"
                    type="text"
                    disabled={!editActive && !empty}
                    className="input"
                    onChange={(e: any) => {
                      handleChange(props, e, 'legalAddress');
                    }}
                  />
                  <ErrorMessage
                    name="legalAddress"
                    render={(msg: string) => (
                      <div className="error-message-label">{msg}</div>
                    )}
                  />
                </div>
                <div className="form-input">
                  <label htmlFor="bank" className="label">
                    {t('NetworkPage.INPUTS_LABEL.BANK')}
                  </label>
                  <Field
                    id="bank"
                    name="bank"
                    type="text"
                    disabled={!editActive && !empty}
                    className="input"
                    onChange={(e: any) => {
                      handleChange(props, e, 'bank');
                    }}
                  />
                  <ErrorMessage
                    name="bank"
                    render={(msg: string) => (
                      <div className="error-message-label">{msg}</div>
                    )}
                  />
                </div>
              </div>
              <div>
                <div className="form-input">
                  <label htmlFor="legalName" className="label">
                    {t('NetworkPage.INPUTS_LABEL.LEGAL_NAME')}
                  </label>
                  <Field
                    id="legalName"
                    name="legalName"
                    type="text"
                    disabled={!editActive && !empty}
                    className="input"
                    onChange={(e: any) => {
                      handleChange(props, e, 'legalName');
                    }}
                  />
                  <ErrorMessage
                    name="legalName"
                    render={(msg: string) => (
                      <div className="error-message-label">{msg}</div>
                    )}
                  />
                </div>
                <div className="form-input">
                  <label htmlFor="iik" className="label">
                    {t('NetworkPage.INPUTS_LABEL.IIK')}
                  </label>
                  <Field
                    id="iik"
                    name="iik"
                    type="text"
                    disabled={!editActive && !empty}
                    className="input"
                    onChange={(e: any) => {
                      handleChange(props, e, 'iik');
                    }}
                  />
                  <ErrorMessage
                    name="iik"
                    render={(msg: string) => (
                      <div className="error-message-label">{msg}</div>
                    )}
                  />
                </div>
                <div className="form-input">
                  <label htmlFor="phone" className="label">
                    {t('NetworkPage.INPUTS_LABEL.PHONE')}
                  </label>
                  <Field
                    id="phone"
                    name="phone"
                    type="text"
                    disabled={!editActive && !empty}
                    className={`input ${props.errors.phone && 'input-error'}`}
                    onChange={(e: any) => {
                      handleChange(props, e, 'phone');
                    }}
                  />
                  <ErrorMessage
                    name="phone"
                    render={(msg: string) => (
                      <div className="error-message-label">{msg}</div>
                    )}
                  />
                </div>
                <div className="form-input">
                  <label htmlFor="director" className="label">
                    {t('NetworkPage.INPUTS_LABEL.DIRECTOR')}
                  </label>
                  <Field
                    id="director"
                    name="director"
                    type="text"
                    disabled={!editActive && !empty}
                    className="input"
                    onChange={(e: any) => {
                      handleChange(props, e, 'director');
                    }}
                  />
                  <ErrorMessage
                    name="director"
                    render={(msg: string) => (
                      <div className="error-message-label">{msg}</div>
                    )}
                  />
                </div>
              </div>
              <div>
                <div className="form-input">
                  <label htmlFor="bin" className="label">
                    {t('NetworkPage.INPUTS_LABEL.BIN')}
                  </label>
                  <Field
                    id="bin"
                    name="bin"
                    type="number"
                    disabled={!editActive && !empty}
                    className="input"
                    onChange={(e: any) => {
                      handleChange(props, e, 'bin');
                    }}
                  />
                  <ErrorMessage
                    name="bin"
                    render={(msg: string) => (
                      <div className="error-message-label">{msg}</div>
                    )}
                  />
                </div>
                <div className="form-input">
                  <label htmlFor="bin" className="label">
                    {t('NetworkPage.INPUTS_LABEL.BIK')}
                  </label>
                  <Field
                    id="bik"
                    name="bik"
                    type="text"
                    disabled={!editActive && !empty}
                    className="input"
                    onChange={(e: any) => {
                      handleChange(props, e, 'bik');
                    }}
                  />
                  <ErrorMessage
                    name="bik"
                    render={(msg: string) => (
                      <div className="error-message-label">{msg}</div>
                    )}
                  />
                </div>

                <div
                  className="form-input input-relative"
                  onClick={() => setRoundingMethOpen(!!(editActive || empty))}
                >
                  <label htmlFor="roundingMethod" className="label">
                    {t('NetworkPage.INPUTS_LABEL.ROUNDING_METHOD')}
                  </label>
                  <Field
                    id="roundingMethod"
                    name="roundingMethod"
                    type="text"
                    disabled={!editActive && !empty}
                    className="input"
                    readOnly
                    onChange={(e: any) => {
                      handleChange(props, e, 'roundingMethod');
                    }}
                  />
                  <ErrorMessage
                    name="roundingMethod"
                    render={(msg: string) => (
                      <div className="error-message-label">{msg}</div>
                    )}
                  />
                  {roundingMethOpen && (
                    <SelectOptions
                      data={selectRoundingMethod}
                      onSetCode={(el) => {
                        handleChange(props, el, 'roundingMethod');
                        setRoundingMeth(el.code as string);
                      }}
                      onClose={() => setRoundingMethOpen(false)}
                    />
                  )}{' '}
                </div>
                <div className="form-input">
                  <label htmlFor="rounding" className="label">
                    {t('NetworkPage.INPUTS_LABEL.ROUNDING')}
                  </label>
                  <Field
                    id="rounding"
                    name="rounding"
                    type="number"
                    disabled={!editActive && !empty}
                    className="input"
                    onChange={(e: any) => {
                      handleChange(props, e, 'rounding');
                    }}
                  />
                  <ErrorMessage
                    name="rounding"
                    render={(msg: string) => (
                      <div className="error-message-label">{msg}</div>
                    )}
                  />
                </div>
              </div>
              {(editActive || empty) && (
                <button
                  type="submit"
                  className={`button ${empty && 'button-create'}`}
                >
                  {t('SourcePage.SAVE')}
                </button>
              )}
            </Form>
          )}
        </Formik>
      </Styled.NetworkInfo>
    </Styled.NetworkMainBlock>
  );
};

export default NetworkComponent;
