import React, { ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Formik,
  FormikProps,
  FormikErrors,
  Field,
  ErrorMessage,
} from 'formik';
import * as actions from '../../actions';
import * as Styled from './TablePromoCode.styles';
import { GeneratePromoCodePropsRequest } from '../../types';
import {
  formatIikPromoCodeToBeValid,
  formatPhoneNumberToBeValid,
} from '../../../../global/helpers';

const TablePromoCode = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSubmitForm = async (values: GeneratePromoCodePropsRequest) => {
    await dispatch(
      actions.generatePromoCodes.request({
        ...values,
        validFrom: values.validFrom
          ? `${values.validFrom}T00:00:00.000000Z`
          : null,
        validUntil: values.validUntil
          ? `${values.validUntil}T00:00:00.000000Z`
          : null,

        postEffect: () => {
          dispatch(actions.getPromoCodesData.request());
        },
      })
    );
  };
  const INITIAL_STATE = {
    code: null,
    description: '',
    templateId: null,
    user: '',
    validDuration: null,
    validFrom: null,
    validUntil: null,
  };
  const validateForm = (values: GeneratePromoCodePropsRequest) => {
    const errors: FormikErrors<GeneratePromoCodePropsRequest> = {};

    if (values.code && !formatIikPromoCodeToBeValid(values.code)) {
      errors.code = t('PROMO_CODE_PAGE.WRONG_FORMATE_CODE');
    }
    if (!values.templateId) {
      errors.templateId = t('PROMO_CODE_PAGE.ERROR_ID');
    }
    if (!values.description) {
      errors.description = t('PROMO_CODE_PAGE.ERROR_DESCRIPTION');
    }
    if (values.user && !formatPhoneNumberToBeValid(values.user)) {
      errors.user = t('LoginPage.PHONE_INVALID_ERROR');
    }

    return errors;
  };
  const handleChange = (
    props: FormikProps<GeneratePromoCodePropsRequest>,
    event: ChangeEvent<HTMLInputElement>,
    inputName: string
  ) => {
    if (inputName === 'code') {
      props.setFieldValue('code', event.target.value.toUpperCase());
    } else if (inputName === 'templateId') {
      props.setFieldValue('templateId', Number(event.target.value));
    } else if (inputName === 'validDuration') {
      props.setFieldValue('validDuration', Number(event.target.value));
    } else {
      props.setFieldValue(inputName, event.target.value);
    }
  };

  return (
    <Styled.TablePromoCodeContainer>
      <Formik
        onSubmit={handleSubmitForm}
        validate={validateForm}
        initialValues={INITIAL_STATE}
      >
        {(props: FormikProps<GeneratePromoCodePropsRequest>) => (
          <Form className="form-container">
            <div className="container-header">
              <div className="cell-header">
                {t('PROMO_CODE_PAGE.ID_TEMPLATE')}
              </div>
              <div className="cell-header">
                {t('PROMO_CODE_PAGE.DESCRIPTION_TEMPLATE')}
              </div>
              <div className="cell-header">
                {t('PROMO_CODE_PAGE.CODE_TEMPLATE')}
              </div>
              <div className="cell-header">
                {t('PROMO_CODE_PAGE.USER_TEMPLATE')}
              </div>
              <div className="cell-header">
                {t('PROMO_CODE_PAGE.DURATION_TEMPLATE')}
              </div>
              <div className="cell-header">
                {t('PROMO_CODE_PAGE.FROM_TEMPLATE')}
              </div>
              <div className="cell-header">
                {t('PROMO_CODE_PAGE.TO_TEMPLATE')}
              </div>
            </div>

            <div className="container-table">
              <div className="cell">
                <Field
                  id="templateId"
                  name="templateId"
                  type="number"
                  disabled={false}
                  className="input"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    handleChange(props, e, 'templateId');
                  }}
                />
                <ErrorMessage
                  name="templateId"
                  render={(msg: string) => (
                    <div className="error-message-label">{msg}</div>
                  )}
                />
              </div>
              <div className="cell">
                <Field
                  id="description"
                  name="description"
                  type="text"
                  disabled={false}
                  className="input"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    handleChange(props, e, 'description');
                  }}
                />
                <ErrorMessage
                  name="description"
                  render={(msg: string) => (
                    <div className="error-message-label">{msg}</div>
                  )}
                />
              </div>
              <div className="cell">
                <Field
                  placeHolder="CODE"
                  id="code"
                  name="code"
                  type="text"
                  disabled={false}
                  className="input"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    handleChange(props, e, 'code');
                  }}
                />
                <ErrorMessage
                  name="code"
                  render={(msg: string) => (
                    <div className="error-message-label">{msg}</div>
                  )}
                />
              </div>
              <div className="cell">
                <Field
                  id="user"
                  name="user"
                  type="text"
                  disabled={false}
                  placeHolder="+77777777777"
                  className="input"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    handleChange(props, e, 'user');
                  }}
                />
                <ErrorMessage
                  name="user"
                  render={(msg: string) => (
                    <div className="error-message-label">{msg}</div>
                  )}
                />
              </div>
              <div className="cell">
                <Field
                  id="validDuration"
                  name="validDuration"
                  type="number"
                  disabled={false}
                  className="input"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    handleChange(props, e, 'validDuration');
                  }}
                />
              </div>
              <div className="cell">
                <Field
                  id="validFrom"
                  name="validFrom"
                  type="date"
                  disabled={false}
                  className="input"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    handleChange(props, e, 'validFrom');
                  }}
                />
              </div>
              <div className="cell">
                <Field
                  id="validUntil"
                  name="validUntil"
                  type="date"
                  disabled={false}
                  className="input"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    handleChange(props, e, 'validUntil');
                  }}
                />
              </div>
            </div>

            <button type="submit" className="create-templates">
              {t('PROMO_CODE_PAGE.CREATE_PROMO_CODE')}
            </button>
          </Form>
        )}
      </Formik>
    </Styled.TablePromoCodeContainer>
  );
};

export default TablePromoCode;
