import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useFaviconBadgeNotify from 'favicon-badge-notify';
import AudioPlayer from 'react-h5-audio-player';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../containers/OrdersPage/actions';
import ModalWindow from '../ModalWindow';
import * as Styled from './NewOrderNotification.styles';
import Button from '../Button';
import sound from '../../global/media/sound-noti.mp3';
import faviconSvg from '../../global/media/favicon.svg';
import { selectors } from '../../containers/OrdersPage/reducer';

interface NewOrderNotificationProps {
  onClose: () => void;
  newOrders: any;
}

const NewOrderNotification = ({
  onClose,
  newOrders,
}: NewOrderNotificationProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const education = history.location.pathname.includes('/test-orders');
  const viewedQuantity = useSelector(selectors.notViewedOrders)?.count;
  const [favicon, setFavicon] = useState(faviconSvg);
  const { drawBadge, destroyBadge } = useFaviconBadgeNotify({
    src: faviconSvg,
  });

  useEffect(() => {
    dispatch(actions.getNotViewedOrders.request({ testOrders: !!education }));
  }, [dispatch]);

  useEffect(() => {
    if (viewedQuantity)
      drawBadge(viewedQuantity).then((badge: string) => setFavicon(badge));
    return () => destroyBadge();
  }, [viewedQuantity]);

  const handleConfirm = () => {
    const orderIdArray = newOrders?.order_ids;
    dispatch(
      actions.putMutedOrders.request({
        orderData: orderIdArray,
        postEffect: () => {
          dispatch(
            actions.getNotMutedOrders.request({ testOrders: !!education })
          );
        },
      })
    );
  };

  return (
    <>
      <Helmet>
        <link rel="icon" type="image/png" href={favicon} />
      </Helmet>
      <ModalWindow onClose={onClose} noClose>
        <Styled.ModalContainer>
          <Styled.Title>
            {t('OrdersPage.OrderConfirmationModal.TITLE')}
          </Styled.Title>
          <Styled.Quantity>
            {t('OrdersPage.OrderConfirmationModal.QUANTITY')} {viewedQuantity}
          </Styled.Quantity>
          <Styled.ButtonConfirm>
            <Button
              text={t('OrdersPage.OrderConfirmationModal.BTN_CONFIRM')}
              onClick={handleConfirm}
            />
          </Styled.ButtonConfirm>
          <div className="no-visible">
            <AudioPlayer src={sound} autoPlay loop />
          </div>
        </Styled.ModalContainer>
      </ModalWindow>
    </>
  );
};

export default NewOrderNotification;
