import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ClickAwayListener } from '@mui/material';
import { selectors as selectorsLogin } from '../../LoginPage/reducer';
import * as Styled from './SalesManagerCollector.styles';
import * as actions from '../../OrdersPage/actions';
import { selectors } from '../../OrdersPage/reducer';
import networksArrow from '../../../global/media/networks-arrow.svg';
import { SalesSelectedPharmacy } from '../types';

const SalesManagerCollector = ({
  currentPharmacy,
  setCurrentPharmacy,
}: {
  currentPharmacy: SalesSelectedPharmacy | null;
  setCurrentPharmacy: React.Dispatch<
    React.SetStateAction<SalesSelectedPharmacy | null>
  >;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dataUser = useSelector(selectorsLogin.userData);

  const sourcesData = useSelector(selectors.sourcesData);
  const [isSourcesOpen, setCurrentPharmacysOpen] = useState<boolean>(false);

  useEffect(() => {
    if (dataUser && sourcesData) {
      const s = sourcesData.find(
        (el) => el.code === dataUser.collectingSourceCode
      );
      s &&
        setCurrentPharmacy({
          name: s.name,
          code: s.code,
        });
      sourcesData &&
        sourcesData?.length === 1 &&
        setCurrentPharmacy({
          name: sourcesData[0].name,
          code: sourcesData[0].code,
        });
    }
  }, [dataUser, sourcesData?.length]);

  const handleClickSourcesOpen = () => {
    sourcesData &&
      sourcesData?.length > 1 &&
      setCurrentPharmacysOpen(!isSourcesOpen);
  };

  useEffect(() => {
    if (dataUser) {
      dispatch(
        actions.getSourcesByNetwork.request({
          networkCode: dataUser?.networkCode,
          postEffect: (source: {
            name: string;
            code: string;
            active: boolean;
            activeUpdatedAt: string | null;
            activeUpdatedBy: string | null;
          }) => {
            setCurrentPharmacy({
              name: source.name,
              code: source.code,
            });
          },
        })
      );
    }
  }, [dispatch, dataUser]);

  return sourcesData && sourcesData?.length !== 0 ? (
    <Styled.ManagerCollectorContainer>
      <p>{t('SALESPAGE.ACTION_BUTTONS.CHANGE_PHARMACY')}</p>
      <Styled.SourceCodesBlock onClick={handleClickSourcesOpen}>
        <div className="source-name">{currentPharmacy?.name}</div>
        {sourcesData && sourcesData?.length > 1 && (
          <img src={networksArrow} alt="networks-arrow" />
        )}
        {isSourcesOpen && (
          <ClickAwayListener onClickAway={() => setCurrentPharmacysOpen(false)}>
            <Styled.SourcesContainer>
              {sourcesData?.map((item: { code: string; name: string }) => (
                <div
                  className="name"
                  key={item.code}
                  onClick={() => {
                    setCurrentPharmacy(item);
                  }}
                >
                  {item.name}
                </div>
              ))}
            </Styled.SourcesContainer>
          </ClickAwayListener>
        )}
      </Styled.SourceCodesBlock>
    </Styled.ManagerCollectorContainer>
  ) : null;
};

export default SalesManagerCollector;
