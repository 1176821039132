import React, { useEffect, useState } from 'react';
import { CircularProgress, ClickAwayListener } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as heplers from './helpers';
import * as constants from './constants';
import * as actions from '../../../../actions';
import { selectors } from '../../../../reducer';
import { selectors as loginSelectors } from '../../../../../LoginPage/reducer';
import * as Styled from './FilterBySource.styles';
import { UserRole } from '../../../../types';

interface FilterBySourceProps {
  onClose: () => void;
  onSourceCLick: (source: string) => void;
  operatorFilter?: boolean;
  searchStr?: string;
  isPharmacy?: boolean;
}

const FilterBySource = ({
  onClose,
  onSourceCLick,
  operatorFilter,
  searchStr = '',
  isPharmacy,
}: FilterBySourceProps) => {
  const dispatch = useDispatch();
  const networkCode = useSelector(loginSelectors.userData)?.networkCode;
  const { t } = useTranslation();

  const sourcesData = useSelector(selectors.sourcesData);
  const schedule = useSelector(selectors.scheduleData);
  const loading = useSelector(selectors.scheduleDataLoading);
  const [visibleData, setVisibleData] = useState(sourcesData);
  const [activePharmacy, setActivePharmacy] = useState(-1);

  useEffect(() => {
    setVisibleData(sourcesData);
  }, [sourcesData]);

  const handleSearchByName = (name: string) => {
    if (name) {
      setVisibleData(
        (sourcesData &&
          sourcesData?.filter(
            (el) =>
              el?.name && el.name.toLowerCase().includes(name.toLowerCase())
          )) ||
          []
      );
    } else {
      setVisibleData(sourcesData);
    }
  };

  useEffect(() => {
    searchStr?.length > 0 && handleSearchByName(searchStr);
  }, [searchStr]);

  useEffect(() => {
    !sourcesData &&
      dispatch(
        actions.getSourcesByNetwork.request({
          networkCode,
          operator: operatorFilter,
        })
      );
  }, [dispatch, sourcesData]);

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Styled.SourceContainer isPharmacy>
        <div
          className={isPharmacy ? 'titleAccent' : 'title'}
          key="all"
          onClick={() => onSourceCLick('' as string)}
        >
          {t('OrdersPage.OrdersTable.ALL_SOURCES')}
        </div>
        {visibleData ? (
          [...visibleData].sort(heplers.sort).map((item, i) => (
            <div
              className={isPharmacy ? 'titleAccent' : 'title'}
              key={item.code}
            >
              <div className="container-item">
                <span onClick={() => onSourceCLick(item.code)}>
                  {item.name}
                </span>

                <button
                  className="button-contacts"
                  type="button"
                  onClick={() => {
                    activePharmacy === i
                      ? setActivePharmacy(-1)
                      : setActivePharmacy(i);

                    dispatch(
                      actions.getSchedule.request({
                        sourceCode: item.code,
                      })
                    );
                  }}
                >
                  {t('ProductsPage.CONTACTS')}
                </button>
              </div>
              {activePharmacy === i && !loading && (
                <table className="table">
                  <tr className="contacts-list-header">
                    {constants.data?.map((item, i) => (
                      <th
                        className={i !== 1 ? 'text' : 'textAccent'}
                        key={item}
                      >
                        <p>{t(item)}</p>
                      </th>
                    ))}
                  </tr>

                  {schedule?.map((item) => (
                    <tr className="contacts-list item" key={item.phone}>
                      <td className="text">{item.name}</td>
                      <td className="textAccent">{item.phone}</td>
                      <td className="text">
                        {item.role === UserRole.MANAGER &&
                          t('OrderHistoryPage.UserRole.MANAGER')}
                        {item.role === UserRole.OPERATOR &&
                          t('OrderHistoryPage.UserRole.OPERATOR')}
                        {item.role === UserRole.HEAD_OPERATOR &&
                          t('OrderHistoryPage.UserRole.HEAD_OPERATOR')}
                        {item.role === UserRole.PHARMACY_MANAGER &&
                          t('OrderHistoryPage.UserRole.PHARMACY_MANAGER')}
                        {item.role === UserRole.PHARMACIST &&
                          t('OrderHistoryPage.UserRole.PHARMACIST')}
                      </td>
                      <td className="text">
                        <div className={item.active ? 'online' : 'offline'} />
                      </td>
                    </tr>
                  ))}
                </table>
              )}
            </div>
          ))
        ) : (
          <CircularProgress />
        )}
      </Styled.SourceContainer>
    </ClickAwayListener>
  );
};

export default FilterBySource;
