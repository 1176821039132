import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './DeleteProductModal.styles';
import ModalWindow from '../ModalWindow';
import Button from '../Button';
import { getFromLocalStorage } from '../../global/helpers';
import * as constants from '../../../src/containers/ProductsPage/constants';

interface DeleteProductModalProps {
  setReason?: (reason: string) => void;
  onClose: () => void;
  onDelete: () => void;
  onDeleteMap: () => void;
}

const DeleteProductModal = ({
  setReason,
  onClose,
  onDelete,
  onDeleteMap,
}: DeleteProductModalProps) => {
  const { t } = useTranslation();

  const handleCancelClick = () => {
    onClose();
  };

  const activeTab = getFromLocalStorage(constants.ACTIVE_TAB_KEY);

  const handleDeleteClick = () => {
    if (
      activeTab === constants.ATTACHED ||
      activeTab === constants.VERIFICATION ||
      activeTab === constants.DYBL
    ) {
      onDeleteMap();
    } else onDelete();
    onClose();
  };

  return (
    <ModalWindow onClose={onClose}>
      <Styled.DeleteProductModalContainer>
        <div className="products-title">{t('EditPage.DELETE_MODAL.TITLE')}</div>
        {activeTab === constants.ATTACHED ||
        activeTab === constants.VERIFICATION ||
        activeTab === constants.DYBL ? (
          <div className="products-sub-title">
            {t('EditPage.DELETE_MODAL.SUB_TITLE_MAP')}
          </div>
        ) : (
          <div className="products-sub-title">
            {t('EditPage.DELETE_MODAL.SUB_TITLE')}
          </div>
        )}
        <input
          placeholder="Укажите причину"
          onChange={(e) => setReason && setReason(e.target.value)}
          style={{ marginTop: '20px', padding: '4px 8px', width: '100%' }}
        />
        <Styled.ModalButtonsContainer>
          <div className="save-btn">
            <Button
              onClick={handleDeleteClick}
              text={t('EditPage.DELETE_MODAL.BLOCKED_BTN')}
              variant="contained"
            />
          </div>
          <div className="cancel-btn">
            <Button
              onClick={handleCancelClick}
              text={t('EditPage.DELETE_MODAL.CANCEL_BTN')}
              variant="outlined"
            />
          </div>
        </Styled.ModalButtonsContainer>
      </Styled.DeleteProductModalContainer>
    </ModalWindow>
  );
};

export default DeleteProductModal;
