import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../../components/Button';
import * as Styled from './ProductCard.styles';
import emptyImg from '../../../../../../global/media/empty-state.svg';
import * as actions from '../../../../actions';
import * as productActions from '../../../../../ProductsPage/actions';
import { ProductResponse } from '../../../../types';
import closeIcon from '../../../../../../global/media/modal-close-icon.svg';
import {
  getFromLocalStorage,
  setToLocalStorage,
} from '../../../../../../global/helpers';
import { selectors as productsSelectors } from '../../../../../ProductsPage/reducer';

const ProductCard = ({ data, onClick, value, code }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = getFromLocalStorage('accessToken');

  const [srcImg, setSrcImg] = useState(data.img);
  const networkForContent = useSelector(productsSelectors.network);
  const storageNetworkForContent = getFromLocalStorage('networkData');
  const mappingCodeForGlobalSearch =
    getFromLocalStorage('selectedProduct').mapping_code;
  const isGlobalSearch = getFromLocalStorage('isGlobalSearch');
  const networkCode = networkForContent?.code
    ? networkForContent?.code
    : storageNetworkForContent?.code;

  const handleClick = (data: ProductResponse) => {
    onClick();
    // @ts-ignore
    dispatch(actions.setSelectedProduct(data));
  };

  const handleDeleteMapping = () => {
    dispatch(
      actions.deleteMappings.request({
        token,
        wareId: data?.wareId,
        code: isGlobalSearch ? mappingCodeForGlobalSearch : networkCode,
        postEffect: () => {
          dispatch(
            actions.getProductsSearchForPharmacy.request({
              searchValue: value,
              code: isGlobalSearch ? mappingCodeForGlobalSearch : networkCode,
              postEffect: (dataSearchSkus: string[]) => {
                dispatch(
                  productActions.getProductsAttALL.request({
                    token,
                    code: isGlobalSearch
                      ? mappingCodeForGlobalSearch
                      : networkCode,
                    dataSearchSkus,
                  })
                );
              },
            })
          );
        },
      })
    );
  };

  return (
    <Styled.ProductContainer>
      <div className="product-img">
        <img src={srcImg} alt="img" onError={() => setSrcImg(emptyImg)} />
      </div>
      <div className="product-info">
        <div className="product-name">{data.name}</div>
        <div className="product-packing">{data.packing}</div>
        <div className="product-manufacturer">{data.manufacturer}</div>
        {data.wareId && (
          <div className="product-mapping-block">
            <div className="mapping">Привязано к WARE ID {data.wareId}</div>
            <div className="img" onClick={handleDeleteMapping}>
              <img src={closeIcon} alt="close" />
            </div>
          </div>
        )}
      </div>
      <Button
        onClick={() => handleClick(data)}
        text={t('EditPage.BTN_TEXT_CONNECT')}
        variant="outlined"
      />
    </Styled.ProductContainer>
  );
};

export default ProductCard;
