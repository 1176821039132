import React from 'react';
import { ClickAwayListener } from '@mui/material';
import * as Styled from './SelectOptions.styles';

interface OptionsParam {
  data: { title: string; code: boolean | string }[];
  onSetCode: (el: { title: string; code: boolean | string }) => void;
  onClose: () => void;
}

const SelectOptions = ({ data, onSetCode, onClose }: OptionsParam) => {
  const handleClickOption = (code: {
    title: string;
    code: boolean | string;
  }) => {
    onSetCode(code);
    onClose();
  };

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Styled.SelectBlock>
        {data.map((el) => (
          <div onClick={() => handleClickOption(el)}>{el.title}</div>
        ))}
      </Styled.SelectBlock>
    </ClickAwayListener>
  );
};

export default SelectOptions;
