import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { AdminService } from './service';
import { NetworkResponse, SourceResponse, TagsFieldResponse } from './types';
import { mappedDataForSources } from './helpers';
import * as actionsNotification from '../InitComponent/actions';
import {
  NOTIFICATION_CHANGES_NOT_SAVED,
  NOTIFICATION_CHANGES_SAVED,
} from '../../global/constants';

interface NetworksResponse {
  status: string;
  result: NetworkResponse[] | null;
}

interface SourcesResponse {
  status: string;
  result: SourceResponse[] | null;
}

interface ActivateSource {
  type: string;
  payload: { sourceCode: string; postEffect: any };
}

interface PharmacyActivateSync {
  type: string;
  payload: { sourceCode: string; postEffect: any };
}

interface PharmacyDeactivateSync {
  type: string;
  payload: { sourceCode: string; postEffect: any };
}

interface DeletePharmacy {
  type: string;
  payload: { sourceCode: string; postEffect: any };
}
interface DeactivateSource {
  type: string;
  payload: { sourceCode: string; message?: string; postEffect: any };
}

interface TagsResponse {
  status: string;
  result: TagsFieldResponse[] | null;
}

export function* getSourcesSaga() {
  try {
    const response: SourcesResponse = yield call(AdminService.getAllSources);
    const networksResponse: NetworksResponse = yield call(
      AdminService.getAllNetworks
    );

    const mappedData = mappedDataForSources(
      response.result,
      networksResponse.result
    );

    yield put(actions.getSources.success(mappedData));
  } catch (error) {
    yield put(actions.getSources.error(error));
  }
}

export function* activateSourceSaga({ payload }: ActivateSource) {
  try {
    // @ts-ignore
    const response = yield call(
      AdminService.activateSource,
      payload.sourceCode
    );

    if (payload.postEffect) {
      payload.postEffect();
    }
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_SAVED,
        canBeClosed: true,
        type: 'success',
      })
    );
    yield put(actions.activateSource.success(response.result));
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: false,
        type: 'error',
      })
    );
    yield put(actions.activateSource.error(error));
  }
}

export function* PharmacyActivateSyncSaga({ payload }: PharmacyActivateSync) {
  try {
    // @ts-ignore
    const response = yield call(
      AdminService.pharmacyActivateSync,
      payload.sourceCode
    );

    if (payload.postEffect) {
      payload.postEffect();
    }
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_SAVED,
        canBeClosed: true,
        type: 'success',
      })
    );
    yield put(actions.pharmacyActivateSync.success(response.result));
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: false,
        type: 'error',
      })
    );
    yield put(actions.pharmacyActivateSync.error(error));
  }
}

export function* PharmacyDeactivateSyncSaga({
  payload,
}: PharmacyDeactivateSync) {
  try {
    // @ts-ignore
    const response = yield call(
      AdminService.pharmacyDeactivateSync,
      payload.sourceCode
    );

    if (payload.postEffect) {
      payload.postEffect();
    }
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_SAVED,
        canBeClosed: true,
        type: 'success',
      })
    );
    yield put(actions.pharmacyDeactivateSync.success(response.result));
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: false,
        type: 'error',
      })
    );
    yield put(actions.pharmacyDeactivateSync.error(error));
  }
}

export function* deletePharmacySaga({ payload }: DeletePharmacy) {
  try {
    // @ts-ignore
    const response = yield call(
      AdminService.deletePharmacy,
      payload.sourceCode
    );

    if (payload.postEffect) {
      payload.postEffect();
    }
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_SAVED,
        canBeClosed: true,
        type: 'success',
      })
    );
    yield put(actions.deletePharmacy.success(response.result));
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: false,
        type: 'error',
      })
    );
    yield put(actions.deletePharmacy.error(error));
  }
}
export function* deactivateSourceSaga({ payload }: DeactivateSource) {
  try {
    // @ts-ignore
    const response = yield call(
      AdminService.deactivateSource,
      payload.sourceCode,
      payload.message
    );

    if (payload.postEffect) {
      payload.postEffect();
    }
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_SAVED,
        canBeClosed: true,
        type: 'success',
      })
    );
    yield put(actions.deactivateSource.success(response.result));
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: false,
        type: 'error',
      })
    );
    yield put(actions.deactivateSource.error(error));
  }
}

export function* setInvisibleSourceSaga({ payload }: DeactivateSource) {
  try {
    // @ts-ignore
    const response = yield call(
      AdminService.setInvisibleSource,
      payload.sourceCode
    );

    if (payload.postEffect) {
      payload.postEffect();
    }
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_SAVED,
        canBeClosed: true,
        type: 'success',
      })
    );
    yield put(actions.setInvisibleSource.success(response.result));
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: false,
        type: 'error',
      })
    );
    yield put(actions.setInvisibleSource.error(error));
  }
}

export function* setVisibleSourceSaga({ payload }: DeactivateSource) {
  try {
    // @ts-ignore
    const response = yield call(
      AdminService.setVisibleSource,
      payload.sourceCode
    );

    if (payload.postEffect) {
      payload.postEffect();
    }
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_SAVED,
        canBeClosed: true,
        type: 'success',
      })
    );
    yield put(actions.setVisibleSource.success(response.result));
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: false,
        type: 'error',
      })
    );
    yield put(actions.setVisibleSource.error(error));
  }
}

export function* sourcesPageWatcherSaga() {
  yield all([
    takeLatest(actions.getSources.REQUEST, getSourcesSaga),
    takeLatest(actions.deletePharmacy.REQUEST, deletePharmacySaga),
    takeLatest(actions.activateSource.REQUEST, activateSourceSaga),
    takeLatest(actions.deactivateSource.REQUEST, deactivateSourceSaga),
    takeLatest(actions.setInvisibleSource.REQUEST, setInvisibleSourceSaga),
    takeLatest(actions.setVisibleSource.REQUEST, setVisibleSourceSaga),
    takeLatest(
      actions.pharmacyDeactivateSync.REQUEST,
      PharmacyDeactivateSyncSaga
    ),
    takeLatest(actions.pharmacyActivateSync.REQUEST, PharmacyActivateSyncSaga),
  ]);
}
