import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as promoCodesActions from '../PromoCodePage/actions';
import { selectors } from '../PromoCodePage/reducer';
import * as Styled from './PharmacistPromoCodePage.styles';
import PharmacistPromocodesTable from './components/PharmacistPromocodesTable/PharmacistPromocodesTable';

const PharmacistPromoCodePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pharmacistIndividualExistingPromoCode = useSelector(
    selectors.pharmacistIndividualExistingPromoCode
  );
  const bonusOrdersDetailsHistory = useSelector(
    selectors.bonusOrdersDetailsHistory
  );

  const bonusOrdersDetails = useSelector(selectors.bonusOrdersDetails);

  useEffect(() => {
    dispatch(
      promoCodesActions.getBonusOrdersDetails.request({
        phoneNumber: null,
      })
    );
    dispatch(
      promoCodesActions.getPharmacistIndividualExistingPromoCode.request()
    );
    dispatch(
      promoCodesActions.getBonusOrdersDetailsHistory.request({
        phoneNumber: null,
      })
    );
    dispatch(
      promoCodesActions.getPharmacistBonusesBalance.request({
        phoneNumber: null,
      })
    );
  }, []);

  return (
    <Styled.PharmacistPromoCodePageContainer>
      <div className="text-warning">
        <div> Данным промокодом вы можете поделиться с клиентами.</div>
        <div>
          Им могут воспользоваться{' '}
          <span className="text-warning-accent">
            Клиенты, которые ни разу еще не пользовались
          </span>{' '}
          нашим сервисом.
        </div>
        <div>
          За завершенный заказ Вашего клиента, Вам будут начислятся бонусы.
        </div>
        <div>
          При{' '}
          <span className="text-warning-accent">заблокированном промокоде</span>
          , бонусы за него не поступят.
        </div>
        <div>
          Промокод{' '}
          <span className="text-warning-accent"> продаже НЕ ПОДЛЕЖИТ</span>, и
          при выявлении продажи будут приняты соответствующие меры.
        </div>
        <div>Вывод доступен с 10000 тг.</div>
      </div>
      <h2 className="title">{t('PHARMACIST_PROMO_CODE_PAGE.TITLE')}</h2>
      <p className="promocode">
        {t('PHARMACIST_PROMO_CODE_PAGE.PHARMACIST_PROMO_CODE_TEXT')}{' '}
        <span className="promocode-name ">
          {pharmacistIndividualExistingPromoCode?.promocode || 'не создан'}
        </span>
      </p>

      {pharmacistIndividualExistingPromoCode &&
        pharmacistIndividualExistingPromoCode.is_blocked && (
          <p className="bonus-title">
            Ваш промокод <span className="bonus-title-error">заблокирован</span>
            , при возникновении вопросов обратитесь к администрации.{' '}
          </p>
        )}

      {bonusOrdersDetailsHistory && bonusOrdersDetails && (
        <PharmacistPromocodesTable
          isAdmin={false}
          bonusOrdersDetails={bonusOrdersDetails}
          bonusOrdersDetailsHistory={bonusOrdersDetailsHistory}
        />
      )}
    </Styled.PharmacistPromoCodePageContainer>
  );
};

export default PharmacistPromoCodePage;
