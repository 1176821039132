import React, { useState, useEffect } from 'react';
import Pagination from '@mui/material/Pagination';
import { useTranslation } from 'react-i18next';
import {
  getUniqueID,
  getFromLocalStorage,
  formatTime,
} from '../../../../global/helpers';
import {
  FormatedBonusOrdersDetailsData,
  FormatedBonusOrdersDetailsHistoryData,
  FormatedBonusBlockedOrdersDetailsData,
} from '../../../PromoCodePage/types';
import * as Styled from './PharmacistPromocodesTable.styles';

interface PharmacistPromocodesTableProps {
  bonusOrdersDetails?: {
    pending: FormatedBonusOrdersDetailsData[];
    confirmed: FormatedBonusOrdersDetailsData[];
  } | null;
  bonusOrdersDetailsHistory: FormatedBonusOrdersDetailsHistoryData[];
  isAdmin: boolean;
  blockedArray?: FormatedBonusBlockedOrdersDetailsData[];
  blockedTotalSum?: string;
}

const PharmacistPromocodesTable = ({
  blockedArray,
  bonusOrdersDetails,
  bonusOrdersDetailsHistory,
  isAdmin,
  blockedTotalSum,
}: PharmacistPromocodesTableProps) => {
  const { t } = useTranslation();
  const role = getFromLocalStorage('role');
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(Number(event.target.value));
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const tableHeader = (
    <Styled.StyledTableHeaderContainer>
      <>
        {role === 'admin' && (
          <div className="header-row header-row-lg dark-green-row ">
            <div className="main-description">
              {t('PHARMACIST_PROMO_CODE_PAGE.TABLE.HEADER.BLOCKED')}{' '}
              <span> ({blockedTotalSum || 0}₸)</span>
            </div>
          </div>
        )}
        <div className="header-row header-row-lg dark-blue-row ">
          <div className="main-description">
            {t('PHARMACIST_PROMO_CODE_PAGE.TABLE.HEADER.PENDING')}{' '}
            <span> ({bonusOrdersDetails?.pending?.[0]?.totalSum || 0}₸)</span>
          </div>
          {role === 'pharmacist' && (
            <div className="description-secondary">
              {t('PHARMACIST_PROMO_CODE_PAGE.TABLE.HEADER.BONUS')}
            </div>
          )}
        </div>
        <div className="header-row header-row-lg orange-row">
          <div className="main-description">
            {t('PHARMACIST_PROMO_CODE_PAGE.TABLE.HEADER.APPROVED')}{' '}
            <span> ({bonusOrdersDetails?.confirmed?.[0]?.totalSum || 0}₸)</span>
          </div>
          {role === 'pharmacist' && (
            <div className="description-secondary">
              {t('PHARMACIST_PROMO_CODE_PAGE.TABLE.HEADER.BONUS_ACTIVE')}
            </div>
          )}
        </div>
      </>
      <div className="header-row header-row-lg blue-row">
        <div className="main-description">
          {t('PHARMACIST_PROMO_CODE_PAGE.TABLE.HEADER.HISTORY')}{' '}
          <span> ({bonusOrdersDetailsHistory?.[0]?.totalSum || 0}₸)</span>
        </div>
        {role === 'pharmacist' && (
          <div className="description-secondary">
            {t('PHARMACIST_PROMO_CODE_PAGE.TABLE.HEADER.OLD_BONUS')}
          </div>
        )}
      </div>
    </Styled.StyledTableHeaderContainer>
  );

  return (
    <Styled.StyledTableContainer>
      {tableHeader}
      <Styled.TableBodyContainer>
        {!isAdmin && (
          <>
            {role === 'admin' && (
              <div className="table-cell-lg table-cell">
                {blockedArray?.map((blocked) => (
                  <div key={getUniqueID()} className="description-bold">
                    <span>{formatTime(blocked.date)}</span>
                    <span style={{ margin: '10px' }}>{blocked.amount}₸</span>
                    {role === 'admin' && <span>№{blocked.order_id}</span>}
                  </div>
                ))}
              </div>
            )}
            <div className="table-cell-lg table-cell">
              {bonusOrdersDetails?.pending.map((orderData) => (
                <div key={getUniqueID()} className="description-bold">
                  <span>{orderData.date}</span>
                  <span style={{ margin: '10px' }}>{orderData.amount}₸</span>
                  {role === 'admin' && <span>№{orderData.orderId}</span>}
                </div>
              ))}
            </div>
            <div className="table-cell-lg table-cell">
              {bonusOrdersDetails?.confirmed?.map((orderData) => (
                <div key={getUniqueID()} className="description-bold">
                  <span>{orderData.date}</span>
                  <span style={{ margin: '10px' }}>{orderData.amount}₸</span>
                  {role === 'admin' && <span>№{orderData.orderId}</span>}
                </div>
              ))}
            </div>
          </>
        )}
        <div className="table-cell-lg table-cell">
          {bonusOrdersDetailsHistory?.map((orderData) => (
            <div key={getUniqueID()} className="description-bold">
              <span style={{ margin: '5px' }}>{orderData.amount}₸</span>
              <span style={{ margin: '5px' }}>
                {t('PHARMACIST_PROMO_CODE_PAGE.TABLE.BODY.PAID')}
              </span>
              <span>{orderData.date}</span>
            </div>
          ))}
        </div>
      </Styled.TableBodyContainer>
      <Styled.PaginationContainer>
        <input
          type="tel"
          max={count}
          value={page}
          onChange={handleChangeInput}
        />
        <Pagination count={count} onChange={handleChange} page={page} />
      </Styled.PaginationContainer>
    </Styled.StyledTableContainer>
  );
};

export default PharmacistPromocodesTable;
