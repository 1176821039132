type ReportKeys =
  | 'online'
  | 'in_place'
  | 'web_mobile'
  | 'web_desktop'
  | 'app_ios'
  | 'app_android'
  | 'operator';

export interface OrdersReportResponse {
  order_id: string;
  sum: number;
}

export interface SourcesReportResponse {
  total: number;
  source_name: string;
  source_city: string;
  source_code: string;
  orders: OrdersReportResponse[];
  refund_total?: number;
}

export interface KeyReport {
  total: number;
  sources: SourcesReportResponse[];
}

export interface ReportResponse {
  result: {
    [key in ReportKeys]: KeyReport;
  };
}

export interface ReportPageState {
  pdfReport: {
    data: null | any;
    loading: boolean;
    error: null | any;
  };
  ordersReport: {
    data: ReportResponse['result'] | null;
    error: null | string;
    loading: boolean;
  };

  ordersReportYest: {
    data: ReportResponse['result'] | null;
    error: null | string;
    loading: boolean;
  };
  refunds: {
    data: {
      payout_date: string;
      total_sum: number;
      network_code: string;
      reports: { order_id: string; sum: number }[];
    } | null;
    error: null | string;
    loading: boolean;
  };
  payments: {
    data:
      | {
          payout_date: Date;
          total_sum: number;
          reports: { order_id: string; sum: number }[];
        }[]
      | null;
    error: null | string;
    loading: boolean;
  };
}

export enum ReportTabOption {
  sales = 'onSales',
  refunds = 'onRefunds',
}
