import { ClickAwayListener } from '@mui/material';
import * as Styled from './SuggestionList.style';
import { ISuggestionOption } from '../../types';

interface SuggestionListProps {
  onClose: () => void;
  optionsList: ISuggestionOption[];
  setOption: (value: ISuggestionOption) => void;
}

const SuggestionList = ({
  onClose,
  optionsList,
  setOption,
}: SuggestionListProps) => {
  return (
    <ClickAwayListener onClickAway={onClose}>
      <Styled.SuggestionContainer>
        {optionsList.map((item) => (
          <Styled.SuggestionListOption
            onClick={() => {
              setOption(item);
              onClose();
            }}
            key={item.name}
          >
            {item.name}
          </Styled.SuggestionListOption>
        ))}
      </Styled.SuggestionContainer>
    </ClickAwayListener>
  );
};

export default SuggestionList;
