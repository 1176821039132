import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import * as Styled from './InfoReportByDay.styles';
import { getFromLocalStorage } from '../../global/helpers';
import { UserRole } from '../../containers/OrdersPage/types';
import {
  ReportTabOption,
  SourcesReportResponse,
} from '../../containers/ReportPage/types';
import bottomArrow from '../../global/media/bottom-arrow.svg';
import { getCities } from '../../global/cities';
import { amountOfOrders } from '../../containers/OrdersPage/helpers';

interface InfoParam {
  day: string;
  data: any;
}

const InfoReportByDay = ({ data, day }: InfoParam) => {
  const { t } = useTranslation();
  const userRole = getFromLocalStorage('role');
  const [activeIndexOnline, setActiveIndexOnline] = useState<number | null>(
    null
  );
  const [activeIndexInterpay, setActiveIndexInterpay] = useState<number | null>(
    null
  );
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [currentTab, setCurrentTab] = useState(ReportTabOption.sales);

  const {
    closedAmountOfOrdersInPlace: amountsInPlace,
    closedAmountOfOrdersOnline: amountsOnline,
    closedAmountOfOrdersInterpay: amountInterpay,
    closedOrdersInPlace: ordersInPlace,
    closedOrdersOnline: ordersOnline,
    closedOrdersInterpay: ordersInterpay,
    webMobile,
    webDesktop,
    appAndroid,
    appIOS,
    operator,
  } = amountOfOrders(data, currentTab === ReportTabOption.refunds && true);

  const getCountByPaymentPlace = (data: SourcesReportResponse[]) => {
    return data?.reduce((accum, source) => {
      return accum + source.orders.length;
    }, 0);
  };

  const getPriceByCity = (data: SourcesReportResponse[], city: string) => {
    const refunds = currentTab === ReportTabOption.refunds;
    return data
      ?.filter((el) => el.source_city === city)
      ?.reduce((accum, source) => {
        return accum + (refunds ? source.refund_total || 0 : source.total);
      }, 0);
  };

  const getPriceByCityAndPayment = (data: SourcesReportResponse[]) => {
    const refunds = currentTab === ReportTabOption.refunds;
    return data?.reduce((accum, source) => {
      return accum + (refunds ? source.refund_total || 0 : source.total);
    }, 0);
  };

  const getCityOrdersByCity = (data: SourcesReportResponse[], city: string) => {
    return data?.filter((el: any) => el.source_city === city);
  };

  const userSources = [
    {
      title: t('OrdersPage.WEB_DESKTOP'),
      count: getCountByPaymentPlace(webDesktop || []),
      visible: Boolean(getCountByPaymentPlace(webDesktop || [])),
    },
    {
      title: t('OrdersPage.WEB_MOBILE'),
      count: getCountByPaymentPlace(webMobile || []),
      visible: Boolean(getCountByPaymentPlace(webMobile || [])),
    },

    {
      title: t('OrdersPage.APP_ANDROID'),
      count: getCountByPaymentPlace(appAndroid || []),
      visible: Boolean(getCountByPaymentPlace(appAndroid || [])),
    },
    {
      title: t('OrdersPage.APP_IOS'),
      count: getCountByPaymentPlace(appIOS || []),
      visible: Boolean(getCountByPaymentPlace(appIOS || [])),
    },
    {
      title: t('OrdersPage.OPERATOR'),
      count: getCountByPaymentPlace(operator || []),
      visible: Boolean(getCountByPaymentPlace(operator || [])),
    },
  ];

  const cityPrice = getCities().map((f: any) => ({
    title: f.ruName,
    price: `${getPriceByCity(ordersOnline.concat(ordersInPlace), f.ruName)} ₸`,
    visible: Boolean(
      getPriceByCity(ordersOnline.concat(ordersInPlace), f.ruName)
    ),
  }));

  const cityOrders = getCities().map((f: any) => ({
    title: f.ruName,
    cityOrdersOnline: getCityOrdersByCity(ordersOnline, f.ruName),
    cityOrdersInPlace: getCityOrdersByCity(ordersInPlace, f.ruName),
    cityOrdersInterpay: getCityOrdersByCity(ordersInterpay, f.ruName),
  }));

  const handleAccordionClickOnline = (index: number) => {
    if (activeIndexOnline === index) {
      setActiveIndexOnline(null);
    } else {
      setActiveIndexOnline(index);
    }
  };
  const handleAccordionClickInterpay = (index: number) => {
    if (activeIndexInterpay === index) {
      setActiveIndexInterpay(null);
    } else {
      setActiveIndexInterpay(index);
    }
  };
  const handleAccordionClick = (index: number) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  const sourceBlockClassname =
    userSources.find((el) => el.visible) && 'source-block';

  return (
    <Styled.Container>
      <Styled.Header
        onClick={() => (isMobile ? setIsCollapsed(!isCollapsed) : null)}
      >
        <Styled.TitleInfo>{day}</Styled.TitleInfo>
        <Styled.ReportTabsContainer>
          <div
            className={`tab-item ${
              currentTab === ReportTabOption.sales && 'type-active'
            }`}
            onClick={() => setCurrentTab(ReportTabOption.sales)}
          >
            {t('ReportPage.TABS.SALES')}
          </div>
          <div
            className={`tab-item ${
              currentTab === ReportTabOption.refunds && 'type-active'
            }`}
            onClick={() => setCurrentTab(ReportTabOption.refunds)}
          >
            {t('ReportPage.TABS.REFUNDS')}
          </div>
        </Styled.ReportTabsContainer>
        {isMobile && (
          <Styled.ImgBlock>
            <img
              className={`headerIcon ${isCollapsed && 'headerIconCollapsed'}`}
              width={16}
              src={bottomArrow}
              alt="bottom arrow"
            />
          </Styled.ImgBlock>
        )}
      </Styled.Header>

      {(!isMobile || (isMobile && !isCollapsed)) && (
        <>
          {(userRole === UserRole.ADMIN ||
            userRole === UserRole.OPERATOR ||
            userRole === UserRole.HEAD_OPERATOR) && (
            <div className={sourceBlockClassname}>
              {userSources.map(
                (el) =>
                  el.visible && (
                    <div key={el.title}>
                      <span className="source-title">{el.title}:</span>
                      <span className="source-count">{el.count}</span>
                    </div>
                  )
              )}
            </div>
          )}

          {(userRole === UserRole.ADMIN ||
            userRole === UserRole.OPERATOR ||
            userRole === UserRole.HEAD_OPERATOR) && (
            <div className="city-price-day">
              {cityPrice.map(
                (el: any) =>
                  el.visible && (
                    <div key={el.title}>
                      <span className="city-title">{el.title} </span>
                      <span className="city-price">{el.price}</span>
                    </div>
                  )
              )}
            </div>
          )}

          <div className="amount-block">
            <div className="title"> {t('OrdersPage.PAY_KASPI')}</div>
            <div className="closed-amount">{amountsOnline}</div>
          </div>

          <div className="quantity-block">
            <div className="title">
              {t(
                `OrdersPage.${
                  currentTab === ReportTabOption.refunds
                    ? 'ALL_REFUNDS_KASPI'
                    : 'ALL_QUANTITY_KASPI'
                }`
              )}
            </div>
            <div className="closed-amount">
              {getCountByPaymentPlace(ordersOnline)}
            </div>
          </div>

          <div className="amount-container">
            <div className="flex-container">
              <div className="closed-order">
                {cityOrders.map((el: any, index: number) => (
                  <div key={el.title + el.cityOrdersOnline}>
                    <div
                      className="city-price-block"
                      onClick={() => handleAccordionClickOnline(index)}
                    >
                      <div>
                        <span className="city">{el.title}</span>
                        <span className="city-price">
                          {getPriceByCityAndPayment(el.cityOrdersOnline)} ₸
                        </span>
                      </div>
                      <div className="accordion-button">
                        {activeIndexOnline === index
                          ? 'Скрыть детали'
                          : 'Открыть'}
                      </div>
                    </div>
                    {activeIndexOnline === index &&
                      el.cityOrdersOnline.map((el: any) => (
                        <div className="network-block" key={el.source_code}>
                          <div className="network-code">{el.source_name}</div>
                          <div className="orders">
                            {el.orders.map((e: any) => (
                              <div className="order" key={e.order_id}>
                                №{e.order_id}{' '}
                                <span>
                                  (
                                  {currentTab === ReportTabOption.refunds
                                    ? e.refund_sum
                                    : e.sum}{' '}
                                  ₸)
                                </span>
                              </div>
                            ))}
                          </div>
                          <div className="amount-orders">
                            {el.orders.length > 0 && (
                              <>
                                {t('OrdersPage.AMOUNT_ORDERS')}: <br />
                                <span>
                                  {currentTab === ReportTabOption.refunds
                                    ? el.refund_total
                                    : el.total}{' '}
                                  ₸
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                ))}
              </div>
            </div>
            <div className="flex-container">
              <div className="amount-block">
                <div className="title">{t('OrdersPage.INTERPAY')}</div>
                <div className="closed-amount">{amountInterpay}</div>
              </div>
              <div className="quantity-block quantity-block-place">
                <div className="title">
                  {t(
                    `OrdersPage.${
                      currentTab === ReportTabOption.refunds
                        ? 'ALL_REFUNDS_INTERPAY'
                        : 'ALL_QUANTITY_INTERPAY'
                    }`
                  )}
                </div>
                <div className="closed-amount">
                  {getCountByPaymentPlace(ordersInterpay)}
                </div>
              </div>
              <div className="closed-order">
                {cityOrders.map((el: any, index: number) => (
                  <div key={el.title}>
                    <div
                      className="city-price-block"
                      onClick={() => handleAccordionClickInterpay(index)}
                    >
                      <div>
                        <span className="city">{el.title}</span>
                        <span className="city-price">
                          {getPriceByCityAndPayment(el.cityOrdersInterpay)} ₸
                        </span>
                      </div>
                      <div className="accordion-button">
                        {activeIndex === index ? 'Скрыть детали' : 'Открыть'}
                      </div>
                    </div>
                    {activeIndexInterpay === index &&
                      el.cityOrdersInterpay?.map((el: any, index: number) => (
                        <div className="network-block" key={el.source_code}>
                          <div className="network-code">{el.source_name}</div>
                          {/* @ts-ignore */}
                          <div className="orders">
                            {el.orders.map((e: any) => (
                              <div className="order" key={e.order_id}>
                                №{e.order_id}{' '}
                                <span>
                                  (
                                  {currentTab === ReportTabOption.refunds
                                    ? e.refund_sum
                                    : e.sum}{' '}
                                  ₸)
                                </span>
                              </div>
                            ))}
                          </div>
                          {/* @ts-ignore */}
                          <div className="amount-orders">
                            {index === 0 && (
                              <>
                                {t('OrdersPage.AMOUNT_ORDERS')}: <br />
                              </>
                            )}
                            <span>
                              {currentTab === ReportTabOption.refunds
                                ? el.refund_total
                                : el.total}{' '}
                              ₸
                            </span>
                          </div>
                        </div>
                      ))}
                  </div>
                ))}
              </div>
            </div>
            {currentTab === ReportTabOption.sales && (
              <div className="flex-container">
                <div className="amount-block">
                  <div className="title">{t('OrdersPage.PAY_IN_PLACE')}</div>
                  <div className="closed-amount">{amountsInPlace}</div>
                </div>
                <div className="quantity-block quantity-block-place">
                  <div className="title">
                    {t('OrdersPage.ALL_QUANTITY_PLACE')}
                  </div>
                  <div className="closed-amount">
                    {getCountByPaymentPlace(ordersInPlace)}
                  </div>
                </div>
                <div className="closed-order">
                  {cityOrders.map((el: any, index: number) => (
                    <div key={el.title}>
                      <div
                        className="city-price-block"
                        onClick={() => handleAccordionClick(index)}
                      >
                        <div>
                          <span className="city">{el.title}</span>
                          <span className="city-price">
                            {getPriceByCityAndPayment(el.cityOrdersInPlace)} ₸
                          </span>
                        </div>
                        <div className="accordion-button">
                          {activeIndex === index ? 'Скрыть детали' : 'Открыть'}
                        </div>
                      </div>
                      {activeIndex === index &&
                        el.cityOrdersInPlace?.map((el: any, index: number) => (
                          <div className="network-block" key={el.source_code}>
                            <div className="network-code">{el.source_name}</div>
                            {/* @ts-ignore */}
                            <div className="orders">
                              {el.orders.map((e: any) => (
                                <div className="order" key={e.order_id}>
                                  №{e.order_id} <span>({e.sum} ₸)</span>
                                </div>
                              ))}
                            </div>
                            {/* @ts-ignore */}
                            <div className="amount-orders">
                              {index === 0 && (
                                <>
                                  {t('OrdersPage.AMOUNT_ORDERS')}: <br />
                                </>
                              )}
                              <span>{el.total} ₸</span>
                            </div>
                          </div>
                        ))}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </Styled.Container>
  );
};

export default InfoReportByDay;
