import * as Yup from 'yup';
import { t } from 'i18next';
import { formatTime, getTrimmedFields } from '../../global/helpers';
import {
  WebStockProductData,
  WebStockProductFormatedData,
  filterInStockOptionsValues,
  filterNameOptionsValues,
  WebStockProductsTabOption,
} from './types';
import { sort as sortByAlphabet } from '../OrdersPage/components/OrdersTable/components/FilterBySource/helpers';
import { ProductResponseItemCache } from '../OrdersPage/types';

export const formatWebStockProductData = (
  product: WebStockProductFormatedData
) => {
  return {
    name: product.name,
    price: product.price,
    quantity: product.quantity,
    sku: product.sku,
    inStock: product.in_stock,
    manufacturer: product.manufacturer || 'не указан',
    updatedAt: (product.updated_at && formatTime(product.updated_at)) || '',
    updatedBy: product.updated_by || '',
    link: product.link || '',
  };
};

export const formatWebStockProductDataForRequest = (
  product: WebStockProductFormatedData | WebStockProductData,
  isAdding?: boolean
) => {
  return {
    price: Number(product.price),
    quantity: Number(product.quantity) || 0,
    sku: getTrimmedFields(product.sku),
  };
};

const webStockProductSchema = {
  price: Yup.string()
    .required('Поле не может быть пустым')
    .matches(/^[^0].*/, 'Цена не должна начинаться с "0"'),
};

const webStockProductSchemaCommon = {
  price: Yup.string()
    .required('Поле не может быть пустым')
    .matches(/^[^0].*/, 'Цена не должна начинаться с "0"'),
  quantity: Yup.string().required('Поле не может быть пустым'),
};

export const webStockProductsSchema = Yup.array().of(
  Yup.object().shape(webStockProductSchema)
);
export const webStockProductsSchemaCommon = Yup.array().of(
  Yup.object().shape(webStockProductSchemaCommon)
);

export const formatWebStockProductsData = (
  productsList: WebStockProductFormatedData[]
) => {
  return productsList.map((product) => formatWebStockProductData(product));
};

export const filterNameOptions = [
  {
    value: filterNameOptionsValues.sortByAlphabet,
    name: 'По алфавиту',
  },
  {
    value: filterNameOptionsValues.withoutSorting,
    name: 'Без сортировки',
  },
];

export const filterInStockOptions = [
  {
    value: filterInStockOptionsValues.inStock,
    name: 'Да',
  },
  {
    value: filterInStockOptionsValues.outOfStock,
    name: 'Нет',
  },
  {
    value: filterInStockOptionsValues.all,
    name: 'Все',
  },
];

export const getNewWebStockProducts = (
  productsList: WebStockProductFormatedData[]
) => {
  return (
    productsList.filter(
      (product: WebStockProductFormatedData) =>
        product.price === 0 && !product.inStock
    ) || null
  );
};

export const getArchivalWebStockProducts = (
  productsList: WebStockProductFormatedData[]
) => {
  return (
    productsList?.filter(
      (product: WebStockProductFormatedData) =>
        product.price > 0 && !product.inStock
    ) || null
  );
};

export const getOnSaleWebStockProducts = (
  productsList: WebStockProductFormatedData[]
) => {
  return (
    productsList
      ?.filter(
        (product: WebStockProductFormatedData) =>
          product.price > 0 && product.inStock
      )
      ?.sort((a, b) => {
        if (a.sku === '117000') {
          return -1;
        }
        if (b.sku === '117000') {
          return 1;
        }
        return 0;
      }) || null
  );
};

export const getProductsBySearchValue = (
  productsList: WebStockProductFormatedData[],
  searchProductValue: string
) => {
  return (
    productsList.filter((product: WebStockProductFormatedData) =>
      product.name
        .toLowerCase()
        .includes(searchProductValue.toLowerCase().trim())
    ) || null
  );
};

export const getSortedProductsOnSearch = (
  currentInStockFilterOption: string,
  currentFilterNameOption: string,
  webStockProductsData: WebStockProductFormatedData[]
) => {
  let updatedSortedProductsList: WebStockProductData[] = [];

  if (
    currentInStockFilterOption === filterInStockOptionsValues.inStock &&
    webStockProductsData
  ) {
    updatedSortedProductsList = webStockProductsData?.filter(
      (product) => product.inStock
    );
  }
  if (
    currentInStockFilterOption === filterInStockOptionsValues.outOfStock &&
    webStockProductsData
  ) {
    updatedSortedProductsList = webStockProductsData?.filter(
      (product) => !product.inStock
    );
  }

  if (
    currentInStockFilterOption === filterInStockOptionsValues.all &&
    webStockProductsData
  ) {
    updatedSortedProductsList = [...webStockProductsData];
  }

  if (
    currentFilterNameOption === filterNameOptionsValues.sortByAlphabet &&
    updatedSortedProductsList
  ) {
    updatedSortedProductsList = updatedSortedProductsList?.sort(sortByAlphabet);
  }

  return updatedSortedProductsList;
};

export const getTabNameByCurrentTab = (currentTab: string) => {
  if (currentTab === WebStockProductsTabOption.newProducts) {
    return t('WebStockPage.TABS.NEW_PRODUCTS').toLowerCase();
  }
};

export const formatMagentaProductForWebStock = (
  magentaProduct: ProductResponseItemCache
) => {
  const getCustomAttribute = (attributes: any, code: string) =>
    attributes?.find((el: any) => el.attribute_code === code)?.values;

  return {
    name: magentaProduct.name,
    manufacturer:
      getCustomAttribute(magentaProduct.attributes, 'manufacturer_id') ||
      'не указан',
    sku: magentaProduct.sku,
    updatedAt: '',
    updatedBy: '',
    inStock: false,
    link: getCustomAttribute(magentaProduct.attributes, 'url_key') || '',
    price: 0,
  };
};

export const formatMagentaResponse = (
  magentaProductsList: {
    result: ProductResponseItemCache[];
  },
  webStockProductsData: WebStockProductFormatedData[]
) => {
  return getNewWebStockProducts(
    magentaProductsList.result.map((magentaItem) => {
      const webStockExistingProduct = webStockProductsData.find(
        (item: WebStockProductFormatedData) => item.sku === magentaItem.sku
      );
      if (webStockExistingProduct) {
        return webStockExistingProduct;
      }
      return formatMagentaProductForWebStock(magentaItem);
    }) as WebStockProductFormatedData[]
  );
};
