import { asyncActionsCreator } from '../../global/redux';
import * as constants from './constants';

export const getAnalogsListBySku = asyncActionsCreator(
  constants.GET_ANALOGS_LIST
);

export const deleteReview = asyncActionsCreator(constants.DELETE_REVIEW);

export const submitReview = asyncActionsCreator(constants.SUBMIT_REVIEW);
