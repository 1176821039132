import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as Styled from './Product.styles';
import { getDefaultValue } from '../../../ProductsPage/helpers';
import { ProductResponse } from '../../../EditPage/types';

interface ProductProps {
  data: ProductResponse;
  // eslint-disable-next-line
  pharmacy?: boolean;
  // eslint-disable-next-line
  service?: boolean;
}

const Product = ({ data, service, pharmacy }: ProductProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const TableHeader = (
    <thead>
      <Styled.ProductTableHeaderRow>
        <th className="table__header-item item-bigger ">
          {t('ProductsPage.HeaderTitles.PRODUCT')}
        </th>
        <th className="table__header-item">
          {t('ProductsPage.HeaderTitles.VENDOR_CODE')}
        </th>
        <th className="table__header-item">
          {t('ProductsPage.HeaderTitles.MANUFACTURER')}
        </th>
      </Styled.ProductTableHeaderRow>
    </thead>
  );

  return (
    <Styled.ProductContainer>
      <div className="product__title">
        {service && t('EditPage.ProductTable.SERVICE_TITLE')}
        {pharmacy && t('EditPage.ProductTable.PHARMACY_TITLE')}
      </div>
      <Styled.ProductTable>
        {TableHeader}
        <Styled.ProductTableRowContainer>
          {data ? (
            <tr key={data?.ware_id}>
              <td className="product-item item-column">{data?.name}</td>
              <td className="product-item">
                {pharmacy ? data?.ware_id : data?.sku}
              </td>
              <td className="product-item">{getDefaultValue(data)}</td>
            </tr>
          ) : (
            <tr className="product-empty-service">
              {/* eslint-disable-next-line */}
              <td onClick={() => history.push('/products')}>
                {' '}
                {t('EditPage.ProductTable.EMPTY_STATE')}
              </td>
            </tr>
          )}
        </Styled.ProductTableRowContainer>
      </Styled.ProductTable>
    </Styled.ProductContainer>
  );
};

export default Product;
