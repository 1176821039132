import React, { useEffect, useState } from 'react';
import Pagination from '@mui/material/Pagination';
import * as Styled from './Pagination.style';
import {
  getFromLocalStorage,
  setToLocalStorage,
} from '../../../../global/helpers';
import * as constants from '../../constants';
import { paginationPage } from '../../helpers';
import { CountPropsNumb } from '../../types';

interface PaginationProps {
  countPages: CountPropsNumb;
  unAttachedNumberPage: string;
  attachedNumberPage: string;
  blockedNumberPage: string;
  verificationNumberPage: string;
  dyblNumberPage: string;
  automappingNumberPage: string;
  setUnAttPage: (value: string) => void;
  setAttPage: (value: string) => void;
  setBlockPage: (value: string) => void;
  setVerPage: (value: string) => void;
  setDyblPage: (value: string) => void;
  setAutomappingPage: (value: string) => void;
}

const CustomPagination = ({
  setVerPage,
  setAttPage,
  setBlockPage,
  setUnAttPage,
  setDyblPage,
  dyblNumberPage,
  unAttachedNumberPage,
  attachedNumberPage,
  verificationNumberPage,
  blockedNumberPage,
  setAutomappingPage,
  automappingNumberPage,
  countPages,
}: PaginationProps) => {
  const activeTab = getFromLocalStorage(constants.ACTIVE_TAB_KEY);

  const {
    countUnAttached,
    countAttached,
    countBlocked,
    countOnVerification,
    countDybl,
    countAutomapping,
  } = countPages;

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);

  const changeNumberPage = (value: any) => {
    if (activeTab === constants.UNATTACHED) {
      setUnAttPage(String(value * 50));
    } else if (activeTab === constants.ATTACHED) {
      setAttPage(String(value * 50));
    } else if (activeTab === constants.BLOCKED) {
      setBlockPage(String(value * 50));
    } else if (activeTab === constants.VERIFICATION) {
      setVerPage(String(value * 50));
    } else if (activeTab === constants.DYBL) {
      setDyblPage(String(value * 50));
    } else if (activeTab === constants.AUTOMAPPING) {
      setAutomappingPage(String(value * 50));
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setToLocalStorage('selectedProduct', null);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    changeNumberPage(value);
    setToLocalStorage('selectedProduct', null);
  };

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(Number(event.target.value));
    changeNumberPage(event.target.value);
  };

  useEffect(() => {
    if (activeTab === constants.UNATTACHED) {
      setPage(Number(unAttachedNumberPage) / 50);
      setCount(countUnAttached);
    } else if (activeTab === constants.ATTACHED) {
      setPage(Number(attachedNumberPage) / 50);
      setCount(countAttached);
    } else if (activeTab === constants.BLOCKED) {
      setPage(Number(blockedNumberPage) / 50);
      setCount(countBlocked);
    } else if (activeTab === constants.VERIFICATION) {
      setPage(Number(verificationNumberPage) / 50);
      setCount(countOnVerification);
    } else if (activeTab === constants.DYBL) {
      setPage(Number(dyblNumberPage) / 50);
      setCount(countDybl);
    } else if (activeTab === constants.AUTOMAPPING) {
      setPage(Number(automappingNumberPage) / 50);
      setCount(countAutomapping);
    }

    setToLocalStorage('pagination', {
      unAttachedPage: paginationPage(Number(unAttachedNumberPage) / 50),
      attachedPage: paginationPage(Number(attachedNumberPage) / 50),
      blockedPage: paginationPage(Number(blockedNumberPage) / 50),
      verificationPage: paginationPage(Number(verificationNumberPage) / 50),
      dyblPage: paginationPage(Number(dyblNumberPage) / 50),
      automappingPage: paginationPage(Number(automappingNumberPage) / 50),
    });
  }, [
    unAttachedNumberPage,
    attachedNumberPage,
    blockedNumberPage,
    verificationNumberPage,
    dyblNumberPage,
    automappingNumberPage,
    countBlocked,
    countAttached,
    countOnVerification,
    countUnAttached,
    countDybl,
    activeTab,
    countAutomapping,
  ]);

  return (
    <Styled.PaginationContainer>
      <input type="tel" max={count} value={page} onChange={handleChangeInput} />
      <Pagination count={count} onChange={handleChange} page={page} />
    </Styled.PaginationContainer>
  );
};

export default CustomPagination;
