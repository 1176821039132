import styled from 'styled-components';
import { baseTheme } from '../../global/styles/theme';

export const StyledCashbackPageContainer = styled('div')`
  max-width: 1300px;
  margin: auto;
  padding: 34px 0;
`;

export const SearchContainer = styled('div')`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 20px;
`;

export const ContainerInput = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 500px;
  height: 50px;
  border-radius: 20px;
  background: #f7f7f7;
  padding-left: 15px;
  padding-right: 15px;

  .search-input {
    background: transparent;
    outline: none;
    border: none;
    width: 450px;
  }
  .clear-input {
    cursor: pointer;
  }
`;

export const ContainerRadioBox = styled('div')`
  display: flex;
  gap: 10px;
  margin-top: 5px;
  align-items: center;
`;

export const ButtonSearch = styled('div')`
  cursor: pointer;
  background: #0a77c7;
  border-radius: 20px;
  padding: 10px 24px;
  color: #ffffff;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    transition: 200ms;
    background-color: #003e6e;
  }
`;

export const TableHeaderContainer = styled('div')`
  padding-top: 56px;
  display: flex;

  .header-row-lg {
    width: 40% !important;
  }

  .header-row-md {
    width: 30% !important;
  }

  .header-row-xsm {
    width: 10% !important;
  }

  .header-row {
    padding: 12px;
    border: 1px solid ${baseTheme.colors.stroking};
    color: ${baseTheme.colors.secondary};
    width: 25%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`;

export const TableBodyContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .product-wrapper {
    width: 100%;

    &--dragging {
      cursor: grab;

      &:active {
        background-color: ${baseTheme.colors.darkBlue};
      }
    }
  }

  .table-cell-lg {
    width: 40% !important;
  }

  .table-cell-md {
    width: 30% !important;
  }

  .table-cell-xsm {
    width: 10% !important;
  }

  .table-cell {
    padding: 12px;
    border: 1px solid ${baseTheme.colors.stroking};
    color: ${baseTheme.colors.secondary};
    width: 25%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    .user-name-title {
      color: #535d6a;
      font-size: 16px;
    }

    .disabled {
      pointer-events: none;
      background: ${baseTheme.colors.disabled} !important;
      border: 2px solid ${baseTheme.colors.disabled};
    }

    .assembly-btn {
      padding: 12px 24px;
      border-radius: 8px;
      background-color: ${baseTheme.colors.focus};
      cursor: pointer;
      margin-right: 24px;
      color: ${baseTheme.colors.white};
    }

    input {
      width: 100%;
      color: ${baseTheme.colors.secondary};
      font-size: 16px;
      -webkit-appearance: none;
      border: none;
      padding: 0;
      background-color: inherit;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: ${baseTheme.colors.tertiary};
      }
    }
  }

  .name-container {
    display: flex;
    flex-direction: column;

    .name-title {
      width: 100%;
      text-align: start;
    }

    img {
      cursor: pointer;
      margin-right: auto;

      &:active {
        scale: 1.3;
      }
    }
  }

  .manufacturer {
    color: #535d6a;
    font-size: 16px;
    margin-right: auto;

    &-name {
      font-weight: 650;
    }
  }
`;
