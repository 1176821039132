import { createReducer } from '@reduxjs/toolkit';
import * as actions from './actions';
import { AppState } from '../../global/types';
import { EditPageState } from './types';

const initialState: EditPageState = {
  productsSearchForPharmacy: {
    data: null,
    loading: false,
    error: null,
  },

  setProduct: {
    selectedProduct: null,
  },

  productBySku: {
    error: null,
    loading: false,
    data: null,
  },

  postDataForMappings: {
    error: null,
    loading: false,
    data: null,
  },

  blockedProduct: {
    error: null,
    loading: false,
    data: null,
  },

  unblockedProduct: {
    error: null,
    loading: false,
    data: null,
  },

  deleteMappings: {
    error: null,
    loading: false,
    data: null,
  },
};

const reducer = createReducer(initialState, {
  [actions.getProductsSearchForPharmacy.REQUEST]: (state) => {
    state.productsSearchForPharmacy.loading = true;
  },
  [actions.getProductsSearchForPharmacy.ERROR]: (state, action) => {
    state.productsSearchForPharmacy.error = action.payload;
    state.productsSearchForPharmacy.loading = false;
  },
  [actions.getProductsSearchForPharmacy.SUCCESS]: (state, action) => {
    state.productsSearchForPharmacy.data = action.payload;
    state.productsSearchForPharmacy.loading = false;
  },
  [actions.getProductBySku.REQUEST]: (state) => {
    state.productBySku.loading = true;
  },
  [actions.getProductBySku.ERROR]: (state, action) => {
    state.productBySku.error = action.payload;
    state.productBySku.loading = false;
  },
  [actions.getProductBySku.SUCCESS]: (state, action) => {
    state.productBySku.data = action.payload;
    state.productBySku.loading = false;
  },
  [actions.setSelectedProduct.type]: (state, action) => {
    state.setProduct.selectedProduct = action.payload;
  },
  [actions.clearSelectedProduct.type]: (state) => {
    state.setProduct.selectedProduct = null;
  },
  [actions.postDataForMappings.REQUEST]: (state) => {
    state.postDataForMappings.loading = true;
  },
  [actions.postDataForMappings.ERROR]: (state, action) => {
    state.postDataForMappings.loading = false;
    state.postDataForMappings.error = action.payload.message;
  },
  [actions.postDataForMappings.SUCCESS]: (state, action) => {
    state.postDataForMappings.data = action.payload;
    state.postDataForMappings.loading = false;
  },
  [actions.blockedProduct.REQUEST]: (state) => {
    state.blockedProduct.loading = true;
  },
  [actions.blockedProduct.SUCCESS]: (state, action) => {
    state.blockedProduct.loading = false;
    state.blockedProduct.data = action.payload.status;
  },
  [actions.blockedProduct.ERROR]: (state, action) => {
    state.blockedProduct.loading = false;
    state.blockedProduct.error = action.payload;
  },
  [actions.unblockedProduct.REQUEST]: (state) => {
    state.unblockedProduct.loading = true;
  },
  [actions.unblockedProduct.SUCCESS]: (state, action) => {
    state.unblockedProduct.loading = false;
    state.unblockedProduct.data = action.payload.status;
  },
  [actions.unblockedProduct.ERROR]: (state, action) => {
    state.unblockedProduct.loading = false;
    state.unblockedProduct.error = action.payload;
  },
  [actions.deleteMappings.REQUEST]: (state) => {
    state.deleteMappings.loading = true;
  },
  [actions.deleteMappings.SUCCESS]: (state, action) => {
    state.deleteMappings.loading = false;
    state.deleteMappings.data = action.payload.status;
  },
  [actions.deleteMappings.ERROR]: (state, action) => {
    state.deleteMappings.loading = false;
    state.deleteMappings.error = action.payload;
  },
});

const productsSearchLoading = (state: AppState) =>
  state.editPageReducer.productsSearchForPharmacy.loading;
const productsSearchError = (state: AppState) =>
  state.editPageReducer.productsSearchForPharmacy.error;
const productsSearchData = (state: AppState) =>
  state.editPageReducer.productsSearchForPharmacy.data;
const setSelectedProduct = (state: AppState) =>
  state.editPageReducer.setProduct.selectedProduct;

const postDataForMapError = (state: AppState) =>
  state.editPageReducer.postDataForMappings.error;

const productBySkuData = (state: AppState) =>
  state.editPageReducer.productBySku.data;
const productBySkuError = (state: AppState) =>
  state.editPageReducer.productBySku.error;
const productBySkuLoading = (state: AppState) =>
  state.editPageReducer.productBySku.loading;

const selectors = {
  productsSearchLoading,
  productsSearchData,
  productsSearchError,
  setSelectedProduct,
  productBySkuData,
  productBySkuError,
  productBySkuLoading,
  postDataForMapError,
};

export { selectors };
export default reducer;
