import { setToLocalStorage } from '../../global/helpers';
import * as constants from '../ProductsPage/constants';

export const formatHTMLString = (value: string | undefined) => {
  if (value) {
    let formattedString;
    // eslint-disable-next-line
    formattedString = value.replace(/class="[a-zA-Z0-9:;\.\s\(\)\-\,]*"/gi, '');
    formattedString = formattedString.replace(
      // eslint-disable-next-line
      /style="[a-zA-Z0-9:;\.\s\(\)\-\,]*"/gi,
      ''
    );
    return formattedString;
  }
};

export const mapDataForProductInfo = (item: any, attachedProduct?: any) => {
  const getCustomAttribute = (customAttributes: any, attributeCode: string) =>
    customAttributes?.find((el: any) => el.attribute_code === attributeCode)
      ?.value;
  if (attachedProduct) {
    const element = attachedProduct.find((el: any) => el.sku === item.sku);
    return item
      ? {
          id: item.id,
          sku: item.sku,
          wareId: element?.ware_id,
          name: item.name,
          img: `https://db-images.object.pscloud.io/optimized_v4_img_small_${item.sku}.webp`,
          packing: formatHTMLString(
            getCustomAttribute(item.custom_attributes, 'pp_packing')
          ),
          manufacturer: formatHTMLString(
            getCustomAttribute(item.custom_attributes, 'manufacturer_id')
          ),
        }
      : null;
  }
  return item
    ? {
        id: item.id,
        sku: item.sku,
        name: item.name,
        img: `https://db-images.object.pscloud.io/optimized_v4_img_small_${item.sku}.webp`,
        packing: formatHTMLString(
          getCustomAttribute(item.custom_attributes, 'pp_packing')
        ),
        manufacturer: formatHTMLString(
          getCustomAttribute(item.custom_attributes, 'manufacturer_id')
        ),
      }
    : null;
};
export const setActiveTab = (activeTab: string | undefined) => {
  setToLocalStorage(constants.ACTIVE_TAB_KEY, activeTab);
};

export const mapDataForProductInfoPrice = (item: any, prices: any) => {
  const getCustomAttribute = (customAttributes: any, attributeCode: string) =>
    customAttributes?.find((el: any) => el.attribute_code === attributeCode)
      ?.value;
  const priceAndQuantity = prices?.find((el: any) => el.sku === item.sku);
  const mappedInfo = item
    ? {
        id: item.id,
        sku: item.sku,
        name: item.name,
        skuName: priceAndQuantity?.pharmacy_name,
        price: priceAndQuantity?.price_with_warehouse_discount,
        allQuantity: priceAndQuantity?.quantity,
        img: `https://db-images.object.pscloud.io/optimized_v4_img_small_${item.sku}.webp`,
        packing: formatHTMLString(
          getCustomAttribute(item.custom_attributes, 'pp_packing')
        ),
        manufacturer: formatHTMLString(
          getCustomAttribute(item.custom_attributes, 'manufacturer_id')
        ),
        needsRecipe: Number(
          formatHTMLString(
            getCustomAttribute(item.custom_attributes, 'recipe_needed')
          )
        ),
      }
    : null;
  return mappedInfo;
};
