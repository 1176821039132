import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ClickAwayListener } from '@mui/material';
import { selectors as selectorsLogin } from '../../../LoginPage/reducer';
import * as Styled from './ManagerCollector.styles';
import Button from '../../../../components/Button';
import * as actions from '../../actions';
import * as actionsUser from '../../../LoginPage/actions';
import { selectors } from '../../reducer';
import { getFromLocalStorage } from '../../../../global/helpers';
import networksArrow from '../../../../global/media/networks-arrow.svg';
import CustomCheckbox from '../../../../components/CustomCheckbox';
import * as actionMagnum from '../../../../components/ActivePharmacistBlock/actions';
import PharmacyActiveModal from '../PharmacyActiveModal';

const ManagerCollector = ({ role }: { role: string }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dataUser = useSelector(selectorsLogin.userData);
  const token = getFromLocalStorage('accessToken');

  const sourcesData = useSelector(selectors.sourcesData);
  const [isCollector, setCollector] = useState<boolean>(dataUser?.isCollector);
  const [isSourcesOpen, setSourcesOpen] = useState<boolean>(false);
  const [selectedSource, setSource] = useState<{
    name: string;
    code: string;
    active: boolean;
    activeUpdatedAt: string | null;
    activeUpdatedBy: string | null;
  }>({
    name: 'Выберите аптеку для отображения на смене',
    code: '',
    active: true,
    activeUpdatedAt: '',
    activeUpdatedBy: '',
  });
  const [activePharmModal, setActivePharmModal] = useState<string | null>(null);

  useEffect(() => {
    if (dataUser) {
      setCollector(dataUser.isCollector);
    }
  }, [dataUser]);

  useEffect(() => {
    if (dataUser && sourcesData) {
      const s = sourcesData.find(
        (el) => el.code === dataUser.collectingSourceCode
      );
      s &&
        setSource({
          name: s.name,
          code: s.code,
          active: s.active,
          activeUpdatedAt: s.activeUpdatedAt,
          activeUpdatedBy: s.activeUpdatedBy,
        });
      sourcesData &&
        sourcesData?.length === 1 &&
        setSource({
          name: sourcesData[0].name,
          code: sourcesData[0].code,
          active: sourcesData[0].active,
          activeUpdatedAt: sourcesData[0].activeUpdatedAt,
          activeUpdatedBy: sourcesData[0].activeUpdatedBy,
        });
    }
  }, [dataUser, sourcesData?.length]);

  const handleChangeCollector = (collector: boolean) => {
    setCollector(collector);
  };

  const handleClickSourcesOpen = () => {
    sourcesData && sourcesData?.length > 1 && setSourcesOpen(!isSourcesOpen);
  };

  useEffect(() => {
    if (dataUser) {
      dispatch(
        actions.getSourcesByNetwork.request({
          networkCode: dataUser?.networkCode,
          postEffect: (source: {
            name: string;
            code: string;
            active: boolean;
            activeUpdatedAt: string | null;
            activeUpdatedBy: string | null;
          }) => {
            setSource({
              name: source.name,
              code: source.code,
              active: source.active,
              activeUpdatedAt: source.activeUpdatedAt,
              activeUpdatedBy: source.activeUpdatedBy,
            });
          },
        })
      );
    }
  }, [dispatch, dataUser]);

  const handleSetCollectorManager = () => {
    dispatch(
      actions.postCollectorManager.request({
        isCollector,
        sourceCode: selectedSource?.code,
        postEffect: () => {
          dispatch(actionsUser.getUserInfo.request({ token }));
          dispatch(
            actionMagnum.getAllUsers.request({
              sourceCode: dataUser.sourceCode,
            })
          );
          if (
            !selectedSource.active &&
            (!selectedSource?.activeUpdatedAt ||
              selectedSource?.activeUpdatedBy === 'system')
          ) {
            setActivePharmModal(selectedSource.code);
          }
        },
      })
    );
  };

  return sourcesData && sourcesData?.length !== 0 ? (
    <Styled.ManagerCollectorContainer>
      {activePharmModal && (
        <PharmacyActiveModal sourceCode={activePharmModal || ''} role={role} />
      )}
      <Styled.IsCollectorBlock>
        <CustomCheckbox
          checked={isCollector}
          onChange={(e) => handleChangeCollector(e)}
          id="collector"
          title={t('OrdersPage.CollectorBlock.CHECKBOX_TITLE')}
        />
      </Styled.IsCollectorBlock>
      <Styled.SourceCodesBlock onClick={handleClickSourcesOpen}>
        <div className="source-name">{selectedSource?.name}</div>
        {sourcesData && sourcesData?.length > 1 && (
          <img src={networksArrow} alt="networks-arrow" />
        )}
        {isSourcesOpen && (
          <ClickAwayListener onClickAway={() => setSourcesOpen(false)}>
            <Styled.SourcesContainer>
              {sourcesData?.map(
                (item: {
                  code: string;
                  name: string;
                  active: boolean;
                  activeUpdatedAt: string | null;
                  activeUpdatedBy: string | null;
                }) => (
                  <div
                    className="name"
                    key={item.code}
                    onClick={() => {
                      setSource(item);
                    }}
                  >
                    {item.name}
                  </div>
                )
              )}
            </Styled.SourcesContainer>
          </ClickAwayListener>
        )}
      </Styled.SourceCodesBlock>
      <Styled.ButtonBlock>
        <Button
          text={t('OrdersPage.CollectorBlock.CONFIRM_BTN')}
          onClick={handleSetCollectorManager}
          variant="dark"
          disabled={selectedSource?.code === ''}
        />
      </Styled.ButtonBlock>
    </Styled.ManagerCollectorContainer>
  ) : null;
};

export default ManagerCollector;
