import styled from 'styled-components';
import { baseTheme } from '../../../../global/styles/theme';

export const ProductsBreadCrumbsContainer = styled('div')`
  padding-top: 24px;
  display: flex;
  max-width: 1216px;
  margin: auto;

  .breadCrumb {
    margin-right: 10px;
    cursor: pointer;
  }

  .active {
    color: ${baseTheme.colors.secondary};
  }
`;

export const ProductsBreadCrumbIcon = styled('div')`
  margin-right: 10px;
`;
