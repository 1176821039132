import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import * as Styled from './SalesPage.styles';
import * as actions from './actions';
import { selectors } from './reducer';
import { SalesProductsTabOption, SalesSelectedPharmacy } from './types';
import { EditService } from '../EditPage/service';

import SearchBar from '../../components/SearchBar';
import { getFromLocalStorage, setToLocalStorage } from '../../global/helpers';
import { getCities, getEncryptedCity } from '../../global/cities';
import { selectors as userSelectors } from '../LoginPage/reducer';
import { UserRole } from '../OrdersPage/types';
import { salesProductsSchemaCommon } from './helpers';
import ModalWindow from '../../components/ModalWindow';
import SalesProductsTable from './SalesProductsTable/SalesProductsTable';
import NewProductCreate from './NewProductCreate/NewProductCreate';
import BestBeforeProductsTable from './BestBeforeProductsTable/BestBeforeProductsTable';
import rightArrow from '../../global/media/right-blue-arrow.svg';
import bottomArrow from '../../global/media/bottom-arrow.svg';
import AllSalesProductsTable from './AllSalesProductsTable/AllSalesProductsTable';
import SalesManagerCollector from './SalesManagerCollector';

function getDateString(date: Date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

const SalesPage = () => {
  const { t } = useTranslation();
  const salesProductsData = useSelector(selectors.salesProductsData);
  const allSalesProducts = useSelector(selectors.allSalesProducts);
  const [searchProductList, setSearchProductList] = useState<any[]>([]);
  const dispatch = useDispatch();
  const userData = useSelector(userSelectors.userData);
  const networkCode =
    getFromLocalStorage('networkData')?.code || userData?.sourceCode;
  const role = getFromLocalStorage('role');
  const [currentPharmacy, setCurrentPharmacy] =
    useState<null | SalesSelectedPharmacy>(null);
  const [currentTab, setCurrentTab] = useState(
    SalesProductsTabOption.salesProducts
  );
  const [searchProductValue, setSearchProductValue] = useState<string>('');
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>({});
  const [minPrices, setMinPrices] = useState<any[]>([]);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [selectedCity, setSelectedCity] = useState<string>('');
  const [managerErr, setManagerErr] = useState<boolean>(false);

  const allShowing = role === UserRole.ADMIN;

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (
        searchProductValue.length >= 3 &&
        currentTab === SalesProductsTabOption.salesProducts
      ) {
        if (role === UserRole.MANAGER && !currentPharmacy?.code) {
          setManagerErr(true);
          setTimeout(() => {
            setManagerErr(false);
          }, 3000);
          return;
        }

        EditService.getProducts(searchProductValue).then((res) => {
          const skus = res.items.map((f: any) => f.sku);
          dispatch(
            actions.getProductsInfo.request({
              skus,
              sourceCode: currentPharmacy?.code || networkCode,
              postEffect: (response: any) => {
                setSearchProductList(response.result);
              },
            })
          );
        });
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [searchProductValue, currentTab, currentPharmacy?.code]);

  useEffect(() => {
    if (
      (role === UserRole.PHARMACIST && networkCode) ||
      ((role === UserRole.MANAGER || role === UserRole.PHARMACY_MANAGER) &&
        currentPharmacy?.code)
    ) {
      dispatch(
        actions.getSalesProducts.request({
          networkCode:
            role === UserRole.MANAGER || role === UserRole.PHARMACY_MANAGER
              ? currentPharmacy?.code
              : networkCode,
        })
      );
    }
  }, [networkCode, currentPharmacy, role]);

  useEffect(() => {
    if (!salesProductsData || salesProductsData?.length === 0 || !networkCode)
      return;
    dispatch(
      actions.getProductsInfo.request({
        skus: salesProductsData?.map((f) => f.sku.replace(/_(.*)/, '')),
        sourceCode: currentPharmacy?.code || networkCode,
        postEffect: (response: any) => {
          setSearchProductList(response.result);
          setMinPrices(response.result);
        },
      })
    );
  }, [salesProductsData, networkCode]);

  const onCreateProductHandler = ({
    // eslint-disable-next-line
    pharmacy_product,
    // eslint-disable-next-line
    current_price,
    // eslint-disable-next-line
    discount_price,
    // eslint-disable-next-line
    expiration_date,
    quantity,
    sku,
    series,
    comment,
  }: {
    pharmacy_product: string;
    current_price: string;
    discount_price: string;
    quantity: string;
    expiration_date: string;
    sku: string;
    series: string;
    comment: string;
  }) => {
    const createdNewOrder: {
      pharmacy_product: string;
      current_price: number;
      discount_price: number;
      quantity: number;
      sku: string;
      series: string;
      comment: string;
      expiration_date?: string;
    } = {
      // eslint-disable-next-line
      pharmacy_product,
      current_price: parseInt(current_price, 10),
      discount_price: parseInt(discount_price, 10),
      quantity: parseInt(quantity, 10),
      sku,
      series,
      comment,
    };
    // eslint-disable-next-line
    if (expiration_date.trim() !== '') {
      createdNewOrder.expiration_date = getDateString(
        new Date(expiration_date)
      );
    }

    if (role === UserRole.MANAGER && !currentPharmacy?.code) {
      return;
    }

    fetch(
      `https://prod-backoffice.daribar.com/api/v1/prices?city=${getEncryptedCity(
        getFromLocalStorage('city') || ''
      )}`,
      {
        method: 'POST',
        body: JSON.stringify([sku]),
        headers: { 'Content-Type': 'text/plain' },
      }
    )
      .then(async (res) => {
        const data = await res.json();
        dispatch(
          actions.createNewSaleOrder.request({
            createdNewOrder: {
              ...createdNewOrder,
              source_code: currentPharmacy?.code || networkCode,
              avg_price:
                data.result?.[0]?.avg_customer_price ||
                createdNewOrder.current_price,
            },
            postEffect: () => {
              dispatch(
                actions.getSalesProducts.request({
                  networkCode:
                    role === UserRole.MANAGER ||
                    role === UserRole.PHARMACY_MANAGER
                      ? currentPharmacy?.code
                      : networkCode,
                })
              );
              setIsCreateModalOpen(!isCreateModalOpen);
              setSelected({});
            },
          })
        );
      })
      .catch((e) => {
        console.log(e);
        dispatch(
          actions.createNewSaleOrder.request({
            createdNewOrder,
            postEffect: () => {
              dispatch(
                actions.getSalesProducts.request({
                  networkCode:
                    role === UserRole.MANAGER ||
                    role === UserRole.PHARMACY_MANAGER
                      ? currentPharmacy?.code
                      : networkCode,
                })
              );
              setIsCreateModalOpen(!isCreateModalOpen);
              setSelected({});
            },
          })
        );
      });
  };

  useEffect(() => {
    setToLocalStorage('looked-sale', true);
  }, []);

  useEffect(() => {
    if (allShowing) {
      dispatch(actions.getAllSalesProducts.request({ city: selectedCity }));
    }
  }, [selectedCity]);

  const productTable = (
    <>
      <th className="header-row header-row-sm">
        {t('SALESPAGE.TABLE.HEADER.AMOUNT')}
      </th>
      <th className="header-row header-row-sm">
        {t('SALESPAGE.TABLE.HEADER.BEST_BEFORE')}
      </th>
      <th className="header-row header-row-sm">
        {t('SALESPAGE.TABLE.HEADER.SERIES')}
      </th>
      <th className="header-row header-row-sm">
        {t('SALESPAGE.TABLE.HEADER.NEW_AMOUNT')}
      </th>
      <th className="header-row header-row-sm">
        {t('SALESPAGE.TABLE.HEADER.COMMENT')}
      </th>
    </>
  );

  const allSalesTable = (
    <>
      <th className="header-row header-row-sm">№</th>
      <th className="header-row header-row-sm">
        {t('SALESPAGE.TABLE.HEADER.CITY')}
      </th>
      <th className="header-row header-row-sm">
        {t('SALESPAGE.TABLE.HEADER.PHARMACY')}
      </th>
      <th className="header-row header-row-lg">
        {t('SALESPAGE.TABLE.HEADER.PRODUCT')}
      </th>
      {productTable}
    </>
  );

  const tableHeader = (
    <thead>
      <Styled.SalesTableHeaderRow>
        <th className="header-row header-row-lg">
          {t('SALESPAGE.TABLE.HEADER.PRODUCT')}
        </th>
        <th className="header-row header-row-sm">
          {t('SALESPAGE.TABLE.HEADER.MIN_AMOUNT')}
        </th>
        {currentTab === SalesProductsTabOption.bestBeforeProducts &&
          productTable}
        <th className="header-row header-row-sm">
          {t('SALESPAGE.TABLE.HEADER.ACTION')}
        </th>
      </Styled.SalesTableHeaderRow>
    </thead>
  );

  const instruction = t('SALESPAGE.COLLAPSE_INSTRUCTION');

  return (
    <>
      <Styled.SalesPageContainer>
        <div className="products-title"> {t('SALESPAGE.TITLE')}</div>
        {role === UserRole.MANAGER && (
          <SalesManagerCollector
            currentPharmacy={currentPharmacy}
            setCurrentPharmacy={setCurrentPharmacy}
          />
        )}
        {allShowing && (
          <FormControl>
            <FormLabel
              id="demo-row-radio-buttons-group-label"
              sx={{ marginTop: '10px' }}
            >
              {t('SALESPAGE.CITIES')}
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={selectedCity}
              onChange={(event) => setSelectedCity(event.target.value)}
            >
              <FormControlLabel
                value=""
                control={<Radio />}
                label="Все города"
              />
              {getCities().map((city: any) => (
                <FormControlLabel
                  key={city.ruName}
                  value={city.ruName}
                  control={<Radio />}
                  label={city.ruName}
                />
              ))}
            </RadioGroup>
          </FormControl>
        )}
        {!allShowing && (
          <>
            <Styled.Collapse onClick={() => setIsCollapsed(!isCollapsed)}>
              <img
                className={`headerIcon ${isCollapsed && 'headerIconCollapsed'}`}
                width={16}
                src={isCollapsed ? rightArrow : bottomArrow}
                alt="bottom arrow"
              />
              <h3>{t('SALESPAGE.COLLAPSE_DESCRIPTION')}</h3>
            </Styled.Collapse>
            {!isCollapsed && (
              <Styled.InstructionsText>
                <p dangerouslySetInnerHTML={{ __html: instruction }} />
              </Styled.InstructionsText>
            )}
            <Styled.SalesPageTabsContainer>
              <div
                className={`tab-item ${
                  currentTab === SalesProductsTabOption.salesProducts &&
                  'type-active'
                }`}
                onClick={() =>
                  setCurrentTab(SalesProductsTabOption.salesProducts)
                }
              >
                {t('SALESPAGE.TABS.SALES_PRODUCTS')}
              </div>
              <div
                className={`tab-item ${
                  currentTab === SalesProductsTabOption.bestBeforeProducts &&
                  'type-active'
                }`}
                onClick={() =>
                  setCurrentTab(SalesProductsTabOption.bestBeforeProducts)
                }
              >
                {t('SALESPAGE.TABS.BEST_BEFORE')}
              </div>
            </Styled.SalesPageTabsContainer>
          </>
        )}

        <Styled.SalesPageTableContainer>
          {currentTab === SalesProductsTabOption.salesProducts &&
            !allShowing && (
              <div className="search-container">
                <div className="serch-field-container">
                  <SearchBar
                    value={searchProductValue}
                    placeholder="Поиск товаров"
                    setValue={(value) => {
                      setSearchProductValue(value);
                    }}
                    onSearchClick={() => false}
                  />
                </div>
                {managerErr && (
                  <p className="manager-error">
                    {t('SALESPAGE.MANAGER_ERROR')}
                  </p>
                )}
              </div>
            )}
          <Styled.SalesTableContainer>
            {allShowing ? allSalesTable : tableHeader}
            <Styled.SalesTable>
              {currentTab === SalesProductsTabOption.salesProducts &&
                !allShowing &&
                searchProductList
                  ?.slice()
                  ?.sort((a, b) => a.sku.localeCompare(b.sku))
                  .map((el) => (
                    <BestBeforeProductsTable
                      el={el}
                      key={el.sku}
                      data={el}
                      setIsModalOpen={() => {
                        setIsCreateModalOpen(true);
                        setSelected(el);
                      }}
                    />
                  ))}
              {currentTab === SalesProductsTabOption.bestBeforeProducts &&
                !allShowing &&
                salesProductsData
                  ?.slice()
                  ?.sort((a, b) => a.sku.localeCompare(b.sku))
                  .map((el) => (
                    <SalesProductsTable
                      currentPharmacyCode={currentPharmacy?.code || null}
                      el={{
                        ...el,
                        minPrice: minPrices.find((f) => el.sku.includes(f.sku))
                          ?.min_customer_price,
                      }}
                      key={el.sku}
                    />
                  ))}
              {allShowing &&
                allSalesProducts?.map((el, ind) => (
                  <AllSalesProductsTable el={el} ind={ind} />
                ))}
            </Styled.SalesTable>
          </Styled.SalesTableContainer>
          {searchProductValue.length >= 3 &&
            searchProductList?.length === 0 &&
            currentTab === SalesProductsTabOption.salesProducts && (
              <div className="empty-search-result-container">
                <p className="empty-search-result-text">
                  {t('SALESPAGE.NOT_FOUND_PRODUCTS')}
                </p>
              </div>
            )}
        </Styled.SalesPageTableContainer>
        {isCreateModalOpen && (
          <ModalWindow
            onClose={() => {
              setIsCreateModalOpen(!isCreateModalOpen);
              setSelected({});
            }}
          >
            <Formik
              initialValues={{
                pharmacy_product: (selected?.name as string) || '',
                discount_price: '0',
                current_price: (selected?.base_price as string) || '',
                quantity: '0',
                sku: (selected?.sku as string) || '',
                expiration_date: '',
                series: '',
                comment: '',
              }}
              validationSchema={salesProductsSchemaCommon}
              onSubmit={onCreateProductHandler}
            >
              {({ values, handleSubmit, handleChange, errors }) => (
                <NewProductCreate
                  values={values}
                  handleSubmit={handleSubmit}
                  handleChange={handleChange}
                  errors={errors}
                />
              )}
            </Formik>
          </ModalWindow>
        )}
      </Styled.SalesPageContainer>
      {/* )} */}
    </>
  );
};

export default SalesPage;
