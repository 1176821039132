import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import { selectors } from '../../containers/OrderPage/reducer';
import * as Styled from './AnalogModal.styles';
import ModalWindow from '../ModalWindow';
import SearchBar from '../SearchBar/SearchBar';
import * as actionOrder from '../../containers/OrderPage/actions';
import { ProductResponse } from '../../containers/EditPage/types';

import ProductCard from './components/ProductCard';
import { mapDataForProductInfoPrice } from '../../containers/EditPage/helpers';

interface AnalogModalProps {
  onClose: () => void;
  source: string;
  orderId: string;
  newProduct?: boolean;
}

const AnalogModal = ({
  onClose,
  source,
  orderId,
  newProduct,
}: AnalogModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState<string>('');

  const searchData = useSelector(selectors.productsSearchData);
  const searchLoading = useSelector(selectors.productsSearchLoading);

  const [isSuggestionOpen, setSuggestionOpen] = useState<boolean>(false);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchValue.length > 2) {
        dispatch(
          actionOrder.getProductsSearchForAnalog.request({
            searchValue,
            source,
            orderId: orderId.slice(1),
          })
        );
      }
      setSuggestionOpen(true);
    }, 100);
    return () => clearTimeout(delayDebounceFn);
  }, [dispatch, searchValue]);

  return (
    <ModalWindow onClose={onClose}>
      <Styled.AnalogModalContainer>
        <Styled.AnalogSearchContainer>
          <div className="description">
            {newProduct
              ? t('OrdersPage.OrderDetailedModal.ANALOG_MODAL.DESC_PRODUCT')
              : t('OrdersPage.OrderDetailedModal.ANALOG_MODAL.DESC')}
          </div>
          <div className="search-bar">
            <SearchBar
              value={searchValue}
              placeholder=""
              setValue={setSearchValue}
              onSearchClick={() => null}
            />
          </div>
        </Styled.AnalogSearchContainer>
        <Styled.AnalogSuggestion>
          {isSuggestionOpen && searchData && searchValue !== '' ? (
            <>
              {/* eslint-disable-nest-line */}
              {searchData.data?.length > 0 ? (
                <Styled.ProductsSearchItemContainer>
                  {searchData.data?.map((el: ProductResponse) => (
                    <ProductCard
                      data={mapDataForProductInfoPrice(
                        el,
                        searchData?.pricesAndQuantity
                      )}
                      key={el.id}
                      newProduct={newProduct}
                      onClose={onClose}
                    />
                  ))}
                </Styled.ProductsSearchItemContainer>
              ) : (
                !searchData.length && (
                  <div className="search-no-results">
                    {t('OrderSearchModal.NO_RESULTS')}
                  </div>
                )
              )}
            </>
          ) : (
            searchLoading && (
              <div className="product-loading">
                <CircularProgress size={25} />
              </div>
            )
          )}
        </Styled.AnalogSuggestion>
      </Styled.AnalogModalContainer>
    </ModalWindow>
  );
};

export default AnalogModal;
