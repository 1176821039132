import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './OneLine.styles';
import CustomCheckbox from '../../../components/CustomCheckbox/CustomCheckbox';

export type Time = { hours: number; minutes: number };

export type DayType = { dayOFWeek: number; from: Time; to: Time };
interface OneLineProps {
  label: string;
  ind: number;
  daySchedule: DayType;
  setNeedValidate: (a: boolean) => void;
  needValidate: boolean;
  setIsValidated: (a: boolean) => void;
  isValidated: boolean;
  workDays: boolean;
  updateDaySchedule: (from: Time, to: Time, ind: number) => any;
}

const OneLine: React.FC<OneLineProps> = ({
  label,
  ind,
  daySchedule,
  updateDaySchedule,
  setNeedValidate,
  needValidate,
  workDays,
  setIsValidated,
  isValidated,
}) => {
  const { t } = useTranslation();
  const [isDayOffChecked, setIsDayOffChecked] = useState(true);
  const [isAllTimeChecked, setIsAllTimeChecked] = useState(true);
  const [valueToHours, setValueToHours] = useState(daySchedule.from.hours);
  const [errorFromHours, setErrorFromHours] = useState(false);
  const [errorToHours, setErrorToHours] = useState(false);
  const [errorFromMinutes, setErrorFromMinutes] = useState(false);
  const [errorToMinutes, setErrorToMinutes] = useState(false);
  const [valueFromMinutes, setValueFromMinutes] = useState(
    daySchedule.from.minutes
  );
  const [valueToMinutes, setValueToMinutes] = useState(daySchedule.to.hours);
  const [valueFromHours, setValueFromHours] = useState(daySchedule.to.minutes);

  useEffect(() => {
    if (!workDays) {
      setValueFromHours(daySchedule.from.hours);
      setValueFromMinutes(daySchedule.from.minutes);
      setValueToHours(daySchedule.to.hours);
      setValueToMinutes(daySchedule.to.minutes);
    }
  }, [
    daySchedule.dayOFWeek,
    daySchedule.from.hours,
    daySchedule.from.minutes,
    daySchedule.to.hours,
    daySchedule.to.minutes,
  ]);

  useEffect(() => {
    if (needValidate) {
      if (
        valueFromHours !== -1 &&
        valueToHours !== -1 &&
        valueToMinutes !== -1 &&
        valueFromMinutes !== -1
      ) {
        setIsValidated(true);
      } else if (valueFromHours === -1) {
        setErrorFromHours(true);
      } else if (valueToHours === -1) {
        setErrorToHours(true);
      } else if (valueFromMinutes === -1) {
        setErrorFromMinutes(true);
      } else if (valueToMinutes === -1) {
        setErrorToMinutes(true);
      }
    }
  }, [
    valueFromHours,
    valueToHours,
    valueFromMinutes,
    valueToMinutes,
    needValidate,
  ]);

  useEffect(() => {
    if (valueFromHours !== -1) {
      setErrorFromHours(false);
    }
    if (valueToHours !== -1) {
      setErrorToHours(false);
    }
    if (valueFromMinutes !== -1) {
      setErrorFromMinutes(false);
    }
    if (valueToMinutes !== -1) {
      setErrorToMinutes(false);
    }
  }, [valueFromHours, valueToHours, valueFromMinutes, valueToMinutes]);

  useEffect(() => {
    if (isDayOffChecked) {
      setIsAllTimeChecked(false);
    }
  }, [isDayOffChecked]);

  useEffect(() => {
    if (isAllTimeChecked) {
      setIsDayOffChecked(false);
    }
  }, [isAllTimeChecked]);

  useEffect(() => {
    updateDaySchedule(
      {
        hours: valueFromHours,
        minutes: valueFromMinutes,
      },
      {
        hours: valueToHours,
        minutes: valueToMinutes,
      },
      ind
    );
  }, [valueToHours, valueFromMinutes, valueToMinutes, valueFromHours]);

  const transformValue = (value: number) => {
    switch (value) {
      case -1:
        return '  ';
      case -2:
        return '--';
      default:
        return value > 9 ? value.toFixed(0) : `0${value.toFixed(0)}`;
    }
  };

  return (
    <Styled.Container>
      <Styled.Text>{label}</Styled.Text>
      <Styled.Label>
        от
        <Styled.Input
          title="от 00 до 23"
          value={transformValue(valueFromHours)}
          pattern="^[0-9]*$"
          error={errorFromHours}
          onChange={(e) =>
            setValueFromHours(
              Number(e.target.value) < 24 ? Number(e.target.value) : 23
            )
          }
          type="text"
        />
      </Styled.Label>
      <Styled.Label>
        :
        <Styled.Input
          title="от 00 до 59"
          value={transformValue(valueFromMinutes)}
          error={errorFromMinutes}
          pattern="^[0-9]*$"
          onChange={(e) =>
            setValueFromMinutes(
              Number(e.target.value) < 60 ? Number(e.target.value) : 59
            )
          }
          type="text"
        />
      </Styled.Label>

      <Styled.Label>
        до
        <Styled.Input
          title="от 00 до 23"
          value={transformValue(valueToHours)}
          error={errorToHours}
          pattern="^[0-9]*$"
          onChange={(e) =>
            setValueToHours(
              Number(e.target.value) < 24 ? Number(e.target.value) : 23
            )
          }
          type="text"
        />
      </Styled.Label>
      <Styled.Label>
        :
        <Styled.Input
          title="от 00 до 59"
          value={transformValue(valueToMinutes)}
          error={errorToMinutes}
          pattern="^[0-9]*$"
          onChange={(e) =>
            setValueToMinutes(
              Number(e.target.value) < 60 ? Number(e.target.value) : 59
            )
          }
          type="text"
        />
      </Styled.Label>
      <Styled.ContainerCheckbox>
        <CustomCheckbox
          title={t('ModalHours.OneLine.AROUND_THE_CLOCK')}
          id={`all${ind}`}
          onChange={() => setIsAllTimeChecked(!isAllTimeChecked)}
          checked={isAllTimeChecked}
          onClick={() => {
            if (!isAllTimeChecked) {
              setValueFromHours(0); // 0 - "00"
              setValueToHours(0);
              setValueFromMinutes(0);
              setValueToMinutes(0);
            } else {
              setValueFromHours(-1); // -1 - ""
              setValueToHours(-1);
              setValueFromMinutes(-1);
              setValueToMinutes(-1);
            }
          }}
        />
      </Styled.ContainerCheckbox>
      <Styled.ContainerCheckbox>
        <CustomCheckbox
          title={t('ModalHours.OneLine.DAY_OFF')}
          id={`work${ind}`}
          onChange={() => setIsDayOffChecked(!isDayOffChecked)}
          checked={isDayOffChecked}
          onClick={() => {
            if (!isDayOffChecked) {
              setValueFromHours(-2); // -2 - "--"
              setValueToHours(-2);
              setValueFromMinutes(-2);
              setValueToMinutes(-2);
            } else {
              setValueFromHours(-1);
              setValueToHours(-1);
              setValueFromMinutes(-1);
              setValueToMinutes(-1);
            }
          }}
        />
      </Styled.ContainerCheckbox>
    </Styled.Container>
  );
};

export default OneLine;
