import { t } from 'i18next';
import { NOTIFICATION_CHANGES_NOT_SAVED } from '../../global/constants';

export const getTagErrorMsg = (errorText: string) => {
  if (
    errorText.includes('duplicate key value violates unique constraint') ||
    errorText === 'tag already exists'
  ) {
    return t('PharmacyTagsPage.TAGS_COMPONENT.FORM.ERROR_EXIST_NAME');
  }
  return NOTIFICATION_CHANGES_NOT_SAVED;
};
