import axios from 'axios';

export class AnalogsService {
  static API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/api/v2/`;

  static getAnalogsBySku = async (key: string, type: string) => {
    const response = await axios({
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      url: `${AnalogsService.API_BASE_URL}admin/suggestions?key=${key}&type=${type}`,
    });
    return response.data;
  };

  static getAnalogsBySkuPharmacist = async (key: string) => {
    const response = await axios({
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      url: `${AnalogsService.API_BASE_URL}suggestions?key=${key}&type=analog`,
    });
    return response.data;
  };

  static setSuggestionOrder = async (orderIds: number[]) => {
    const response = await axios({
      method: 'put',
      data: {
        ordered_ids: orderIds,
      },
      url: `${AnalogsService.API_BASE_URL}admin/suggestions/order`,
    });

    return response.data;
  };

  static submitReview = async (sku: string, value: string) => {
    const response = await axios({
      method: 'post',
      data: {
        value,
      },
      url: `${AnalogsService.API_BASE_URL}admin/suggestions?type=analog&key=${sku}_reviews`,
    });

    return response.data;
  };

  static deleteReview = async (id: number) => {
    const response = await axios({
      method: 'delete',
      url: `${AnalogsService.API_BASE_URL}admin/suggestions?id=${id}`,
    });
    return response.data;
  };
}
