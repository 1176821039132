import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { OperatorsService } from './service';
import { UserResponse } from '../SourcePage/types';
import { usersOfSource } from '../SourcePage/helpers';

interface UsersResponse {
  status: string;
  result: UserResponse[] | null;
}

export function* getOperatorsSaga() {
  try {
    const response: UsersResponse = yield call(OperatorsService.getOperators);
    yield put(actions.getOperators.success(usersOfSource(response.result)));
  } catch (error) {
    yield put(actions.getOperators.error(error));
  }
}

export function* operatorPageWatcherSaga() {
  yield all([takeLatest(actions.getOperators.REQUEST, getOperatorsSaga)]);
}
