import React, { useEffect } from 'react';
import * as Styled from './ModalWindow.styles';
import closeIcon from '../../global/media/modal-close-icon.svg';

interface ModalWindowProps {
  onClose: () => void;
  noClose?: boolean;
  zIndex?: boolean;
  bc?: string;
}

const ModalWindow: React.FC<ModalWindowProps> = ({
  onClose,
  children,
  noClose,
  zIndex,
  bc,
}) => {
  useEffect(() => {
    document.body.classList.add('no-scroll');
    document.getElementById('root')?.classList.add('no-scroll');

    return () => {
      document.body.classList.remove('no-scroll');
      document.getElementById('root')?.classList.remove('no-scroll');
    };
  }, []);

  return (
    <>
      <Styled.ModalWindowBackdrop onClick={!noClose ? onClose : () => {}} />
      {zIndex ? (
        <Styled.ModalWindowContainer100
          style={{ backgroundColor: bc || 'white' }}
        >
          {!noClose && (
            <Styled.ModalWindowHeader>
              <Styled.ModalCloseIconContainer onClick={onClose}>
                <img src={closeIcon} alt="close icon" />
              </Styled.ModalCloseIconContainer>
            </Styled.ModalWindowHeader>
          )}
          <div className="modal-window">{children} </div>
        </Styled.ModalWindowContainer100>
      ) : (
        <Styled.ModalWindowContainer style={{ backgroundColor: bc || 'white' }}>
          {!noClose && (
            <Styled.ModalWindowHeader>
              <Styled.ModalCloseIconContainer onClick={onClose}>
                <img src={closeIcon} alt="close icon" />
              </Styled.ModalCloseIconContainer>
            </Styled.ModalWindowHeader>
          )}
          <div className="modal-window">{children} </div>
        </Styled.ModalWindowContainer>
      )}
    </>
  );
};

export default ModalWindow;
