import styled from 'styled-components';
import { baseTheme } from '../../../../../../global/styles/theme';

export const TagsContainer = styled('div')`
  width: 400px;
  margin: 0 -30px 20px -30px;
  max-height: 460px;
  z-index: 1000;

  .title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .meta-input-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 16px;
    width: 100%;
    height: 48px;
    border: 1px solid ${baseTheme.colors.stroking};
    border-radius: 8px;

    input {
      width: 100%;
      color: ${baseTheme.colors.secondary};
      font-size: 16px;
      -webkit-appearance: none;
      border: none;
      padding: 0;
      background-color: inherit;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: ${baseTheme.colors.tertiary};
      }
    }
  }
`;

export const SearchBarInput = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 16px;
  width: 100%;
  height: 48px;
  border: 1px solid ${baseTheme.colors.stroking};
  border-radius: 8px;
  position: relative;

  input {
    width: 100%;
    color: ${baseTheme.colors.secondary};
    font-size: 16px;
    -webkit-appearance: none;
    border: none;
    padding: 0;
    background-color: inherit;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: ${baseTheme.colors.tertiary};
    }
  }
`;

export const SourceTagsBlock = styled('div')`
  margin-top: 24px;
  max-height: 200px;
  overflow: auto;

  .tags-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 40px;
    width: 100%;
    cursor: pointer;
    border-bottom: 1px solid ${baseTheme.colors.stroking};
    &:hover {
      background-color: rgba(10, 119, 199, 0.1);
    }

    .checkbox-container {
      position: absolute;
      top: -9px;
    }
  }

  .added-tag {
    background-color: rgba(10, 119, 199, 0.1);
  }

  .tag {
    margin-left: 20px;
    padding: 6px 12px;
  }

  .tag-color-block {
    height: 20px;
    width: 20px;
  }
`;

export const AddTagsBtn = styled('div')`
  margin-top: auto;
  padding: 8px 20px;
  border-radius: 8px;
  background-color: ${baseTheme.colors.focus};
  cursor: pointer;
  color: ${baseTheme.colors.white};
`;
