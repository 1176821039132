import React, { Component } from 'react';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import {
  captureSentryError,
  getErrorMessageTimeout,
  getFromLocalStorage,
  setToLocalStorage,
} from '../../helpers';
import { LoginService } from '../../../containers/LoginPage/service';

const withAuth = (WrappedComponent: any) => {
  class ComposedComponent extends Component {
    requestInterceptor = axios.interceptors.request.use((req: any) => {
      req.headers['Accept-Encoding'] = 'gzip';
      /* 
      if (
        process.env.REPORT_REGULAR_REQUEST_TIMEOUT &&
        req.url.includes('api/v1/reports/orders/payments')
      ) {
        req.timeout = process.env.REPORT_REGULAR_REQUEST_TIMEOUT;
      }

      if (
        !process.env.REPORT_REGULAR_REQUEST_TIMEOUT &&
        req.url.includes('api/v1/reports/orders/payments')
      ) {
        req.timeout = 30000;
      }

      if (
        process.env.REGULAR_REQUEST_TIMEOUT &&
        !req.url.includes('api/v1/reports/orders/payments')
      ) {
        req.timeout = process.env.REGULAR_REQUEST_TIMEOUT;
      }

      if (
        !process.env.REGULAR_REQUEST_TIMEOUT &&
        !req.url.includes('api/v1/reports/orders/payments')
      ) {
        req.timeout = 7000;
      } */

      if (getFromLocalStorage('accessToken')) {
        req.headers.Authorization = `Bearer ${getFromLocalStorage(
          'accessToken'
        )}`;
      }

      return req;
    });

    responseInterceptor = axios.interceptors.response.use(
      (res) => {
        return res;
      },
      async (err) => {
        const { status } = err.response || '';
        const requestUrl = err.request?.responseURL || '';
        const token = getFromLocalStorage('accessToken');
        const xRequestId = err.response.headers['x-request-id'] || '';
        const xTraceId = err.response.headers['x-trace-id'] || '';
        const { code } = err.response.data || '';

        let errorBody: any = null;

        const requestConfig = err.config;
        if (requestConfig && requestConfig.data) {
          errorBody = requestConfig.data;
        }

        if (status !== 304) {
          Sentry.captureException(err, status);
          captureSentryError(
            err,
            status,
            requestUrl,
            errorBody,
            xRequestId,
            xTraceId
          );
        }

        if (
          err.message?.includes('timeout') ||
          err.message === 'Network Error' ||
          status.toString()[0] === '4' ||
          status.toString()[0] === '5'
        ) {
          setToLocalStorage('requestErrorData', {
            url: requestUrl || err?.config?.url || '',
            status: status || '',
            error_message:
              err.response?.data?.error ||
              err?.response?.data ||
              err?.message ||
              '',
            code: err.response?.data?.code || null,
            body: errorBody || null,
            token,
            time: getErrorMessageTimeout(),
            x_trace_id: xRequestId,
            x_request_id: xTraceId,
          });
          window.dispatchEvent(new Event('storage'));
        }

        if (status === 403 && (code === 'A403002' || code === 'A403004')) {
          setToLocalStorage('accessToken', null);
          setToLocalStorage('refreshToken', null);
          setToLocalStorage('selectedProduct', null);
          setToLocalStorage('role', null);
          setToLocalStorage('networkData', null);
          setToLocalStorage('education', false);
          setToLocalStorage('pagination', {
            unAttachedPage: 0,
            attachedPage: 0,
            blockedPage: 0,
            verificationPage: 0,
          });
          window.location.href = '/login';
        }

        if (
          status === 401 &&
          getFromLocalStorage('refreshToken') &&
          getFromLocalStorage('refreshToken') !== ''
        ) {
          const response = await LoginService.refreshTokens(
            getFromLocalStorage('refreshToken')
          );
          if (response.status === 'success') {
            setToLocalStorage('accessToken', response.result.access_token);
            setToLocalStorage('refreshToken', response.result.refresh_token);
            window.location.reload();
          } else {
            setToLocalStorage('accessToken', null);
            setToLocalStorage('refreshToken', null);
            setToLocalStorage('selectedProduct', null);
            setToLocalStorage('role', null);
            setToLocalStorage('networkData', null);
            setToLocalStorage('education', false);
            setToLocalStorage('pagination', {
              unAttachedPage: 0,
              attachedPage: 0,
              blockedPage: 0,
              verificationPage: 0,
            });
            window.location.href = '/login';
          }
        }

        if (
          status === 401 &&
          (!getFromLocalStorage('refreshToken') ||
            getFromLocalStorage('refreshToken') === '')
        ) {
          setToLocalStorage('accessToken', null);
          setToLocalStorage('refreshToken', null);
          setToLocalStorage('selectedProduct', null);
          setToLocalStorage('role', null);
          setToLocalStorage('networkData', null);
          setToLocalStorage('education', false);
          setToLocalStorage('pagination', {
            unAttachedPage: 0,
            attachedPage: 0,
            blockedPage: 0,
            verificationPage: 0,
          });
          window.location.href = '/login';
        }

        if (status === 500) {
          return Promise.reject(err);
        }
        return Promise.reject(err);
      }
    );

    componentWillUnmount() {
      axios.interceptors.request.eject(this.requestInterceptor);
      axios.interceptors.response.eject(this.responseInterceptor);
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return ComposedComponent;
};

export default withAuth;
